import styled from "styled-components";

export const TtivosFinder = styled.div` 
    width: 100%;
    height: 70vh;
    display: flex;
    flex-direction: column;
    gap: 15px;

    .TtivosFinder__header {
        width: 100%;
        height: 60px;
        display: flex;
        align-items: flex-end;
        gap: 10px;

        img.TtivosFinder__header__logo {
            height: 90%;
        }

        h1.TtivosFinder__header_title {
            margin: 0;
            padding: 0;
            font-weight: 600;
            font-size: 25px;
            color: var(--text-color-light);
        }
    }

    .TtivosFinder__results {
        width: 100%;
        height: 100%;
        overflow-y: auto;
        box-sizing: border-box;
        padding-right: 10px;

        .TtivosFinder__results__page {
            width: 100%;
            box-sizing: border-box;
            padding: 15px 15px 20px 15px;
            display: flex;
            gap: 15px;
            border-radius: 7px;
            transition: .4s;

            :is([data-disabled = true]) {
                background-color: var(--disabled-color);
                border: thin solid var(--disabled-text-color);
            }
            :hover:is([data-disabled = true]) {
                background-color: var(--disabled-color);
            }

            :is([data-active = true]) {
                background-color: var(--primary-color);
            }
            :hover:is([data-active = true]) {
                background-color: var(--primary-color);
            }

            :active {
                transition: .1s;
                transform: scale(0.995);
            }
            
            :hover {
                background-color: rgba(133, 255, 187, 0.3);

                .TtivosFinder__results__page__icon {
                    background-color: var(--primary-color);
                    color: #fff;
                }
                .TtivosFinder__results__page__link {
                    opacity: 1;
                    transition: .2s;
                }
            } 

            
            .TtivosFinder__results__page__icon {
                width: 40px;
                min-width: 40px;
                height: 40px;
                min-height: 40px;
                border-radius: 7px;
                display: flex;
                align-items: center;
                justify-content: center;
                margin-top: 5px;
                background-color: var(--primary-color);
                color: #fff;

                :is([data-sub = true]) {          
                    background-color: transparent;
                    border: thin solid var(--primary-color);
                    color: var(--primary-color);
                }
            }

            :is([data-disabled = true]) .TtivosFinder__results__page__icon {
                background-color: var(--disabled-text-color);
                color: var(--disabled-color);

                :is([data-sub = true]) {          
                    background-color: transparent;
                    border: thin solid var(--disabled-text-color);
                    color: var(--disabled-text-color);
                }
            }

            :is([data-active = true]) .TtivosFinder__results__page__icon {
                background-color: #fff;
                color: var(--primary-color);

                :is([data-sub = true]) {          
                    background-color: #fff;
                    color: var(--primary-color);
                }
            }

            .TtivosFinder__results__page__info {
                width: 100%;
                color: var(--text-color-light);
            }

            :is([data-disabled = true]) .TtivosFinder__results__page__info {
                color: var(--disabled-text-color);
            }
            :is([data-active = true]) .TtivosFinder__results__page__info {
                color: #fff;
            }

            .TtivosFinder__results__page__link {
                width: 20px;
                display: flex;
                align-items: center;
                color: var(--primary-color);
                opacity: 0;
            }

            :is([data-disabled = true]) .TtivosFinder__results__page__link {
                display: none;
            }
        }

    }
`;