import { useQuery } from 'react-query';
import { message } from 'antd';

//APP
import { api } from '../../../api';
import { localConfig } from '../../../../Configs/localConfig';

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
type Item = {
    cod: number,
    cod_conta: number,
    cod_usuario: number,
    create_time: string | null,
    create_user: string | null,
    delete_time: string | null,
    delete_user: string | null,
    tipo: number | null,
    tipo_nome: string | null,
    update_time: string | null,
    update_user: string | null,
    usuario_nome: string | null,
}

//INTERFACE
export interface UsuarioAssisTecnico {
    itens: Item[]
}

export const useGetUsuarioTipoAssisTecnico = () => {
    const queryKey: ['usuario-tipo-assistente-tecnico'] = [`usuario-tipo-assistente-tecnico`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<UsuarioAssisTecnico, Error>(queryKey, async () => {
        return api.get(`usuario-tipo-assistente-tecnico?codConta=${localConfig()?.conta}`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                message.error({content: error?.response?.data?.error ?? 'Falha ao obter lista de usuários!', duration: 5} );
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_usuarios: data, 
        carregando_usuarios: isFetching, 
        refetch_data_usuarios: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
type ItemGerenciar = {
    cod: number,
    cod_func: number,
    cod_conta: number,
    cod_usuario: number,
    create_time: string | null,
    create_user: string | null,
    delete_time: string | null,
    delete_user: string | null,
    tipo: number | null,
    tipo_nome: string | null,
    update_time: string | null,
    update_user: string | null,
    usuario_nome: string | null,
}

//INTERFACE
export interface GerenciarAssisTecnico {
    itens: ItemGerenciar[]
}

export const useGetGerenciarEquipeAssisTecnico= (cod_consultor: number) => {
    const queryKey: any = [`get-gerenciar-equipe-assistente-_${Date.now()}`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<GerenciarAssisTecnico, Error>(queryKey, async () => {
        return api.get(`gerenciar-equipe-assistente-tecnico?codConta=${localConfig()?.conta}&codLider=${cod_consultor}&pagination=-1`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                message.error({content: error?.response?.data?.error ?? 'Falha ao obter lista de usuários!', duration: 5} );
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_gerenciar: data, 
        carregando_gerenciar: isFetching, 
        refetch_data_gerenciar: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};

//=================================================================================>
// LISTAR USUÁRIOS DISPONÍVEIS
//<=================================================================================

//TYPES
type ItemEquipe = {
    acessa_sistema: number | null,
    acesso_cliente: number | null,
    cliente_logo: string | null,
    cliente_nome: string | null,
    cod: number | null,
    cod_cliente: number | null,
    cod_consultor: number | null,
    cod_conta: number | null,
    cod_func: number | null,
    cod_perfil_cliente: number | null,
    cod_perfil_cliente_geral: number | null,
    cod_perfil_conta: number | null,
    conta_logo: string | null,
    conta_logo_url: string | null,
    conta_nome: string | null,
    create_time: string | null,
    create_user: string | null,
    delete_time: string | null,
    delete_user: string | null,
    responsavel_fertilidade: boolean | null,
    status: number | null,
    update_time: string | null,
    update_user: string | null,
    usuario_nome: string | null
}

//INTERFACE
export interface EquipeUsuarios {
    itens: ItemEquipe[]
}

export const useGetEquipeUsuarios= (cod_consultor: number) => {
    const queryKey: any = [`equipe-usuarios_${cod_consultor}`];

    const { data, isFetching, refetch, isFetchedAfterMount, isError } = useQuery<EquipeUsuarios, Error>(queryKey, async () => {
        return api.get(`/equipe?codConta=${localConfig()?.conta}&tecnicoPermitidoConsultor=${cod_consultor}&status=1&embed='tipo_tecnico'&pagination=-1`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                message.error({content: error?.response?.data?.error ?? 'Falha ao obter lista de usuários!', duration: 5} );
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_equipe: data, 
        carregando_equipe: isFetching, 
        refetch_data_equipe: refetch,
        isFetched: isFetchedAfterMount,
        isError: isError
    };
};
