import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export type ItensPerUserLogged = {
  cod: number
  acesso: number
  alteracao: number
  inclusao: number
  exclusao: number
  cod_rotina: number
  rotina_nome: string
  cod_perfil: number
  perfil_nome: string
}

export interface TypePerUserLogged {
  itens?: [ItensPerUserLogged]
  total?: number
  pagina?: number
  quantidadePorPagina?: number
  links?: {
    atual?: string
    anterior?: string
    proximo?: string
  }
  mensagem?: string
  timestamp?: number
}

type TypeQuery = {
  codConta: number | null
  codCliente: number | null
  pagination?: number
}

const url = '/permissao/usuario/logado'

export async function getPerUserLogged(params: TypeQuery) {
  const { pagination = -1 } = params

  const { acesso } = localConfig()
  api.defaults.headers.Acesso = acesso

  const response: AxiosResponse<TypePerUserLogged> = await api.get(url, {
    params: {
      pagination,
      ...params,
    },
  })
  
  localStorage.setItem('cod_perfil', String(response?.data?.itens?.[0]?.cod_perfil))


  return response
}
