import { H2Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
    style?: React.CSSProperties
}

export const TitleH2: React.FC<Props> = ({ children, style }) => {
    return (
        <H2Component style={style}>
            {children}
        </H2Component>
    )
}