export const legendas_mapa_indicadores  = {
    // Adubação de Sistemas:
    34: [
        {cor: 'vermelho', carta: 'Corrigir perfil do solo', hexadecimal: '#e22626'},
        {cor: 'alaranjado', carta: 'Elevar teores de Ca e/ou Mg', hexadecimal: '#ff9a00'},
        {cor: 'amarelo', carta: 'Elevar teor de P', hexadecimal: '#f0ff00'},
        {cor: 'verde_claro', carta: 'Elevar teor de K', hexadecimal: '#b0f13c'},
        {cor: 'verde_escuro', carta: 'Adubação de sistema', hexadecimal: '#2ecc71'},
    ],
    // Mapa de Decisão:
    33: [
        {cor: 'vermelho', carta: 'Calcário 40 cm incorporado', hexadecimal: '#e22626'},
        {cor: 'alaranjado', carta: 'Calcário 40 cm + gesso', hexadecimal: '#ff9a00'},
        {cor: 'amarelo', carta: 'Calcário 30 cm', hexadecimal: '#f0ff00'},
        {cor: 'verde_claro', carta: 'Calcário 20 cm', hexadecimal: '#b0f13c'},
        {cor: 'verde_escuro', carta: 'Sem intervenção', hexadecimal: '#2ecc71'},
    ],
    // Enxofre
    38: [
        {cor: 'vermelho', carta: 'Enxofre elementar', hexadecimal: '#e22626'},
        {cor: 'alaranjado', carta: 'Enxofre elementar', hexadecimal: '#ff9a00'},
        {cor: 'amarelo', carta: 'Gesso', hexadecimal: '#f0ff00'},
        {cor: 'verde_claro', carta: 'Aplicação desnecessária', hexadecimal: '#b0f13c'},
        {cor: 'verde_escuro', carta: 'Aplicação desnecessária ', hexadecimal: '#2ecc71'}
    ]
}