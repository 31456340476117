/* eslint-disable react/require-default-props */
import React, { useState } from 'react'

import { faMinus, faPlus } from '@fortawesome/free-solid-svg-icons'
import { GoogleMap } from '@react-google-maps/api'
import { Drawer } from 'antd'
import WaterMarker from '../WaterMarker'

import Icons from '../Icons'
import { Container, ZoomControl } from './styles'

interface PropsDefaultMap {
  open: boolean
  onClose: (data: boolean) => void
  onMapLoad: (map: google.maps.Map) => void
  mapOptions?: google.maps.MapOptions
  zoomMargin?: number
  escClose?: boolean
}

export const DefaultMap: React.FC<PropsDefaultMap> = (props) => {
  const { open, onClose, onMapLoad, mapOptions, zoomMargin = 0, children, escClose = true } = props

  const [map, setMap] = useState<google.maps.Map>()

  function onLoadMap(mapProps: google.maps.Map) {
    setMap(mapProps)
    onMapLoad(mapProps)
  }

  const handleCloseDrawer = () => {
    onClose(false)
  }
  return (
    <Container>
      <Drawer
        forceRender
        open={open}
        closeIcon={false}
        onClose={() => {
          escClose ? handleCloseDrawer() : null
        }}
        maskClosable={false}
        placement='right'
        headerStyle={{ display: 'none' }}
        width='100%'
        contentWrapperStyle={{ minHeight: '100%' }}
        bodyStyle={{ overflow: 'hidden', padding: 0 }}
      >
        {/* @ts-ignore */}
        <GoogleMap
          zoom={4}
          onLoad={(data) => onLoadMap(data)}
          mapContainerStyle={{
            width: '100%',
            height: '100vh',
          }}
          options={{
            mapTypeId: 'hybrid',
            mapTypeControl: false,
            streetViewControl: false,
            fullscreenControl: false,
            zoomControl: false,
            ...mapOptions,
          }}
        >
          {children}
          <WaterMarker placement='bottomRight' />
          <ZoomControl rMargin={zoomMargin}>
            <a onClick={() => map.setZoom(map.getZoom() + 1)}>
              <Icons icon={faPlus} />
            </a>
            <a onClick={() => map.setZoom(map.getZoom() - 1)}>
              <Icons icon={faMinus} />
            </a>
          </ZoomControl>
        </GoogleMap>
      </Drawer>
    </Container>
  )
}
