import { BoxContainer } from "./styled"

//PROPS
interface Props {
    children?: React.ReactNode;
    borderRadius?: number;
    padding?: number | string;
    gap?: number;
}

export const Box: React.FC<Props> = ({ children, padding, borderRadius, gap }) => {
    return (
        <BoxContainer style={{borderRadius: borderRadius, padding: padding, gap: gap}}>
            {children}
        </BoxContainer>
    )
}