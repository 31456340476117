
import { api } from '../../api'

export interface TypeFormulacao {
  itens: [Formulacao]
}

export interface Formulacao {
  cod?: number
  nome?: string
}

interface TypeQuery {
  nome?: string
  cod?: number
  pagination?: number | -1
}

const url = '/recomendacao/formulacao'

export const getFormulacao = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    ...params,
    pagination,
  }

  const response = api.get(url, {
    params,
  })

  return response
}

export const postFormulacao = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putFormulacao = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delFormulacao = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
