import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeMoniNivelControle {
  itens: [MoniNivelControleItens]
}

export interface MoniNivelControleItens {
  cod?: number
  cod_conta?: number
  cod_cliente?: number
  cod_cultura?: number
  status?: number
  cultura_nome?: string
  item?: ItemMoniNivelControl[]
}

export interface ItemMoniNivelControl {
  cod?: number | string
  id?: number | string
  edt?: boolean
  cod_nivel_controle?: number
  cod_variacao_especie?: number
  cod_tipo_metrica?: number
  create_time?: string
  cod_categoria?: number
  cod_nivel_controle_item?: number
  valor?: string | number
  status?: number
  cod_especie?: number
  especie_nome?: string
  totalizador?: number
  variacao_especie_nome?: string
  tipo_metrica_nome?: string
  nivel_controle_item_estadio?: [
    {
      cod: number
      cod_nivel_controle_item: number
      cod_estadio: number
      create_time: string
      estadio_nome: string
      cod_nivel_controle: number
      cod_especie: number
      cod_variacao_especie: number
    }
  ]
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  codCultura?: number
  testeBandeja?: number
  codSafra?: number
  cod?: number
  pagination?: number | -1
  embed?: string | 'item'
}

export const getMoniNivelControle = async (params: TypeQuery) => {
  const { pagination = -1, codConta } = params

  const { codCliente, conta, acesso } = localConfig()

  api.defaults.headers.Acesso = acesso

  params = {
    pagination,
    codConta: codConta ? codConta : conta,
    codCliente,
    ...params,
  }

  const response = api.get('/monitoramento/nivel-controle', {
    params,
  })

  return response
}

export const getListEstadios = async (cod) => {
  const { conta, acesso } = localConfig()

  api.defaults.headers.Acesso = acesso
  let codCultura = cod.data
  const response = api.get('/monitoramento/nivel-controle-item-estadio', {
    params: {
      pagination: -1,
      codConta: conta,
      codNivelControle: codCultura,
    },
  })

  return response
}

export const getAttMoniNivelControle = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.get(`/monitoramento/nivel-controle/${cod.cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'item',
    },
  })

  return response
}

export const postMoniNivelControle = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.post('/monitoramento/nivel-controle', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'item',
    },
  })

  return response
}

export const putMoniNivelControle = async (cod, data, embed = 'item') => {
  const config = localConfig()
 

  api.defaults.headers.Acesso = config.acesso

  const response = api.put(`/monitoramento/nivel-controle/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed,
    },
  })

  return response
}

export const delMoniNivelControle = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.delete(`/monitoramento/nivel-controle/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
