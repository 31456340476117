import styled from 'styled-components'

export const Container = styled.div``

export const CampoEstatística = styled.div`
  width: 100%;
  height: 80%;
  font-size: 14px;
  box-sizing: border-box;
  padding: 10px;
  border: 1px solid #d9d9d9;
  font-weight: 400 !important;
  border-radius: 5px;

  p{
    margin: 5px 0;
  }
`
