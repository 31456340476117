import React, { useState } from 'react'

import {
  faAddressCard,
  faBarcode,
  faBook,
  faCalendarDay,
  faCalendarDays,
  faCar,
  faEye,
  faFile,
  faFolder,
  faGraduationCap,
  faIdBadge,
  faIdCard,
  faMapLocationDot,
  faUpload,
  faWallet,
} from '@fortawesome/free-solid-svg-icons'
import Input from '../../../CustomUi/input'
import ButtonCustom from '../../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { ContainerDocumentos } from './styled'
import { Upload } from 'antd/lib'
import { getEquipeFiles, TypesEquipe } from '../../../../services/Equipe'
import { Modal } from 'antd'
import Svg from '@ant-design/icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Select } from '../../../CustomUi/Select'
import { tiposReservistas } from '../../meta/index '
import { formatarData, getB64Type } from '../../../../services/Afins'
import { cpfMask } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

interface DocumentosProps {
  doc_cpf: any[]
  doc_rg: any[]
  doc_titulo: any[]
  doc_cnh: any[]
  doc_pis: any[]
  doc_reservista: any[]
  doc_diploma: any[]
  doc_certidao: any[]
  doc_ctps: any[]
}
interface FormDocs {
  cpf?: string
  setCpf?: React.Dispatch<React.SetStateAction<string>>
  rg?: string
  setRg?: React.Dispatch<React.SetStateAction<string>>
  ufRg?: string
  setUfRg?: React.Dispatch<React.SetStateAction<string>>
  diplomas?: string
  setDiplomas?: React.Dispatch<React.SetStateAction<string>>
  reservista?: string
  setReservista?: React.Dispatch<React.SetStateAction<string>>
  tipoReservista?: string
  tipoReservistaID?: number
  setTipoReservista?: React.Dispatch<React.SetStateAction<string>>
  setTipoReservistaID?: React.Dispatch<React.SetStateAction<number>>
  cnh?: string
  setCnh?: React.Dispatch<React.SetStateAction<string>>
  categoriaCnh?: string
  setCategoriaCnh?: React.Dispatch<React.SetStateAction<string>>
  validadeCnh?: string
  setValidadeCnh?: React.Dispatch<React.SetStateAction<string>>
  pis?: string
  setPis?: React.Dispatch<React.SetStateAction<string>>
  ctps?: string
  setCtps?: React.Dispatch<React.SetStateAction<string>>
  serieCTPS?: string
  setSerieCTPS?: React.Dispatch<React.SetStateAction<string>>
  documentos?: DocumentosProps
  setDocumentos?: React.Dispatch<React.SetStateAction<DocumentosProps>>
  permissions?: ItensPerUserLogged
}

const FormDocumentos: React.FC<FormDocs> = ({
  cpf,
  setCpf,
  rg,
  setRg,
  ufRg,
  setUfRg,
  diplomas,
  setDiplomas,
  reservista,
  setReservista,
  tipoReservista,
  tipoReservistaID,
  setTipoReservista,
  setTipoReservistaID,
  cnh,
  setCnh,
  categoriaCnh,
  setCategoriaCnh,
  validadeCnh,
  setValidadeCnh,
  pis,
  setPis,
  ctps,
  setCtps,
  serieCTPS,
  setSerieCTPS,
  documentos,
  setDocumentos,
  permissions
}) => {
  const [openTipoReservista, setOpenReservista] = useState(false)

  const onChangeDocuments = async (tipo, data: TypesEquipe = {} as TypesEquipe) => {
    if (tipo === null) {
      const usuario = data.itens[0].usuario[0]
      const codEquipe = data.itens[0].cod

      let temp = documentos
      Object.entries(usuario).forEach(async ([key, value]) => {
        const oldImg = String(value).split('_')

        if (oldImg.length <= 1) {
          if (
            key === 'doc_certidao' ||
            key === 'doc_pis' ||
            key === 'doc_reservista' ||
            key === 'doc_cpf' ||
            key === 'doc_rg' ||
            key === 'doc_cnh' ||
            key === 'doc_ctps' ||
            key === 'doc_sus' ||
            key === 'doc_titulo' ||
            key === 'doc_diploma'
          ) {
            if (value !== null) {
              const foto = await getEquipeFiles({
                doc: value,
                // @ts-ignore
                docType: key,
                cod: codEquipe,
              })

              temp = { ...temp, [key]: [foto.fileString] }
            }
          }
        }

        setDocumentos(temp)
      })
    } else {
      setDocumentos({ ...documentos, ...tipo })
    }
  }

  const onPreviewDocuments = async (file, type = null) => {
    let src = documentos?.[file]?.file
    if (type === null) type = documentos[file]?.file?.type

    if (src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(documentos[file]?.file?.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    } else {
      src = documentos[file]
      type = getB64Type(src)
    }

    switch (file) {
      case 'doc_cpf':
        file = 'CPF'
        break
      case 'doc_rg':
        file = 'Rg'
        break
      case 'doc_titulo':
        file = 'Título de Eleitor'
        break
      case 'doc_reservista':
        file = 'Reservista'
        break
      case 'doc_pis':
        file = 'Pis'
        break
      case 'doc_diploma':
        file = 'Diploma'
        break
      case 'doc_cnh':
        file = 'Carteira de Habilitação'
        break
      case 'doc_certidao':
        file = 'Certidão'
        break
      case 'doc_sus':
        file = 'Cartão SUS'
        break

      default:
        file = 'Documento'
        break
    }

    Modal.info({
      title: file,
      width: '1024px',
      style: { top: '10px' },
      okText: 'Fechar',
      icon: (
        <Svg
          component={() => (
            <FontAwesomeIcon
              icon={type === 'application/pdf' ? regular('file-pdf') : regular('image')}
            />
          )}
        />
      ),
      content: (
        <embed
          width={type === 'application/pdf' ? '100%' : null}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
            maxWidth: type !== 'application/pdf' ? '100%' : null,
          }}
          src={src}
        />
      ),
    })
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  return (
    <ContainerDocumentos>
      <div className='lines'>
        <div className='campo-input-upload'>
          <Input
            label='CPF'
            icon={faIdBadge}
            placeholder='000.000.000-00'
            value={cpfMask(cpf)}
            onChange={setCpf}
            width={'100%'}
            maxLength={14}
            obrigatorio
            desabilitado={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_cpf: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              disabled={documentos?.doc_rg === undefined || documentos?.doc_cpf?.length <= 0}
              type='secondary'
              onClick={() => onPreviewDocuments('doc_cpf')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
        <Input
          label='RG'
          icon={faIdCard}
          placeholder='Digite o RG'
          value={rg}
          onChange={setRg}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <div className='campo-input-upload'>
          <Input
            label='UF RG'
            icon={faMapLocationDot}
            placeholder='Digite o UF RG'
            value={ufRg}
            onChange={setUfRg}
            width={'100%'}
            desabilitado={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_rg: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentos?.doc_rg === undefined || documentos?.doc_rg?.length <= 0}
              onClick={() => onPreviewDocuments('doc_rg')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>
      <div className='lines'>
        <div className='campo-input-upload'>
          <Input
            label='Diplomas/Certificados'
            icon={faGraduationCap}
            placeholder='Diplomas / Certificados'
            value={diplomas}
            onChange={setDiplomas}
            width={'100%'}
            desabilitado={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_diploma: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentos?.doc_diploma === undefined || documentos?.doc_diploma?.length <= 0}
              onClick={() => onPreviewDocuments('doc_diploma')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>
      <div className='lines'>
        <Input
          label='Reservista'
          icon={faFile}
          placeholder='Digite a reservista'
          value={reservista}
          onChange={setReservista}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <div className='campo-input-upload'>
          <Select
            label='Tipo reservista'
            icon={faFolder}
            placeholder='Selecione o tipo reservista'
            value={tipoReservista}
            onChange={setTipoReservista}
            onChangeID={setTipoReservistaID}
            open={openTipoReservista}
            trigger={setOpenReservista}
            items={tiposReservistas}
            descricaoItem='nome'
            idItem='cod'
            disabled={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_reservista: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentos?.doc_reservista === undefined || documentos?.doc_reservista?.length <= 0}
              onClick={() => onPreviewDocuments('doc_reservista')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>

      <div className='lines'>
        <Input
          label='CNH'
          icon={faAddressCard}
          placeholder='CNH'
          value={cnh}
          onChange={setCnh}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Categoria CNH'
          icon={faCar}
          placeholder='Categoria CNH'
          value={categoriaCnh}
          onChange={setCategoriaCnh}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />

        <div className='campo-input-upload'>
          <Input
            label='Validade CNH'
            icon={faCalendarDays}
            placeholder='Validade CNH'
            value={formatarData(validadeCnh)}
            onChange={setValidadeCnh}
            width={'100%'}
            desabilitado={permissions?.acesso === 1}
            maxLength={10}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_cnh: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentos?.doc_cnh === undefined || documentos?.doc_cnh?.length <= 0}
              onClick={() => onPreviewDocuments('doc_cnh')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>

      <div className='lines'>
        <Input
          label='PIS'
          icon={faWallet}
          placeholder='Digite o PIS'
          value={pis}
          onChange={setPis}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='CTPS'
          icon={faBook}
          placeholder='Digite o CTPS'
          value={ctps}
          onChange={setCtps}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <div className='campo-input-upload'>
          <Input
            label='Série CTPS'
            icon={faBarcode}
            placeholder='Série CTPS'
            value={serieCTPS}
            onChange={setSerieCTPS}
            width={'100%'}
            desabilitado={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_pis: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentos?.doc_pis === undefined || documentos?.doc_pis?.length <= 0}
              onClick={() => onPreviewDocuments('doc_pis')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>
    </ContainerDocumentos>
  )
}

export default FormDocumentos