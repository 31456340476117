import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Modal, Space, Table } from 'antd'
import { Notification } from '../../../../Components/notification'

import FormFornecedor from '../../../../Components/fornecedor'

import { delFornecedor, getFornecedor } from '../../../../services/Fornecedores'

import { Container } from './styled'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

const OrderSer: React.FC = () => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [dataFornecedores, setDataForncedores] = useState([])
  const [visibleForm, setVisibleForm] = useState(false)
  const [updateUpdateFornecedor, setUpdateUpdateFornecedor] = useState<number>()

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const listarFornecedor = async () => {
    setLoadingTable(true)
    const response = await getFornecedor(1, '')

    setLoadingTable(false)

    setDataForncedores(response.data.itens)
  }

  const handleClickVisuFornecedor = async (data) => {
    setUpdateUpdateFornecedor(data)
    setVisibleForm(true)
  }

  const handleTrashFornecedor = async (data) => {
    const responsePro = await getFornecedor('', data)
    const nomePro = responsePro.data.itens[0].nome

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Fornecedor ${nomePro}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delFornecedor(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Fornecedor Excluido',
              type: 'success',
            })

            listarFornecedor()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateFornecedor(0)
  }

  const colum = [
    {
      key: 1,
      title: 'Nome Fornecedor',
      dataIndex: 'nome',
    },
    {
      title: 'Ações',
      dataIndex: 'cod',
      key: 'acao',
      render: (data) => (
        <Space>
          <Button
            shape='circle'
            hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
            onClick={() => handleClickVisuFornecedor(data)}
            icon={<FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
            size='middle'
          />
          <Button
            shape='circle'
            onClick={() => handleTrashFornecedor(data)}
            className='trash-button'
            type='primary'
            icon={<FontA icon={solid('trash')} />}
            hidden={permissions?.exclusao === 0}
            size='middle'
          />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    listarFornecedor()
  }, [])

  return (
    <Container>
      <ViewPage
        title='Fornecedores'
        rotina={52}
        onPermission={setPermissions}
        btnClick={handleVisibleForm}
        content={
          <FormFornecedor
          dataFornecedores={dataFornecedores}
            permissions={permissions}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            update={updateUpdateFornecedor}
            setUpdate={setUpdateUpdateFornecedor}
            atualizar={listarFornecedor}
          />
        }
      >
        <Table
          loading={loadingTable}
          pagination={pagination}
          dataSource={dataFornecedores}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataFornecedores.length}</b>
            </div>
          )}
          bordered
          size='small'
          columns={colum}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuFornecedor(record.cod)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default OrderSer
