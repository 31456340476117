import { filtrarTipoMapa } from "./index";
import { Item__MapaFertilidade__Config } from "../../../../../../../services/novoConfigBook/get";


// =============================================================================================>
    // ALTERAR VISIBILIDADE GERAL
// =============================================================================================>
//TYPES
type TypeAlterarVisibilidadeSecao = {
    info_elemento: Item__MapaFertilidade__Config,
    swt_visivel_no_book: boolean,
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>;
}

export const alterarVisibilidadeSecao = ({ info_elemento, swt_visivel_no_book, setInfo_elemento }: TypeAlterarVisibilidadeSecao) => {  
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));
    
    novoDados.secoes[0].visivel = !swt_visivel_no_book;   
    

    setInfo_elemento(novoDados ?? info_elemento);

}

// =============================================================================================>
    // ALTERAR OBSERVAÇÃO DO TIPO DE MAPA
// =============================================================================================>
//TYPES
type TypeAlterarObsMapa = {
    info_elemento: Item__MapaFertilidade__Config,
    tipo_mapa_atual: number, 
    obs_atual: string,
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>
}

export const alterarObservacaoMapa = ({ info_elemento, tipo_mapa_atual, obs_atual, setInfo_elemento }: TypeAlterarObsMapa) => {
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));
    
    novoDados.secoes[0].tipos_mapa[tipo_mapa_atual===1?0:1].observacao = obs_atual;     
    
    setInfo_elemento(novoDados ?? info_elemento);
};

// =============================================================================================>
    // ALTERAR OBSERVAÇÃO DA SEÇÃO (PRESCRIÇÃO)
// =============================================================================================>
//TYPES
type TypeAlterarObsSecao = {
    info_elemento: Item__MapaFertilidade__Config,
    obs_atual: string,
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>
}

export const alterarObservacaoSecao = ({ info_elemento, obs_atual, setInfo_elemento }: TypeAlterarObsSecao) => {
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));
    // console.log("obs: ", novoDados.secoes[0])
    novoDados.secoes[0].observacao = obs_atual;     
    
    setInfo_elemento(novoDados ?? info_elemento);
};


// =============================================================================================>
    // ALTERAR OBSERVAÇÃO DA PROFUNDIDADE
// =============================================================================================>
//TYPES
//TYPES
type TypeParamsProfundidade = {
    profundidade_nome: string,
    cod_profundidade: number,
    tipo_mapa_nome: string,
    cod_tipo_mapa: number,
    key: string
}
type TypeAlterarObsProfundidade = {
    info_elemento: Item__MapaFertilidade__Config,
    lista_params_profundidade: TypeParamsProfundidade[],
    tipo_mapa_atual: number, 
    tipo_de_mapa: 'Interpretado' | 'Distribuído', 
    obs_atual: string,
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>,
    profundidade_atual: number
}

export const alterarObservacaoProfundidade = ({ info_elemento, lista_params_profundidade, tipo_mapa_atual, 
    tipo_de_mapa, obs_atual, setInfo_elemento, profundidade_atual }: TypeAlterarObsProfundidade) => {
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));

    let lista_profundidades_filtrada = (lista_params_profundidade ?? [])?.filter(filtrarTipoMapa(tipo_de_mapa==='Interpretado'?1:2));
    let index_profundidade_atual = lista_profundidades_filtrada?.findIndex(objeto => objeto.cod_profundidade === profundidade_atual)
    
    novoDados.secoes[0].tipos_mapa[tipo_mapa_atual===1?0:1].profundidades[index_profundidade_atual].observacao = obs_atual;
    
    setInfo_elemento(novoDados ?? info_elemento);
};

// =============================================================================================>
    // ALTERAR VISIBILIDADE DO MAPA
// =============================================================================================>

type TypeAlterarVisibilidadeMapa = {
    info_elemento: Item__MapaFertilidade__Config,
    tipo_mapa_atual: number, 
    checkbox_visivel: boolean;
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>> 
}

export const alterarVisibilidadeMapa = ({ info_elemento, tipo_mapa_atual, checkbox_visivel, setInfo_elemento }: TypeAlterarVisibilidadeMapa) => {
        
    const novoDados = JSON.parse(JSON.stringify(info_elemento));
        
    novoDados.secoes[0].tipos_mapa[tipo_mapa_atual===1?0:1].visivel = !checkbox_visivel;
        
    setInfo_elemento(novoDados ?? info_elemento);
};

// =============================================================================================>
    // ALTERAR VISIBILIDADE DA PROFUNDIDADE
// =============================================================================================>

type TypeAlterarVisibilidadeProfundidade = {
    info_elemento: Item__MapaFertilidade__Config,
    lista_params_profundidade: TypeParamsProfundidade[],
    tipo_mapa_atual: number, 
    tipo_de_mapa: 'Interpretado' | 'Distribuído', 
    checkbox_visivel: boolean;
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>,
    profundidade_atual: number
}

export const alterarVisibilidadeProfundidade = ({ info_elemento, lista_params_profundidade, tipo_mapa_atual, tipo_de_mapa,
     profundidade_atual, checkbox_visivel, setInfo_elemento }: TypeAlterarVisibilidadeProfundidade) => {
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));

    // console.log("novoDados: ", novoDados)

    let lista_profundidades_filtrada = (lista_params_profundidade ?? [])?.filter(filtrarTipoMapa(tipo_de_mapa==='Interpretado'?1:2));
    let index_profundidade_atual = lista_profundidades_filtrada?.findIndex(objeto => objeto.cod_profundidade === profundidade_atual);
    
    novoDados.secoes[0].tipos_mapa[tipo_mapa_atual===1?0:1].profundidades[index_profundidade_atual].visivel = !checkbox_visivel;
    
    setInfo_elemento(novoDados ?? info_elemento);
};

// =============================================================================================>
    // ALTERAR VISIBILIDADE DA PROFUNDIDADE APENAS DO CALCÁRIO
// =============================================================================================>

type TypeAlterarVisibilidadeProfundidadeCalcario = {
    info_elemento: Item__MapaFertilidade__Config,
    lista_params_profundidade: TypeParamsProfundidade[],
    tipo_mapa_atual: number, 
    tipo_de_mapa: 'Interpretado' | 'Distribuído', 
    checkbox_visivel: boolean;
    setInfo_elemento: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config>>,
    profundidade_atual: number
}

export const alterarVisibilidadeProfundidadeCalcario = ({ info_elemento, lista_params_profundidade, tipo_mapa_atual, tipo_de_mapa,
     profundidade_atual, checkbox_visivel, setInfo_elemento }: TypeAlterarVisibilidadeProfundidadeCalcario) => {
            
    const novoDados = JSON.parse(JSON.stringify(info_elemento));

    // console.log("novoDados: ", novoDados)

    let lista_profundidades_filtrada = (lista_params_profundidade ?? [])?.filter(filtrarTipoMapa(2));
    let index_profundidade_atual = lista_profundidades_filtrada?.findIndex(objeto => objeto.cod_profundidade === profundidade_atual);

    // console.log("lista_profundidades_filtrada: ", lista_profundidades_filtrada);
    // console.log("index_profundidade_atual: ", index_profundidade_atual);
    
    novoDados.secoes[0].tipos_mapa[0].profundidades[index_profundidade_atual].visivel = !checkbox_visivel;
    
    setInfo_elemento(novoDados ?? info_elemento);
};

