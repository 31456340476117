import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  height: 100%;
  width: 70 vw;
  display: flex;
  flex-direction: column;
  gap: 8px;

  .active {
    position: absolute;
    transform: translateY(-100%);
    z-index: 9999;
  }
`

export const Header = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
`
export const HeaderTitle = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 14px;
  cursor: pointer;

  div {
    display: flex;
    button {
      background-color: #fafafa;
      border: none;
      transition: 0.5s;
      font-size: 18px;
      opacity: 0.8;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: #000000 !important;
        opacity: 0.5;
      }
    }

    div {
      display: flex;
      flex-direction: column;
      gap: 5px;

      span {
        font-weight: 700;
      }
      small {
        color: #b9b9b9;

        &:nth-child(3) {
          font-weight: 600;
          color: #cbcbcb;
        }
      }
    }
  }
`
export const HeaderContent = styled.div``

export const Body = styled.div<{ hideOverflow: boolean }>`
  position: relative;
  display: flex;
  overflow: ${p => p.hideOverflow ? 'hidden' : 'auto'};
  height: calc(100vh - 340px);
  flex-direction: column;
  width: 100%;
  border: 1px solid #dfdfdf;
  border-radius: 10px;
`

export const BodyTitle = styled.div`
  display: flex;
  flex-direction: column;
  
  small {
    opacity: 0.6;
    margin-left: 25px;
    padding: 0;
    margin-top: 0;
  }
`
export const BodyHeader = styled.div<{ open?: boolean }>`
  display: flex;
  width: 100%;
  flex-direction: row;
  justify-content: space-between;
  background-color: ${(p) => (p.open ? 'green' : '')};
  align-items: center;
  border-top: 1px solid #dfdfdf;
  padding: 18px;
  background-color: #fafafa;

  &:first-child {
    border-radius: 10px 10px 0 0;
  }

  &:nth-last-child(2) {
    border-radius: 0 0 10px 10px;
  }

  div {
    button {
      background-color: #fafafa;
      border: none;
      transition: 0.5s;
      font-size: 18px;
      opacity: 0.8;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: #000000 !important;
        opacity: 0.5;
      }
    }
  }

  cursor: pointer;
  transition: 0.5s;

  &:first-child {
    border-top: 0;
  }
`
export const BodyContent = styled.div<{ open: boolean }>`
  position: sticky;
  bottom: 0;
  width: 100%;
  height: 100%;
  transform: ${p => p.open ? 'translateY(0)' : 'translateY(-1200%)'};
  background-color: #ffffff;
  transition: 0.3s;
`

export const ContentHeader = styled.div`
  display: flex;
  padding: 18px;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  border-bottom: 1px solid #dfdfdf;
  border-radius: 0 0 10px 10px;
  height: 80px;
  background-color: #fafafa;
  margin-bottom: 12px;

  div {
    button {
      background-color: #fafafa;
      border: none;
      transition: 0.5s;
      font-size: 18px;
      opacity: 0.8;
      font-weight: 600;
      cursor: pointer;

      &:hover {
        color: #000000 !important;
        opacity: 0.5;
      }
    }
  }
`

export const Extra = styled.div`
  width: 50px;
  height: 50px;
`
