import styled from 'styled-components'

export const Container = styled.div`
  .map-content {
    position: absolute;
    width: 100%;

    .btn-edit-talhao {
      background-color: #ffffff;
      position: absolute;
      z-index: 99999;
      right: 0;

      margin: 15px;
      border-radius: 5px;
    }
  }
`
