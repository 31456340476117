import React, { useEffect, useRef, useState } from 'react'

import { GoogleMap, Polygon, Polyline } from '@react-google-maps/api'

import { Button, Card, Input, List, Space, Switch, Typography, Upload } from 'antd'

// @ts-ignore
import { kml } from '@tmcw/togeojson'

import Icon from '@ant-design/icons'

import { v4 as uid } from 'uuid'

import { UploadChangeParam } from 'antd/lib/upload'
import { UploadType } from 'antd/lib/upload/interface'
import { SvgPolyline } from '../../assets/svg'
import { centerMap, fileTypeText, parseXml } from '../../services/Afins'
import { TalhaoCultivaveisItens } from '../../services/TalhaoSafra'
import Svg from '../talhasvg'
import { Container } from './styled'

interface TypesLinhaPlanejada {
  onClose: (data: boolean) => void
  talhao: TalhaoCultivaveisItens
}

const LinhaPlanejada: React.FC<TypesLinhaPlanejada> = (props) => {
  const { onClose, talhao } = props

  const [polygonVisible, setPolygonVisible] = useState(true)

  const [centerMapa, setCenterMapa] = useState<google.maps.LatLngLiteral>()

  const [colorTalhao, setColorTalhao] = useState('#00FFFF')

  const [listLinhas, setListLinhas] = useState([])

  const [zoom, setZoom] = useState(2)

  const uploadFile = useRef<UploadType>()

  const handleVisiblePolygon = (data) => {
    setPolygonVisible(data)
  }

  const handleUploadKml = async (file: UploadChangeParam) => {
    if (file.file.status === 'done') {
      const domKml = parseXml(await fileTypeText(file.file.originFileObj))
      const dataGeoJson = kml(domKml)

      const newArray = {
        nome: null,
        ativo: true,
        fill: '#00FFFF',
        coord: [],
      }
      dataGeoJson.features.forEach((data) => {
        if (data.geometry.type === 'LineString' || data.geometry.type === 'Polyline') {
          let newLatLng = []
          data.geometry.coordinates.forEach((latLng) => {
            newLatLng = [...newLatLng, { lat: latLng[1], lng: latLng[0] }]
            return { newLatLng }
          })
          const temp = newLatLng

          newArray.coord = [...newArray.coord, temp]
        }

        return newArray
      })

      setListLinhas([...listLinhas, newArray])
    }
  }

  const handleColorTalhao = (data) => {
    setColorTalhao(data.target.value)
  }

  const handleColorLine = (data, i) => {
    const cor = data.target.value

    const dados = JSON.parse(JSON.stringify(listLinhas))

    dados[i].fill = cor

    setListLinhas(dados)
  }

  const handleActiveLinha = (status, i) => {
    const dados = JSON.parse(JSON.stringify(listLinhas))

    dados[i].ativo = status

    setListLinhas(dados)
  }

  const handleChangeNameTalhao = (record, index) => {
    const dados = JSON.parse(JSON.stringify(listLinhas))

    dados[index].nome = record

    setListLinhas(dados)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  useEffect(() => {
    // @ts-ignore
    setCenterMapa(centerMap(talhao?.kml?.coordenadas))
    setZoom(12)
  }, [talhao])

  return (
    <Container>
      <div className='container-floating'>
        <Card size='small' title='Talhão' extra={<></>}>
          <List itemLayout='vertical'>
            <List.Item
              style={{
                display: 'flex',
                justifyContent: 'start',
                alignItems: 'center',
              }}
              extra={
                <Icon
                  component={() => (
                    <Svg
                      fill={colorTalhao}
                      /* @ts-ignore */
                      coordinates={talhao?.kml?.coordenadas}
                    />
                  )}
                />
              }
            >
              <Space align='center'>
                <Switch
                  checked={polygonVisible}
                  checkedChildren='Sim'
                  unCheckedChildren='Não'
                  onChange={handleVisiblePolygon}
                />
                {talhao.nome}
                <Input
                  onChange={handleColorTalhao}
                  value={colorTalhao}
                  style={{ width: '50px' }}
                  type='color'
                />
              </Space>
            </List.Item>
          </List>
        </Card>
      </div>
      <div className='container-floating-line'>
        <Card
          size='small'
          title='Linhas'
          extra={
            <Upload
              accept='.kml'
              onChange={handleUploadKml}
              ref={uploadFile}
              showUploadList={false}
              maxCount={1}
              multiple={false}
              customRequest={dummyRequest}
            >
              <Button shape='round' size='small' type='primary'>
                Importar Linhas
              </Button>
            </Upload>
          }
        >
          <List itemLayout='vertical'>
            {listLinhas.map((data, i) => (
              <List.Item key={uid()} draggable>
                <Space>
                  <Icon component={() => <SvgPolyline fill={data.fill} stroke={data.fill} />} />

                  <Typography.Text
                    editable={{
                      onChange: (record) => handleChangeNameTalhao(record, i),
                    }}
                  >
                    {data.nome === null ? 'Sem Nome' : data.nome}
                  </Typography.Text>

                  <Input
                    type='color'
                    onChange={(e) => handleColorLine(e, i)}
                    style={{ width: '50px' }}
                    value={data.fill}
                  />
                  <Switch
                    checked={data.ativo}
                    onChange={(data) => handleActiveLinha(data, i)}
                    unCheckedChildren='Não'
                    checkedChildren='Sim'
                  />
                </Space>
              </List.Item>
            ))}
          </List>
        </Card>
        <div className='container-floating-btn'>
          <Button type='primary'>Salvar</Button>
          <Button onClick={() => onClose(false)} type='primary' danger>
            Cancelar
          </Button>
        </div>
      </div>
      {/* @ts-ignore */}
      <GoogleMap
        center={centerMapa}
        zoom={zoom}
        mapContainerStyle={{
          width: '100%',
          height: '90vh',
        }}
        options={{
          mapTypeId: 'hybrid',
          streetViewControl: false,
          fullscreenControl: false,
          mapTypeControl: false,
          zoomControl: false,
        }}
      >
        {listLinhas.map((list) => {
          return list.coord.map((line) => (
            <Polyline
              path={line}
              visible={list.ativo}
              options={{
                strokeColor: list.fill,
                strokeOpacity: 0.8,
              }}
            />
          ))
        })}
        <Polygon
          open={polygonVisible}
          /* @ts-ignore */
          paths={talhao?.kml?.coordenadas}
          options={{ strokeColor: colorTalhao, fillOpacity: 0 }}
        />
      </GoogleMap>
    </Container>
  )
}

export default LinhaPlanejada
