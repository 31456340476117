import styled from 'styled-components'

export const HeaderNavagacao = styled.div`
  width: 100%;
  display: flex;
  align-items: center;
`

export const HeaderGaveta = styled.div`
  width: 100%;
  padding: 15px 20px;
  display: inline-flex;
  flex-direction: column;
  border-bottom: 1px solid #d9d9d9;

  h2.HeaderGaveta__titulo {
    font-size: 18px;
    font-weight: 700;
    color: var(--text-color);
  }

  div.HeaderGaveta__tags {
    display: flex;
    gap: 5px;
  }
`

export const SwitchSecao = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #d9d9d9;
  display: inline-flex;
  flex-direction: column;
  gap: 5px;

  h3.SwitchSecao__titulo {
    color: var(--text-color-light);
    font-size: 12px;
    font-weight: 600;
    display: flex;
  }

  div.SwitchSecao__switch {
    display: flex;
    align-items: center;
    gap: 5px;
    
    p.SwitchSecao__switch__label {
      margin: 0px;
      font-size: 13px;
      font-weight: 400;
      color: var(--text-color);
    }
  }
`

export const Representacao = styled.div`
  width: 100%;
  padding: 15px 20px;
  border-bottom: 1px solid #d9d9d9;
  transition: .4s;

  &&:is([data-desabilitar = true]) {
	opacity: 0.4;
  }

  .Representacao__titulo{
    color: var(--text-color-light);
    font-size: 12px;
    font-weight: 600;
  }

`

export const CampoTabs = styled.div`
  width: 100%;
  height: calc(100% - 315px);
  box-sizing: border-box;
  padding: 20px;
  display: inline-flex;
  flex-direction: column;
  transition: .4s;

	&&:is([data-desabilitar = true]) {
		opacity: 0.4;
	}

    h3.CampoTabs__titulo {
      color: var(--text-color-light);
      font-size: 12px;
      font-weight: 600;
      display: flex;
    }
`

export const CampoObs = styled.div`
  width: 100%;
  box-sizing: border-box;
  padding: 12px 17px;
  border: 1px solid #d9d9d9;
  border-bottom-left-radius: 5px;
  border-bottom-right-radius: 5px;
  border-top-right-radius: 5px;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .camp__checkbox {
    width: 100%;
    display: flex;
    align-items: center;
  }

  .title__checkbox{
    font-size: 13px;
    font-weight: 400;
    margin: 0;
    display: flex;
  }

  .camp__obs{
    width: 100%;
  }

  .title__obs{
    margin: 0;
    font-size: 12px;
    font-weight: 500;
  }

  .lenght__text{
    width: 100%;
    display: flex;
    justify-content: flex-end;
    color: var(--text-color);
  }
  span.lenght__text__bold {
    font-weight: 700;
  }

  .number__total{
    margin: 0;
    font-size: 10px;
  }
`

export const ObsIndicadores = styled.div`
    display: flex;
    flex-direction: column;
    gap: 15px;

    .lenght__text{
        width: 100%;
        display: flex;
        justify-content: flex-end;
        color: var(--text-color);
    }
    span.lenght__text__bold {
        font-weight: 700;
    }

    .number__total{
        margin: 0;
        font-size: 10px;
    }

`


export const Footer = styled.div`
	width: 100%;
	border-top: thin solid var(--border-color);
	display: flex;
	align-items: center;
	box-sizing: border-box;
	padding: 15px 20px;
`
