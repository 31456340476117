import { CheckOutlined, CloseOutlined } from '@ant-design/icons'
import { faCheck, faCircleInfo, faListCheck, faWarning } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Checkbox,
  Divider,
  Drawer,
  Form,
  Input,
  Modal,
  Space,
  Switch,
  Tabs,
  Tooltip,
  Typography,
  message,
} from 'antd'
import { useEffect, useState } from 'react'
import { tiposSistema, tryError } from '../../../services/Afins'
import { ItensPerfilTypes, PermissoesType, postPerfil, putPerfil } from '../../../services/perfil'
import { ItensRotinaTypes, getRotina } from '../../../services/rotina'
import Icons from '../../Icons'
import { Notification } from '../../notification'
import { Container, SwitchIco } from './styles'
import DiasDesdeData from '../../DiasDesdeData'
import { gerarKey } from '../../../utils'
import { localConfig } from '../../../Configs/localConfig'
import { useHistory } from 'react-router'
import ButtonCustom from '../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
type Props = {
  onClose?: (data: boolean) => void
  update: ItensPerfilTypes
  visible: boolean
  onFinish?: () => void
  onModulo?: Function
}

const Perfil = (props: Props) => {
  const { visible, onClose, update, onFinish, onModulo } = props

  const history = useHistory()

  const [rotinas, setRotinas] = useState<ItensRotinaTypes[]>([])
  const [accessRotinas, setAccessRotinas] = useState<ItensPerfilTypes>({} as ItensPerfilTypes)
  const [valueSearch, setValueSearch] = useState('')
  const [checkAccessAll, setCheckAccessAll] = useState(false)
  const [checkAccessConsulta, setCheckAccessConsulta] = useState(false)
  const [possuiModulo, setPossuiModulo] = useState(true)
  const [modulos, setModulos] = useState<
    {
      cod: number
      cod_tipo_geral: number
      nome: string
    }[]
  >([])

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  // const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null);
  const [modalAvisoDeslogar, setModalAvisoDeslogar] = useState<boolean>(false);

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const [form] = Form.useForm()

  const handleChangeCheckBox = (
    rotina,
    campo: 'acesso' | 'alteracao' | 'exclusao' | 'inclusao',
    check: boolean
  ) => {
    const dados = { ...accessRotinas }

    const index = dados.permissoes.findIndex((rec) => rotina.cod === rec.cod_rotina)

    if (index === -1) {
      // Crie um novo objeto para permissões
      const novaPermissao = {
        alteracao: 0,
        acesso: 0,
        exclusao: 0,
        inclusao: 0,
        rotina_nome: rotina.nome,
        cod_rotina: rotina.cod,
      }

      // Defina as permissões com base no valor de check
      novaPermissao[campo] = check ? 1 : 0

      // Adicione o novo objeto à matriz de permissões
      dados.permissoes.push(novaPermissao)
    } else {
      // Se index não for -1, apenas atualize as permissões existentes
      dados.permissoes[index][campo] = check ? 1 : 0
    }

    // Atualize os dados de acesso às rotinas
    setAccessRotinas(dados)
  }

  const handleCheckAllCard = (rotina) => {
    const dados = { ...accessRotinas }

    const index = dados.permissoes.findIndex((rec) => rotina.cod === rec.cod_rotina)
    const premissao_comp = dados.permissoes.find((rec) => rotina.cod === rec.cod_rotina)

    const base = dados.permissoes[index]?.inclusao === 1 ? 0 : 1

    function marcarConsulta(): 0 | 1 {
      
      let nao_tem_inclusao = rotina.inclusao === 0;
      let nao_tem_alteracao = rotina.alteracao === 0;
      let nao_tem_exclusao = rotina.exclusao === 0;

      if ( nao_tem_inclusao && nao_tem_alteracao && nao_tem_exclusao && premissao_comp.acesso === 1 ) {
        return 0;
      } 
      else if ( nao_tem_inclusao && nao_tem_alteracao && nao_tem_exclusao && premissao_comp.acesso === 0 ) {
        return 1;
      }
      else if ( !nao_tem_inclusao && !nao_tem_alteracao && !nao_tem_exclusao && premissao_comp.acesso === 1 ) {
        return 0;
      }
      else if ( !nao_tem_inclusao && !nao_tem_alteracao && !nao_tem_exclusao && premissao_comp.acesso === 0 ) {
        return 0;
        // return 1;
      }
      return premissao_comp.acesso as 0 | 1
    }

    if (index === -1) {

      // Se o índice for -1, crie um novo objeto para a nova rotina
      const novaPermissao = {
        inclusao: base,
        alteracao: base,
        exclusao: base,
        acesso: 0, //via de regra ao `marcar todos`, a consulta não deve ser marcada. (ñ tenho certeza se regra está correta)
        rotina_nome: rotina.nome,
        cod_rotina: rotina.cod,
      }
      
      // Adicione o novo objeto à matriz de permissões
      dados.permissoes.push(novaPermissao)
    } else {

      // Se o índice não for -1, atualize as permissões existentes
      dados.permissoes[index].inclusao = rotina.inclusao !== 0 ? base : 0
      dados.permissoes[index].alteracao = rotina.alteracao !== 0 ? base : 0
      dados.permissoes[index].exclusao = rotina.exclusao !== 0 ? base : 0
      // dados.permissoes[index].acesso = todos === true ? 1 : 0
      dados.permissoes[index].acesso = marcarConsulta();
    }

    setAccessRotinas(dados)
  }

  const handleOnFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)
      const dados = { ...accessRotinas }

      let remover_rotina = []

      const p = dados.permissoes.filter((rec) => {
        const rotina = rotinas.find((item) => item.cod === rec.cod_rotina)
        let liberadoRotinas: any = { acesso: rec.acesso, cod_rotina: rec.cod_rotina }

        if (rotina == undefined) {
          if (!remover_rotina.includes(rec.cod_rotina)) {
            remover_rotina = [...remover_rotina, rec.cod_rotina]
          }
        } else {
          if (rotina?.alteracao === 1) {
            liberadoRotinas.alteracao = rec.alteracao
          }
          if (rotina?.inclusao === 1) {
            liberadoRotinas.inclusao = rec.inclusao
          }
          if (rotina?.exclusao === 1) {
            liberadoRotinas.exclusao = rec.exclusao
          }

          return {
            ...liberadoRotinas,
          }
        }
      })

      const rotinaFilter = {
        descricao: form.getFieldValue('descricao'),
        nome: form.getFieldValue('nome'),
        status: 1,
        permissoes: p,
      }

      if (remover_rotina.length > 0) {
        rotinaFilter['permissoes_remover'] = remover_rotina
      }

      if (!update.cod) {
        const resp = await postPerfil(rotinaFilter)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Perfil criado com sucesso',
          })
          setAccessRotinas({} as ItensPerfilTypes)
          form.resetFields()
          message.destroy()
          onFinish?.()
        }
      } else {
        const resp = await putPerfil(rotinaFilter, update.cod)
        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Perfil atualizado com sucesso',
          })
          message.destroy()
          onFinish?.()
        }
        if (resp?.data?.itens?.[0]?.cod === Number(localConfig()?.cod_perfil)) {
          setTimeout(() => {
            setModalAvisoDeslogar(true);
          }, 500);
            
        }
      }
    } catch (error) {
      tryError(error)
    }
  }
  //FUNÇÃO QUE MARCADA TODOS OS CHECK MENOS O ACESSO
  const handleCheckAll = (check: boolean) => {
    setCheckAccessAll(check)
    handleCheckAllAcesso(false)
    const dados = { ...accessRotinas }
    const pe = []

    const result = dados?.permissoes.filter((item) => {
      let fixedItem = rotinas.find((fixedItem) => fixedItem.cod === item.cod_rotina)
      if (
        fixedItem.acesso === 1 &&
        fixedItem.alteracao !== 1 &&
        fixedItem.exclusao !== 1 &&
        fixedItem.inclusao !== 1
      ) {
        const teste = dados.permissoes
          .filter((permi) => permi.cod_rotina === fixedItem.cod)
          .map((t) => {
            if (check) {
              item.acesso = 1
            } else {
              item.acesso = 0
              item.alteracao = 0
              item.exclusao = 0
              item.inclusao = 0
            }
          })
      } else {
        const teste = dados.permissoes
          .filter((permi) => permi.cod_rotina === fixedItem.cod)
          .map((t) => {
            if (check) {
              item.alteracao = 1
              item.exclusao = 1
              item.inclusao = 1
            } else {
              item.alteracao = 0
              item.exclusao = 0
              item.inclusao = 0
            }
          })
      }
    })

    setAccessRotinas(dados)
  }

  const handleCheckAllAcesso = (check) => {
    setCheckAccessConsulta(check)
    const dados = { ...accessRotinas }

  

    const p = dados.permissoes.map((rec) => {
      if (check) {
        setCheckAccessAll(false)
        rec.alteracao = 0
        rec.inclusao = 0
        rec.exclusao = 0
        rec.acesso = 1
      } else {
        rec.acesso = 0
      }
      return rec
    })

    dados.permissoes = p

    setAccessRotinas(dados)
  }

  const handleCloseDrawer = () => {
    onClose?.(false)
  }

  const listInit = async () => {
    let arrayRotinas = []
    let codModulos = []

    const [routines, mod] = await Promise.all([
      getRotina({}),
      tiposSistema(37, { codCliente: localStorage.getItem('cod-cliente') }),
    ])

    const dados = routines.data.itens

    mod.forEach((item) => {
      codModulos = [...codModulos, item.cod]
    })

    dados.forEach((itemRotina) => {
      codModulos.forEach((item) => {
        if (item == itemRotina.cod_modulo) {
          arrayRotinas = [...arrayRotinas, itemRotina]
        }
      })
    })

    /* function que passa um evento para o componente perfis, so sera chamada caso o modulo seja vazio
    seta um estado false caso nao exista modulo liberados
    
    */

    function handlePossuiModulo() {
      if (possuiModulo) {
        setPossuiModulo(false)
        onModulo(possuiModulo)
      }

      Notification({
        type: 'warning',
        message: 'Aviso',
        descricao: 'Sem módulos liberados.',
      })
    }

    if (mod.length == 0) {
      handlePossuiModulo()
    }

    setRotinas(arrayRotinas)
    setModulos(mod)
  }

  const handleInputChange = (event) => {
    const inputText = event.target.value
    setValueSearch(inputText)
  }

  const hiddenOrShow = (rotina: ItensRotinaTypes, access: PermissoesType) => {
    const bo = []
    if (rotina?.alteracao === 1 && access?.alteracao === 1) {
      bo.push(true)
    } else {
      if (rotina?.alteracao !== 0) {
        bo.push(false)
      }
    }
    if (rotina?.inclusao === 1 && access?.inclusao === 1) {
      bo.push(true)
    } else {
      if (rotina?.inclusao !== 0) {
        bo.push(false)
      }
    }
    if (rotina?.exclusao === 1 && access?.exclusao === 1) {
      bo.push(true)
    } else {
      if (rotina?.exclusao !== 0) {
        bo.push(false)
      }
    }

    const re = bo.filter((b) => b === true)
    return re.length > 0
  }

  useEffect(() => {
    if (update.cod) {
      form.setFieldsValue({ nome: update.nome, descricao: update.descricao })
      setAccessRotinas(update)
    } else {
      form.setFieldsValue({ nome: '', descricao: '' })

      setAccessRotinas({
        nome: '',
        descricao: '',
        status: 1,
        permissoes: rotinas.map((rec) => {
          return {
            acesso: 0,
            alteracao: 0,
            cod_rotina: rec.cod,
            exclusao: 0,
            inclusao: 0,
            rotina_nome: rec.nome,
          }
        }),
      })
    }
  }, [update])

  useEffect(() => {
    if (accessRotinas?.permissoes?.length <= 0) {
      setAccessRotinas({
        nome: '',
        descricao: '',
        status: 1,
        permissoes: rotinas.map((rec) => {
          return {
            acesso: 0,
            alteracao: 0,
            cod_rotina: rec.cod,
            exclusao: 0,
            inclusao: 0,
            rotina_nome: rec.nome,
          }
        }),
      })
    }
  }, [rotinas])

  useEffect(() => {
    listInit()
  }, [])

  useEffect(() => {
    if (visible) {

      if (update) {
        if (update.create_user) {
          setUsuarioQueCriou(update.create_user)

          // const dateOnlyString = update.create_time.split(' ')[0]
          const timeOnlyString = update.create_time.split(' ')[1]

          // setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (update.update_user) {
          setUsuarioQueAlterou(update.update_user)

          const dateOnlyString = update.update_time.split(' ')[0]
          const timeOnlyString = update.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

  }, [logUser, visible, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao]);

  function quandoFoiCriado(diasDesdeCriacao: number|null, horarioDeCriacao:string): string {
    if (diasDesdeCriacao !== null && diasDesdeCriacao !== 0) {
      return `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`;
    }
    return `às ${horarioDeCriacao}h`;
  }

  return (
    <Container>
      <Drawer
        forceRender
        style={{ position: 'absolute' }}
        bodyStyle={{ padding: 0 }}
        placement='right'
        width='100%'
        open={visible}
        closeIcon={false}
        getContainer={false}
        onClose={() => handleCloseDrawer()}
        footer={
          <Space style={{ display: 'flex', justifyContent: 'space-between' }}>
            <Space>
              <Button onClick={handleOnFinish} type='primary'>
                {update.cod ? 'Atualizar' : 'Salvar'}
              </Button>
              <Button
                danger
                onClick={() => handleCloseDrawer()}
                type='primary'
                style={{ marginRight: 8 }}
              >
                Fechar
              </Button>
            </Space>

            <Space className='log-user'>
              {logUser && update.cod > 0 && usuarioQueAlterou !== '' ? (
                <>
                  <DiasDesdeData
                    dataCriacao={dataAlteracao}
                    onDataFromCreation={handleDataFromCreation}
                  />
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    atualizado por: {usuarioQueAlterou}{' '}
                    {quandoFoiCriado(diasDesdeCriacao, horarioDeAtualizacao)}
                  </small>
                </>
              ) : !logUser && update.cod > 0 && (
                <>
                  {' '}
                  {usuarioQueCriou === '' ? null : (
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      criado por: {usuarioQueCriou}{' '}
                      {quandoFoiCriado(diasDesdeCriacao, horarioDeCriacao)}
                    </small>
                  )}
                </>
              )}
            </Space>
          </Space>
        }
      >
        <Form size='small' layout='inline' form={form} onFinish={handleOnFinish}>
          <Tabs type='card' tabPosition='left'>
            {modulos.map((rec, index) => {
              const rotina = rotinas.filter((item) => item.cod_modulo === rec.cod)

              const filtered = rotinas.filter((item) =>
                item.nome.toLowerCase().includes(valueSearch.toLowerCase())
              )

              const filteredRotina = valueSearch.length > 0 ? filtered : rotina

              return (
                <Tabs.TabPane key={rec.cod} tabKey={String(rec.cod)} tab={rec.nome}>
                  <Form.Item name='status' initialValue={1} noStyle />
                  <Card
                    className='card-pai'
                    style={{ width: 'calc(100vw - 480px)', borderLeft: 'none' }}
                    bodyStyle={{ height: 'calc(100vh - 198px)', overflow: 'auto' }}
                    title={
                      <Space>
                        <Input.Search
                          value={valueSearch}
                          placeholder='Pesquisar'
                          onChange={(e) => handleInputChange(e)}
                        />
                      </Space>
                    }
                    extra={
                      <Space>
                        <span>Acesso total</span>
                        <Switch
                          checked={checkAccessAll}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                          onChange={handleCheckAll}
                        />
                        <Divider type='vertical' />
                        <span>Apenas consulta</span>
                        <Switch
                          size='small'
                          checked={checkAccessConsulta}
                          onChange={handleCheckAllAcesso}
                          checkedChildren={<CheckOutlined />}
                          unCheckedChildren={<CloseOutlined />}
                        />
                      </Space>
                    }
                  >
                    <Space
                      style={{ width: '100%', display: 'flex', flexDirection: 'column' }}
                      styles={{ item: { width: '100%' } }}
                      align='start'
                    >
                      <Form.Item rootClassName='input-text' name='nome' label='Nome do Perfil'>
                        <Input style={{ maxWidth: '50%' }} />
                      </Form.Item>
                      <Form.Item rootClassName='input-text' name='descricao' label='Descrição'>
                        <Input.TextArea style={{ maxWidth: '50%' }} />
                      </Form.Item>
                    </Space>
                    {filteredRotina.map((item, i) => {
                      const access = accessRotinas?.permissoes?.find(
                        (ac) => item.cod === ac.cod_rotina
                      )
                      // const indexAccess = accessRotinas?.permissoes?.findIndex(
                      //   (ac) => item.cod === ac.cod_rotina
                      // )

                      const disa = hiddenOrShow(item, access)

                      return (
                        <Space style={{ padding: 5 }} key={gerarKey(index)}>
                          <Card
                            extra={
                              <Space>
                                <Tooltip trigger='hover' title={item.descricao}>
                                  {item.descricao !== null && item.descricao.length > 0 ? (
                                    <div style={{ cursor: 'pointer' }}>
                                      <Icons color='#507fff' icon={faCircleInfo} />
                                    </div>
                                  ) : null}
                                </Tooltip>
                                <SwitchIco>
                                  <Icons icon={faListCheck} />
                                  <input
                                    type='checkbox'
                                    onClick={(e) => handleCheckAllCard(item)}
                                  />
                                </SwitchIco>
                              </Space>
                            }
                            style={{ width: '364.18px' }}
                            headStyle={{
                              backgroundColor: '#f1f1f1',
                            }}
                            bodyStyle={{ height: '78.15px', backgroundColor: '#fcfcfc' }}
                            size='small'
                            title={
                              <>
                                {process.env.NODE_ENV === 'development' ? item?.cod_rotina : null}
                                {item?.nome.length > 20 ? (
                                  <Tooltip trigger='hover' title={item?.nome}>
                                    <Typography.Text
                                      editable={
                                        process.env.NODE_ENV === 'development'
                                          ? {
                                              onChange(value) {
                                                //
                                              },
                                            }
                                          : false
                                      }
                                    >
                                      {item.cod} {''}
                                      {item.nome.length > 35
                                        ? item.nome.substring(0, 36) + '...'
                                        : item.nome}
                                    </Typography.Text>
                                  </Tooltip>
                                ) : (
                                  <Typography.Text>
                                    {item?.cod} {item?.nome}
                                  </Typography.Text>
                                )}
                              </>
                            }
                          >
                            <div
                              style={{ display: 'flex', width: '100%', flexWrap: 'wrap', gap: 5 }}
                            >
                              <Form.Item
                                hidden={item.acesso === 0}
                                className='form-item'
                                label='Apenas Consulta'
                              >
                                <Checkbox
                                  checked={access?.acesso === 1}
                                  disabled={disa}
                                  onChange={(data) =>
                                    handleChangeCheckBox(item, 'acesso', data.target.checked)
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                hidden={item.inclusao === 0}
                                className='form-item'
                                label='Inclusão'
                              >
                                <Checkbox
                                  checked={access?.inclusao === 1}
                                  disabled={access?.acesso === 1}
                                  onChange={(data) =>
                                    handleChangeCheckBox(item, 'inclusao', data.target.checked)
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                hidden={item.alteracao === 0}
                                className='form-item'
                                label='Alteração'
                              >
                                <Checkbox
                                  checked={access?.alteracao === 1}
                                  disabled={access?.acesso === 1}
                                  onChange={(data) =>
                                    handleChangeCheckBox(item, 'alteracao', data.target.checked)
                                  }
                                />
                              </Form.Item>
                              <Form.Item
                                hidden={item.exclusao === 0}
                                className='form-item'
                                label='Exclusão'
                              >
                                <Checkbox
                                  checked={access?.exclusao === 1}
                                  disabled={access?.acesso === 1}
                                  onChange={(data) =>
                                    handleChangeCheckBox(item, 'exclusao', data.target.checked)
                                  }
                                />
                              </Form.Item>
                            </div>
                          </Card>
                        </Space>
                      )
                    })}
                  </Card>
                </Tabs.TabPane>
              )
            })}
          </Tabs>
        </Form>
      </Drawer>

      <Modal
        width={550}
        open={modalAvisoDeslogar}
        title={<><i style={{color: '#faad14'}}><FontAwesomeIcon icon={faWarning}/></i>  Será necessário refazer o login para aplicar as alterações!</>}
        footer={false}
        closable={false}
      >
        Deseja refazer o login agora?
        <div style={{display:'flex',justifyContent:'flex-end', gap:15, marginTop:15}}>
          <ButtonCustom 
            type='tertiary' 
            danger 
            onClick={()=>{setModalAvisoDeslogar(false);}}
          >
              Mais tarde
          </ButtonCustom>
          <Button 
            type='primary' 
            onClick={()=>{setModalAvisoDeslogar(false);history.push('/logout')}}
            icon={<FontAwesomeIcon icon={faCheck} />}
          >
            Sim, refazer
          </Button>
        </div>
      </Modal>
    </Container>
  )
}

export default Perfil