
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetlaboratorio {
  itens: [LabAnaliseItens]
}

export interface LabAnaliseItens {
  cod_laboratorio?: number
  cod_analise?: number
  cod_categoria_fornecedor?: number
  laboratorio_nome?: string
  analise_nome?: string
  profundidade_nome?: string
  cod_profundidade?: number
  data?: string
  valor?: string
  analises?: {
    profundidade?: {
      nome?: string
      cod?: number
    }
    analises?: [
      {
        cod?: number
        nome?: string
      },
      {
        cod?: number
        nome?: string
      }
    ]
  }
}

export const getLabAnalise = async (cod, codLaboratorio) => {
  const config = localConfig()

  const dataDia = new Date()
  const dia = dataDia.getDate()
  const mes = dataDia.getMonth() + 1
  const ano = dataDia.getFullYear()

  const dataHoje = `${ano}-${mes}-${dia}`

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetlaboratorio> = await api.get(
    `/laboratorio-analise/analises?codConta=${config.conta}&codLaboratorio=${codLaboratorio}&cod=${cod}&dataVigente=${dataHoje}&pagination=-1`
  )

  return response
}
