import styled from 'styled-components'

export const ContainerModal = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .campo-link {
    width: 100%;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    gap: 10px;
    border-radius: 5px;
    color: black;

    .icone-agrupamento {
      background-color: #5da47d;
      color: white;
      font-size: 20px;
      padding: 10px;
      border-radius: 5px;
    }
  }

  .campo-link:hover {
    background-color: #d5ffe7;
  }
`
