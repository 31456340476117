import { ItemAtivoCadastrado } from "../../../../../../../../services/recomendacao/ativos/novo-index";
import { obterNomeObersavacao } from "../../../gavetaCadastrar/components/observacoes/meta";
import { isAbaCultura, isCulturaOuEstadio, obterNomeLancamento, obterValorLancamento, obterValorLancamentoSemCultura } from "../../../../meta";

//PROPS
interface Props {
    item: ItemAtivoCadastrado;
    id_tipo_principal: number;
    isObservacao?: boolean;
    isPlantback?: boolean;
}

/**Retorna uma tag `span` com o `nome` e `tipo` de item cadastrado */
export const ZLabel: React.FC<Props> = ({ item, id_tipo_principal, isObservacao, isPlantback }) => {

    const { item_lancamento, lancamento, cultura_lancamento, cultura_item_lancamento } = item;

    const is_cultura_ou_estadio = isCulturaOuEstadio(item_lancamento, lancamento);
    const is_aba_cultura = isAbaCultura(item_lancamento, lancamento, id_tipo_principal);

    if(is_cultura_ou_estadio && !is_aba_cultura) {
        let prop_lancamento = item_lancamento === 6 || item_lancamento === 7 ? item_lancamento : lancamento;
        return (
            <span>
                <span style={{color:'var(--text-color-light)'}}>{obterNomeLancamento(prop_lancamento)}</span>
                
                {!isPlantback &&<>
                    {' 》 '}
                    <b><i>({cultura_item_lancamento ?? cultura_lancamento})</i></b>
                </>}
                
                {' 》 '}
                <b>{item?.[obterValorLancamento(item.lancamento, id_tipo_principal, isCulturaOuEstadio(item?.item_lancamento, 0))]}</b> 

                {isObservacao &&<>
                    {' 》 '}
                    <i>{obterNomeObersavacao(item?.cod_observacao)}</i>
                </>}
            </span>
        )
    }

    if(is_cultura_ou_estadio && is_aba_cultura) {
        let prop_lancamento = item_lancamento === 6 || item_lancamento === 7 ? lancamento : item_lancamento;
        return (
            
            <span>
                <span style={{color:'var(--text-color-light)'}}>{obterNomeLancamento(prop_lancamento)}</span>
               
                
                {' 》 '}
                <b>{item?.[obterValorLancamento(item.lancamento, id_tipo_principal, isCulturaOuEstadio(item?.lancamento, 0))]}</b>  

                {!isPlantback &&<>
                    {' ║ '}
                    <span style={{color:'var(--text-color-light)'}}>{'Estádio'}</span>
                    {' 》 '}
                    <b><i>({item?.[obterValorLancamento(item.lancamento, id_tipo_principal, isCulturaOuEstadio(item?.item_lancamento, 0))]})</i></b>
                </>}

                {isObservacao &&<>
                    {' 》 '}
                    <i>{obterNomeObersavacao(item?.cod_observacao)}</i>
                </>}
            </span>
        )
    }

    let prop_lancamento = (id_tipo_principal === item.item_lancamento ) ? lancamento : item_lancamento;
    return (
        <span>
            <span style={{color:'var(--text-color-light)'}}>{obterNomeLancamento(prop_lancamento)}</span>
            
            {' 》 '}
            <b>{item?.[obterValorLancamentoSemCultura(item.lancamento, id_tipo_principal)]}</b> 
            
        </span>
    )
    
}
