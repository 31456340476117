import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TituloBook } from "./styled";
import { faClipboardCheck, faGripVertical } from "@fortawesome/free-solid-svg-icons";

//PROPS
interface Props {
    nome_elemento: string;
    profundidade?: string; 
    tipo_de_mapa: string;
    id: string;
}

const TituloPagina: React.FC<Props> = ({ nome_elemento, profundidade, tipo_de_mapa, id }) => {

    function usaCentimetros() {
        return (profundidade!=='micro' && profundidade!=='indicadores' && profundidade!=='prescricoes') ? 'cm' : ''
    }

    return (
        <TituloBook id={id}>
            <h2 className="TituloPagina__titulo" data-reduced={nome_elemento?.length >= 40}>
                {nome_elemento}
            </h2>

            <div className="TituloPagina__sub">
                <p className="TituloPagina__sub__profundidade">
                    {String(profundidade)?.replace("prescricoes", 'prescrições')} {usaCentimetros()} {profundidade!=='prescricoes' ? '|' : null}
                </p>

                {profundidade!=='prescricoes' &&(
                    <p className="TituloPagina__sub__mapa">
                        {tipo_de_mapa}
                        <FontAwesomeIcon icon={tipo_de_mapa==='interpretado' ? faClipboardCheck : faGripVertical} />
                    </p>
                )}
                
            </div>
        </TituloBook>
    )
};


export default TituloPagina;