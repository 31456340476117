
import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { tryError } from '../../Afins'
import { api } from '../../api'

export interface TypesTipoTechnician {
  itens: ItensTipoTechnicianTypes[]
}

export interface ItensTipoTechnicianTypes {
  cod?: number
  cod_conta?: number
  cod_usuario?: number
  tipo?: number
  usuario_nome: string
  tipo_nome: string
  usuario: [
    {
      cod?: number
      nome: string
      email: string
    }
  ]
}

type TypesQuery = {
  cod?: number
  embed?: 'permissoes'
}

const url = '/usuario-tipo-tecnico'

export const getTipoTechnician = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesTipoTechnician> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postTipoTechnician = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesTipoTechnician> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delTipoTechnician = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesTipoTechnician> = await api.delete(`${url}/${cod}`, {
    params: {
      codConta: conta,
      codCliente,
    },
  })

  return resp
}
