import styled from 'styled-components'

export const Container = styled.div``

export const ContainerMap = styled.div`
  .floating-map {
  }
  .floating-div {
    position: absolute;
    width: 300px;

    padding-bottom: 0;
    margin: 15px;
    border-radius: 5px;
    background-color: white;
    z-index: 10000;
    right: 20px;
    top: 20px;
  }
`
