
import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesUserPropriedade {
  itens: ItensUserPropriedadeTypes[]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface ItensUserPropriedadeTypes {
  ponto: number
  cod_perfil_monitoramento: number
  cod_estadio: number
  cod_talhao: number
  data_inicio: string
  data_final: string
  usuario_nome: string
  estadio_nome: string
  hora_inicio: string
  colmo?: string
  hora_final: string
  longitude: number
  latitude: number
  altitude: string
  tempo: number
  repeticoes_remover: []
}

type TypesQuery = {
  codUsuario?: number
  codPropriedade?: number
  codClientePropriedade?: number
  embed?: string
  pagination?: number
}

type TypesPost = {
  cod_usuario?: number
  propriedades?: string[]
  propriedades_remover?: string[]
}

const url = '/usuario-propriedade'

export const getUserPropriedade = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const { pagination = -1 } = params
  const resp: AxiosResponse<TypesUserPropriedade> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      pagination,
      ...params,
    },
  })

  return resp
}

export const postUserPropriedade = async (data: TypesPost) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesUserPropriedade> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

const putUserPropriedade = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesUserPropriedade> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

const delUserPropriedade = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesUserPropriedade> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
