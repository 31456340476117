import { Item__MapaFertilidade__Config, ParametroFertilidade__Itens, ParametroFertilidade__Itens__Params } from "../../../../../../../services/novoConfigBook/get";

//TYPES
type TypeTipoSecoes = 'Fertilidade' | 'Indicadores' | 'Prescrições';

export function header_tabela(tab_secao?: TypeTipoSecoes): {titulo: string, width: string | number, display: 'none' | undefined}[] {
    let largura_coluna: string = (tab_secao==='Indicadores' ? '63.55%' : '35%');
    return [ 
        {titulo: 'Ordem',         width: 80,    display: undefined},
        {titulo: 'Elementos',     width: tab_secao==='Prescrições' ? '83.3%' : largura_coluna, display: undefined},
        {titulo: 'Mapa',          width: '20%', display: (tab_secao==='Prescrições') ? 'none' : undefined},
        {titulo: 'Profundidades', width: 'calc(35% - 80px)', display: (tab_secao!=='Fertilidade') ? 'none' : undefined},
        {titulo: 'Ações',         width: '10%', display: undefined},
    ];
}

export function obterVisibilidade(lista_elementos: Item__MapaFertilidade__Config[]) {

    let visibilidade = lista_elementos?.[0]?.secoes?.[0]?.visivel;

    return Boolean(visibilidade);
}

export function obterTipoDeMapas(parametros: ParametroFertilidade__Itens__Params[], lista_elementos: Item__MapaFertilidade__Config[], tab_secao:'Fertilidade' | 'Indicadores' | 'Prescrições') {

    let tipos_mapa_indicadores = lista_elementos?.[0]?.secoes?.[0]?.tipos_mapa;
    let profundidades_inter = lista_elementos?.[0]?.secoes?.[0]?.tipos_mapa?.[0]?.profundidades;
    let profundidades_distri = lista_elementos?.[0]?.secoes?.[0]?.tipos_mapa?.[1]?.profundidades;

    function visibilidade(tipo_mapa: number ): boolean {  
        let visibilidade_inter: boolean = profundidades_inter?.some((item)=> item.visivel === true);
        let visibilidade_distr: boolean = profundidades_distri?.some((item)=> item.visivel === true);

        return tipo_mapa===1 ? visibilidade_inter : visibilidade_distr
    }

    function visibilidadeIndicadores(tipo_mapa: number ): boolean {  
        let visibilidade = tipos_mapa_indicadores?.filter((item)=> item.tipo_mapa === tipo_mapa);
        return visibilidade?.[0]?.visivel ?? true
    }

    let descricao = (parametros ?? [])?.map((item)=> {
        return {
            secao_nome: item?.secao_nome,
            descricao: item?.tipo_mapa_nome,
            cod_tipo_mapa:  item?.tipo_mapa,
            ativo: lista_elementos?.length > 0,
            visivel: tab_secao==='Indicadores' ? visibilidadeIndicadores(item?.tipo_mapa) : visibilidade(item?.tipo_mapa)
        }

    })
    function removerDuplicados(chave: string) {
        const chaveSet = new Set();
        return (descricao ?? []).filter(item => {
            if (chaveSet.has(item[chave])) {
                return false;
            } else {
                chaveSet.add(item[chave]); 
                return true; 
            }
        });
    }
    return removerDuplicados('cod_tipo_mapa')?.filter((item)=> item.secao_nome !== 'Prescrições');
}

export type TypeobterProfundidades = {
    profundidade_nome: string, 
    cod_profundidade: number, 
    tipo_mapa: number,
    tipo_mapa_nome: string,
    visivel: boolean,
    ativo?: boolean
}

export function obterVisibilidadeSecao(lista_elementos: Item__MapaFertilidade__Config[]): boolean {    

    let visivilidade_secao = lista_elementos?.[0]?.secoes?.[0]?.visivel;

    return Boolean(visivilidade_secao);
}


export function obterProfundidades(parametros: ParametroFertilidade__Itens__Params[], lista_elementos: Item__MapaFertilidade__Config[]): TypeobterProfundidades[] {

    let config_interpetado = lista_elementos?.[0]?.secoes?.[0]?.tipos_mapa?.[0]?.profundidades;
    let config_distribuido = lista_elementos?.[0]?.secoes?.[0]?.tipos_mapa?.[1]?.profundidades;

    function visibilidadeProfundidade(cod_profundidade: number, cod_tipo_mapa: number) {
        if (cod_tipo_mapa===1) {
            return (config_interpetado?.filter((item)=> item.profundidade === cod_profundidade))?.[0]?.visivel;
        }

        return (config_distribuido?.filter((item)=> item.profundidade === cod_profundidade))?.[0]?.visivel;
    }

    let profundidades = (parametros ?? [])?.map((item)=> {
        return {
            secao_nome: item?.secao_nome,
            profundidade_nome: item?.profundidade_nome, 
            cod_profundidade: item?.profundidade, 
            tipo_mapa: item?.tipo_mapa,
            tipo_mapa_nome: item?.tipo_mapa_nome,
            visivel: lista_elementos?.length === 0 ? false : visibilidadeProfundidade(item?.profundidade, item?.tipo_mapa),
            ativo: lista_elementos?.length > 0
        }
    })


    return profundidades?.filter((item)=> item.secao_nome === 'Fertilidade');
}

export function filtrarSecao(lista_elementos: ParametroFertilidade__Itens[], secao:'Fertilidade' | 'Indicadores' | 'Prescrições') {

    let indicadores_e_precricoes = [30, 31, 32, 49, 41, 42, 46, 43, 44, 45, 33, 34, 28, 38, 50]
    let prescricoes = [30, 31, 32, 49, 41, 42, 46, 43, 44, 45];
    let indicadores = [33, 34, 28, 38, 50];

    const elementos_com_parametros = lista_elementos.filter((item)=> item.parametros.length > 0)

    if (secao==='Indicadores') {
        return elementos_com_parametros.filter((item)=> {
            return indicadores?.indexOf(item?.cod) > -1
        })
    }
    else if (secao==='Prescrições') {
        return elementos_com_parametros.filter((item)=> {
            return prescricoes?.indexOf(item?.cod) > -1
        })
    }
    else {
        return elementos_com_parametros.filter((item)=> {
            return indicadores_e_precricoes?.indexOf(item?.cod) === -1
        })
    }
    
}

function removerAcentos(str: string): string {
    return str.normalize("NFD").replace(/[\u0300-\u036f]/g, "");
}

export function encontrarElemento(elemento: string) {
    const elementoSemAcentos = removerAcentos(elemento);
    return function(item: { descricao: string }) {
        const nomeSemAcentos = removerAcentos(String(item.descricao));
        return nomeSemAcentos.toLowerCase().includes(elementoSemAcentos.toLowerCase()) || !elemento;
    }
}

// =============================================================================================>
    // ADAPTAR LISTA PRA FUNCIONAR A ORDENAÇÃO
// =============================================================================================>
/**Ordena a lista de elementos baseado nos parametros
 * 
 * Adiciona um `id` único para cada posição do array, para que a lib de ordenação `dnd-kit` funcione
 */
export function adaptarLista(isFetched: boolean, lista_base: ParametroFertilidade__Itens[], setElementos_base: Function, lista_elementos: Item__MapaFertilidade__Config[]) {
    

    function ordenarElementos() {
        let elem_ordenados = lista_elementos?.map((item)=> {
            return {
                cod: item.elemento,
                nome: item.nome,
                ordem: item?.secoes?.[0]?.ordem
            }
        });

        return elem_ordenados.sort((a, b) => a.ordem - b.ordem);
    }


    function obterNovaOrdem(cod_elemento: number) {
        let encontrar = ordenarElementos()?.filter(item=> item.cod === cod_elemento);

        return encontrar?.[0]?.ordem;
    }

    let ordenar = [];

    if (isFetched) {
        for (let index = 0; index < lista_base.length; index++) {
            ordenar.push( {
                id: index + 1,
                cod: lista_base?.[index]?.cod,
                descricao: lista_base?.[index]?.descricao,
                nome: lista_base?.[index]?.nome,
                ordem: lista_base?.[index]?.ordem,
                nova_ordem: obterNovaOrdem(lista_base?.[index]?.cod) ??  index + 1,
                parametros: lista_base?.[index]?.parametros
            })
            // setElementos_base(elementos_base=>[...elementos_base, {
            //     id: index + 1,
            //     cod: lista_base?.[index]?.cod,
            //     descricao: lista_base?.[index]?.descricao,
            //     nome: lista_base?.[index]?.nome,
            //     ordem: lista_base?.[index]?.ordem,
            //     nova_ordem: obterNovaOrdem(lista_base?.[index]?.cod),
            //     parametros: lista_base?.[index]?.parametros
            // }])
        } 
        
        setElementos_base((ordenar ?? []).sort((a, b) => a.nova_ordem - b.nova_ordem))
    }    

}
//***********************************************************************************************=> *


export function atualizarElementoModificado(lista_elementos: Item__MapaFertilidade__Config[], objetoAlterado: Item__MapaFertilidade__Config, info_ordem?: number) {

   

    const novoDados = JSON.parse(JSON.stringify(objetoAlterado));

    novoDados.secoes[0].ordem = info_ordem

    let index = (lista_elementos ?? []).findIndex(objeto => objeto.elemento === novoDados.elemento);

    if (index !== -1) {
        (lista_elementos).splice(index, 1, novoDados);
    } else {
        (lista_elementos)?.push(novoDados)
    }
}