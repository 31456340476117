import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PropsProgressState {
  value: number
}

const initialState: PropsProgressState = { value: 0 }

const progress = createSlice({
  name: 'progress',
  initialState,
  reducers: {
    percent(state, action: PayloadAction<number>) {
      state.value = action.payload
    },
    reset(state) {
      state.value = 0
    },
  },
})

export const { percent, reset } = progress.actions
export default progress.reducer
