import React, { useEffect, useState } from 'react'
import { ContainerInputs } from '../membroEquipe/styled'
import Input from '../../../CustomUi/input'
import {
  faBriefcase,
  faHome,
  faIdBadge,
  faIdCard,
  faMap,
  faMobileScreenButton,
  faPerson,
  faPersonDress,
  faPersonHalfDress,
  faRing,
  faUserGroup,
  faUserTie,
} from '@fortawesome/free-solid-svg-icons'
import { Select } from '../../../CustomUi/Select'
import TextArea from '../../../CustomUi/textarea'
import { useAccess } from '../../../../context/useAccess'
import { listarEstadoCivil, listTipoUniao } from '../../meta/index '
import { cpfMask, phoneMask } from '../../../../services/Afins'
import { message } from 'antd'
import Carregando from '../../../CustomUi/loading'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
interface FormDados {
  profissao?: string
  setProfissao?: React.Dispatch<React.SetStateAction<string>>
  profissaoId?: number
  setProfissaoId?: React.Dispatch<React.SetStateAction<number>>
  estadoCivil?: string
  setEstadoCivil?: React.Dispatch<React.SetStateAction<string>>
  estadoCivilId?: number
  setEstadoCivilId?: React.Dispatch<React.SetStateAction<number>>
  tipoUniao?: string
  setTipoUniao?: React.Dispatch<React.SetStateAction<string>>
  tipoUniaoId?: number
  setTipoUniaoId?: React.Dispatch<React.SetStateAction<number>>
  nomeConjuge?: string
  setNomeConjuge?: React.Dispatch<React.SetStateAction<string>>
  cpfConjuge?: string
  setCpfConjuge?: React.Dispatch<React.SetStateAction<string>>
  RgConjuge?: string
  setRgConjuge?: React.Dispatch<React.SetStateAction<string>>
  estadoNascimentoConjuge?: string
  setEstadoNascimentoConjuge?: React.Dispatch<React.SetStateAction<string>>
  estadoNascimentoConjugeID?: number
  setEstadoNascimentoConjugeID?: React.Dispatch<React.SetStateAction<number>>
  cidadeNascimentoConjuge?: string
  setCidadeNascimentoConjuge?: React.Dispatch<React.SetStateAction<string>>
  cidadeNascimentoConjugeID?: number
  setCidadeNascimentoConjugeID?: React.Dispatch<React.SetStateAction<number>>
  nomeMae?: string
  setNomeMae?: React.Dispatch<React.SetStateAction<string>>
  celularMae?: string
  setCelularMae?: React.Dispatch<React.SetStateAction<string>>
  nomePai?: string
  setNomePai?: React.Dispatch<React.SetStateAction<string>>
  celularPai?: string
  setCelularPai?: React.Dispatch<React.SetStateAction<string>>
  observacao?: string
  setObservacao?: React.Dispatch<React.SetStateAction<string>>
  update?: number
  listaCidades?: any[]
  listarProfissoes?: any[]
  permissions?: ItensPerUserLogged
}

const FormDadosComplementares: React.FC<FormDados> = ({
  profissao,
  profissaoId,
  estadoCivil,
  estadoCivilId,
  tipoUniao,
  tipoUniaoId,
  nomeConjuge,
  cpfConjuge,
  RgConjuge,
  estadoNascimentoConjuge,
  estadoNascimentoConjugeID,
  cidadeNascimentoConjuge,
  cidadeNascimentoConjugeID,
  nomeMae,
  celularMae,
  nomePai,
  celularPai,
  observacao,
  update,
  listaCidades,
  listarProfissoes,
  permissions,
  setProfissao,
  setProfissaoId,
  setEstadoCivil,
  setEstadoCivilId,
  setTipoUniao,
  setTipoUniaoId,
  setNomeConjuge,
  setCpfConjuge,
  setRgConjuge,
  setEstadoNascimentoConjuge,
  setEstadoNascimentoConjugeID,
  setCidadeNascimentoConjuge,
  setCidadeNascimentoConjugeID,
  setNomeMae,
  setCelularMae,
  setNomePai,
  setCelularPai,
  setObservacao,
}) => {
  const [listDataProfissao, setListDataProfissao] = useState([])
  const [openProfissao, setOpenProfissao] = useState(false)
  const [openEstadoCivil, setOpenEstadoCivil] = useState(false)
  const [openTipoUniao, setOpenTipoUniao] = useState(false)
  const [listUF, setListUF] = useState([])
  const [openUF, setOpenUF] = useState(false)
  const [listCity, setListCity] = useState([])
  const [openCity, setOpenCity] = useState(false)
  const [loading, setLoading] = useState(true)

  const { listDefault } = useAccess()

  const listarEstados = async () => {
    const resp = await listDefault({ type: 'UF' })

    setListUF(resp)
  }

  const handleChangeUF = (data) => {
    const resp = listaCidades.filter((item) => item.uf_nome === data)
    setListCity(resp)

    return resp
  }

  useEffect(() => {
    if (update <= 0) {
      handleChangeUF(estadoNascimentoConjuge)
    }
  }, [estadoNascimentoConjuge])

  useEffect(() => {
    if (update > 0) {
      const filterEstadoCivil = listarEstadoCivil.filter((item) => item.cod === estadoCivilId)
      setEstadoCivil(filterEstadoCivil[0]?.nome)
      const filterProfissao = listarProfissoes.filter((rec) => rec.cod === profissaoId)
      setProfissao(filterProfissao[0]?.nome)
      const filtrarEstado = listaCidades.filter((item) => item.cod === cidadeNascimentoConjugeID)
      setEstadoNascimentoConjuge(filtrarEstado[0]?.uf_nome)
      listarEstados()
      setCidadeNascimentoConjuge(filtrarEstado[0]?.nome)
    }
  }, [update, estadoCivilId, profissaoId, cidadeNascimentoConjugeID])

  useEffect(() => {
    if (update <= 0) {
      listarEstados()
    }
    setTimeout(() => {
      setLoading(false)
    }, 50)
  }, [])
  if (loading) {
    return <Carregando justifyContent='center' height={500} legenda='Carregando...' animation />
  }

  return (
    <ContainerInputs>
      <div className='lines'>
        <Select
          label='Profissão'
          icon={faBriefcase}
          placeholder='Selecione a profissão'
          value={profissao}
          onChange={setProfissao}
          onChangeID={setProfissaoId}
          open={openProfissao}
          trigger={setOpenProfissao}
          items={listarProfissoes}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />

        <Select
          label='Estado civil'
          icon={faPersonHalfDress}
          placeholder='Selecione o estado civil'
          value={estadoCivil}
          onChange={setEstadoCivil}
          onChangeID={setEstadoCivilId}
          open={openEstadoCivil}
          trigger={setOpenEstadoCivil}
          items={listarEstadoCivil}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />

        <Select
          label='Tipo de união'
          icon={faUserGroup}
          placeholder='Selecione o tipo de união'
          value={tipoUniao}
          onChange={setTipoUniao}
          onChangeID={setTipoUniaoId}
          open={openTipoUniao}
          trigger={setOpenTipoUniao}
          items={listTipoUniao}
          descricaoItem='nome'
          idItem='cod'
          disabled={estadoCivilId !== 2}
        />
      </div>
      <div className='lines'>
        <Input
          label='Nome do cônjuge'
          icon={faRing}
          placeholder='Digite o nome do cônjuge'
          value={nomeConjuge}
          onChange={setNomeConjuge}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='CPF do cônjuge'
          icon={faIdBadge}
          placeholder='000.000.000-00'
          value={cpfMask?.(cpfConjuge)}
          onChange={setCpfConjuge}
          width={'100%'}
          maxLength={14}
          desabilitado={permissions?.acesso === 1}
        />

        <Input
          label='RG do cônjuge'
          icon={faIdCard}
          placeholder='Digite o RG do cônjuge'
          value={RgConjuge}
          onChange={setRgConjuge}
          width={'100%'}
          maxLength={9}
          desabilitado={permissions?.acesso === 1}
        />
      </div>

      <div className='lines'>
        <Select
          label='Estado nascimento do cônjuge'
          icon={faMap}
          placeholder='Selecione o estado nascimento do cônjuge'
          value={estadoNascimentoConjuge}
          onChange={setEstadoNascimentoConjuge}
          onChangeID={setEstadoNascimentoConjugeID}
          open={openUF}
          trigger={setOpenUF}
          items={listUF}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />

        <Select
          label='Cidade nascimento do cônjuge'
          icon={faHome}
          placeholder='Selecione o cidade nascimento do cônjuge'
          value={cidadeNascimentoConjuge}
          onChange={setCidadeNascimentoConjuge}
          onChangeID={setCidadeNascimentoConjugeID}
          open={openCity}
          trigger={setOpenCity}
          items={listaCidades}
          descricaoItem='nome'
          idItem='cod'
          disabled={permissions?.acesso === 1}
        />
      </div>

      <div className='lines'>
        <Input
          label='Nome da mãe'
          icon={faPersonDress}
          placeholder='Digite o nome da mãe'
          value={nomeMae}
          onChange={setNomeMae}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Celular da mãe'
          icon={faMobileScreenButton}
          placeholder='Digite o celular da mãe'
          value={phoneMask(celularMae)}
          onChange={setCelularMae}
          width={'100%'}
          maxLength={15}
          desabilitado={permissions?.acesso === 1}
        />
      </div>

      <div className='lines'>
        <Input
          label='Nome do pai'
          icon={faPerson}
          placeholder='Digite o nome do pai'
          value={nomePai}
          onChange={setNomePai}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Celular do pai'
          icon={faMobileScreenButton}
          placeholder='Digite o celular do pai'
          value={phoneMask(celularPai)}
          onChange={setCelularPai}
          width={'100%'}
          maxLength={15}
          desabilitado={permissions?.acesso === 1}
        />
      </div>

      <TextArea label='Observação' value={''} onChange={(e) => {}} width={'100%'} maxHeight={50} disabled={permissions?.acesso === 1} />
    </ContainerInputs>
  )
}

export default FormDadosComplementares
