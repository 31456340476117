import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
import Label from "../../../../../../../Components/Label";
import ButtonCustom from "../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../Components/CustomUi/box";
import { CopiableRow } from "../../../../../../../Components/CustomUi/copiableRow";
import { ItemRequest } from "../../../../../../../services/logs";
import { copyJson } from "./meta";

//PROPS
interface Props {
    request_selected: ItemRequest;
}

export const AuthRequest: React.FC<Props> = ({ request_selected }) => {

    const { authUserEmail, authUserId, authUserName, tokenApi, tokenAuth } = request_selected?.descricao ?? {};

    return (
        <Box padding={15}>
            <Label style={{fontWeight: 700, color: 'var(--text-color-light)', fontSize:13}} >Autenticação da requisição</Label>

            <div style={{display:'flex',flexDirection:'column'}}>
                <CopiableRow 
                    atribute="authUserId"
                    value={authUserId}
                />
                <CopiableRow 
                    atribute="authUserName"
                    value={authUserName}
                />
                <CopiableRow 
                    atribute="authUserEmail"
                    value={authUserEmail}
                />
                <CopiableRow 
                    atribute="tokenApi"
                    value={tokenApi}
                />
                <CopiableRow 
                    atribute="tokenAuth"
                    value={tokenAuth}
                    isLast
                />
            </div>

            <ButtonCustom 
                onClick={()=>{copyJson(request_selected)}}
                type='secondary'
                style={{alignSelf:'flex-start'}}
                icon={<FontAwesomeIcon icon={faCopy} />}
            >
                Copiar JSON
            </ButtonCustom>
            
        </Box>
    )
}