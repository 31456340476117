
import axios, { AxiosResponse } from 'axios'

interface TypesParams {
  q?: {
    city: string
    uf: string
  }
}

export interface TypesForecast {
  city?: {
    coord?: {
      lat: number
      lon: number
    }
    uf: string
    country?: string
    id?: number
    name?: string
    population?: number
    sunrise?: number
    sunset?: number
    timezone?: number
  }
  cnt?: number
  cod?: number
  list?: Array<TypesForecastList>
  message?: number
}

export interface TypesForecastList {
  clouds?: {
    all?: number
  }
  dt?: number
  dt_txt?: string
  semana?: string
  semanaFormat?: string
  dataDia?: string
  dataDiaFormat?: string
  main?: {
    feels_like?: number
    grnd_level?: number
    humidity?: number
    pressure?: number
    sea_level?: number
    temp?: number
    temp_kf?: number
    temp_max?: number
    temp_min?: number
  }
  pop?: number
  svg?: string
  sys?: { pod?: string }
  visibility?: number
  weather?: [
    {
      id?: number
      main?: string
      description?: string
      icon?: string
    }
  ]
  wind?: {
    speed?: number
    deg?: number
    gust?: number
  }
  rain?: {
    '3h'?: number | string
  }
}

const apiClima = axios.create({
  baseURL: 'https://api.openweathermap.org/data/2.5/forecast/',
  params: {
    appid: '4e9845573695e3b43ed7edf0f5ccae35',
    lang: 'pt_br',
    units: 'metric',
  },
})

export const getClimaCodCity = async (params: TypesParams) => {
  const response: AxiosResponse<TypesForecast> = await apiClima.get('', {
    params: {
      q: `${params.q.city},BR-${params.q.uf},BRA`,
    },
  })

  return response
}