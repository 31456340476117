import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesPerfil {
  itens: ItensPerfilTypes[]
}

export interface ItensPerfilTypes {
  cod?: number
  nome: string
  create_user?: string
  create_time?: string
  update_user?: string
  update_time?: string
  descricao?: string
  cod_conta?: number
  cod_cliente?: number
  cod_perfil?: number
  status: number
  conta_nome?: string
  permissoes: PermissoesType[]
}

export type PermissoesType = {
  cod?: number
  cod_perfil?: number
  cod_rotina: number
  acesso: number
  inclusao: number
  alteracao: number
  exclusao: number
  perfil_nome?: string
  rotina_nome: string
}

type TypesQuery = {
  cod?: number
  embed?: 'permissoes'
  pagination?: number
  order?: string
}

const url = '/perfil'

export const getPerfil = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPerfil> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })
  
  return resp
}

export const postPerfil = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPerfil> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putPerfil = async (data, cod) => {
  const { codCliente, conta } = localConfig()

  const resp: AxiosResponse<TypesPerfil> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delPerfil = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPerfil> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
