import React, { useEffect, useState, useRef } from 'react'

import {
  Form,
  Input,
  Drawer,
  message,
  Button,
  Col,
  Row,
  Select,
  Upload,
  Spin,
  InputNumber,
  Radio,
  DatePicker as YearPiker,
} from 'antd'

import { UploadChangeParam } from 'antd/lib/upload'
import { PlusOutlined } from '@ant-design/icons'

import { v4 as uid } from 'uuid'

import dayjs from 'dayjs'

import Pdf from '../../assets/img/pdf.png'

import {
  postPatrimonio,
  putPatrimonio,
  getPatrimonio,
  getFilePatrimonioArr,
  getFilePatrimonioBlob,
} from '../../services/Patrimonio'

import { getCatPatrimonio } from '../../services/config/categoriasPatrimonio'

import { Notification } from '../notification'

import { Container } from './style'

import gerarBase64 from '../Base64Geral'
import { getCategoriaMarca } from '../../services/config/marca'

import { formatMoeda } from '../../services/Afins'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import DatePicker from '../DatePiker'
import DiasDesdeData from '../DiasDesdeData'

interface TypesFormPatrimonio {
  onShow: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (data: number) => void
  refresh: (data: string) => void
  permissions: ItensPerUserLogged
  dataPatrimonio: any
}

interface TypeFiltroCat {
  codCategora: number
  horimetro: boolean
  hodometro: boolean
}

const { Option } = Select

const Patrimonio: React.FC<TypesFormPatrimonio> = (props) => {
  const { update, setUpdate, onShow, onClose, refresh, dataPatrimonio, permissions } = props

  const uploadImagens = useRef(null)

  const [form] = Form.useForm()

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loadingSelecTipoCatego, setLoadingSelecTipoCatego] = useState(false)
  const [loadingDowloandingFiles, setLoadingDowloandingFiles] = useState(false)

  const [preview, setPreview] = useState(null)
  const [motorizado, setMotorizado] = useState(null)
  const [odomTipo, setOdomTipo] = useState(null)

  const [selectedFile, setSelectedFile] = useState([])
  const [dataCategorias, setDataCategorias] = useState([])
  const [dataTipoCategorias, setTipoDataCategorias] = useState([])
  const [dataMarca, setDataMarca] = useState([])
  const [idFileList, setIdFileList] = useState([])

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const [filtroCategoria, setFiltroCategoria] = useState<TypeFiltroCat>({
    codCategora: null,
    horimetro: false,
    hodometro: false,
  })

  const [previewImage, setPreviewImage] = useState({
    image: null,
    visible: false,
    title: null,
  })

  const listCategorias = async () => {
    const response = await getCatPatrimonio({
      principal: 1,
      order: 'nome',
      pagination: -1,
    })

    setDataCategorias(response.data.itens)
  }

  const listMarcas = async (data) => {
    const response = await getCategoriaMarca('', '', data, 'nome')

    setDataMarca(response.data.itens)
  }

  const handleChangeCategoria = async (data, limpa) => {
    listMarcas(data)
    setLoadingSelecTipoCatego(true)
    if (data !== null && data !== undefined) {
      const response = await getCatPatrimonio({
        codCategoria: data,
        order: 'nome',
        pagination: -1,
      })

      setTipoDataCategorias(response.data.itens)
    } else {
      setTipoDataCategorias([])
    }
    if (limpa === 1) {
      form.setFieldsValue({ cod_tipo: null })
      form.setFieldsValue({ cod_marca: null })
      setOdomTipo(0)
    }
    setLoadingSelecTipoCatego(false)
    setFiltroCategoria({
      codCategora: data,
      hodometro: data === 2,
      horimetro: false,
    })
  }

  const handleAllClear = () => {
    setTipoDataCategorias([])
  }

  const editPatrimonio = async (data) => {
    message.loading('Carregando...', 99999)
    const response = await getPatrimonio({ cod: data, pagination: -1 })
    const dados = response.data.itens[0]

    dados.data_aquisicao = dados.data_aquisicao === null ? '' : dayjs(dados.data_aquisicao)

    dados.ano = dados.ano === null ? '' : dayjs(`01/01/${dados.ano}`)

    form.setFieldsValue(dados)
    handleChangeCategoria(dados.tipo.cod_categoria === null ? null : dados.tipo.cod_categoria, 0)

    form.setFieldsValue({
      categoria: dados.tipo.cod_categoria === null ? dados.tipo.cod : dados.tipo.cod_categoria,

      cod_tipo: dados.tipo.cod,
      valor: formatMoeda(dados.valor),
      odom_horimetro: parseInt(dados.odom_horimetro, 10),
    })
    setMotorizado(dados.motorizado)
    setOdomTipo(dados.tipo_odom)

    if (dados.arquivos.length > 0) {
      const array = dados.arquivos

      setLoadingDowloandingFiles(true)
      let fileList: any = []
      let arquivos: any

      for (let index = 0; index < array.length; index += 1) {
        const files = array[index]

        const uuid = uid()

        const extencao = dados.arquivos[index].nome.split('.')[1]

        const typeFile = `${extencao === 'pdf' ? 'application' : 'image'}/${extencao}`

        let exibir

        if (extencao === 'pdf') {
          // eslint-disable-next-line no-await-in-loop
          const retorno = await getFilePatrimonioBlob(files.arquivo_url)

          exibir = {
            // @ts-ignore
            url: URL.createObjectURL(retorno.data),
            thumbUrl: Pdf,
          }
        } else {
          // eslint-disable-next-line no-await-in-loop
          const retorno = await getFilePatrimonioArr(files.arquivo_url)
          const base64String = Buffer.from(retorno.data).toString('base64')

          exibir = {
            // @ts-ignore
            preview: `data:image/${extencao};base64,${base64String}`,
            thumbUrl: `data:image/${extencao};base64,${base64String}`,
          }
        }

        const file = {
          uid: uuid,
          cod: files.cod,
          status: 'done',
          response: 'done',
          name: dados.arquivos[index].nome,
          url: exibir.url,
          preview: exibir.preview,
          thumbUrl: exibir.thumbUrl,
          type: typeFile,
        }

        const temp = {
          uid: uuid,
          cod: files.cod,
          status: 'done',
          response: 'done',
          name: dados.arquivos[index].nome,
          url: exibir.url,
          preview: exibir.preview,
          thumbUrl: exibir.thumbUrl,
          type: typeFile,
        }

        fileList = [...fileList, temp]

        arquivos = {
          file,
          fileList,
        }

        onChangeUpload(arquivos)
      }
      setLoadingDowloandingFiles(false)
    }
    message.destroy()
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    setTipoDataCategorias([])
    setSelectedFile([])
    setIdFileList([])
    setDataMarca([])
    setOdomTipo(0)
    setFiltroCategoria({
      codCategora: null,
      horimetro: false,
      hodometro: false,
    })
    setMotorizado(null)
    form.resetFields()
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    info.file.status = 'done'
    if (info.file.status === 'done') {
      const files = info.fileList

      files.forEach(async (data) => {
        if (data.type === 'application/pdf') {
          data.thumbUrl = Pdf
          // @ts-ignore
          data.preview = data.url !== undefined ? '' : await gerarBase64(data.originFileObj)
        }
      })

      setSelectedFile(files)
    }
  }

  const onRemoveFile = (data) => {
    const dados = selectedFile
    const ids = idFileList

    ids.push(data.cod)

    setSelectedFile(dados.filter((remove) => remove.uid !== data.uid))
    setIdFileList(ids)
  }

  const handlePreviewImage = async (file) => {
    if (!file.url && !file.preview) {
      file.preview = await gerarBase64(file.originFileObj)
    }

    setPreviewImage({
      image: file.url || file.preview,
      visible: true,
      title: '',
    })
    if (file.type === 'application/pdf') {
      setPreview(
        <embed
          width='auto'
          height='100vh'
          style={{ width: '97vw', minHeight: '90vh' }}
          src={file.url || file.preview}
        />
      )
    } else {
      setPreview(<img alt='FileImage' width='auto' height='auto' src={file.url || file.preview} />)
    }
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const uploadButton = (
    <div>
      <PlusOutlined />
      <div style={{ marginTop: 8 }}>Arquivos</div>
    </div>
  )

  const dowLoadButton = (
    <div>
      <Spin />
      <div style={{ marginTop: 8 }}>Carregando...</div>
    </div>
  )

  const handleClosePreview = () => {
    setPreviewImage({ visible: false, image: null, title: null })
    setPreview(null)
  }

  const onChangeMoeda = (data) => {
    const valor = formatMoeda(data.target.value)

    form.setFieldsValue({ valor })
  }

  const onChangeOdomTipo = (data) => {
    setOdomTipo(data.target.value)

    form.setFieldsValue({ odom_horimetro: '' })
  }

  const onChangeMotorizado = (data) => {
    setMotorizado(data.target.value)
    setOdomTipo(null)
    form.setFieldsValue({ tipo_odom: null })
  }

  const raioOptionsMotorizado = [
    { label: 'Sim', value: 1 },
    { label: 'Não', value: 0 },
  ]

  const tipoOdom = [
    { label: 'Nenhum', value: 0 },
    { label: 'Odômetro', value: 1 },
    { label: 'Horímetro', value: 2 },
  ]

  const onFinish = async (data) => {
    data.data_aquisicao =
      data.data_aquisicao === null ||
      data.data_aquisicao === '' ||
      data.data_aquisicao === undefined
        ? ''
        : data.data_aquisicao?.format('YYYY-MM-DD')

    data.ano =
      data.ano === null || data.ano === '' || data.ano === undefined ? '' : data.ano?.format('YYYY')

    try {
      message.loading('Aguarde...', 9999)

      const fd = new FormData()
      data.cod_tipo = data.cod_tipo === undefined ? data.categoria : data.cod_tipo

      const arrayFiles = selectedFile

      Object.entries(data).forEach(([key, value]) => {
        if (key !== 'arquivo') {
          if (value === null) {
            // @ts-ignore
            fd.append(key, '')
          } else {
            // @ts-ignore
            fd.append(key, value)
          }
        }
      })

      for (let i = 0; i < arrayFiles.length; i += 1) {
        const data = arrayFiles[i]
        if (data.cod > 0) {
          // empty
        } else {
          fd.append('arquivo[]', data.originFileObj)
        }
      }

      if (idFileList.length > 0) {
        idFileList.forEach((data) => fd.append('cod_arquivo[]', data))
      }

      if (update <= 0) {
        const response = await postPatrimonio(fd)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Patrimônio Cadastrado com Sucesso',
            type: 'success',
          })
          setSelectedFile([])
          setDataMarca([])
          setFiltroCategoria({
            horimetro: false,
            hodometro: false,
            codCategora: null,
          })
          setMotorizado(null)
          form.resetFields()
          refresh('')
        }
      } else {
        const response = await putPatrimonio(update, fd)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Patrimônio Atualizado com Sucesso',
            type: 'success',
          })
          refresh('')
          setIdFileList([])
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setDrawerVisible(onShow)
  }, [onShow])

  useEffect(() => {
    if (update !== 0) {
      editPatrimonio(update)
    }
  }, [update])

  useEffect(() => {
    listCategorias()
  }, [])

  useEffect(() => {
    if (drawerVisible) {
      const patrimonioCheck = dataPatrimonio.filter((item) => item.cod === update)

      if (patrimonioCheck.length > 0) {
        if (patrimonioCheck[0].create_user) {
          setUsuarioQueCriou(patrimonioCheck[0].create_user)

          const dateOnlyString = patrimonioCheck[0].create_time.split(' ')[0]
          const timeOnlyString = patrimonioCheck[0].create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (patrimonioCheck[0].update_user) {
          setUsuarioQueAlterou(patrimonioCheck[0].update_user)

          const dateOnlyString = patrimonioCheck[0].update_time.split(' ')[0]
          const timeOnlyString = patrimonioCheck[0].update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, drawerVisible, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao])

  return (
    <Container>
      <Form encType='multipart/form-data' layout='vertical' form={form} onFinish={onFinish}>
        <Drawer
          title={
            <div style={{ display: 'flex', flexDirection: 'column' }}>
              <>{update <= 0 ? 'Cadastro de Patrimônio' : 'Atualizar Patrimônio'}</>
              {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                <>
                  <DiasDesdeData
                    dataCriacao={dataAlteracao}
                    onDataFromCreation={handleDataFromCreation}
                  />
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    atualizado por: {usuarioQueAlterou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeAtualizacao}h`}
                  </small>
                </>
              ) : !logUser && update > 0 ? (
                <>
                  {' '}
                  {usuarioQueCriou === '' ? null : (
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      criado por: {usuarioQueCriou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeCriacao}h`}
                    </small>
                  )}
                </>
              ) : null}
            </div>
          }
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          open={drawerVisible}
          onClose={hancleDrawerClose}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' htmlType='submit'>
                {update ? 'Atualizar Patrimônio' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col
            span={22}
            style={{
              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
            }}
          >
            <Row gutter={[8, 8]}>
              <Col flex='1 1 300px'>
                <Form.Item initialValue='' name='nome' label='Nome'>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex='0 1 300px'>
                <Form.Item initialValue='' name='modelo' label='Modelo'>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex='0 1 140px'>
                <Form.Item name='data_aquisicao' label='Data da Aquisição'>
                  <DatePicker placeholder='' style={{ width: '100%' }} />
                </Form.Item>
              </Col>
              <Col flex='0 1 150px'>
                <Form.Item initialValue='0,00' name='valor' label='Valor'>
                  <Input onChange={onChangeMoeda} />
                </Form.Item>
              </Col>
              <Col flex='0 1 140px'>
                <Form.Item initialValue='' name='condicao_aquisicao' label='Condição'>
                  <Select>
                    <Option key='1' value={1}>
                      Novo
                    </Option>
                    <Option key='2' value={2}>
                      Usado
                    </Option>
                  </Select>
                </Form.Item>
              </Col>

              <Col flex='0 1 140px'>
                <Form.Item name='ano' label='Ano de Fabricação'>
                  {/* @ts-ignore */}
                  <YearPiker placeholder='' picker='year' style={{ width: '100%' }} />
                </Form.Item>
              </Col>

              <Col flex='1 1 350px'>
                <Form.Item name='categoria' label='Categoria'>
                  <Select
                    style={{ minWidth: '100%' }}
                    showSearch
                    allowClear
                    onChange={(data) => handleChangeCategoria(data, 1)}
                    onClear={handleAllClear}
                    filterOption={(input, option) =>
                      option.children
                        // @ts-ignore
                        .toLowerCase()
                        .indexOf(input.toLowerCase()) >= 0
                    }
                    placeholder='Selecione uma Categoria'
                  >
                    {dataCategorias.map((data) => (
                      <Option key={data.cod} value={data.cod}>
                        {data.nome.slice(0, 1) + data.nome.slice(1).toLowerCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
              {dataTipoCategorias.length > 0 ? (
                <Col flex='1 1 300px'>
                  <Form.Item name='cod_tipo' label='Tipo'>
                    <Select
                      loading={loadingSelecTipoCatego}
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          // @ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder='Selecione o Tipo'
                    >
                      {dataTipoCategorias.map((data) => (
                        <Option key={data.cod} value={data.cod}>
                          {data.nome.slice(0, 1) + data.nome.slice(1).toLowerCase()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
              {dataMarca.length > 0 ? (
                <Col flex='0 1 300px'>
                  <Form.Item name='cod_marca' label='Marca'>
                    <Select
                      style={{ minWidth: '100%' }}
                      showSearch
                      allowClear
                      filterOption={(input, option) =>
                        option.children
                          // @ts-ignore
                          .toLowerCase()
                          .indexOf(input.toLowerCase()) >= 0
                      }
                      placeholder='Selecione a Marca'
                    >
                      {dataMarca.map((data) => (
                        <Option key={data.cod} value={data.cod}>
                          {data.nome.slice(0, 1) + data.nome.slice(1).toLowerCase()}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
              ) : null}
              {/* eslint-disable-next-line no-nested-ternary */}
              {filtroCategoria.codCategora === 27 || filtroCategoria.codCategora === 2 ? (
                filtroCategoria.codCategora === 27 ? (
                  <Col flex='0 1 142px'>
                    <Form.Item initialValue={0} name='motorizado' label='Motorizado?'>
                      <Radio.Group
                        optionType='button'
                        onChange={onChangeMotorizado}
                        options={raioOptionsMotorizado}
                        buttonStyle='solid'
                      />
                    </Form.Item>
                  </Col>
                ) : null
              ) : null}
              {motorizado === 1 ||
              filtroCategoria.codCategora === 26 ||
              filtroCategoria.codCategora === 2 ||
              filtroCategoria.codCategora === 223 ? (
                <>
                  <Col flex='0 1 310px'>
                    <Form.Item initialValue={0} name='tipo_odom' label='Tipo de Medidor'>
                      <Radio.Group
                        optionType='button'
                        buttonStyle='solid'
                        onChange={onChangeOdomTipo}
                        options={tipoOdom}
                      />
                    </Form.Item>
                  </Col>
                </>
              ) : null}

              {filtroCategoria.codCategora === 26 || filtroCategoria.codCategora === 223 ? (
                <>
                  <Col flex='0 1 175px'>
                    <Form.Item initialValue='' name='placa' label='Placa'>
                      <Input maxLength={15} />
                    </Form.Item>
                  </Col>{' '}
                  <Col flex='0 1 175px'>
                    <Form.Item initialValue='' name='renavam' label='Renavam'>
                      <Input maxLength={45} />
                    </Form.Item>
                  </Col>
                </>
              ) : null}
              {odomTipo === 1 || odomTipo === 2 ? (
                <Col flex='0 1 175px'>
                  <Form.Item
                    initialValue=''
                    name='odom_horimetro'
                    label={odomTipo === 1 ? 'Odômetro' : 'Horímetro'}
                  >
                    <InputNumber style={{ width: '100%' }} />
                  </Form.Item>
                </Col>
              ) : null}
              <Col span={24}>
                <Form.Item initialValue='' label='Observações' name='descricao'>
                  <Input.TextArea />
                </Form.Item>
              </Col>

              <Col span={24}>
                <Form.Item label='Arquivos' name='arquivo'>
                  <Upload
                    ref={uploadImagens}
                    accept='image/jpeg, image/png, application/pdf'
                    listType='picture-card'
                    onChange={onChangeUpload}
                    fileList={selectedFile}
                    customRequest={dummyRequest}
                    onRemove={onRemoveFile}
                    onPreview={handlePreviewImage}
                    maxCount={10}
                    multiple
                  >
                    {/* eslint-disable-next-line no-nested-ternary */}
                    {selectedFile.length >= 10
                      ? null
                      : loadingDowloandingFiles === true
                      ? dowLoadButton
                      : uploadButton}
                  </Upload>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Drawer>
        <Form.Item name='status' initialValue={1} />
      </Form>
      <Drawer
        width='100%'
        height='100%'
        placement='bottom'
        open={previewImage.visible}
        closeIcon={false}
        title={previewImage.title}
        onClose={handleClosePreview}
        footer={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button danger type='primary' onClick={handleClosePreview} style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>
        }
      >
        {preview}
      </Drawer>
    </Container>
  )
}

export default Patrimonio
