import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeInsumos {
  itens: [InsumosItens]
}

export interface InsumosItens {
  cod?: number
  cod_conta?: number
  cod_cliente?: number
  cod_categoria_produto?: number
  cod_classe_agronomica?: number
  cod_medida_aplicacao?: number
  cod_fabricante?: number
  observacao?: string
  nome?: string
  descricao?: string
  cod_formulacao?: number
  fabricante_nome?: string
  categoria_produto_nome?: string
  cod_tipo_adjuvante?: number
  adjuvante?: number
  aplicacoes?: {
    cod?: string | number
    cod_produto?: number
    cod_cultura?: number
    cod_fase_cultura?: number
    cultura_nome?: string
    alvos?: {
      cod?: string | number
      dose_bula?: number
      medida?: number
      dose_usual?: number
      cod_especie?: number
      cod_variacao_especie?: number
      cod_cultura?: number
    }[]
  }[]
  composicao?: [
    {
      cod?: number
      cod_produto?: number
      cod_ativo?: number
      concentracao?: number
      produto_nome?: string
      ativo_nome?: string
      ativos?: [
        {
          cod?: number
          nome?: string
          cod_categoria_produto?: number
          cod_classe_agronomica?: number
          cod_grupo_quimico?: number
          cod_classe_quimica?: number
          cod_grupo?: number
          cod_modo_acao?: number
          cod_local_acao?: number
          cod_mecanismo_acao?: number
          categoria_produto_nome?: string
          grupo_quimico_nome?: string
          classe_quimica_nome?: string
          grupo_nome?: string
          modo_acao_nome?: string
          local_acao_nome?: string
          local_mecanismo_acao?: string
        }
      ]
    }
  ]
}

interface TypeQuery {
  codConta?: number | string
  cod?: number
  pagination?: number | -1
  nome?: string
  embed?: string
  page?: number
  pageSize?: number
}

const url = '/recomendacao/insumo-agricola'

export const getInsumos = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  const { conta, acesso } = localConfig()

  api.defaults.headers.Acesso = acesso

  params = {
    pagination,
    codConta: conta,
    ...params,
  }

  const response = api.get(url, {
    params,
  })

  return response
}

export const postInsumos = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.post(url, data, {
    params: {
      codConta: config.conta,
    },
  })

  return response
}

export const putInsumos = async (cod, data, params?: TypeQuery) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.put(`${url}/${cod}`, data, {
    params: {
      codConta: config.conta,
      ...params,
    },
  })

  return response
}

export const delInsumos = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.delete(`${url}/${cod}`, {
    params: {
      codConta: config.conta,
    },
  })

  return response
}
