import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Collapse, CollapseProps, Empty, Popconfirm, Tag } from "antd";

//APP
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { ItemAtivoCadastrado } from "../../../../../../../../services/recomendacao/ativos/novo-index";
import { useDeleteCompatibilidadeEmMassa } from "../../../../../../../../services/recomendacao/ativos/delete";

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import Checkbox from "../../../../../../../../Components/CustomUi/checkbox";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { ModalEditar } from "../../../gavetaCadastrar/components/doses/components/editar";
import { ZLabel } from "../zLabel";

//PROPS
interface Props {
    ativos_cadastrados: ItemAtivoCadastrado[],
    carregando: boolean;
    refetch: Function;
    atualizarListaPrincipal: Function;
    id_tipo_principal: number | null
}

export const DetalhesDoses: React.FC<Props> = ({ ativos_cadastrados, carregando, refetch, atualizarListaPrincipal, id_tipo_principal }) => {

    //STATES
    const [search, setSearch] = useState<string>('');
    const [checados, setChecados] = useState([]);
    const [hover_item_atual, setHover_item_atual] = useState<number>(-1);
    const [collapsable, setCollapsable] = useState<boolean>(true);
    const [todosChecados, setTodosChecados] =useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    //API
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidadeEmMassa();

    function isChecado(cod_item: number) {
        return todosChecados ? true : (checados?.find(item=> item === cod_item)) !== undefined;
    }

    function marcarCheck(cod_item: number) {
        if (isChecado(cod_item)) {
            let remover = checados.filter(item=> item !== cod_item);
            setChecados(remover);
        }
        else {
            setChecados(checados=>[...checados, cod_item]);
        }
    }

    function buscarCadastro(buscarItem) {
        return function(item) {              
            return String(item.cultura_item_lancamento?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.estadio_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            !buscarItem;
        }
    }
    function filtrarDoses() {
        return function(item) {              
            return item.cod_tipo === 5
        }
    }

    function montarListaCollapse() {
        let lista_ativos:CollapseProps['items'] = (ativos_cadastrados)?.filter(buscarCadastro(search))?.filter(filtrarDoses()).map((item)=> {
            let is_item_atual: undefined|'disabled' = hover_item_atual === item.cod ? 'disabled' : undefined;
            return {
                key: item.cod,
                label: <p style={{margin:0, display:'flex',justifyContent:'space-between'}}>
                        {/* <span>{item.cultura_item_lancamento} ({item.item_lancamento_nome??'todos os estádios'})</span> */}
                        <ZLabel
                            item={item}
                            id_tipo_principal={id_tipo_principal}
                        />
                        <span style={{display:'flex'}}>
                            <Tag color={"success"} style={{alignSelf: 'center'}}>Min: {item?.dose_minima}</Tag>
                            <Tag color={"error"} style={{alignSelf: 'center'}}>Max: {item?.dose_maxima}</Tag>
                            <Checkbox 
                                isChecked={isChecado(item.cod)} 
                                trigger={()=>{marcarCheck(item.cod)}}
                                onMouseOver={()=>{setHover_item_atual(item.cod);setCollapsable(false)}}
                                onMouseOut={()=>{setHover_item_atual(-1);setCollapsable(true)}}
                                // disabled={todosChecados}
                            />
                        </span> 
                    </p>,
                children: <ModalEditar id_tipo_principal={id_tipo_principal} registros={item} atualizarLista={refetch} atualizarListaPrincipal={atualizarListaPrincipal} isVisualizar />,
                destroyInactivePanel: true,
                collapsible: collapsable ? undefined : is_item_atual
            }
        });
        return lista_ativos;
    }

    function deletarCompatibilidade() {
        let deletar_items = todosChecados ? montarListaCollapse().map(item=> item.key) : checados;

        for (const cod of deletar_items) {
            mutate_deletar_compati({
                codConta: Number(localConfig().conta),
                cod: cod,
                limmparCheckBox: () => {setChecados([]);setTodosChecados(false)},
                atualizarLista: () => refetch(),
                atualizarListaPrincipal: () => atualizarListaPrincipal(),
                ultimaChamada: deletar_items?.[deletar_items?.length -1] === cod,
                closePopup: ()=> setConfirmDelete(false)
            })
        } 
    }

    return (
        <Box>
            <TitleH4 style={{color: 'var(--text-color-light)'}}>Doses cadastradas ({montarListaCollapse()?.length ?? ''})</TitleH4>

            <Input
                placeholder="Buscar dose"
                value={search}
                onChange={setSearch}
            />
            
            {carregando ?
                <Carregando 
                    legenda="Carregando doses cadastradas"
                    justifyContent="center"
                    animation
                    height={100}
                />
                : <>
                    {montarListaCollapse()?.length === 0 
                        ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={search?.length > 0 ? <span><b>"{search}"</b> não encontrado</span> : `Nenhuma dose cadastrada`} />
                        : <Collapse accordion items={montarListaCollapse()} style={{opacity: carregando ? 0.4 : 1}} />
                    } 
                </>
            }

            {montarListaCollapse().length > 0 &&(
                <div style={{display:'flex'}}>
                    <Checkbox
                        isChecked={todosChecados}
                        trigger={setTodosChecados}
                        label="Marcar todos"
                    />
                    <Popconfirm
                        placement="topRight"
                        title={`Tem certeza?`}
                        open={confirmDelete}
                        description={
                        <div style={{display:'flex',flexDirection:'column',gap:15}}>
                            <span>{`${todosChecados?'Todos os':checados?.length} serão deletados`}</span>
                            <Inline justifyContent="flex-end">

                                <ButtonCustom 
                                    onClick={()=>{setConfirmDelete(false)}}
                                    type="tertiary"
                                    icon={<FontAwesomeIcon icon={faXmark} />}
                                >
                                    Cancelar
                                </ButtonCustom>

                                <ButtonCustom 
                                    value="Deletar"
                                    onClick={()=>{deletarCompatibilidade()}}
                                    icon={<FontAwesomeIcon icon={faTrash} /> }
                                    danger
                                    loading={carregando_deletar_compati}
                                    disabled={carregando_deletar_compati ? true : (checados?.length === 0 && !todosChecados)}
                                >
                                    Deletar
                                </ButtonCustom>

                            </Inline>
                        </div>}
                        okButtonProps={{style: {display:'none'}}}
                        cancelButtonProps={{style: {display:'none'}}}
                    >
                        <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                            <ButtonCustom
                                danger
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                onClick={() => setConfirmDelete(true)}
                                disabled={carregando_deletar_compati ? true : (checados?.length === 0 && !todosChecados)}
                            >
                                Deletar
                            </ButtonCustom>  
                        </Button>
                    </Popconfirm>
                </div>
            )}  
            
        </Box> 
    );
}