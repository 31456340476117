import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//APP
import { copyJson } from "./meta";
import { ItemRequest } from "../../../../../../../services/logs";

//COMPONENTS
import Label from "../../../../../../../Components/Label";
import ButtonCustom from "../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../Components/CustomUi/box";
import { CopiableRow } from "../../../../../../../Components/CustomUi/copiableRow";

//PROPS
interface Props {
    request_selected: ItemRequest;
}

export const InfoRequest: React.FC<Props> = ({ request_selected }) => {

    const { uri, httpMethod, ip } = request_selected?.descricao ?? {};

    return (
        <Box padding={15}>
            <Label style={{fontWeight: 700, color: 'var(--text-color-light)', fontSize:13}} >Informações da requisição</Label>

            <div style={{display:'flex',flexDirection:'column'}}>
                <CopiableRow 
                    atribute="cod"
                    value={request_selected?.cod}
                />
                <CopiableRow 
                    atribute="uri"
                    value={uri}
                />
                <CopiableRow 
                    atribute="httpMethod"
                    value={httpMethod}
                />
                <CopiableRow 
                    atribute="ip"
                    value={ip}
                />
                <CopiableRow 
                    atribute="cod_usuario"
                    value={request_selected?.cod_usuario}
                />
                <CopiableRow 
                    atribute="usuario_nome"
                    value={request_selected?.usuario_nome}
                />
                <CopiableRow 
                    atribute="usuario_email"
                    value={request_selected?.usuario_email}
                />
                <CopiableRow 
                    atribute="create_time"
                    value={request_selected?.create_time}
                />
                <CopiableRow 
                    atribute="create_user"
                    value={request_selected?.create_user}
                />
                <CopiableRow 
                    atribute="update_time"
                    value={request_selected?.update_time}
                />
                <CopiableRow 
                    atribute="update_user"
                    value={request_selected?.update_user}
                />
                <CopiableRow 
                    atribute="delete_time"
                    value={request_selected?.delete_time}
                />
                <CopiableRow 
                    atribute="delete_user"
                    value={request_selected?.delete_user}
                    isLast
                />
            </div>

            <ButtonCustom 
                onClick={()=>{copyJson(request_selected)}}
                type='secondary'
                style={{alignSelf:'flex-start'}}
                icon={<FontAwesomeIcon icon={faCopy} />}
            >
                Copiar JSON
            </ButtonCustom>
            
        </Box>
    )
}