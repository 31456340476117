import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export const getAniversariantes = async (codCliente, conta, dataInicio, dataFim) => {
  const { acesso } = localConfig()
  api.defaults.headers.Acesso = acesso

  const resp: AxiosResponse = await api.get('equipe/aniversariantes', {
    params: {
      codCliente: codCliente,
      codConta: conta,
      dataInicio: dataInicio,
      dataFim: dataFim,
    },
  })

  return resp
}
