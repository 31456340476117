import axios from 'axios'
import { localConfig } from '../Configs/localConfig'

export const api = axios.create({
  baseURL: process.env.REACT_APP_LINK_API,
  headers: {
    'Content-Type': 'application/json',
    Authorization: `Token ${process.env.REACT_APP_TOKEN_API}`,
    Acesso: localConfig().acesso,
  },
  responseType: 'json',
  timeoutErrorMessage: 'Tempo limite excedido',
})
