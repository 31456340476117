import React, { useEffect, useState } from 'react'

import { Button, Modal, Table, Tag } from 'antd'

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { orderBy } from 'lodash'
import {
  delCatPatrimonio,
  getCatPatrimonio,
} from '../../../../services/config/categoriasPatrimonio'

import FormCatPatrimonio from '../../../../Components/CatPatrimonio'

import { Notification } from '../../../../Components/notification'

import { Container } from './styled'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'

const CatPatrimonio: React.FC = () => {
  const [loaddingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisisbleForm] = useState(false)

  const [uppdateCatPatrimonio, setUpdateCatPatrimonio] = useState(0)

  const [dataTable, setLDataTable] = useState([])
  const [dataTableRelative, setLDataTableRelative] = useState([])

  const listCatPatrimonio = async () => {
    setLoadingTable(true)
    const response = await getCatPatrimonio({ order: 'nome', pagination: -1 })
    const dados = response.data.itens

    let principal: any = []
    let segundar: any = []

    dados.forEach((data) => {
      if (data.cod_categoria === null) {
        const temp = data

        principal = [...principal, temp]
      }
      return principal
    })

    dados.forEach((data) => {
      if (data.cod_categoria !== null) {
        const temp = data

        segundar = [...segundar, temp]
      }
      return segundar
    })

    const filtrado = principal.concat(segundar)

    setLDataTable(filtrado)
    setLDataTableRelative(filtrado)
    setLoadingTable(false)
  }

  const handleVisibleForm = () => {
    setVisisbleForm(true)
    setUpdateCatPatrimonio(0)
  }

  const handleEditCatPatrimonio = (data) => {
    setUpdateCatPatrimonio(data)
    setVisisbleForm(true)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataTable
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setLDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleTrashCatPatrimonio = async (data, nome) => {
    try {
      Modal.confirm({
        title: 'Excluir',
        content: `Deseja realmente remover a Categoria Patrimônio ${nome}?`,
        icon: <IconAlert type='confirm' size={6} />,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delCatPatrimonio(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Categoria de Patrimônio Excluido',
              type: 'success',
            })

            listCatPatrimonio()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  useEffect(() => {
    listCatPatrimonio()
  }, [])

  return (
    <Container>
      <ViewPage
        title='Categoria de Patrimônio'
        btnClick={handleVisibleForm}
        search
        inputChange={handlesPesquisa}
        content={
          <FormCatPatrimonio
            onShow={visibleForm}
            onClose={setVisisbleForm}
            update={uppdateCatPatrimonio}
            setUpdate={setUpdateCatPatrimonio}
            refresh={listCatPatrimonio}
          />
        }
      >
        <Table
          pagination={pagination}
          size='small'
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTable.length}</b>
            </div>
          )}
          loading={loaddingTable}
          scroll={{ y: 'calc(90vh - 255px)' }}
          dataSource={orderBy(dataTableRelative, 'nome')}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleEditCatPatrimonio(record.cod)
              },
            }
          }}
          columns={[
            {
              key: 1,
              dataIndex: 'cod',
              title: 'Codigo',
              width: '100px',
            },
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 3,
              dataIndex: 'cod_categoria',
              title: 'Categoria ',
              render: (data) => {
                const nameCategoria = dataTable.find((info) => info.cod === data)
                return (
                  <>
                    {nameCategoria ? (
                      <Tag>{nameCategoria.nome}</Tag>
                    ) : (
                      <Tag color='green'>Principal</Tag>
                    )}
                  </>
                )
              },
            },
            {
              key: 4,
              dataIndex: 'cod',
              title: 'Ações',
              render: (data, dados) => (
                <>
                  <Button
                    type='primary'
                    shape='circle'
                    disabled={dados.cod_categoria === null && process.env.NODE_ENV === 'production'}
                    className='info-button'
                    onClick={() => handleEditCatPatrimonio(data)}
                    icon={<FaIcon icon={solid('pen')} />}
                    size='middle'
                  />
                  {'   '}
                  <Button
                    shape='circle'
                    disabled={dados.cod_categoria === null && process.env.NODE_ENV === 'production'}
                    onClick={() => handleTrashCatPatrimonio(data, dados.nome)}
                    className='trash-button'
                    type='primary'
                    icon={<FaIcon icon={solid('trash')} />}
                    size='middle'
                  />
                </>
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default CatPatrimonio
