import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesMonitoramentoResumo {
  itens: ItensMonitoramentoResumoTypes[]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface ItensMonitoramentoResumoTypes {
  cod_especie: number
  cod_estadio: number
  cod_monitoramento: number
  cod_nivel_controle_item: number
  cod_propriedade: number
  cod_talhao: number
  cod_variacao_especie: number
  create_time: string
  data_inicio: string
  delete_time: string
  especie_nome: string
  maximo: string
  media: string
  minimo: string
  ponto: string
  pontos: number
  pontos_abaixo: number
  pontos_acima: number
  repeticao: number
  tipo: number
  total: string
  update_time: string
  variacao_nome: string
  estadios_nc?: {
    cod_especie: number
    cod_variacao_especie: number
    cod_estadio: number
    data_inicio: string
    cod_talhao: number
    nc: {
      cod_nivel_controle_item: number
      cod_tipo_metrica: number
      valor: string
      tipo_metrica_nome: string
    }[]
  }[]
}

type TypesQuery = {
  cod?: number
  codTalhao?: number
  codPropriedade?: number
  embed?: string
  dataVigenteSafra?: string
  codMonitoramento?: string
  dataInicioMonitoramento?: string
  tipo?: number
  pagination?: number
  page?: number
}

const url = '/resumo-monitoramento'

export const getResumoMonitoramento = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const { pagination = -1 } = params
  const resp: AxiosResponse<TypesMonitoramentoResumo> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      pagination,
      ...params,
    },
  })

  return resp
}
