import { useQuery } from 'react-query';
import { message } from 'antd';

//APP
import { api } from '../api';

//=================================================================================>
// BUSCAR LISTA DE ELEMENTOS
//<=================================================================================

//TYPES
type Logs__descricao__headers = {
    referer: string | null,
    origin: string | null,
    contentType: string | null,
    userAgent: string | null,
    host: string | null,
}
type Logs__descricao = {
    uri: string,
    httpMethod: 'POST' | 'PUT' | 'DELETE' | 'GET' | 'PATCH',
    queryParams: any[],
    body: any,
    tokenApi: string | null,
    tokenAuth: string | null,
    headers: Logs__descricao__headers,
    ip: string | null,
    authUserName: string | null,
    authUserEmail: string | null,
    authUserId: string | null,
    response: {httpCode: number|string|null, content: any[]},
}
export type ItemRequest = {
    cod: string | null,
    descricao: Logs__descricao,
    usuario_nome: string | null,
    usuario_email: string | null,
    cod_usuario: string | null,
    create_time: string | null,
    update_time: string | null,
    delete_time: string | null,
    delete_user: string | null,
    create_user: string | null,
    update_user: string | null,
    data: string | null,
}
type ParamsReq = {
    method: 'POST' | 'PUT' | 'DELETE' | 'GET' | 'PATCH' | null,
    endpoint: string | null,
    cod: string | null,
    emailUsuario: string | null,
    date: string | null,
    pagination: number,
    page: number,
}
//INTERFACE
export interface LogsInterface {
    itens: ItemRequest[],
    total: number,
    pagina: number,
    quantidadePorPagina: number
}

export const useGetLogs = ({method, endpoint, cod, emailUsuario, date, pagination = 15, page = 1}:ParamsReq) => {
    const queryKey: ['logs'] = [`logs`];

    let paramMethod = method ? `&method=${method}` : '';
    let paramEndpoint = endpoint ? `&endpoint=${endpoint}` : '';
    let paramCod = cod ? `&cod=${cod}` : '';
    let paramEmail = emailUsuario ? `&emailUsuario=${emailUsuario}` : '';
    let paramDate = date ? `&data=${date}` : '';

    const { data, isFetching, refetch, isError } = useQuery<LogsInterface, Error>(queryKey, async () => {
        return api.get(`/log-requisicao?&pagination=${pagination}&page=${page}${paramMethod}${paramEndpoint}${paramCod}${paramEmail}${paramDate}`)
            .then((response):any => {
                return response.data
            })
            .catch((error) => {
                message.error({content: error?.response?.data?.error ?? 'Falha ao obter logs!', duration: 5} );
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_logs: data, 
        carregando_logs: isFetching, 
        refetch_logs: refetch,
        isError: isError
    };
};
