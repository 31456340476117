import styled from "styled-components";
import { Tag } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//APP
import { ListaObs } from "../../../..";

//STYLES
const SelecContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0px 15px 20px;
    border-left: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 15px;

    :is([data-disabled = true]) {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

//COMPONENTS
import { Box } from "../../../../../../../../../../Components/CustomUi/box";
import { TitleH3 } from "../../../../../../../../../../Components/CustomUi/titleH3";
import Label from "../../../../../../../../../../Components/Label";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";

//PROPS
interface Props {
    lista_obs: ListaObs[];
    removerDaListaObs: Function;
    disabled?: boolean;
    cod_alerta?: number;
}

export const Selecionados: React.FC<Props> = ({ lista_obs, removerDaListaObs, disabled, cod_alerta }) => {

    function nivelAlerta(): {descr:string,cor:string} {
        switch (cod_alerta) {
            case 1:
                return { descr: 'Baixo', cor: 'yellow' }
            case 2:
                return { descr: 'Médio', cor: 'orange' }
            case 3:
                return { descr: 'Alto', cor: 'red' }
            default:
                return { descr: '-', cor: '' }
        }
    }

    return (
        <SelecContainer data-disabled={disabled}>
            <TitleH3>Observações Adicionadas</TitleH3>
            {(lista_obs ?? []).map(({ key, estadios, obs })=> {
                return (
                    <Box padding={15} key={key}>
                        <ButtonCustom 
                            onClick={()=>{removerDaListaObs(key)}}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            style={{alignSelf: 'flex-start'}}
                            type="tertiary"
                            danger
                            disabled={disabled}
                        >
                            Deletar
                        </ButtonCustom>    

                        <div style={{display:'flex',flexDirection:'column',gap: 2}}>
                            <Label fontSize={13}>Estádios selecionados</Label>
                            <div className="SelecContainer__itens">
                                {(estadios ?? []).map(({ descr, id })=> {
                                    return (
                                        <Tag color="success" key={id}>
                                            {descr}
                                        </Tag>
                                    )
                                })}
                            </div>  
                        </div>

                        <div style={{display:'flex',flexDirection:'column',gap: 2}}>
                            <Label fontSize={13}>Nível de alerta</Label>
                            <Tag color={nivelAlerta().cor} bordered={false} style={{alignSelf: 'flex-start'}}>
                                {nivelAlerta().descr}
                            </Tag>   
                        </div>
                        


                        <TextArea
                            label={`Observação da compatibilidade`}
                            placeholder='Digite aqui a observação'
                            value={obs}
                            onChange={()=>{}}
                            resize={false}
                            height={70}
                            disabled={disabled}
                        />

                    </Box>
                )
            })}
            
        </SelecContainer>
    )
}