import styled from "styled-components"
import { removerEspacos, ultimaPosicao } from "../../../../utils";
import { faArrowDownWideShort, faArrowUpWideShort, faSort } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState } from "react";
import { Tooltip } from "antd";

//STYLES
const Header = styled.div`
    width: 100%;
    height: 45px;
    display: flex;
    color: var(--text-color);
    background-color: #FAFAFA;
    border-bottom: thin solid rgba(0,0,0,0.06);
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;

    .Header__item {
        width: 100%;
        height: 100%;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        font-weight: 600;
        font-size: 14px;
        color: var(--text-color);
        text-transform: capitalize;
        position: relative;

        button.Header__item__sort {
            font-size: 11px;
            position: absolute;
            right: 5px;
            border: none;
            background: transparent;
            border-radius: 5px;
            cursor: pointer;
            color: var(--text-color-light);

            :hover {
                background: #e6e6e639;
            }
        }
    }
`;

//PROPS
type Itens = {
    id?: number | string,
    valor: string;
    width: number | string;
    typeSort?: 'asc' | 'desc';
    onSort?: Function;
    sorting?: boolean;
}
interface Props {
    itens: Itens[],
    opacity?: number;
}

export const HeaderTable: React.FC<Props> = ({ itens, opacity }) => {

    //STATE
    const [sort_key, setSort_key] = useState([]);
    const [sort, setSort] = useState<boolean>(false);


    function typeIcon(typeSort: 'asc' | 'desc') {
        if (typeSort==='asc') {
            return faArrowDownWideShort;
        }
        return faArrowUpWideShort;
    };

    function typeTitle(typeSort: 'asc' | 'desc') {
        
        if (typeSort==='asc') {
            return 'clique para ordenar de forma ascendente';
        }
        return 'clique para ordenar de forma descendente';
    }

    return (
        <Header style={{opacity: opacity}}>
            {(itens ?? []).map(({ id, valor, width, onSort, typeSort, sorting })=> {
                let key_id = id ?? removerEspacos(valor);
                return (
                    <div 
                        key={key_id}
                        className="Header__item" 
                        style={{
                            width: width,
                            minWidth: width,
                            maxWidth: width,
                            borderRight: ultimaPosicao(itens)?.valor !== valor 
                            ? 'thin solid rgba(0,0,0,0.06)' 
                            : undefined
                        }}
                    >
                        {valor}

                        {!!onSort &&(
                            <Tooltip title={typeTitle(typeSort)}>
                                <button className="Header__item__sort" onClick={()=>{onSort();setSort_key([...sort_key, key_id]);setSort(true)}}>
                                    <FontAwesomeIcon icon={sorting ? typeIcon(typeSort) : faSort} />
                                </button>
                            </Tooltip>
                            
                        )}
                        
                    </div>
                )
            })}
        </Header>
    )
}