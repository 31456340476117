import { notification as notif } from 'antd'
import React from 'react'
import { IconAlert } from '../iconsAlert'

interface NotificationPros {
  message: string
  descricao?: React.ReactNode
  type: 'error' | 'info' | 'warning' | 'success' | 'confirm'
  placement?: 'topRight' | 'topLeft' | 'top' | 'bottom' | 'bottomLeft' | 'bottomRight'
  duration?: number | 3
}

export const removeNotification = () => {
  const elements = document.getElementsByClassName('ant-notification-middleTop')
  while (elements.length > 0) {
    elements[0].parentNode.removeChild(elements[0])
  }
}

export const Notification = ({
  message: messagem,
  descricao,
  type,
  duration,
  placement = 'topRight',
}: NotificationPros): any => {
  return notif[type]({
    message: messagem,
    description: descricao,
    placement,
    duration,
    icon: <IconAlert type={type} size={8} />,
  })
}
