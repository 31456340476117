import styled from 'styled-components'

export const ContainerHeader = styled.header`
  margin-top: 10px;
  margin-bottom: 10px;
`

export const ContainerFooter = styled.footer`
  margin-top: 10px;
  margin-bottom: 10px;
`

export const Title = styled.div`
  min-width: 100%;
  text-align: center;
`
export const Logo = styled.div`
  width: 100%;
  display: flex;
  justify-content: left;
  align-items: left;
  text-align: left;
  font-size: 11px;

  img {
    width: 75px;
  }
`
export const Linear = styled.img`
  width: 100%;
  height: 10px;
`
export const Address = styled.small`
  font-size: '10px';
  margin: 0;
`
