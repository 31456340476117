import { TypesNavigate } from "../../../Configs/Navegacao";

export function isLastSubmenu(sebmenu_list: TypesNavigate, path: string) {

    let removes = (sebmenu_list?.submenu ?? []).filter(item=> item.hidden === false);
    let last_item = removes?.[removes?.length -1]?.path; 

    if (path === last_item) {
        return true;
    }
    return false;
}
// export function isLastSubmenu(sebmenu_list: TypesNavigate, index: number) {

//     let removes = (sebmenu_list?.submenu ?? []).filter(item=> item.hidden === false)
//     let size_list: number = (removes ?? [])?.length ?? 0;
//     let current_menu_item: number = index + 1;

//     // console.log("submenu: ", sebmenu_list?.submenu);
//     if (removes?.[0]?.title === 'Mapa de fertilidade') {
//         console.log("removes: ", removes);
//         console.log("size_list: ", size_list);
//         console.log("current_menu_item: ", current_menu_item)
//     }
    

//     if (size_list === current_menu_item) {
//         return true;
//     }
//     return false;
// }