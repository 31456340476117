import styled, { css, keyframes } from 'styled-components'

const animateIn = keyframes`
  0% {
    width: 3rem;
    min-height: 3rem;
  };
  100% {
    width: 100%;
    min-height: 100%;
  }
`

const animateOut = keyframes`
  100% {
    width: 3rem;
    min-height: 3rem;
  };
  0% {
    width: 100%;
    min-height: 100%;
  }
`

const animateBodyIn = keyframes`
  0% {
    visibility: hidden;
    padding: 0;
    transform: translateY(-100rem);
  };
  100% {
    visibility: visible;
    padding: 12px;
    transform: translateX(0);
  }
`

const animateBodyOut = keyframes`
  100% {
    visibility: hidden;
    padding: 0;
    transform: translateY(-100rem);
  };
  0% {
    visibility: visible;
    padding: 12px;
    transform: translateY(0);
  }
`
const animateBorderIn = keyframes`
  0% {
    height: 50px;
  };
  100% {
    height: auto;
  }
`

const animateBorderOut = keyframes`
  100% {
    height: 50px;
  };
  0% {
    height: auto;
  }
  `

export const Container = styled.div<{ toggle: boolean }>`
  position: absolute;
  width: ${(p) => (p.toggle ? '3rem' : '100%')};
  min-height: ${(p) => (p.toggle ? '3rem' : '100%')};
  transition: 0.5s;
  ${(p) =>
    !p.toggle
      ? css`
          animation: ${animateIn} 0.3s ease-in;
          .ant-card-bordered {
            height: auto;
            animation: ${animateBorderIn} 0.5s ease-in;
          }
          .ant-card-body,
          .ant-card-actions {
            visibility: visible;
            transform: translateY(0);
            padding: 12px;
            animation: ${animateBodyIn} 0.3s ease-in;
          }
        `
      : css`
          animation: ${animateOut} 0.3s ease-out;
          .ant-card-bordered {
            height: 50px;
            animation: ${animateBorderOut} 0.5s ease-in;
          }
          .ant-card-body,
          .ant-card-actions {
            visibility: hidden;
            transform: translateY(-100rem);
            padding: 0;
            animation: ${animateBodyOut} 0.3s ease-in;
          }
        `}
  .btn-save {
    bottom: 0%;
    display: ${(p) => (p.toggle ? 'none' : 'flex')};
    width: 100%;
    justify-content: space-between;
    align-items: center;
  }
`
