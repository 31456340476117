import React, { useState } from 'react'
import FormDocumentos from '../documentos'
import Input from '../../../CustomUi/input'
import { Modal, Upload } from 'antd/lib'
import ButtonCustom from '../../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faBarcode,
  faBriefcaseMedical,
  faCreditCard,
  faEye,
  faHandHoldingDroplet,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import Svg from '@ant-design/icons'
import { getEquipeFiles, TypesEquipe } from '../../../../services/Equipe'
import { ContainerSaude } from './styled'
import { Select } from '../../../CustomUi/Select'
import { listTipoSanguineo } from '../../meta/index '
import { getB64Type } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

interface PropDocumentoSus {
  doc_sus: any[]
}

interface FormSaude {
  tipoSanguineo?: string
  setTipoSanguineo?: React.Dispatch<React.SetStateAction<string>>
  cartaoSus?: string
  setCartaoSus?: React.Dispatch<React.SetStateAction<string>>
  planoSaude?: string
  setPlanoSaude?: React.Dispatch<React.SetStateAction<string>>
  cartaoParticular?: string
  setCartaoParticular?: React.Dispatch<React.SetStateAction<string>>
  documentoSus?: PropDocumentoSus
  setDocumentosSus?: React.Dispatch<React.SetStateAction<PropDocumentoSus>>
  permissions?: ItensPerUserLogged
}

const FormSaude: React.FC<FormSaude> = ({
  tipoSanguineo,
  setTipoSanguineo,
  cartaoSus,
  setCartaoSus,
  planoSaude,
  setPlanoSaude,
  cartaoParticular,
  setCartaoParticular,
  documentoSus,
  setDocumentosSus,
  permissions
}) => {
  const [openTipoSanguineo, setOpenTipoSanguineo] = useState(false)

  const onChangeDocuments = async (tipo, data: TypesEquipe = {} as TypesEquipe) => {
    if (tipo === null) {
      const usuario = data.itens[0].usuario[0]
      const codEquipe = data.itens[0].cod

      let temp = documentoSus
      Object.entries(usuario).forEach(async ([key, value]) => {
        const oldImg = String(value).split('_')

        if (oldImg.length <= 1) {
          if (key === 'doc_sus') {
            if (value !== null) {
              const foto = await getEquipeFiles({
                doc: value,
                // @ts-ignore
                docType: key,
                cod: codEquipe,
              })

              temp = { ...temp, [key]: [foto.fileString] }
            }
          }
        }

        setDocumentosSus(temp)
      })
    } else {
      setDocumentosSus({ ...documentoSus, ...tipo })
    }
  }

  const onPreviewDocuments = async (file, type = null) => {
    let src = documentoSus[file].file
    if (type === null) type = documentoSus[file].file?.type

    if (src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(documentoSus[file].file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    } else {
      src = documentoSus[file]
      type = getB64Type(src)
    }

    switch (file) {
      case 'doc_cnh':
        file = 'CNH'
        break
    }

    Modal.info({
      title: file,
      width: '1024px',
      style: { top: '10px' },
      okText: 'Fechar',
      icon: (
        <Svg
          component={() => (
            <FontAwesomeIcon
              icon={type === 'application/pdf' ? regular('file-pdf') : regular('image')}
            />
          )}
        />
      ),
      content: (
        <embed
          width={type === 'application/pdf' ? '100%' : null}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
            maxWidth: type !== 'application/pdf' ? '100%' : null,
          }}
          src={src}
        />
      ),
    })
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  return (
    <ContainerSaude>
      <div className='lines'>
        <Select
          label='Tipo sanguíneo'
          icon={faHandHoldingDroplet}
          placeholder='Digite o Tipo sanguíneo'
          value={tipoSanguineo}
          onChange={setTipoSanguineo}
          open={openTipoSanguineo}
          trigger={setOpenTipoSanguineo}
          items={listTipoSanguineo}
          descricaoItem={'nome'}
          idItem={'cod'}
          disabled={permissions?.acesso === 1}
        />
        <div className='campo-input-upload'>
          <Input
            label='Cartão SUS'
            icon={faCreditCard}
            placeholder='000.000.000-00'
            value={cartaoSus}
            onChange={setCartaoSus}
            width={'100%'}
            desabilitado={permissions?.acesso === 1}
          />
          <div className='campo-upload-preview'>
            <Upload
              className='upload-button'
              customRequest={dummyRequest}
              maxCount={1}
              accept='image/png, image/jpeg, .pdf'
              onChange={(file) => onChangeDocuments({ doc_sus: file })}
              onPreview={onPreview}
              showUploadList={false}
            >
              <ButtonCustom
                style={{ height: '36px' }}
                onClick={() => {}}
                icon={<FontAwesomeIcon icon={faUpload} />}
              ></ButtonCustom>
            </Upload>

            <ButtonCustom
              type='secondary'
              disabled={documentoSus?.doc_sus === undefined || documentoSus?.doc_sus?.length <= 0}
              onClick={() => onPreviewDocuments('doc_sus')}
              style={{ height: '37px' }}
              icon={<FontAwesomeIcon icon={faEye} />}
            ></ButtonCustom>
          </div>
        </div>
      </div>
      <div className='lines'>
        <Input
          label='Plano de saúde particular'
          icon={faBriefcaseMedical}
          placeholder='Plano de saúde particular'
          value={planoSaude}
          onChange={setPlanoSaude}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
        <Input
          label='Nº cartão plano de saúde'
          icon={faBarcode}
          placeholder='Nº cartão plano de saúde'
          value={cartaoParticular}
          onChange={setCartaoParticular}
          width={'100%'}
          desabilitado={permissions?.acesso === 1}
        />
      </div>
    </ContainerSaude>
  )
}

export default FormSaude
