// @ts-ignore
import tokml from 'tokml'

export const geraKml = (data, nome) => {
  let feature: any = []

  data.pontos.forEach((info) => {
    const temp = {
      type: 'Feature',
      geometry: {
        type: 'Point',
        title: info.NOME,
        coordinates: [parseFloat(info.LONGITUDE), parseFloat(info.LATITUDE)],
      },
      properties: {
        INDICE: info.INDICE,
        NOME: info.NOME,
        COMENTARIO: info.NOME,
        LATITUDE: info.LATITUDE,
        LONGITUDE: info.LONGITUDE,
      },
    }

    feature = [...feature, temp]

    return feature
  })

  const geoJson = {
    type: 'FeatureCollection',
    features: feature,
    fileName: nome,
  }

  const kml = tokml(geoJson)

  return kml
}

export const geralKmlPolygon = (data, nome: string) => {
  let positions = []

  data.forEach((item) => {
    let temp = [];

    if (item.lng !== undefined && item.lat !== undefined) {
      temp = [{ LONGITUDE: item.lng, LATITUDE: item.lat }];
    } else if (item.lat !== undefined && item.lat !== undefined) {
      temp = [{ LONGITUDE: item.lng, LATITUDE: item.lat }];
    }

    positions = [...positions, ...temp];

  });

  const kml = geraKmlTrakmaker(positions, nome);

  // console.log("kml", kml)
  return kml
}
export const gerarBaixarlKmlPolygon = (data, nome: string) => {
  let positions = []

  data.forEach((item) => {
    let temp = [];

    if (item.longitude !== undefined && item.latitude !== undefined) {
      temp = [{ LONGITUDE: item.longitude, LATITUDE: item.latitude }];
    } else if (item.lat !== undefined && item.lat !== undefined) {
      temp = [{ LONGITUDE: item.longitude, LATITUDE: item.latitude }];
    }

    positions = [...positions, ...temp];

  });

  const kml = geraKmlTrakmaker(positions, nome);

  const blob = new Blob([kml], { type: 'application/vnd.google-earth.kml+xml' });

   // Cria uma URL para o blob
   const url = URL.createObjectURL(blob);

   // Cria um link temporário para o download
   const link = document.createElement('a');
   link.href = url;
   link.download = `${nome}.kml`;

   // Aciona o download e limpa o link
   link.click();
   URL.revokeObjectURL(url);

  return null;
}





export const geraKmlTrakmaker = (array, nome: string) => {
  const coordinates = array.map(data => `${data.LONGITUDE},${data.LATITUDE},0`).join(' ');
  const kml =
    `<?xml version="1.0" encoding="UTF-8"?>
<kml xmlns="http://www.opengis.net/kml/2.2" xmlns:gx="http://www.google.com/kml/ext/2.2" xmlns:kml="http://www.opengis.net/kml/2.2" xmlns:atom="http://www.w3.org/2005/Atom">
<Document>
	<name>${nome}.kml</name>
	<Style id="poly-000000-1200-77-highlight">
		<LineStyle>
			<color>ff000000</color>
			<width>1.8</width>
		</LineStyle>
		<PolyStyle>
			<color>4d000000</color>
		</PolyStyle>
	</Style>
	<Style id="poly-000000-1200-77-normal">
		<LineStyle>
			<color>ff000000</color>
			<width>1.2</width>
		</LineStyle>
		<PolyStyle>
			<color>4d000000</color>
		</PolyStyle>
	</Style>
	<StyleMap id="poly-000000-1200-77">
		<Pair>
			<key>normal</key>
			<styleUrl>#poly-000000-1200-77-normal</styleUrl>
		</Pair>
		<Pair>
			<key>highlight</key>
			<styleUrl>#poly-000000-1200-77-highlight</styleUrl>
		</Pair>
	</StyleMap>
	<Placemark>
  <name>${nome}</name>
		<description><![CDATA[descricao: <br>NOME: ${nome}]]></description>
		<styleUrl>#poly-000000-1200-77</styleUrl>
		<ExtendedData>
			<Data name="descricao">
			</Data>
			<Data name="NOME">
				<value>${nome}</value>
			</Data>
		</ExtendedData>
		<MultiGeometry>
			<Polygon>
				<outerBoundaryIs>
					<LinearRing>
						<tessellate>1</tessellate>
						<coordinates>
${coordinates}
						</coordinates>
					</LinearRing>
				</outerBoundaryIs>
			</Polygon>
		</MultiGeometry>
	</Placemark>
</Document>
</kml>`
  return kml;
}


export const geralKmlLab = (array, nome: string) => {
  let positions = []

  const indice = (array.pontos.length / 2).toFixed(0)

  const centro = array.pontos[indice]

  array.pontos.forEach((data) => {
    const tmp = `<Placemark>
          <name>${data.NOME}</name>
          <description>${data.NOME}</description>
          <styleUrl>#waypoint</styleUrl>
          <ExtendedData>
            <Data name="INDICE">
               <value>${data.INDICE}</value>
            </Data>
            <Data name="NOME">
               <value>${data.NOME}</value>
            </Data>
            <Data name="COMENTARIO">
               <value>${data.NOME}</value>
            </Data>
            <Data name="LATITUDE">
               <value>${data.LATITUDE}</value>
            </Data>
            <Data name="LONGITUDE">
               <value>${data.LONGITUDE}</value>
            </Data>
         </ExtendedData>
          <Point>
            <coordinates>${data.LONGITUDE},${data.LATITUDE},0</coordinates>
          </Point>
      </Placemark>`

    positions = [...positions, tmp]

    return positions
  })

  const kml = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://earth.google.com/kml/2.0">
     <Document>
        <name>GPS ttivos</name>
        <visibility>1</visibility>
        <Style id="waypoint">
           <Icon>
              <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
           </Icon>
        </Style>
        <Style id="route">
           <Icon>
              <href>root://icons/bitmap-4.png?x=160&amp;y=0&amp;w=32&amp;h=32</href>
           </Icon>
        </Style>
        <LookAt>
           <longitude>${centro.LONGITUDE}</longitude>
           <latitude>${centro.LATITUDE}</latitude>
           <range>3994</range>
        </LookAt>
        <Folder>
           <name>${nome}</name>
           <visibility>1</visibility>
           ${positions}
        </Folder>
    </Document>
  </kml>`

  return kml
}

export const geralKmlPontos = (array, nome: string) => {
  let positions = []

  const indice = (array.pontos.length / 2).toFixed(0)

  const centro = array.pontos[indice]

  array.pontos.forEach((data) => {
    const tmp = `<Placemark>
          <name>${data.NOME}</name>
          <description>${data.NOME}</description>
          <styleUrl>#waypoint</styleUrl>
          <ExtendedData>
            <Data name="INDICE">
               <value>${data.INDICE}</value>
            </Data>
            <Data name="NOME">
               <value>${data.NOME}</value>
            </Data>
            <Data name="COMENTARIO">
               <value>${data.NOME}</value>
            </Data>
            <Data name="LATITUDE">
               <value>${data.LATITUDE}</value>
            </Data>
            <Data name="LONGITUDE">
               <value>${data.LONGITUDE}</value>
            </Data>
            <Data name="NOME_TALHAO">
               <value>${data.NOME_TALHAO}</value>
            </Data>
            <Data name="COD">
               <value>${data.COD}</value>
            </Data>
            <Data name="COD_TALHAO">
               <value>${data.COD_TALHAO}</value>
            </Data>
         </ExtendedData>
          <Point>
            <coordinates>${data.LONGITUDE},${data.LATITUDE},0</coordinates>
          </Point>
      </Placemark>`

    positions = [...positions, tmp]

    return positions
  })

  const kml = `<?xml version="1.0" encoding="UTF-8"?>
  <kml xmlns="http://earth.google.com/kml/2.0">
     <Document>
        <name>Ttivos - Pontos</name>
        <visibility>1</visibility>
        <Style id="waypoint">
           <Icon>
              <href>http://maps.google.com/mapfiles/kml/pushpin/ylw-pushpin.png</href>
           </Icon>
        </Style>
        <Style id="route">
           <Icon>
              <href>root://icons/bitmap-4.png?x=160&amp;y=0&amp;w=32&amp;h=32</href>
           </Icon>
        </Style>
        <LookAt>
           <longitude>${centro.LONGITUDE}</longitude>
           <latitude>${centro.LATITUDE}</latitude>
           <range>3994</range>
        </LookAt>
        <Folder>
           <name>${nome}</name>
           <visibility>1</visibility>
           ${positions}
        </Folder>
    </Document>
  </kml>`

  return kml
}
