import styled from 'styled-components'

export const Container = styled.div`
  td.descriptions-title {
    padding-bottom: 0 !important;
  }

  .ant-table-small .ant-table-thead > tr > th {
    background-color: #e6e3e3;
  }
`
