import React, { useEffect, useState } from 'react'
import { Button, Modal, Pagination, Popconfirm, Space, Table, Tag } from 'antd'
import Icon from '@ant-design/icons'
import { AxiosResponse } from 'axios'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { faCopy } from '@fortawesome/free-solid-svg-icons'
import ViewPage from '../../../../Components/ViewPage'
import {
  delInsumos,
  getInsumos,
  InsumosItens,
  TypeInsumos,
} from '../../../../services/recomendacao/insumos'
import { pagination } from '../../../../Components/Default'
import { IconAlert } from '../../../../Components/iconsAlert'
import { Notification } from '../../../../Components/notification'
import FormInsumos from '../../../../Components/recomendacao/insumos'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { filterTable, tryError } from '../../../../services/Afins'
import Icons from '../../../../Components/Icons'

const RecomendaInsumos: React.FC = () => {
  const [visibleForm, setVisibleForm] = useState(false)
  const [update, setUpdate] = useState(0)

  const [dataInsumos, setDataInsumos] = useState<InsumosItens[]>([])
  const [tempInsumos, setTempInsumos] = useState([])

  const [totalInsumos, setTotalInsumos] = useState(0)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [loadingTable, setLoadingTable] = useState(false)

  const [clone, setClone] = useState(0)

  const [itensExibidos, setItensExibidos] = useState(15)

  const [currentPage, setCurrentPage] = useState(1)
  const [pageSize, setPageSize] = useState(15)
  const [total, setTotal] = useState(0)
  const [totalItems, setTotalItems] = useState(0)
  const [searchValue, setSearchValue] = useState('')

  //   setLoadingTable(true)
  //   const quantidadeDesejada = 10;
  //   const resp: AxiosResponse<TypeInsumos> = await getInsumos({
  //     embed: 'composicao,aplicacoes',
  //     pagination: quantidadeDesejada,
  //   })

  //   setDataInsumos(resp.data.itens)
  //   setTempInsumos(resp.data.itens)
  //   setLoadingTable(false)
  // }

  // const listInsumos = async (pagination = 15) => {
  //   setLoadingTable(true);

  //   const resp: AxiosResponse<TypeInsumos> = await getInsumos({
  //     embed: 'composicao,aplicacoes',
  //     pagination,
  //   });

  //   setDataInsumos(resp.data.itens);
  //   setTempInsumos(resp.data.itens);
  //   setLoadingTable(false);
  // };

  const handlePageChange = (page) => {
    setCurrentPage(page)
    listInsumos(page)
  }

  const handlePageSizeChange = (size) => {
    setDataInsumos([])

    setPageSize(size)
    setCurrentPage(1)

    listInsumos(1, size)
  }

  // Objeto para armazenar os dados das páginas
  const pageData = {}

  const getInsumoPage = async (page: number, pagination?: number) => {
    setLoadingTable(true)
    const resp = await getInsumos({
      embed: 'composicao,aplicacoes',
      pagination: pagination,
      page: page,
      nome: searchValue,
    })

    const dados = resp.data.itens

    const arrayObjectInsumos = (insumo) => ({ ...insumo, [page]: dados })

    setDataInsumos(arrayObjectInsumos)

    // Calcula o número total de páginas necessárias
    const totalPages = Math.ceil(resp.data.total / pageSize)

    setTotalItems(resp.data.total)
    setTotal(resp.data.total)
    setTotalInsumos(resp.data.itens.length)
    setLoadingTable(false)

    return dados
  }

  const listInsumos = async (page = 1, pagination = pageSize) => {
    const insumos = await getInsumoPage(page, pagination)

    setTotalInsumos(insumos.length)
    setTempInsumos(insumos)
    setCurrentPage(page)
    setLoadingTable(false)
  }

  const handleClickNewTipo = () => {
    setVisibleForm(true)
  }

  const handleClickEdit = (cod) => {
    setUpdate(cod)
    setVisibleForm(true)
  }

  const handleClickClone = (cod) => {
    setClone(cod)
    setVisibleForm(true)
  }

  const handleSearch = async (data) => {
    const dados = dataInsumos
    const nome: any = data.target.value.toLowerCase()
    setSearchValue(nome)

    const arrayResultante = Object.values(dados).reduce(
      (accumulator, array) => accumulator.concat(array),
      []
    )

    setTimeout(async () => {
      if (nome.length >= 3) {
     
        setLoadingTable(true)
        const resp = await getInsumos({
          embed: 'composicao,aplicacoes',
          pagination: -1,
          nome: nome,
        })

        setTotalItems(resp.data.total)
        setTempInsumos(resp.data.itens)
        setLoadingTable(false)
      }
    }, 500)

    if (nome.length <= 0) {
      setLoadingTable(true)
      const resp = await getInsumos({
        embed: 'composicao,aplicacoes',
        pagination: pageSize,
        page: currentPage,
        nome: '',
      })

      setTotalItems(resp.data.total)
      setTempInsumos(resp.data.itens)
      setLoadingTable(false)
    }
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o Insumo ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        try {
          const response = await delInsumos(cod)

          if (response.status === 200) {
            Notification({
              message: 'Insumo removido com sucesso',
              type: 'success',
            })

            listInsumos()
          }
        } catch (error) {
          tryError(error)
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    if (data) {
      listInsumos()
    } else {
      setUpdate(0)
      setClone(0)
    }
  }

  let countItensPage = currentPage
  useEffect(() => {
    setLoadingTable(true)
    listInsumos(countItensPage)
  }, [])

  return (
    <ViewPage
      rotina={38}
      onPermission={setPermissions}
      title='Catálogo insumos'
      btnClick={handleClickNewTipo}
      search
      inputChange={handleSearch}
      content={
        <FormInsumos
          permissions={permissions}
          onClose={(data) => handleCloseForm(data)}
          visible={visibleForm}
          update={update}
          codClone={clone}
        />
      }
    >
      <Table
        rowKey='cod'
        bordered
        loading={loadingTable}
        size='small'
        onChange={(e, b, c, d) => setTotalInsumos(d.currentDataSource.length)}
        dataSource={tempInsumos.slice(0, pageSize)}
        scroll={{ y: 'calc(90vh - 275px)' }}
        pagination={false}
        onRow={(record) => {
          return {
            onDoubleClick: () => {
              handleClickEdit(record.cod)
            },
          }
        }}
        columns={[
          {
            key: 1,
            title: 'Nome',
            dataIndex: 'nome',
            ...filterTable(true, false, 'nome', tempInsumos, false),
          },
          {
            key: 2,
            title: 'Fabricante',
            dataIndex: 'fabricante_nome',
            ...filterTable(true, true, 'fabricante_nome', tempInsumos),
          },
          {
            key: 3,
            title: 'Categoria do insumo',
            width: 220,
            dataIndex: 'categoria_produto_nome',
            ...filterTable(true, true, 'categoria_produto_nome', tempInsumos),
          },
          {
            key: 4,
            title: 'Composição',
            dataIndex: 'composicao',
            render: (data: InsumosItens['composicao']) => {
              return data.map((info) => (
                <Tag key={info.cod} color='blue'>
                  {info.ativo_nome}
                </Tag>
              ))
            },
          },
          {
            key: 5,
            title: 'Culturas',
            dataIndex: 'aplicacoes',
            render: (data: InsumosItens['aplicacoes']) => {
              return data.map((info) => (
                <Tag key={info.cod} color='blue'>
                  {info.cultura_nome}
                </Tag>
              ))
            },
          },
          {
            key: 6,
            width: 135,
            title: 'Ações',
            dataIndex: 'cod',
            render: (data, record) => (
              <Space>
                <Button
                  hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                  shape='circle'
                  type='default'
                  onClick={() => handleClickEdit(data)}
                  icon={
                    <Icon
                      component={() => (
                        <FontAwesomeIcon
                          icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')}
                        />
                      )}
                    />
                  }
                />
                <Popconfirm
                  placement='bottomLeft'
                  okText='Sim'
                  title={`Deseja clonar o insumo ${record.nome}?`}
                  onConfirm={() => handleClickClone(data)}
                >
                  <Button
                    hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                    shape='circle'
                    icon={<Icon component={() => <Icons icon={faCopy} />} />}
                  />
                </Popconfirm>
                <Button
                  hidden={permissions?.exclusao === 0}
                  shape='circle'
                  type='primary'
                  danger
                  onClick={() => handleClickTrash(data, record.nome)}
                  icon={<Icon component={() => <FontAwesomeIcon icon={solid('trash')} />} />}
                />
              </Space>
            ),
          },
        ]}
        footer={() => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between', // ou 'flex-end' para alinhar à direita
              alignItems: 'center', // ajuste conforme necessário
              width: '100%',
            }}
          >
            <b>Total por geral: {totalItems}</b>

            {/* 
            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={(current, size) => handlePageSizeChange(size)}
            /> */}

            <Pagination
              current={currentPage}
              total={totalItems}
              pageSize={pageSize}
              onChange={handlePageChange}
              showSizeChanger
              onShowSizeChange={(current, size) => handlePageSizeChange(size)}
            />
          </div>
        )}
      />
    </ViewPage>
  )
}

export default RecomendaInsumos
