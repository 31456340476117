import { AxiosResponse } from 'axios'
import { api } from '../api'

export interface TypeUserTesterApp {
  itens: [UserTesterAppItens]
}

export interface UserTesterAppItens {
  cod_conta: number
  cod_usuario: number | string
  cod_aplicativo: number
  conta_nome: string
  usuario_nome: string
}

interface TypeQuery {
  pagination?: number | -1
}

const url = '/usuario-teste-app'

export const getUserTesterApp = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    pagination,
    ...params,
  }

  const response: AxiosResponse<TypeUserTesterApp> = await api.get(url, {
    params,
  })

  return response
}

export const postUserTesterApp = async (data) => {
  const response: AxiosResponse<TypeUserTesterApp> = await api.post(url, data, {
    params: {},
  })

  return response
}

export const putUserTesterApp = async (cod, data) => {
  const response: AxiosResponse<TypeUserTesterApp> = await api.put(`${url}/${cod}`, data, {
    params: {},
  })

  return response
}

export const delUserTesterApp = async (cod) => {
  const response: AxiosResponse<TypeUserTesterApp> = await api.delete(`${url}/${cod}`, {
    params: {},
  })

  return response
}
