import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import { Notification } from '../../notification'
import {
  getFormulacao,
  postFormulacao,
  putFormulacao,
  TypeFormulacao,
} from '../../../services/recomendacao/formulacao'
import { tiposSistema } from '../../../services/Afins'

interface TypesFormulacao {
  visible: boolean
  update: number
  onClose: (data: boolean) => void
}

const FormFormulacao: React.FC<TypesFormulacao> = (props) => {
  const { visible, update, onClose } = props

  const [form] = Form.useForm()

  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataTipos, setDataTipos] = useState([])
  const [nameInput, setNameInput] = useState<string>('')

  const editAtivo = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse<TypeFormulacao> = await getFormulacao({
      cod,
    })

    form.setFieldsValue(resp.data.itens[0])
    message.destroy()
  }

  const listTipoFormulacao = async () => {
    const resp = await tiposSistema(31)

    setDataTipos(resp)
  }

  const handleDrawerClose = () => {
    onClose(false)
    form.resetFields()
    setNameInput('')
  }

  const handleChangeInputName = (value) => {
    setNameInput(value)
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postFormulacao(data)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Formulação salva com sucesso',
          })
          onClose(true)
          form.resetFields()
          message.destroy()
          setNameInput('')
        }
      } else {
        const resp = await putFormulacao(update, data)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Formulação atualizada com sucesso',
          })
          message.destroy()
          onClose(true)
          setNameInput('')
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setVisibleDrawer(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editAtivo(update)
    }
  }, [update])

  useEffect(() => {
    if (nameInput.length >= 1) {
      const split = nameInput.toLocaleUpperCase().split(' ')
      if (split.length >= 2) {
        form.setFieldsValue({ sigla: `${split[0].substring(0, 1)}${split[1].substring(0, 1)}` })
      } else {
        form.setFieldsValue({
          sigla: `${nameInput.toLocaleUpperCase().substring(0, 1)}${nameInput
            .toLocaleUpperCase()
            .substring(1, 1)}`,
        })
      }
    } else {
      form.resetFields(['sigla'])
    }
  }, [nameInput])

  useEffect(() => {
    listTipoFormulacao()
  }, [])

  return (
    <Form form={form} onFinish={onFinish} layout='vertical'>
      <Drawer
        placement='left'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={visibleDrawer}
        closeIcon={false}
        onClose={() => handleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => handleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form.Item label='Nome' name='nome'>
          <Input onChange={(data) => handleChangeInputName(data.target.value)} />
        </Form.Item>
        <Form.Item label='Tipo' name='cod_tipo'>
          <Select
            options={dataTipos.map((rec) => {
              return { label: rec.nome, value: rec.cod }
            })}
          />
        </Form.Item>
        <Form.Item label='Sigla' name='sigla'>
          <Input maxLength={15} style={{ width: '100px' }} />
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default FormFormulacao
