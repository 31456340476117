import styled from "styled-components";

export const TituloBook = styled.div`
    display: flex;
    justify-content: space-between;
    align-items: center;
    box-sizing: border-box;
    padding: 17px 15px;
    border: thin solid var(--border-color);
    border-radius: 7px;
    width: 100%;

    h2.TituloPagina__titulo {
        font-weight: 700;
        color: var(--text-color);
        display: flex;
        margin: 0;
        font-size: 18px;
    } 
    h2.TituloPagina__titulo:is([data-reduced = true]) {
        font-size: 16px;
    } 

    div.TituloPagina__sub {
        display: flex;
        align-items: center;
        height: 100%;
        gap: 5px;
        white-space: nowrap;

        p.TituloPagina__sub__profundidade {
            font-weight: 700;
            color: var(--text-color-light);
            margin: 0;
            font-size: 16px;
        }

        p.TituloPagina__sub__mapa {
            font-weight: 400;
            color: #00A8B9;
            margin: 0;
            font-size: 16px;
            display: flex;
            gap: 4px;
        }

    }

`