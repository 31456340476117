import styled from "styled-components";
import { faPagelines } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Button, Empty, Modal, Popconfirm, Tag } from "antd";
import { faAdd, faBatteryFull, faBatteryQuarter, faChartArea, faFloppyDisk, faPenToSquare, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { usePostDose } from "../../../../../../../../services/recomendacao/ativos/post";
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { apenasNumeros, gerarKey } from "../../../../../../../../utils";
import { useDeleteCompatibilidade } from "../../../../../../../../services/recomendacao/ativos/delete";
import { useGetEstadiosByCultura, useGetRecomendacaoAtivosCadastrados, useGetRecomendacaoCultura } from "../../../../../../../../services/recomendacao/ativos/novo-index";

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../Components/CustomUi/textarea";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { Select } from "../../../../../../../../Components/CustomUi/Select";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { Warning } from "../../../../../../../../Components/CustomUi/warning";
import { RowItem } from "../../../../../../../../Components/CustomUi/table/rowItem";
import { Switcher } from "../../../../../../../../Components/CustomUi/Switcher";
import { RowTable } from "../../../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../../../Components/CustomUi/table/bodyTable";
import { obterValor } from "../compatibilidades/meta";
import { SelectMulti } from "../../../../../../../../Components/CustomUi/SelectMulti";
import { ModalEditar } from "./components/editar";
import { HeaderTable } from "../../../../../../../../Components/CustomUi/table/headerTable";
import { Selecionados } from "./components/selecionados";

//STYLES
const Container = styled.div`
    gap: 15px;
    width: 100%;
    display: flex;
    padding: 15px;
    flex-direction: column;
    box-sizing: border-box;
`;
const BodyDrawer = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: thin solid var(--border-color);
`;

//PROPS
interface Props {
    id_tipo_principal: number | null;
    id_item_principal: number | null;
    estadios_selecionados_principal: {descr:string,id:number}[];
    openDropdownDoses: boolean;
    setopenDropdownDoses: React.Dispatch<React.SetStateAction<boolean>>;
    nome_culturaDoses: string;
    setNome_culturaDoses: React.Dispatch<React.SetStateAction<string>>;
    id_culturaDoses: number | null;
    setId_culturaDoses: React.Dispatch<React.SetStateAction<number | null>>;
    todos_estadiosDoses: boolean;
    setTodos_estadiosDoses: React.Dispatch<React.SetStateAction<boolean>>;
    estadios_selecionadosDoses: {descr: string, id: number, key?: number}[];
    setEstadios_selecionadosDoses: React.Dispatch<React.SetStateAction<{descr: string, id: number, key?: number}[]>>;
    openDropdownEstadiosDoses: boolean;
    setOpenDropdownEstadiosDoses: React.Dispatch<React.SetStateAction<boolean>>;
    dose_minima: string;
    setDose_minima: React.Dispatch<React.SetStateAction<string>>;
    dose_maxima: string;
    setDose_maxima: React.Dispatch<React.SetStateAction<string>>;
    id_ativo_selecionado: number;
    atualizarListaPrincipal: Function;
}

export const Doses: React.FC<Props> = (props) => {

    //PROPS
    const { openDropdownDoses, setopenDropdownDoses, nome_culturaDoses, 
        setNome_culturaDoses, id_culturaDoses, setId_culturaDoses, 
        todos_estadiosDoses, setTodos_estadiosDoses, estadios_selecionadosDoses, 
        setEstadios_selecionadosDoses, openDropdownEstadiosDoses, 
        setOpenDropdownEstadiosDoses, dose_minima, setDose_minima, 
        dose_maxima, setDose_maxima, id_ativo_selecionado, atualizarListaPrincipal, 
        id_tipo_principal, id_item_principal } = props;

    function limparStates() {
        setNome_culturaDoses('');
        setId_culturaDoses(null);
        setEstadios_selecionadosDoses([]);
        setEstadios_ja_selecionados([]);
        setDose_maxima('');
        setDose_minima('');
        setObs('');
        setLista_obs([]);
    }
    
    //STATES
    const [search, setSearch] = useState<string>('');
    const [lista_obs, setLista_obs] = useState([]);
    const [obs, setObs] = useState('');
    const [estadios_ja_selecionados, setEstadios_ja_selecionados] = useState([]);
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [nome_item_atual, setNome_item_atual] = useState<string>('');
    const [id_item_atual, setId_item_atual] = useState<number | null>(null);
    const [modalEditar, setModalEditar] = useState<boolean>(false);
    const [dados_editar, setDados_editar] = useState<any>();

    //API - GET
    const { data_cadastrados, refetch_data_cadastrados, carregando_cadastrados } = useGetRecomendacaoAtivosCadastrados(id_ativo_selecionado, id_tipo_principal);
    const { data_cultura, carregando_cultura } = useGetRecomendacaoCultura(true);
    const { data_estadios, refetch_estadios, carregando_estadios } = useGetEstadiosByCultura(id_culturaDoses);
    //API  - POST
    const { carregando_salvar_dose, mutate_salvar_dose } =  usePostDose();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarDose() {
        mutate_salvar_dose({
            codConta: Number(localConfig().conta),
            cod_ativo: id_ativo_selecionado,
            id_tipo_principal: id_tipo_principal,
            id_item_principal: id_item_principal,
            cod_cultura: id_culturaDoses,
            dose_maxima: Number(dose_maxima),
            dose_minima: Number(dose_minima),
            todos_estadios: todos_estadiosDoses,
            cod_estadios: estadios_selecionadosDoses.map((item => item.id)) ?? [],
            obs: obs,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            atualizarListaPrincipal: () => atualizarListaPrincipal()
        });
        if (!todos_estadiosDoses) {
            for (const item of lista_obs) {
                mutate_salvar_dose({
                    codConta: Number(localConfig().conta),
                    cod_ativo: id_ativo_selecionado,
                    id_tipo_principal: id_tipo_principal,
                    id_item_principal: id_item_principal,
                    cod_cultura: id_culturaDoses,
                    dose_maxima: Number(item.dose_maxima),
                    dose_minima: Number(item.dose_minima),
                    todos_estadios: todos_estadiosDoses,
                    cod_estadios: (item.estadios).map((item => item.id)) ?? [],
                    obs: item.obs,
                    limparStates: () => limparStates(),
                    atualizarLista: () => refetch_data_cadastrados(),
                    atualizarListaPrincipal: () => atualizarListaPrincipal()
                });
            } 
        } 
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: id_item_atual,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        if (id_ativo_selecionado !== null) {
            refetch_data_cadastrados();
        }
    }, [id_ativo_selecionado])
    useEffect(() => {
        if (id_culturaDoses !== null) {
            refetch_estadios();
        }
    }, [id_culturaDoses])

    function addListaObs() {
        setEstadios_ja_selecionados(estadios_ja_selecionados=>[ ...estadios_ja_selecionados, ...estadios_selecionadosDoses]);

        setLista_obs(lista_obs=>[...lista_obs, {
            key: Date.now(),
            estadios: estadios_selecionadosDoses,
            dose_minima: dose_minima,
            dose_maxima: dose_maxima,
            obs: obs
        }]);

        setEstadios_selecionadosDoses([]);
        setDose_maxima('');
        setDose_minima('');
        setObs('');
    }

    function removerDaListaObs(key: number) {
        let remove = lista_obs.filter(item=> item.key === key);
        let restante = lista_obs.filter(item=> item.key !== key);
        let IDs_removidos = (remove?.[0]?.estadios ?? []).map(item=> item.id);
        let remover_estadios_ja_selec = estadios_ja_selecionados?.filter(estadio => !IDs_removidos.includes(estadio.id));


        setEstadios_ja_selecionados(remover_estadios_ja_selec);
        setLista_obs(restante);
    }

    function buscarCadastro(buscarItem) {
        return function(item) {              
            return String(item.cultura_item_lancamento?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.estadio_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
            !buscarItem;
        }
    }
    function filtrarDoses() {
        return function(item) {              
            return item.cod_tipo === 5
        }
    }
    function isDoseMaior() {
        if (!dose_maxima) {
            return false;
        }
        if (!dose_minima) {
            return false;
        }
        
        return Number(dose_maxima) < Number(dose_minima);
    }

    function disabledAddButton(): boolean {
        if (isDoseMaior()) {
            return true;
        }
        if (estadios_selecionadosDoses.length === 0) {
            return true
        }
        return id_culturaDoses===null || dose_minima==='' || dose_maxima==='';
    }

    function disableSavebutton() {
        if (carregando_salvar_dose) {
            return true;
        }
        if (isDoseMaior()) {
            return true;
        }
        if (estadios_selecionadosDoses.length === 0 && !todos_estadiosDoses) {
            return true;
        }
        return id_culturaDoses===null || dose_maxima==='';
    }

    /**Desabilita a seleção de estádio que já foram adicionados no cadastro */
    function desabilitarEstadiosSelecionados() {
        return (data_cadastrados?.itens ?? [])?.filter(filtrarDoses())?.filter(item=>item.cod_estadio !== null)?.map(item=> item?.['cod_estadio']) ?? [];
    }

    /**Desabilita os tipos que já foram adicionados no cadastro */
    function desabilitarSelecionados() {

        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarDoses())?.filter(item=>item.cod_cultura!==null) ?? [];
        let apenas_todos_estadios = (apenas_culturas ?? [])?.filter(item=>item.cod_estadio===null) ?? [];
        let culturas = (apenas_todos_estadios ?? [])?.map(item=> item?.['cod_cultura']) ?? [];

        return culturas;
    }

    function quantidadeRestante(): string {
        let qtd_estadios: number = (data_estadios?.itens ?? []).length;
        let total_selecionado: number = (estadios_ja_selecionados ?? []).length;
        let selecao_atual:  number = (estadios_selecionadosDoses ?? []).length;
        let atual_e_selecionados: number = total_selecionado + selecao_atual;
        let total: number = qtd_estadios - atual_e_selecionados;

        return total > 0 ? `(${total} restantes)` : '';
    }

    function obterQtdEstadiosSelecionados(cod_cultura: number) {
        let apenas_culturas = (data_cadastrados?.itens ?? [])?.filter(filtrarDoses())?.filter(item=>item.cod_cultura!==null) ?? [];
        let cultura_selecionadas = (apenas_culturas ?? [])?.filter((item)=> item.cod_cultura === cod_cultura);
        return (cultura_selecionadas ?? [])?.length;
    };

    function todosEstadiosJaForamAdd() {
        if (obterQtdEstadiosSelecionados(id_culturaDoses) === 0 || (data_estadios?.itens ?? []).length === 0) {
            return false;
        }
        return ( id_culturaDoses !== null && (data_estadios?.itens ?? []).length === obterQtdEstadiosSelecionados(id_culturaDoses)) ?? false;
    }

    function addTodosEstadiosManualmente() {
        if (todos_estadiosDoses) {
            setEstadios_selecionadosDoses([]);
            for (const item of (data_estadios?.itens ?? [])) {
                setEstadios_selecionadosDoses(estadios_selecionadosDoses=> [...estadios_selecionadosDoses, {
                    descr: item?.nome,
                    id: item?.cod,
                    key: item?.cod 
                }])
            }
        }
    }

    useEffect(() => {
        addTodosEstadiosManualmente();
    }, [todos_estadiosDoses]);
    
    useEffect(() => {
        if (estadios_selecionadosDoses.length < (data_estadios?.itens ?? []).length) {
            setTodos_estadiosDoses(false)
        }
    }, [estadios_selecionadosDoses])

    return (
        <Fragment>
        <Container>
            <Box>

                <Select
                    label='Selecione a cultura'
                    placeholder='Selecione a cultura'
                    value={nome_culturaDoses}
                    onChange={setNome_culturaDoses}
                    onChangeID={setId_culturaDoses}
                    open={openDropdownDoses}
                    trigger={setopenDropdownDoses}
                    items={data_cultura?.itens ?? []}
                    icon={faPagelines}
                    maxHeight={250}
                    idItem={'cod'}
                    descricaoItem='nome'
                    loading={carregando_cultura}
                    atual={nome_culturaDoses}
                    disabledItens={desabilitarSelecionados()}
                />

                {obterQtdEstadiosSelecionados(id_culturaDoses) === 0 &&(
                    <Switcher
                        label='Válida para todos os estádios'
                        isChecked={todos_estadiosDoses}
                        trigger={setTodos_estadiosDoses}
                    />
                )}
                 
                {todosEstadiosJaForamAdd() && (
                    <Warning type='info'>
                        Todos os estádios dessa cultura já foram adicionados!
                    </Warning>
                )}

                {(!todosEstadiosJaForamAdd()) &&(
                    <SelectMulti 
                        label={`Selecione os estádios ${quantidadeRestante()}`}
                        selecteds={estadios_selecionadosDoses}
                        onChange={setEstadios_selecionadosDoses}
                        placeholder='Selecione os estádios'
                        open={openDropdownEstadiosDoses}
                        trigger={setOpenDropdownEstadiosDoses}
                        maxHeight={250}
                        items={data_estadios?.itens ?? []}
                        descricaoItem='nome'
                        idItem='cod'
                        icon={faChartArea}
                        removeItens={estadios_ja_selecionados}
                        keyProp={'multi_estadios_doses'}
                        loading={carregando_estadios}
                        disabled={id_culturaDoses===null}
                        disabledItens={desabilitarEstadiosSelecionados()}
                    />
                )}
                
                {!todosEstadiosJaForamAdd() &&<>
                    <Inline>
                        <Input 
                            label="Informe a dose mínima (g/ha)"
                            placeholder="Informe a dose mínima (g/ha)"
                            value={apenasNumeros(dose_minima)}
                            onChange={setDose_minima}
                            icon={faBatteryQuarter}
                        />
                        <Input 
                            label="Informe a dose máxima (g/ha)"
                            placeholder="Informe a dose máxima (g/ha)"
                            value={apenasNumeros(dose_maxima)}
                            onChange={setDose_maxima}
                            icon={faBatteryFull}
                            error={isDoseMaior()}
                            errorMessage="Dose máxima não pode ser menor que a dose mínima"
                        />
                    </Inline>
                    <TextArea
                        label={`Observação`}
                        placeholder='Digite aqui a observação'
                        value={obs}
                        onChange={setObs}
                        resize={false}
                        height={70}
                    />

                    {!todos_estadiosDoses &&(
                        <ButtonCustom
                            icon={<FontAwesomeIcon icon={faAdd} />}
                            onClick={()=>{addListaObs()}}
                            style={{alignSelf: 'flex-start'}}
                            type="tertiary"
                            disabled={disabledAddButton()}
                        >
                            Adicionar outra
                        </ButtonCustom>
                    )}

                </>}

                {(lista_obs.length > 0 && !todos_estadiosDoses) && (
                    <Selecionados
                        lista_obs={lista_obs}
                        removerDaListaObs={removerDaListaObs}
                        disabled={todos_estadiosDoses}
                    />
                )}

            </Box>

            <ButtonCustom 
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                onClick={()=>{salvarDose()}}
                style={{alignSelf: 'flex-start'}}
                loading={carregando_salvar_dose}
                disabled={disableSavebutton()}
            >
                Cadastrar dose
            </ButtonCustom>


        </Container>

        <BodyDrawer>

            <Box>

                <TitleH4 style={{color: 'var(--text-color-light)'}}>Doses Cadastradas</TitleH4>

                <Input 
                    placeholder="Buscar cultura ou estádio"
                    value={search}
                    onChange={setSearch}
                />

                    {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarDoses()).length === 0
                    ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Nenhuma dose cadastrada`} />
                    : <>
                        {carregando_cadastrados &&(
                            <Carregando
                                legenda="Carregando lista"
                                justifyContent="center"
                                animation
                                height={100}
                            />
                        )}

                        <HeaderTable
                            itens={[
                                {valor: 'Cultura', width: 200},
                                {valor: 'Estádio', width: 250},
                                {valor: 'Doses', width: 230},
                                {valor: 'Opções', width: 120}
                            ]}
                        />
                        <BodyTable style={{marginTop: -10, height: (data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarDoses())?.length < 7 ? 'auto' : 400}}>
                            {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarDoses()).map((item, index)=> {
                                return (
                                    <RowTable key={gerarKey(index)}>
                                        <RowItem width={200}>
                                            {item?.cultura_item_lancamento}
                                        </RowItem>
                                        <RowItem width={250}>
                                            {item?.estadio_nome ?? item?.item_lancamento_nome}
                                        </RowItem>
                                        <RowItem width={230}>
                                            <Tag color="success" bordered={false}>min: {item?.dose_minima}</Tag>
                                            <Tag color="error" bordered={false}>max: {item?.dose_maxima}</Tag>
                                        </RowItem>
                                        <RowItem width={((data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarDoses()) ?? [])?.length > 7 ? 116 : 120} gap={10}>
                                            <ButtonCustom
                                                onClick={()=>{setDados_editar(item);setModalEditar(true)}}
                                                icon={<FontAwesomeIcon icon={faPenToSquare} />}
                                                type="tertiary"
                                            />
                                            
                                            <Popconfirm
                                                key={gerarKey(index)}
                                                placement="topRight"
                                                title={'Tem certeza que deseja deletar este item?'}
                                                open={openConfirm && id_item_atual===item.cod}
                                                description={
                                                <div style={{display:'flex',flexDirection:'column',gap:15}}>
                                                    <span>o item <b><i>{nome_item_atual}</i></b> será deletado!</span>
                                                    <Inline justifyContent="flex-end">

                                                        <ButtonCustom 
                                                            onClick={()=>{setOpenConfirm(false)}}
                                                            type="tertiary"
                                                            icon={<FontAwesomeIcon icon={faXmark} />}
                                                            disabled={carregando_deletar_compati}
                                                        >
                                                            Cancelar
                                                        </ButtonCustom>

                                                        <ButtonCustom 
                                                            onClick={()=>{deletarCompatibilidade()}}
                                                            icon={<FontAwesomeIcon icon={faTrash} />}
                                                            danger
                                                            loading={carregando_deletar_compati}
                                                            disabled={carregando_deletar_compati}
                                                        >
                                                            Deletar
                                                        </ButtonCustom>

                                                    </Inline>
                                                </div>}
                                                okButtonProps={{style: {display:'none'}}}
                                                cancelButtonProps={{style: {display:'none'}}}
                                            >
                                                <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                                                    <ButtonCustom
                                                        onClick={()=>{setId_item_atual(item.cod);setNome_item_atual(obterValor(item));setOpenConfirm(true)}}
                                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                                        type="tertiary"
                                                        danger
                                                    />  
                                                </Button>
                                            </Popconfirm>
                                        </RowItem>
                                    </RowTable>
                                )
                            })}
                        </BodyTable>
                    </>
                    }
            </Box>            
        </BodyDrawer>

        <Modal
            title={`Editar ${obterValor(dados_editar)}`}
            width={650}
            open={modalEditar} 
            onOk={()=>{}} 
            onCancel={()=>{setModalEditar(false)}}
            footer={false}
        >
            <ModalEditar
                registros={dados_editar}
                onClose={()=>{setModalEditar(false)}}
                atualizarLista={()=>{refetch_data_cadastrados()}}
                id_tipo_principal={id_tipo_principal}
            />
        </Modal>

        </Fragment>
    )
};