import styled from "styled-components";

export const ContainerTabs = styled.div`
    width: 100%;

    label.ContainerTabs__label {
        display: flex;
        box-sizing: border-box;
        padding-bottom: 5px;
        font-weight: 500;
        font-size: 13px;
    } 
`
//PROPS
interface Props {
    trackWidth: number
}

export const ListTabs = styled.div<Props>`
    width: 100%;
    height: 65px;
    display: flex;
    box-sizing: border-box;
    padding: 5px;
    background-color: #EEEEEE;
    border-radius: 5px;
    position: relative;

    :is([data-disabled = true]) {
        background-color: var(--disabled-text-color);
    }

    button.ListTabs__tab {
        /* width: 100%; */
        width: 173px;
        height: 100%;
        border: none;
        background-color: transparent;
        display: flex;
        flex-direction: column;
        gap: 2px;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        color: var(--text-color);
        border-radius: 5px;
        z-index: 1;
        transition: .45s;
        white-space: nowrap;

        div.ListTabs__tab__icon {
            width: 30px;
            min-width: 30px;
            max-width: 30px;
            height: 30px;
            min-height: 30px;
            max-height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;

            img {
                width: 100%;
            }
        }

    }
    button.ListTabs__tab:active {
        transform: scale(0.9);
    }
    button.ListTabs__tab:disabled,
    button.ListTabs__tab:disabled:hover {
        cursor: not-allowed;
        background-color: var(--disabled-text-color) !important;
        opacity: 0.4;
    }
    button.ListTabs__tab:is([data-active = 'true']) {
        color: #fff;

        img {
            transition: .15s;
            filter: brightness(0) saturate(100%) invert(100%) sepia(0%) saturate(7486%) hue-rotate(45deg) brightness(108%) contrast(99%);
        }
    }
    button.ListTabs__tab:disabled:is([data-active = 'true']) {
        color: var(--disabled-bg-color);
    }
    button.ListTabs__tab:is([data-active = 'true']):hover {
        background: transparent;
    }
    button.ListTabs__tab:hover {
        background-color: #fff;
    }
    button.ListTabs__tab svg {
        display: flex;
        /* margin-top: -3px; */ //comentado para melhorar nas tabs de incompatibilidade, precisa verificar pq foi adicionado
    }

    button.ListTabs__tab:is([data-only-icon = true]) {
        width: 60px !important;
        min-width: 60px !important;
    }
    button.ListTabs__tab:is([data-only-icon = true]) .ListTabs__tab__descr {
        display: none;
    }

    div.tab__highlight {
        width: 173px;
        height: 55px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: var(--primary-color);
        border-radius: 5px;
        transition: .3s;
        z-index: 0;
    } 
    div.tab__highlight.disabled {
        background-color: var(--disabled-text-color);
    } 
    div.tab__highlight:is([data-apenas-icone = true]) {
       width: 60px;
    } 

    div.tab__highlight:is([data-position = '0']) {
        transform: translateX(0px);
    }
    div.tab__highlight:is([data-position = '1']) {
        /* transform: translateX(calc(173px * 1)); */
        transform: translateX(calc(${props => props.trackWidth}px * 1));
    }
    div.tab__highlight:is([data-position = '2']) {
        /* transform: translateX(calc(173px * 2)); */
        transform: translateX(calc(${props => props.trackWidth}px * 2));
    }
    div.tab__highlight:is([data-position = '3']) {
        /* transform: translateX(calc(173px * 3)); */
        transform: translateX(calc(${props => props.trackWidth}px * 3));
    }
    div.tab__highlight:is([data-position = '4']) {
        /* transform: translateX(calc(173px * 4)); */
        transform: translateX(calc(${props => props.trackWidth}px * 4));
    }
    div.tab__highlight:is([data-position = '5']) {
        /* transform: translateX(calc(173px * 5)); */
        transform: translateX(calc(${props => props.trackWidth}px * 5));
    }
    div.tab__highlight:is([data-position = '6']) {
        /* transform: translateX(calc(173px * 5)); */
        transform: translateX(calc(${props => props.trackWidth}px * 6));
    }
    div.tab__highlight:is([data-position = '7']) {
        /* transform: translateX(calc(173px * 5)); */
        transform: translateX(calc(${props => props.trackWidth}px * 7));
    }
    div.tab__highlight:is([data-position = '8']) {
        /* transform: translateX(calc(173px * 5)); */
        transform: translateX(calc(${props => props.trackWidth}px * 8));
    }
    div.tab__highlight:is([data-position = '9']) {
        /* transform: translateX(calc(173px * 5)); */
        transform: translateX(calc(${props => props.trackWidth}px * 9));
    }

    div.tab__highlight_float {
        width: 173px;
        height: 30px;
        position: absolute;
        top: 5px;
        left: 5px;
        background-color: var(--primary-color);
        border-radius: 5px;
        transition: .3s;
        z-index: 0;
        transform: translateX(calc(${props => props.trackWidth}px));
    } 

`