import styled from "styled-components";
import { faCirclePlus, faEdit, faEye, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLES
const Tag = styled.div`
    box-sizing: border-box;
    padding: 5px 12px;
    background-color: #F0F0F0;
    border-radius: 20px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    font-weight: 600;
    flex-shrink: 0;
    min-height: 30px;


    :is([data-method = 'POST']) {
        background-color: #FFF7E6;
        color: #D46B08;
    }
    :is([data-method = 'PUT']) {
        background-color: #E6F4FF;
        color: #0958D9;
    }
    :is([data-method = 'DELETE']) {
        background-color: #FFF1F0;
        color: #CF1322;
    }
    :is([data-method = 'GET']) {
        background-color: #F6FFED;
        color: #52C41A;
    }

`;

//PROPS
interface Props {
    method: 'POST' | 'PUT' | 'DELETE' | 'GET' | 'PATCH';
    style?: React.CSSProperties;
}

export const TagMethod : React.FC<Props> = ({ method, style }) => {

    function iconMethod() {
        switch (method) {
            case 'POST':
                return faCirclePlus;
            case 'PUT':
                return faEdit;
            case 'DELETE':
                return faTrash;
            case 'GET':
                return faEye;
            default:
                return faCirclePlus;
        }
    }

    return (
        <Tag data-method={method} style={style}>
            <FontAwesomeIcon icon={iconMethod()} />
            {method}
        </Tag>
    )
}