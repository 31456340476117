import styled from "styled-components";

export const PainelSafra = styled.div`
    position: absolute;
    top: 15px;
    left: 15px;
    width: 350px;
    background-color: #fff;
    border-radius: 7px;
    overflow: hidden;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    transition: .4s;

    :is([data-shrink = true]) {
        width: 180px;
        height: 60px;
    }

    div.PainelSafra__containerList {
        width: 100%;
        max-height: calc(100vh - 420px);
        overflow-y: auto;
        /* margin-top: -10px; */
        border: thin solid var(--border-color);
        border-radius: 5px;


        div.PainelSafra__containerList__option {
            width: 100%;
            height: 70px;
            display: flex;
            align-items: center;
            gap: 15px;
            border-bottom: thin solid var(--border-color);
            box-sizing: border-box;
            padding: 10px;
            cursor: pointer;

            :hover {
                background: var(--hover-color);
            }
        }

        div.PainelSafra__containerList__option__info {
            width: calc(100% - 100px);
            display: flex;
            flex-direction: column;

            .PS__CL__option__info__name {
                font-weight: 600;
                font-size: 14px;
                color: var(--text-color);
                margin: 0;
            }
            span.PS__CL__option__info__alert {
                font-size: 10px;
                font-weight: 500;
                color: red
            }
            span.PS__CL__option__info__area {
                margin: 0;
                font-size: 13px;
                color: var(--text-color-light);
            }
        }
    }
`;