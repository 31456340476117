
import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { tryError } from '../../Afins'
import { api } from '../../api'

export interface TypesTipoAT {
  itens: ItensTipoATTypes[]
}

export interface ItensTipoATTypes {
  cod?: number
  cod_conta?: number
  cod_usuario?: number
  tipo?: number
  usuario_nome: string
  tipo_nome: string
  usuario: [
    {
      cod?: number
      nome: string
      email: string
    }
  ]
}

type TypesQuery = {
  cod?: number
  embed?: 'permissoes'
}

const url = '/usuario-tipo-assistente-tecnico'

export const getTipoAT = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesTipoAT> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postTipoAT = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesTipoAT> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delTipoAT = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesTipoAT> = await api.delete(`${url}/${cod}`, {
    params: {
      codConta: conta,
      codCliente,
    },
  })

  return resp
}
