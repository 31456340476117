import { orderBy, uniq } from 'lodash'

export const useLogic = () => {
  const generateColors = (valueEncontrado: number[], typeKrigagem: boolean, reverse: boolean) => {

    // console.log("valueEncontrado: ", valueEncontrado)
    // console.log('reverse', reverse, typeKrigagem)
    const colors = typeKrigagem
      ? reverse
        ? [
            '#2ecc71', // 80 - 100 verde
            '#b0f13c', // 60 - 80 verde claro
            '#f0ff00', // 40 - 60 amarelo
            '#ff9a00', // 20 - 40 laranja
            '#e22626', // 00 - 20 vermelho
          ]
        : [
            '#2ecc71', // 80 - 100 verde
            '#b0f13c', // 60 - 80 verde claro
            '#f0ff00', // 40 - 60 amarelo
            '#ff9a00', // 20 - 40 laranja
            '#e22626', // 00 - 20 vermelho
          ].reverse()
      : reverse
      ? [
          '#2ecc71', // 80 - 100 verde
          '#b0f13c', // 60 - 80 verde claro
          '#f0ff00', // 40 - 60 amarelo
          '#ff9a00', // 20 - 40 laranja
          '#e22625', // 00 - 20 vermelho
        ].reverse()
      : [
          '#2ecc71', // 80 - 100 verde
          '#b0f13c', // 60 - 80 verde claro
          '#f0ff00', // 40 - 60 amarelo
          '#ff9a00', // 20 - 40 laranja
          '#e22625', // 00 - 20 vermelho
          // "#d9d9d9",
        ]

    let finalColors = []
    let colorInterpletado = []
    // console.log('value', valueEncontrado)
    for (let i = 0; i < valueEncontrado.length; i++) {
      // const encontrado = valueEncontrado[i]
      // const percentage = Number((((encontrado - limitMin) / (limitMax - limitMin)) * 100).toFixed(2))
      const percentage = valueEncontrado[i]
      // console.log('value', percentage)
      if (typeKrigagem) {
        if (percentage <= 20) {
          colorInterpletado = [...colorInterpletado, { order: 5, color: colors[4] }]
        } else if (percentage <= 40) {
          colorInterpletado = [...colorInterpletado, { order: 4, color: colors[3] }]
        } else if (percentage <= 60) {
          const index = Math.floor(colors.length / 2)
          colorInterpletado = [...colorInterpletado, { order: 3, color: colors[index] }]
        } else if (percentage <= 80) {
          colorInterpletado = [...colorInterpletado, { order: 2, color: colors[1] }]
        } else if (percentage > 80) {
          colorInterpletado = [...colorInterpletado, { order: 1, color: colors[0] }]
        }
      } else {
        finalColors = colors
        // console.log('ELSE AQUI!')
      }
    }
    if (colorInterpletado.length > 0) {
      colorInterpletado = orderBy(colorInterpletado, 'order')

      finalColors = colorInterpletado.map((rec) => rec.color).reverse()
      // console.log('finalColors', finalColors)
      return finalColors
    } else {
      return uniq(finalColors)
    }
  }

  return { generateColors }
}
