
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetfornecedorServico {
  itens: [fornecedorServicoItens]
}

export interface fornecedorServicoItens {
  cod_fornecedor: number
  cod_servico: number
  status: number
  data: string
  valor: string
}

export const getfornecedorServico = async (codFornecedor, codServico, cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetfornecedorServico> = await api.get(
    `/fornecedor-servico?codConta=${config.conta}&codCliente=${config.acesso === 'conta' ? '' : config.codCliente
    }&codFornecedor=${codFornecedor}&cod=${cod}&codServico=${codServico}&pagination=-1`
  )

  return response
}

export const postfornecedorServico = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetfornecedorServico> = await api.post(
    `/fornecedor-servico?codConta=${config.conta}&codCliente=${config.acesso === 'conta' ? '' : config.codCliente
    }`,
    data
  )

  return response
}

export const putfornecedorServico = async (codfornecedorServico, codFornecedor, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetfornecedorServico> = await api.put(
    `/fornecedor-servico/${codfornecedorServico}?codConta=${config.conta}?codCliente=${config.acesso === 'conta' ? '' : config.codCliente
    }&codFornecedor=${codFornecedor}`,
    data
  )

  return response
}

export const delfornecedorServico = async (codServicoFor) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetfornecedorServico> = await api.delete(
    `/fornecedor-servico/${codServicoFor}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
