import styled from 'styled-components'
import Img from '../../../assets/img/bg.jpg'

export const Bg = styled.div`
  display: flex;
  min-height: 100vh;
  margin: 0 auto;
  background-image: url(${Img});
  background-size: cover;
  justify-content: center;
  align-items: center;
`

export const Container = styled.div`
  border-radius: 6px;
  padding: 30px;
  background-color: #ffffff;
  width: 350px;
  height: auto;

  img {
    width: 215px;
  }
`
