import React, { Suspense } from 'react'
import { Switch, Route, Redirect } from 'react-router-dom'
import Loading from '../../Components/loading'
import { AUTHLAB_PREFIX_PATH, AUTH_PREFIX_PATH } from '../../Configs/AppConfig'
import Login from './login'
import Reset from './reset'

export const AppViews: React.FC = () => {
  return (
    <Suspense fallback={<Loading cover='page' />}>
      <Switch>
        <Route path={`${AUTH_PREFIX_PATH}/login`} component={Login} />
        <Route path={`${AUTHLAB_PREFIX_PATH}/login`} component={Login} />
        <Route path={`${AUTH_PREFIX_PATH}/login/redefinir`} component={Login} />
        <Route path={`${AUTH_PREFIX_PATH}/reset/:token`} component={Reset} />
        <Redirect from={`${AUTH_PREFIX_PATH}`} to={`${AUTH_PREFIX_PATH}/login`} />
      </Switch>
    </Suspense>
  )
}

export default AppViews
