import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'

import { faEdit } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'
import { orderBy } from 'lodash'
import FormElementos from '../../../../Components/FormElementos'
import Icons from '../../../../Components/Icons'
import TableDrag from '../../../../Components/TableDrag'
import { tryError } from '../../../../services/Afins'
import { ItensElementosTypes, getElementos, putElementos } from '../../../../services/Elementos'
import { Container } from './styles'
const PageElementos: React.FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataElements, setDataElements] = useState<ItensElementosTypes[]>([])
  const [dataElements__com_index, setDataElements__com_index] = useState<ItensElementosTypes[]>([])
  const [element, setElement] = useState<ItensElementosTypes>({} as ItensElementosTypes)
  const [dragElements, setDragElements] = useState<ItensElementosTypes[]>([])

  const listElements = async (loading = true) => {
    // console.log('PUXOU')
    try {
      setLoading(loading)
      const resp = await getElementos({})
      const ordemMax = resp.data.itens.reduce((a, b) => {
        return Math.max(a, b.ordem)
      }, -Infinity)
      let ordem = ordemMax

      resp.data.itens.map(async (rec, i) => {
        if (rec.ordem === null) {
          ordem += 1
          rec.ordem = ordem
          await putElementos({ cod: rec.cod, ordem })
        }
        return rec
      })
      const ordemBy = orderBy(resp.data.itens, 'ordem')
      setDataElements(ordemBy)
      setLoading(false)
    } catch (error) {
      tryError(error)
    }
  }

  const handleEditElement = (record: ItensElementosTypes) => {
    setElement(record)
    setVisibleDrawer(true)
  }

  useEffect(() => {
    listElements()
  }, [])

  const Order = async () => {
    for (let i = 0; i < dataElements.length; i += 1) {
      const element = dataElements[i]
      await putElementos({ cod: element.cod, ordem: i + 1 })
    }
  }

  useEffect(() => {
    // console.log("dragElements: ", orderBy(dragElements.map((rec) => rec.novo_index)));
    if (dragElements.length > 0) {
      const newOrdem = orderBy(dragElements.map((rec) => rec.novo_index))
      // console.log("dataElements: ", dataElements);
      // console.log("dragElements: ", dragElements);
      // console.log("newOrdem: ", newOrdem);

      const newArray = dragElements.map((rec, i, self) => {
        if (i === (rec.origem ==='subiu' ? 0 : (self?.length - 1))) {
          return putElementos({ cod: rec.cod, ordem: Number(newOrdem[i]) })
        }
        
      })

      // console.log('newArray', newArray)

      Promise.all(newArray).then(() => listElements(false))
    }
  }, [dragElements])

  // console.log('dragElements', dragElements)

  function addIndex() {
    for (let i = 0; i < dataElements.length; i++) {
      const elementoAtual = { ...dataElements[i] }
      elementoAtual.novo_index = i + 1
      setDataElements__com_index((prevState) => {
        const newState = [...prevState]
        newState[i] = elementoAtual
        return newState
      })
    }
  }

  useEffect(() => {
    addIndex()
  }, [dataElements])

  return (
    <Container>
      <ViewPage
        newHide
        title='Elementos'
        btnClick={() => {}}
        content={
          <FormElementos
            open={visibleDrawer}
            element={element}
            onClose={setVisibleDrawer}
            refresh={listElements}
          />
        }
      >
        {/* <Button onClick={Order}>Ordem</Button> */}
        <TableDrag
          rowKey='novo_index'
          // rowKey='ordem'
          bordered
          size='small'
          scroll={{ y: 'calc(100vh - 320px)' }}
          dataSource={dataElements__com_index}
          // dataSource={dataElements}
          dragEnd={(data, drag) => {
            setDataElements(data)
            setDragElements(drag)
          }}
          loading={loading}
          columns={[
            {
              key: 'drag',
              title: 'Ordem',
              width: '80px',
              align: 'center',
              dataIndex: 'cod',
            },
            {
              title: 'Nome',
              dataIndex: 'nome',
            },
            {
              title: 'Descrição',
              dataIndex: 'descricao',
            },
            {
              title: 'Unidade',
              dataIndex: 'unidade',
            },
            {
              title: 'Editar',
              width: 90,
              dataIndex: 'cod',
              align: 'center',
              render: (_, record) => (
                <Button
                  onClick={() => handleEditElement(record)}
                  shape='circle'
                  icon={<Icons icon={faEdit} />}
                />
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default PageElementos
