import { Button } from "antd";
import { faFloppyDisk } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";

//APP
import { decodeHtml } from "../../../../../../services/Afins";
import { adaptarLista } from "../elementos/meta";
import { Item__MapaFertilidade__Config, ParametroFertilidade__Itens, 
    useGetElementosFertilidade, useGetParametrosFertilidadeRef } from "../../../../../../services/novoConfigBook/get";

//STYLE
import { ContainerTexto } from "./styled";

//COMPONENTS
import EditorText from "../../../../../../Components/EditorText";
import Carregando from "../../../../../../Components/CustomUi/loading";

//PROPS
interface Props {
    texto_introducao: string;
    carregando_salvar: boolean;
    setElementos_base: React.Dispatch<React.SetStateAction<ParametroFertilidade__Itens[]>>;
    setLista_elementos: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config[]>>;
    setTexto_introducao: React.Dispatch<React.SetStateAction<string>>;
    callUsePostSalvarConfigBook: Function;
}

const TextoIntroducao: React.FC<Props> = ({ texto_introducao, setTexto_introducao, callUsePostSalvarConfigBook, 
    carregando_salvar, setElementos_base, setLista_elementos }) => {

    //STATES
    const [texto_foi_editado, setTexto_foi_editado] = useState(false);
    
    //API
    const { data_elementos, carregando_elementos } = useGetElementosFertilidade();
    const { data_paramentros, carregando_parametros, isFetched } = useGetParametrosFertilidadeRef();

    useEffect(() => {
        setTexto_introducao(decodeHtml(data_elementos?.itens?.[0]?.mapa_fertilidade?.texto_introducao) ?? '');
        setLista_elementos(data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []);
        adaptarLista(isFetched, (data_paramentros?.itens), setElementos_base, (data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []));
    }, [data_elementos, data_paramentros]);
    

    const handleChangeEditor = (dataEditor, editor = false) => {
        setTexto_introducao(dataEditor);
        if (editor) {
            setTexto_foi_editado(true);
        }
      }

    return (
        <ContainerTexto>

            {(carregando_elementos || carregando_parametros) 
            ?
                <Carregando
                    height={'calc(100vh - 270px)'}
                    justifyContent="center"
                    legenda="Carregando texto de inttrodução..."
                    animation
                />
            :
                <EditorText
                    data={texto_introducao}
                    onChange={(_, editor) => {
                        handleChangeEditor(editor.getData(), true)
                    }}
                />
            }
          <div className="ContainerTexto__footer">
            <Button 
                type="primary" 
                icon={<FontAwesomeIcon icon={faFloppyDisk} />} 
                onClick={()=>callUsePostSalvarConfigBook()}
                loading={carregando_salvar}
                disabled={!texto_foi_editado}
            >
                Salvar texto
            </Button>
          </div>
        </ContainerTexto>
    )
};


export default TextoIntroducao;