import styled from 'styled-components'

export const ContainerDocumentos = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .campo-input-upload {
    display: flex;
    width: 100%;
  }

  .campo-upload-preview {
    display: flex;
    justify-content: space-around;
    align-items: end;
    gap: 5px;
    padding-left: 5px;
  }
  .lines{
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .upload-button{
    height: 61%;
  }
`
