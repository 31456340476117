import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useLoadScript } from '@react-google-maps/api'
import { Button, Result, Spin } from 'antd'
import CheckToken from '../../services/ChekToken'
import { Container, Tools } from './styled'

import { faPrint } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'
import ReactToPrint from 'react-to-print'
import Icons from '../../Components/Icons'
import Monitoramento, { PropsGeraBooking } from '../../Components/monitoramento'
import logoTtivos from '../../assets/img/logo.png'
import { useAccess } from '../../context/useAccess'
import { useLogic } from '../../context/useLogic'
import { ClienteItensTypes, getClienteBook, getConsultoria } from '../../services/Cliente'
import { PropriedadeItens, getPropriedade } from '../../services/Propriedades'
import { getTalhaoCultivavel } from '../../services/TalhaoSafra'
import { api } from '../../services/api'
import {
  MoniNivelItemControleItens,
  getMoniNivelControleItem,
} from '../../services/monitoramento/nivelControleItens'

// type Props = {}

interface PropsParam {
  token: string
  codConta: string
  codSafra: string
  codCliente: string
  codPropriedade: number
  nomeConsultor: string
  dataMonitoramento: string
  terreno: boolean
  /**
   * @params {listaEspecie} exemplo = listaEspecie=CRESTAMENTO BACTERIANO, 106, null, 1-PERCEVEJO MARROM, 86, 68, 1
   * Sendo, nome da especie, código variação, e se totaliza,
   * OBS se não existir variação para especie enviar como null
   */
  listaEspecie: string
  /**
   * @params {codTalhao} separar por virgula os codTalhao
   */
  codTalhao: string
  resumir: boolean
  ordenar: boolean
  codCultura: boolean
  tipoAcesso: string
}

const limparParams = () => {
  const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
  window.history.pushState({}, document.title, newUrl)
}

function BookMonitoramento() {
  const { setConsultoria, consultoria, setNameCliente, setNameSafra, setCodCultura } = useAccess()
  const { setHash, hash } = useLogic()

  const btnPrint = useRef<ReactToPrint>()

  const { search } = useLocation<PropsParam>()

  const [queres, setQueres] = useState({} as any)
  const [nivelControle, setDataNivelControle] = useState<MoniNivelItemControleItens[]>([])
  const [loading, setLoading] = useState(true)
  const [params, setParams] = useState<{ [key: string]: string }>()
  const [gerarBook, setGerarBook] = useState<PropsGeraBooking>()
  const [resumir, setResumir] = useState(false)
  const [ordenar, setOrdenar] = useState(false)

  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens>()

  const [loadToken, setLoadToken] = useState(false)
  const [toolsOpen, setToolsOpen] = useState(true)
  const [finishBook, setFinishBook] = useState(true)
  const [click, setClick] = useState(0)
  const [clienteBook, setClienteBook] = useState<ClienteItensTypes>()

  const query = new URLSearchParams(search)

  const loadScriptGoogleMaps = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_TOKEN_GOOGLE_MAPS,
    libraries: ['places', 'drawing', 'visualization', 'geometry'],
    language: 'pt-BR',
  })

  const checkParams = async () => {
    try {
      limparParams()

      setLoading(true)
      if (
        !params.token &&
        !params.codConta &&
        !params.codCliente &&
        !params.codPropriedade &&
        !params.codSafra &&
        !params.codCultura
      ) {
        setQueres({ error: 'Token não informado', status: 403 })
        setLoading(false)
      } else {
        localStorage.setItem('conta', params.codConta)
        localStorage.setItem('cod-cliente', params.codCliente)
        localStorage.setItem('tipo-acesso', params.tipoAcesso)
        localStorage.setItem('codCultura', params.codCultura)

        const bookAppLocal = JSON.stringify(1)
        localStorage.setItem('bookApp', bookAppLocal)
        const conta = await getConsultoria(params.codConta)
        setConsultoria(conta.data.itens[0])

        const resp = await getPropriedade(params.codPropriedade, 'talhao', params.codCliente)
        const talhoes = await getTalhaoCultivavel({
          codPropriedade: Number(params.codPropriedade),
          codCliente: Number(params.codCliente),
        })

        const dados = resp.data.itens[0]

        const converter = params?.resumir === 'true' ? true : false
        setResumir(converter)

        setNameCliente(dados.cliente_nome)
        localStorage.setItem('nome-cliente', dados.cliente_nome)
        const clienteApi = await getClienteBook(params?.codCliente)
        const clienteLogo = clienteApi.data.itens[0]
        JSON.stringify(localStorage.setItem('logo', clienteLogo.logo))

        const newDados = talhoes.data.itens.map((rec) => {
          // @ts-ignore
          rec.kml.coordenadas = rec.kml.coordenadas.map((item) => {
            return { lat: Number(item.latitude), lng: Number(item.longitude) }
          })
          return rec
        })

        dados.talhao = newDados

        setDataPropriedade(dados)
      }
      const resp = await CheckToken(params.token)
      localStorage.setItem('Itens', JSON.stringify(resp))

      if (!resp) {
        setQueres({ error: 'Token inválido', status: 403 })
        setLoading(false)
        return
      }

      // if (!params.codConta) {
      //   setQueres({ error: 'Código consultoria não informado', status: 403 })
      //   setLoading(false)
      //   return
      // } else {
      //   localStorage.setItem('conta', params.codConta)
      //   const conta = await getConsultoria(params.codConta)

      //   setConsultoria(conta.data.itens[0])
      // }

      // if (!params.codCliente) {
      //   setQueres({ error: 'Código do cliente não informado', status: 403 })
      //   setLoading(false)
      //   return
      // } else {
      //   localStorage.setItem('cod-cliente', params.codCliente)
      // }

      // if (!params.codPropriedade) {
      //   setQueres({ error: 'Código da propriedade não informado', status: 403 })
      //   setLoading(false)
      //   return
      // } else {
      //   const resp = await getPropriedade(params.codPropriedade, 'talhao', params.codCliente)
      //   const talhoes = await getTalhaoCultivavel({
      //     codPropriedade: Number(params.codPropriedade),
      //     codCliente: Number(params.codCliente),
      //   })

      //   const dados = resp.data.itens[0]

      //   console.log('dados', dados)
      //   const converter = params?.resumir === 'true' ? true : false
      //   setResumir(converter)

      //   setNameCliente(dados.cliente_nome)

      //   const newDados = talhoes.data.itens.map((rec) => {
      //     // @ts-ignore
      //     rec.kml.coordenadas = rec.kml.coordenadas.map((item) => {
      //       return { lat: Number(item.latitude), lng: Number(item.longitude) }
      //     })
      //     return rec
      //   })

      //   dados.talhao = newDados

      //   setDataPropriedade(dados)
      //   const respSafra = await getSafra({ cod: Number(params.codSafra) })
      //   const dadosSafra = respSafra.data.itens[0]
      //   setNameSafra(dadosSafra.nome)
      //   setCodCultura(dadosSafra.cod_cultura)
      //   localStorage.setItem('codSafra', params.codSafra)
      // }

      // if (!params.codSafra) {
      //   setQueres({ error: 'Safra não informado', status: 403 })
      //   setLoading(false)
      //   return
      // } else {
      //   const respSafra = await getSafra({ cod: Number(params.codSafra) })
      //   const dadosSafra = respSafra.data.itens[0]
      //   setNameSafra(dadosSafra.nome)
      //   setCodCultura(dadosSafra.cod_cultura)
      //   localStorage.setItem('codSafra', params.codSafra)
      // }

      if (!params.dataMonitoramento) {
        setQueres({ error: 'Data do monitoramento não informado', status: 403 })
        setLoading(false)
        return
      }

      if (!params.terreno) {
        setQueres({ error: 'Terreno não informado', status: 403 })
        setLoading(false)
        return
      }

      if (!params.listaEspecie) {
        setQueres({ error: 'Lista de especie e variação não informado', status: 403 })
        setLoading(false)
        return
      }

      if (!params.ordenar) {
        setOrdenar(false)
      }

      //Filtros book

      const filtroBook: PropsGeraBooking = {
        codTalhao: params?.codTalhao
          ? params?.codTalhao
              .split(',')
              .map((rec) => Number(rec))
              .filter((value) => !isNaN(value))
          : [],
        consultorName: params?.nomeConsultor,
        dateBook: dayjs(params?.dataMonitoramento),
        listEspecieVariacao: params.listaEspecie.split('-'),
        mapType: params?.terreno ? 'terrain' : 'satellite',
      }

      if (filtroBook.dateBook) {
        // Percorrer as propriedades do objeto date
        Object.keys(filtroBook.dateBook).forEach((key) => {
          // Verificar se o valor é undefined e removê-lo
          if (filtroBook.dateBook[key] === undefined) {
            delete filtroBook.dateBook[key]
          }
        })
      }

      setGerarBook(filtroBook)
      setClick(1)

      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.log(error)
      setQueres({ error: error.response?.data.error })
    }
  }

  const listNivelControle = async () => {
    const resp = await getMoniNivelControleItem({ codConta: consultoria.cod, pagination: -1 })
    setDataNivelControle(resp.data.itens)
  }

  useEffect(() => {
    let para: { [key: string]: string }
    for (const p of query) {
      para = { ...para, [p[0]]: p[1] }
    }

    api.defaults.headers.Acesso = 'conta'
    if (para?.token?.length > 20) {
      setHash(para.token)
    }
    setParams(para)
  }, [])

  useEffect(() => {
    api.defaults.headers.Authorization = `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${hash}`
    const a = api.defaults.headers.Authorization.split(', ')
    if (a[1] && a[1].length > 21) {
      setLoadToken(false)
      checkParams()
      listNivelControle()

      setLoadToken(true)
    }
  }, [hash])

  useEffect(() => {
    if (finishBook === false) {
      const bookAppLocal = JSON.stringify(0)
      localStorage.setItem('bookApp', bookAppLocal)
    }
  }, [])

  return (
    <Container reverse={toolsOpen} id='divScroll'>
      {loading && loadScriptGoogleMaps.isLoaded && params.token ? (
        <div className='spin'>
          <Spin size='large' />
        </div>
      ) : queres.error ? (
        <Result
          status={queres.status ? queres.status : 'warning'}
          title={queres.status ? queres.status : 'Atenção'}
          subTitle={queres.error}
        />
      ) : (
        <>
          <div className='print'>
            {loadToken ? (
              <Monitoramento
                nivelControle={nivelControle}
                visible
                onClose={null}
                geraBooking={gerarBook}
                propriedade={dataPropriedade}
                bookApp={click}
                resumir={resumir}
                ordenar={ordenar}
                finishBook={(data) => {
                  setFinishBook(data)
                }}
              />
            ) : null}
          </div>
          <Tools>
            <h3>Para imprimir ou salvar o book, pressione o botão abaixo</h3>
            <ReactToPrint
              ref={btnPrint}
              trigger={() => (
                <Button
                  loading={finishBook}
                  size='large'
                  icon={<Icons icon={faPrint} />}
                  type='primary'
                  htmlType='button'
                >
                  Imprimir / salvar pdf
                </Button>
              )}
              content={() => document.getElementById('printMonitoramento')}
            />
            <img src={logoTtivos} alt='ttivos logo' />
          </Tools>
        </>
      )}
    </Container>
  )
}
export default BookMonitoramento
