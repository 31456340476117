import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import Icon, { SearchOutlined } from '@ant-design/icons'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  message,
  Modal,
  Row,
  Select,
  Space,
  Switch,
  Table,
  Tag,
  Typography,
} from 'antd'

import { faClose } from '@fortawesome/free-solid-svg-icons'
import { GoogleMap, InfoBox, Polygon } from '@react-google-maps/api'
import { useLogic } from '../../../context/useLogic'

import { Notification } from '../../../Components/notification'

import {
  AgrupamentoTalhaoItens,
  delAgrupamentoTalhao,
  getAgrupamentoTalhao,
  postAgrupamentoTalhao,
  putAgrupamentoTalhao,
} from '../../../services/agrupamentoTalhao'

import Svg from '../../../Components/talhasvg'
import { centerMap, getBounds } from '../../../services/Afins'
import { Container, ContainerMap } from './styled'

import AgrupamentoTalhao from '../../../Components/agrupamentoTalhao/index2'
import { pagination } from '../../../Components/Default'
import Icons from '../../../Components/Icons'
import { IconAlert } from '../../../Components/iconsAlert'
import ViewPage from '../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { getPropriedade } from '../../../services/Propriedades'
import { getTalhaoCultivavel as getTalhaoCultivable } from '../../../services/TalhaoSafra'
import { formatarValor } from '../../../utils'
import GeneratePdfButton from '../../../Components/relatorio-component'

interface TypesMapa {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  style?: { [key: string]: string }
}
const { Option } = Select

const PageAgrupamentoTalhao: React.FC = () => {
  const { checkSafra } = useLogic()

  const [form] = Form.useForm()

  const history = useHistory()

  const codSafra = localStorage.getItem('codSafra')

  const [dataAgrupamento, setDataAgrupamento] = useState<AgrupamentoTalhaoItens[]>([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [visualizarPropriedade, setVisualizarPropriedade] = useState(false)
  const [update, setUpdate] = useState(0)
  const [talhaoSele, setTalhaoSele] = useState(false)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [zoom, setZoom] = useState(2)

  const [selectTalhao, setSelectTalhao] = useState(null)

  const [center, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>()

  const [dataListAgrupamento, setDataListAgrupamento] = useState([])
  const [dataPropriedade, setDataPropriedade] = useState([])

  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 2,
    center: {
      lat: 0,
      lng: 0,
    },
    style: { width: '100%', height: '78vh' },
  })

  const [dataPolygon, setDataPolygon] = useState([])
  const [dataAgrupamentoTalhao, setDataAgrupamentoTalhao] = useState<any>([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])

  const [nomeAgrupamento, setNomeAgrupamento] = useState<string>()

  const url = useRef(null)

  const searchInputTable = useRef(null)

  const handleClickViewAgrupamento = (codGroup) => {
    setVisibleForm(true)
    setUpdate(codGroup)

    const dados = dataAgrupamento.find((data) => data.cod === codGroup)

    setDataAgrupamentoTalhao(dados)

    setNomeAgrupamento(dados.nome)

    handleGetTalhao(dados.cod_propriedade, dados.talhao)

    form.setFieldsValue(dados)

    setZoom(12)
  }

  const listPropriedade = async () => {
    const response = await getPropriedade('')

    setDataPropriedade(response.data.itens)
  }

  const handleVisibleForm = () => {
    setZoom(2)
    setCenter({ lat: 0.0, lng: 0.0 })
    setUpdate(0)
    if (!visibleForm) {
      setVisibleForm(true)
    }
  }

  const handleCloseForm = () => {
    setVisibleForm(false)
    setDataListAgrupamento([])
    setSelectTalhao(null)
    setNomeAgrupamento('')
    form.resetFields()
  }

  const listAgrupamento = async () => {
    setLoadingTable(true)
    const response = await getAgrupamentoTalhao({ pagination: -1 })
    setDataAgrupamento(response?.data.itens)
    gerarTabela(response.data.itens)
    somarValores(response.data.itens, response.data.itens.length)
    setLoadingTable(false)
  }

  function somarValores(dados, totais) {
    const resultado = dados?.reduce(
      (soma, item) => {
        return {
          //@ts-ignore
          area: soma?.area + parseFloat(item?.area_total),
        }
      },
      {
        area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
      },
    ]

    gerarTabela2(resultadoFinal, totais)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },

        {
          text: formatarValor(item?.area_total),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        { text: item?.talhao.length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'right' },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'],
          body: [
            [
              { text: 'Nome agrupamento', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Quantidade', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.totalizado_area, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*'], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total de agrupamento',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const svgCoordPropiedade = (data) => {
    const dados = data

    let talhoes = []
    for (let i = 0; i < dados.talhao.length; i += 1) {
      const talhao = dados.talhao[i]

      let temp = []

      for (let ii = 0; ii < talhao.talhao_kml.coordenadas.length; ii += 1) {
        const coord = talhao.talhao_kml.coordenadas[ii]

        temp = [...temp, coord]
      }
      talhoes = [...talhoes, temp]
    }

    return talhoes
  }

  const handleVisualizarPropri = async (data) => {
    message.loading('Carregando aguarde...', 999999)
    const agrupamento = dataAgrupamento.find((info) => info.cod === data)
    setNomeAgrupamento(agrupamento.nome)
    const respPro = await getTalhaoCultivable({
      codPropriedade: agrupamento.cod_propriedade,
    })

    let calcCenter = []
    const dados = respPro.data.itens.map((info) => {
      let newArray = []
      info.select = false
      for (let i = 0; i < agrupamento.talhao.length; i += 1) {
        const agr = agrupamento.talhao[i]

        if (agr.cod_talhao === info.cod) {
          info.select = true
        }
      }

      info.kml.coordenadas.map((record) => {
        newArray = [...newArray, { lat: record.latitude, lng: record.longitude }]

        return newArray
      })
      // @ts-ignore
      info.kml.coordenadas = newArray

      calcCenter = [...calcCenter, newArray]

      return info
    })

    const centro = getBounds(calcCenter).getCenter()

    setPropsMapa({
      center: {
        lat: centro.lat(),
        lng: centro.lng(),
      },
      zoom: 12,
    })
    setDataPolygon(dados)
    setVisualizarPropriedade(true)
    message.destroy()
  }

  const handleSearch = (selectedKeys: any, confirm) => {
    confirm()
  }

  const handleReset = (clearFilters) => {
    clearFilters()
  }

  const handleToogleSwitchTalhao = (data, cod) => {
    const dados = JSON.parse(JSON.stringify(dataListAgrupamento))

    dados.forEach((record, i) => {
      if (record.cod === cod) {
        dados[i].select = data
      }
    })

    setDataListAgrupamento(dados)
  }

  const handleClickListPolygon = (index) => {
    setSelectTalhao(index)
  }

  const handleGetTalhao = async (data, talhoes) => {
    setTalhaoSele(true)
    const response = await getTalhaoCultivable({ codPropriedade: data })

    const dados = response.data.itens

    if (dados.length > 0) {
      let calcCenter = []
      dados.forEach((record, index) => {
        record.select = false
        const newArray = record.kml.coordenadas.map((p) => {
          return { lat: p.latitude, lng: p.longitude }
        })

        // @ts-ignore
        dados[index].kml.coordenadas = newArray
        calcCenter = [...calcCenter, newArray]
      })

      const centro = getBounds(calcCenter).getCenter()

      setCenter(centro)

      if (talhoes.length > 0) {
        talhoes.forEach((info) => {
          dados.forEach((record) => {
            if (info.cod_talhao === record.cod) {
              record.select = true
            }
          })
        })
      }

      setZoom(12)
      setDataListAgrupamento(dados)

      setTalhaoSele(false)
    } else {
      Notification({
        message: 'Propriedade não possui talhão cultivável',
        type: 'info',
      })
      setTalhaoSele(false)
    }
  }

  const handleTrashAgrupamento = async (codGrup) => {
    const responsePro = await getAgrupamentoTalhao({
      cod: codGrup,
      pagination: -1,
    })
    const nomePro = responsePro.data.itens[0].nome

    try {
      Modal.confirm({
        title: 'Excluir',
        content: `Deseja realmente remover o Agrupamento ${nomePro}?`,
        icon: <IconAlert type='confirm' size={6} />,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delAgrupamentoTalhao(codGrup)

          if (responseDel.status === 200) {
            Notification({
              message: 'Agrupamento Excluído',
              type: 'success',
            })

            listAgrupamento()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...')
      let talhoes = []
      dataListAgrupamento.forEach((data) => {
        if (data.select === true) {
          talhoes = [...talhoes, data.cod]
        }

        return talhoes
      })

      data.talhao = talhoes

      if (update <= 0) {
        const response = await postAgrupamentoTalhao(data)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Agrupamento cadastrado com sucesso',
            type: 'success',
          })
          form.resetFields()
          listAgrupamento()
          handleCloseForm()
        }
      } else {
        const response = await putAgrupamentoTalhao(update, data)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Agrupamento atualizado com sucesso',
            type: 'success',
          })
          listAgrupamento()
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    listAgrupamento()
    listPropriedade()
    const idDaSafra = checkSafra('/talhao/grupotalhaoes')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    listAgrupamento()
    listPropriedade()
  }, [codSafra])

  // console.log('dataListAgrupamento', dataListAgrupamento);

  // console.log('dados', dataAgrupamentoTalhao);
  return (
    <Container>
      <ViewPage
        title='Agrupamento de talhões'
        rotina={31}
        onPermission={setPermissions}
        btnClick={handleVisibleForm}
        btnsExta={
          <div>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório agrupamento de talhões'}
                totais={dadosResultado}
                disable={dataAgrupamento.length <= 0}
                titleSecundario={nomeAgrupamento}
              />
            ) : null}
          </div>
        }
        content={
          <AgrupamentoTalhao
            nomeTalhao={nomeAgrupamento}
            permissions={permissions}
            polygon={dataListAgrupamento}
            center={center}
            update={update}
            dataAgrupamentoTalhao={dataAgrupamentoTalhao}
            visible={visibleForm}
            onClose={handleCloseForm}
            onSelect={setSelectTalhao}
            select={selectTalhao}
            onFinish={() => onFinish(form.getFieldsValue())}
            zoom={zoom}
          >
            <Form form={form} layout='vertical' onFinish={onFinish}>
              <Row gutter={[8, 0]}>
                <Col flex='0 1 400px'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Nome'
                    name='nome'
                  >
                    <Input />
                  </Form.Item>
                </Col>

                <Col flex='0 1 400px'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Propriedade'
                    name='cod_propriedade'
                  >
                    <Select onChange={handleGetTalhao}>
                      {dataPropriedade.map((data) => (
                        <Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Option>
                      ))}
                    </Select>
                  </Form.Item>
                </Col>
                <Col flex='1 1 auto'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                      },
                    ]}
                    label='Talhões'
                    name='talhao'
                    help={
                      form.getFieldValue('cod_propriedade') === undefined
                        ? 'Selecione uma propriedade'
                        : null
                    }
                  >
                    <div
                      style={{
                        maxHeight: 'calc(100vh - 500px)',
                        overflowY: 'auto',
                      }}
                    >
                      <List loading={talhaoSele}>
                        {dataListAgrupamento.map((data, index) => {
                          const key = index + 1

                          return (
                            <List.Item
                              key={key}
                              style={{
                                background: selectTalhao === index ? '#00ffff4d' : '',
                              }}
                              onClick={() => handleClickListPolygon(index)}
                              extra={
                                <>
                                  <div key={key} id={`talhao_${index}`} />
                                  <Icon
                                    component={() => (
                                      <Svg
                                        // @ts-ignore
                                        coordinates={data.kml.coordenadas}
                                        fill={data.select ? '#70ce9f' : '#9b9b9b'}
                                      />
                                    )}
                                  />
                                </>
                              }
                            >
                              <div
                                style={{
                                  display: 'flex',
                                  justifyContent: 'center',
                                  alignItems: 'center',
                                }}
                              >
                                {' '}
                                <Switch
                                  disabled={permissions?.alteracao === 0}
                                  onChange={(swtich) => handleToogleSwitchTalhao(swtich, data.cod)}
                                  checkedChildren='Sim'
                                  unCheckedChildren='Não'
                                  checked={data.select}
                                />
                                <div style={{ paddingLeft: '15px' }}>
                                  {data.ativo ? (
                                    <h5 style={{ marginBottom: '0' }}>
                                      <Typography.Text>{data.talhao}</Typography.Text>
                                    </h5>
                                  ) : (
                                    <h5 style={{ marginBottom: '0' }}>{data.nome}</h5>
                                  )}

                                  <small>{data.area} ha</small>
                                </div>
                              </div>
                            </List.Item>
                          )
                        })}
                      </List>
                    </div>
                  </Form.Item>
                </Col>
              </Row>
              {/* Hiddenn */}

              <Form.Item initialValue='1' hidden name='status' />
              <Form.Item hidden initialValue={localStorage.getItem('codSafra')} name='cod_safra' />
            </Form>
          </AgrupamentoTalhao>
        }
      >
        <Table
          rowKey='cod'
          loading={loadingTable}
          scroll={{ y: 'calc(90vh - 220px)' }}
          pagination={pagination}
          dataSource={dataAgrupamento}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total Geral: {dataAgrupamento.length}</b>
            </div>
          )}
          bordered
          // @ts-ignore
          atualizar={listAgrupamento}
          expandable={{
            expandedRowRender: (record) => (
              <>
                <Table
                  bordered
                  style={{ marginLeft: '89px', width: '600px' }}
                  scroll={{ y: '200px' }}
                  size='small'
                  footer={() => (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'right',
                      }}
                    >
                      <b>Total Geral: {record.talhao.length}</b>
                    </div>
                  )}
                  pagination={{
                    pageSize: 999999,
                    hideOnSinglePage: true,
                  }}
                  columns={[
                    {
                      key: 1,
                      dataIndex: 'talhao_kml',
                      width: '75px',
                      title: 'Talhão',
                      render: (data) => {
                        return (
                          <>
                            <Icon
                              component={() => (
                                <Svg
                                  fill='green'
                                  fillOpacity={0.5}
                                  coordinates={data.coordenadas}
                                />
                              )}
                            />
                          </>
                        )
                      },
                    },
                    {
                      key: 2,
                      dataIndex: 'talhao',
                      title: 'Nome',
                      render: (data, full) => {
                        return <>{full.talhao_nome}</>
                      },
                      filterDropdown: ({
                        setSelectedKeys,
                        selectedKeys,
                        confirm,
                        clearFilters,
                      }) => (
                        <div style={{ padding: 8 }}>
                          <Input
                            ref={searchInputTable}
                            placeholder='Pesquisar nome talhão'
                            value={selectedKeys[0]}
                            onChange={(e) =>
                              setSelectedKeys(e.target.value ? [e.target.value] : [])
                            }
                            onPressEnter={() => handleSearch(selectedKeys, confirm)}
                            style={{ marginBottom: 8, display: 'block' }}
                          />
                          <Space>
                            <Button
                              type='primary'
                              onClick={() => handleSearch(selectedKeys, confirm)}
                              icon={<SearchOutlined />}
                              size='small'
                              style={{ width: 90 }}
                            >
                              Buscar
                            </Button>
                            <Button
                              onClick={() => {
                                confirm({ closeDropdown: false })
                                handleReset(clearFilters)
                              }}
                              size='small'
                              style={{ width: 90 }}
                            >
                              Resetar
                            </Button>
                          </Space>
                        </div>
                      ),
                      filterIcon: (filtered) => (
                        <SearchOutlined
                          style={{
                            color: filtered ? '#1890ff' : undefined,
                          }}
                        />
                      ),
                      onFilter: (value, record) =>
                        // @ts-ignore
                        record.talhao_nome
                          ? record.talhao_nome
                              .toString()
                              .toLowerCase()
                              // @ts-ignore
                              .includes(value.toLowerCase())
                          : '',
                      onFilterDropdownVisibleChange: (visible) => {
                        if (visible) {
                          setTimeout(() => searchInputTable.current.select(), 100)
                        }
                      },
                    },

                    {
                      key: 3,
                      dataIndex: 'talhao',
                      title: 'Área/ha',
                      render: (data, full) => {
                        return <>{full.talhao_area}</>
                      },
                    },
                  ]}
                  dataSource={record.talhao}
                />
              </>
            ),
          }}
          size='small'
          columns={[
            {
              key: 1,
              title: 'Talhões',
              dataIndex: 'cod',
              width: '75px',
              render: (data, record) => {
                const coord = svgCoordPropiedade(record)
                return (
                  <Icon
                    onClick={() => handleVisualizarPropri(data)}
                    component={() => (
                      <Svg
                        multi
                        fill='green'
                        fillOpacity={1}
                        // @ts-ignore
                        coordinates={coord}
                      />
                    )}
                  />
                )
              },
            },
            {
              key: 1,
              title: 'Nome',
              dataIndex: 'nome',
            },
            {
              key: 2,
              title: 'Área (ha)',
              dataIndex: 'area_total',
              render: (data) => {
                return <>{String(data).replace('.', ',')}</>
              },
            },
            {
              key: 3,
              title: 'Quantidade',
              dataIndex: 'talhao',
              render: (data) => {
                let dados = []

                for (let i = 0; i < data.length; i += 1) {
                  const element = data[i]

                  dados = [...dados, element.talhao_nome]
                }

                return (
                  <>
                    <Tag color='geekblue'>{data.length}</Tag>
                  </>
                )
              },
            },
            {
              title: 'Ações',
              dataIndex: 'cod',
              key: 'acao',
              align: 'center',
              width: '90px',
              render: (data) => (
                <>
                  <Space>
                    <Button
                      shape='circle'
                      onClick={() => handleClickViewAgrupamento(data)}
                      hidden={permissions?.alteracao === 0 && permissions?.acesso === 0}
                      icon={
                        <Icon
                          component={() => (
                            <FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />
                          )}
                        />
                      }
                      size='middle'
                    />
                    <Button
                      shape='circle'
                      hidden={permissions?.exclusao === 0}
                      onClick={() => handleTrashAgrupamento(data)}
                      className='trash-button'
                      type='primary'
                      icon={<Icon component={() => <FontA icon={solid('trash')} />} />}
                      size='middle'
                    />
                  </Space>
                  <a style={{ display: 'none' }} href='empty' ref={url}>
                    ref
                  </a>
                </>
              ),
            },
          ]}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickViewAgrupamento(record.cod)
              },
            }
          }}
        />
      </ViewPage>
      <Modal
        open={visualizarPropriedade}
        onCancel={() => setVisualizarPropriedade(false)}
        style={{ top: '10px', minWidth: '97%', height: '93vh' }}
        footer={false}
        closeIcon={false}
        closable={false}
      >
        <ContainerMap>
          {/* @ts-ignore */}
          <GoogleMap
            mapContainerClassName='flooting-map'
            mapContainerStyle={{ width: '100%', height: '93vh' }}
            center={propsMap.center}
            zoom={propsMap.zoom}
            options={{
              mapTypeId: 'hybrid',
              streetViewControl: false,
              mapTypeControl: false,
              fullscreenControl: false,
            }}
          >
            {dataPolygon.map((data) => {
              const center = centerMap(data.kml.coordenadas)
              return (
                <>
                  <Polygon
                    options={{
                      fillColor: data.select ? 'cyan' : 'white',
                      strokeColor: data.select ? 'cyan' : 'white',
                      paths: data.kml.coordenadas,
                    }}
                  />
                  {data.select ? (
                    // @ts-ignore

                    <InfoBox
                      options={{
                        closeBoxURL: '',
                        enableEventPropagation: true,
                      }}
                      position={center}
                    >
                      <div
                        style={{
                          overflow: 'hidden',
                          width: '400px',
                          textShadow: '2px 2px 3px black',
                        }}
                      >
                        <h4>
                          <b style={{ color: 'white' }}>{data.nome}</b>
                        </h4>
                      </div>
                    </InfoBox>
                  ) : null}
                </>
              )
            })}
          </GoogleMap>
          <Card
            className='floating-div'
            title={nomeAgrupamento}
            extra={<Button size='small' ghost icon={<Icons icon={faClose} />} />}
          >
            <Button type='primary' danger block onClick={() => setVisualizarPropriedade(false)}>
              Fechar
            </Button>
          </Card>
        </ContainerMap>
      </Modal>
    </Container>
  )
}

export default PageAgrupamentoTalhao
