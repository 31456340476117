import styled from 'styled-components'

export const ContainerAcesso = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .lines {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .lines-liberado {
    width: 100%;
    height: 200px;
    display: flex;
    gap: 10px;
    border: 1px solid #ddd;
    box-sizing: border-box;
    padding: 10px;
    border-top-left-radius: 5px;
    border-top-right-radius: 5px;
    display: flex;
    flex-direction: column;
    overflow-y: auto;

    p{
      margin: 0;
      padding: 0;
    }
  }

  .lines-sem-acesso {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .campo-move-keys {
    display: flex;
    gap: 10px;
  }

  .move-key-esquerda {
    border: 1px solid #bbb;
    border-radius: 5px;
    width: 47.5%;
    padding: 10px;
    box-sizing: border-box;
  }

  .keys-moves {
    display: flex;
    gap: 10px;
    justify-content: center;
    align-items: center;
    flex-direction: column;
  }

  .move-key-direita {
    border: 1px solid #bbb;
    border-radius: 5px;
    width: 47.5%;
    padding: 10px;
    box-sizing: border-box;
  }

  .header-sem-acesso {
    display: flex;
    align-items: center;
    border-bottom: 1px solid #bbb;
    gap: 10px;
    padding: 10px 0;
  }

  .label-sem-acesso {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .clientes-sem-acesso {
    display: flex;
    flex-direction: column;
    gap: 10px;
    padding: 10px 0px;
  }

  .clientes {
    display: flex;
    align-items: center;
  }

  .nome-clientes {
    width: 100%;
    margin: 0px;
    padding: 0px;
  }

  .ant-transfer-list-content-item-text {
    display: block;
    width: 300px;
  }

  /* Cor de fundo quando o item for selecionado */
  .ant-transfer-list-content-item-checked {
    background-color: #d4f4d2 !important; /* Verde claro */
    border-color: #52c41a !important; /* Borda verde */
  }

  /* Personalizar checkbox */
  .ant-checkbox-wrapper:hover .ant-checkbox-inner,
  .ant-checkbox-checked .ant-checkbox-inner {
    border-color: #52c41a !important; /* Borda verde no hover e selecionado */
    background-color: #5da57d !important; /* Fundo verde quando marcado */
  }


  .ant-transfer-operation .ant-btn-primary {
    background-color: #5da57d !important; 
    border-color: #52c41a !important; 
  }

  .ant-transfer-operation .ant-btn[disabled] {
    background-color: #eee !important; 
    border-color: #d9d9d9 !important;
    color: #ccc !important; 
  }

  /* Hover do botão ativo */
  .ant-transfer-operation .ant-btn-primary:hover {
    background-color: #5da57d!important; /* Verde mais escuro no hover */
    border-color: #5da57d !important;
    color: white !important;
  }
`
