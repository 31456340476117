import { CSSProperties } from "styled-components";
import { Button } from "./styled";
import { Spin } from "antd";

//PROPS
interface Props {
    value?: string;
    icon?: React.ReactNode;
    children?: React.ReactNode;
    onClick: Function;
    type?: 'primary' | 'secondary' | 'tertiary'
    danger?: boolean;
    disabled?: boolean;
    style?: CSSProperties;
    refProp?: any,
    loading?: boolean,
    hidden?: boolean,
}

const ButtonCustom: React.FC<Props> = ({ value, icon, onClick, type, danger, children, disabled, style, refProp, loading, hidden }) => {
    
    if (hidden) {
        return null
    };

    return (
        <Button 
            className="ttivos-button"
            data-type={type??'primary'}
            data-danger={danger}
            onClick={()=>{onClick?.()}}
            value={value}
            disabled={disabled}
            style={style}
            type="button"
            ref={refProp}
        >
            
            {loading ? <Spin size="small" /> : <>{icon}</>}
            {children}
        </Button>
    )
};

export default ButtonCustom;