import L from 'leaflet';
import React, { Fragment, Ref, useEffect, useRef, useState } from 'react';
import dayjs, { Dayjs } from 'dayjs';
import SlowBookOverlayWorker from 'worker-loader!../slowOverlay.worker';
import { v4 as uuid } from 'uuid';
import { Card, Descriptions, message } from 'antd';
import { ImageOverlay, Marker, Polygon } from 'react-leaflet';
import { capitalize, groupBy, orderBy, uniq } from 'lodash';

//APP
import { useLogic } from '../useLogic';
import { OsItensTypes } from '../../../../services/Laboratorio';
import { removerCalcario2040, replaceCalcario } from './meta/ajustes_calcario';
import { PropriedadeItens } from '../../../../services/Propriedades';
import { ItensElementosTypes } from '../../../../services/Elementos';
import { ConsultoriaItensTypes } from '../../../../services/Cliente';
import { ItensMapaFertilidadeTypes } from '../../../../services/Laboratorio/osResultado';
import { obterObservacao, verificarVisibilidadeElemento } from './meta/config_book';
import { Item__MapaFertilidade__Config, useGetElementosFertilidade } from '../../../../services/novoConfigBook/get';
import { decodeHtml, geraOverlayView, iconDiv, middleIndex, tryError } from '../../../../services/Afins';
import { ObterNomeProfundidade_ou_Secao, obterLIPrincipal, obterNomeProfundidadeProps } from './meta';

//STYLES
import { Capa, Container, Content, ContentPontos, Page, PdfPrint, ResumoNutrientes, 
        SubCapa, SubCapaWhite, Sumario, TrReverse, Warning } from './styles';

//COMPONENTS
import Carregando from '../../../CustomUi/loading';
import MapLeaflet from '../../../MapLeaflet';
import TituloPagina from './components/tituloPagina';
import RodapeTtivvos from '../../../RodapeTtivos';
import BackToTopButton from '../../../BackTop';
import TabelaFertilidade from './components/tabelaFertilidade';

interface Props {
    idPrint?: string
    typeKricagem: boolean
    defaultBounds: L.LatLngBounds
    isLoading?: (loading: boolean) => void
    exibeTerreno?: boolean
    idScroll?: string
    dataColeta: Dayjs | string
    dataFertilidade: { [key: string]: ItensMapaFertilidadeTypes[] }
    talhao: OsItensTypes['talhao_kml']
    propriedade: PropriedadeItens
    talhaoNome: string
    areaTalhao?: string
    elementosData: ItensElementosTypes[]
    // rangeZoom?: number
    onRangeZoom?: (zoom: number) => void
    consultoria: ConsultoriaItensTypes
    nameCliente: string
    openBook?: boolean
    refPrint?: Ref<HTMLDivElement>
    cod_prescricoes: Set<number>
    cods_q_devem_ser_reverso?: number[];
    setNome_cliente_painel?: React.Dispatch<React.SetStateAction<string>>;
    nome_calcario_propriedade?: any;
    qualidade_definicao_book?: number;
    sempre_quebrar_sumario?: boolean;
}

interface PropKrikagem {
    bounds: L.LatLngBounds
    circle: L.LatLngTuple[]
    dataProfundidade: ItensMapaFertilidadeTypes[]
    krigagem: {
        url: string
        colors: any[]
        descriptionElemento: string
        ordem: number
        bounds: {
            east: number
            north: number
            south: number
            west: number
        },
        valor_interpretado: { minimo: number, media: number, maximo: number }
    }[]
}

const DrawerPrintMapaFertilidade: React.FC<Props> = (props) => {
    const {
        idPrint,
        refPrint,
        dataFertilidade,
        talhao = { coordenadas: [] },
        talhaoNome,
        propriedade,
        typeKricagem,
        defaultBounds,
        dataColeta,
        elementosData,
        isLoading,
        idScroll = 'divScroll',
        areaTalhao,
        nameCliente,
        consultoria,
        onRangeZoom,
        exibeTerreno,
        openBook,
        cod_prescricoes,
        cods_q_devem_ser_reverso,
        setNome_cliente_painel,
        nome_calcario_propriedade,
        qualidade_definicao_book,
        sempre_quebrar_sumario
    } = props
    const { generateColors } = useLogic();


    const [polygon, setPolygon] = useState<L.LatLngExpression[]>([]);
    const [loading, setLoading] = useState(false);

    const [dataElementos, setDataElementos] = useState<{ [key: string]: ItensMapaFertilidadeTypes[] }[]>([]);
    const [dataKrigagem, setDataKrigagem] = useState<PropKrikagem[]>([]);

    const refSumario = useRef<HTMLDivElement>();

    const mapL = useRef<L.Map[]>([]);

    //LOCAL
    const tipoAcesso = localStorage.getItem('tipo-acesso');
    const nomeBookApp = JSON.parse(localStorage.getItem('bookApp'));
    const nomeClienteLocal = localStorage.getItem('nome-cliente');

    //STATES
    const [logo_cliente, setLogo_cliente] = useState('');
    const [nome_cliente, setNome_cliente] = useState('');

    //API
    const { data_elementos: data_config_book, carregando_elementos: carregando_config, isFetched: isFetched__config } = useGetElementosFertilidade();

    //CONST
    const configuracaos_do_book: Item__MapaFertilidade__Config[] = data_config_book?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? [];

    const filtrarDadosLocal = () => {
        if (nomeBookApp == 1) {
            setLogo_cliente(localStorage.getItem('logo'));
            setNome_cliente(localStorage.getItem('nome-cliente'));
            setNome_cliente_painel(localStorage.getItem('nome-cliente'));
        } else {
            const cod =
                tipoAcesso === 'conta'
                    ? JSON.parse(localStorage.getItem('conta'))
                    : JSON.parse(localStorage.getItem('cod-cliente'))

            const licenciadoPara = tipoAcesso === 'cliente' ? nomeClienteLocal : consultoria.linha1;
            setNome_cliente(licenciadoPara);
            setNome_cliente_painel(licenciadoPara);
            const dadosLocal = JSON?.parse?.(localStorage.getItem('Itens'))?.[0]
            const acessoCliente = dadosLocal?.['acessoConta']
            const clientes = tipoAcesso === 'conta' ? acessoCliente['contas'] : acessoCliente['clientes'];
            const dadosLogoLocal = clientes.find((codId) => codId.cod === cod)
            if (dadosLogoLocal) {
                const logos = dadosLogoLocal?.['logo']
                setLogo_cliente(logos)
            }
        }
    }

    const overLayGera = async ({ grid, colors, lats, longs }) => {
        let overlay: { url: string; bounds: any; key: any; colors: any }
        if (colors.length > 1) {
            overlay = await geraOverlayView(grid, lats, longs, colors)
        } else {
            overlay = { key: null, url: null, colors, bounds: null }
        }

        return overlay
    }

    function AgruparElementosPrecricao(dados_prescricao) {

        let result = dados_prescricao.reduce((acc, curr) => {

            const newEntry = {
                classificacao: curr.classificacao,
                cod_elemento: curr.cod_elemento,
                cod_elemento_pedido: curr.cod_elemento_pedido,
                cod_profundidade: curr.cod_profundidade,
                cod_propriedade: curr.cod_propriedade,
                cod_safra_conta: curr.cod_safra_conta,
                create_time: curr.create_time,
                create_user: curr.create_user,
                delete_time: curr.delete_time,
                delete_user: curr.delete_user,
                elemento: curr.elemento,
                elemento_descricao: curr.elemento_descricao === 'Calcário' ? `${curr.elemento_descricao} ${curr.profundidade}` : `${curr.elemento_descricao}`,
                cod_elemento_descricao: curr.cod_elemento === 30 ? `${curr.cod_elemento} | ${curr.profundidade}` : curr.cod_elemento,
                latitude: curr.latitude,
                limite_maximo: curr.limite_maximo,
                limite_minimo: curr.limite_minimo,
                longitude: curr.longitude,
                maximo: curr.maximo,
                media: curr.media,
                minimo: curr.minimo,
                observacao: curr.observacao,
                ordem: curr.ordem,
                ponto: curr.ponto,
                profundidade: curr.profundidade,
                qualidade: curr.qualidade,
                unidade: curr.unidade,
                update_time: curr.update_time,
                update_user: curr.update_user,
                uso_mapa: curr.uso_mapa,
                valor: curr.valor,
                valor_distribuido: curr.valor_distribuido,
                valor_interpretado: cod_prescricoes.has(curr.cod_elemento) ? curr.valor_distribuido : curr.valor_interpretado
            };
            return [...acc, newEntry];
        }, []);

        return result
    }

    useEffect(() => {
        let dados = []

        Object.entries(dataFertilidade).map(([key, value]) => {

            const filter = value.filter((rec) =>
                typeKricagem ? (rec.valor_interpretado !== null || cod_prescricoes.has(rec.cod_elemento)) : rec.valor_distribuido !== null
            )
            if (filter.length > 0) {
                const or = orderBy(filter, (or) => {
                    return Number(or.ordem)
                })

                dados = [...dados, groupBy(AgruparElementosPrecricao(or), 'cod_elemento_descricao')]

            }
        })


        function ajustarArraysNaEstrutura(objeto) {
            function ajustarArray(array) {
                if (array.length === 1) {
                    while (array.length < 3) {
                        array.push(array[0])
                    }
                }
                return array
            }

            function percorrer(obj) {
                for (const chave in obj) {
                    if (Array.isArray(obj[chave])) {
                        obj[chave] = ajustarArray(obj[chave])
                    } else if (typeof obj[chave] === 'object') {
                        percorrer(obj[chave])
                    }
                }
            }

            percorrer(objeto)

            return objeto
        }

        ajustarArraysNaEstrutura(dados)

        setDataElementos(dados)

        if (Object.keys((rec) => rec).length === 0) {
            setDataKrigagem([])
        }
    }, [dataFertilidade]);

    function obterOrdem(cod_elemento: number): number | null | undefined {
        let elemento = (data_config_book?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? [])?.find((item)=> item.elemento === cod_elemento);
        return elemento?.secoes?.[0]?.ordem;
    }

    useEffect(() => {
        if (dataElementos.length > 0) {
            const fetchData = async () => {
                setLoading(true)
                isLoading?.(true)
                let dataUrls: {
                    bounds: any
                    circle: any
                    dataProfundidade: any
                    qualidade_definicao_book: number
                    krigagem: {
                        coord: any[]
                        points: any[]
                        colors: any[]
                        descriptionElemento: string
                        ordem: number,
                        valor_interpretado: any
                    }[]
                }[] = []

                for (const data_elemento of dataElementos) {
                    
                    const data = data_elemento
                    const index = Object.keys(data)[0];
                    const circle = data[index];

                    const latLng: L.LatLngTuple[] = circle.map((rec) => {
                        return [Number(rec.latitude), Number(rec.longitude)]
                    })

                    const polygon = new L.Polygon(latLng, {})

                    const bounds = polygon.getBounds();

                    function obterValorEncontrado(cod_elemento: number, valor_interpretado: string, valor_distribuido: string): number {
                        if (!typeKricagem) {
                            return Number(valor_distribuido);
                        }
                        return cod_prescricoes.has(cod_elemento) ? Number(valor_distribuido) : Number(valor_interpretado)
                    }
                    function obterTipodeMapa(key: string): boolean {
                        if (cod_prescricoes.has(Number(key))) {
                            return false;
                        }
                        return (key === '30 | 00-30') ? false : typeKricagem;
                    }

                    dataUrls = [
                        ...dataUrls,
                        {
                            bounds,
                            circle: latLng,
                            dataProfundidade: circle,
                            qualidade_definicao_book: qualidade_definicao_book,
                            krigagem: await Promise.all(
                                Object.entries(data).map(async ([key, value]) => {
                                    const reverse: boolean = cods_q_devem_ser_reverso.indexOf(Number(key?.split('|')?.[0])) > -1;

                                    const colors = uniq(
                                        generateColors(
                                            value.map((item) =>
                                                obterValorEncontrado(item.cod_elemento, item.valor_interpretado, item.valor_distribuido)
                                            ),
                                            obterTipodeMapa(key),
                                            !reverse
                                        )
                                    )

                                    
                                    const lastColor = colors[colors.length - 1]

                                    colors.push(lastColor);

                                    function obterValorDistribuido(cod_elemento: number, valor_distribuido: string, valor_interpretado: string): string {
                                        if (!typeKricagem) {
                                            return valor_distribuido;
                                        }
                                        return cod_prescricoes.has(cod_elemento) ? valor_distribuido : valor_interpretado;
                                    }

                                    const pontos = value.map((item) => {
                                        return {
                                            lat: Number(item.latitude),
                                            lng: Number(item.longitude),
                                            ponto: String(item.ponto),
                                            valor: item.valor,
                                            valorDistribuido: obterValorDistribuido(item.cod_elemento, item.valor_distribuido, item.valor_interpretado)
                                        }
                                    })
                                    const coord = talhao?.coordenadas.map((rec) => {
                                        return [Number(rec.longitude), Number(rec.latitude)]
                                    })

                                    const poly: L.LatLngExpression[] = talhao?.coordenadas.map((rec) => {
                                        return { lat: Number(rec.latitude), lng: Number(rec.longitude) }
                                    })

                                    setPolygon(poly)

                                    const valuesDis = value.map((rec) =>
                                        obterValorDistribuido(rec.cod_elemento, rec.valor_distribuido, rec.valor_interpretado)
                                    )

                                    const points = [
                                        {
                                            t: valuesDis,
                                            x: pontos.map((rec) => rec.lng),
                                            y: pontos.map((rec) => rec.lat),
                                        },
                                    ];

                                    const krig = {
                                        coord,
                                        points,
                                        colors,
                                        descriptionElemento: value[0].elemento_descricao,
                                        ordem: obterOrdem(value[0].cod_elemento) ?? Number(value[0].ordem),
                                        valor_interpretado: (value ?? []).map(item => item.valor_interpretado)
                                    }

                                    return krig
                                })
                            ),
                        },
                    ]
                    
                }

                const webWorkerBook = new SlowBookOverlayWorker();

                webWorkerBook.onmessage = async (e) => {
                    const data_worker: {
                        bounds: L.LatLngBounds
                        circle: L.LatLngTuple[]
                        dataProfundidade: ItensMapaFertilidadeTypes[]
                        krigagem: { grid; lats; longs; colors; descriptionElemento; ordem, valor_interpretado, teste }[]
                    }[] = await e.data
                    

                    try {
                        // console.log("data_worker: ", data_worker)
                        const overlayView = data_worker?.map?.(async (rec) => {
                            const response = (rec?.krigagem ?? []).map(async (item) => {
                                const overlay = await overLayGera({ ...item })
                                return {
                                    url: overlay?.url,
                                    colors: uniq(overlay.colors),
                                    descriptionElemento: item.descriptionElemento,
                                    ordem: Number(item.ordem),
                                    bounds: overlay.bounds, // falta esse
                                    valor_interpretado: item.valor_interpretado
                                }
                            })
                            const resolve = await Promise.all(response)
                            return {
                                bounds: rec.bounds,
                                circle: rec.circle,
                                dataProfundidade: rec.dataProfundidade,
                                krigagem: orderBy(resolve, 'ordem')
                            }
                        })

                        const resolve = await Promise.all(overlayView)

                        setDataKrigagem(resolve)

                        isLoading?.(false)
                        setLoading(false)
                        message.destroy()
                        webWorkerBook.terminate()
                    } catch (error) {
                        webWorkerBook.terminate()
                        setLoading(false)
                        isLoading?.(false)
                        message.destroy()
                        message.error('Erro ao gerar Grid!')
                        tryError(error)
                    }
                }
                webWorkerBook.postMessage({ command: 'message-book', dados: dataUrls })
            }

            if (isFetched__config) {
                fetchData();                
            }
            
        }
    }, [dataElementos, isFetched__config]);

    let pagina = 5;
    let paginaMapa = 5;


    function novaListadeFertilidades(lista_fertility: ItensMapaFertilidadeTypes[]) {
        let dados_receber = lista_fertility ?? [];

        for (const item_elemento of dados_receber) {
            item_elemento.elemento_descricao = item_elemento.cod_elemento == 30
                ? (`Calcário | ${item_elemento.profundidade}`).slice(0, 16)
                : `${item_elemento.elemento_descricao}`;
        }

        return dados_receber;
    }

    useEffect(() => {
        if (!openBook) {
            setDataKrigagem([]);
        }
    }, [openBook])


    useEffect(() => {
        filtrarDadosLocal()
    }, [])

    function obterElementoDescricao(lista_de_elementos: any, nome_elemento: string) {

        return lista_de_elementos[replaceCalcario(nome_elemento)]?.[0] ?? lista_de_elementos[nome_elemento]?.[0];
    }

    //teste rio interprise

    function substituirAsc(str:string) {
        
        let remove = str?.replace(/quot;|&amp;/g, '"');
        
        return remove ?? str;
    }

    return (
        <Container>
            {(loading || carregando_config) ? (
                <div
                    style={{
                        display: 'flex',
                        width: '100%',
                        height: '100vh',
                        justifyContent: 'center',
                        alignItems: 'center',
                    }}
                >
                    <Carregando 
                        legenda={carregando_config ? 'Carregando configurações do book...' : 'Gerando book completo...'}
                        animation
                        justifyContent='center'
                        size='large'
                    />
                </div>
            ) : (
                <>
                    <BackToTopButton idScroll={idScroll} idBack='sumario' />
                    <PdfPrint id={idPrint} ref={refPrint}>
                        <Capa className='break' id='topo-documento'>
                            <div className='title'>
                                <h2>Ronaldo Araújo Marques</h2>
                                <span>Engenheiro Agrônomo</span>
                            </div>
                            <div className='footer'>

                                <div>
                                    <img className='Capa__logo' src={logo_cliente ?? consultoria?.logo_url} alt={consultoria?.logo_url ? 'logo-consultoria' : 'consultoria-logo'} />
                                </div>
                            </div>
                        </Capa>

                        <Sumario id='sumario' className={(typeKricagem || sempre_quebrar_sumario) ? 'break-manual' : 'break'} $interpretado={typeKricagem} ref={refSumario}>
                            <span className='Sumario__referencia'>
                                <h2>Sumário</h2>
                                <ul key={uuid()} className='ul-principal'>
                                    {dataKrigagem.map((data, index) => {

                                        const nome_profundidade_ou_secao: string = ObterNomeProfundidade_ou_Secao({
                                            index: index,
                                            tamanho_array: dataKrigagem.length,
                                            tipo_de_mapa: typeKricagem,
                                            profundidade: data.dataProfundidade[0]?.profundidade

                                        });

                                        const profundidade_sem_hifen = nome_profundidade_ou_secao.replace('-', ' a ');

                                        pagina = index === 0 ? pagina + 1 : pagina + 2;

                                        let pageResumo = 0;

                                        return (
                                            <>
                                                {((typeKricagem && nome_profundidade_ou_secao === 'prescricoes') || (sempre_quebrar_sumario && nome_profundidade_ou_secao === 'prescricoes')) && (<div className='quebrar-forca' style={{ width: '100%', height: 0 }} />)}
                                                <li className='li-principal'>
                                                    <div className='div-principal'>
                                                        <div>
                                                            <a href={`#${profundidade_sem_hifen}`}>
                                                                {obterLIPrincipal(nome_profundidade_ou_secao, profundidade_sem_hifen, typeKricagem)}
                                                            </a>
                                                        </div>
                                                        <div key={uuid()}>{pagina}</div>
                                                    </div>
                                                    <ul className='ul-secundaria'>
                                                        {(data.krigagem ?? []).map((krig, sub_index) => {

                                                            const fertility = nome_profundidade_ou_secao === 'prescricoes' ? AgruparElementosPrecricao(dataFertilidade[nome_profundidade_ou_secao]) : dataFertilidade[nome_profundidade_ou_secao];
                                                            const elementData = groupBy(fertility, 'elemento_descricao')

                                                            const dataElemento = obterElementoDescricao(elementData, krig?.descriptionElemento);

                                                            let cod_elemento: number = Number(dataElemento?.cod_elemento);

                                                            const isVisivelNoBook: boolean = verificarVisibilidadeElemento({
                                                                config_elementos: configuracaos_do_book,
                                                                cod_elemento,
                                                                tipo_secao_ou_profundidade: nome_profundidade_ou_secao,
                                                                tipo_mapa: typeKricagem,
                                                                nome_elemento: dataElemento?.elemento_descricao
                                                            });

                                                            let resumoPage = (page) => {
                                                                if (sub_index === data.krigagem.length - 1 && nome_profundidade_ou_secao !== 'indicadores' && nome_profundidade_ou_secao !== 'prescricoes') {
                                                                    return (
                                                                        <li className='li-secundaria'>
                                                                            <div className='div-secundaria'>
                                                                                <div>
                                                                                    <a href={`#resumo${data.dataProfundidade[0]?.profundidade}`}>
                                                                                        Resumo de análises{' '}
                                                                                        {data.dataProfundidade[0]?.profundidade.replace('-', ' a ')}{' '}
                                                                                        {data.dataProfundidade[0]?.profundidade !== 'micro' &&
                                                                                            'cm:'}
                                                                                    </a>
                                                                                </div>
                                                                                <div>{page}</div>
                                                                            </div>
                                                                        </li>
                                                                    )
                                                                }
                                                                return null
                                                            }
                                                            pagina = pagina + 1

                                                            if (sub_index === data.krigagem.length - 1) {
                                                                pageResumo = pagina + 1
                                                            }
                                                            if ((isVisivelNoBook === false) || removerCalcario2040(dataElemento)) {
                                                                pagina -= 1
                                                                pageResumo = pageResumo - 1
                                                                return resumoPage(pageResumo)
                                                            }
                                                            else {
                                                                let key_id = sub_index;
                                                                return (
                                                                    <Fragment key={key_id}>
                                                                        <li className='li-secundaria' id={`item_elemento_${sub_index}`}>
                                                                            <div className='div-secundaria'>
                                                                                <div>
                                                                                    <a href={`#${profundidade_sem_hifen}${dataElemento?.elemento_descricao}`}>
                                                                                        {dataElemento?.elemento_descricao}:
                                                                                    </a>{' '}
                                                                                </div>
                                                                                <div>{pagina}</div>
                                                                            </div>
                                                                        </li>
                                                                        {resumoPage(pageResumo)}
                                                                    </Fragment>
                                                                )
                                                            }
                                                        })}
                                                    </ul>
                                                </li>
                                            </>
                                        )
                                    })}
                                </ul>
                            </span>

                        </Sumario>

                        <Warning className='break '>
                            <div
                                className='msg'
                                dangerouslySetInnerHTML={{
                                    __html: decodeHtml(data_config_book?.itens?.[0]?.mapa_fertilidade?.texto_introducao)
                                }}
                            />

                            <div className='logo'>
                                <div>
                                    <img className='Warning__logo' src={logo_cliente ?? consultoria?.logo_url} alt='logo-consultoria' />
                                </div>
                            </div>
                        </Warning>

                        {dataKrigagem.map((data, index) => {

                            const nome_profundidade_ou_secao = ObterNomeProfundidade_ou_Secao({
                                index: index,
                                tamanho_array: dataKrigagem.length,
                                tipo_de_mapa: typeKricagem,
                                profundidade: data.dataProfundidade[0]?.profundidade

                            })

                            const profundidade_sem_hifen = nome_profundidade_ou_secao.replace('-', ' a ').split(' ');

                            paginaMapa = index === 0 ? paginaMapa + 1 : paginaMapa + 2;

                            let pageResumo = 0;

                            const nome_profundidade_pagina = obterNomeProfundidadeProps({
                                index: index,
                                tamanho_array: dataKrigagem.length,
                                tipo_de_mapa: typeKricagem,
                                profundidade: data.dataProfundidade[0]?.profundidade
                            })

                            if (nome_profundidade_pagina === 'indicadores') {
                                pagina -= (typeKricagem ? 1 : 2)
                            }
                            if (nome_profundidade_pagina === 'prescricoes') {
                                pagina -= 1
                            }

                            return (
                                <>
                                    <SubCapa
                                        className='break'
                                        hidden={nome_profundidade_pagina === '00-50'}
                                        profundidade={nome_profundidade_pagina}
                                        isPrescricoes={nome_profundidade_pagina === 'prescricoes'}
                                        isIndicadores={nome_profundidade_pagina === 'indicadores'}
                                    >
                                        <div className='header'id={profundidade_sem_hifen.join(' ')}>
                                            <h1>
                                                {profundidade_sem_hifen.map((rec) => {
                                                    let ele
                                                    if (rec === 'a') {
                                                        ele = <span style={{ color: '#00a8b9' }}>{rec}</span>
                                                    } else {
                                                        ele = <b style={{ fontSize: '35px' }}>{capitalize(String(rec)?.replace("prescricoes", ''))}</b>
                                                    }
                                                    return ele
                                                })}
                                                <span style={{ color: '#00a8b9' }}>
                                                    {' '}
                                                    {(nome_profundidade_pagina !== 'micro' && nome_profundidade_pagina !== 'indicadores' && nome_profundidade_pagina !== 'prescricoes') && 'cm:'}
                                                </span>
                                            </h1>
                                        </div>
                                        <div className='footer'>
                                            <div>
                                                <img className='SubCapa__logo' src={logo_cliente ?? consultoria.logo_url} alt='logo-consultoria' />
                                            </div>
                                        </div>
                                    </SubCapa>

                                    <SubCapaWhite className='break' hidden={nome_profundidade_pagina !== '00-50'}>
                                        <div className='header'>
                                            <h1>
                                                {profundidade_sem_hifen.map((rec) => {
                                                    let ele
                                                    if (rec === 'a') {
                                                        ele = <span style={{ color: '#00a8b9' }}>{rec}</span>
                                                    } else {
                                                        ele = <b style={{ fontSize: '35px' }}>{capitalize(rec)}</b>
                                                    }
                                                    return ele
                                                })}
                                                <span style={{ color: '#00a8b9' }}>
                                                    {' '}
                                                    {(nome_profundidade_pagina !== 'micro' && nome_profundidade_pagina !== 'indicadores' && nome_profundidade_pagina !== 'prescricoes') && 'cm:'}
                                                </span>
                                            </h1>
                                        </div>
                                        <div className='title'>
                                            <div>Granulometria</div>
                                        </div>
                                        <div className='footer'>
                                            <div>
                                                <img src={logo_cliente ?? consultoria.logo_url} alt='logo-consultoria' />
                                            </div>
                                        </div>
                                    </SubCapaWhite>

                                    <ContentPontos className='break' hidden={nome_profundidade_pagina === 'indicadores' || nome_profundidade_pagina === 'prescricoes'}>
                                        <Card size='small' className='header'>
                                            <h1 id={profundidade_sem_hifen.join(' ')}>PONTOS COLETADOS </h1>
                                            <h1 key={nome_profundidade_pagina}>{capitalize(nome_profundidade_pagina)}</h1>
                                        </Card>

                                        <MapLeaflet
                                            exibeTerreno={exibeTerreno}
                                            sizeMarcaDagua={3}
                                            className='map-style'
                                            whenCreated={(map) => {
                                                map.fitBounds(defaultBounds)
                                                onRangeZoom?.(map.getZoom())
                                            }}
                                            zoomAnimation={true}
                                            scrollWheelZoom={false}
                                            zoomControl={false}
                                            dragging={false}
                                            style={{
                                                width: '95%',
                                                height: '60%',
                                                borderRadius: '10px',
                                            }}
                                        >
                                            {data.circle.map((ltdLng) => {
                                                const ponto = data.dataProfundidade.find(
                                                    (rec) =>
                                                        Number(rec.latitude) === ltdLng[0] &&
                                                        Number(rec.longitude) === ltdLng[1]
                                                ).ponto
                                                return <Marker key={Number(ltdLng)} position={ltdLng} icon={iconDiv(ponto)} />
                                            })}
                                            <Polygon
                                                positions={polygon}
                                                pathOptions={{
                                                    opacity: 1,
                                                    color: 'cyan',
                                                    fillColor: 'cyan',
                                                    fillOpacity: 0.3,
                                                }}
                                            />
                                        </MapLeaflet>

                                        <Descriptions
                                            style={{ marginBottom: 70, width: '100%', padding: '15px' }}
                                            labelStyle={{
                                                fontSize: 12,
                                                fontWeight: 'bold',
                                                backgroundColor: '#f1f1f1',
                                            }}
                                            column={1}
                                            size='small'
                                            bordered
                                            layout='horizontal'
                                        >
                                            <Descriptions.Item label='Produtor'>{nameCliente}</Descriptions.Item>
                                            <Descriptions.Item label='Fazenda'>{propriedade?.nome}</Descriptions.Item>
                                            <Descriptions.Item label='Campo'>
                                                {talhaoNome} - {areaTalhao}ha
                                            </Descriptions.Item>
                                            <Descriptions.Item label='Total de pontos coletados:'>
                                                {data.circle.length}
                                            </Descriptions.Item>
                                        </Descriptions>
                                        <RodapeTtivvos logo={false} />
                                        <Page>{paginaMapa}</Page>
                                    </ContentPontos>
                                    
                                    {((data.krigagem ?? [])).map((krig, i) => {

                                        const bounds = L.polygon(polygon, {})

                                        let fertilidade_concatenada = novaListadeFertilidades(dataFertilidade[nome_profundidade_pagina]);
                                        
                                        const elementData = groupBy(fertilidade_concatenada, 'elemento_descricao');
                                        
                                        const elemento = obterElementoDescricao(elementData, krig?.descriptionElemento);

                                        const reverse = [7,18,25,26,28,30,31,32,41,42,43,44,45,46,49,50]?.indexOf(Number(elemento?.cod_elemento)) > -1;

                                        paginaMapa = paginaMapa + 1;

                                        if (i === data.krigagem.length - 1) {
                                            pageResumo = paginaMapa + 1
                                        }

                                        const isVisivelNoBook: boolean = verificarVisibilidadeElemento({
                                            config_elementos: configuracaos_do_book,
                                            cod_elemento: Number(elemento?.cod_elemento),
                                            tipo_secao_ou_profundidade: nome_profundidade_ou_secao,
                                            tipo_mapa: typeKricagem,
                                            nome_elemento: elemento?.elemento_descricao
                                        });

                                        const obs_do_elemento: string = obterObservacao({
                                            config_elementos: configuracaos_do_book,
                                            cod_elemento: Number(elemento?.cod_elemento),
                                            tipo_secao_ou_profundidade: nome_profundidade_ou_secao,
                                            tipo_mapa: typeKricagem,
                                            nome_elemento: elemento?.elemento_descricao
                                        });

                                        if (isVisivelNoBook === false || removerCalcario2040(elemento)) {
                                            pageResumo = pageResumo - 1
                                            paginaMapa -= 1
                                            return null
                                        } 
                                        else {
                                            let key_id_pagina: number = i;
                                            return (
                                                <Content className='break' key={key_id_pagina}>

                                                    <TituloPagina 
                                                        id={profundidade_sem_hifen.join(' ') + elemento?.elemento_descricao}
                                                        nome_elemento={krig.descriptionElemento}
                                                        profundidade={nome_profundidade_pagina}
                                                        tipo_de_mapa={typeKricagem ? 'interpretado' : 'distribuído'}
                                                    />
   
                                                    <MapLeaflet
                                                        id={`map_krig${i}`}
                                                        exibeTerreno={exibeTerreno}
                                                        sizeMarcaDagua={3}
                                                        scrollWheelZoom={false}
                                                        className='map-style map-dimension'
                                                        zoomAnimation
                                                        zoomSnap={0}
                                                        zoomDelta={0.25}
                                                        zoomControl={false}
                                                        dragging={false}
                                                        whenCreated={(map) => {
                                                            map.fitBounds(defaultBounds, { padding: [45, 45] })
                                                            mapL.current = [...mapL.current, map]
                                                        }}
                                                    >
                                                        {krig.colors.length > 1 && (
                                                            <ImageOverlay
                                                                key={krig.url}
                                                                url={krig.url}
                                                                bounds={bounds.getBounds()}
                                                            />
                                                        )}
                                                        <Polygon
                                                            positions={polygon}
                                                            pathOptions={{
                                                                opacity: 1,
                                                                color: 'cyan',
                                                                fillColor: krig.colors.length === 1 && krig.colors[0],
                                                                fillOpacity: krig.colors.length === 1 ? 1 : 0,
                                                                weight: 5,
                                                            }}
                                                        />
                                                    </MapLeaflet>

                                                    <TabelaFertilidade
                                                        nome_cliente={nameCliente !== '' ? (nameCliente ?? nome_cliente) : nome_cliente}
                                                        nome_propriedade={propriedade?.nome}
                                                        logo_url={logo_cliente ?? consultoria?.logo_url}
                                                        nome_calcario_propriedade={nome_calcario_propriedade}

                                                        nome_talhao={talhaoNome}
                                                        data_coleta={dataColeta ? dayjs(dataColeta).format('DD/MM/YYYY') ?? 'Não informado' : 'Não informado'}
                                                        profundidade={`${nome_profundidade_pagina} ${nome_profundidade_pagina !== 'micro' && nome_profundidade_pagina !== 'indicadores' && nome_profundidade_pagina !== 'prescricoes'
                                                                ? 'cm'
                                                                : ''
                                                            }`}
                                                        elemento_analisado={replaceCalcario(krig?.descriptionElemento)}

                                                        unidade={elementosData.find((rec) => rec.cod === (elemento?.cod_elemento))?.unidade}
                                                        nota_minima={obterElementoDescricao(elementData, krig?.descriptionElemento)?.minimo}
                                                        nota_media={obterElementoDescricao(elementData, krig?.descriptionElemento)?.media}
                                                        nota_maxima={obterElementoDescricao(elementData, krig?.descriptionElemento)?.maximo}
                                                        cor_invertida={reverse}
                                                        isPrescricao={nome_profundidade_pagina === 'prescricoes'}
                                                        isIndicadores={nome_profundidade_pagina === 'indicadores'}
                                                        colors={krig?.colors}
                                                        interpretado_minimo={krig?.valor_interpretado?.minimo}
                                                        interpretado_media={krig?.valor_interpretado?.media}
                                                        interpretado_maximo={krig?.valor_interpretado?.maximo}
                                                        cod_elemento={elemento?.cod_elemento}
                                                        isInterpretado={typeKricagem}
                                                        areaTalhao={Number(areaTalhao)}
                                                    />

                                                    <div style={{ width: '100%', marginBottom: 100 }}>
                                                        <Card
                                                            bodyStyle={{ textAlign: 'left', height: 95, fontSize: 12 }}
                                                            size='small'
                                                        >
                                                            
                                                            <p>
                                                                <b>Obs.: </b>
                                                                <span dangerouslySetInnerHTML={{ __html: substituirAsc(obs_do_elemento) }} />
                                                            </p>
                                                            
                                                        </Card>
                                                    </div>
                                                    <RodapeTtivvos logo={false} />

                                                    <Page>{paginaMapa}</Page>
                                                </Content>
                                            )
                                        }
                                    })}

                                    {(nome_profundidade_pagina !== 'indicadores' && nome_profundidade_pagina !== 'prescricoes') && (
                                        <ResumoNutrientes className='break'>
                                            <h2 id={`resumo${data.dataProfundidade[0]?.profundidade}`}>
                                                Resumo de análises{' '}
                                                {data.dataProfundidade[0]?.profundidade.replace('-', ' a ')} {(data.dataProfundidade[0]?.profundidade) === 'micro' ? '00 a 20' : 'cm'}
                                            </h2>
                                            <div className='div-table'>
                                                <table>
                                                    <thead>
                                                        <tr>
                                                            <th>Elemento</th>
                                                            <th>Mínimo</th>
                                                            <th>Média</th>
                                                            <th>Máximo</th>
                                                            {/* <th>Unidade</th> */}
                                                        </tr>
                                                    </thead>
                                                    <tbody>
                                                        {data.krigagem.map((rec, index) => {

                                                            if (index === 0) {
                                                                pagina = pagina + 1
                                                            }

                                                            const fertility = dataFertilidade[data.dataProfundidade[0]?.profundidade];
                                                            const elementData = groupBy(fertility, 'elemento_descricao');
                                                            const elemento = elementData[rec?.descriptionElemento]?.[0];
                                                            
                                                            const unidade = elementosData.find((rec) => rec.cod === (elemento?.cod_elemento))?.unidade;

                                                            const reverse = [7,18,25,26,28,30,31,32,41,42,43,44,45,46,49,50]?.indexOf(Number(elemento?.cod_elemento)) > -1;

                                                            pagina = pagina + 1;

                                                            const isVisivelNoBook: boolean = verificarVisibilidadeElemento({
                                                                config_elementos: configuracaos_do_book,
                                                                cod_elemento: Number(elemento?.cod_elemento),
                                                                tipo_secao_ou_profundidade: nome_profundidade_ou_secao,
                                                                tipo_mapa: typeKricagem,
                                                                nome_elemento: elemento?.elemento_descricao
                                                            });

                                                            if (!isVisivelNoBook) {
                                                                return null
                                                            }

                                                            let key_id_tabela: number = index;
                                                            return (
                                                                <TrReverse reverse={reverse} key={key_id_tabela}>
                                                                    <th>
                                                                        {rec.descriptionElemento}
                                                                        <small>{unidade !== null && unidade}</small>
                                                                    </th>
                                                                    <td>
                                                                        <div>
                                                                            {elementData[rec?.descriptionElemento]?.[0]?.minimo}{' '}
                                                                            <div
                                                                                style={{ backgroundColor: rec.colors[0] }}
                                                                                hidden={!typeKricagem}
                                                                                className='circle'
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {elementData[rec?.descriptionElemento]?.[0]?.media}{' '}
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: rec.colors[middleIndex(rec.colors)],
                                                                                }}
                                                                                hidden={!typeKricagem}
                                                                                className='circle'
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                    <td>
                                                                        <div>
                                                                            {elementData[rec?.descriptionElemento]?.[0]?.maximo}{' '}
                                                                            <div
                                                                                style={{
                                                                                    backgroundColor: rec.colors[rec.colors.length - 1],
                                                                                }}
                                                                                hidden={!typeKricagem}
                                                                                className='circle'
                                                                            />
                                                                        </div>
                                                                    </td>
                                                                </TrReverse>
                                                            )
                                                        })}
                                                    </tbody>
                                                </table>
                                            </div>
                                            <Page>{pageResumo}</Page>
                                        </ResumoNutrientes>
                                    )}
                                </>
                            )
                        })}
                    </PdfPrint>
                </>
            )}
        </Container>
    )
}

export default DrawerPrintMapaFertilidade