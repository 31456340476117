import styled, { css } from 'styled-components'

export const Container = styled.div<{ width: number; placement: 'right' | 'left'; open: boolean }>`
  max-width: ${(props) => (!props.open ? '100%' : '0')};
  overflow: hidden;
  padding: ${(props) => (!props.open ? '10px' : '0')};
  transition: all 0.5s ease-in-out;
  background-color: #fff;
  width: ${(p) => p.width}px;
  height: calc(100vh - 100px);
  position: absolute;
  margin: 10px;
  border-radius: 5px;
  z-index: 999999;

  ${(p) =>
    p.placement === 'right'
      ? css`
          right: 0;
        `
      : css`
          left: 0;
        `};

  header {
    border-bottom: 1px solid rgba(0, 0, 0, 0.3);
  }

  main {
    margin-top: 10px;
    height: calc(100% - 80px);
    overflow: hidden;
  }

  footer {
    display: flex;
    flex: 1;
  }
`
