
type TypeNomeProfundadeOuSecao = {
    index: number, 
    tamanho_array: number, 
    tipo_de_mapa: boolean, 
    profundidade: string,
}

export function ObterNomeProfundidade_ou_Secao(params: TypeNomeProfundadeOuSecao): string {

    const { index, tamanho_array, profundidade } = params;

    const isPenultimoIndex: boolean = index === (tamanho_array - 2);
    const isUltimoIndex: boolean    = index === (tamanho_array - 1);

    if (isPenultimoIndex) {
        return 'indicadores'
    }
    if (isUltimoIndex) {
        return 'prescricoes'
    }

    return profundidade;
}


export function obterNomeProfundidadeProps(params: TypeNomeProfundadeOuSecao): string {

    const { index, tamanho_array, profundidade } = params;

    const isPenultimoIndex: boolean = index === (tamanho_array - 2);
    const isUltimoIndex: boolean    = index === (tamanho_array - 1);

    if (isPenultimoIndex) {
        return 'indicadores';
    }
    if (isUltimoIndex) {
        return 'prescricoes';
    }

    return profundidade;
}

/**retorna o nome da profundidade (`profundidade`,`indicadores`,`prescrições`) ou seção junto com o tipo de mapa (`interpretado`,`distribuído`)*/
export function obterLIPrincipal(nome_profundidade_ou_secao: string, profundidade_sem_hifen: string, typeKricagem: boolean): string {
    if (nome_profundidade_ou_secao==='indicadores') {
        return 'Indicadores'
    }
    if (nome_profundidade_ou_secao==='prescricoes') {
        return 'Prescrições'
    }

    let tipo_de_mapa:'interpretado'|'distribuído' = typeKricagem ? 'interpretado' : 'distribuído';

    return `Pontos coletados ${profundidade_sem_hifen === '00 a 50' ? 'Granulometria' : profundidade_sem_hifen} ${tipo_de_mapa}: `
}