import styled from 'styled-components'

export const Container = styled.div`
  .back-to-top-button {
    position: fixed;
    bottom: 50px;
    right: 5px;
    padding: 10px 15px;
    background-color: #007bff;
    color: #fff;
    border: none;
    border-radius: 4px;
    cursor: pointer;
    z-index: 99999;
  }

  .back-to-top-button:hover {
    background-color: #0056b3;
  }
`
