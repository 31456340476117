import React, { useEffect, useState } from 'react'

import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'
import InputMask from 'react-input-mask'

import { getFornecedor, postFornecedor, putFornecedor } from '../../services/Fornecedores'
import { InputChangeEventHandler } from '../../types'

import { buscaCidade, listUf } from '../../services/Afins'

import dayjs, { Dayjs } from 'dayjs'
import DatePicker from '../DatePiker'

import { getGrupoServico } from '../../services/GrupoServicos'
import {
  delLabAnalise,
  getLabAnalise,
  postLabAnalise,
  putLabAnalise,
} from '../../services/LabAnalise'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import { IconAlert } from '../iconsAlert'
import { Notification } from '../notification'
import { Container } from './styled'
import { postLaboratorio, putLaboratorio } from '../../services/Laboratorio'
import DiasDesdeData from '../DiasDesdeData'

const { Option } = Select

const { TabPane } = Tabs

interface TypesLaboratorio {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setBtnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
  acessoPermissoes: ItensPerUserLogged
  btnClick: Boolean
  // nomeLaboratorio: string
}

const Laboratorio: React.FC<TypesLaboratorio> = (props) => {
  const {
    update,
    visible,
    setOnClose,
    setBtnClose,
    setUpdate,
    atualizar,
    permissions,
    acessoPermissoes,
    btnClick,
  } = props

  const [dataCity, setDataCity] = useState([])
  const [dataUf, setDataUf] = useState([])
  const [inputDisableCidade, setInputDisableCidade] = useState(true)
  const [valueInputCity, setValueInputCity] = useState(undefined)
  const [tabsSelect, setTabSelect] = useState('1')
  const [cardService, setCardService] = useState('1')
  const [nomeLaboratorio, setNomeLaboratorio] = useState('')
  const [updateService, setUpdateService] = useState(0)
  const [rowSelected, setRowSelected] = useState<number>()
  const [toogleTipo, setToogleTipo] = useState({
    mask: '',
    label: 'CPF/CNPJ',
    fisico: true,
    juridico: true,
    labelF: 'Nome',
    labelJ: 'Nome Fantasia',
  })

  const [dataService, setDataService] = useState([])
  const [dataFornecedorServi, setDataFornecedorServi] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)

  const [upAnalise, setUpAnalise] = useState(false)
  const [dataLaboratorio, setDataLaboratorio] = useState<any>([])

  const [form] = Form.useForm()

  const [radioTipo, setRadioTipo] = useState('')
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)
  const [reset, setReset] = useState(false)
  const [dataUpdate, setDataUpdate] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const hancleDrawerClose = () => {
    setOnClose(false)
    setBtnClose(false)
    setUpdate(0)
    setTabSelect('1')
    setBtnClose(false)
    setUpdateService(0)
    setRadioTipo('')
    setUpAnalise(false)
  }

  const formatarData = (data) => {
    // Supondo que a propriedade que você deseja formatar é $d
    const date = data.data

    // Formatar a data usando dayjs
    const dataFormatada = dayjs(date).format('DD/MM/YYYY')

    // Retornar o objeto com a data formatada
    return {
      ...data,
      data: dataFormatada,
    }
  }

  const onFinish = async (data) => {
    const dados = formatarData(data)

    try {
      if (tabsSelect === '1') {
        if (update === 0) {
          message.loading('Aguarde...')
          const response = await postLaboratorio(dados)

          if (response.status === 201) {
            message.destroy()
            Notification({
              message: 'Laboratório cadastrado com Sucesso',
              type: 'success',
            })
            setReset(true)
            atualizar()
          }
        } else {
          message.loading('Aguarde...')
          const response = await putLaboratorio(update, dados)

          if (response.status === 200) {
            message.destroy()
            Notification({
              message: 'Laboratório atualizado com Sucesso',
              type: 'success',
            })
          }
          atualizar()
          setUpAnalise(false)
        }
      } else if (tabsSelect === '2') {
        if (updateService === 0) {
          message.loading('Aguarde...')
          const response = await postLabAnalise(dados)

          if (response.status === 201) {
            message.destroy()
            Notification({
              message: 'Servico Adicionado',
              type: 'success',
            })
            setReset(true)
            handleChangeCliente(update)
          }
        } else {
          message.loading('Aguarde...')
          const response = await putLabAnalise(updateService, dados)

          if (response.status === 200) {
            message.destroy()
            Notification({
              message: 'Atualizado com Sucesso',
              type: 'success',
            })
            handleChangeCliente(update)
            setReset(true)
            setUpdateService(0)
            setRowSelected(null)
          }
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response

        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleChangeCliente = async (data) => {
    message.loading('Aguarde...')
    const response = await getFornecedor(2, data)
    const dados = response.data.itens[0]

    const nomePro = response.data.itens[0].nome
    setDataLaboratorio(dados)
    setNomeLaboratorio(nomePro)

    setLoadingTable(true)
    const respServico = await getLabAnalise('', update, '', '')
    setDataFornecedorServi(respServico.data.itens)
    setLoadingTable(false)

    form.setFieldsValue({ cod_laboratorio: update })
    handleChangeTipo(dados.tipo)

    form.setFieldsValue(dados)
    haldleChangeCidadeUf(dados?.cidade[0]?.cod_uf)

    form.setFieldsValue({ cidade_cod_uf: dados?.cidade[0]?.cod_uf })
    message.destroy()
  }

  const handleChangeTipo = (value) => {
    setRadioTipo(value)
  }

  const haldleChangeCidadeUf = async (cod) => {
    if (cod) {
      const resCity = await buscaCidade('', cod, '')
      setDataCity(resCity)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  const handleChangeCity = (value) => {
    setValueInputCity({ value })
  }

  const listServico = async () => {
    const response = await getGrupoServico('', '', '')
    setDataService(response.data.itens)
  }

  const handleChangeMask = (data) => {
    let valor = data.target.value
    valor = valor.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    valor = (valor / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ valor })
  }

  const handelClickSubmit = () => {
    form.submit()
  }

  const handelChangeTabs = (key) => {
    setTabSelect(key)
  }

  const colums = [
    {
      key: 1,
      title: 'Data',
      dataIndex: 'data',
      render: (data) => {
        const date = data.split('-').reverse().join('/')
        return <>{date}</>
      },
    },
    {
      key: 2,
      title: 'Serviço',
      dataIndex: 'analise_nome',
      render: (data, dados) => (
        <>
          {dados.profundidade_nome} | {data}
        </>
      ),
    },
    {
      key: 3,
      title: 'Valor',
      dataIndex: 'valor',
    },
    {
      title: 'Ações',
      dataIndex: 'cod',
      key: 'acao',
      render: (data, dados) => {
        setCardService(dados.fornecedor_nome)
        return (
          <>
            {/* <Button
              type="primary"
              shape="circle"
              className="info-button"
              onClick={() => handleClickFornServ(data)}
              icon={<FontA icon={solid("pen")} />}
              size="middle"
            /> */}
            {'   '}
            <Button
              disabled={permissions?.exclusao === 0}
              shape='circle'
              onClick={() => handleTrashFornServ(data)}
              className='trash-button'
              type='primary'
              icon={<FontA icon={solid('trash')} />}
              size='middle'
            />
          </>
        )
      },
    },
  ]

  const handleClickFornServ = async (data) => {
    setUpdateService(data)
    setUpAnalise(true)
    const response = await getLabAnalise(data, update, '', '')

    form.setFieldsValue({
      cod_analise: response?.data?.itens[0]?.cod_analise,
      data: dayjs(response?.data?.itens[0]?.data),
      valor: response?.data?.itens[0]?.valor.replace('.', ','),
    })
  }

  const handleTrashFornServ = async (data) => {
    try {
      Modal.confirm({
        title: 'Excluir',
        content: 'Deseja realmente remover a análise?',
        okText: 'Confirmar',
        icon: <IconAlert type='confirm' size={6} />,
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delLabAnalise(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Serviço Excluido',
              type: 'success',
            })

            handleChangeCliente(update)
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  useEffect(() => {
    if (update > 0) {
      handleChangeCliente(update)
    } else {
      setReset(true)
    }
  }, [update])

  useEffect(() => {
    if (reset) {
      form.resetFields()
      setRadioTipo('')
      setReset(false)
    }
  }, [reset, form])

  useEffect(() => {
    if (radioTipo === 'J') {
      setToogleTipo({
        mask: '99.999.999/9999-99',
        label: 'CNPJ',
        fisico: false,
        juridico: true,
        labelF: '',
        labelJ: 'Nome Fantasia',
      })
    } else if (radioTipo === 'F') {
      setToogleTipo({
        mask: '999.999.999-99',
        label: 'CPF',
        fisico: true,
        juridico: false,
        labelF: 'Nome',
        labelJ: '',
      })
    }
  }, [radioTipo])

  useEffect(() => {
    async function fetch() {
      const resUF = await listUf()
      setDataUf(resUF)
    }
    listServico()
    fetch()
  }, [])

  const optionsCity = dataCity.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))
  const optionsUF = dataUf.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.sigla}
    </Option>
  ))

  const optionsServicos = dataService.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.profundidade_nome} | {e.nome}
    </Option>
  ))

  useEffect(() => {
    if (visible) {
      if (dataLaboratorio) {
        if (dataLaboratorio.create_user) {
          setUsuarioQueCriou(dataLaboratorio.create_user)
          const dateOnlyString = dataLaboratorio.create_time.split(' ')[0]
          const timeOnlyString = dataLaboratorio.create_time.split(' ')[1]
          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
        if (dataLaboratorio.update_user) {
          setUsuarioQueAlterou(dataLaboratorio.update_user)
          const dateOnlyString = dataLaboratorio.update_time.split(' ')[0]
          const timeOnlyString = dataLaboratorio.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [
    logUser,
    dataLaboratorio,
    visible,
    usuarioQueAlterou,
    usuarioQueCriou,
    update,
    horarioDeCriacao,
  ])

  return (
    <Form.Provider
      onFormFinish={() => {
        //
      }}
    >
      <Drawer
        style={{ position: 'absolute' }}
        placement='right'
        width='100%'
        onClose={hancleDrawerClose}
        open={visible}
        closeIcon={false}
        getContainer={false}
        footer={
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              {tabsSelect === '2' ? (
                <Button
                  // hidden={btnClick === false}
                  onClick={handelClickSubmit}
                  type='primary'
                  htmlType='submit'
                >
                  {tabsSelect === '2' && upAnalise === true ? 'Atualizar Análise': 'Salvar Análise'}
                </Button>
              ) : (
                <Button
                  // hidden={btnClick === false}
                  onClick={handelClickSubmit}
                  type='primary'
                  htmlType='submit'
                >
                  {btnClick === true ? 'Salvar' : 'Atualizar Laboratório'}
                </Button>
              )}

              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>

            <Space className='log-user'>
              <small style={{ opacity: '0.5' }}>
                {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                  <>
                    <DiasDesdeData
                      dataCriacao={dataAlteracao}
                      onDataFromCreation={handleDataFromCreation}
                    />
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      atualizado por: {usuarioQueAlterou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeAtualizacao}h`}
                    </small>
                  </>
                ) : !logUser && update > 0 ? (
                  <>
                    {' '}
                    {usuarioQueCriou === '' ? null : (
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        criado por: {usuarioQueCriou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeCriacao}h`}
                      </small>
                    )}
                  </>
                ) : null}
              </small>
            </Space>
          </div>
        }
      >
        <Tabs
          className='ant-tab-green'
          activeKey={tabsSelect}
          onChange={handelChangeTabs}
          size='large'
          type='card'
        >
          <TabPane key={1} tab={update > 0 ? 'Atualizar Laboratório' : 'Cadastrar Laboratório'}>
            {tabsSelect === '1' ? (
              <Form name='forncedor' form={form} layout='vertical' onFinish={onFinish}>
                <Row
                  style={{
                    pointerEvents: acessoPermissoes?.acesso === 1 ? 'none' : 'auto',
                  }}
                  gutter={[8, 0]}
                >
                  <Col span={23} flex='1 1 100vw'>
                    <Card size='small' title='Tipo de Laboratório'>
                      <Form.Item
                        rules={[
                          {
                            required: true,
                            message: 'Selecione o Tipo de Cadastro',
                          },
                        ]}
                        required
                        name='tipo'
                      >
                        <Radio.Group
                          buttonStyle='outline'
                          onChange={(e) => handleChangeTipo(e.target.value)}
                          value={radioTipo}
                        >
                          <Radio disabled={update > 0 && radioTipo === 'J'} name='tipo' value='F'>
                            Pessoa Fisica
                          </Radio>

                          <Radio disabled={update > 0 && radioTipo === 'F'} name='tipo' value='J'>
                            Pessoa Jurídica
                          </Radio>
                        </Radio.Group>
                      </Form.Item>
                    </Card>
                  </Col>
                  <Col hidden={!!(radioTipo === 'G' || radioTipo === '')} span={23}>
                    <Row gutter={[8, 0]}>
                      <Col hidden={!!(radioTipo === 'F')} flex='1 1 300px'>
                        <Form.Item
                          rules={[
                            {
                              required: radioTipo === 'J',
                            },
                          ]}
                          label='Razão Social'
                          name='razao_social'
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          name='nome'
                          label={radioTipo === 'J' ? toogleTipo.labelJ : toogleTipo.labelF}
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col hidden={!!(radioTipo === 'G')} flex='1 1 300px'>
                        <Form.Item label={toogleTipo.label} name='cpf_cnpj'>
                          <InputMask mask={toogleTipo.mask}>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>
                      <Col hidden={!!(radioTipo === 'G' || radioTipo === 'F')} flex='1 1 300px'>
                        <Form.Item
                          rules={[
                            {
                              required: radioTipo === 'J',
                            },
                          ]}
                          label='IE'
                          name='inscricao_estadual'
                        >
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col flex='1 1 300px'>
                        <Form.Item label='Celular' name='fone'>
                          <InputMask mask='(99)99999-9999'>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item label='Fone Fixo' name='fone2'>
                          <InputMask mask='(99)9999-9999'>
                            {/* @ts-ignore */}
                            {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                          </InputMask>
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          name='email'
                          label='Email'
                        >
                          <Input type='email' />
                        </Form.Item>
                      </Col>
                    </Row>
                    <Row gutter={[8, 0]}>
                      <Col style={{ width: '80px' }}>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          label='UF'
                          name='cidade_cod_uf'
                        >
                          <Select
                            showSearch
                            onChange={haldleChangeCidadeUf}
                            filterOption={(input, option) =>
                              option.children
                                // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {optionsUF}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 200px'>
                        <Form.Item
                          rules={[
                            {
                              required: true,
                            },
                          ]}
                          label='Cidade'
                          name='cod_cidade'
                        >
                          <Select
                            disabled={inputDisableCidade}
                            onChange={handleChangeCity}
                            value={valueInputCity}
                            showSearch
                            filterOption={(input, option) =>
                              option.children
                                // @ts-ignore
                                .toLowerCase()
                                .indexOf(input.toLowerCase()) >= 0
                            }
                          >
                            {optionsCity}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item name='cep' label='Cep'>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item name='endereco' label='Endereço'>
                          <Input />
                        </Form.Item>
                      </Col>

                      <Col flex='1 1 300px'>
                        <Form.Item name='endereco_numero' label='Número'>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item name='bairro' label='Bairro'>
                          <Input />
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 300px'>
                        <Form.Item name='complemento' label='Complemento'>
                          <Input />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>

                {/* Hiddenn */}

                <Form.Item initialValue='1' hidden name='status' />
                <Form.Item initialValue={2} hidden name='cod_categoria' />
              </Form>
            ) : null}
          </TabPane>

          <TabPane disabled={update <= 0} key={2} tab='Análises'>
            {tabsSelect === '2' ? (
              <Form name='services' form={form} layout='vertical' onFinish={onFinish}>
                <Card size='small' title={`Laboratório: ${nomeLaboratorio}`}>
                  <Col span={22}>
                    <Row gutter={[8, 0]}>
                      <Col flex='1 1 100px'>
                        <Form.Item hidden name='cod_laboratorio'>
                          <Input />
                        </Form.Item>
                        <Form.Item hidden initialValue='1' name='status'>
                          <Input />
                        </Form.Item>
                        <Form.Item label='Data Vigência' name='data'>
                          <DatePicker style={{ width: '100%' }} placeholder='Selecione uma data' />
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 200px'>
                        <Form.Item label='Tipo Análise' name='cod_analise'>
                          <Select
                            placeholder='Selecione a Análise'
                            style={{ width: '100%' }}
                            disabled={permissions?.acesso === 1}
                          >
                            {optionsServicos}
                          </Select>
                        </Form.Item>
                      </Col>
                      <Col flex='1 1 100px'>
                        <Form.Item label='Valor' name='valor'>
                          <Input onChange={handleChangeMask} disabled={permissions?.acesso === 1} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Card>

                <Col span={24}>
                  <Container
                  // style={{
                  //   pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                  // }}
                  >
                    <Table
                      rowKey='cod'
                      loading={loadingTable}
                      size='small'
                      bordered
                      columns={colums}
                      dataSource={dataFornecedorServi}
                      onRow={(record, i) => {
                        return {
                          onDoubleClick: () => {
                            setRowSelected(i)
                            handleClickFornServ(record.cod)
                          },
                          style: {
                            backgroundColor: rowSelected === i ? '#d2f8c8' : '',
                          },
                        }
                      }}
                    />
                  </Container>
                </Col>
              </Form>
            ) : null}
          </TabPane>
        </Tabs>
      </Drawer>
    </Form.Provider>
  )
}

export default Laboratorio
