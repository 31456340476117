import { removerAcentos } from "../../../../../../utils";


export function saudacao(): 'Bom dia' | 'Boa tarde' | 'Boa noite' {
    let current_time = (new Date())?.getHours();

    if (current_time < 12) {
        return 'Bom dia';
    }
    else if (current_time >= 12 && current_time <= 17) {
        return 'Boa tarde'
    }
    
    return `Boa noite`;
}


export function saveHistory(item) {
    let history = {
        user: 0,
        cod: item.cod,
        nome: item.nome,
        acesso: item.acesso,
        logo: item.logo,
        last_login: new Date()
    };

    localStorage.setItem('last_access', JSON.stringify(history));

    let recents = JSON.parse(localStorage.getItem('access_history')) || [];
    let finder = recents?.find(rece=> rece.cod === item.cod);

    if ((recents??[]).length >= 3) {
        recents.shift()
    }

    if (finder!=undefined) {
        let remove = recents.filter(fil=> fil.cod !== item.cod);
        let add_new = [...remove, history];

        localStorage.setItem('access_history', JSON.stringify(add_new));
        
        return null;
    }
    

    let add_new = [...recents, history];
    
    localStorage.setItem('access_history', JSON.stringify(add_new));

}


export function findClient(buscar_nome: string) {
    const ativoSemAcentos = removerAcentos(buscar_nome)
    return function (item) {
        const nomeSemAcentos = removerAcentos(String(item?.['nome']))
        return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome
    }
}


export function firstAndLastName(full_name: string) {
    let split_name = full_name.split(' ');
    
    return `${split_name?.[0]} ${split_name?.[split_name?.length - 1]}`
}