
import { Spin } from 'antd';
import { LoadingContainer } from './styled';

//PROPS
interface Props {
    justifyContent?: 'flex-start' | 'center' | 'flex-end';
    legenda?: string;
    height?: number | string;
    animation?: boolean;
    size?: 'small' | 'default' | 'large';
    direction?: 'column' | 'row';
    isWhite?: boolean;
}


const Carregando: React.FC<Props> = ({ justifyContent, height, animation, legenda, size, direction, isWhite }) => {
    return (
        <LoadingContainer style={{justifyContent: justifyContent, height: height}} data-isWhite={isWhite}>
            <div className='LoadingContainer__conteudo' style={{flexDirection: direction}}>
                <Spin size={size ?? 'default'} />

                <span className='LoadingContainer__conteudo__legenda' style={{animation: !animation && 'none'}}>
                    {legenda}
                </span>
            </div>
        </LoadingContainer>
    )
}

export default Carregando;