import React, { useCallback, useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'

import { Button, Form, Select, Space, Table, Tag } from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import { groupBy } from 'lodash'
import DatePicker from '../../../../Components/DatePiker'
import { pagination } from '../../../../Components/Default'
import { useLogic } from '../../../../context/useLogic'
import { filterTable, tiposGerais, tryError } from '../../../../services/Afins'
import { EquipeItens, getEquipe } from '../../../../services/Equipe'
import { PropriedadeItens, getPropriedadeLiberada } from '../../../../services/Propriedades'
import { TalhaoSafraGeralItens, getTalhaoSafraGeral } from '../../../../services/TalhaoSafraGeral'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import MapaPropriedade from './mapa'
import { Container } from './styles'

interface PropsForm {
  cod_cultura: number
  cod_consultor_at: number
  data_vigente: Dayjs
}

export interface PropsDataTalhoesAll {
  cod: number
  propriedade: string
  cliente: string
  area: string
  qtaTalhoes: number
  talhoes: google.maps.LatLngLiteral[][]
}

const calcPorcentagem = (qtda, total) => {
  return parseFloat(((qtda * 100) / total).toFixed(0))
}

const PageMapaPropriedade: React.FC = () => {
  const [data, setData] = useState<{
    equipes: EquipeItens[]
    culturas: any[]
  }>({
    equipes: [],
    culturas: [],
  })

  const [percent, setPercent] = useState(0)
  const [openMap, setOpenMap] = useState(false)
  const [loading, setLoading] = useState(false)
  const [statusBtnConsultar, setStatusBtnConsultar] = useState(true)

  const [dataTalhao, setDataTalhao] = useState<{ ['key']: TalhaoSafraGeralItens[] }>(
    {} as { ['key']: TalhaoSafraGeralItens[] }
  )

  const [viewTalhao, setViewTalhao] = useState<PropsDataTalhoesAll[]>([])

  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens[]>([])

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const { responsive } = useLogic()

  const [form] = Form.useForm<PropsForm>()

  const listData = useCallback(async () => {
    const [equipes, culturas] = await Promise.all([
      getEquipe({ embed: 'tipo_assistente_tecnico,tipo_consultor,tipo_lider_equipe' }),
      tiposGerais(),
    ])

    setData({ equipes: equipes.data.itens, culturas })
  }, [data])

  useEffect(() => {
    listData()
  }, [])

  const handleChangeConsultor = async (value: number, option: {}) => {
    setViewTalhao([])
  }

  const onFinish = async (values: PropsForm) => {
    try {
      setLoading(true)
      // const pagination = 100
      // let total = 0
      // let totalPage = 1
      // let page = 1
      // let dados: TalhaoSafraGeralItens[] = []

      // for (let i = 0; i < totalPage; i += 1) {
      //   const resp = await getTalhaoSafraGeral({
      //     codUsuarioPropriedade: values.cod_consultor_at,
      //     dataVigenciaSafra: !dayjs.isDayjs(values.data_vigente)
      //       ? null
      //       : values.data_vigente.format('L'),
      //     codCultura: values.cod_cultura,
      //     embed: 'safra',
      //     pagination,
      //     page,
      //   })

      //   dados = [...dados, ...resp.data.itens]

      //   if (i === 0) {
      //     total = resp.data.total
      //     totalPage = Math.ceil(total / pagination)
      //   }
      //   if (page >= 1) {
      //     setPercent(calcPorcentagem(page, totalPage))
      //   }
      //   page += 1
      // }

      const [dados, resp] = await Promise.all([
        getTalhaoSafraGeral({
          codUsuarioPropriedade: values.cod_consultor_at,
          dataVigenciaSafra: !dayjs.isDayjs(values.data_vigente)
            ? null
            : values.data_vigente.format('L'),
          codCultura: values.cod_cultura,
          embed: 'safra',
          pagination: -1,
        }),
        getPropriedadeLiberada(values.cod_consultor_at),
      ])

      setDataPropriedade(resp.data.itens)

      setDataTalhao(
        groupBy(dados.data.itens, 'cod_propriedade') as { ['key']: TalhaoSafraGeralItens[] }
      )
      setLoading(false)
    } catch (error) {
      tryError(error)
      setLoading(false)
    }
  }

  const handleToggleMap = () => {
    setOpenMap(!openMap)
  }

  const handleChangeFieldsForm = () => {
    setViewTalhao([])
    const dados: PropsForm = form.getFieldsValue()
    if (dados.cod_consultor_at && dados.cod_cultura && dados.data_vigente) {
      setStatusBtnConsultar(false)
    } else {
      setStatusBtnConsultar(true)
    }
  }

  useEffect(() => {
    try {
      setViewTalhao(
        Object.entries(dataTalhao).map(([key, value]) => {
          return {
            cod: dataPropriedade.find((rec) => rec.cod === Number(key)).cod,
            propriedade: dataPropriedade.find((rec) => rec.cod === Number(key)).nome,
            cliente: dataPropriedade.find((rec) => rec.cod === Number(key)).cliente_nome,
            area: value.reduce((a, b) => a + Number(b.area), 0).toFixed(2),
            qtaTalhoes: value.length,
            talhoes: value.map((rec) =>
              rec.kml.coordenadas.map((item) => {
                return { lat: item.latitude, lng: item.longitude }
              })
            ),
          }
        })
      )
    } catch (error) {
      tryError(error)
    }
  }, [dataTalhao])

  return (
    <Container>
      <ViewPage
        newHide
        rotina={81}
        onPermission={setPermissions}
        title='Mapa de área com acesso'
        btnClick={() => {}}
        btnsExta={
          <Form form={form} onFinish={onFinish} onFieldsChange={handleChangeFieldsForm}>
            <Space>
              <Form.Item name='cod_consultor_at' noStyle>
                <Select
                  popupMatchSelectWidth={false}
                  style={{ minWidth: '200px' }}
                  placeholder='Consultor / At / Líder'
                  optionFilterProp='children'
                  showSearch
                  onChange={handleChangeConsultor}
                >
                  <Select.OptGroup label='Consultor'>
                    {data.equipes
                      .filter((item) => item.consultor === true)
                      .map((rec) => (
                        <Select.Option key={String(rec.cod)} tipo={1} value={rec.cod_func}>
                          {rec.usuario_nome}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                  <Select.OptGroup label='Assistente técnico'>
                    {data.equipes
                      .filter((item) => item.assistente_tecnico === true)
                      .map((rec) => (
                        <Select.Option tipo={3} value={rec.cod_func}>
                          {rec.usuario_nome}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                  <Select.OptGroup label='Líder de equipe'>
                    {data.equipes
                      .filter((item) => item.lider_equipe === true)
                      .map((rec) => (
                        <Select.Option tipo={3} value={rec.cod_func}>
                          {rec.usuario_nome}
                        </Select.Option>
                      ))}
                  </Select.OptGroup>
                </Select>
              </Form.Item>
              <Form.Item name='cod_cultura' noStyle>
                <Select
                  style={{ minWidth: '175px' }}
                  placeholder='Cultura'
                  optionFilterProp='label'
                  showSearch
                  popupMatchSelectWidth={false}
                  options={data.culturas.map((rec) => {
                    return { label: rec.nome, value: rec.cod }
                  })}
                />
              </Form.Item>
              <Form.Item style={{ minWidth: '150px' }} name='data_vigente' noStyle>
                <DatePicker style={{ minWidth: '150px' }} placeholder='Data vigente' />
              </Form.Item>
              <Button
                loading={loading}
                disabled={statusBtnConsultar}
                htmlType='submit'
                type='primary'
              >
                Consultar
              </Button>
              <Button
                onClick={handleToggleMap}
                disabled={viewTalhao.length <= 0}
                htmlType='button'
                type='primary'
              >
                Visualizar mapa
              </Button>
            </Space>
          </Form>
        }
        content={
          <MapaPropriedade
            dataTalhoes={openMap ? viewTalhao : []}
            open={openMap}
            onClose={setOpenMap}
          />
        }
      >
        <Table
          size='small'
          loading={loading}
          scroll={{ y: 'calc(100vh - 400px)' }}
          pagination={pagination}
          columns={[
            {
              title: 'Propriedade',
              dataIndex: 'propriedade',
              ...filterTable(true, true, 'propriedade', viewTalhao, false),
            },
            {
              title: 'Cliente',
              dataIndex: 'cliente',
              ...filterTable(true, true, 'cliente', viewTalhao),
            },
            {
              title: 'Qtde de Talhões',
              dataIndex: 'qtaTalhoes',
              render: (data) => <Tag>{data}</Tag>,
            },
            {
              title: 'Área/ha',
              dataIndex: 'area',
            },
          ]}
          dataSource={viewTalhao}
          summary={(data) => {
            return (
              <Table.Summary fixed>
                <Table.Summary.Row>
                  <Table.Summary.Cell index={0}>
                    <b>Totais</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={1}></Table.Summary.Cell>
                  <Table.Summary.Cell index={2}>
                    <b>{data.reduce((a, b) => a + b.qtaTalhoes, 0).toFixed(0)}</b>
                  </Table.Summary.Cell>
                  <Table.Summary.Cell index={3}>
                    <b>{data.reduce((a, b) => a + Number(b.area), 0).toLocaleString('pt-BR')}</b>
                  </Table.Summary.Cell>
                </Table.Summary.Row>
              </Table.Summary>
            )
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default PageMapaPropriedade
