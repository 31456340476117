import React, { useEffect, useState } from 'react'

import { Form, Input, Drawer, message, Button, Col, Row, Select } from 'antd'

import {
  postCategoriaMarca,
  putCategoriaMarca,
  getCategoriaMarca,
} from '../../services/config/marca'

import { getCatPatrimonio } from '../../services/config/categoriasPatrimonio'

import { Notification } from '../notification'

import { Container } from './styled'
import { tiposGerais } from '../../services/Afins'

interface TypesFormMarca {
  onShow: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (data: number) => void
  refresh: (data: string) => void
}

const { Option } = Select

const Marca: React.FC<TypesFormMarca> = (props) => {
  const { update, setUpdate, onShow, onClose, refresh } = props

  const [form] = Form.useForm()

  const [drawerVisible, setDrawerVisible] = useState(false)

  const [dataCategorias, setDataCategorias] = useState([])

  const listCategorias = async () => {
    const resp = await tiposGerais()

    setDataCategorias(resp)
  }

  const editMarca = async (data) => {
    message.loading('Carregando...')
    const response = await getCategoriaMarca(data, '', '', 'nome')
    const dados = response.data.itens[0]

    let categorias = []

    dados.categorias.forEach((info) => {
      categorias = [...categorias, info.cod_categoria]

      return categorias
    })

    form.setFieldsValue({
      nome: dados.nome,
      categorias,
    })
    message.destroy()
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...')
      if (update <= 0) {
        const response = await postCategoriaMarca(data)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Marca Cadastrada com Sucesso',
            type: 'success',
          })
          form.resetFields()
          refresh('')
        }
      } else {
        const response = await putCategoriaMarca(update, data)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Marca Atualizada com Sucesso',
            type: 'success',
          })
          refresh('')
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setDrawerVisible(onShow)
  }, [onShow])

  useEffect(() => {
    if (update !== 0) {
      editMarca(update)
    }
  }, [update])

  useEffect(() => {
    listCategorias()
  }, [])

  return (
    <Container>
      <Form layout='vertical' form={form} onFinish={onFinish}>
        <Drawer
          title={update <= 0 ? 'Cadastro de Marca' : 'Atualizar Marca'}
          style={{ position: 'absolute' }}
          placement='right'
          width='100%'
          open={drawerVisible}
          closeIcon={false}
          onClose={hancleDrawerClose}
          getContainer={false}
          footer={
            <div
              style={{
                textAlign: 'left',
              }}
            >
              <Button type='primary' htmlType='submit'>
                {update ? 'Atualizar Marca' : 'Salvar'}
              </Button>
              {'   '}
              <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
                Fechar
              </Button>
            </div>
          }
        >
          <Col span={22}>
            <Row gutter={[8, 8]}>
              <Col>
                <Form.Item name='nome' label='Nome Marca'>
                  <Input />
                </Form.Item>
              </Col>
              <Col flex='0 1 300px'>
                <Form.Item name='categorias' label='Categoria'>
                  <Select
                    style={{ minWidth: '100%' }}
                    mode='tags'
                    showSearch
                    placeholder='Selecione uma Categoria'
                  >
                    {dataCategorias.map((data) => (
                      <Option key={data.cod} value={data.cod}>
                        {data.nome.slice(0, 1) + data.nome.slice(1).toLowerCase()}
                      </Option>
                    ))}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Drawer>
      </Form>
    </Container>
  )
}

export default Marca
