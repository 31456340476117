import React from 'react'

import { Container } from './styled'

import logoTtivos from '../../assets/img/logo-2.png'
import { useAccess } from '../../context/useAccess'

type Props = { logo?: boolean }

const RodapeTtivvos: React.FC<Props> = (props) => {
  const { consultoria } = useAccess()
  const { logo = true } = props
  return (
    <Container>
      <div className='gradiente' />
      <div className='logo'>
        {logo ? (
          <img width='95px' src={logoTtivos} alt='SysAgro' />
        ) : (
          <span>ttivos® gestão rural</span>
        )}
      </div>
      <small className='texto'>Licenciado para: {consultoria?.linha1}</small>
    </Container>
  )
}
export default RodapeTtivvos
