import { Button, Card, Modal, Row } from 'antd'
import React, { ReactNode, useEffect, useState } from 'react'

import { GoogleMap, Polygon } from '@react-google-maps/api'

import { CloseOutlined, MenuUnfoldOutlined } from '@ant-design/icons'
import { Container } from './styled'
import { TalhaoCultivaveisItens } from '../../services/TalhaoSafra'

import { calculoAreaCoord } from '../../services/Afins'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import DiasDesdeData from '../DiasDesdeData'

interface TypesMaps {
  visible: boolean
  onClose: (data: boolean) => void
  onSelect: (data: number) => void
  onFinish: (data: any) => void
  select: number
  nomeTalhao: string
  children: ReactNode
  polygon: TalhaoCultivaveisItens[]
  center: google.maps.LatLng | google.maps.LatLngLiteral
  // eslint-disable-next-line react/require-default-props
  zoom?: number
  update?: number
  permissions: ItensPerUserLogged
  dataAgrupamentoTalhao: any
}

const AgrupamentoTalhao: React.FC<TypesMaps> = (props) => {
  const {
    visible,
    onClose,
    onFinish,
    children,
    polygon,
    center = { lat: 0, lng: 0 },
    zoom = 2,
    onSelect,
    select,
    nomeTalhao,
    permissions,
    dataAgrupamentoTalhao,
    update
  } = props

  const [flutuantDiv, setFlutuantDiv] = useState(false)
  const [modalVisible, setModalVisible] = useState(false)

  const [actionsData, setActionsData] = useState({
    total: 0,
    selecionados: 0,
    area: 0,
  })

  /* console.log(polygon); */
  
  const [talhaSelect, setTalhaSelect] = useState(0)
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const handleClickHiddenFlutuant = (data) => {
    setFlutuantDiv(!data)
  }

  const handleCloseModal = () => {
    setModalVisible(false)
    onClose(false)
    setTalhaSelect(null)
  }

  const onClickPolygon = (index, scroll) => {
    onSelect(index)
    if (scroll) {
      const element = document.getElementById(`talhao_${index}`)
      element.scrollIntoView({ behavior: 'smooth', block: 'center' })
    }
  }

  useEffect(() => {
    setModalVisible(visible)
  }, [visible])

  useEffect(() => {
    let selec = 0
    let areaTotal = 0
    polygon.forEach((data) => {
      if (data.select) {
        selec += 1
        areaTotal += calculoAreaCoord([data.kml.coordenadas])
      }

      return { selec, areaTotal }
    })

    setActionsData({
      total: polygon.length,
      selecionados: selec,
      area: areaTotal,
    })
  }, [polygon])

  useEffect(() => {
    setTalhaSelect(select)
  }, [select])

  useEffect(() => {
    if (visible) {
      // const safraCheck = dataAgrupamentoTalhao.filter((item) => item.cod === update)
     

      if (dataAgrupamentoTalhao) {
        if (dataAgrupamentoTalhao.create_user && dataAgrupamentoTalhao.create_user !== undefined) {
          setUsuarioQueCriou(dataAgrupamentoTalhao.create_user)


          const dateOnlyString = dataAgrupamentoTalhao.create_time.split(' ')[0]
          const timeOnlyString = dataAgrupamentoTalhao.create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (dataAgrupamentoTalhao.update_user && dataAgrupamentoTalhao.update_user !== undefined) {
          setUsuarioQueAlterou(dataAgrupamentoTalhao.update_user)

          const dateOnlyString = dataAgrupamentoTalhao.update_time.split(' ')[0]
          const timeOnlyString = dataAgrupamentoTalhao.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [ logUser, visible, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao])


  return (
    <Modal
      closeIcon
      style={{ top: '10px', minWidth: '97%' }}
      open={modalVisible}
      okButtonProps={{ hidden: true }}
      cancelButtonProps={{ hidden: true }}
    >
      <Row>
        {/* @ts-ignore */}
        <GoogleMap
          options={{
            streetViewControl: false,
            mapTypeId: 'hybrid',
            mapTypeControl: false,
            fullscreenControl: false,
          }}
          zoom={zoom}
          mapContainerStyle={{
            width: '100%',
            height: '90vh',
          }}
          center={center}
        >
          {polygon.map((data, i) => {
            let cor
            if (talhaSelect !== i) {
              cor = {
                fillColor: data.select ? 'cyan' : 'white',
                strokeColor: data.select ? 'cyan' : 'white',
                fillOpacity: null,
              }
            } else {
              cor = {
                fillColor: 'orange',
                strokeColor: 'orange',
                fillOpacity: 1,
              }
            }
            return (
              <Polygon
                // @ts-ignore;
                paths={data.kml.coordenadas}
                onClick={() => onClickPolygon(i, true)}
                options={cor}
              />
            )
          })}
        </GoogleMap>
        <Container style={{ width: flutuantDiv ? 'auto' : '325px', overflowY: 'hidden' }}>
          <Card
            extra={
              <Button
                className='btn-hidden'
                onClick={() => handleClickHiddenFlutuant(flutuantDiv)}
                size='small'
                type='text'
                icon={flutuantDiv ? <MenuUnfoldOutlined /> : <CloseOutlined />}
              />
            }
            bodyStyle={{ display: flutuantDiv ? 'none' : '' }}
            size='small'
   
            title={
              <div style={{ display: 'flex', flexDirection: 'column' }}>
                <>{flutuantDiv ? '' : <>Agrupamento de talhões {nomeTalhao}</>}</>
                {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                  <>
                    <DiasDesdeData
                      dataCriacao={dataAlteracao}
                      onDataFromCreation={handleDataFromCreation}
                    />
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      atualizado por: {usuarioQueAlterou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeAtualizacao}h`}
                    </small>
                  </>
                ) : !logUser && update > 0 ? (
                  <>
                    {' '}
                    {usuarioQueCriou === '' ? null : (
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        criado por: {usuarioQueCriou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeCriacao}h`}
                      </small>
                    )}
                  </>
                ) : null}
              </div>
            }
            actions={
              !flutuantDiv
                ? [
                    <div title='Quantidade de talhões' className='card-action-talhao'>
                      <h5>Total</h5>
                      <h5>{actionsData.total}</h5>
                    </div>,
                    <div title='Quantidade de talhões ativos'>
                      <h5>Selecionados</h5>
                      <h5>{actionsData.selecionados}</h5>
                    </div>,
                    <div title='Área'>
                      <h5>Área</h5>
                      <h5>{actionsData.area.toFixed(2)}/ha</h5>
                    </div>,
                  ]
                : null
            }
          >
            <div
              style={{
                maxHeight: 'calc(100vh - 320px)',
                overflowY: 'hidden',
                overflowX: 'hidden',
              }}
            >
              {children}
            </div>
          </Card>
          <div
            hidden={flutuantDiv}
            style={{
              padding: '10px',
              width: '315px',
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <Button
              hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
              onClick={onFinish}
              type='primary'
            >
              Salvar
            </Button>
            <Button onClick={handleCloseModal} danger type='primary'>
              Cancelar
            </Button>
          </div>
        </Container>
      </Row>
    </Modal>
  )
}

export default AgrupamentoTalhao
