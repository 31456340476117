import { useState } from "react";
import { faEnvelopeCircleCheck, faFileCode, faInfoCircle, faLink, faPassport, faSliders, faUserShield } from "@fortawesome/free-solid-svg-icons";

//STYLE
import { ContainerDetalhes } from "./style";

//APP
import { ItemRequest } from "../../../../../services/logs";

//COMPONENTS
import Tabs from "../../../../../Components/Tabs";
import Input from "../../../../../Components/CustomUi/input";
import { Render } from "../../../../../Components/CustomUi/render";
// import { Warning } from "../../../../../Components/CustomUi/warning";
import { InfoRequest } from "./components/info";
import { BodyRequest } from "./components/body";
import { AuthRequest } from "./components/auth";
import { ParamsRequest } from "./components/params";
import { HeadersRequest } from "./components/headers";
import { ResponseRequest } from "./components/response";

//PROPS
interface Props {
    request_selected: ItemRequest;
}

export const DetalhesRequisicao: React.FC<Props> = ({ request_selected }) => {

    const { uri } = request_selected?.descricao ?? {};
    const endpoint = uri?.split('?')?.[0];
    // const statusCode = String(response?.httpCode);

    //STATES
    const [current_tab, setCurrent_tab] = useState('Info');

    return (
        <ContainerDetalhes>
            <div className="ContainerDetalhes__urlPreview">
                <Input 
                    label="Endpoint"
                    value={endpoint}
                    onChange={()=>{}}
                    placeholder="uri da requisição"
                    icon={faLink}
                    allowCopy
                    readOnly
                />
            </div>

            <div className="ContainerDetalhes__content">
                {/* <Warning type={(statusCode==='200'||statusCode==='201') ? "success" : 'error'}>
                    {statusCode}
               </Warning> */}

                <Tabs 
                    tabs={[
                        {descricao: 'Info', icone: faInfoCircle},
                        {descricao: 'Params', icone: faSliders},
                        {descricao: 'Headers', icone: faPassport},
                        {descricao: 'Body', icone: faFileCode},
                        {descricao: 'Auth', icone: faUserShield},
                        {descricao: 'Response', icone: faEnvelopeCircleCheck},
                    ]}
                    selecionado={current_tab}
                    onChange={setCurrent_tab}
                    trackWidth={126}
                />

                <Render condition={current_tab === 'Info'}>
                    <InfoRequest request_selected={request_selected} />
                </Render>
                
                <Render condition={current_tab === 'Params'}>
                    <ParamsRequest request_selected={request_selected} />
                </Render>

                <Render condition={current_tab === 'Headers'}>
                    <HeadersRequest request_selected={request_selected} />
                </Render>
                
                <Render condition={current_tab === 'Body'}>
                    <BodyRequest request_selected={request_selected} />
                </Render>

                <Render condition={current_tab === 'Auth'}>
                    <AuthRequest request_selected={request_selected} />
                </Render>

                <Render condition={current_tab === 'Response'}>
                    <ResponseRequest request_selected={request_selected} />
                </Render>

            </div>
            
        </ContainerDetalhes>
    )
}