
//PROPS
interface Props {
    type: 'row' | 'column',
    value: number;
}

export const Gap: React.FC<Props> = ({ type, value }) => {
    return (
        <div style={{
            width: type==='row' ? value : 1,
            height: type==='column' ? value : 1,
        }}/>
    )
}