import React, { useEffect, useRef, useState } from 'react'

import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'

import { faMapMarker } from '@fortawesome/free-solid-svg-icons'

import { DownloadOutlined, ExperimentOutlined } from '@ant-design/icons'

import {
  Button,
  Card,
  Checkbox,
  Col,
  Form,
  message,
  Modal,
  Popconfirm,
  Row,
  Select,
  Switch,
  Tag,
} from 'antd'
import { v4 as uuid } from 'uuid'
import { geraKmlTrakmaker, geralKmlLab } from '../gerarKml'

import { CardBtn } from '../ordemLaboratorio/styled'

import { IconAlert } from '../iconsAlert'
import { Container } from './styled'

export interface TypesGoogleMaps {
  geoLocation?: any
  status?: boolean
  talhaoSelecionado?: any
  pontosSelecionados?: any
  setPontos: () => void
  gerarAnalise?: (data: any[]) => void
  visible: boolean
  atualizoupontos: (data: any[]) => void
  areaTalhao?: number
  resetAnalize?: () => void
  grupoDeAnalises?: [
    {
      cod_laboratorio?: number
      cod_analise?: number
      cod_categoria_fornecedor?: number
      laboratorio_nome?: string
      analise_nome?: string
      cor?: string
      profundidade_nome?: string
      cod_profundidade?: number
      data?: string
      valor?: string
      analises?: {
        profundidade?: {
          nome?: string
          cod?: number
        }
        analises?: [
          {
            cod?: number
            nome?: string
          },
          {
            cod?: number
            nome?: string
          }
        ]
      }
    }
  ]
}

const { Option } = Select

const { confirm } = Modal

function ordernar(data) {
  data.sort((a, b) => {
    if (a.properties.NOME < b.properties.NOME) {
      return -1
    }
    return true
  })

  return data
}

const GoogleMaps: React.FC<TypesGoogleMaps> = (props) => {
  const [form] = Form.useForm()

  const mapRef = useRef(null)
  const url = useRef(null)

  const {
    geoLocation,
    talhaoSelecionado,
    gerarAnalise,
    grupoDeAnalises,
    setPontos,
    visible,
    atualizoupontos,
    areaTalhao,
    resetAnalize,
    status = true,
  } = props

  const [geoJson, setGeoJson] = useState<any>(geoLocation)

  const [getlat, setLat] = useState<number>()
  const [getlng, setLng] = useState<number>()

  const [listLinha, setListLinha] = useState([])
  const [getPontosSelecionados, setPontosSelecionados] = useState<any>([])

  const [corSelecionada, setCorSelecionada] = useState(null)

  const [analises, setAnalises] = useState([])

  const [micro, setMicro] = useState(false)
  const [geraMicro, setGeraMicro] = useState(false)

  const [addAnalise, setAddAnalise] = useState<any>([])
  const [getAutoAnalise, setAutoAnalise] = useState<any>()

  const [gridAmostral, setGridAmostral] = useState<number>()
  const [drag, setDrag] = useState<boolean>(false)
  const [drawing, setDrawing] = useState<boolean>(false)

  let poline = []

  function linha(lat, lgt) {
    const index = (talhaoSelecionado.coordenadas.length / 2).toFixed(0)

    const metade = talhaoSelecionado.coordenadas[index]

    let LatLng = { lat: '', lng: '' }

    if (talhaoSelecionado.lookAt.latitude === '') {
      LatLng = { lat: metade.latitude, lng: metade.longitude }
    } else {
      LatLng = {
        lat: talhaoSelecionado.lookAt.latitude,
        lng: talhaoSelecionado.lookAt.longitude,
      }
    }

    setLat(
      talhaoSelecionado.kml === null || talhaoSelecionado.kml === 0 ? lat : parseFloat(LatLng.lat)
    )
    setLng(
      talhaoSelecionado.kml === null || talhaoSelecionado.kml === 0 ? lgt : parseFloat(LatLng.lng)
    )
    if (talhaoSelecionado.kml === null) {
      poline = []

      return poline
    }
    talhaoSelecionado.coordenadas.forEach((data) => {
      poline = [...poline, { lat: parseFloat(data.latitude), lng: parseFloat(data.longitude) }]

      return poline
    })

    setListLinha(poline)
    return poline
  }

  const qtda = geoLocation.features.length

  const divisao = qtda / 2 + 4

  const centro = divisao.toFixed(0)

  const inicio = {
    lat: getlat,
    lng: getlng,
  }

  const onClikCirCle = (data, i) => {
    message.loading('Selecionando...')
    const dataGeo = { ...geoJson }
    const pontos: any = getPontosSelecionados

    if (dataGeo.features[i].color !== '#ffffff') {
      setPontosSelecionados(
        pontos.filter(
          (item) =>
            item.geometry.coordinates[0] !== dataGeo.features[i].geometry.coordinates[0] &&
            item.geometry.coordinates[1] !== dataGeo.features[i].geometry.coordinates[1]
        )
      )
      dataGeo.features[i].color = '#ffffff'
    } else {
      dataGeo.features[i].color = corSelecionada
      setPontosSelecionados([...pontos, dataGeo.features[i]])
    }

    setGeoJson(dataGeo)
    message.destroy()
  }

  const handleClickSelectTudo = () => {
    message.loading('Selecionando...')
    const dataGeo = { ...geoJson }

    const cor = analises.find((info) => info.cod_analise === form.getFieldValue('corAnalise'))

    let pontos: any = []

    dataGeo.features.forEach((data) => {
      if (data.color === '#ffffff' || data.color === cor.cor) {
        data.color = cor.cor

        pontos = [...pontos, data]
      }
      return pontos
    })
    setPontosSelecionados(pontos)

    setGeoJson(dataGeo)
    message.destroy()
  }

  const handleClickResetAnaliseMapa = (atualiza = true) => {
    setPontosSelecionados([])

    if (atualiza) {
      const dadosGeo = { ...geoJson }
      dadosGeo.features.forEach((data) => {
        data.color = '#ffffff'
      })
      setGeoJson(dadosGeo)
    }

    setGeraMicro(false)
    setMicro(false)

    setAddAnalise([])

    const primeiro = analises.find(
      (info) =>
        info.cod_profundidade === 2 &&
        info.analises.analises.find((ana) => ana.cod === 1) &&
        info.analises.analises.find((ana) => ana.cod === 3)
    )
    if (primeiro?.cod_analise) {
      form.setFieldsValue({ corAnalise: primeiro.cod_analise })
      handleChangeTipoAnalise(primeiro.cod_analise)
    } else {
      handleChangeTipoAnalise(null)
    }
    setGeraMicro(false)
  }

  useEffect(() => {
    //COMEÇAR COM O SELECT DE COR JÁ PREENCIDO
    handleClickResetAnaliseMapa(true)
  }, [analises])
  

  const handleChangeTipoAnalise = (data) => {
    if (data === null) {
      setCorSelecionada(grupoDeAnalises[0].cor)
    } else {
      const compare = analises.find((info) => info.cod_analise === data)

      setCorSelecionada(compare.cor)
    }
  }

  const handleClickNextAnalise = (data, micro) => {
    const id = uuid()
    const analise = analises.find((info) => info.cod_analise === data)
    const pontos = ordernar(getPontosSelecionados)

    let dadosAnalises = addAnalise

    dadosAnalises = [
      ...dadosAnalises,
      {
        title: `${analise.profundidade_nome} | ${analise.analise_nome}`,
        cod_analises: {
          cod: analise.cod_analise,
          valor: parseFloat(analise.valor),
        },
        cod_profundidade: analise.analises.profundidade.cod,
        profundidade: analise.analises.profundidade.nome,
        id,
        pontos,
        valor_analise: parseFloat(analise.valor) * pontos.length,
      },
    ]

    if (micro) {
      const segundo = analises.find(
        (info) =>
          info.cod_profundidade === 1 &&
          info.analises.analises.find((ana) => ana.cod === 1) &&
          info.analises.analises.find((ana) => ana.cod === 2)
      )
      form.setFieldsValue({ corAnalise: segundo.cod_analise })

      setCorSelecionada(segundo.cor)

      setPontosSelecionados([])

      setGeraMicro(true)
    } else {
      form.setFieldsValue({ corAnalise: null })

      handleCorSelect()

      setPontosSelecionados([])

      if (geraMicro) {
        confirm({
          title: 'Deseja Gerar as Seguintes Analises?',
          icon: <ExperimentOutlined />,
          content: (
              <Form form={form}>
                <Form.Item name='autoAnalise'>
                  <Checkbox.Group style={{ width: '100%' }} onChange={handelChangeCheckbox}>
                    <Col span={24}>
                      <Checkbox value='4'>40 - 60 | Macro + Enxofre</Checkbox>
                    </Col>
                    <Col span={24}>
                      <Checkbox value='3'>00 - 50 | Granulometria</Checkbox>
                    </Col>
                  </Checkbox.Group>
                </Form.Item>
              </Form>
          ),
          onOk: () => macroRestante(dadosAnalises),
          okCancel: false,
          okText: 'Ok',
        })
      }
    }
    setAddAnalise(dadosAnalises)
  }

  const macroRestante = (data) => {
    confirm({
      title:
        'Deseja gerar os pontos de 00 a 20 cm  |  Macro + S, desconsiderando os pontos com micronutrientes?',
      icon: <ExperimentOutlined />,
      onOk: () => handleOkModal(data, '6'),
      onCancel: () => handleOkModal(data, '0'),
      okText: 'Sim',
      cancelText: 'Não',
    })
  }

  const handelChangeCheckbox = (data) => {
    setAutoAnalise(data)
  }

  const handleOkModal = (dados, rest) => {
    let analisando = dados
    let autoAnalise = form.getFieldValue('autoAnalise')

    if (rest === '6') {
      autoAnalise = [...autoAnalise, '6']
    }

    const pontos = analisando[1]?.pontos
    autoAnalise.forEach((info) => {
      if (info === '4') {
        
        // 40 - 60 | Macro + Enxofre?
        const id = uuid()
        const analise = analises.find(
          (info) =>
            info.cod_profundidade === 3 &&
            info.analises.analises.find((ana) => ana.cod === 3) &&
            info.analises.analises.find((ana) => ana.cod === 1)
        )

        analisando = [
          ...analisando,
          {
            title: `${analise?.profundidade_nome} | ${analise?.analise_nome}`,
            cod_analises: {
              cod: analise?.cod_analise,
              valor: parseFloat(analise.valor),
            },
            cod_profundidade: analise.analises.profundidade.cod,
            profundidade: analise.analises.profundidade.nome,
            id,
            pontos,
            valor_analise: parseFloat(analise.valor) * pontos.length,
          },
        ]
      }

      if (info === '3') {
        // 00 - 50 | Granolometria?
        
        const id = uuid()

        const analise = analises.find(
          (info) =>
            info.cod_profundidade === 4 && info.analises.analises.find((ana) => ana.cod === 5)
        )

        analisando = [
          ...analisando,
          {
            title: `${analise.profundidade_nome} | ${analise.analise_nome}`,
            cod_analises: {
              cod: analise.cod_analise,
              valor: parseFloat(analise.valor),
            },
            cod_profundidade: analise.analises.profundidade.cod,
            profundidade: analise.analises.profundidade.nome,
            id,
            pontos,
            valor_analise: parseFloat(analise.valor) * pontos.length,
          },
        ]
      }

      if (info === '6' && analises.length > 0) {
        // 00 - 20 | Macro + Enxofre?
        
        const cor2040 = analises.find(
          (info) =>
            info.cod_profundidade === 2 &&
            info.analises.analises.find((ana) => ana.cod === 3) &&
            info.analises.analises.find((ana) => ana.cod === 1)
        )
        const dataGeo = { ...geoJson }
        let pontos = []

        dataGeo.features.forEach((data) => {
          if (data.color === '#ffffff' || data.color === cor2040.cor) {
            pontos = [...pontos, data]
          }
        })

        const id = uuid();
        
        const nova_analise = analises?.filter(
            (info) =>
            info.cod_profundidade === 1 &&
            info.analises.analises.find((ana) => ana.cod === 3) &&
            info.analises.analises.find((ana) => ana.cod === 1) &&
            (info.analises.analises.find((ana) => ana.cod === 2)) === undefined
        );

        const analise_encontrada = nova_analise?.[0];

        analisando = [
          ...analisando,
          {
            title: `${analise_encontrada.profundidade_nome} | ${analise_encontrada.analise_nome}`,
            cod_analises: {
              cod: analise_encontrada.cod_analise,
              valor: parseFloat(analise_encontrada.valor),
            },
            cod_profundidade: analise_encontrada.analises.profundidade.cod,
            profundidade: analise_encontrada.analises.profundidade.nome,
            id,
            pontos,
            valor_analise: parseFloat(analise_encontrada.valor) * pontos.length,
          },
        ]
      }

      return analisando
    })

    gerarAnalise(analisando)
    setAddAnalise(analisando)
    Modal.destroyAll()
    form.setFieldsValue({ corAnalise: null })
  }

  const handleLoadMap = (map) => {
    mapRef.current = map
  }

  const handelCenterMapa = () => {
    if (!mapRef.current) return
    const newPos = mapRef.current.getCenter().toJSON()

    setLat(newPos.lat)
    setLng(newPos.lng)
  }

  const handleCorSelect = () => {
    const compare = analises.find(
      (info) =>
        info.cod_profundidade === 2 &&
        info.analises.analises[0].cod === 3 &&
        info.analises.analises[1].cod === 1
    )

    if (compare?.cod_analise) {
      setCorSelecionada(compare?.cor)

      form.setFieldsValue({ corAnalise: compare.cod_analise })
    } else {
      setCorSelecionada(analises[0]?.cor)
    }
  }

  const handelUnmountMap = () => {
    //
  }

  const handleDragMap = (data) => {
    setDrag(data)
    // eslint-disable-next-line no-new
    new window.google.maps.Marker({
      draggable: data,
    })
  }

  const handleDrawing = (data) => {
    setDrawing(data)
  }

  const handleRemovePonto = (data, ponto, i) => {
    Modal.confirm({
      title: `Deseja Remover o Ponto ${ponto}`,
      okText: 'Sim',
      cancelText: 'Não',
      icon: <IconAlert type='confirm' size={6} />,
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: () => {
        const geo = { ...geoJson }

        let newGeo = []
        geo.features.forEach((info, index) => {
          if (index !== i) {
            const temp = info

            newGeo = [...newGeo, temp]
          }

          return newGeo
        })
        geo.features = newGeo

        atualizoupontos(geo)

        handleNewGeoJson(geo)
      },
    })
  }

  const handleNewGeoJson = (data) => {
    setGeoJson(data)
  }

  const addMarker = (position) => {
    const geo = { ...geoJson }
    const arrayUltimo = geoJson.features[geoJson.features.length - 1]

    const namePonto = arrayUltimo.properties.NOME + 1

    const lng = position.lng()
    const lat = position.lat()

    const newPonto = {
      color: '#ffffff',
      geometry: {
        coordinates: [lng, lat],
        type: 'Point',
      },
      properties: {
        COMENTARIO: namePonto as string,
        INDICE: namePonto,
        LATITUDE: lat.toString(),
        LONGITUDE: lng.toString(),
        NOME: namePonto,
        ZONA: null,
      },
      type: 'Feature',
    }
    geo.features.push(newPonto)

    atualizoupontos(geo)

    handleNewGeoJson(geo)
  }

  const handleClickKMLGeral = () => {
    const geo = { ...geoJson }

    // console.log("geo: ", geoLocation)

    let coordenadas = []
    geo.features.forEach((data) => {
      const dados = {
        NOME: data.properties.NOME,
        INDICE: data.properties.INDICE,
        LATITUDE: data.geometry.coordinates[1],
        LONGITUDE: data.geometry.coordinates[0],
      }
      coordenadas = [...coordenadas, dados]
      return coordenadas
    })
    
    const dadosGerarKml = {
      pontos: coordenadas,
    }
    const response = geralKmlLab(dadosGerarKml, geo.fileName)

    const blob = new Blob([response], {
      type: 'application/octet-stream',
    })
    url.current.href = URL.createObjectURL(blob)
    url.current.download = `${geo.fileName}.kml`
    url.current.click()
  }

  const handleDragEnd = (coord, index) => {
    const geo = JSON.parse(JSON.stringify(geoJson))

    const path = { lng: coord.latLng.lng(), lat: coord.latLng.lat() }
    geo.features[index].geometry.coordinates[0] = path.lng
    geo.features[index].geometry.coordinates[1] = path.lat

    geo.features[index].properties.LATITUDE = String(path.lat)
    geo.features[index].properties.LONGITUDE = String(path.lng)

    setGeoJson(geo)

    atualizoupontos(geo)
  }

  const propsEventsMarker = (data, i, drag) => {
    const props = {
      onDragEnd: (data) => handleDragEnd(data, i),
      onRightClick: (info) => handleRemovePonto(info, parseInt(data.properties.NOME, 10), i),
      onClick: drawing ? null : (data) => onClikCirCle(data, i),
      options: {
        clickable: true,
        draggable: drag,
      },
    }
    return props
  }

  useEffect(() => {
    linha(
      geoLocation.features[centro]?.geometry.coordinates[1],
      geoLocation.features[centro]?.geometry.coordinates[0]
    )
    setPontosSelecionados([])
    handelCenterMapa()
    setGeoJson(geoLocation)
    setAnalises(grupoDeAnalises)
    handleCorSelect()
  }, [geoLocation])

  useEffect(() => {
    if (visible) {
      setDrag(false)
      setDrawing(false)
    }
  }, [visible])

  useEffect(() => {
    const calc = talhaoSelecionado.area
      ? talhaoSelecionado.area
      : areaTalhao / getPontosSelecionados.length
    setGridAmostral(calc)
  }, [getPontosSelecionados])

  useEffect(() => {
    geoJson.features.forEach((ponto) => {
      ponto.color = '#ffffff'
    })
  }, [setPontos])

  useEffect(() => {
    handleClickResetAnaliseMapa(false)
  }, [resetAnalize])

  useEffect(() => {
    form.setFieldsValue({ autoAnalise: ['4', '3'] })
    setAnalises(grupoDeAnalises)
  }, [])

  return (
    <Container>
      <Row gutter={[8, 8]}>
        <Col span={19}>
          <Form form={form} size='small' layout='inline'>
            <Form.Item label='Inserir Pontos' name='inseriPontos'>
              <Switch
                disabled={!status}
                size='small'
                onChange={handleDrawing}
                checkedChildren='Sim'
                unCheckedChildren='Não'
                checked={drawing}
              />
            </Form.Item>
            <Form.Item label='Mover Pontos' name='movePontos'>
              <Switch
                disabled={!status}
                size='small'
                onChange={handleDragMap}
                checkedChildren='Sim'
                unCheckedChildren='Não'
                checked={drag}
              />
            </Form.Item>
            <Form.Item>
              <Button
                onClick={handleClickKMLGeral}
                icon={<DownloadOutlined />}
                type='primary'
                size='small'
              >
                Baixar Kml Malha Geral
              </Button>
              <a style={{ display: 'none' }} href='empty' ref={url}>
                ref
              </a>
            </Form.Item>
          </Form>
          <p />
          {/* @ts-ignore */}
          <GoogleMap
            options={{
              mapTypeId: 'satellite',
              mapTypeControl: false,
              streetViewControl: false,
              clickableIcons: true,
            }}
            onLoad={handleLoadMap}
            onClick={drawing ? (data) => addMarker(data.latLng) : null}
            onUnmount={handelUnmountMap}
            onCenterChanged={handelCenterMapa}
            mapContainerStyle={{
              width: '100%',
              height: '78vh',
            }}
            center={inicio}
            zoom={15}
            id='map'
          >
            {geoJson.features.map((data, i) => {
              return (
                // @ts-ignore
                <Marker
                  icon={{
                    path: faMapMarker.icon[4] as string,
                    anchor: new window.google.maps.Point(190, 550),
                    fillColor: data.color,
                    fillOpacity: 1,
                    strokeWeight: 1,
                    strokeColor: '#ffffff',
                    scale: 0.055,
                    labelOrigin: new window.google.maps.Point(190, 220),
                  }}
                  // onDragEnd={(data) => handleDragEnd(data, i)}
                  // onRightClick={(info) =>
                  //   handleRemovePonto(
                  //     info,
                  //     parseInt(data.properties.NOME, 10),
                  //     i
                  //   )
                  // }
                  // onClick={drawing ? null : (data) => onClikCirCle(data, i)}
                  // options={{
                  //   clickable: true,
                  //   draggable: drag,
                  // }}
                  {...(status ? propsEventsMarker(data, i, drag) : null)}
                  animation={window.google.maps.Animation.DROP}
                  label={{
                    className: 'color-marker-label',
                    text: parseInt(data.properties.NOME, 10).toString(),
                    fontSize: '11px',
                    fontWeight: 'bold',
                  }}
                  key={data.properties.INDICE}
                  position={{
                    lat: parseFloat(data.geometry.coordinates[1]),
                    lng: parseFloat(data.geometry.coordinates[0]),
                  }}
                />
              )
            })}
            <Polyline
              options={{
                path: listLinha,
                geodesic: true,
                strokeColor: '#00f75b',
                strokeOpacity: 1.0,
                strokeWeight: 4,
                zIndex: 0,
              }}
            />
          </GoogleMap>
        </Col>
        <Col span={5}>
          <Card
            extra={
              <>
                <Button
                  disabled={!status}
                  title='Seleciona todos os Pontos não Selecionados'
                  size='small'
                  className='primary-button'
                  onClick={handleClickSelectTudo}
                  type='primary'
                >
                  Selecionar Todos
                </Button>
                {'  '}
                <Popconfirm
                  disabled={!status}
                  okText='Sim'
                  onConfirm={() => handleClickResetAnaliseMapa()}
                  icon={<IconAlert type='confirm' size={4} />}
                  cancelText='Não'
                  cancelButtonProps={{
                    type: 'primary',
                    danger: true,
                  }}
                  title='Deseja Recomeçar as analises?'
                >
                  <Button
                    disabled={!status}
                    title='Resetar'
                    size='small'
                    className='primary-button'
                    type='primary'
                  >
                    Resetar
                  </Button>
                </Popconfirm>
              </>
            }
            size='small'
            style={{ minWidth: '100%' }}
          >
            <Row gutter={[2, 2]}>
              <>
                <Col span={24}>
                  <Form layout='vertical' form={form}>
                    <Form.Item label='Profundidade | Análises' name='corAnalise'>
                      <Select
                        disabled={getPontosSelecionados.length > 0 || !status}
                        onChange={handleChangeTipoAnalise}
                        allowClear
                        filterOption={(input, option) =>
                          option.children
                            // @ts-ignore
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {analises.map((info) => {
                          const dados = addAnalise
                          const resp = dados.find(
                            (data) => data.cod_analises.cod === info.cod_analise
                          )
                          
                          return (
                            <Option
                              disabled={resp !== undefined}
                              key={info.cod_analise}
                              value={info.cod_analise}
                            >
                              <Row>
                                <Tag color={info.cor}> </Tag>
                                {info.profundidade_nome} | {info.analise_nome}
                              </Row>
                            </Option>
                          )
                        })}
                      </Select>
                    </Form.Item>
                  </Form>
                </Col>
                {getPontosSelecionados.map((data) => {
                  return (
                    <Col style={{ width: '48px' }}>
                      <CardBtn>
                        <Card
                          size='small'
                          key={data.properties.INDICE}
                          className='info-button btn-ponto'
                          style={{
                            display: 'flex',
                            justifyContent: 'center',
                          }}
                        >
                          {parseInt(data.properties.NOME, 10)}
                        </Card>
                      </CardBtn>
                    </Col>
                  )
                })}
                {getPontosSelecionados.length > 0 ? (
                  <>
                    <Col hidden={micro} span={24}>
                      <Popconfirm
                        title='Deseja Gerar Micronutrientes?'
                        icon={<IconAlert type='confirm' size={4} />}
                        cancelText='Não'
                        okText='Sim'
                        cancelButtonProps={{
                          type: 'primary',
                          danger: true,
                        }}
                        onConfirm={() => {
                          handleClickNextAnalise(form.getFieldValue('corAnalise'), true)
                          setMicro(true)
                        }}
                        onCancel={() => {
                          handleClickNextAnalise(form.getFieldValue('corAnalise'), false)
                          setMicro(false)
                        }}
                      >
                        <Button size='small' type='primary'>
                          Concluir
                        </Button>
                      </Popconfirm>
                    </Col>
                    <Col hidden={!micro} span={24}>
                      <Button
                        onClick={() =>
                          handleClickNextAnalise(form.getFieldValue('corAnalise'), false)
                        }
                        size='small'
                        type='primary'
                      >
                        Concluir
                      </Button>
                    </Col>
                    <b>
                      {getPontosSelecionados.length} de {geoJson.features.length} (
                      {((getPontosSelecionados.length * 100) / geoJson.features.length).toFixed(2)}
                      %) <p /> Grid Amostral: {gridAmostral.toFixed(1)}
                    </b>
                  </>
                ) : null}
              </>
            </Row>
          </Card>
        </Col>
      </Row>
    </Container>
  )
}

export default GoogleMaps
