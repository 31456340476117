import React, { useEffect, useRef, useState } from 'react'
import { Button, Col, Drawer, Form, Input, InputRef, message, Row, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import { Notification } from '../../notification'
import {
  getRecomendaAtivos,
  postRecomendaAtivos,
  putRecomendaAtivos,
  TypeRecomendaAtivos,
} from '../../../services/recomendacao/ativos'

import {
  getRecomendaAcaoAtivos,
  TypeRecomendaAcaoTipos,
} from '../../../services/recomendacao/ativosTipos'
import { getCategoriaInsumo, getCategoriaProdutos, TypeCategoriaProdutos } from '../../../services/categoriaProdutos'
import { getDadosQuimicos, TypeDadosQuimicos } from '../../../services/recomendacao/dadosQuimicos'

interface TypesRecomendaAtivos {
  visible: boolean
  update: number
  clone: number
  onClose: (data: boolean) => void
}

const FormRecomendaAtivos: React.FC<TypesRecomendaAtivos> = (props) => {
  const { visible, update, onClose, clone } = props

  const refInputName = useRef<InputRef>({} as InputRef)

  const [form] = Form.useForm()

  const [dataCategoriaProduto, setDataCategoriaProduto] = useState([])
  const [dataAcaoAtivos, setDataAcaoAtivos] = useState([])
  const [dataDadosQuimicos, setDataDadosQuimicos] = useState([])

  const [hideSelects, setHideSelects] = useState({
    modoAcao: true,
    localAcao: true,
    mecaAcao: true,
    grupoQuimico: true,
    classeQuimica: true,
    grupo: true,
  })

  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const listCategoriaProduto = async () => {
    const resp: AxiosResponse<TypeCategoriaProdutos> = await getCategoriaInsumo({
      pagination: -1,
    })

    setDataCategoriaProduto(resp.data.itens)
  }

  const listAtivosAcao = async () => {
    const resp: AxiosResponse<TypeRecomendaAcaoTipos> = await getRecomendaAcaoAtivos({
      pagination: -1,
    })
    setDataAcaoAtivos(resp.data.itens)
  }

  const listDadosQuimicos = async () => {
    const resp: AxiosResponse<TypeDadosQuimicos> = await getDadosQuimicos({
      pagination: -1,
    })

    setDataDadosQuimicos(resp.data.itens)
  }

  const handleChangeCodCategoria = (cod) => {
    switch (cod) {
      case 2:
        setHideSelects({
          modoAcao: false,
          localAcao: true,
          mecaAcao: false,
          grupoQuimico: false,
          classeQuimica: true,
          grupo: true,
        })
        break
      case 3:
        setHideSelects({
          modoAcao: false,
          localAcao: true,
          mecaAcao: false,
          grupoQuimico: false,
          classeQuimica: false,
          grupo: false,
        })
        break
      case 4:
        setHideSelects({
          modoAcao: false,
          localAcao: false,
          mecaAcao: true,
          grupoQuimico: false,
          classeQuimica: true,
          grupo: true,
        })
        break
      default:
        setHideSelects({
          modoAcao: false,
          localAcao: false,
          mecaAcao: false,
          grupoQuimico: false,
          classeQuimica: false,
          grupo: false,
        })
        break
    }
  }

  const editAtivo = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse<TypeRecomendaAtivos> = await getRecomendaAtivos({
      cod,
    })
    const dados = resp.data.itens[0]
    handleChangeCodCategoria(dados.cod_classe_quimica)
    form.setFieldsValue(resp.data.itens[0])
    message.destroy()
    if (resp.status === 200) {
      return true
    }
    return false
  }

  const hancleDrawerClose = () => {
    onClose(false)
    handleChangeCodCategoria(0)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postRecomendaAtivos(data)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Ativo salvo com sucesso',
          })
          onClose(true)
          form.resetFields()
          message.destroy()
          handleChangeCodCategoria(0)
        }
      } else {
        const resp = await putRecomendaAtivos(update, data)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Ativo atualizado com sucesso',
          })
          message.destroy()
          onClose(true)
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setVisibleDrawer(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editAtivo(update)
    }
  }, [update])

  useEffect(() => {
    if (clone > 0) {
      if (editAtivo(clone)) {
        setTimeout(() => {
          refInputName.current.focus()
        }, 200)
      }
    }
  }, [clone])

  useEffect(() => {
    listCategoriaProduto()
    listAtivosAcao()
    listDadosQuimicos()
  }, [])

  return (
    <Form form={form} onFinish={onFinish} layout='vertical'>
      <Drawer
        size='large'
        placement='left'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={visibleDrawer}
        closeIcon={false}
        onClose={() => hancleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => hancleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Row gutter={[6, 6]}>
          <Col span={24}>
            <Form.Item label='Nome' name='nome'>
              <Input ref={refInputName} />
            </Form.Item>

            <Form.Item label='Classe Agronômica' name='cod_classe_agronomica'>
              <Select
                onChange={handleChangeCodCategoria}
                showSearch
                filterOption={(input, option) =>
                  String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                }
              >
                {dataCategoriaProduto.map((data) => (
                  <Select.Option key={data.cod} value={data.cod}>
                    {data.nome}
                  </Select.Option>
                ))}
              </Select>
            </Form.Item>
          </Col>

          <Col span={24}>
            <Row gutter={[6, 6]}>
              <Col hidden={hideSelects.modoAcao} span={hideSelects.localAcao ? 24 : 12}>
                <Form.Item label='Modo de Ação' name='cod_modo_acao'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataAcaoAtivos.map((data) =>
                      data.cod_tipo === 2 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col hidden={hideSelects.localAcao} span={12}>
                <Form.Item label='Local de Ação' name='cod_local_acao'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataAcaoAtivos.map((data) =>
                      data.cod_tipo === 3 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[6, 6]}>
              <Col hidden={hideSelects.mecaAcao} span={12}>
                <Form.Item label='Mecanismo de ação' name='cod_mecanismo_acao'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataAcaoAtivos.map((data) =>
                      data.cod_tipo === 4 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>
              <Col hidden={hideSelects.grupoQuimico} span={12}>
                <Form.Item label='Grupo químico' name='cod_grupo_quimico'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataDadosQuimicos.map((data) =>
                      data.cod_tipo === 6 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>

          <Col span={24}>
            <Row gutter={[6, 6]}>
              <Col hidden={hideSelects.classeQuimica} span={12}>
                <Form.Item label='Classe química' name='cod_classe_quimica'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataDadosQuimicos.map((data) =>
                      data.cod_tipo === 7 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>

              <Col hidden={hideSelects.grupo} span={12}>
                <Form.Item label='Grupo' name='cod_grupo'>
                  <Select
                    showSearch
                    filterOption={(input, option) =>
                      String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                    }
                  >
                    {dataDadosQuimicos.map((data) =>
                      data.cod_tipo === 8 ? (
                        <Select.Option key={data.cod} value={data.cod}>
                          {data.nome}
                        </Select.Option>
                      ) : null
                    )}
                  </Select>
                </Form.Item>
              </Col>
            </Row>
          </Col>
        </Row>
      </Drawer>
    </Form>
  )
}

export default FormRecomendaAtivos
