import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
`

export const ZoomControl = styled.div<{ rMargin?: number }>`
  position: absolute;
  z-index: 99999;
  font-size: 18px;
  right: ${(p) => `${p.rMargin}px`};
  border-radius: 5px;
  text-decoration: none;
  margin: 5px;
  display: flex;
  flex-direction: column;
  padding: 10px;
  margin: 10px;
  background-color: #ffffff;
  transition: 0.5s;

  a {
    &:hover {
      opacity: 0.8;
    }
  }
`
