import styled from 'styled-components';

export const Container = styled.div<{ height: number }>`
  height: 100%;
  overflow: auto;

  .ck-editor__editable_inline {
      height: ${props => props.height || '400px'};
      max-height: ${props => props.height || '400px'};
  }


`
