import styled from "styled-components";

export const ContainerTexto = styled.div`
    height: calc(100vh - 270px);

    div.ContainerTexto__footer {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: center;
        border-top: thin solid var(--border-color);
    }
`