import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faRefresh, faWarning } from "@fortawesome/free-solid-svg-icons";

//ASSETS
import errorMilho from '../../../assets/img/erro.svg';

//COMPONENTS
import Carregando from "../loading";
import ButtonCustom from "../../ButtonCustom";

//STYLE
const Container = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 10px;
    animation: entryAnimation 1s forwards;

    @keyframes entryAnimation {
        0% {
            transform: translateY(-30px);
            opacity: 0;
        }
        50% {
            opacity: 0.5;
        }
        100% {
            transform: translateY(0);
            opacity: 1;
        }
    }

    img.Container__media {
        width: 100px;
    }

    h5.Container__message {
        font-weight: 600;
        font-size: 15px;
        color: var(--text-color);
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

//PROPS
interface Props {
    loading?: boolean;
    tentarNovamente?: Function;
}

export const ErrorDisplay: React.FC<Props> = ({ tentarNovamente, loading }) => {
    return (
        <Container>
            <img src={errorMilho} alt='erro' className="Container__media" />
            <h5 className="Container__message"><FontAwesomeIcon icon={faWarning} className="Container__button" /> Falha ao obter registros!</h5>
            {loading &&(
                <Carregando justifyContent="center" />
            )}
            
            {(tentarNovamente && !loading) &&(
                <ButtonCustom 
                    onClick={()=>{tentarNovamente?.()}}
                    icon={<FontAwesomeIcon icon={faRefresh} className="Container__button" />}
                    type="secondary"
                    danger
                >
                    Tentar novamente
                </ButtonCustom>
            )}
            
        </Container>
    )
};

