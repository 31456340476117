import { useEffect, useRef, useState } from 'react'
import { useLocation } from 'react-router-dom'

import { useLoadScript } from '@react-google-maps/api'
import { Button, Result, Spin } from 'antd'
import L from 'leaflet'
import DrawerPrintMapaFertilidade from '../../Components/ordemLaboratorio/mapaFertilidade/DrawerPrint'
import { getBoundsNoArray } from '../../services/Afins'
import CheckToken from '../../services/ChekToken'
import { ItensElementosTypes, getElementos } from '../../services/Elementos'
import { OsItensTypes, getPedLaboratorio } from '../../services/Laboratorio'
import {
  ItensMapaFertilidadeTypes,
  getMapaFertilidade,
} from '../../services/Laboratorio/osResultado'
import { api } from '../../services/api'
import { Container, Tools } from './styled'

import { faPrint } from '@fortawesome/free-solid-svg-icons'
import ReactToPrint from 'react-to-print'
import Icons from '../../Components/Icons'
import logoTtivos from '../../assets/img/logo.png'
import { useAccess } from '../../context/useAccess'
import { getConsultoria } from '../../services/Cliente'
import { orderBy } from 'lodash'
import { ItensConfigConsultoriaTypes, getConfigBookMobile } from '../../services/configBook'

// type Props = {}

interface PropsParam {
  token: string
  codPedido: string
  codConta: string
  codSafra: string
  codCliente: string
  /**
   * @params {distribuido} 0 para interpretado, 1 para distribuído, se não informado e mesmo e interpretado
   */
  distribuido?: '0' | '1'
  /**
   * @params {ocultarTerreno} 0 para não mostrar terreno, 1 para exibe o terreno do mapa, se não informado o mesmo exibe o terreno
   */
  ocultarTerreno?: '0' | '1'
}

const limparParams = () => {
  const newUrl = window.location.protocol + '//' + window.location.host + window.location.pathname
  window.history.pushState({}, document.title, newUrl)
}

function BookFertilidade() {
  // const {} = props

  const { setConsultoria, consultoria } = useAccess()

  const btnPrint = useRef<ReactToPrint>()

  const { search } = useLocation<PropsParam>()

  const [queres, setQueres] = useState({} as any)
  const [dataOsFer, setDataOsFer] = useState<{
    [key: string]: ItensMapaFertilidadeTypes[]
  }>({})
  const [dataOs, setDataOs] = useState<OsItensTypes>({} as OsItensTypes)
  const [dataElemento, setDataElemento] = useState<ItensElementosTypes[]>([])
  const [loading, setLoading] = useState(false)
  const [params, setParams] = useState<{ [key: string]: string }>()
  //STATES - INFO DO CLIENTE
  const [nome_cliente_painel, setNome_cliente_painel] = useState('');
  //STATES - QUALIDADE DO MAPA DE KRIGAGEM
  const [qualidade_definicao_book, setQualidade_definicao_book] = useState(0.0001);

  const [configBook, setConfigBook] = useState<
    ItensConfigConsultoriaTypes['mapa_fertilidade']['elementos']
  >([])

  const [defaultBounds, setDefaultBounds] = useState<L.LatLngBounds>()

  const [toolsOpen, setToolsOpen] = useState(true)

  const cod_prescricoes = new Set([30, 31, 32, 41, 42, 43, 44, 45, 46, 49]);

  const query = new URLSearchParams(search)

  const listElements = async () => {
    const resp = await getElementos({})

    setDataElemento(resp.data.itens)
  }

  const loadScriptGoogleMaps = useLoadScript({
    googleMapsApiKey: process.env.REACT_APP_TOKEN_GOOGLE_MAPS,
    libraries: ['places', 'drawing', 'visualization', 'geometry'],
    language: 'pt-BR',
  })

  const listConfigBook = async () => {
    const resp = await getConfigBookMobile(params?.token)
    // console.log('CONFIG BOOK: ', resp.data.itens[0].mapa_fertilidade.elementos)

    setConfigBook(resp.data.itens[0].mapa_fertilidade.elementos)
  }

  const checkParams = async () => {
    try {
      limparParams()

      setLoading(true)
      if (!params.token) {
        setQueres({ error: 'Token não informado', status: 403 })
        setLoading(false)
        return
      }
      const resp = await CheckToken(params.token)
      if (!resp) {
        setQueres({ error: 'Token inválido', status: 403 })
        setLoading(false)
        return
      }
      api.defaults.headers.Authorization = `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${params.token}`
      listElements()
      
      

      if (!params.codConta) {
        setQueres({ error: 'Código consultoria não informado', status: 403 })
        setLoading(false)
        return
      } else {
        localStorage.setItem('conta', params.codConta)
        const conta = await getConsultoria(params.codConta)

        setConsultoria(conta.data.itens[0])
      }
      api.defaults.headers.Acesso = 'conta'

      if (!params.distribuido) {
        params.distribuido = '0'
      }

      if (!params.codCliente) {
        setQueres({ error: 'Código do cliente não informado', status: 403 })
        setLoading(false)

        return
      }

      if (!params.codSafra) {
        setQueres({ error: 'Safra não informado', status: 403 })
        setLoading(false)
        return
      }

      if (!params.codPedido) {
        setQueres({
          error: 'Código do pedido da Os não informado',
          status: 403,
        })
        setLoading(false)
        return
      }
      const os = await getPedLaboratorio(
        Number(params.codPedido),
        params.token,
        params.codConta,
        params.codSafra,
        params.codCliente
      )

      const coord = os.data.itens[0].talhao_kml?.coordenadas?.map((rec) => {
        return { lat: Number(rec.latitude), lng: Number(rec.longitude) }
      })

      const bound = getBoundsNoArray(coord)

      const b: L.LatLngBounds = new L.LatLngBounds(
        bound.getSouthWest().toJSON(),
        bound.getNorthEast().toJSON()
      )
      setDefaultBounds(b)
      

      const osFer = await getMapaFertilidade({
        codPedido: Number(params.codPedido),
        token: params.token,
        codConta: params.codConta,
        codCliente: params.codCliente,
      })

      if (osFer.data.itens.length <= 0) {
        setQueres({ error: 'Os não encontrada', status: 404 })
        setLoading(false)
        return
      }
      setDataOs(os.data.itens[0])

      
      // listConfigBook()

      const filter = (osFer.data.itens).filter((rec) =>
        (params?.distribuido == '0') ? (rec.valor_interpretado !== null || cod_prescricoes.has(rec.cod_elemento) ) : rec.valor_distribuido !== null
      )

      const filterORder = orderBy(
        filter.map((rec) => {
          const elemento = configBook.find(
            (item) => Number(item.key) === Number(rec.cod_elemento)
          )
          if (elemento) {
            rec.ordem = elemento.ordem
          } else {
            rec.ordem = null
          }
          return rec
        }),
        (or) => Number(or.ordem)
      )

      // console.log("configBook: ", configBook);
      // console.log("osFer.data.itens: ", osFer.data.itens);
      // console.log("filterORder: ", filterORder);

      let indicadores_e_precricoes = [30, 31, 32, 49, 41, 42, 46, 43, 44, 45, 33, 34, 28, 38, 50]

      const zeroAVinte = (filterORder ?? []).filter((rec) => rec.profundidade === '00-20' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
      const vinteAQuarenta = (filterORder ?? []).filter((rec) => rec.profundidade === '20-40' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
      const prescricoes = filterORder.filter(
        (rec) =>
          rec.cod_elemento === 30 ||
          rec.cod_elemento === 31 ||
          rec.cod_elemento === 32 ||
          rec.cod_elemento === 49 ||
          rec.cod_elemento === 41 ||
          rec.cod_elemento === 42 ||
          rec.cod_elemento === 46 ||
          rec.cod_elemento === 43 ||
          rec.cod_elemento === 44 ||
          rec.cod_elemento === 45
      );
      const micro = (filterORder ?? []).filter((rec) => rec.profundidade === 'micro' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
      const zeroACinquenta = (filterORder ?? []).filter((rec) => rec.profundidade === '00-50' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
      
      const indicadores = (filterORder ?? []).filter(
        (rec) =>
          rec.cod_elemento === 33 ||
          rec.cod_elemento === 34 ||
          rec.cod_elemento === 28 ||
          rec.cod_elemento === 38 ||
          rec.cod_elemento === 50
      )

      let grup: { [key: string]: ItensMapaFertilidadeTypes[] } = {}

      if (zeroAVinte.length > 0) {
        grup = { ...grup, '00-20': zeroAVinte }
      }
      if (vinteAQuarenta.length > 0) {
        grup = { ...grup, '20-40': vinteAQuarenta }
      }
      if (micro.length > 0) {
        grup = { ...grup, micro: micro }
      }
      if (zeroACinquenta.length > 0) {
        grup = { ...grup, '00-50': zeroACinquenta }
      }
      if (indicadores.length > 0) {
        grup = { ...grup, indicadores }
      }
      if (prescricoes.length > 0) {
        grup = { ...grup, prescricoes }
      }

      setDataOsFer(grup)

      setQueres(params)
      setLoading(false)
    } catch (error) {
      setLoading(false)

      setQueres({ error: error.response?.data.error })
    }
  }

  useEffect(() => {
    let para: { [key: string]: string }
    for (const p of query) {
      para = { ...para, [p[0]]: p[1] }
    }
    setParams(para)
  }, [])

  useEffect(() => {
    
    if (configBook?.length === 0) {
      listConfigBook();
    }
    if (configBook?.length > 0) {
      checkParams()
    }
    // checkParams()
  }, [params, configBook])

  function definicaoDaKrigagem(): number {
    if (Number(dataOs?.areaTalhao) < 70) {
      return 0.00003
    }
    if (Number(dataOs?.areaTalhao) < 100) {
      return 0.00004
    }
    if (Number(dataOs?.areaTalhao) < 200) {
      return 0.00005
    }
    if (Number(dataOs?.areaTalhao) < 300) {
      return 0.0001
    }
    if (Number(dataOs?.areaTalhao) < 400) {
      return 0.0002
    }
    if (Number(dataOs?.areaTalhao) > 400) {
      return 0.0003
    }
  }

  useEffect(() => {
    setQualidade_definicao_book(definicaoDaKrigagem)
  }, [dataOs?.areaTalhao])

  return (
    <Container reverse={toolsOpen} id='divScroll'>
      {loading && loadScriptGoogleMaps.isLoaded && params.codPedido ? (
        <div className='spin'>
          <Spin size='large' />
        </div>
      ) : queres.error ? (
        <Result
          status={queres.status ? queres.status : 'warning'}
          title={queres.status ? queres.status : 'Atenção'}
          subTitle={queres.error}
        />
      ) : (
        <>
          <div className='print'>
            <DrawerPrintMapaFertilidade
              idPrint='mapa-fertilidade'
              idScroll='root'
              defaultBounds={defaultBounds}
              typeKricagem={params?.distribuido == '0' ? true : false}
              exibeTerreno={params?.ocultarTerreno !== '1'}
              dataColeta={dataOs?.data}
              talhao={dataOs?.talhao_kml}
              // nameCliente={dataOs?.propriedade?.[0]?.cliente_nome}
              nameCliente={dataOs?.nome_cliente}
              talhaoNome={dataOs?.talhao_nome}
              propriedade={dataOs?.propriedade?.[0] as any}
              dataFertilidade={dataOsFer}
              // dataFertilidade={lista_profundidade}
              elementosData={dataElemento}
              consultoria={consultoria}
              areaTalhao={dataOs?.areaTalhao}
              cod_prescricoes={cod_prescricoes}
              cods_q_devem_ser_reverso={[7, 18, 28, 30, 31, 32, 41, 42, 43, 44, 45, 46, 49, 50]}
              setNome_cliente_painel={setNome_cliente_painel}
              nome_calcario_propriedade={dataOs?.calcario_propriedade}
              qualidade_definicao_book={qualidade_definicao_book}
              sempre_quebrar_sumario={true}
            />
          </div>
          <Tools>
            <h3>Para imprimir ou salvar o book de fertilidade, pressione o botão abaixo</h3>
            <ReactToPrint
              ref={btnPrint}
              trigger={() => (
                <Button
                  size='large'
                  icon={<Icons icon={faPrint} />}
                  type='primary'
                  htmlType='button'
                >
                  Imprimir / salvar pdf
                </Button>
              )}
              content={() => document.getElementById('mapa-fertilidade')}
            />
            <img src={logoTtivos} alt='ttivos logo' />
          </Tools>
        </>
      )}
    </Container>
  )
}
export default BookFertilidade
