/* eslint-disable react/require-default-props */
import React from 'react'

import geojson2svg from 'geojson-to-svg'

interface TypesCordenates {
  coordinates: [string | number, string | number]
  fill: string
  fillOpacity?: number
  multi?: boolean,
  className?: string;
}

const Svg: React.FC<TypesCordenates> = (props) => {
  const { coordinates, fill = 'green', multi = false, fillOpacity = 1, className } = props
  let pontos = []
  let features = []

  if (coordinates === undefined) {
    features = [
      {
        type: 'Feature',
        geometry: {
          type: 'LineString',
          coordinates: [0, 0],
        },
        properties: {
          thumb: 'basic',
        },
      },
    ]
  }

  if (multi) {
    coordinates?.forEach((talhao) => {
      // @ts-ignore
      talhao.filter((e) => (e.latitude || e.lat) && (e.longitude || e.lng)).forEach((dados) => {
        // @ts-ignore
        const latitude = dados.longitude || dados.lng
        // @ts-ignore
        pontos = [
          ...pontos,
          [
            // @ts-ignore
            dados.longitude || String(dados.lng),
            latitude
            // parseFloat(String(latitude).replace('-', '')),
          ],
        ]

        return pontos
      })
    })

    features = [
      ...features,
      {
        type: 'Feature',
        geometry: {
          type: 'MultiPolygon',
          coordinates: [pontos],
        },
        properties: {
          thumb: 'basic',
        },
      },
    ]
  } else {
    coordinates?.filter((e: any) => (e.latitude || e.lat) && (e.longitude || e.lng)).forEach((dados) => {
      // @ts-ignore
      const latitude = dados.latitude || dados.lat
      // @ts-ignore
      pontos = [
        [
          // @ts-ignore
          dados.longitude || String(dados.lng),
          -latitude
          // parseFloat(String(latitude).replace('-', '')),
        ],
        ...pontos,
      ]

      return pontos
    })

    features = [
      {
        type: 'Feature',
        geometry: {
          type: 'Polygon',
          coordinates: [pontos],
        },
        properties: {
          thumb: 'basic',
        },
      },
    ]
  }

  let svg
  if (pontos.length === 0) {
    svg = null
  } else {
    svg = geojson2svg()
      .type('thumb')
      .styles({
        basic: { fill, fillOpacity, strokeColor: '#ffffff' },
      })
      .data({
        type: 'FeatureCollection',
        features,
      })
      .render() 
  }
  
  
  return (
    <svg
      style={{ maxWidth: '50px', maxHeight: '50px', marginRight: 0 }}
      // eslint-disable-next-line react/no-danger
      dangerouslySetInnerHTML={{ __html: svg }}
      className={className??""}
    />
  )
}

export default Svg