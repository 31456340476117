import React, { useEffect, useState } from 'react'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Modal, Space, Table } from 'antd'
import { delServico, getServico } from '../../../../services/Servicos'

import { Notification } from '../../../../Components/notification'

import FormServico from '../../../../Components/servico'

import { Container } from './styled'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { tryError } from '../../../../services/Afins'

const Servicos: React.FC = () => {
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [updateUpdateServico, setUpdateUpdateServico] = useState<number>()

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [dataServicoSer, setDataServicoSer] = useState([])

  const listarServico = async () => {
    try {
      setLoadingTable(true)
      const response = await getServico('')

      if (response.status === 200) {
        setDataServicoSer(response.data.itens)
        setLoadingTable(false)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleClickVisuServico = async (data) => {
    setVisibleForm(true)
    setUpdateUpdateServico(data)
  }

  const handleTrashServico = async (data) => {
    const responsePro = await getServico(data)
    const nomePro = responsePro.data.itens[0].nome

    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover o Serviço ${nomePro}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delServico(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Serviço Excluido',
              type: 'success',
            })

            listarServico()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateServico(0)
  }

  const colum = [
    {
      key: 1,
      title: 'Sigla',
      dataIndex: 'sigla',
    },
    {
      key: 2,
      title: 'Nome Serviço',
      dataIndex: 'nome',
    },
    {
      title: 'Ações',
      dataIndex: 'cod',
      key: 'acao',
      render: (data) => (
        <Space>
          <Button
            hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
            shape='circle'
            onClick={() => handleClickVisuServico(data)}
            icon={<FontA icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
            size='middle'
          />
          <Button
            shape='circle'
            onClick={() => handleTrashServico(data)}
            className='trash-button'
            type='primary'
            icon={<FontA icon={solid('trash')} />}
            size='middle'
          />
        </Space>
      ),
    },
  ]

  useEffect(() => {
    listarServico()
  }, [])

  return (
    <Container>
      <ViewPage
        rotina={57}
        onPermission={setPermissions}
        title='Serviços'
        btnClick={handleVisibleForm}
        content={
          <FormServico
            permissions={permissions}
            visible={visibleForm}
            setOnClose={setVisibleForm}
            update={updateUpdateServico}
            setUpdate={setUpdateUpdateServico}
            atualizar={listarServico}
          />
        }
      >
        <Table
          loading={loadingTable}
          dataSource={dataServicoSer}
          pagination={pagination}
          bordered
          size='small'
          columns={colum}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataServicoSer.length}</b>
            </div>
          )}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickVisuServico(record.cod)
              },
            }
          }}
        />
      </ViewPage>
    </Container>
  )
}

export default Servicos
