import { TypeobterProfundidades } from "../../elementos/meta";

export function tipoSecao(cod_sesao: number): 'Fertilidade'|'Indicadores'|'Prescrições' {
    let secoes = {
        1: 'Fertilidade',
        2: 'Indicadores',
        3: 'Prescrições',
        undefined: 'undefined',
        null: 'null',
        NaN: 'NaN',
    }

    return secoes?.[cod_sesao]
};


export function nomeProfundidade(cod_profundidade: number): string {
    let profundidades = {
        1: '00-20',
        2: '20-40',
        3: '40-60',
        4: '00-50',
        5: '00-30',
        6: '00-40',
        7: 'micro',
        undefined: 'undefined',
        null: 'null',
        NaN: 'NaN',
    };

    return profundidades?.[cod_profundidade]
}


export function filtrarTipoMapa(tipo_mapa: number) {
    return function(item) {              
        return String(item.cod_tipo_mapa)?.includes(String(tipo_mapa)) || !String(tipo_mapa);
    }
}


export function obterIdSecao(secao_atual: 'Fertilidade'|'Indicadores'|'Prescrições') {
    let secoes = {
        Fertilidade: 1,
        Indicadores: 2,
        Prescrição: 3,
        Prescrições: 3,
    }

    return secoes?.[secao_atual]
}


export function attrTipos_mapa(secao_atual: 'Fertilidade'|'Indicadores'|'Prescrições'|string, info_profundidades: TypeobterProfundidades[], isCalcario: boolean) {

    const profundidades_interp = info_profundidades?.filter(item=>item.tipo_mapa === 1);    
    const profundidades_distri = info_profundidades?.filter(item=>item.tipo_mapa === 2);  

    function obterProfundidades(tipo_mapa: number) {
        if (isCalcario) {
            let profu_calcario = [1,5,6]?.map((profun)=> {
                return {
                    profundidade: profun,
                    visivel: false,
                    observacao: ''
                }
            })

            return profu_calcario;
        }

        if (tipo_mapa === 1) {
            let interpre = profundidades_interp?.map((item)=> {
                return {
                    profundidade: item.cod_profundidade,
                    visivel: false,
                    observacao: ''
                }
            })

            return interpre;
        }

        let distri = profundidades_distri?.map((item)=> {
            return {
                profundidade: item.cod_profundidade,
                visivel: false,
                observacao: ''
            }
        })

        return distri;

    }
    
    let attr_tipos_mapa = secao_atual === 'Prescrições' ? 
    [
        {
            secao: 3,
            tipo_mapa: 1,
            visivel: true,
            observacao: '',
            profundidades: isCalcario ? obterProfundidades(2) : []
        }
    ] 
    : 
    [
        {
            tipo_mapa: 1, 
            visivel: false, 
            observacao: '', 
            profundidades: secao_atual==='Fertilidade' ? obterProfundidades(1) : undefined
        },
        {
            tipo_mapa: 2, 
            visivel: false, 
            observacao: '',
            profundidades: secao_atual==='Fertilidade' ? obterProfundidades(1) : undefined
        }
    ]

    return attr_tipos_mapa;
}


export function corTagSecao(cod_secao: number): 'purple' | 'volcano' | 'magenta' {
    if (cod_secao === 1) {
        return 'purple';
    }
    return cod_secao === 2 ? 'volcano' : 'magenta';
}