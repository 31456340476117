import { EstadiosDaCultura, ItemAtivoCadastrado } from "../../../../../../../../../services/recomendacao/ativos/novo-index";

export function nivelAlerta(cod_alerta: number) {
    let niveis = {
        1: 'Baixo',
        2: 'Médio',
        3: 'Alto'
    }
    return niveis?.[cod_alerta];
}
export function codNivelAlerta(cod_alerta: number): string[] {
    let niveis = {
        1: ['#fdd732', '#555b5c', 'yellow'],
        2: ['#fdad35', '#fdf1df', 'orange'],
        3: ['#fd6f70', '#fff', 'error']
    }
    return niveis?.[cod_alerta];
}

/**Retorna o `valor` do tipo selecionado*/
export function obterValor(registros: ItemAtivoCadastrado) {
    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return registros?.ingrediente_ativo_nome;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return registros?.insumo_nome

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return registros?.grupo_quimico_nome;

    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return registros?.classe_agronomica_nome;

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return registros?.formulacao_nome;

    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return registros?.cultura_nome

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return registros?.tecnologia_nome

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return registros?.grupo_dado_quimico_nome

}

/**Retorna o `id` do tipo (insumo/ingrediente ativo/grupo quimico/cultura/tecnologia) */
export function obterIdTipo(registros: ItemAtivoCadastrado) {

    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return 1;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return 2;

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return 3;
    
    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return 4;

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return 5;

    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return 6

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return 8;

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return 9;
}

/**Retorna o `id` da compatibilidade */
export function obterIdCompatibilidade(registros: ItemAtivoCadastrado) {
    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return registros?.cod_ingrediente_ativo;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return registros?.cod_insumo

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return registros?.cod_grupo_quimico

    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return registros?.cod_classe_agronomica

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return registros?.cod_formulacao
    
    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return registros?.cod_cultura

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return registros?.cod_tecnologia

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return registros?.cod_grupo

}

export function obterIdCompatibilidadePorId(item_lancamento: number, registros: ItemAtivoCadastrado): number {
    let cod_compatibilidade = {
        1: registros.cod_ingrediente_ativo,
        2: registros.cod_insumo,
        3: registros.cod_grupo_quimico,
        4: registros.cod_classe_agronomica,
        5: registros.cod_formulacao,
        6: registros.cod_cultura,
        7: registros.cod_cultura,
        8: registros.cod_tecnologia,
        9: registros.cod_grupo,
    }

    return cod_compatibilidade?.[item_lancamento] ?? 0;
}


export function disabilitarBtnAdd(estadios_selecionados: {descr: string, id: number, key?: number}[], data_estadios: EstadiosDaCultura) {
    if (estadios_selecionados?.length === (data_estadios?.itens)?.length) {
        return true;
    }
    return estadios_selecionados?.length === 0;
}

/**Desabilita o `Textarea` caso nenhum estádio seja selecionado ou caso todos os estádios já tenham sido adicionados
 * 
 * Habilita o `Textarea` caso o tipo seja diferente de `cultura` ou caso o Switch `todos_estadios` seja `true`
 */
export function disabilitarTextArea(id_tipo_selecionado:number|null, todos_estadios: boolean, 
    estadios_selecionados:{descr: string, id: number, key?: number}[], data_estadios: EstadiosDaCultura) {

    if (id_tipo_selecionado !== 6) {
        return false;
    }
    if (todos_estadios) {
        return false;
    }
    if (estadios_selecionados?.length === (data_estadios?.itens)?.length) {
        return true;
    }
    return estadios_selecionados?.length === 0;
}

/**Filtrar listagem baseado no valor digitado no campo buscar*/
export function buscarCadastro(buscarItem) {
    return function(item) {              
        return String(item.insumo_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.grupo_quimico_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.tecnologia_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.classe_agronomica_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.formulacao_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.grupo?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.ingrediente_ativo_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.cultura_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        !buscarItem;
    }
}

/**Listar apenas items de compatiblidade `(cod_tipo == 1)` */
export function filtrarIncompatibilidade() {
    return function(item) {              
        return item.cod_tipo === 2
    }
}