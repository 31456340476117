import styled from 'styled-components'

export const Container = styled.div`
  table {
    margin-bottom: 10px;
  }
  tbody tr th {
    min-width: 200px;
    max-width: 200px;
    height: 57.58px;
    z-index: 9998;
    border: 1px solid #f0f0f0f0;
    text-align: left;
    padding-left: 10px;
    vertical-align: middle;
    background-color: #ffffff;
  }

  .th-date {
    position: sticky;
    z-index: 9997;
    top: 0;
    background-color: rgba(250, 250, 250, 0.9);
  }

  tbody tr td {
    border: 1px solid #f0f0f0f0;
    text-align: center;
    vertical-align: middle;
    z-index: 29;
    min-width: 122px;
    max-width: 122px;
  }
`

export const ContainerItem = styled.tr`
  z-index: 9996;
  position: relative;
  
  th {
    position: sticky;
    left: 0;
    display: flex;
    flex-direction: column;
    background-color: #ffffff;

    span {
      font-size: 13px;
    }

    small {
      font-size: 9px;
      opacity: 0.7;
      padding: 0;
    }
  }
  td {
    position: relative;
  }
`
