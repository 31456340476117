import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import {
  getCulturas,
  postCadastrarCultura,
  putCadastrarCultura,
} from '../../../../../services/cadastrarCultura'
import { Notification } from '../../../../../Components/notification'

interface TypesCadastrarCultura {
  open: boolean
  update: number
  onClose?: (data: boolean) => void
  tipoMedidas?: any[]
}

const FormCadastarCultura: React.FC<TypesCadastrarCultura> = (props) => {
  const { open, update, onClose, tipoMedidas } = props

  const [form] = Form.useForm()

  const [dataTipoMedidas, setDataTipoMedida] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)

  // const editAtivo = async (cod) => {
  //   message.loading('Carregando', 99999)
  //   const resp: AxiosResponse<TypeCategoriaProdutos> = await getCategoriaProdutos({ cod })

  //   form.setFieldsValue(resp.data.itens[0])
  //   message.destroy()
  // }

  const hancleDrawerClose = () => {
    onClose(false)
    form.resetFields()
  }

  const editCultura = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse = await getCulturas({ cod })

    form.setFieldsValue(resp.data.itens[0])
    message.destroy()
  }

  const onFinish = async (data) => {

    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postCadastrarCultura(data)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Cultura salva com sucesso',
          })
          hancleDrawerClose()
          form.resetFields()
          message.destroy()
        }
      } else {
        const resp = await putCadastrarCultura(update, data)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Cultura atualizada com sucesso',
          })
          message.destroy()
          hancleDrawerClose()
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setOpenDrawer(open)
    setDataTipoMedida(tipoMedidas)
  }, [open])

  useEffect(() => {
    if (update > 0) {
      editCultura(update)
    }
  }, [update])

  // useEffect(() => {
  //   listTipos()
  // }, [])

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        placement='right'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={open}
        closeIcon={false}
        onClose={() => hancleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              {update > 0 ? 'Atualizar' : 'Salvar'}
            </Button>
            <Button type='primary' danger onClick={() => hancleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        {update > 0 ? (
          <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Atualizar cultura</h2>
        ) : (
          <h2 style={{fontSize: '20px', fontWeight: 'bold'}}>Cadastrar cultura</h2>
        )}
        <Form.Item label='Nome' name='nome'>
          <Input />
        </Form.Item>
        <Form.Item label='Nome científico' name='nome_cientifico'>
          <Input />
        </Form.Item>
        <Form.Item label='Unidade de medida' name='unidade_colheita'>
          <Select placeholder='Selecione a unidade de medida'>
            {tipoMedidas?.map((data) => (
              <Select.Option key={data.cod} value={data.cod}>
                {data.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default FormCadastarCultura
