import { api } from '../../api';
import { useQuery } from 'react-query';


//=================================================================================>
// BUSCAR VERSÃO ATUAL DO SISTEMA
//<=================================================================================

//TYPES
type itemVersao = {
    versao: string
}

//INTERFACE
export interface Versao {
    itens: itemVersao[]
}

export const useGetUltimaVersao = () => {
    const queryKey: any = [`versao-atual`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Versao, Error>(queryKey, async () => {
        return api.get(`versao?aplicacao=1`)
            .then((response):any => {
                return response.data
            })
            .catch(() => {
                console.error('erro')
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_versao: data?.itens?.[0]?.versao ?? [], 
        carregando_parametros: isFetching, 
        refetch_versao: refetch,
        isFetched_versao: isFetchedAfterMount
    };
};