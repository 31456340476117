import { Button, Descriptions, Drawer, Space, Switch, message } from 'antd'
import React, { useEffect, useRef, useState } from 'react'
import { MoniNivelItemControleItens } from '../../services/monitoramento/nivelControleItens'
import CanvasInterpolation, {
  TypesGerarGroupOverlay,
  TypesOverlayView,
} from '../CanvasInterpolation'
import WaterMarker from '../WaterMarker'
import {
  Capa,
  Container,
  Content,
  ContentMap,
  Escala,
  PdfPrint,
  RodapeTtivos,
  SubCapa,
} from './styled'

import { PrinterOutlined } from '@ant-design/icons'
import dayjs from 'dayjs'
import L from 'leaflet'
import {
  ImageOverlay,
  Marker as LMarker,
  Polygon as LPolygon,
  MapContainer,
  TileLayer,
} from 'react-leaflet'
import ReactToPrint from 'react-to-print'
import logoTtivos from '../../assets/img/logo-2.png'
import pin from '../../assets/svg/marker.svg'
import { useAccess } from '../../context/useAccess'
import { getResumoMonitoramento } from '../../services/monitoramento/resumo'
import { ResumoNutrientes, TrReverse } from '../ordemLaboratorio/mapaFertilidade/DrawerPrint/styles'

interface TypesBookMonitoramento {
  data?: TypesBookData
  mapType: google.maps.MapTypeId
  open: boolean
  onLoading?: (data: boolean) => void
  finishBook?: (data: boolean) => void
  nivelControleItens: MoniNivelItemControleItens[]
  onClose?: (data: boolean) => void
  resumir: boolean
  ordenarTalhao?: boolean
  totalizar?: boolean
  setOrdenarTalhao?: (data: boolean) => void
  setResumir?: (data: boolean) => void
  setOpenBook: (data: boolean) => void
  codPro?: number
}

export interface TypesBookData {
  nameCliente?: string
  namePropriedade?: string
  nameConsultor?: string
  codTalhao?: number[]
  date?: string
  monitoramento?: TypeBookMonitoramento[]
}

export interface TypeBookMonitoramento {
  especieVariacao: string
  data: {
    key: string
    codTalhao: number
    talhao?: string
    estadios?: string[]
    area: string | number
    min?: string
    med?: string
    max?: string
    talhao_observacao?: string
    polygon?: { lat: number; lng: number }[]
    idw?: [number, number, string, number, string][]
    overLayView?: TypesOverlayView[]
  }[]
}

const BookMonitoramento: React.FC<TypesBookMonitoramento> = (props) => {
  const {
    data,
    open,
    nivelControleItens,
    onClose,
    onLoading,
    mapType,
    finishBook,
    resumir,
    ordenarTalhao,
    totalizar,
    codPro,
    setResumir,
    setOpenBook,
    
  } = props

  const { consultoria } = useAccess()

  const { monitoramento = [] } = data

  const refPdfPrint = useRef<HTMLDivElement>()

  const [dataBooking, setDataBooking] = useState<TypesBookData>({} as TypesBookData)

  const [geraGroundOverlay, setGeraGroundOverlay] = useState<TypesGerarGroupOverlay[]>([])

  const [markerOpacitySubCapa, setMarkerOpacitySubCapa] = useState(false)

  const [returnOverlayView, setReturnOverlayView] = useState<TypesOverlayView[]>([])

  const [openDrawer, setOpenDrawer] = useState(false)
  const [totalizarBook, setTotalizarBook] = useState(false)
  const [logoBook, setLogoBook] = useState('')
  const [infoLocal, setInfoLocal] = useState([])
  const [licenciado, setLicenciado] = useState('')
  const [dataResumo, setDataResumo] = useState([])

  const tipoAcesso = localStorage.getItem('tipo-acesso')
  const nomeBookApp = JSON.parse(localStorage.getItem('bookApp'))
  const nomeClienteLocal = localStorage.getItem('nome-cliente')

  const filtrarDadosLocal = () => {
    if (nomeBookApp == 1) {
      setLogoBook(localStorage.getItem('logo'))
      setLicenciado(localStorage.getItem('nome-cliente'))
    } else {
      const cod =
        tipoAcesso === 'conta'
          ? JSON.parse(localStorage.getItem('conta'))
          : JSON.parse(localStorage.getItem('cod-cliente'))

      const licenciadoPara = tipoAcesso === 'cliente' ? nomeClienteLocal : consultoria.linha1
      setLicenciado(licenciadoPara)
      const dadosLocal = JSON.parse(localStorage.getItem('Itens'))[0]
      const acessoCliente = dadosLocal['acessoConta']
      const clientes = tipoAcesso === 'conta' ? acessoCliente['contas'] : acessoCliente['clientes']
      setInfoLocal(clientes)
      const dadosLogoLocal = infoLocal.find((codId) => codId.cod === cod)
      if (dadosLogoLocal) {
        const logos = dadosLogoLocal['logo']
        setLogoBook(logos)
      }
    }
  }

  const listResumo = async () => {
    const resp = await getResumoMonitoramento({
      dataInicioMonitoramento: data.date,
      codPropriedade: codPro,
      tipo: 3,
      embed: 'estadios_nc',
    })

    setDataResumo(resp.data.itens)
  }

  useEffect(() => {
    if (data) {
      onLoading?.(true)
      setTotalizarBook(totalizar)
      let temp: TypesGerarGroupOverlay[] = []
      monitoramento.forEach((rec) => {
        const values = rec.especieVariacao
          .split(', ')
          .map((rec) => (rec === 'null' ? null : parseInt(rec)))

        const nivel = nivelControleItens.find((rec) =>
          isNaN(values[2]) || values[2] == null || values[2] == 1
            ? rec.cod_especie === values[1]
            : rec.cod_variacao_especie === values[2]
        )

        if (rec.data.length > 0) {
          rec.data.forEach((item) => {
            temp = [
              ...temp,
              {
                key: item.key,
                nivelVari: rec.especieVariacao,
                polyCoord: item.polygon.map((p) => [p.lat, p.lng]),
                praga: item.idw.map((idw) => [idw[0], idw[1], idw[2]]),
                inverterEscala: nivel.inverter_escala === 1,
                talhao_observacao: item.talhao_observacao,
              },
            ]
            // console.log('item.talhao_observacao', item.talhao_observacao);
            // console.log('item.talhao_observacao', item);
          })
        }
      })

      setGeraGroundOverlay(temp)
    }
    if (localStorage.getItem('Itens') !== null) {
      filtrarDadosLocal()
    }
  }, [data])

  useEffect(() => {
    // message.loading('Gerando book...', 99999)
    setDataBooking(data)
    onLoading?.(false)
    setOpenBook(true)
    message.destroy()
  }, [returnOverlayView])

  useEffect(() => {
    if (geraGroundOverlay.length > 0) {
      onLoading?.(false)
      finishBook?.(false)
    }
  }, [geraGroundOverlay])

  useEffect(() => {
    if (nomeBookApp === 1) {
      setMarkerOpacitySubCapa(true)
    }else{
      setMarkerOpacitySubCapa(false)
    }
  }, [open])


  console.log("data: ", data)

  return (
    <Container>
      <Drawer
        forceRender={true}
        open={open}
        onClose={() => {
          onClose?.(false)
          setOpenDrawer(false)
          setResumir?.(false)
          setOpenBook(false)
          setTotalizarBook(false)
        }}
        width='311mm'
        footer={
          <Space>
            <ReactToPrint
              documentTitle={`${dayjs(data.date, 'DD/MM/YYYY').format('YYYYMMDD')}_${
                data.namePropriedade
              } - ttivos`}
              trigger={() => (
                <Button type='primary' title='Imprimir' icon={<PrinterOutlined />}>
                  Imprimir
                </Button>
              )}
              content={() => refPdfPrint.current}
            />
            <span>Ocultar marcadores na subcapa</span>
            <Switch
              onChange={(e) => setMarkerOpacitySubCapa(e)}
              checkedChildren='Sim'
              unCheckedChildren='Não'
            />
          </Space>
        }
      >
        <PdfPrint id='printMonitoramento' ref={refPdfPrint}>
          <title>
            {data.date}_{data.namePropriedade} - ttivos
          </title>
          <div id='docPrintPdf' className='break'>
            <>
              <title>
                {data.date}_{data.namePropriedade} - ttivos
              </title>
              {nomeBookApp === 1 ? (
                <Capa style={{ height: '190mm' }}>
                  <span>Monitoramento</span>
                  <h2 style={{ marginBottom: 0 }}>{dataBooking?.namePropriedade?.toLowerCase()}</h2>
                  <h3 style={{ color: '#a0a0a0', fontWeight: 700 }}>
                    {tipoAcesso === 'conta'
                      ? dataBooking?.nameCliente?.toLowerCase()
                      : nomeClienteLocal}
                  </h3>
                  <h3>
                    <b>{dataBooking?.date}</b>
                  </h3>

                  {tipoAcesso === 'conta' && dataBooking?.nameConsultor !== '' ? (
                    <>
                      <h3>Consultor</h3>
                      <h3>{dataBooking?.nameConsultor?.toLowerCase()}</h3>
                    </>
                  ) : null}

                  {logoBook === '' ? null : (
                    <div style={{ width: '30%', marginTop: '20px' }}>
                      <img style={{ width: '90%' }} src={logoBook} alt='Agromarques' />
                    </div>
                  )}

                  <RodapeTtivos>
                    <div style={{ marginBottom: '10px' }}>
                      <img src={logoTtivos} alt='' />
                    </div>

                    <small>Licenciado para: {licenciado}</small>
                  </RodapeTtivos>
                </Capa>
              ) : (
                <Capa>
                  <span>Monitoramento</span>
                  <h2 style={{ marginBottom: 0 }}>{dataBooking?.namePropriedade?.toLowerCase()}</h2>
                  <h3 style={{ color: '#a0a0a0', fontWeight: 700 }}>
                    {tipoAcesso === 'conta'
                      ? dataBooking?.nameCliente?.toLowerCase()
                      : nomeClienteLocal}
                  </h3>
                  <h3>
                    <b>{dataBooking?.date}</b>
                  </h3>

                  {tipoAcesso === 'conta' && dataBooking?.nameConsultor !== '' ? (
                    <>
                      <h3>Consultor</h3>
                      <h3>{dataBooking?.nameConsultor?.toLowerCase()}</h3>
                    </>
                  ) : null}

                  {logoBook === '' ? null : (
                    <div style={{ width: '30%', marginTop: '20px' }}>
                      <img style={{ width: '90%' }} src={logoBook} alt='Agromarques' />
                    </div>
                  )}

                  <RodapeTtivos>
                    <div style={{ marginBottom: '10px' }}>
                      <img src={logoTtivos} alt='' />
                    </div>

                    <small>Licenciado para: {licenciado}</small>
                  </RodapeTtivos>
                </Capa>
              )}
              {dataBooking?.monitoramento?.map((item, i) => {
                const values = item.especieVariacao
                  .split(', ')
                  .map((rec) => (rec === 'null' ? null : parseInt(rec)))
                const nivel = nivelControleItens.find((rec) =>
                  values[2] == null || isNaN(values[2]) || values[2] == 1
                    ? rec.cod_especie === values[1]
                    : rec.cod_especie === values[1] && rec.cod_variacao_especie === values[2]
                )

                const nomesTotalizados = item.especieVariacao.split(', ')
             

                let tempTalhaoes = []
                dataBooking.codTalhao?.forEach(
                  (d) =>
                    (tempTalhaoes = [
                      ...tempTalhaoes,
                      ...item.data.filter((rec) => d === rec.codTalhao),
                    ])
                )

                const dadosGroundOverlay: TypeBookMonitoramento['data'] =
                dataBooking?.codTalhao === undefined || dataBooking?.codTalhao?.length === 0 ? item.data : tempTalhaoes

                //gerar subcapa com mapa todo
                const overlayAll = returnOverlayView?.filter(
                  (f) => f.praga === item.especieVariacao
                )
                const allPolygon = overlayAll.map((m) => m.polygon)
                const polygon = new L.Polygon(allPolygon, { weight: 0, fillOpacity: 1 })

                const fitBounds = polygon.getBounds()
                return (
                  <>
                    {ordenarTalhao == true ? null : (
                      <SubCapa className='break'>
                        <div className='sub-capa-title'>
                          <span>Mapa de pragas</span>
                          {totalizarBook === true && values[3] === 1 ? (
                            <>
                              <h2>{nomesTotalizados[0]?.toLocaleLowerCase()}</h2>{' '}
                              <h3>
                                {nomesTotalizados[2] === '1'
                                  ? null
                                  : nomesTotalizados[2].toLowerCase()}
                              </h3>
                            </>
                          ) : (
                            <>
                              <h2>{nivel.especie_nome?.toLocaleLowerCase()}</h2>{' '}
                              <h3>{nivel.variacao_especie_nome?.toLowerCase()}</h3>
                            </>
                          )}
                        </div>

                        <div style={{ width: '831px', height: '555px' }}>
                          <MapContainer
                            scrollWheelZoom={false}
                            dragging={false}
                            style={{ height: '555px' }}
                            id={`map${i}`}
                            bounds={fitBounds}
                            zoomControl={false}
                          >
                            <TileLayer
                              url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                              maxZoom={20}
                              subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                            />

                            {overlayAll.map((ov) => {
                              const markers = dadosGroundOverlay.find((rec) => rec.key === ov.key)

                              if (ov.url !== null) {
                                return (
                                  <>
                                    <ImageOverlay url={ov.url} bounds={ov.boundsL} />
                                    {markers?.idw?.map((rec) => {
                                      return (
                                        // @ts-ignore
                                        <LMarker
                                          opacity={markerOpacitySubCapa ? 0 : 1}
                                          ref={(data) =>
                                            data?.bindTooltip(Number(rec[4]).toFixed(1), {
                                              permanent: true,
                                              sticky: false,
                                              opacity: markerOpacitySubCapa ? 0 : 1,
                                              direction: 'top',
                                              className: 'leaflet-tooltip',
                                            })
                                          }
                                          icon={
                                            new L.Icon({
                                              iconUrl: pin,
                                              iconSize: [40, 50],
                                              iconAnchor: [20, 50],
                                              tooltipAnchor: [0, -16],
                                            })
                                          }
                                          position={{ lat: rec[0], lng: rec[1] }}
                                        />
                                      )
                                    })}
                                  </>
                                )
                              } else {
                                return (
                                  <>
                                    <LPolygon
                                      positions={ov.polygon}
                                      pathOptions={{
                                        opacity: 0,
                                        fillOpacity: 1,
                                        fillColor: ov.cor,
                                      }}
                                    />
                                    {markers?.idw?.map((rec) => {
                                      return (
                                        // @ts-ignore
                                        <LMarker
                                          ref={(data) =>
                                            data?.bindTooltip(Number(rec[4]).toFixed(1), {
                                              permanent: true,
                                              sticky: false,
                                              opacity: markerOpacitySubCapa ? 0 : 1,
                                              direction: 'top',
                                              className: 'leaflet-tooltip',
                                            })
                                          }
                                          opacity={markerOpacitySubCapa ? 0 : 1}
                                          icon={
                                            new L.Icon({
                                              iconUrl: pin,
                                              iconSize: [40, 50],
                                              iconAnchor: [20, 50],
                                              tooltipAnchor: [0, -16],
                                            })
                                          }
                                          position={{ lat: rec[0], lng: rec[1] }}
                                        />
                                      )
                                    })}
                                  </>
                                )
                              }
                            })}

                            <LPolygon
                              fillOpacity={0}
                              pathOptions={{ color: '#ffffff' }}
                              positions={allPolygon}
                            />
                          </MapContainer>
                        </div>
                      </SubCapa>
                    )}
                    {resumir == true
                      ? null
                      : dadosGroundOverlay?.map((rec, index) => {
                          // const fitBounds = getBoundsNoArray(rec.polygon)
                          const menorValorEstadios = rec.estadios.reduce(
                            (menor, atual) => (atual < menor ? atual : menor),
                            rec.estadios[0]
                          )
                          const maiorValorEstadios = rec.estadios.reduce(
                            (maior, atual) => (atual > maior ? atual : maior),
                            rec.estadios[0]
                          )

                          const estadiosFormatados = `${menorValorEstadios} a ${maiorValorEstadios}`

                          const overlay = returnOverlayView.filter((re) => re.key === rec.key)

                          const polygonFit = new L.Polygon(rec.polygon, {})
                          const fitBounds = polygonFit.getBounds()

                          //alterar aqui
                          return (
                            <>
                              <Content zIndex={index + 1} className='break'>
                                <div
                                  style={{
                                    width: '100%',
                                    height: '100%',
                                    overflow: 'hidden',
                                    position: 'relative',
                                  }}
                                >
                                  <MapContainer
                                    scrollWheelZoom={false}
                                    dragging={false}
                                    zoomControl={false}
                                    bounds={fitBounds}
                                    boundsOptions={{ paddingTopLeft: [0, 250] }}
                                    style={{
                                      width: '297mm',
                                      height: '210mm',
                                    }}
                                  >
                                    <TileLayer
                                      url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
                                      maxZoom={20}
                                      subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
                                      zIndex={30}
                                    />
                                    <LPolygon
                                      pathOptions={{ color: '#ffffff', fillOpacity: 0 }}
                                      positions={rec.polygon}
                                    />
                                    {overlay?.map((ov) => {
                                      if (ov.url !== null) {
                                        return <ImageOverlay url={ov.url} bounds={ov.boundsL} />
                                      } else {
                                        return (
                                          <LPolygon
                                            positions={ov.polygon}
                                            pathOptions={{
                                              fillOpacity: 1,
                                              fillColor: ov.cor,
                                              color: '#ffffff',
                                            }}
                                          />
                                        )
                                      }
                                    })}
                                    {rec.idw.map((rec) => {
                                      return (
                                        // @ts-ignore
                                        <LMarker
                                          ref={(data) =>
                                            data?.bindTooltip(Number(rec[4]).toFixed(1), {
                                              permanent: true,
                                              sticky: false,
                                              direction: 'top',
                                              className: 'leaflet-tooltip',
                                            })
                                          }
                                          icon={
                                            new L.Icon({
                                              iconUrl: pin,
                                              iconSize: [40, 50],
                                              iconAnchor: [20, 50],
                                              tooltipAnchor: [0, -10],
                                            })
                                          }
                                          position={{ lat: rec[0], lng: rec[1] }}
                                        />
                                      )
                                    })}
                                    <ContentMap>
                                      <Descriptions
                                        layout='vertical'
                                        bordered
                                        style={{ width: '100%', padding: '0px' }}
                                        size='small'
                                        column={8}
                                        labelStyle={{
                                          fontWeight: 'bold',
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                        contentStyle={{
                                          display: 'flex',
                                          justifyContent: 'center',
                                        }}
                                      >
                                        <Descriptions.Item
                                          label='Talhão'
                                          contentStyle={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          {rec.talhao}
                                          <small style={{ opacity: 0.8 }}>Área: {rec.area}ha</small>
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          label='Espécie variação'
                                          contentStyle={{
                                            display: 'flex',
                                            flexDirection: 'column',
                                          }}
                                        >
                                          {nivel?.especie_nome}
                                          {totalizarBook === true ? (
                                            <small style={{ opacity: 0.8 }}>
                                              {nomesTotalizados[2] === '1'
                                                ? null
                                                : nomesTotalizados[2]}
                                            </small>
                                          ) : (
                                            <small style={{ opacity: 0.8 }}>
                                              {nivel.variacao_especie_nome}
                                            </small>
                                          )}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Min'>{rec.min}</Descriptions.Item>
                                        <Descriptions.Item label='Méd'>{rec.med}</Descriptions.Item>
                                        <Descriptions.Item label='Máx'>{rec.max}</Descriptions.Item>
                                        <Descriptions.Item label='Pontos'>
                                          {rec.idw.length}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Estadios'>
                                          {estadiosFormatados}
                                        </Descriptions.Item>
                                        <Descriptions.Item label='Inverter escala'>
                                          {nivel?.inverter_escala === 1 ? 'Sim' : 'Não'}
                                        </Descriptions.Item>
                                        <Descriptions.Item
                                          className='label-vazia'
                                          labelStyle={{ padding: 0 }}
                                        >
                                          <Escala>
                                            {nivel?.inverter_escala === 1 ? (
                                              <>
                                                <div>
                                                  <div style={{ backgroundColor: '#FCFF02' }} />
                                                  <div style={{ backgroundColor: '#BAFF04' }} />
                                                  <div style={{ backgroundColor: '#01FF00' }} />
                                                </div>
                                                <div style={{ height: '20px' }}>
                                                  <div>Pior caso</div>
                                                  <div>Melhor caso</div>
                                                </div>
                                              </>
                                            ) : (
                                              <>
                                                <div>
                                                  <div style={{ backgroundColor: '#01FF00' }} />
                                                  {/* <div style={{ backgroundColor: '#BAFF04' }} /> */}
                                                  <div style={{ backgroundColor: '#FCFF02' }} />
                                                  <div style={{ backgroundColor: '#FCB700' }} />
                                                  <div style={{ backgroundColor: '#FB0303' }} />
                                                </div>
                                                <div className='legend-scale'>
                                                  <div>Abaixo do NC</div>
                                                  <div>Nível de controle</div>
                                                  <div>Acima do NC</div>
                                                </div>
                                                {rec.talhao_observacao && (
                                                  <div>
                                                    <strong>Observação:</strong> <br />
                                                    {rec.talhao_observacao}
                                                  </div>
                                                )}
                                              </>
                                            )}
                                          </Escala>
                                        </Descriptions.Item>
                                      </Descriptions>
                                    </ContentMap>
                                    <WaterMarker placement='bottomRight' />
                                  </MapContainer>
                                </div>
                              </Content>
                            </>
                          )
                        })}
                  </>
                )
              })}
            </>
           
          </div>
        </PdfPrint>
      </Drawer>
      <CanvasInterpolation
        dataGroundOverlay={geraGroundOverlay}
        onOverlayView={setReturnOverlayView}
      />
    </Container>
  )
}
export default BookMonitoramento