/* eslint-disable no-nested-ternary */
import React, { useEffect, useRef, useState } from 'react'

import { useHistory } from 'react-router-dom'

import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { Button, Drawer, message, Modal, Select, Table, Timeline } from 'antd'

import { CloseOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadChangeParam } from 'antd/lib/upload'
import ExclamationError from '../../../../assets/img/exclamation-error.png'
import Exclamation from '../../../../assets/img/exclamation.png'
import FormOrderSer from '../../../../Components/ordemLaboratorio'

import { Notification } from '../../../../Components/notification'

import { useLogic as useLogicOrder } from './useLogic'

import { useLogic } from '../../../../context/useLogic'

import {
  delPedLaboratorio,
  getDownloadResult,
  getPedLaboratorio,
  getPedLaboratorioStatus,
  putPedlaboratorioResultado,
  putPedlaboratorioStatus,
  reprocessarOs,
} from '../../../../services/Laboratorio'
import { Container } from './styled'

import fileBase64 from '../../../../Components/Base64Geral'
import { pagination } from '../../../../Components/Default'
import { IconAlert } from '../../../../Components/iconsAlert'
import ViewPage from '../../../../Components/ViewPage'
import { localConfig } from '../../../../Configs/localConfig'
import { CSVToJSON, fileTypeText } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { getSafraConsultoria } from '../../../../services/Safra'
import dayjs from 'dayjs'
import GeneratePdfButton from '../../../../Components/relatorio-component'
import { Render } from '../../../../Components/CustomUi/render'

const { Option } = Select

const OrderSer: React.FC = () => {
  const { checkSafra } = useLogic()
  const { columLabOrder } = useLogicOrder()

  const refDowloadResult = useRef(null)

  const history = useHistory()

  const [isModalVisible, setIsModalVisible] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisibleForm] = useState(false)
  const [modalTimeLine, setModalTimeLine] = useState(false)
  const [addStatus, setAddStatus] = useState(false)
  const [looadingSaveStatus, setlooadingSaveStatus] = useState(false)
  const [drawerFilePrevia, setDrawerFilePrevia] = useState(false)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [updateUpdateOrder, setUpdateUpdateOrder] = useState<number>()
  const [cloneOrder, setCloneOrder] = useState<number>()
  const [numPedido, setNumPedido] = useState<number>()

  const [selectStatus, setSelectStatus] = useState(null)
  const [colum, setColum] = useState(null)
  const [resultAnalise, setResultAnalise] = useState({
    base64: null,
    cod: null,
  })

  const [dataOrderSer, setDataOrderSer] = useState([])
  const [oldDataOS, setOldDataOS] = useState([])
  const [dataStatusOs, setDataStatusOs] = useState([])
  const [lisStatusOs, setLisStatusOs] = useState([])
  const [dataTableAnalise, setDataTableAnalise] = useState([])
  const [dataSafraConsultoria, setDataSafraConsultoria] = useState([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [nomeCliente, setNomeCliente] = useState('')
  const codSafra = localStorage.getItem('codSafra')

  const listarOrdensServico = async () => {
    setLoadingTable(true)
    const safras = await getSafraConsultoria()
    const response = await getPedLaboratorio('')
    const dados = response.data.itens

    setDataSafraConsultoria(safras.data.itens)
    setNomeCliente(dados[0]?.nome_cliente)
    gerarTabela(dados)
    gerarTabela2(dados, dados.length)
    setDataOrderSer(dados)
    gerarTabela(dados)
    setOldDataOS(dados)

    setLoadingTable(false)
  }

  function gerarTabela(dados) {
    const data = dados?.map((item) => {
      return [
        { text: item?.propriedade_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.talhao_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.safra_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.laboratorio_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: dayjs(item?.data).format('DD/MM/YYYY'),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*', '*'],
          body: [
            [
              { text: 'Propriedade', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Talhão', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Safra', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Laboratório', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Data', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
          dontBreakRows: false, 
          keepWithHeaderRows: 0,
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = [[{ text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' }]]

    const table = [
      {
        table: {
          headerRows: 1,
          widths: [95], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total os',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
          alignment: 'center',
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]

    setDadosResultado(table)
  }

  const handleTrashOrderSer = async (data, dados) => {
    Modal.confirm({
      title: 'Excluir',
      icon: <IconAlert type='confirm' size={6} />,
      content: `Deseja realmente remover esse Pedido de Análise ${dados.propriedade_nome}?`,
      okText: 'Confirmar',
      cancelText: 'Cancelar',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        try {
          const responseDel = await delPedLaboratorio(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Pedido de Analise Excluido',
              type: 'success',
            })

            listarOrdensServico()
          }
        } catch (error) {
          if (error.response) {
            const { data } = error.response

            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.error,
            })
          }
        }
      },
    })
  }

  const handleStatusOsColeta = async (pedido) => {
    message.loading('Carregando...', 99999)
    const response = await getPedLaboratorioStatus(pedido)

    const dados = response.data.itens

    setDataStatusOs(dados.reverse())

    const ultimo = dados[dados.length - 1].status

    handleListStatus(ultimo)

    setSelectStatus(null)

    setModalTimeLine(true)

    setNumPedido(pedido)
    message.destroy()
  }

  const handleClickAlteraStatus = async () => {
    setlooadingSaveStatus(true)
    const status = selectStatus

    const listaStatus = lisStatusOs.find((data) => data.cod === status)

    const response = await putPedlaboratorioStatus(numPedido, listaStatus.cod)

    if (response.status === 200) {
      listarOrdensServico()

      handleStatusOsColeta(numPedido)

      Notification({
        message: `Status da OS alterado para ${listaStatus.nome}`,
        type: 'success',
      })
      setlooadingSaveStatus(false)
    } else {
      Notification({
        message: `ERROR ${response.status}`,
        type: 'error',
      })
    }
  }

  const handleChangeStatus = (data) => {
    setSelectStatus(data)
  }

  const handleCloneData = (data, dados) => {
    const local = localConfig()

    if (local.codCliente === '') {
      if (dados.cod_cliente === null) {
        setCloneOrder(data)
        setVisibleForm(true)
        setUpdateUpdateOrder(0)
      } else {
        Modal.error({
          title: `Para clonar selecione o cliente correspondente: ${dados.cliente[0]?.nome}`,
        })
      }
    } else {
      setCloneOrder(data)
      setVisibleForm(true)
      setUpdateUpdateOrder(0)
    }
  }

  const handleVisibleForm = () => {
    setVisibleForm(true)
    setUpdateUpdateOrder(0)
    setCloneOrder(0)
  }

  const handleCloseForm = () => {
    setVisibleForm(false)
    setCloneOrder(0)
  }

  const handleClickUpdate = async (data, dados) => {
    const local = localConfig()
    if (local.codCliente === '') {
      if (dados.cod_cliente === null) {
        setUpdateUpdateOrder(data)
        setVisibleForm(true)
      } else {
        Modal.error({
          title: `Para editar selecione o cliente correspondente: ${dados.cliente[0]?.nome}`,
        })
      }
    } else {
      setUpdateUpdateOrder(data)
      setVisibleForm(true)
    }
  }

  const handleOk = () => {
    setIsModalVisible(false)
  }

  const handleCancel = () => {
    setIsModalVisible(false)
  }

  // const handleTableChange = (pagination, filters) => {
  //   if (filters.status === null) {
  //     listarOrdensServico()
  //   } else {
  //     let dados = []
  //     oldDataOS?.forEach((info) => {
  //       const temp = filters?.status?.forEach((data) => {
  //         if (info.status === data) {
  //           dados = [...dados, info]
  //         }

  //         return { dados, temp }
  //       })

  //       return dados
  //     })
  //     setDataOrderSer(dados)
  //   }
  // }

  const dumpRequest = (options) => {
    const { onSuccess } = options

    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const handleUploadResult = async (info: UploadChangeParam, cod: number) => {
    if (info.file.status === 'done') {
      message.loading('Carregando...', 9999)
      const csv = info.file.originFileObj

      const texCsv = await fileTypeText(csv)

      const base64 = await fileBase64(csv)

      const csvJson = await CSVToJSON(texCsv)

      setColum(csvJson.colum)
      setDataTableAnalise(csvJson.rows)

      setResultAnalise({ base64, cod })

      setDrawerFilePrevia(true)
      message.destroy()
    }
  }

  const handleDownloadResultado = async (url) => {
    const response = await getDownloadResult(url)
    const link = refDowloadResult.current

    // @ts-ignore
    const newLink = URL.createObjectURL(response.data)

    link.href = newLink
    link.click()
  }

  const handleReprocessarOs = async (
    data,
    cod_cliente,
    nome,
    talhao,
    codCalcario,
    codCalcarioPropriede
  ) => {
    Modal.confirm({
      title:
        codCalcario !== codCalcarioPropriede
          ? `O calcário atual é diferente do processado, deseja realmente reprocessar a OS?`
          : `Deseja reprocessar a OS da ${nome} - ${talhao}?`,
      okText: 'Sim',
      cancelText: 'Não',
      icon: <IconAlert type='confirm' size={6} />,
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        try {
          message.loading('Aguarde...', 999999)
          const response = await reprocessarOs(data, cod_cliente)

          if (response.status === 200) {
            Notification({
              message: 'OS reprocessada com sucesso!',
              type: 'success',
            })
            message.destroy()
            Modal.destroyAll()
          }
        } catch (error) {
          Modal.error({
            title: 'Erro',
            content: 'Ocorreu um erro ao reprocessar a OS.',
          })
          message.destroy()
        }
      },
    })
  }

  const handleEnviarFile = async () => {
    try {
      message.loading('Aguarde...', 99999)
      const response = await putPedlaboratorioResultado(resultAnalise.cod, resultAnalise.base64)

      if (response.status === 200) {
        message.destroy()
        Notification({
          message: 'Resultado enviado',
          type: 'success',
        })
        listarOrdensServico()
        setDrawerFilePrevia(false)
      }
      message.destroy()
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0]?.field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleAtualizado = (data) => {
    setUpdateUpdateOrder(data)
    listarOrdensServico()
  }

  const handleClikAlert = async (osCod) => {
    const tipoModal = osCod.status === 8 ? Modal.error : Modal.warning

    const obs = String(osCod.observacao)

    tipoModal({
      title:
        osCod.status !== 8
          ? `Resultado com inconsistência na OS ${osCod.cod}`
          : `Erro na OS ${osCod.cod}`,
      icon: (
        <span className='anticon anticon-close-circle'>
          <img
            width='25px'
            src={osCod.status === 8 ? ExclamationError : Exclamation}
            alt='Warning'
          />
        </span>
      ),
      content: <div style={{ fontSize: '12px' }} dangerouslySetInnerHTML={{ __html: obs }} />,
      width: '800px',
      style: { top: '20px' },
      bodyStyle: { overflowY: 'scroll', maxHeight: 'calc(100vh - 200px)' },
    })
  }

  const handleListStatus = (cod) => {
    const status = [
      {
        nome: 'ABERTA',
        cod: 1,
        disable: false,
      },
      {
        nome: 'LIBERADA PARA COLETA',
        cod: 2,
        disable: false,
        hidden: process.env.NODE_ENV === 'production',
      },
      {
        nome: 'COLETADA',
        cod: 3,
        disable: false,
      },
      {
        nome: 'ENVIADO PARA ANÁLISE',
        cod: 4,
        disable: false,
      },
      {
        nome: 'EM ANÁLISE',
        cod: 5,
        disable: false,
      },

      {
        nome: 'INCONSISTÊNCIA MODERADA',
        cod: 6,
        disable: false,
        hidden: process.env.NODE_ENV === 'production',
      },
      {
        nome: 'INCONSISTÊNCIA ALTA',
        cod: 7,
        disable: false,
        hidden: process.env.NODE_ENV === 'production',
      },
      {
        nome: 'RESULTADO COM ERRO',
        cod: 8,
        disable: false,
        hidden: process.env.NODE_ENV === 'production',
      },
      {
        nome: 'ANALISADA',
        cod: 9,
        disable: false,
        // hidden: process.env.NODE_ENV === "production",
      },
    ]

    if (process.env.NODE_ENV === 'production') {
      status.forEach((data) => {
        if (data.cod <= cod || data.cod === 5) {
          data.disable = true
        }
      })
    }

    setLisStatusOs(status)
  }

  const handleAddStatus = () => {
    setAddStatus(!addStatus)
  }

  useEffect(() => {
    const idDaSafra = checkSafra('/precisao/laborder')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])
  useEffect(() => {
    if (codSafra !== null) {
      listarOrdensServico()
    }
   
  }, [codSafra])
  return (
    <Container>
      <ViewPage
        onPermission={setPermissions}
        rotina={32}
        title='OS para coleta'
        btnClick={handleVisibleForm}
        btnsExta={
          <div>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório Os'}
                disable={dataOrderSer.length <= 0}
                totais={dadosResultado}
                titleSecundario={nomeCliente}
              />
            ) : null}
          </div>
        }
        content={
          <Render condition={visibleForm}>
            <FormOrderSer
              permissions={permissions}
              visible={visibleForm}
              setOnClose={handleCloseForm}
              update={updateUpdateOrder}
              setUpdate={handleAtualizado}
              clone={cloneOrder}
            />
          </Render>
          
        }
      >
        <Table
          rowKey='cod'
          pagination={pagination}
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataOrderSer.length}</b>
            </div>
          )}
          // onChange={handleTableChange}
          loading={loadingTable}
          dataSource={dataOrderSer}
          bordered
          size='small'
          columns={columLabOrder({
            dataOrderSer,
            dataSafraConsultoria,
            dumpRequest,
            handleClickUpdate,
            handleClikAlert,
            handleCloneData,
            handleDownloadResultado,
            handleStatusOsColeta,
            handleTrashOrderSer,
            handleUploadResult,
            handleReprocessarOs,
            permissions,
          })}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickUpdate(record.cod, record)
              },
            }
          }}
        />
      </ViewPage>
      <Modal
        style={{ minHeight: '100%' }}
        title='Basic Modal'
        open={isModalVisible}
        onOk={handleOk}
        onCancel={handleCancel}
        width='100%'
        destroyOnClose
      >
        <div style={{ width: '50%' }} />
      </Modal>
      <Drawer
        title='Visualizar Arquivo'
        destroyOnClose
        height='89%'
        placement='bottom'
        open={drawerFilePrevia}
        closeIcon={false}
        onClose={() => setDrawerFilePrevia(false)}
        footer={
          <>
            <Button onClick={handleEnviarFile} type='primary' htmlType='submit'>
              Enviar
            </Button>
            {'   '}
            <Button
              danger
              type='primary'
              onClick={() => setDrawerFilePrevia(false)}
              style={{ marginRight: 8 }}
            >
              Fechar
            </Button>
          </>
        }
      >
        <Table
          className='head-visu'
          size='small'
          rowClassName={(record, index) => (index % 2 === 0 ? 'table-row-light' : 'table-row-dark')}
          scroll={{ x: '5000px', y: '60vh' }}
          columns={colum}
          dataSource={dataTableAnalise}
        />
      </Drawer>
      <a href='#' hidden ref={refDowloadResult}>
        a
      </a>
      <Modal
        title={`Status do Pedido ${numPedido}`}
        open={modalTimeLine}
        width={600}
        destroyOnClose
        onCancel={() => {
          setModalTimeLine(false)
          setAddStatus(false)
        }}
        style={{ top: '50px' }}
        bodyStyle={{
          paddingTop: '10px',
          maxHeight: '500px',
          flexDirection: 'column-reverse',
          overflowX: 'hidden',
          overflowY: 'auto',
        }}
        okButtonProps={{
          hidden: true,
        }}
        cancelButtonProps={{
          hidden: true,
        }}
        footer={[
          <div key='' style={{ width: '100%', display: 'flex', justifyContent: 'left', gap: 5 }}>
            <Button
              onClick={handleAddStatus}
              icon={addStatus ? <CloseOutlined /> : <PlusOutlined />}
              type='primary'
            >
              Alterar Status
            </Button>
            {'  '}
            {addStatus ? (
              <>
                <Select
                  value={selectStatus}
                  onChange={handleChangeStatus}
                  placeholder='Selecione o Status'
                  popupMatchSelectWidth
                  allowClear
                  size='middle'
                  optionFilterProp='children'
                  showSearch
                  style={{ width: '60%', textAlign: 'left' }}
                >
                  {lisStatusOs.map((data) => (
                    <Option
                      style={data.hidden ? { display: 'none' } : { display: 'block' }}
                      // disabled={data.disable}
                      key={data.cod}
                      value={data.cod}
                    >
                      {data.nome}
                    </Option>
                  ))}
                </Select>
                {'  '}
                <Button
                  disabled={selectStatus === null}
                  onClick={handleClickAlteraStatus}
                  type='primary'
                  size='middle'
                  icon={<FontA icon={solid('save')} />}
                  loading={looadingSaveStatus}
                />
              </>
            ) : (
              <>
                <b style={{ opacity: 0 }}>a</b>
              </>
            )}
          </div>,
        ]}
      >
        <Timeline mode='left'>
          {dataStatusOs.map((data, i) => {
            let color
            let arrow
            const old = i === 0 ? i : i - 1

            data.usuario_nome = String(data.usuario_nome).toLowerCase()

            if (data.status < dataStatusOs[old].status) {
              color = 'red'
              arrow = solid('arrow-alt-circle-left')
            } else {
              color = 'green'
              arrow = solid('arrow-alt-circle-right')
            }

            const dataHora = (
              <div>
                <p style={{ marginBottom: 0 }}>
                  {data.create_time.substr(0, 10).split('-').reverse().join('/') +
                    data.create_time.substr(10, 18)}
                </p>
                <p
                  className='capitalized'
                  style={{ fontSize: '10px', marginTop: 0, paddingTop: 0 }}
                >
                  {data.usuario_nome}
                </p>
              </div>
            )
            return (
              <Timeline.Item
                key=''
                dot={<FontA icon={arrow} className='timeline-Arrow-icon' />}
                color={color}
                label={dataHora}
              >
                {data.status_nome.toUpperCase()}
              </Timeline.Item>
            )
          })}
        </Timeline>
      </Modal>
    </Container>
  )
}

export default OrderSer
