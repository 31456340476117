import styled from 'styled-components'

export const Container = styled.div`
  .ant-drawer-body {
    padding: 0;
  }
`
export const Tools = styled.div`
  position: absolute;
  display: flex;
  justify-content: end;
  width: 20rem;
  height: 90vh;
  right: 0;
  margin: 1rem;
`
