import styled from 'styled-components'

export const Container = styled.div`
  display: flex;

  #printable {
    table {
      font-size: 12px;
      border: 1px solid #454545;
      margin: 0px;
      min-width: 90%;

      tr {
        border: 1px solid #454545;
      }

      td,
      th {
        padding: 4px;
      }
    }
  }

  @media print {
    @page {
      size: A4;
    }
    body {
      visibility: hidden;
    }

    table {
      page-break-inside: auto;
    }
    thead {
      padding: 10px;
    }

    tfoot {
    }

    #printable {
      margin: 40px;
    }

    #printable,
    #printable * {
      visibility: visible;
    }
  }
`
