import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeSubArea {
  itens: [SubAreaItens]
}

export interface SubAreaItens {
  cod?: number
  cod_conta?: number
  cod_propriedade?: number
  area?: string
  arquivo?: string
  nome?: string
  status?: 0 | 1
  kml?: {
    nome?: string
    lookAt?:
    | {
      latitude?: number
      longitude?: number
    }
    | google.maps.LatLng
    coordenadas?:
    | {
      latitude?: number
      longitude?: number
    }[]
    | google.maps.LatLng[]
    | google.maps.LatLngLiteral[]
  }
  cod_safra?: number
  cod_talhao?: number
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  codSafra?: number
  codPropriedade?: number
  embed?: string,
  codTalhao?: number
  pagination?: number | -1
  nome?: string
  order?: string
  cod?: number
}

export const getSubArea = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  const { codCliente, conta, acesso } = localConfig()

  api.defaults.headers.Acesso = acesso

  params = {
    pagination,
    codConta: conta,
    codCliente,
    ...params,
  }

  const response = api.get('/sub-area-talhao', {
    params,
  })

  return response
}

export const postSubArea = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.post('/sub-area-talhao', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'variacao',
    },
  })

  return response
}

export const putSubArea = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.put(`/sub-area-talhao/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const delSubArea = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response = api.delete(`/sub-area-talhao/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
