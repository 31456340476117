import React, { useEffect, useState } from 'react'
import { getAniversariantes } from '../../services/aniversariantes'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faGift } from '@fortawesome/free-solid-svg-icons'
import { CardNiver } from './styled'
import ButtonCustom from '../ButtonCustom'

import dayjs from 'dayjs'
import ListaAniversariantes from './components/lista_aniversariantes'
import { orderBy } from 'lodash'
import { Switcher } from '../CustomUi/Switcher'

interface PropsCardAniver {
  abrir?: boolean
  onClose?: () => void
}

const AniversariantesCard: React.FC<PropsCardAniver> = () => {
  const conta = localStorage.getItem('conta') === undefined ? '' : localStorage.getItem('conta')
  const codCliente = conta !== '' ? '' : localStorage.getItem('cod-cliente')

  const [dados, setDados] = useState([])
  const [mostrar, setMostrar] = useState(false)
  const [loading, setLoading] = useState(true)

  async function listarAniversariantes(dataInicio) {
    const response = await getAniversariantes(codCliente, conta, dataInicio, '')
    setDados(response.data.itens)
  }

  function exibirMais() {
    setMostrar((prevState) => !prevState)
  }

  const ordenarAniversariantes = (aniversariantes) => {
    let hoje = dayjs().format('MM-DD')
    let aniversario = aniversariantes
      ?.filter((item) => item?.nascimento !== null)
      .map((rec) => {
        return {
          ...rec,
          tipo: 'nascimento',
          referencia: dayjs(rec?.nascimento).format('MM-DD'),
          dataBase: rec?.nascimento,
          hoje: dayjs(rec?.nascimento).format('MM-DD') === hoje,
        }
      })
    let aniversarioEmpresa = aniversariantes
      ?.filter((item) => item?.admissao !== null)
      .map((rec) => {
        return {
          ...rec,
          tipo: 'admissao',
          referencia: dayjs(rec?.admissao).format('MM-DD'),
          dataBase: rec?.admissao,
          hoje: dayjs(rec?.admissao).format('MM-DD') === hoje,
        }
      })

    let mesclar = [...aniversario, ...aniversarioEmpresa]

    let ordenarPorData = orderBy(mesclar, 'referencia', ['asc'])

    let refOrdem = ordenarPorData.map((item, index) => {
      return {
        ...item,
        ordem: item?.dataBase <= hoje ? index + 100 : index,
      }
    })

    let ordemFinal = orderBy(refOrdem, 'ordem', ['asc'])

    return ordemFinal
  }

  useEffect(() => {
    const hoje = dayjs().format('YYYY-MM-DD')
    listarAniversariantes(hoje)
  }, [])

  useEffect(() => {
    setTimeout(() => {
      setLoading(false)
    }, 800)
  }, [])

  return (
    <CardNiver>
      <div className='header__principal'>
        <div className='header__titulo'>
          <FontAwesomeIcon icon={faGift} style={{ fontSize: '16px', color: '#2D3436' }} />
          <h2>Aniversariantes do mês</h2>
        </div>
      </div>
      <div className='campo__aniversariantes' data-open={ordenarAniversariantes(dados).length <= 0} data-loading={loading}>
        <ListaAniversariantes
          dados={ordenarAniversariantes(dados)}
          loading={loading}
          setLoading={setLoading}
        />
      </div>
    </CardNiver>
  )
}

export default AniversariantesCard
