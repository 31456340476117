import { Modal, Select } from 'antd'
import React, { createContext, useContext, useEffect, useState } from 'react'
import { localConfig } from '../Configs/localConfig'
import { api } from '../services/api'

import { IconAlert } from '../Components/iconsAlert'
import { TypesForecast } from '../services/apiClima'
import { ClienteItensTypes } from '../services/Cliente'
import { SafraItens } from '../services/Safra'
import dayjs from 'dayjs'
import { display } from 'html2canvas/dist/types/css/property-descriptors/display'

export interface Itens {
  cod?: number
  nome?: string
  email?: string
  status?: number
  acesso_admin?: number
  cod_laboratorio?: number
  sexo?: string
  doc_foto_perfil?: string
  acessoConta?: {
    contas?: {
      cod?: number
      logo?: string
      nome?: string
      clientes?: {
        cod?: number
        nome?: string
        logo?: string
      }[]
      acesso?: string
      temAcesso?: boolean
    }[]
    clientes?: {
      cod?: number
      cod_conta?: number
      nome?: string
      acesso?: string
    }[]
  }
  hash?: string
}

interface AcessTypes {
  itens: Itens[]
  setItens: (item: Itens[]) => void
  responsive: boolean
  setResponsive: (responsive) => void
  typeAccess: 'cliente' | 'conta'
  setTypeAccess: (responsive) => void
  navegacao: any[]
  setNavegacao: (data: any[]) => void
  acesso: 'conta' | 'cliente' | 'lab'
  setAcesso: (data: 'conta' | 'cliente' | 'lab') => void
  setClienteTemAcesso: (data) => void
  lisSafra: SafraItens[]
  setLisSafra: (data: SafraItens[]) => void
  checkSafra: (data: string) => boolean
  dataCliente: ClienteItensTypes[]
  setDataCliente: (data: ClienteItensTypes[]) => void
  dataConta: any[]
  setDataConta: (data: any[]) => void
  dados_cliente: any;
  setDados_cliente: any;
  safraSelecionada: number
  setSafraSelecionada: (data: number) => void
  rota: string
  setRota: (rota) => void
  dataClima?: TypesForecast
  setDataClima: (rota) => void
  codCliente?: number
  setCodCliente: (data: number) => void
  clienteTemAcesso?: Itens[]
  getLocation?: {
    hash: string
    host: string
    hostname: string
    href: string
    origin: 'https://ttivos.app' | 'https://test.ttivos.app'
    pathname: string
    port: string
    protocol: string
    search: string
  }
  setLocation?: (data: any) => void
  hash?: string
  setHash?: (data: string) => void
}

export const LogicContext = createContext<AcessTypes>({} as AcessTypes)
// const LogicContext = createContext<AcessTypes>({} as AcessTypes)

const { Option } = Select

export const LogicProvider: React.FC = ({ children }) => {
  const [itens, setItens] = useState<Itens[]>([] as Itens[])
  const [responsive, setResponsive] = useState()
  const [typeAccess, setTypeAccess] = useState()
  const [getLocation, setLocation] = useState<any>()
  const [navegacao, setNavegacao] = useState([])
  const [codCliente, setCodCliente] = useState<number>()
  const [acesso, setAcesso] = useState<'conta' | 'cliente' | 'lab'>(null)
  const [clienteTemAcesso, setClienteTemAcesso] = useState<Itens[]>([
    {
      acessoConta: {
        contas: [],
      },
    } as unknown as Itens,
  ])
  const [hash, setHash] = useState('')
  const [lisSafra, setLisSafra] = useState([])
  const [safraSelecionada, setSafraSelecionada] = useState<number>()
  const [dataCliente, setDataCliente] = useState([])
  const [dataConta, setDataConta] = useState([])
  const [dataClima, setDataClima] = useState({})
  const [rota, setRota] = useState<string>();
  const [dados_cliente, setDados_cliente] = useState<any>([]);

  const handleChangeSafra = (id, data) => {
    setSafraSelecionada(id)
    Modal.destroyAll()
    setRota(data)
    localStorage.setItem('codSafra', id)
  }

  const checkSafra = (data) => {
    const { codSafra } = localConfig()

    setRota(data)

    if (codSafra === null || codSafra === 'undefined') {
      Modal.confirm({
        title: 'Selecione uma Safra',
        icon: <IconAlert type='warning' size={6} />,
        type: 'warning',
        okCancel: false,
        okText: 'Sair',
        okButtonProps: {
          type: 'primary',
          danger: true,
        },
        content: (
          <Select
            onChange={(id) => handleChangeSafra(id, data)}
            placeholder='Selecione uma Safra'
            style={{ width: '300px' }}
          >
            {lisSafra.map((data) => (
              <Option key={data.cod} value={data.cod} >
                {data.nome}
                <small style={{ color: '#ccc', fontSize: '10px' ,display: 'flex', flexDirection: 'column', marginBottom: '4px'  }}>
                  Vigência {dayjs(data.data_final).format('DD/MM/YYYY')}
                </small>
              </Option>
            ))}
          </Select>
        ),
      })
    }

    return codSafra !== null
  }

  useEffect(() => {
    if (itens[0]?.hash.length > 20) {
      setHash(itens[0]?.hash)
    }
    if (itens[0]?.hash !== undefined) {
      api.defaults.headers.Authorization = `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${itens[0]?.hash}`
    }
  }, [itens])

  useEffect(() => {
    if (hash.length > 20) {
      api.defaults.headers.Authorization = `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${hash}`
    }
  }, [hash])

  return (
    <LogicContext.Provider
      value={{
        itens,
        setItens,
        responsive,
        setResponsive,
        acesso,
        setAcesso,
        typeAccess,
        setTypeAccess,
        navegacao,
        setNavegacao,
        clienteTemAcesso,
        setClienteTemAcesso,
        hash,
        setHash,
        lisSafra,
        setLisSafra,
        dataConta,
        setDataConta,
        dataCliente,
        setDataCliente,
        checkSafra,
        safraSelecionada,
        setSafraSelecionada,
        rota,
        setRota,
        setDataClima,
        dataClima,
        setCodCliente,
        codCliente,
        getLocation,
        setLocation,
        dados_cliente,
        setDados_cliente
      }}
    >
      {children}
    </LogicContext.Provider>
  )
}

export const useLogic = () => {
  const context = useContext(LogicContext)

  return context
}
