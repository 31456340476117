import { AxiosResponse } from 'axios'
import { api } from '../api'
import { message } from 'antd'

export interface TypesElementos {
  itens: ItensElementosTypes[]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface ItensElementosTypes {
  cod: number
  nome: string
  descricao: string
  ordem: number
  create_time?: string
  update_time?: string
  delete_time?: string
  unidade: string
  uso_mapa: boolean,
  novo_index?: number | string,
  origem?: string,
}

type TypesQuery = {
  cod?: number
  embed?: string
  pagination?: number
  page?: number
  elementoUsoMapa?: boolean
  ordem?: number
}

const url = '/ap-elemento-fertilidade'

export const getElementos = async (params: TypesQuery, acesso: 'conta' | 'admin' = 'admin') => {
  const { pagination = -1 } = params
  const resp: AxiosResponse<TypesElementos> = await api.get(url, {
    params: {
      pagination,
      ...params,
    },
    headers: {
      Acesso: acesso,
    },
  })

  return resp
}

export const postElementos = async (params: TypesQuery) => {
  api.defaults.headers.Acesso = 'admin'
  const resp: AxiosResponse<TypesElementos> = await api.post(url, {
    params: {
      ...params,
    },
  })

  return resp
}
export const putElementos = async (params: TypesQuery) => {
  
  const { cod } = params
  api.defaults.headers.Acesso = 'admin'
  try {
    // console.log("params: ", params);
    message.loading({ content: 'Atualizando...', key: 'loading' });
    const resp: AxiosResponse<TypesElementos> = await api.put(`${url}/${cod}`, {
      ...params,
    })
      // message.success('Ordem dos elementos atualizada!', 2.5);
      message.success({ content: 'Ordem dos elementos atualizada!', key: 'loading' });
    return resp
  }
  catch {
    message.error('Falha ao ordenar!', 2.5);
  }
  
}
