import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import {
  ClockCircleOutlined,
  EditOutlined,
  LockOutlined,
  LogoutOutlined,
  SettingOutlined,
} from '@ant-design/icons'
import { Avatar, Popover, Upload, Typography, Modal, message } from 'antd'

//APP
import { useLogic, Itens } from '../../context/useLogic'

//STYLE
import { Container, ContainerForm, PopoverOpcoes } from './styled'

//ASSETS
import cley from '../../assets/img/cley.jpg'
import {
  faFileLines,
  faFloppyDisk,
  faList,
  faLocationDot,
  faLock,
  faPhone,
  faSignInAlt,
  faUpload,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import ButtonCustom from '../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import Svg from '@ant-design/icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import Tabs from '../Tabs'
import FormLogin from './components/formLogin'
import FormUsuario from './components/formUsuario'
import FormEndereco from './components/formEndereco'
import { useAccess } from '../../context/useAccess'
import {
  getPerfilUsuario,
  postCadastroPerfil,
  postVerificarSenha,
} from '../../services/editarPerfil'
import ImgCrop from 'antd-img-crop'
import { UploadProps } from 'antd/lib'
import { UploadChangeParam } from 'antd/es/upload'
import FormDocumento from './components/formDocumento'
import FormSenha from './components/formSenha'
import { formatarData } from '../../services/Afins'

//TYPE
type TypeOpcoes = {
  id: number
  icone: React.ReactNode
  descricao: string
  onClick: Function
}

const DropUser: React.FC = () => {
  //CONST
  let history = useHistory()
  const { itens } = useLogic()
  const refFoto = useRef<UploadProps>({} as UploadProps)
  const opcoes_usuario: TypeOpcoes[] = [
    {
      id: 0,
      icone: <EditOutlined />,
      descricao: 'Editar Perfil',
      onClick: () => {
        setVisibleEditPerfil(true)
        setAlterarSenha(false)
      },
    },
    {
      id: 1,
      icone: <LockOutlined />,
      descricao: 'Alterar senha',
      onClick: () => {
        setVisibleEditPerfil(true)
        setAlterarSenha(true)
      },
    },
    // { id: 2, icone: <SettingOutlined />, descricao: 'Configurações', onClick: () => {} },
    {
      id: 3,
      icone: <LogoutOutlined />,
      descricao: 'Sair',
      onClick: () => {
        history.push('/logout')
      },
    },
  ]

  //STATES
  const [userLogado, setUserLogado] = useState<Itens>({} as Itens)
  const [visibleEditPerfil, setVisibleEditPerfil] = useState(false)
  const [loading, setLoading] = useState(false)

  const { listDefault } = useAccess()

  const [fileList, setFileList] = useState([])
  const [tab_container_atual, setTab_container_atual] = useState<string>('Contato')
  const [cidades, setCidades] = useState([])
  const [imgUser, setImgUser] = useState(null)
  const [alterarSenha, setAlterarSenha] = useState(false)

  //LOGIN
  // const [email, setEmail] = useState('')

  const [celular, setCelular] = useState('')

  //SENHA
  const [senha, setSenha] = useState<string>('')
  const [senhaAnterior, setSenhaAnterior] = useState('')
  const [novaSenha, setNovaSenha] = useState('')
  const [confirmarSenha, setConfirmarSenha] = useState('')
  const [senhaValidada, setSenhaValidada] = useState('')
  const [desabilitar, setDesabilitar] = useState(true)
  const [erroSenha, setErroSenha] = useState(false)
  const [erroSenhaAtual, setErroSenhaAtual] = useState(false)

  //USUARIO
  const [camisa, setCamisa] = useState('')
  const [camiseta, setCamiseta] = useState('')
  const [calcado, setCalcado] = useState('')
  const [calca, setCalca] = useState('')
  const [hobby1, setHobby1] = useState('')
  const [hobby2, setHobby2] = useState('')
  const [openCamisa, setOpenCamisa] = useState(false)
  const [openCamiseta, setOpenCamiseta] = useState(false)

  //ENDEREÇO
  const [cep, setCep] = useState('')
  const [cepOriginal, setCepOriginal] = useState('')
  const [cidade, setCidade] = useState('')
  const [cidadeId, setCidadeId] = useState(0)
  const [openCidade, setOpenCidade] = useState(false)
  const [logradouro, setLogradouro] = useState('')
  const [numero, setNumero] = useState('')
  const [complemento, setComplemento] = useState('')

  //DOCUMENTO
  const [cnh, setCnh] = useState('')
  const [categoriaCnh, setCategoriaCnh] = useState('')
  const [validadeCnh, setValidadeCnh] = useState('')
  const [documentoCnh, setDocumentoCnh] = useState([])

  const dataListCitys = async () => {
    const resp = await listDefault({ type: 'CITY' })
    setCidades(resp)
  }

  function limpar() {
    // setEmail('')
    setSenha('')
    setNovaSenha('')
    setConfirmarSenha('')
    setCelular('')
    setCamisa('')
    setCamiseta('')
    setCalcado('')
    setCalca('')
    setHobby1('')
    setHobby2('')
    setCep('')
    setCepOriginal('')
    setCidade('')
    setCidadeId(0)
    setLogradouro('')
    setNumero('')
    setComplemento('')
    setFileList([])
    setErroSenhaAtual(false)
    setTab_container_atual('Contato')
    setVisibleEditPerfil(false)
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onChange = ({ fileList: newFileList }: UploadChangeParam) => {
    setFileList(newFileList)
  }

  function onRemove() {
    setFileList([])
  }

  const onPreview = async (file) => {
    let src = file?.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file?.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  async function validarSenha(data) {
    const senhaAtual = {
      senha: data,
    }

    const response = await postVerificarSenha(
      senhaAtual,
      onFinish,
      setErroSenhaAtual,
      setSenhaAnterior
    )
  }

  async function buscarPerfilUsuario() {
    message.loading('Aguarde...', 9999999)
    const resp = await getPerfilUsuario()
    const dados = resp.data?.itens[0]
    setCelular(dados[0]?.celular)
    setCamisa(dados[0]?.camisa)
    setCamiseta(dados[0]?.camiseta)
    setCalcado(dados[0]?.calcado)
    setCalca(dados[0]?.calca)
    setHobby1(dados[0]?.hobby)
    setHobby2(dados[0]?.hobby2)
    setCep(dados[0]?.cep)
    setCepOriginal(dados[0]?.cep)
    setCidadeId(dados[0]?.endereco_cidade)
    setLogradouro(dados[0]?.endereco)
    setNumero(dados[0]?.endereco_numero)
    setComplemento(dados[0]?.complemento)
    setCnh(dados[0]?.cnh)
    setCategoriaCnh(dados[0]?.cnh_categoria)
    setValidadeCnh(formatarData(dados[0]?.cnh_validade))
    setDocumentoCnh(dados[0]?.doc_cnh)
    setImgUser(dados[0]?.doc_foto_perfil_url)
    message.destroy()
  }

  async function onFinish() {
    if (novaSenha === confirmarSenha) {
      const data = {
        doc_foto_perfil: fileList?.[0]?.originFileObj,
        doc_cnh: documentoCnh?.[0]?.originFileObj,
        cnh: cnh,
        cnh_categoria: categoriaCnh,
        cnh_validade: validadeCnh,
        senha: novaSenha,
        // email: email,
        celular: celular,
        endereco_cidade: cidadeId,
        endereco: logradouro,
        endereco_numero: numero,
        complemento: complemento,
        cep: cep,
        camisa: camisa,
        camiseta: camiseta,
        calcado: calcado,
        calca: calca,
        hobby: hobby1,
        hobby2: hobby2,
      }

      setLoading(true)
      const response = await postCadastroPerfil(data, limpar, setLoading)
    } else {
      message.error('Nova senha e confirmar senha não conferem', 7)
    }
  }

  async function validar() {
    if (alterarSenha) {
      message.loading('Validando...', 99999)
      validarSenha(senha)
    } else {
      onFinish()
    }
  }

  useEffect(() => {
    dataListCitys()
  }, [])

  useEffect(() => {
    if (itens[0]) {
      itens[0].nome = itens[0]?.nome?.toLowerCase()

      if (itens[0].email === 'cley@vegatecnologia.com.br') {
        itens[0].doc_foto_perfil = cley
      } else {
        itens[0].doc_foto_perfil = null
      }

      setUserLogado(itens[0])
    }
  }, [])

  useEffect(() => {
    if (visibleEditPerfil) {
      buscarPerfilUsuario()
    }
  }, [visibleEditPerfil])

  useEffect(() => {
    if (alterarSenha) {
      setDesabilitar(true)
    } else {
      setDesabilitar(false)
    }
  }, [alterarSenha])

  useEffect(() => {
    if (novaSenha.length > 0 && confirmarSenha.length > 0) {
      if (novaSenha === confirmarSenha) {
        setDesabilitar(false)
        setErroSenha(false)
      } else {
        setDesabilitar(true)
        setErroSenha(true)
      }
    } else {
      setDesabilitar(true)
    }
  }, [novaSenha, confirmarSenha])

  useEffect(() => {
    if (senha !== senhaAnterior) {
      setErroSenhaAtual(false)
    }
  }, [senha])

  useEffect(() => {
    if (loading) {
      setDesabilitar(true)
    }
  }, [loading])

  return (
    <Container>
      <Popover
        placement='bottomRight'
        trigger='click'
        content={
          <PopoverOpcoes>
            <div className='PopoverOpcoes__logado'>
              <Avatar
                src={userLogado.doc_foto_perfil}
                style={{
                  background: 'var(--primary-color)',
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 18,
                }}
              >
                {String(userLogado.nome).substring(0, 1).toLocaleUpperCase()}
              </Avatar>
              <p className='PopoverOpcoes__logado__nome'>{String(userLogado.nome)}</p>
            </div>

            <ul className='PopoverOpcoes__menu'>
              {opcoes_usuario.map(({ id, descricao, icone, onClick }) => {
                return (
                  <li key={id} className='PopoverOpcoes__menu__opt'>
                    <button
                      className='PopoverOpcoes__menu__opt__btn'
                      id={`menu_opt_${id}`}
                      onClick={() => {
                        onClick()
                      }}
                    >
                      {icone}
                      {descricao}
                    </button>
                  </li>
                )
              })}
            </ul>
          </PopoverOpcoes>
        }
      >
        <button className='btn-avatar-usuario' title={`Logado como ${String(userLogado.nome)}`}>
          {userLogado.doc_foto_perfil ? (
            <img src={userLogado.doc_foto_perfil} alt='' className='btn-avatar-usuario__img' />
          ) : (
            <>{String(userLogado.nome).substring(0, 2)}</>
          )}
        </button>
      </Popover>

      <Modal
        open={visibleEditPerfil}
        onCancel={limpar}
        title={alterarSenha ? 'Alterar senha' : 'Editar perfil'}
        width={alterarSenha ? '700px' : '850px'}
        destroyOnClose
        style={{ top: '50px' }}
        footer={
          <div style={{ display: 'flex', gap: '5px', width: '100%', justifyContent: 'flex-end' }}>
            <ButtonCustom
              onClick={validar}
              icon={<FontAwesomeIcon icon={faFloppyDisk} />}
              type='primary'
              loading={loading}
              disabled={alterarSenha ? desabilitar : loading}
            >
              Salvar
            </ButtonCustom>
          </div>
        }
      >
        <ContainerForm>
          <div className='upload__avatar'>
            <ImgCrop modalOk='Salvar' modalTitle='Foto' aspect={500 / 500} rotationSlider>
              <Upload
                ref={refFoto}
                listType='picture-circle'
                className='foto__upload__avatar'
                customRequest={dummyRequest}
                fileList={fileList}
                maxCount={1}
                accept='image/png, image/jpeg, image/jpg'
                onChange={onChange}
                onPreview={onPreview}
                onRemove={onRemove}
                style={{
                  width: 160,
                  height: 160,
                  border: '3px solid green',
                  overflow: 'hidden',
                }}
              >
                {fileList.length < 1 && (
                  <div className='div__teste'>
                    <Avatar
                      draggable
                      src={imgUser}
                      size='large'
                      className='avatar__profile'
                    ></Avatar>
                    <FontAwesomeIcon icon={faUpload} className='upload__icone' />
                  </div>
                )}
              </Upload>
            </ImgCrop>
            <div className='infos__primarias'>
              <h2 style={{ margin: '0', padding: '0', textTransform: 'capitalize' }}>
                {userLogado.nome}
              </h2>
              <h3 title={userLogado.email} style={{ margin: '0', padding: '0' }}>
                {userLogado.email}
              </h3>
            </div>
          </div>
          <div className='form__edit'>
            {alterarSenha ? (
              <FormSenha
                senha={senha}
                setSenha={setSenha}
                novaSenha={novaSenha}
                setNovaSenha={setNovaSenha}
                confirmarSenha={confirmarSenha}
                setConfirmarSenha={setConfirmarSenha}
                erroSenha={erroSenha}
                erroSenhaAtual={erroSenhaAtual}
              />
            ) : (
              <>
                <Tabs
                  tabs={[
                    { descricao: 'Contato', icone: faPhone },
                    { descricao: 'Documento', icone: faFileLines },
                    { descricao: 'Preferências', icone: faUser },
                    { descricao: 'Endereço', icone: faLocationDot },
                  ]}
                  selecionado={tab_container_atual}
                  trackWidth={143}
                  onChange={setTab_container_atual}
                />
                {tab_container_atual === 'Contato' && (
                  <FormLogin celular={celular} setCelular={setCelular} />
                )}
                {tab_container_atual === 'Documento' && (
                  <FormDocumento
                    cnh={cnh}
                    setCnh={setCnh}
                    categoriaCnh={categoriaCnh}
                    setCategoriaCnh={setCategoriaCnh}
                    validadeCnh={validadeCnh}
                    setValidadeCnh={setValidadeCnh}
                    documentoCnh={documentoCnh}
                    setDocumentoCnh={setDocumentoCnh}
                  />
                )}

                {tab_container_atual === 'Preferências' && (
                  <FormUsuario
                    camisa={camisa}
                    setCamisa={setCamisa}
                    openCamisa={openCamisa}
                    setOpenCamisa={setOpenCamisa}
                    camiseta={camiseta}
                    setCamiseta={setCamiseta}
                    openCamiseta={openCamiseta}
                    setOpenCamiseta={setOpenCamiseta}
                    calcado={calcado}
                    setCalcado={setCalcado}
                    calca={calca}
                    setCalca={setCalca}
                    hobby1={hobby1}
                    setHooby1={setHobby1}
                    hobby2={hobby2}
                    setHooby2={setHobby2}
                  />
                )}
                {tab_container_atual === 'Endereço' && (
                  <FormEndereco
                    cep={cep}
                    cepOriginal={cepOriginal}
                    setCep={setCep}
                    setCepOriginal={setCepOriginal}
                    cidade={cidade}
                    cidadeId={cidadeId}
                    setCidade={setCidade}
                    setCidadeId={setCidadeId}
                    openCidade={openCidade}
                    setOpenCidade={setOpenCidade}
                    logradouro={logradouro}
                    setLogradouro={setLogradouro}
                    numero={numero}
                    setNumero={setNumero}
                    complemento={complemento}
                    setComplemento={setComplemento}
                    listarCidade={cidades}
                  />
                )}
              </>
            )}
          </div>
        </ContainerForm>
      </Modal>
    </Container>
  )
}

export default DropUser
