import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import { Notification } from '../notification'
import {
  getCategoriaProdutos,
  postCategoriaProdutos,
  putCategoriaProdutos,
  TypeCategoriaProdutos,
} from '../../services/categoriaProdutos'
import { tiposSistema } from '../../services/Afins'

interface TypesCatProdutos {
  open: boolean
  update: number
  onClose: (data: boolean) => void
}

const FormCatProdutos: React.FC<TypesCatProdutos> = (props) => {
  const { open, update, onClose } = props

  const [form] = Form.useForm()

  const [dataGrupoCatProdutos, setDataTipos] = useState([])
  const [openDrawer, setOpenDrawer] = useState(false)

  const listTipos = async () => {
    const resp = await tiposSistema(19)

    setDataTipos(resp)
  }

  const editAtivo = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse<TypeCategoriaProdutos> = await getCategoriaProdutos({ cod })

    form.setFieldsValue(resp.data.itens[0])
    message.destroy()
  }

  const hancleDrawerClose = () => {
    onClose(false)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postCategoriaProdutos(data)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Categoria salva com sucesso',
          })
          onClose(true)
          form.resetFields()
          message.destroy()
        }
      } else {
        const resp = await putCategoriaProdutos(update, data)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Categoria atualizada com sucesso',
          })
          message.destroy()
          onClose(true)
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setOpenDrawer(open)
  }, [open])

  useEffect(() => {
    if (update > 0) {
      editAtivo(update)
    }
  }, [update])

  useEffect(() => {
    listTipos()
  }, [])

  return (
    <Form form={form} onFinish={onFinish} layout='vertical'>
      <Drawer
        placement='left'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={openDrawer}
        closeIcon={false}
        onClose={() => hancleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => hancleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form.Item label='Nome' name='nome'>
          <Input />
        </Form.Item>
        <Form.Item label='Tipo' name='cod_grupo'>
          <Select>
            {dataGrupoCatProdutos?.map((data) => (
              <Select.Option key={data.cod} value={data.cod}>
                {data.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default FormCatProdutos
