import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Button, Collapse, CollapseProps, Empty, Popconfirm, Tag } from "antd";

//APP
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { ItemAtivoCadastrado } from "../../../../../../../../services/recomendacao/ativos/novo-index";
import { filtrarIncompatibilidade } from "../../../gavetaCadastrar/components/incompatibilidades/meta";
import { buscarCadastro, obterTipoPorId } from "../../../gavetaCadastrar/components/compatibilidades/meta";
import { useDeleteCompatibilidadeEmMassa } from "../../../../../../../../services/recomendacao/ativos/delete";

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import Checkbox from "../../../../../../../../Components/CustomUi/checkbox";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { ZLabel } from "../zLabel";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { Warning } from "../../../../../../../../Components/CustomUi/warning";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { ModalEditar } from "../../../gavetaCadastrar/components/incompatibilidades/components/editar";

//PROPS
interface Props {
    ativos_cadastrados: ItemAtivoCadastrado[],
    carregando: boolean;
    refetch: Function;
    atualizarListaPrincipal: Function;
    id_tipo_principal: number | null;
}

export const DetalhesIncompatibilidade: React.FC<Props> = ({ ativos_cadastrados, carregando, refetch, atualizarListaPrincipal, id_tipo_principal }) => {

    //STATES
    const [search, setSearch] = useState<string>('');
    const [checados, setChecados] = useState([]);
    const [hover_item_atual, setHover_item_atual] = useState<number>(-1);
    const [collapsable, setCollapsable] = useState<boolean>(true);
    const [todosChecados, setTodosChecados] = useState<boolean>(false);
    const [confirmDelete, setConfirmDelete] = useState<boolean>(false);

    //API
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidadeEmMassa();

    function isChecado(cod_item: number) {
        return todosChecados ? true : (checados?.find(item=> item === cod_item)) !== undefined;
    }

    function marcarCheck(cod_item: number) {
        if (isChecado(cod_item)) {
            let remover = checados.filter(item=> item !== cod_item);
            setChecados(remover);
        }
        else {
            setChecados(checados=>[...checados, cod_item]);
        }
    }

    function montarListaCollapse() {
        let lista_ativos:CollapseProps['items'] = (ativos_cadastrados)?.filter(buscarCadastro(search))?.filter(filtrarIncompatibilidade()).map((item)=> {
            let is_item_atual: undefined|'disabled' = hover_item_atual === item.cod ? 'disabled' : undefined;
            return {
                key: item.cod,
                label: <p style={{margin:0, display:'flex',justifyContent:'space-between'}}>
                    
                    <ZLabel 
                        item={item}
                        id_tipo_principal={id_tipo_principal}
                    />
                    
                    <span style={{display: 'flex', alignSelf: 'center'}}>
                        <Tag color="error">{obterTipoPorId(item.lancamento)}</Tag>
                        <Checkbox 
                            isChecked={isChecado(item.cod)} 
                            trigger={()=>{marcarCheck(item.cod)}}
                            onMouseOver={()=>{setHover_item_atual(item.cod);setCollapsable(false)}}
                            onMouseOut={()=>{setHover_item_atual(-1);setCollapsable(true)}}
                            // disabled={todosChecados}
                        />
                    </span>
                </p>,
                children: <ModalEditar id_tipo_principal={id_tipo_principal} registros={item} atualizarLista={refetch} isVisualizar />,
                destroyInactivePanel: true,
                collapsible: collapsable ? undefined : is_item_atual
            }
        });
        return lista_ativos;
    }

    function deletarCompatibilidade() {
        let deletar_items = todosChecados ? montarListaCollapse().map(item=> item.key) : checados;

        for (const cod of deletar_items) {
            mutate_deletar_compati({
                codConta: Number(localConfig().conta),
                cod: cod,
                limmparCheckBox: () => {setChecados([]);setTodosChecados(false)},
                atualizarLista: () => refetch(),
                atualizarListaPrincipal: () => atualizarListaPrincipal(),
                ultimaChamada: deletar_items?.[deletar_items?.length -1] === cod,
                closePopup: ()=> setConfirmDelete(false)
            })
        } 
    }

    return (
        <div className='BodyDrawer__box' style={{borderColor: '#ff4d4f'}}>

            <Warning type='danger'>
                Incompatibilidade
            </Warning>

            <Box>
                <TitleH4 style={{color: 'var(--text-color-light)'}}>Incompatibilidades cadastradas ({montarListaCollapse()?.length ?? ''})</TitleH4>

                <Input
                    placeholder="Buscar incompatibilidade"
                    value={search}
                    onChange={setSearch}
                />

                {carregando ?
                    <Carregando 
                        legenda="Carregando incompatibilidades cadastradas"
                        justifyContent="center"
                        animation
                        height={100}
                    />
                    : <>
                        {montarListaCollapse()?.length === 0 
                            ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={search?.length > 0 ? <span><b>"{search}"</b> não encontrado</span> : `Nenhuma incompatibilidade cadastrada`} />
                            : <Collapse accordion items={montarListaCollapse()} style={{opacity: carregando ? 0.4 : 1}} />
                        } 
                    </>
                }

                {montarListaCollapse().length > 0 &&(
                    <div style={{display:'flex'}}>
                        <Checkbox 
                            isChecked={todosChecados}
                            trigger={setTodosChecados}
                            label="Marcar todos"
                        />
                        
                        <Popconfirm
                            placement="topRight"
                            title={`Tem certeza?`}
                            open={confirmDelete}
                            description={
                            <div style={{display:'flex',flexDirection:'column',gap:15}}>
                                <span>{`${todosChecados?'Todos os':checados?.length} itens serão deletados`}</span>
                                <Inline justifyContent="flex-end">

                                    <ButtonCustom 
                                        onClick={()=>{setConfirmDelete(false)}}
                                        type="tertiary"
                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                    >
                                        Cancelar
                                    </ButtonCustom>

                                    <ButtonCustom 
                                        value="Deletar"
                                        onClick={()=>{deletarCompatibilidade()}}
                                        icon={<FontAwesomeIcon icon={faTrash} /> }
                                        danger
                                        loading={carregando_deletar_compati}
                                        disabled={carregando_deletar_compati ? true : (checados?.length === 0 && !todosChecados)}
                                    >
                                        Deletar
                                    </ButtonCustom>

                                </Inline>
                            </div>}
                            okButtonProps={{style: {display:'none'}}}
                            cancelButtonProps={{style: {display:'none'}}}
                        >
                            <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                                <ButtonCustom
                                    danger
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    onClick={() => setConfirmDelete(true)}
                                    disabled={carregando_deletar_compati ? true : (checados?.length === 0 && !todosChecados)}
                                >
                                    Deletar
                                </ButtonCustom>  
                            </Button>
                        </Popconfirm>
                    </div>
                )}          
                
            </Box>
        </div> 
    );
}