import { H1Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
}

export const TitleH1: React.FC<Props> = ({ children }) => {
    return (
        <H1Component>
            {children}
        </H1Component>
    )
}