import React from 'react'
import Input from '../../../CustomUi/input'
import { faEnvelope, faLock, faMobileScreenButton } from '@fortawesome/free-solid-svg-icons'
import { phoneMask } from '../../../../services/Afins'

interface PropsLogin {
  // email?: string
  // setEmail?: React.Dispatch<React.SetStateAction<string>>
  celular?: string
  setCelular?: React.Dispatch<React.SetStateAction<string>>
  
}

const FormLogin: React.FC<PropsLogin> = ({ celular, setCelular }) => {
  return (
    <div className='line'>
      {/* <Input
        label='Email'
        icon={faEnvelope}
        placeholder='Digite um email'
        value={email}
        onChange={setEmail}
        width={'100%'}
        height={'30%'}
        desabilitado={true}
      /> */}

      <Input
        label='Celular'
        icon={faMobileScreenButton}
        placeholder='(00) 00000-0000'
        value={phoneMask(celular)}
        onChange={setCelular}
        maxLength={15}
        width={'100%'}
        height={'30%'}
      />
    </div>
  )
}

export default FormLogin
