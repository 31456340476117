import styled from 'styled-components'

export const Container = styled.div`
  .ant-card-body {
    padding-bottom: 0;
  }

  .btn-ponto > .ant-card-body {
    padding: 0;
  }

  .class-remove {
    height: 60px !important;
  }
  .ant-card-actions {
    background-color: #d9d9d9;
    li {
      color: #000000;
    }
  }
  .ant-tabs-content {
    margin-left: 0 !important;
  }
`

export const CardBtn = styled.div`
  .btn-ponto > .ant-card-body {
    padding: 5px;
    height: 30px;
    color: #fff;
    cursor: pointer;
  }
`
export const DivCultura = styled.div`
  border: 1px solid #d9d9d9;
  height: 30px;
  padding: 3px;
  border-radius: 5px;
  background-color: #f5f5f5;
  color: #c3c4c3;
  :hover{
    cursor: not-allowed;
  }
`
