import { legendas_mapa_indicadores } from "../../../../components/cardLegenda/meta";

// ----------------------------------------------------------------------------------------------------------------->
// ===|> INFORMAÇÕES DA FAZENDA 
// ----------------------------------------------------------------------------------------------------------------->
export function info_fazenda(nome_cliente: string, nome_propriedade: string): {descricao: string, valor: string}[] {
    return [
        {descricao: 'Cliente', valor: nome_cliente},
        {descricao: 'Propriedade', valor: nome_propriedade},
    ];
};

// ----------------------------------------------------------------------------------------------------------------->
// ===|> DETALHES DA ANALISE
// ----------------------------------------------------------------------------------------------------------------->
type PropDetalhes = {
    nome_talhao: string,
    data_coleta: string,
    profundidade: string,
    elemento_analisado: string,
    isPrescricao: boolean,
    isIndicadores: boolean,
    nome_calcario_propriedade?: string
}

function descricaoInfoDetalhes(elemento_analisado: string, isPrescricao: boolean, isIndicadores: boolean) {
    if (elemento_analisado?.startsWith('Calcário'))  return 'Calcário utilizado';
    return (isPrescricao || isIndicadores) ? 'Tipo de mapa' : 'Profundidade'
}
function valorInfoDetalhes(elemento_analisado: string, profundidade: string, nome_calcario_propriedade?: string) {
    if (elemento_analisado?.startsWith('Calcário'))  return nome_calcario_propriedade;
    return String(profundidade)?.replace('micro','00-20')?.replace("prescricoes",'prescrições')
}

export function info_detalhes(detalhes: PropDetalhes) {
    let { nome_talhao, data_coleta, profundidade, elemento_analisado, isPrescricao, isIndicadores, nome_calcario_propriedade } = detalhes;
    return [
        {id: 0, descricao: 'Talhão', valor: nome_talhao},
        {id: 1, descricao: 'Data da coleta', valor: data_coleta},
        {id: 2, descricao: descricaoInfoDetalhes('null', isPrescricao, isIndicadores), valor: valorInfoDetalhes('null', profundidade, nome_calcario_propriedade)},
        {id: 3, descricao: (isPrescricao) ? 'Mapa recomendado' : 'Elemento analisado', valor: elemento_analisado, small: true},
        {id: 4, descricao: descricaoInfoDetalhes(elemento_analisado, isPrescricao, isIndicadores), valor: valorInfoDetalhes(elemento_analisado, profundidade, nome_calcario_propriedade)},
    ]?.filter((item)=> {
        return (elemento_analisado?.startsWith('Calcário')) ? item.id!==2 : item.id!==4
    })
}
// ----------------------------------------------------------------------------------------------------------------->
// ===|> INTERPRETAÇÃO (NOTAS MINIMA - MÉDIA - MAXIMA)
// ----------------------------------------------------------------------------------------------------------------->
type PropsInterpretacoes = {
    interpretado_minimo: number,
    interpretado_media: number,
    interpretado_maximo: number,
    cor_invertida: boolean,
    isInterpretado: boolean,
    cod_elemento: number,
    unidade: string,
    nota_minima: string,
    nota_media: string,
    nota_maxima: string,
    cores_do_mapa: string[],
    isIndicadores: boolean,
    isPrescricao: boolean,
    areaTalhao: number
}

function isMedia(tipo: "Média" | "Máxima", interpretado_media: number, interpretado_maximo: number): number {
    if (tipo==='Média') {
        return interpretado_media;
    }
    return interpretado_maximo;
}

function isInvertido(cor_invertida: boolean, valor_verdade: number|string, valor_falso: number|string) {
    return cor_invertida ? valor_verdade : valor_falso;
}

export function legendaClassificacao(tipo: 'Mínima'|'Média'|'Máxima'|'Unidade', notas_interp: PropsInterpretacoes): {classificacao: string, hexadecimal: string} {

    let { interpretado_minimo, interpretado_media, interpretado_maximo, isInterpretado, cor_invertida, cod_elemento } = notas_interp;

    if (tipo==='Unidade') return undefined;

    let valor_para_comparar = tipo === 'Mínima' ? interpretado_minimo : isMedia(tipo, interpretado_media, interpretado_maximo);
    let cor_base: string[] = ['#2ecc71', '#b0f13c', '#f0ff00', '#ff9a00', '#e22626'];

    if (!isInterpretado && tipo==='Mínima') {
        return {classificacao: '', hexadecimal: (cor_invertida ? "#2ecc71" : '#e22626')}
    }
    if (!isInterpretado && tipo==='Média') {
        return {classificacao: '', hexadecimal: '#f0ff00'}
    }
    if (!isInterpretado && tipo==='Máxima') {
        return {classificacao: '', hexadecimal: (cor_invertida ? "#e22626" : '#2ecc71')}
    }

    function classicacaoEspecialIndicadores(hexadecimal_comparativo: string) {
        let legendas = legendas_mapa_indicadores?.[cod_elemento];
        const cartaEncontrada = legendas?.find(item => item?.hexadecimal === hexadecimal_comparativo);

        return cartaEncontrada ? cartaEncontrada.carta : "";
    }
    
    if (valor_para_comparar <=20) {
        return {
            classificacao: ([33,34,38]?.indexOf(cod_elemento) > -1) ? classicacaoEspecialIndicadores(cor_base?.[isInvertido(cor_invertida, 0, 4)]) : 'Muito baixo', 
            hexadecimal: isInterpretado ? (cor_base?.[isInvertido(cor_invertida, 0, 4)]) : (isInvertido(cor_invertida, '#2ecc71', '#e22626'))
        };    
    }
    else if (valor_para_comparar <= 40) {
        return {
            classificacao: ([33,34,38]?.indexOf(cod_elemento) > -1) ? classicacaoEspecialIndicadores(cor_base?.[isInvertido(cor_invertida, 1, 3)]) : 'Baixo', 
            hexadecimal: isInterpretado ? (cor_base?.[isInvertido(cor_invertida, 1, 3)]) : (isInvertido(cor_invertida, "#2ecc71", '#e22626'))
        };    
    }
    else if (valor_para_comparar <= 60) {
        return {
            classificacao: ([33,34,38]?.indexOf(cod_elemento) > -1) ? classicacaoEspecialIndicadores(cor_base?.[2]) : 'Médio', 
            hexadecimal: cor_base?.[2]
        };
    }
    else if (valor_para_comparar <= 80) {
        return {
            classificacao: ([33,34,38]?.indexOf(cod_elemento) > -1) ? classicacaoEspecialIndicadores(cor_base?.[isInvertido(cor_invertida, 3, 1)]) : 'Alto', 
            hexadecimal: isInterpretado ? (cor_base?.[isInvertido(cor_invertida, 3, 1)]) : isInvertido(cor_invertida, "#e22626", '#2ecc71')
        };       
    }
    else {
        return {
            classificacao: ([33,34,38]?.indexOf(cod_elemento) > -1) ? classicacaoEspecialIndicadores(cor_base?.[isInvertido(cor_invertida, 4, 0)]) : 'Muito alto', 
            hexadecimal: isInterpretado ? (cor_base?.[isInvertido(cor_invertida, 4, 0)]) : isInvertido(cor_invertida, "#e22626", "#2ecc71")
        };          
    }
}

function legenda3Cores(notas_interp: PropsInterpretacoes, descricao: string) {
    let { cod_elemento, cores_do_mapa, cor_invertida } = notas_interp;

    let tres_cores = {
        Mínima: cor_invertida ? cores_do_mapa?.[2] : cores_do_mapa?.[0],
        Média:  cores_do_mapa?.[1],
        Máxima: cor_invertida ? cores_do_mapa?.[0] : cores_do_mapa?.[2],
    }

    function classicacaoEspecialIndicadores(hexadecimal_comparativo: string) {
        let legendas = legendas_mapa_indicadores?.[cod_elemento];
        const cartaEncontrada = legendas?.find(item => item?.hexadecimal === hexadecimal_comparativo);

        return cartaEncontrada ? cartaEncontrada.carta : "";
    }

    return classicacaoEspecialIndicadores(tres_cores?.[descricao])
}

export function info_min_med_max(notas_interp: PropsInterpretacoes): {descricao: 'Mínima'|'Média'|'Máxima'|'Unidade', valor: string, nota?: string|number, esconder?: boolean}[] {
    let { unidade, nota_minima, nota_media, nota_maxima, cores_do_mapa, isIndicadores, cod_elemento, isPrescricao, areaTalhao } = notas_interp;
    let is_tres_cores = (cores_do_mapa?.length) === 3 && isIndicadores && ([33,34,38]?.indexOf(cod_elemento) > -1);


    function valorDaNotaMedia(valor_da_nota: string): string | number {

        if (isPrescricao) {
            let toneladas = (Number(valor_da_nota) * areaTalhao) / 1000;
            let valor_final = toneladas >= 1 ? `Total de ${toneladas?.toFixed(2)} t` : `Total de ${(toneladas * 1000)?.toFixed(2)} kg`
    
            return `${Number(valor_da_nota)?.toFixed(1)} ║ ${valor_final}`
        }

        return valor_da_nota
    }

    return [
        {descricao: 'Unidade', valor: unidade, esconder: !(!!unidade)},
        {descricao: 'Mínima', valor: is_tres_cores ? legenda3Cores(notas_interp, 'Mínima') : legendaClassificacao('Mínima', notas_interp).classificacao, nota: isPrescricao ? Number(nota_minima)?.toFixed(1) : nota_minima},
        {descricao: 'Média', valor: is_tres_cores ? legenda3Cores(notas_interp, 'Média') : legendaClassificacao('Média', notas_interp).classificacao, nota: valorDaNotaMedia(nota_media)},
        {descricao: 'Máxima', valor: is_tres_cores ? legenda3Cores(notas_interp, 'Máxima') : legendaClassificacao('Máxima', notas_interp).classificacao, nota: isPrescricao ? Number(nota_maxima)?.toFixed(1) : nota_maxima}
    ];
};