import React, { useEffect } from 'react'

import { MapContainer, MapContainerProps, TileLayer, useMap } from 'react-leaflet'
import WaterMarker from '../WaterMarker'

interface Props extends MapContainerProps {
  ref?: (map: L.Map) => void
  exibeTerreno?: boolean
  hideMarcaDagua?: boolean
  sizeMarcaDagua?: number
}

const MapLeaflet: React.FC<Props> = (props) => {
  const {
    children,
    center = { lat: 0, lng: 0 },
    zoom = 3,
    ref,
    exibeTerreno = true,
    hideMarcaDagua = false,
    sizeMarcaDagua = 5,
  } = props
  return (
    <MapContainer ref={(map) => ref(map)} {...props} center={center} zoom={zoom}>
      {exibeTerreno ? (
        <>
          <TileLayer
            url='http://{s}.google.com/vt/lyrs=s,h&x={x}&y={y}&z={z}'
            maxZoom={20}
            subdomains={['mt0', 'mt1', 'mt2', 'mt3']}
          />
        </>
      ) : null}
      {children}
      <WaterMarker hidden={hideMarcaDagua} size={sizeMarcaDagua} placement='bottomRight' />
    </MapContainer>
  )
}
export default MapLeaflet
