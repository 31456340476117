import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeMoniEspecie {
  itens: [MoniEspecieItens]
}

export interface MoniEspecieItens {
  cod?: number
  nome?: string
  cod_categoria?: number
  cod_conta?: number
  cod_cliente?: number
  status?: number
  nome_cientifico?: string
  categoria_nome?: string
  variacao?: VariacaoItem[]
}

export interface VariacaoItem {
  cod?: number
  cod_especie?: number
  nome?: string
  status?: number
  especie_nome?: string
  categoria_nome?: string
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  testeBandeja?: number
  codSafra?: number
  cod?: number
  pagination?: number | -1
  embed?: string
}

export const getMoniEspecie = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  const { codCliente, conta, acesso } = localConfig()

  params = {
    pagination,
    codConta: conta,
    codCliente,
    ...params,
  }

  const response = api.get('/monitoramento/especie', {
    params,
  })

  return response
}

export const postMoniEspecie = async (data) => {
  const config = localConfig()

  const response = api.post('/monitoramento/especie', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'variacao',
    },
  })

  return response
}

export const putMoniEspecie = async (cod, data) => {
  const config = localConfig()

  const response = api.put(`/monitoramento/especie/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const delMoniEspecie = async (cod) => {
  const config = localConfig()

  const response = api.delete(`/monitoramento/especie/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
