import { faCopy } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//COMPONENTS
import Label from "../../../../../../../Components/Label";
import ButtonCustom from "../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../Components/CustomUi/box";
import { CopiableRow } from "../../../../../../../Components/CustomUi/copiableRow";
import { ItemRequest } from "../../../../../../../services/logs";
import { Render } from "../../../../../../../Components/CustomUi/render";
import { Empty } from "antd";
import { copyJson } from "./meta";

//PROPS
interface Props {
    request_selected: ItemRequest;
}

export const BodyRequest: React.FC<Props> = ({ request_selected }) => {

    const { body } = request_selected?.descricao ?? {};
    const bodies:{descr:string,value:string,key:number}[] = (Object.entries(body)).map(([chave, valor], index) => { 
        return { 
            descr: chave, 
            value: typeof valor !== 'string' ? JSON.stringify(valor) : String(valor), 
            key: index 
        }; 
    });

    return (
        <Box padding={15}>

            <Render condition={bodies.length > 0}>
                <Label style={{fontWeight: 700, color: 'var(--text-color-light)', fontSize:13}} >Corpo da requisição</Label>

                <div style={{display:'flex', flexDirection:'column'}}>
                    {(bodies ?? []).map(({ descr, value, key })=> {
                        return (
                            <CopiableRow 
                                key={key}
                                atribute={descr}
                                value={value ?? ''}
                                isLast={bodies.length === (key + 1)}
                            />
                        )
                    })}
                </div>

                <ButtonCustom 
                    onClick={()=>{copyJson(request_selected)}}
                    type='secondary'
                    style={{alignSelf:'flex-start'}}
                    icon={<FontAwesomeIcon icon={faCopy} />}
                >
                    Copiar JSON
                </ButtonCustom>
            </Render>
            
            <Render condition={bodies.length === 0}>
                <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<>Requisição não possui corpo</>} />
            </Render>
            
        </Box>
    )
}