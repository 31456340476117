import styled from 'styled-components'

export const Container = styled.div`
  width: 40px;
  height: 76px;
  position: relative;
  display: flex;
  align-items: center;

  .menu-user {
    width: 500px;

    ul {
      li {
        font-size: 25px;
      }
    }
  }
  .btn-avatar-usuario {
    width: 40px;
    height: 40px;
    border-radius: 50%;
    display: flex;
    align-items: center;
    justify-content: center;
    background-color: var(--primary-color);
    border: 2px solid var(--primary-color);
    color: #fff;
    font-weight: 600;
    font-family: 'Poppins', Arial, Helvetica, sans-serif;
    font-size: 17px;
    cursor: pointer;
    text-transform: uppercase;
    box-sizing: border-box;
    transition: 0.3s;
    overflow: hidden;
  }
  .btn-avatar-usuario:hover {
    transform: scale(1.05);
    filter: brightness(110%);
  }
  .btn-avatar-usuario:active {
    transform: scale(0.9);
  }
  .btn-avatar-usuario__img {
    width: 100%;
    border-radius: 50%;
    transform: scale(1.4);
  }
`

export const ContainerForm = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-top: 20px;
  gap: 10px;

  .form__edit {
    width: 73%;
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .line {
    display: flex;
    justify-content: center;
    align-items: center;
    gap: 10px;
    flex-direction: column;
  }

  .upload__avatar {
    width: 27%;
    display: flex;
    flex-direction: column;
    box-sizing: border-box;
    border-radius: 10px;
    justify-content: center;
    align-items: center;
    gap: 10px;
  }

  .foto__upload__avatar {
    display: flex;
    justify-content: center;
    align-items: center;
  }

  .infos__primarias {
    display: flex;
    flex-direction: column;
    justify-content: center;
    text-align: center;
    width: 100%;

    h2 {
      font-size: 14px;
      font-weight: 700;
      color: var(--text-color);
    }

    h3 {
      font-size: 13px;
      text-overflow: ellipsis;
      overflow: hidden;
      white-space: nowrap;
      color: var(--text-color-light);
    }
  }

  .campo__tamanho__camisa {
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h3 {
      font-size: 13px;
      padding: 0;
      margin: 0;
    }
  }

  .campo__tamanhos {
    width: 100%;
    border: 1px solid var(--border-color);
    padding: 10px;
    border-radius: 10px;
    gap: 10px;
    display: flex;
    flex-direction: column;

    .header__tamanhos {
      text-align: center;

      h3 {
        margin: 0;
        padding: 0;
        font-size: 15px;
        font-weight: 600;
      }
    }
  }

  .upload__icone {
    font-size: 14px;
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    z-index: 1;
    padding: 10px;
    color: white;
    border-radius: 5px;
    opacity: 0;
    transition: 0.1s;
  }

  .avatar__profile {
    width: 80px;
    height: 80px;
    text-align: center;
    cursor: pointer;
    filter: brightness(0.9);
    transition: 0.1s;
  }

  .div__teste {
    position: relative;
    border-radius: 50%;
  }

  .div__teste:hover .avatar__profile {
    filter: brightness(0.3);
    transition: 0.3s;
  }

  .div__teste:hover .upload__icone {
    opacity: 1;
    transition: 0.3s;
  }

  .campo-input-upload {
    display: flex;
    width: 100%;
  }

  .campo-upload-preview {
    display: flex;
    justify-content: space-around;
    align-items: end;
    gap: 5px;
    padding-left: 5px;
  }
  .lines {
    width: 100%;
    display: flex;
    gap: 10px;
  }
  .upload-button {
    height: 61%;
  }

  .campo__nova__senha {
    display: flex;
    flex-direction: column;
    gap: 10px;
  }

  .campo__nova__senha:is([data-open='true']) {
    transform: translateX(-50px);
    animation: fadeSlideIn 0.5s forwards; /* Animação com duração de 0.5s */
  }

  @keyframes fadeSlideIn {
    to {
      opacity: 1;
      transform: translateX(0);
    }
  }
`

export const PopoverOpcoes = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 15px;

  .PopoverOpcoes__logado {
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    gap: 5px;
    border-bottom: thin solid var(--border-color);

    .PopoverOpcoes__logado__nome {
      display: flex;
      margin: 0;
      padding: 0;
      text-transform: capitalize;
      font-size: 15px;
      font-weight: 500;
      color: var(--text-color);
    }
  }

  .PopoverOpcoes__menu {
    width: 100%;
    list-style: none;
    padding: 0;
    margin: 0;

    li.PopoverOpcoes__menu__opt {
      width: 100%;

      button.PopoverOpcoes__menu__opt__btn {
        width: 100%;
        height: 40px;
        text-align: left;
        display: inline-flex;
        align-items: center;
        gap: 10px;
        background-color: transparent;
        border: none;
        cursor: pointer;
        color: var(--text-color);
        transition: 0.4s;
        border-radius: 5px;
      }
      button.PopoverOpcoes__menu__opt__btn:hover {
        color: var(--primary-color);
      }
      button.PopoverOpcoes__menu__opt__btn#menu_opt_3:hover {
        color: red;
      }
      button.PopoverOpcoes__menu__opt__btn:active {
        transform: scale(0.95);
      }
    }
  }
`
