import styled from 'styled-components'

export const ContainerCultura = styled.section`
  width: 100%;
  height: calc(100% - 10px);
  box-sizing: border-box;
  padding: 5px;
  h1 {
    font-weight: 700;
    font-size: 18px;
    color: var(--text-color);
  }
`

export const CampoPrincipal = styled.section`
  width: 100%;

  background-color: white;
  border: thin solid var(--border-color);
  border-radius: 10px;
  box-sizing: border-box;
  padding: 15px;
  display: flex;
  flex-direction: column;
  gap: 5px;

  .header__cadastrar {
    width: 100%;
    display: flex;
    flex-direction: column;
    margin-bottom: 5px;
  }
  .title__header {
    font-size: 18px;
    font-weight: bold;
  }

  .camp__add {
    width: 50%;
    display: flex;
  }

  .btn__register {
    background-color: var(--primary-color);
  }

  .camp__search {
    width: 50%;
    margin-left: 5px;
    border-radius: 10px;
    border: 1px solid #d9d9d9;
  }

  .input__search {
    height: 100%;
    width: 90%;
    border-radius: 10px;
    border-bottom: none;
    border-top: none;
    border-left: none;
    border-right: none;
  }
`

export const TabelaCulturas = styled.div`
  width: 100%;
  height: calc(100% - 90px);
  background-color: white;
  border-radius: 10px;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  gap: 5px;

  div.Conteudo__tabela {
    width: 100%;
    height: calc(100% - 20px);
    border: thin solid var(--border-color);
    border-radius: 5px;
    overflow: hidden;
  }

  div.Conteudo__tabela__header {
    width: 100%;
    height: 45px;
    background-color: #fafafa;
    display: flex;
  }
  div.Conteudo__tabela__header__item {
    width: 150px;
    height: 100%;
    display: flex;
    align-items: center;
    padding-left: 10px;
    font-weight: 500;
    font-size: 14px;
    border-left: thin solid #ebebeb;
  }
  div.Conteudo__tabela__header__item:first-child {
    border: none;
  }

  div.Conteudo__tabela__body {
    width: 100%;
    height: calc(100% - 45px);
    overflow-y: auto;
    overflow-x: hidden;
  }
  div.Conteudo__tabela__body__row {
    width: 100.4%;
    height: 56px;
    display: flex;
    align-items: center;
    border-bottom: thin solid var(--border-color);
  }
  div.Conteudo__tabela__body__row__item {
    width: 80px;
    height: 100%;
    display: flex;
    align-items: center;
    padding: 0 10px;
    border-left: thin solid #ebebeb;
    font-weight: 400;
    font-size: 14px;
    background-color: #fff;
  }
  div.Conteudo__tabela__body__row__item:first-child {
    border: none;
  }
  div.Conteudo__tabela__body__row__item.ordem {
    width: 80px;
    min-width: 80px;
    gap: 7px;
    cursor: move;
    position: relative;
  }
  div.Conteudo__tabela__body__row__item.ordem svg {
    color: var(--primary-color);
    font-size: 16px;
  }
`
