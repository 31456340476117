import { api } from '../../../api';
import { message } from 'antd';
import { UseMutationResult, useMutation } from 'react-query';

//=================================================================================>
// DELETAR COMPATIBILIDADE
//<=================================================================================

interface ParamsDeleteCompat {
    cod?: number,
    codConta: number;
    limparStates: () => void;
    atualizarLista: Function;
    closePopup: Function;
}

export const useDeleteCompatibilidade = (): {
    carregando_deletar_compati: boolean;
    mutate_deletar_compati: (params: ParamsDeleteCompat) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteCompat> = useMutation(
        async (params: ParamsDeleteCompat) => {
            const { codConta, atualizarLista, limparStates, cod, closePopup } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            return api.delete(`/recomendacao/compatibilidade/${cod}?codConta=${codConta}&embed=composicao`)
                .then((response: any) => {
                    message.success({ content: 'Excluído com sucesso!', key: 'loading' });
                    limparStates();
                    atualizarLista();
                    closePopup();
                    return response;
                })
                .catch((error: Error) => {
                    message.error({ content: 'Falha ao excluir compatibilidade', key: 'loading' });
                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_compati: mutation.isLoading, 
        mutate_deletar_compati: mutation.mutate 
    };
};

//=================================================================================>
// DELETAR COMPATIBILIDADE EM MASSA
//<=================================================================================

interface ParamsDeleteCompatEmMassa {
    cod?: number,
    codConta: number;
    atualizarLista: Function;
    atualizarListaPrincipal: Function;
    limmparCheckBox: Function;
    ultimaChamada: boolean;
    closePopup: Function;
}

export const useDeleteCompatibilidadeEmMassa = (): {
    carregando_deletar_compati: boolean;
    mutate_deletar_compati: (params: ParamsDeleteCompatEmMassa) => void;
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsDeleteCompatEmMassa> = useMutation(
        async (params: ParamsDeleteCompatEmMassa) => {
            const { codConta, atualizarLista, atualizarListaPrincipal, cod, limmparCheckBox, ultimaChamada, closePopup } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            return api.delete(`/recomendacao/compatibilidade/${cod}?codConta=${codConta}&embed=composicao`)
                .then((response: any) => {
                    if (ultimaChamada) {
                        message.success({ content: 'Compatibilidades excluídas com sucesso!', key: 'loading' });
                        atualizarLista();
                        atualizarListaPrincipal();
                        limmparCheckBox();
                        closePopup();
                    }
                    return response;
                })
                .catch((error: Error) => {
                    message.error({ content: 'Falha ao excluir compatibilidades', key: 'loading' });
                    throw error;
                });
        }
    );

    return { 
        carregando_deletar_compati: mutation.isLoading, 
        mutate_deletar_compati: mutation.mutate 
    };
};
