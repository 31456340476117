import styled from 'styled-components'

export const Container = styled.div`
  .btn-radio {
    width: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
    flex-direction: column;
    font-size: 13px;
  }
  .ant-radio-group-middle .ant-radio-button-wrapper {
    height: 4.5rem;
  }

  .ant-radio-button-wrapper:hover {
    position: relative;
    color: #8bbea3;
  }

  .ant-radio-button-wrapper-checked:not(.ant-radio-button-wrapper-disabled) {
    z-index: 1;
    color: #8bbea3;
    background: #fff;
    border-color: #8bbea3;
  }

  .ant-radio-button-wrapper-checked
    :not([class*=' ant-radio-button-wrapper-disabled'])
    .ant-radio-button-wrapper:first-child {
    border-right-color: #8bbea3;
    border-left-color: #8bbea3;
  }
  .ant-form-item {
    margin-bottom: 0;
  }
  .table-row {
    cursor: pointer;
  }

  .optionsExport {
    width: 100%;
    height: 70px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    gap: 2px;
    /* background-color: red; */

    span.optionsExport__label {
      color: var(--text-color)
    }
    div.optionsExport__actions {
      display: flex;
      gap: 10px;
    }
  }

`
export const Tools = styled.div`
  position: absolute;
  margin: 0.8rem;
  max-height: 90vh;
  max-width: 27rem;
`
