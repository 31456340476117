import styled from 'styled-components'

export const Container = styled.div<{ loading?: boolean }>`
  position: relative;
  display: flex;
  flex: auto;
  width: 100%;
  height: calc(80vh - 50px);
  align-items: center;

  .flex-column {
    gap: 0 !important;
  }
  .ant-checkbox-wrapper-checked {
    background-color: #e6f7ff;
  }
  .ant-card-body {
    padding: 0 !important;
  }

  .ant-checkbox-wrapper {
    padding: 0.5rem !important;
  }

  .select-all.ant-checkbox-wrapper {
    padding: 0 !important;
  }
`
export const GroupBtn = styled.div`
  display: flex;
  flex-direction: column;
  gap: 0.5rem;
  padding: 0.5rem;
`
export const TransferContent = styled.div``
export const TransferSearch = styled.div`
  padding: 0.5rem !important;
`

export const TransferBody = styled.div`
  flex: auto;
  height: calc(100vh - 330px);
  overflow: auto;
  .flex-column {
    display: flex;
    flex-direction: column;
    gap: 0.9rem;
    margin-top: 1rem;

    .ant-checkbox-wrapper {
      padding: 10px !important;

      :hover {
        background: rgb(93, 165, 125, 0.1);
      }
    }

    .ant-checkbox-group-item {
      width: 100%;
      font-size: 13px;
      line-height: 15px;
      /* white-space: nowrap; */
      text-overflow: ellipsis;
      overflow: hidden;
    }
  }
`
export const Loading = styled.div<{ loading?: boolean }>`
  visibility: ${(p) => (p.loading ? 'visible' : 'hidden')};
  z-index: 1;
  opacity: 0.2;
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100%;
  background-color: #7d7d7d;
`;
