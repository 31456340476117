import React, { useEffect, useRef, useState } from 'react'

import { Button, Collapse, Input, Modal, Space, Table } from 'antd'

import { faPen, faPlus as fasPlus, faTrash } from '@fortawesome/free-solid-svg-icons'

import Icon, { SearchOutlined } from '@ant-design/icons'

import { AxiosResponse } from 'axios'
import { Notification } from '../../../../Components/notification'

import { pagination } from '../../../../Components/Default'
import Icons from '../../../../Components/Icons'
import { IconAlert } from '../../../../Components/iconsAlert'
import MoniNivelControle from '../../../../Components/monitoramento/nivelControle'
import ViewPage from '../../../../Components/ViewPage'
import { tiposGerais } from '../../../../services/Afins'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import {
  delMoniNivelControle,
  getMoniNivelControle,
  MoniNivelControleItens,
  TypeMoniNivelControle,
} from '../../../../services/monitoramento/nivelControle'
import { Container } from './styled'

const { Panel } = Collapse

const PageNivelControle: React.FC = () => {
  const searchInputTable = useRef(null)
  const [visibleForm, setVisibleForm] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [dataCultura, setDataCultura] = useState([])

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [codCultura, setCodCultura] = useState<number>(null)

  const [update, setUpdate] = useState(0)

  const [codEstadio, setCodEstadio] = useState(0)

  const [dataListEstadio, setDataListEstadio] = useState<MoniNivelControleItens[]>([])

  /* functio que chama o estadio */
  const listEstadio = async () => {
    setLoadingTable(true)
    const response: AxiosResponse<TypeMoniNivelControle> = await getMoniNivelControle({
      embed: 'item',
    })

    setDataListEstadio(response.data.itens)

    setLoadingTable(false)
  }

  const handleClickNewEstadio = (cod, data) => {
    
    if (data === undefined) {
      setVisibleForm(false)
      Notification({
        type: 'error',
        message: 'Nenhum estadio cadastrado para essa cultura'
      })
    }else{
      setCodCultura(cod)
      setVisibleForm(true)
      setCodEstadio(data)
      setUpdate(0)
    }
   
  }

  const handleClickEdit = (data, cod) => {
    setCodCultura(cod)

    setVisibleForm(true)

    setUpdate(data)
  }

  const handleClickTrash = (cod) => {
    Modal.confirm({
      title: 'Deseja remover o nível de controle?',
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delMoniNivelControle(cod)

        if (response.status === 200) {
          Notification({
            message: 'Nível de controle excluído com sucesso',
            type: 'success',
          })

          listEstadio()
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    listEstadio()
    if (!data) {
      setUpdate(0)
      setCodCultura(0)
      setCodEstadio(0)
    }
  }

  const handleSearch = (selectedKeys, confirm) => {
    confirm()
  }

  const handleReset = (clearFilters) => {
    clearFilters()
  }

  const listCultura = async () => {
    const resp = await tiposGerais()

    setDataCultura(resp)
  }
  /* Carregar o estadio e cultura toda vez que o componente for montado */
  useEffect(() => {
    listEstadio()
    listCultura()
  }, [])

  /*   const onChangeTipoMetrica = (cod, name, id) => {
      const dados = JSON.parse(JSON.stringify(dataItemNivelControle))
  
      const index = dados.findIndex((rec) => rec.cod === id)
  
      dados[index].cod_tipo_metrica = cod
      dados[index].tipo_metrica_nome = name.children
      setDataItemNivelControle(dados)
    } */

  const getNameMEtrica = (metrica) => {
    switch (metrica) {
      case 1:
        metrica = 'Unidade'
        break
      case 2:
        metrica = 'Escala'
        break
      case 3:
        metrica = 'Porcentagem'
        break

      default:
        break
    }

    return metrica
  }

  return (
    <Container>
      <ViewPage
        rotina={36}
        onPermission={setPermissions}
        title='Nível de controle'
        btnClick={null}
        newHide
        /* chama o form ao clicar em editar */
        content={
          <MoniNivelControle
            codCultura={codCultura}
            codEstadio={codEstadio}
            onClose={(data) => handleCloseForm(data)}
            visible={visibleForm}
            listNivelControle={dataListEstadio}
            atualiza={update}
          />
        }
      >
        <div style={{ height: 'calc(90vh - 100px)', overflow: 'auto' }}>
          <Collapse accordion>
            {dataCultura.map((data) => {
              const estadio = dataListEstadio.find((info) => info.cod_cultura === data.cod)
              const itens = estadio?.item === undefined ? [] : estadio.item

              /* estadio.map((met) => {
                  console.log("Itens MEtrica", met.cod_tipo_metrica);
                  return 
                }) */

               

              return (
                <Panel
                  header={data.nome}
                  key={data.cod}
                  extra={
                    <Space>
                      {itens.length > 0 ? (
                        <Button
                          disabled={itens.length <= 0}
                          onClick={() => handleClickEdit(estadio?.cod, estadio?.cod_cultura)}
                          hidden={permissions?.alteracao === 0}
                          shape='circle'
                          icon={<Icon component={() => <Icons icon={faPen} />} />}
                        />
                      ) : (
                        <Button
                          onClick={() => handleClickNewEstadio(data.cod, estadio?.cod)}
                          hidden={permissions?.inclusao === 0}
                          type='primary'
                          shape='circle'
                          icon={<Icon component={() => <Icons icon={fasPlus} />} />}
                        />
                      )}

                      <Button
                        disabled={itens.length <= 0}
                        type='primary'
                        danger
                        hidden={permissions?.exclusao === 0}
                        onClick={() => handleClickTrash(estadio.cod)}
                        shape='circle'
                        icon={<Icon component={() => <Icons icon={faTrash} />} />}
                      />
                    </Space>
                  }
                >
                  {/* Tabela mostra informações nivel de controle */}
                  <Table
                    loading={loadingTable}
                    scroll={{ y: 'calc(46.6vh - 50px)' }}
                    pagination={pagination}
                    footer={() => (
                      <div
                        style={{
                          display: 'flex',
                          width: '100%',
                          justifyContent: 'right',
                        }}
                      >
                        <b>Total geral: {itens.length}</b>
                      </div>
                    )}
                    size='small'
                    columns={[
                      {
                        key: 1,
                        dataIndex: 'categoria_nome',
                        width: '140px',
                        title: 'Categoria',
                      },
                      {
                        key: 2,
                        dataIndex: 'especie_nome',
                        title: 'Tipo',
                        /* filtrar por ordem alfabetica */
                        sorter: (a, b) => a.especie_nome.localeCompare(b.especie_nome),
                        filterDropdown: ({
                          setSelectedKeys,
                          selectedKeys,
                          confirm,
                          clearFilters,
                        }) => (
                          <div style={{ padding: 8 }}>
                            <Input
                              ref={searchInputTable}
                              placeholder='Pesquisar por tipo'
                              value={selectedKeys[0]}
                              onChange={(e) =>
                                setSelectedKeys(e.target.value ? [e.target.value] : [])
                              }
                              onPressEnter={() => handleSearch(selectedKeys, confirm)}
                              style={{ marginBottom: 8, display: 'block' }}
                            />
                            <Space>
                              <Button
                                type='primary'
                                onClick={() => handleSearch(selectedKeys, confirm)}
                                icon={<SearchOutlined />}
                                size='small'
                                style={{ width: 90 }}
                              >
                                Buscar
                              </Button>
                              <Button
                                onClick={() => {
                                  confirm({ closeDropdown: false })
                                  handleReset(clearFilters)
                                }}
                                size='small'
                                style={{ width: 90 }}
                              >
                                Resetar
                              </Button>
                            </Space>
                          </div>
                        ),
                        filterIcon: (filtered) => (
                          <SearchOutlined
                            style={{
                              color: filtered ? '#1890ff' : undefined,
                            }}
                          />
                        ),
                        onFilter: (value, record) =>
                          // @ts-ignore
                          record.especie_nome
                            ? record.especie_nome
                                .toString()
                                .toLowerCase()
                                // @ts-ignore
                                .includes(value.toLowerCase())
                            : '',
                        onFilterDropdownVisibleChange: (visible) => {
                          if (visible) {
                            setTimeout(() => searchInputTable.current.select(), 100)
                          }
                        },
                      },
                      {
                        key: 3,
                        dataIndex: 'variacao_especie_nome',
                        title: 'Variação',
                      },
                      {
                        key: 4,
                        dataIndex: 'valor',
                        width: '70px',
                        title: 'Valor',
                      },
                      {
                        key: 5,
                        dataIndex: 'cod_tipo_metrica',
                        width: '150px',
                        title: 'Métrica',
                        /*  ...filterTable(
                             true,
                             true,
                             'cod_tipo_metrica',
                             dataItemNivelControle,
                             false
                           ), */
                        render: (metrica) => {
                          // lógica com retorno apropriado ()

                          /* getNameMEtrica */
                          return <span>{getNameMEtrica(metrica)}</span>
                        },
                      },
                      {
                        key: 0,
                        dataIndex: 'inverter_escala',
                        width: '110px',
                        title: 'Inverter escala',
                        render: (data) => <>{data === 1 ? 'Sim' : 'Não'}</>,
                      },
                      {
                        key: 0,
                        dataIndex: 'totalizar',
                        width: '85px',
                        title: 'Totalizar',
                        render: (met) => <>{met.cod_tipo_metrica === 2 ? 'Sim' : 'Não'}</>,
                      },
                    ]}
                    dataSource={itens}
                  />
                </Panel>
              )
            })}
          </Collapse>
        </div>
      </ViewPage>
    </Container>
  )
}
export default PageNivelControle
