import { AxiosResponse } from 'axios'
import { api } from '../api'

export interface TypeAdmConsultoria {
  itens: [AdmConsultoriaItens]
}

export interface AdmConsultoriaItens {
  cod: number
  nome: string
  status: number
  cpf_cnpj: string
  rg: string
  uf_rg: string
  nacionalidade: string
  tipo: string
  sexo: string
  nascimento: string
  cod_escolaridade: string
  cod_estado_civil: string
  cod_profissao: string
  endereco: string
  complemento: string
  cep: string
  cod_cidade: string
  fone: string
  fone2: string
  email: string
  razao_social: string
  linha2: string
  linha1: string
  logo: string
  ie: string
}

interface TypeQuery {
  pagination?: number | -1
}

const url = '/consultoria'

export const getAdmConsultoria = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    pagination,
    ...params,
  }

  const response: AxiosResponse<TypeAdmConsultoria> = await api.get(url, {
    params,
  })

  return response
}

export const postAdmConsultoria = async (data) => {
  const response: AxiosResponse<TypeAdmConsultoria> = await api.post(url, data, {
    params: {},
  })

  return response
}

export const putAdmConsultoria = async (cod, data) => {
  const response: AxiosResponse<TypeAdmConsultoria> = await api.put(`${url}/${cod}`, data, {
    params: {},
  })

  return response
}

export const delAdmConsultoria = async (cod) => {
  const response: AxiosResponse<TypeAdmConsultoria> = await api.delete(`${url}/${cod}`, {
    params: {},
  })

  return response
}
