import { orderBy } from "lodash";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm } from "antd";
import { useEffect, useRef, useState } from "react";
import { faCircleArrowRight, faUpload, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { leadZero } from "../../../../../../../../utils";
import { tryError } from "../../../../../../../../services/Afins";
import { typeMessage } from "./meta";
import { importarArquivoTalhao } from "../../../../../meta";
import { usePostImportarPontos } from "../../../../../../../../services/monitoramento/padraoDePontos/novo_index";
import { TalhaoCultivaveisItens } from "../../../../../../../../services/TalhaoSafra";

//COMPONENTS
import ButtonCustom from "../../../../../../../ButtonCustom";
import { Inline } from "../../../../../../../CustomUi/inline";
import { AgrupamentoTalhaoItens } from "../../../../../../../../services/agrupamentoTalhao";

//TYPES
type SalvarPontos = {
    cod_talhao: number,
    latitude: number,
    longitude: number,
    nome: string,
    is_subarea?: boolean
}
type MarkerType = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
}
//PROPS 
interface Props {
    handleChangePropriedade: Function;
    handleChangeAgrupamento: Function;
    handleChangeTalhao: Function;
    handleChangeSubarea: Function;
    markerDefault: MarkerType[];
    tipoMonitoramento: number | null;
    cod_agrupamento: number | null;
    cod_propriedade_atual: number | null;
    cod_talhao_atual: number | null;
    cod_subarea_atual: number | null;
    list_talhoes: TalhaoCultivaveisItens[];
    carregando_remover_pontos: boolean;
    list_Agrupamentos: AgrupamentoTalhaoItens[]
    polygonSubArea: google.maps.Polygon;
    nome_subarea: string;
    atualizarListaPropriedades: Function;
}

export const ImportarPontos: React.FC<Props> = ({ handleChangePropriedade, handleChangeTalhao, markerDefault,
    tipoMonitoramento, cod_propriedade_atual, cod_talhao_atual, list_talhoes, cod_subarea_atual, carregando_remover_pontos, 
    cod_agrupamento, list_Agrupamentos, handleChangeAgrupamento, handleChangeSubarea, polygonSubArea, nome_subarea, 
    atualizarListaPropriedades
}) => {

    //REFs
    const inputRef = useRef(null);

    //STATES
    const [jsonResult, setJsonResult] = useState<any>(null);
    const [popup_confirm, setPopup_confirm] = useState<boolean>(false);
    const [loading_confirm, setLoading_confirm] = useState<boolean>(false);
    const [dados_confirm, setDados_confirm] = useState([]);
    const [qtd_confirm, setQtd_confirm] = useState<number>(0);

    //API
    const { carregando_importar, mutate_importar } = usePostImportarPontos();

    useEffect(() => {

        function adaptarJsonPontos(kml_convertido: any[]) {

            if (kml_convertido.length > 0) {

                let novo_formato = kml_convertido?.map((item) => {

                    return {
                        cod_talhao: Number(item.cod_talhao),
                        latitude: Number(item.latitude),
                        longitude: Number(item.longitude),
                        nome: item.nomeTalhao
                    }
                })

                return novo_formato;
            }
            return undefined
        }

        if ((jsonResult?.placemarks ?? []).length > 0) {
            vincularPontosATalhoesDaSafra(adaptarJsonPontos(jsonResult?.placemarks ?? []))
        }

    }, [jsonResult]);


    function vincularPontosATalhoesDaSafra(kml_pontos_antigo: SalvarPontos[]) {
        const google = window.google;


        /**vincula nome e cod do talhão de acordo com a cooredenada*/
        let vincular_talhoes: SalvarPontos[] = kml_pontos_antigo.map(({ latitude, longitude }) => {
            const point = new google.maps.LatLng(latitude, longitude);
            return {
                cod_talhao: getTalhaoNameAndCodeOfCoordnates(point)?.cod_talhao ?? null,
                nome: getTalhaoNameAndCodeOfCoordnates(point)?.nome,
                latitude: latitude,
                longitude: longitude
            }
        });

        /**remove os itens que não fazem parte do talhão e add número do talhão de forma ordenada */
        let validar_e_ordenar: SalvarPontos[] = vincular_talhoes.filter(item=> item.cod_talhao !== null).map((item, index)=> {
            let new_name = (item?.nome)?.split('|')?.[0]
            return {
                ...item,
                nome: `${new_name} | ${index + 1}`
            }
        })

        clearInput();

        if (tipoMonitoramento === 1) {
            if (validar_e_ordenar.length < vincular_talhoes.length) {
                let qtd_excluidos = Number((vincular_talhoes.length) - (validar_e_ordenar.length))
                setQtd_confirm(qtd_excluidos);
                setDados_confirm(validar_e_ordenar);
                setPopup_confirm(true);
                setLoading_confirm(true);

                return null;
            }
            salvarTalhaoImportado(validar_e_ordenar);
            return null
        }

        if (tipoMonitoramento === 2) {
            let find_agrupamento: AgrupamentoTalhaoItens = list_Agrupamentos.find(item=> item.cod === cod_agrupamento);
            let cod_talhoes_agrupados: number[] = (find_agrupamento?.talhao ??[]).map((item)=> item.cod_talhao);

            let filtrar_talhoes = validar_e_ordenar.filter(item => cod_talhoes_agrupados.includes(item.cod_talhao)) || [];

            if (filtrar_talhoes.length < validar_e_ordenar.length) {
                let qtd_excluidos = Number((validar_e_ordenar.length) - (filtrar_talhoes.length))
                setQtd_confirm(qtd_excluidos);
                setDados_confirm(filtrar_talhoes);
                setPopup_confirm(true);
                setLoading_confirm(true);

                return null;
            }

            salvarTalhaoImportado(filtrar_talhoes);
            return null
        }

        if (tipoMonitoramento === 3) {
            let apenas_talhao_select = validar_e_ordenar.filter(item=> item.cod_talhao === cod_talhao_atual);


            if (apenas_talhao_select.length < validar_e_ordenar.length) {
                let qtd_excluidos = Number((validar_e_ordenar.length) - (apenas_talhao_select.length))
                setQtd_confirm(qtd_excluidos);
                setDados_confirm(apenas_talhao_select);
                setPopup_confirm(true);
                setLoading_confirm(true);

                return null;
            }

            salvarTalhaoImportado(apenas_talhao_select);
            return null
        }
 
        if (tipoMonitoramento === 4) {

            let vincular_subarea: SalvarPontos[] = validar_e_ordenar.map((item) => {
                const area_sub = new google.maps.LatLng((item.latitude), item.longitude);
                let nome_split = (item.nome)?.split(' | ');
                return {
                    ...item,
                    nome: `${nome_subarea} | ${nome_split?.[1]}`,
                    is_subarea: google.maps.geometry.poly.containsLocation(area_sub, polygonSubArea)
                }
            });

            let apenas_subarea_select = vincular_subarea.filter(item=> item.is_subarea === true)


            if (apenas_subarea_select.length < validar_e_ordenar.length) {
                let qtd_excluidos = Number((validar_e_ordenar.length) - (apenas_subarea_select.length))
                setQtd_confirm(qtd_excluidos);
                setDados_confirm(apenas_subarea_select);
                setPopup_confirm(true);
                setLoading_confirm(true);

                return null;
            }

            salvarTalhaoImportado(apenas_subarea_select);
            return null
        }


        salvarTalhaoImportado(validar_e_ordenar)
    }

    function salvarTalhaoImportado(pontos) {

        const nomesIguais = (novo: any) => {
            return markerDefault.some(
                item => (item.nome === novo.nome)
            );
        };

        let remove_iguais = pontos.filter(ponto => !nomesIguais(ponto));

        function refetchPontosPorTipo() {
            switch (tipoMonitoramento) {
                case 1:
                    handleChangePropriedade(cod_propriedade_atual);
                    break;
                case 2:
                    handleChangeAgrupamento(cod_agrupamento);
                    break;
                case 3:
                    handleChangeTalhao({ value: cod_talhao_atual });
                    break;
                case 4:
                    handleChangeSubarea(cod_subarea_atual);
                    break;
                default:
                    handleChangePropriedade(cod_propriedade_atual);
                    break;
            }
        }

        mutate_importar({
            pontos: remove_iguais,
            refetchPontos: ()=>{refetchPontosPorTipo();atualizarListaPropriedades();setPopup_confirm(false);setLoading_confirm(false)}
        })

    }

    function clearInput() {
        let dataTransfer = new DataTransfer();
      
        inputRef.current.files = dataTransfer.files
    }

    function clickImportarTalhao() {
        let input_upload = document.getElementById('importar_kml_pontos');
        input_upload.click()
    }

    /**Retorna o `nome` e o `cod` do talhão, baseado nas coordenadas enviadas */
    const getTalhaoNameAndCodeOfCoordnates: any = (coord: google.maps.LatLng) => {
        // console.log("list_talhoes: ", list_talhoes)
        try {
            const newPolygon = new google.maps.Polygon({
                fillColor: 'none',
                strokeColor: 'none',
            })
            const talhaoMarker = (list_talhoes ?? []).map((talhao) => {
                return {
                    nome: talhao?.nome,
                    cod_talhao: talhao?.cod,
                    coord: (talhao?.kml?.coordenadas ?? []).map((kml) => {
                        return { lat: kml?.latitude, lng: kml?.longitude }
                    }),
                }
            })

            const dataTalhaoMarker = talhaoMarker.filter((item) => {
                newPolygon.setPath(item.coord)
                if (google.maps.geometry.poly.containsLocation(coord, newPolygon)) {
                    return {
                        cod_talhao: item.cod_talhao,
                        latitude: coord.lat.toString(),
                        longitude: coord.lng.toString(),
                    }
                }
            })

            if (talhaoMarker.length > 0) {
                let typePost
                if (tipoMonitoramento === 4) {
                    typePost = { cod_talhao: cod_subarea_atual }
                    // typePost = { cod_talhao: form.getFieldValue(['cod_subarea']) }
                } else {
                    typePost = { cod_talhao: dataTalhaoMarker[0].cod_talhao }
                }
                const nameCodMarker = orderBy(
                    markerDefault.map((m) => {
                        return parseInt(m.nome.split('|')[1])
                    })
                )

                

                function isNanOrNomeCod() {
                    const isNan = Number.isNaN(nameCodMarker[nameCodMarker.length - 1]);

                    if (isNan) {
                        return 1;
                    }
                    return nameCodMarker[nameCodMarker.length - 1] + 1;
                }

                const newMarker = nameCodMarker.length <= 0 ? 1 : isNanOrNomeCod()
                    // nameCodMarker.length <= 0 ? 1 : isNan ? 1 : nameCodMarker[nameCodMarker.length - 1] + 1

                return {
                    nome: `${dataTalhaoMarker[0].nome} | ${newMarker}`,
                    ...typePost
                }
            }
            return false
        } catch (error) {
            tryError(error)
        }
    }

    function desabilitarButton(): boolean {
        const desabilitar_default = (carregando_importar || carregando_remover_pontos);
    
        const camposPorTipo = {
            1: [cod_propriedade_atual],
            2: [cod_propriedade_atual, cod_agrupamento],
            3: [cod_propriedade_atual, cod_talhao_atual],
            4: [cod_propriedade_atual, cod_talhao_atual, cod_subarea_atual]
        };
    
        const camposParaVerificar = camposPorTipo[tipoMonitoramento] || [];
        
        const algumCampoNulo = camposParaVerificar.some(campo => campo === null);
    
        return algumCampoNulo || desabilitar_default;
    }
    
    

    return (
        <>
            <input type="file" ref={inputRef} style={{ display: 'none' }} accept=".kml" onChange={(e: any) => { importarArquivoTalhao(e, setJsonResult) }} id={'importar_kml_pontos'} />
            <Popconfirm
                placement="topRight"
                title={typeMessage('title', tipoMonitoramento)}
                open={popup_confirm}
                description={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>
                        <span>
                            <b>{leadZero(qtd_confirm)} pontos</b> estão fora da área delimitada {typeMessage('message', tipoMonitoramento)} e <b>serão descartados</b>. Deseja continuar?
                        </span>

                        <Inline justifyContent="flex-end">

                            <ButtonCustom
                                onClick={() => { setPopup_confirm(false);setLoading_confirm(false);setDados_confirm([])}}
                                type="tertiary"
                                danger
                                icon={<FontAwesomeIcon icon={faXmark} />}
                            >
                                Cancelar
                            </ButtonCustom>

                            <ButtonCustom
                                onClick={() => { salvarTalhaoImportado(dados_confirm) }}
                                icon={<FontAwesomeIcon icon={faCircleArrowRight} />}
                                loading={carregando_importar}
                                disabled={carregando_importar}
                            >
                                Continuar
                            </ButtonCustom>

                        </Inline>
                    </div>}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Button style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}>
                    <ButtonCustom
                        onClick={() => { clickImportarTalhao() }}
                        icon={<FontAwesomeIcon icon={faUpload} />}
                        type='secondary'
                        loading={carregando_importar || loading_confirm}
                        disabled={desabilitarButton() || loading_confirm}
                    >
                        Importar
                    </ButtonCustom>
                </Button>
            </Popconfirm>
            
        </>
    )
};