import { String } from "lodash";
import { 
    Item__MapaFertilidade__Config, 
    Item__MapaFertilidade__Config__Secoes, 
    Item__MapaFertilidade__Config__Secoes__Mapa 
} from "../../../../../services/novoConfigBook/get";

type TypeChecarVisibilidade = {
    config_elementos: Item__MapaFertilidade__Config[], 
    cod_elemento: number, 
    tipo_secao_ou_profundidade: string,
    tipo_mapa: boolean,
    nome_elemento: string;
}

type TypeProfundidades = {
    profundidade: number,
    visivel: boolean,
    observacao: string
}[]

function obterProfundidadeCalcario(calcario: string): string {
    let remove = calcario.replace('Calcário | ',"").replace('Calcário ',"");
    return remove;
}


/**verifica se nas `configurações do book` o elemento está marcado como `visível`.
 * 
 * leva em consideração se está visivel na `seção` (FERTILIDADE | INDICADORES | PRESCRIÇÃO)
 * 
 * `Não` leva em consideração se está visivel no tipo de mapa (tipo de krigagem) (INTERPRETADO | DSTRIBUÍDO)
 * 
 * leva em considereação se está visivel na profundidade
*/
export function verificarVisibilidadeElemento(args: TypeChecarVisibilidade): boolean {

    const { config_elementos, cod_elemento, tipo_secao_ou_profundidade, tipo_mapa, nome_elemento } = args;
    
    const index_tipo_de_mapa: 0|1 = tipo_mapa ? 0 : 1; // Assume que 'interpretado' sempre estará na posição 0
    
    const encontrar_elemento: Item__MapaFertilidade__Config = config_elementos?.find(item => item.elemento === cod_elemento);

    if (!encontrar_elemento) {
        return false;
    }
    
    const secoes: Item__MapaFertilidade__Config__Secoes[] = encontrar_elemento.secoes ?? [];
    const mapas: Item__MapaFertilidade__Config__Secoes__Mapa[] = secoes?.[0]?.tipos_mapa ?? [];
    const profundidades: TypeProfundidades = mapas?.[index_tipo_de_mapa]?.profundidades ?? [];
    const profundidades_calc: TypeProfundidades = mapas?.[0]?.profundidades ?? [];
    
    const isSecaoFertilidade: boolean = tipo_secao_ou_profundidade !== 'indicadores' && tipo_secao_ou_profundidade !== 'prescricoes';
    const isVisivelNaSecao: boolean = secoes?.[0]?.visivel ?? false;
    const isVisivelNoMapa: boolean = mapas?.[index_tipo_de_mapa]?.visivel ?? false;
    
    if (!isVisivelNaSecao) {
        return false;
    }
    
    if (isSecaoFertilidade) {
        if (isVisivelNoMapa === true || isVisivelNoMapa === false) {
            const codProfundidade = obterCodProfundidade(tipo_secao_ou_profundidade);
            const profundidadeItem = profundidades.find(item => item.profundidade === codProfundidade);
            return profundidadeItem?.visivel ?? false;
        }
        return false;
    }

    // if (tipo_secao_ou_profundidade === 'prescricoes' && cod_elemento === 30) {
    //     console.log("nome_elemento: ", nome_elemento)
    //     if (isVisivelNoMapa === true || isVisivelNoMapa === false) {
    //         const codProfundidade = obterCodProfundidade(obterProfundidadeCalcario(nome_elemento));
    //         const profundidadeItem = profundidades.find(item => item.profundidade === codProfundidade);
    //         return profundidadeItem?.visivel ?? false;
    //     }
    //     return false;
    // }
    
    
    if (tipo_secao_ou_profundidade === 'indicadores') {
        return isVisivelNoMapa;
    }
    
    if (tipo_secao_ou_profundidade === 'prescricoes') {

        if (cod_elemento === 30) {
            if (nome_elemento === undefined && nome_elemento === null) {
                return true;
            }
            if (isVisivelNoMapa === true || isVisivelNoMapa === false) {
                const codProfundidade = obterCodProfundidade(obterProfundidadeCalcario(nome_elemento));
                const profundidadeItem = profundidades_calc.find(item => item.profundidade === codProfundidade);

                return profundidadeItem?.visivel ?? false;
            }
            return false;
        }

        return isVisivelNaSecao;
    }

    return false;
}

/**recebe o `nome da profundidade` como parametro, e retorna o `código da profundidade` */
export function obterCodProfundidade(nome_profundidade: string): number {

    let profundidades = {
        '00-20': 1,
        ' 00-20': 1,
        '20-40': 2,
        '40-60': 3,
        '00-50': 4,
        '00-30': 5,
        ' 00-30': 5,
        '00-40': 6,
        ' 00-40': 6,
        'micro': 7,
        undefined: -1,
        null: -1,
        NaN: -1,
    };

    return profundidades?.[nome_profundidade]
}


export function obterObservacao(params: TypeChecarVisibilidade): string {

    const { config_elementos, cod_elemento, tipo_secao_ou_profundidade, tipo_mapa, nome_elemento } = params;

    let elemento:Item__MapaFertilidade__Config =  config_elementos?.find((item)=> item.elemento === cod_elemento);

    if (!elemento) {
        return '';
    }

    const index_tipo_de_mapa: 0|1 = tipo_mapa ? 0 : 1; // Assume que 'interpretado' sempre estará na posição 0

    const secoes: Item__MapaFertilidade__Config__Secoes[] = elemento.secoes ?? [];
    const mapas: Item__MapaFertilidade__Config__Secoes__Mapa[] = secoes?.[0]?.tipos_mapa ?? [];
    const isSecaoFertilidade: boolean = tipo_secao_ou_profundidade !== 'indicadores' && tipo_secao_ou_profundidade !== 'prescricoes';
    const profundidades: TypeProfundidades = mapas?.[index_tipo_de_mapa]?.profundidades ?? [];

    if (tipo_secao_ou_profundidade==='prescricoes' && cod_elemento != 30) {
        return elemento?.secoes?.[0]?.observacao;
    }

    if (tipo_secao_ou_profundidade==='indicadores') {
        return elemento?.secoes?.[0]?.tipos_mapa?.[index_tipo_de_mapa]?.observacao;
    }

    
    if (isSecaoFertilidade) {
        const codProfundidade = obterCodProfundidade(tipo_secao_ou_profundidade);
        const profundidadeItem = profundidades.find(item => item.profundidade === codProfundidade);

        return profundidadeItem?.observacao ?? '';   
    }

    if (tipo_secao_ou_profundidade==='prescricoes' && cod_elemento === 30) {
        const codProfundidade = obterCodProfundidade(obterProfundidadeCalcario(nome_elemento));
        const profundidadeItem = profundidades.find(item => item.profundidade === codProfundidade);

        return profundidadeItem?.observacao ?? ''; 
    }

    return '';
}