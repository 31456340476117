import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'
import { TalhaoItens } from '../Talhao'
import { TalhaoCultivaveisItens } from '../TalhaoSafra'

export interface TypeGetPropriedade {
  itens: [PropriedadeItens]
}

export interface PropriedadeItens {
  cod?: number
  nome: string
  status: number
  // 1: proprio / 2:Arendado
  cod_tipo?: 1 | 2
  cod_conta: number
  cod_cliente: number
  cod_calcario?: number
  arquivo?: string
  cliente_nome?: string
  area?: number
  area_total_talhao_safra?: number
  cidade?: {
    cod: number
    cod_uf: number
    codigo: number
    nome: string
    status: 1 | 0
    uf: string
    uf_nome: string
    uf_sigla: string
  }
  matricula?: string
  cod_cidade?: number
  cod_consultor?: number
  arquivo_url?: string
  arquivo_base64?: string
  talhao: TalhaoItens[]
  nameCity?: string
}
export interface PropriedadeItensMoni {
  cod?: number
  nome: string
  status: number
  // 1: proprio / 2:Arendado
  cod_tipo?: 1 | 2
  cod_conta: number
  cod_cliente: number
  arquivo?: string
  cliente_nome?: string
  area?: number
  cidade?: {
    cod: number
    cod_uf: number
    codigo: number
    nome: string
    status: 1 | 0
    uf: string
    uf_nome: string
    uf_sigla: string
  }
  matricula?: string
  cod_cidade?: number
  arquivo_url?: string
  arquivo_base64?: string
  talhao: TalhaoCultivaveisItens[]
  nameCity?: string
}

export const getPropriedade = async (
  codPropriedade,
  embed = '',
  codCliente = null,
  codSafra = null,
  areaTotalTalhaoNaSafra = null
) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso ? config.acesso : 'conta'

  let url = `/propriedade?codConta=${config.conta}&codCliente=${
    codCliente === null ? config.codCliente : codCliente
  }&cod=${codPropriedade}&embed=${embed}&pagination=-1&areaTotalTalhaoNaSafra=${codSafra}`

  if (codSafra !== null) {
    url += `&comTalhaoSafra=${codSafra === null ? config.codSafra : codSafra}`
  } else {
    url += `&comTalhaoSafra=${' '}`
  }

  const response: AxiosResponse<TypeGetPropriedade> = await api.get(url)

  return response
}

export const getPropriedadeLiberada = async (
  codUsuarioPropriedade = null,
  codCliente = null,
  codClientePropriedade = null
) => {
  const config = localConfig()

  const { conta } = config
  const codLocal = config.codCliente

  if (codLocal === '') {
    codCliente = ''
  } else {
    codClientePropriedade = null
    codCliente = codLocal
  }

  api.defaults.headers.Acesso = config.acesso

  const resp: AxiosResponse<TypeGetPropriedade> = await api.get('/propriedade-liberada-usuario', {
    params: {
      codConta: conta,
      codCliente,
      codClientePropriedade,
      pagination: -1,
      codUsuarioPropriedade,
    },
  })

  return resp
}

export const postPropriedade = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetPropriedade> = await api.post(
    `/propriedade?codConta=${config.conta}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const putPropriedade = async (codCliente, cod, data, pagination = -1) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetPropriedade> = await api.put(
    `/propriedade/${cod}?codConta=${config.conta}&codCliente=${codCliente}&pagination=${pagination}`,
    data
  )

  return response
}

export const getDocPropriedade = async (cod, file) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse = await api.get(
    `/propriedade/${cod}/visualizar/arquivo/${file}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
export const delPropriedade = async (cod, codCliente, itempro) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPropriedade> = await api.delete(
    `/propriedade/${cod}?codConta=${config.conta}&codCliente=${codCliente}`
  )

  return response
}
