import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";

//APP
import { geralKmlPontos } from "../../../../../../../gerarKml";

//COMPONENTS
import ButtonCustom from "../../../../../../../ButtonCustom";

//TYPES
type MarkerType = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
}
type dadosPontos = {
    INDICE: string,
    LATITUDE: string,
    LONGITUDE: string,
    NOME: number,
    NOME_TALHAO: string,
    COD: number,
    COD_TALHAO: number | string
}
//PROPS
interface Props {
    markerDefault: MarkerType[];
    carregando_remover_pontos: boolean;
    tipoMonitoramento: number;
    nome_propriedade_atual: string;
    nome_talhao_atual: string;
    nome_agrupamento_atual: string;
    nome_subarea_atual: string;
    fakeButton: Function
}

export const ExportarPontos: React.FC<Props> = ({ markerDefault, carregando_remover_pontos, tipoMonitoramento,
    nome_propriedade_atual, nome_talhao_atual, nome_agrupamento_atual, nome_subarea_atual, fakeButton
}) => {

    //REFs
    const url = useRef(null);

    //STATES
    const [fake_loading, setFake_loading] = useState<boolean>(false);

    function exportarTalhao(dados_gerais: any) {

        function nomeKML() {
            switch (tipoMonitoramento) {
                case 1:
                    return nome_propriedade_atual;
                case 2:
                    return `${nome_propriedade_atual} - (Agrupamento ${nome_agrupamento_atual})`;
                case 3:
                    return `${nome_propriedade_atual} - (Talhão ${nome_talhao_atual})`;
                case 4:
                    return `${nome_propriedade_atual} - (Subárea ${nome_subarea_atual} do talhão ${nome_talhao_atual})`;
                default:
                    return nome_propriedade_atual;
            }
        }
        function nomeArquivo() {
            switch (tipoMonitoramento) {
                case 1:
                    return `pontos_propriedade__${String(nome_propriedade_atual)}`;
                case 2:
                    return `pontos_agrupamento__${String(nome_agrupamento_atual)}__(${String(nome_propriedade_atual)})`;
                case 3:
                    return `pontos_talhão__${String(nome_talhao_atual)}__(${String(nome_propriedade_atual)})`;
                case 4:
                    return `pontos_subárea__${String(nome_subarea_atual)}__(${String(nome_talhao_atual)})__(${String(nome_propriedade_atual)})`;
                default:
                    return `pontos__${String(nome_propriedade_atual)}`;
            }
        }

        let coordenadas: dadosPontos[] = dados_gerais.map((item, index) => ({
            INDICE: String(index + 1),
            LATITUDE: item.latitude,
            LONGITUDE: item.longitude,
            NOME: index + 1,
            NOME_TALHAO: item.nome,
            COD: item.cod,
            COD_TALHAO: item.cod_talhao ?? 0
        }));

        const dadosGerarKml = {
            pontos: coordenadas,
        }

        const response = geralKmlPontos(dadosGerarKml, `${nomeKML() ?? 'Pontos KML'}`)

        const blob = new Blob([response], {
            type: 'application/octet-stream',
        });

        setTimeout(() => {
            setFake_loading(false);
            url.current.href = URL.createObjectURL(blob)
            url.current.download = `${nomeArquivo()}.kml`
            url.current.click();
        }, 1000);


    }

    return (
        <a href={`#exportar`} ref={url} style={{position:'absolute',top:0,left:0,zIndex:10}}>
            <ButtonCustom
                onClick={() => { setFake_loading(true); exportarTalhao(markerDefault); fakeButton()}}
                icon={<FontAwesomeIcon icon={faFileExport} />}
                loading={fake_loading}
                disabled={fake_loading ? true : (markerDefault?.length === 0 || carregando_remover_pontos)}
            >
                Exportar
            </ButtonCustom>
        </a>
    )
}