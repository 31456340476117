import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

interface PropsState {
  value: string[]
}

const initialState: PropsState = { value: [] }

const listDate = createSlice({
  name: 'teste',
  initialState,
  reducers: {
    setListDates(state, action: PayloadAction<string[]>) {
      state.value = action.payload
    },
    resetListDates(state) {
      state.value = []
    },
  },
})

export const { setListDates, resetListDates } = listDate.actions
export default listDate.reducer
