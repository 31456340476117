
export function obterNomeObersavacao(cod_observacao: number) {
    const lista = {
        1: 'PH de calda',
        2: 'Umidade do solo',
        3: 'Umidade relativa do ar',
        4: 'Temperatura mínima',
        5: 'Temperatura máxima',
        6: 'Aplicação de calcário recente',
        7: 'Necessidade de pré-mistura',
        8: 'Teor de argila',
        9: 'Teor de Matéria Orgânica',
        10: 'Carry-over',
        11: 'Outros'
    };

    return lista?.[cod_observacao] ?? '';
}