
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetFornecedor {
  itens: [FornecedorItens]
}

export interface FornecedorItens {
  cod: number
  cod_conta: number
  cod_cliente: number
  nome: string
  cpf_cnpj: string
  tipo: 'J' | 'F'
  razao_social: string
  endereco: string
  complemento: string
  cep: number
  cod_cidade: number
  fone: string
  fone2: string
  email: string
  status: 1 | 0
  cod_categoria: number
  endereco_numero: string
  bairro: string
  inscricao_estadual: number
  cidade: [
    {
      cod: number
      codigo: number
      nome: string
      uf: string
      cod_uf: number
      status: 1 | 0
      uf_nome: string
      uf_sigla: string
    }
  ]
}

export const getFornecedor = async (codCategoria, codFornecedor) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetFornecedor> = await api.get(
    `/fornecedor?codConta=${config.conta}&codCliente=${codCategoria === 2 ? '' : config.codCliente
    }&codCategoria=${codCategoria}&cod=${codFornecedor}&pagination=-1`
  )

  return response
}


export const postFornecedor = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetFornecedor> = await api.post(
    `/fornecedor?codConta=${config.conta}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const putFornecedor = async (codFornecedor, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetFornecedor> = await api.put(
    `/fornecedor/${codFornecedor}?codConta=${config.conta}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const delFornecedor = async (codFornecedor) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetFornecedor> = await api.delete(
    `/fornecedor/${codFornecedor}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
