import React, { useEffect, useState } from 'react'

import { faArrowUp } from '@fortawesome/free-solid-svg-icons'
import Icons from '../Icons'
import { Container } from './styled'

interface Props {
  idBack: string
  idScroll: string
}

const BackToTopButton: React.FC<Props> = (props) => {
  const [showButton, setShowButton] = useState(false)

  const { idBack, idScroll } = props

  const handleScroll = (div: HTMLElement, divPosition: HTMLElement) => {
    setShowButton(div.scrollTop >= divPosition.offsetTop + 500)
  }

  useEffect(() => {
    const divScroll = document.getElementById(idScroll)
    const divPosition = document.getElementById(idBack)

    const handleScrollEvent = () => handleScroll(divScroll, divPosition)

    divScroll.addEventListener('scroll', handleScrollEvent)
    return () => {
      divScroll.removeEventListener('scroll', handleScrollEvent)
    }
  })

  const scrollToTop = () => {
    window.scrollTo({
      top: 0,
      behavior: 'smooth',
    })
  }

  return (
    <Container>
      {showButton && (
        <a href={`#${idBack}`} aria-hidden className='back-to-top-button' onClick={scrollToTop}>
          <Icons icon={faArrowUp} />
        </a>
      )}
    </Container>
  )
}

export default BackToTopButton
