import styled from 'styled-components'

export const Container = styled.div`
  .container-floating,
  .container-floating-line {
    position: absolute;
    background-color: white;
    width: 300px;
    max-height: 85vh;
    z-index: 99999;
    margin: 15px;
    border-radius: 5px;

    .container-floating-btn {
      display: flex;
      padding: 10px;
      width: 100%;
      justify-content: space-between;
      align-items: center;
    }
  }
  .container-floating-line {
    right: 0;
    margin-right: 35px;
  }
`
