import React from 'react'
import { DatePicker as AntdDatePicker, DatePickerProps as AntDatePickerProps } from 'antd'
import IMask from 'imask'
import dayjs from 'dayjs'

const DATE_FORMAT = 'DD/MM/YYYY'
const MASKED = IMask.createMask({
  blocks: {
    DD: { from: 1, mask: IMask.MaskedRange, to: 31 },
    MM: { from: 1, mask: IMask.MaskedRange, to: 12 },
    YYYY: { from: 1900, mask: IMask.MaskedRange, to: Number.MAX_VALUE },
  },
  format: (date: Date) => dayjs(date).format(DATE_FORMAT),
  mask: Date,
  parse: (date: string) => dayjs(date, DATE_FORMAT),
  pattern: DATE_FORMAT,
})

export type DatePickerProps = Omit<AntDatePickerProps, 'format' | 'picker' | 'onKeyDown'>

const DatePicker = (props: DatePickerProps) => (
  // @ts-ignore
  <AntdDatePicker
    format={DATE_FORMAT}
    onKeyDown={(event: React.KeyboardEvent<HTMLInputElement>) => {
      const input = event.target as HTMLInputElement
      input.value = MASKED.resolve(input.value)
    }}
    picker='date'
    placeholder={DATE_FORMAT}
    {...props}
  />
)

export default DatePicker
