/* eslint-disable react/require-default-props */
import React, { useEffect, useRef } from 'react'
import { ContainerHeader, Title, Logo, Linear, Address, ContainerFooter } from './styled'

import { useAccess } from '../../context/useAccess'
import logoAgroMarques from '../../assets/img/agromarques.png'
import logo from '../../assets/img/logo.png'

interface PropsReportHeader {
  title: string
  id?: string
}

interface PropsReportFooter {
  id?: string
}

export const ReportHeader: React.FC<PropsReportHeader> = (props) => {
  const { title, id } = props
  const { consultoria } = useAccess()

  const c = document.createElement('canvas')
  c.height = 20
  const ctx = c.getContext('2d')

  const grd = ctx.createLinearGradient(0, 0, 300, 0)
  grd.addColorStop(1, '#00770e')
  grd.addColorStop(0, '#ffffff')

  ctx.fillStyle = grd
  ctx.fillRect(0, 10, 300, 80)

  return (
    <ContainerHeader id={id}>
      <Title>
        <h3>{title?.toUpperCase()}</h3>
      </Title>
      <Logo>
        <img src={logoAgroMarques} alt='AgroMarques' />
      </Logo>
      <Linear src={c.toDataURL()} />
      <Address>
        {consultoria?.linha1}
        <p style={{ margin: 0 }} />
        {consultoria?.linha2}
      </Address>
    </ContainerHeader>
  )
}

export const ReportFooter: React.FC<PropsReportFooter> = (props) => {
  const { id } = props
  const { consultoria } = useAccess()

  const c = document.createElement('canvas')
  c.height = 20
  const ctx = c.getContext('2d')

  const grd = ctx.createLinearGradient(0, 0, 300, 0)
  grd.addColorStop(0, '#00770e')
  grd.addColorStop(1, '#ffffff')

  ctx.fillStyle = grd
  ctx.fillRect(0, 10, 300, 80)

  return (
    <ContainerFooter id={id}>
      <Linear src={c.toDataURL()} />
      <div
        style={{
          width: '100%',
          justifyContent: 'left',
          alignItems: 'left',
          textAlign: 'left',
          fontSize: '11px',
        }}
      >
        <img width='95px' src={logo} alt='SysAgro' />
      </div>
      <small style={{ fontSize: '10px', margin: 0 }}>Licenciado para: {consultoria.linha1}</small>
    </ContainerFooter>
  )
}
