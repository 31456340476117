
//PROPS
interface Props {
    children: string | React.ReactNode;
    paddingBottom?: number;
    fontSize?: number;
    style?: React.CSSProperties;
}

const Label: React.FC<Props> = ({ children, paddingBottom, fontSize, style }) => {
    return (
        <label style={{fontSize: fontSize ?? 14, fontWeight: 500, color: 'var(--text-color)', display: 'flex', paddingBottom: paddingBottom, ...style}}>
            {children}
        </label>
    )
};

export default Label;