import styled from "styled-components";


export const BotaoDefinicao = styled.div`
    display: flex;
    justify-content: flex-start;
    flex-direction: column;
    font-size: 14px;
    color: var(--primary-color);
    cursor: pointer;
    font-weight: 500;
    height: 35px; 
    transition: .3s;
    z-index: 2000 !important;

    div.BotaoDefinicao__conteudo {
        border-radius: 5px;
        height: 35px;
        transition: .3s;
        border: thin solid var(--primary-color);
        box-sizing: border-box;
    }
    div.BotaoDefinicao__conteudo:is([data-open = true]) {
        height: 150px;
    }

    div.BotaoDefinicao__wrapper {
        display: flex;
        align-items: center;
        gap: 10px;
        z-index: 2;
        border-radius: 5px;
        width: 100%;
        height: 35px;
        min-height: 35px;
        padding: 0 10px;
    }
    div.BotaoDefinicao__wrapper:hover {
        background-color: var(--primary-color);
        color: #fff;
    }

    img.BotaoDefinicao__img {
        width: 20px;
        filter: drop-shadow(2px 0 0 white) 
        drop-shadow(0 2px 0 white)
        drop-shadow(-2px 0 0 white) 
        drop-shadow(0 -2px 0 white);
    }

    div.BotaoDefinicao__lista {
        width: 100%;
        z-index: 1;
        display: flex;
        flex-direction: column;
        background-color: #fff;
        border-radius: 5px;
        /* transform: translateY(-150%); */
        opacity: 0;
        transition: .4s;
    }
    div.BotaoDefinicao__lista:is([data-open = true]) {
        opacity: 1;
        transform: translateY(0);
        z-index: 2000 !important;
    }
`

export const BotaoOpcao = styled.button`
    height: 35px;
    text-align: left;
    font-weight: 500;
    font-size: 14px;
    display: flex;
    gap: 10px;
    align-items: center;
    background-color: transparent;
    border: none;

    &&:hover {
        background-color: var(--primary-color);
        color: #fff;
        cursor: pointer;
    }
    &&:disabled {
        cursor: default;
    }
    
    img.BotaoDefinicao__lista__img {
        width: 20px;
        filter: drop-shadow(2px 0 0 white) 
        drop-shadow(0 2px 0 white)
        drop-shadow(-2px 0 0 white) 
        drop-shadow(0 -2px 0 white);
    }
`