import React, { useEffect, useState } from 'react'
import Input from '../../../CustomUi/input'
import { Select } from '../../../CustomUi/Select'
import {
  faHashtag,
  faHouse,
  faLocationDot,
  faMap,
  faPlus,
  faRoad,
} from '@fortawesome/free-solid-svg-icons'
import { message } from 'antd'
import { cepMask } from '../../../../services/Afins'
import { apenasNumeros } from '../../../../utils'
import Carregando from '../../../CustomUi/loading'

interface PropsEndereco {
  listarEstado?: any[]
  listarCidade?: any[]
  cep?: string
  setCep?: React.Dispatch<React.SetStateAction<string>>
  cepOriginal?: string
  setCepOriginal?: React.Dispatch<React.SetStateAction<string>>
  cidade?: string
  setCidade?: React.Dispatch<React.SetStateAction<string>>
  cidadeId?: number
  setCidadeId?: React.Dispatch<React.SetStateAction<number>>
  openCidade?: boolean
  setOpenCidade?: React.Dispatch<React.SetStateAction<boolean>>
  logradouro?: string
  setLogradouro?: React.Dispatch<React.SetStateAction<string>>
  numero?: string
  setNumero?: React.Dispatch<React.SetStateAction<string>>
  complemento?: string
  setComplemento?: React.Dispatch<React.SetStateAction<string>>
}

const FormEndereco: React.FC<PropsEndereco> = ({
  cep,
  setCep,
  cepOriginal,
  setCepOriginal,
  cidade,
  setCidade,
  cidadeId,
  setCidadeId,
  openCidade,
  setOpenCidade,
  logradouro,
  setLogradouro,
  numero,
  setNumero,
  complemento,
  setComplemento,
  listarCidade,
}) => {
  const [renderizou, setRenderizou] = useState(false)
  const [loading, setLoading] = useState(true)

  function checkCep(cepValue) {
    const cep = cepValue.replace(/\D/g, '')

    fetch(`https://viacep.com.br/ws/${cep}/json/`)
      .then((res) => res.json())
      .then((data) => {
        if (data.bairro !== '' && data.logradouro !== '') {
          setCidade(data?.localidade)
          const cidadeFiltrada = listarCidade.filter((item) => item.nome === data?.localidade)
          setCidadeId(cidadeFiltrada[0]?.cod)
          setLogradouro(data?.logradouro)
        } else {
          setCidade(data?.localidade)
          const cidadeFiltrada = listarCidade.filter((item) => item.nome === data?.localidade)
          setCidadeId(cidadeFiltrada[0]?.cod)
        }
      })
    message.destroy()
  }

  useEffect(() => {
    if (cep?.length === 9 && renderizou) {
      message.loading('Buscando...', 999999)
      checkCep(cep)
    }

    // console.log(cepOriginal, cep)

    if (cepOriginal !== cep) {
      setLogradouro('')
      setNumero('')
      setComplemento('')
    }
  }, [cep])

  useEffect(() => {
    setTimeout(() => {
      setRenderizou(true)
      setLoading(false)
    }, 300)
    const cidadeFiltrada = listarCidade.filter((item) => item.cod === cidadeId)
    setCidade(cidadeFiltrada[0]?.nome)
  }, [])

  if (loading) {
    return <Carregando justifyContent='center' height={500} legenda='Carregando...' animation />
  }

  return (
    <div className='line'>
      <Input
        label='CEP'
        icon={faLocationDot}
        placeholder='Digite o CEP'
        value={cepMask(cep)}
        onChange={setCep}
        width={'100%'}
        maxLength={9}
      />

      <Select
        label='Cidade'
        icon={faHouse}
        placeholder='Selecione a cidade'
        value={cidade}
        onChange={setCidade}
        onChangeID={setCidadeId}
        descricaoItem='nome'
        idItem='cod'
        open={openCidade}
        items={listarCidade}
        trigger={setOpenCidade}
      />

      <Input
        label='Logradouro'
        icon={faRoad}
        placeholder='Digite o logradouro'
        value={logradouro}
        onChange={setLogradouro}
        width={'100%'}
        height={'30%'}
      />
      <Input
        label='Número'
        icon={faHashtag}
        placeholder='Digite o número da residencia'
        value={numero}
        onChange={setNumero}
        width={'100%'}
      />

      <Input
        label='Complemento'
        icon={faPlus}
        placeholder='Digite o complemento'
        value={complemento}
        onChange={setComplemento}
        width={'100%'}
        height={'30%'}
      />
    </div>
  )
}

export default FormEndereco
