import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesMonitoramento {
  itens: ItensMonitoramentoTypes[]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface ItensMonitoramentoTypes {
  ponto: number
  cod?: number
  cod_perfil_monitoramento: number
  cod_estadio: number
  cod_talhao: number
  data_inicio: string
  talhao_observacao: string
  data_final: string
  usuario_nome: string
  estadio_nome: string
  hora_inicio: string
  colmo?: string
  hora_final: string
  longitude: number
  latitude: number
  altitude: string
  tempo: number
  repeticoes_remover: []
  monitoramento_repeticao: RepeticoesMonitoramentoType[]
}

export interface RepeticoesMonitoramentoType {
  repeticao: number
  longitude: number
  latitude: number
  data_inicio: string
  data_final: string
  hora_inicio: string
  hora_final: string
  tempo: number

  monitoramento_leitura: LeiturasMonitoramentoType[]
}

export interface LeiturasMonitoramentoType {
  cod_nivel_controle_item: number
  valor: string
  data_inicio: string
  data_final: string
  hora_inicio: string
  hora_final: string
  cod_usuario: number
  usuario_nome: string
  tempo: number
  nivel_controle_item: [
    {
      cod: number
      cod_nivel_controle: number
      cod_variacao_especie: number
      cod_tipo_metrica: number
      valor: string
      status: number
      create_time: string
      update_time: string
      delete_time: number
      cod_especie: number
      totalizar: number
      inverter_escala: number
      chave: number
      especie_nome: string
      variacao_especie_nome: string
      cod_categoria: number
      categoria_nome: string
    }
  ]
}

type TypesQuery = {
  cod?: number
  codTalhao?: number
  codPropriedade?: number
  codAgrupamentoPropriedade?: number
  embed?: string
  pagination?: number
  page?: number
}

const url = '/monitoramento'

export const getMonitoramento = async (params: TypesQuery) => {
  const { codCliente, conta, codSafra } = localConfig()
  const { pagination = -1 } = params
  const resp: AxiosResponse<TypesMonitoramento> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      codSafra,
      pagination,
      ...params,
    },
  })

  return resp
}

export const getMonitoramentoDatas = async (params: TypesQuery) => {
  const { codCliente, conta, codSafra } = localConfig()
  const { pagination = -1 } = params
  const resp: AxiosResponse<{ itens: { data_inicio: string }[] }> = await api.get(
    `${url}/datas-monitoradas`,
    {
      params: {
        codCliente,
        codConta: conta,
        codSafra,
        pagination,
        ...params,
      },
    }
  )

  return resp
}

export const postMonitoramento = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesMonitoramento> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putMonitoramento = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesMonitoramento> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delMonitoramento = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesMonitoramento> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
