import styled from 'styled-components'

export const Container = styled.div`
  position: relative;
  display: flex;
  height: 100vh;
  z-index: 2;
`

export const PdfPrint = styled.div`
  display: flex;
  flex-direction: column;
  font-variant: normal;
  height: 100%;
  width: 100%;
  @media print {
    @page {
      size: A4 landscape;
      margin: 0px;
    }
    body {
      visibility: hidden;
    }

    .break {
      width: 100%;
      height: 100%;
      page-break-inside: avoid;
      break-inside: avoid;
    }
  }

  .map-sub-capa {
    width: 220mm;
    height: 147mm;
  }
`

export const Capa = styled.div`
  position: relative;
  display: flex;
  width: 297mm;
  height: 210mm;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #bbd9cb;
  background-image: linear-gradient(#cee3d9 80px, #ffffff 300px);
  span,
  h2,
  div,
  i,
  h3 {
    font-family: 'Poppins', sans-serif;
    text-transform: capitalize;
    color: #498564;
  }

  span {
    font-weight: bold;
    font-size: 90px;
  }
  h2 {
    font-weight: 600;
    font-size: 30px;
  }
  div {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    justify-self: flex-start;
    flex-direction: column;
    img {
      width: 200px;
    }
    span {
      font-size: 15px;
      font-weight: 500;
    }
    i {
      font-size: 20px;
    }
  }
`

export const SubCapa = styled.div`
  position: relative;
  background-color: #ffffff;
  width: 297mm;
  height: 210mm;
  max-width: 297mm;
  max-height: 210mm;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  border: 1px solid #bbd9cb;
  z-index: 4999;
  background-image: linear-gradient(#cee3d9 80px, #ffffff 300px);
  div {
    padding: 0;
  }

  div.sub-capa-title {
    height: 20%;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    span {
      font-weight: bold;
      font-size: 40px;
      color: #498564;
      font-variant: small-caps;
    }
    h2 {
      font-weight: 600;
      font-size: 30px;
      color: #498564;

      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
    h3 {
      font-weight: bold;
      font-style: italic;
      color: #498564;
      font-variant: small-caps;
      text-transform: lowercase;

      &:first-letter {
        text-transform: uppercase;
      }
    }
    img {
      filter: drop-shadow(3px 2px 15px #000000);
    }
  }
`

export const Content = styled.div<{ zIndex: number }>`
  z-index: ${(p) => 5000 - p.zIndex};
  position: relative;
  width: 297mm;
  height: 210mm;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 90px;
  border: 1px solid #bbd9cb;
  float: left;
`

export const ContentMap = styled.div`
  position: absolute;
  margin: 25px;
  z-index: 9999;
  top: 0;
  background-color: #ffffff;

  .ant-descriptions-item-label {
    background-color: #ffffff !important;
  }

  table {
    th,
    td {
      border: 1px solid #dfdfdf !important;
    }

    .label-vazia {
      padding: 0 !important;
      border-bottom: none;
    }
  }
`

export const Escala = styled.div`
  display: flex;
  width: 100%;
  font-size: 12px;
  flex-direction: column;
  font-family: 'Poppins', sans-serif;
  box-sizing: border-box;
  padding: 10px;

  div {
    width: 100%;

    &:nth-child(1) {
      display: flex;
      height: 10px;
    }

    &:nth-child(2) {
      display: flex;
      justify-content: space-around;
      div {
        height: 20px;
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
  }
`
export const RodapeTtivos = styled.div`
  position: absolute;
  justify-content: flex-start !important;
  align-items: flex-start !important;
  text-transform: none !important;
  bottom: 15px;
  width: 100%;

  &::before {
    content: ' ';
    width: 100%;
    min-height: 8px;
  }

  div {
    margin-left: 15px;
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    gap: 5px;

    img {
      width: 95px;
    }
  }
  small {
    font-size: 10px;
    margin-left: 15px;
  }
`