import styled from "styled-components";

//STYLES
const Item = styled.div`
    width: 100%;
    height: auto;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 10px;
    border-right: thin solid rgba(0,0,0,0.06);

    :is([data-sorting = true]) {
        background: #fafafa;
    }

`;

//PROPS
interface Props {
    children?: React.ReactNode;
    width?: number | string;
    gap?: number;
    fontSize?: number;
    onClick?: Function;
    nowrap?: boolean;
    sorting?: boolean;
}

export const RowItem: React.FC<Props> = ({ children, width, gap, fontSize, onClick, nowrap, sorting }) => {
    
    return (
        <Item 
            style={{ 
                width: width, 
                minWidth: width, 
                gap: gap, 
                fontSize: fontSize
            }} data-sorting={sorting} 
            onClick={()=>{onClick?.()}}
        >
            <span style={{
                margin: 0,
                overflow: nowrap ? 'hidden' : undefined,
                textOverflow: nowrap ? 'ellipsis' : undefined,
                whiteSpace: nowrap ? 'nowrap' : undefined,
            }}
            >
                {children}
            </span>
        </Item>
    )
}