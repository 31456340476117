import styled from "styled-components";

export const NavegacaoDrawer = styled.div`
    width: 100%;
    height: 30px;
`

export const BodyDrawer = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    /* border-bottom: thin solid var(--border-color); */

    .BodyDrawer__box {
        width: 100%;
        box-sizing: border-box;
        padding: 0px 0px 0px 15px;
        border-left: 3px solid #8AC558;
        display: flex;
        flex-direction: column;
        gap: 15px;
    }

`