import { Col, message, Tabs, Transfer } from 'antd'
import { TransferDirection } from 'antd/lib/transfer'
import { AxiosResponse } from 'axios'
import React, { useEffect, useState } from 'react'
import TransferTree from '../../../../Components/tranferTree'
import ViewPage from '../../../../Components/ViewPage'
import { tryError } from '../../../../services/Afins'
import {
  delTipoAT,
  getTipoAT,
  postTipoAT,
  TypesTipoAT,
} from '../../../../services/consultoriaAgricola/tipoAT'
import {
  delTipoTechnician,
  getTipoTechnician,
  postTipoTechnician,
  TypesTipoTechnician,
} from '../../../../services/consultoriaAgricola/tipoTechnician'
import { EquipeItens, getEquipe, TypesEquipe } from '../../../../services/Equipe'

import { Container } from './styles'
import { getTipoLiderEquipe, postTipoLiderEquipe, TypesTipoLiderEquipe } from '../../../../services/consultoriaAgricola/tipoLiderEquipe'
import { getTipoEquipeLiderGerenciar } from '../../../../services/consultoriaAgricola/equipLiderGerenciar'
import { Notification } from '../../../../Components/notification'
import { log } from 'console'
import { getTipoConsultor, postTipoConsultor, TypesTipoConsultor } from '../../../../services/consultoriaAgricola/tipoConsultor'

const { TabPane } = Tabs

const UserTechnician: React.FC = () => {
  const [_visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataEquipe, setDataEquipe] = useState<EquipeItens[]>([])
  const [dataEquipeT, setDataEquipeT] = useState<EquipeItens[]>([])
  const [dataEquipeA, setDataEquipeA] = useState<EquipeItens[]>([])
  const [dataEquipeLider, setDataEquipeLider] = useState<EquipeItens[]>([])
  const [dataEquipeConsultor, setDataEquipeConsultor] = useState([])
  const [dataTechnician, setDataTechnician] = useState<string[]>([])
  const [dataAT, setDataAT] = useState<string[]>([])
  const [dataLider, setDataLider] = useState<string[]>([])
  const [loading, setLoading] = useState(false)
  const [checkMembro, setCheckMembro] = useState(false)

  const listEquipe = async () => {
    message.loading('Carregando...')
    setLoading(true)

    const resp: AxiosResponse<TypesEquipe> = await getEquipe({
      pagination: -1,
      embed: 'tipo_tecnico,tipo_assistente_tecnico,tipo_consultor,tipo_lider_equipe',
    })
    const dados = resp.data.itens

    const Eq = dados.filter(
      (rec) =>
        rec.assistente_tecnico === false &&
        rec.tecnico === false &&
        rec.consultor === false &&
        rec.lider_equipe === false
    )

    const At = dados.filter((item) => item.assistente_tecnico === true)
    const Tec = dados.filter((item) => item.tecnico === true)
    const lider = dados.filter((item) => item.lider_equipe === true)
    const consultor = dados.filter((item) => item.consultor === true)

    setDataEquipe(Eq)
    setDataEquipeA(At)
    setDataEquipeT(Tec)
    setDataEquipeLider(lider)
    setDataEquipeConsultor(consultor)
    setLoading(false)

    message.destroy()
  }



  const listTechnician = async () => {
    const resp: AxiosResponse<TypesTipoTechnician> = await getTipoTechnician({})

    const cods = resp.data.itens.map((item) => String(item.cod_usuario))

    setDataTechnician(cods)
  }

  const listAT = async () => {
    const resp: AxiosResponse<TypesTipoAT> = await getTipoAT({})

    const cods = resp.data.itens.map((item) => String(item.cod_usuario))

    setDataAT(cods)
  }

  const listLider = async () => {
    const resp: AxiosResponse<TypesTipoLiderEquipe> = await getTipoLiderEquipe({})

    const cods = resp.data.itens.map((item) => String(item.cod_usuario))

    setDataLider(cods)
  }

  const listConsultor = async () => {
    const resp: AxiosResponse<TypesTipoConsultor> = await getTipoConsultor({})

    const cods = resp.data.itens.map((item) => String(item.cod_usuario))

    // setDataEquipeConsultor(cods)
  }


  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleChangeMoveTransfer = async (
    moveKeys: any[],
    direction: 'left' | 'center' | 'lider' | 'right' | 'consultores',
    origin: 'left' | 'center' | 'lider' | 'right' | 'consultores'
  ) => {
    try {
      setLoading(true)
      let resp

      if (origin === 'lider') {
        
          // Adicione a verificação aqui antes de fazer a remoção
          const checkPromises = moveKeys.map(async codFunc => {
            const temMembro = await checkLiderHasMembers(codFunc);
  
            return { codFunc, temMembro };
          });

          const checkResults = await Promise.all(checkPromises);

          
          const filteredMoveKeys = checkResults
            .filter(result => !result.temMembro)
            .map(result => result.codFunc);


          moveKeys.forEach(cod => {

            dataEquipeLider.forEach(item => {

              if (item.cod_func === cod && !filteredMoveKeys.includes(cod)) {
                Notification({
                  message: `O líder de campo, possui um ou mais membros em sua equipe. Remova todos os membros da equipe para promover o líder.`,
                  type: 'warning',
                })
                
              }
            });
          
          });

          moveKeys = filteredMoveKeys

          
          if (filteredMoveKeys.length > 0) {
            resp = await postTipoLiderEquipe({ usuarios_remover: filteredMoveKeys });
          }

          setLoading(false)
      }

     if(moveKeys.length > 0){

      if (origin === 'center') {
        resp = await postTipoTechnician({ usuarios_remover: moveKeys })
      }



      if (origin === 'right') {
        resp = await postTipoAT({ usuarios_remover: moveKeys })
      }

      // if (origin === 'consultores') {
      //   resp = await post({ usuarios_remover: moveKeys })
      // }



      switch (direction) {
        case 'left':
          listEquipe()

          break
        case 'center':
          await postTipoTechnician({ usuarios: moveKeys })
          listEquipe()
          break
        case 'lider':
          await postTipoLiderEquipe({ usuarios: moveKeys })
          listEquipe()
          break
        case 'right':
          await postTipoAT({ usuarios: moveKeys })
          listEquipe()
          break
        case 'consultores':
          await postTipoConsultor({ usuarios: moveKeys })
          listEquipe()
          break

        default:
          break
      }
     }
    } catch (error) {
      tryError(error)
      setLoading(false)
    }
  }

  const checkLiderHasMembers = async (cod): Promise<boolean> => {
    setLoading(true)
    const respGe = await getTipoEquipeLiderGerenciar({
      codConsultor: cod,
    });

    const dados = respGe.data.itens;
  

    if (dados.length > 0) {
      return true
    }

    setLoading(false)

    return false;
  };

  useEffect(() => {
    listEquipe()
    listTechnician()
    listAT()
    listLider()
    // listConsultor()
  }, [])
  return (
    <Container>
      <ViewPage
        newHide
        title='Atribuição de cargos'
        // handleClickNew()
        btnClick={() => { }}
        content={null}
      >
        <TransferTree
          title={{ left: 'Usuários disponíveis', center: 'Técnicos de campo', lider: 'Líderes de campo', right: 'Assistentes Técnicos - AT', consultores: 'Consultores' }}
          loading={loading}
          dataLeft={dataEquipe.map((item) => {
            return {
              label: item.usuario_nome,
              value: item.cod_func,
              cod: item.cod,
              disabled: item.cod_consultor !== null,
            }
          })}
          dataCenter={dataEquipeT.map((item) => {
            return {
              label: item.usuario_nome,
              value: item.cod_func,
              cod: item.cod,
              disabled: item.cod_consultor !== null,
            }
          })}
          dataLider={dataEquipeLider.map((item) => {
            return {
              label: item.usuario_nome,
              value: item.cod_func,
              cod: item.cod,
              disabled: item.cod_consultor !== null,
            }
          })}
          dataRight={dataEquipeA.map((item) => {
            return {
              label: item.usuario_nome,
              value: item.cod_func,
              cod: item.cod,
              disabled: item.cod_consultor !== null,
            }
          })}
          dataConsultores={dataEquipeConsultor.map((item) => {
            return {
              label: item.usuario_nome,
              value: item.cod_func,
              cod: item.cod,
              disabled: item.cod_consultor !== null,
            }
          })}
          onTransfer={(v, p, o) => handleChangeMoveTransfer(v, p, o)}
        />
      </ViewPage>
    </Container>
  )
}

export default UserTechnician
