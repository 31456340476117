import React, { useEffect, useRef, useState } from 'react'

import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Row,
  Select,
  Skeleton,
  Upload,
} from 'antd'
import { AxiosResponse } from 'axios'
import { LoadingOutlined, PlusOutlined } from '@ant-design/icons'
import { UploadState } from 'antd/lib/upload/interface'
import { Notification } from '../notification'
import {
  getEstadios,
  postEstadios,
  putEstadios,
  TypeEstadios as EstadiosTypes,
} from '../../services/estadio'
import { tiposGerais } from '../../services/Afins'
import base64 from '../Base64Geral'

interface TypesEstadios {
  update: number
  open: boolean
  onClose: (data: boolean) => void
}

const Estadios: React.FC<TypesEstadios> = (props) => {
  const { update, onClose, open } = props

  const uploadFoto = useRef<UploadState>(null)

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [loadingImg, setLoadingImg] = useState(false)

  const [fileListImg, setFileListImg] = useState([])

  const [imageUrl, setImageUrl] = useState(null)

  const [previewVisibleImg, setPreviewVisibleImg] = useState(false)

  const [loadingEdit, setLoadingEdit] = useState(false)

  const [dataCultura, setDataCultura] = useState([])

  const [form] = Form.useForm()

  const editEstadio = async (data) => {
    message.loading('Aguarde...')
    setLoadingEdit(true)
    const response: AxiosResponse<EstadiosTypes> = await getEstadios({
      cod: data,
    })

    const dados = response.data.itens[0]

    if (dados.arquivo !== null) {
      uploadFoto.current.fileList[0] = {
        uid: String(dados.cod),
        name: dados.nome,
        thumbUrl: dados.arquivo_url,
        preview: dados.arquivo_url,
        url: dados.arquivo_url,
      }
      setImageUrl(dados.arquivo_url)

      dados.arquivo = ''
    }

    form.setFieldsValue(dados)
    setLoadingEdit(false)
    message.destroy()
  }

  const listCultura = async () => {
    const response = await tiposGerais()

    setDataCultura(response)
  }

  const handleChangeUpload = async (info) => {
    if (info.file.status === 'uploading') {
      setLoadingImg(true)
    }

    const status = info.file.status === 'removed' ? 'removed' : 'done'

    if (status === 'done') {
      // Get this url from response in real world.
      const temp = await base64(info.file.originFileObj)

      setImageUrl(temp)
      setFileListImg(info.fileList)
      setLoadingImg(false)
    } else {
      setLoadingImg(false)
    }
  }

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setImageUrl(null)
    form.resetFields()
  }

  const onFinish = async (data) => {
    message.loading('Aguarde...', 99999)
    if (imageUrl !== null) {
      const temp = data.arquivo.split(':')
      if (temp === 'data') {
        data.arquivo = imageUrl
      } else {
        delete data.arquivo
      }
    } else {
      delete data.arquivo
    }

    try {
      if (update <= 0) {
        const response: AxiosResponse<TypesEstadios> = await postEstadios(data)

        if (response.status === 201) {
          Notification({
            message: 'Estádio cadastrado com sucesso',
            type: 'success',
          })
          onClose(true)
          form.resetFields()
          setImageUrl(null)
          setFileListImg([])
          message.destroy()
        }
      } else {
        const response: AxiosResponse<EstadiosTypes> = await putEstadios(update, data)
        if (response.status === 200) {
          Notification({
            message: 'Estádio atualizado com sucesso',
            type: 'success',
          })

          editEstadio(update)

          onClose(true)
          message.destroy()
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const uploadButton = (
    <div>
      {loadingImg ? <LoadingOutlined /> : <PlusOutlined />}
      <div style={{ width: '350px', height: '350', marginTop: 8 }}>Foto</div>
    </div>
  )

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onPreview = async () => {
    setPreviewVisibleImg(true)
  }

  const onRemoveFoto = () => {
    setFileListImg([])
  }

  useEffect(() => {
    setDrawerVisible(open)
  }, [open])

  useEffect(() => {
    if (update > 0) {
      editEstadio(update)
    } else {
      setFileListImg([])
    }
  }, [update])

  useEffect(() => {
    listCultura()
  }, [])

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        placement='left'
        width='400px'
        open={drawerVisible}
        closeIcon={false}
        onClose={hancleDrawerClose}
        style={{ position: 'absolute' }}
        getContainer={false}
        footer={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button type='primary' htmlType='submit'>
              {update ? 'Atualizar' : 'Salvar'}
            </Button>
            {'   '}
            <Button danger onClick={hancleDrawerClose} type='primary' style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>
        }
      >
        <Col span={24}>
          <Row hidden={!loadingEdit} gutter={[8, 25]}>
            <Col flex='0 1 200px'>
              <Skeleton.Input block active />
            </Col>
            <Col flex='0 1 200px'>
              <Skeleton.Input block active />
            </Col>
            <Col flex='0 1 200px'>
              <Skeleton.Input style={{ height: '142px', width: '342px' }} block active />
            </Col>

            <Col flex='0 1 200px'>
              <Skeleton.Image />
            </Col>
          </Row>
          <Row hidden={loadingEdit} gutter={[8, 0]}>
            <Col flex='0 1 200px'>
              <Form.Item name='nome' label='Nome'>
                <Input />
              </Form.Item>
            </Col>
            <Col flex='0 1 200px'>
              <Form.Item name='cod_cultura' label='Cultura'>
                <Select>
                  {dataCultura.map((data) => (
                    <Select.Option key={data.cod} value={data.cod}>
                      {data.nome}
                    </Select.Option>
                  ))}
                </Select>
              </Form.Item>
            </Col>
            <Col flex='1 1 100%'>
              <Form.Item name='descricao' label='Descrição'>
                <Input.TextArea rows={6} />
              </Form.Item>
            </Col>

            <Col flex='1 1 100%'>
              <Form.Item name='arquivo' label='Foto'>
                <Upload
                  ref={uploadFoto}
                  accept='image/*'
                  onChange={handleChangeUpload}
                  listType='picture-card'
                  customRequest={dummyRequest}
                  onPreview={onPreview}
                  fileList={fileListImg}
                  onRemove={onRemoveFoto}
                  maxCount={1}
                >
                  {fileListImg.length < 1 && uploadButton}
                </Upload>
              </Form.Item>
            </Col>
          </Row>
        </Col>
      </Drawer>
      <Modal
        open={previewVisibleImg}
        title='Imagem'
        style={{ minWidth: '840px' }}
        footer={null}
        onCancel={() => setPreviewVisibleImg(false)}
      >
        <img alt='example' style={{ width: '100%' }} src={imageUrl} />
      </Modal>
    </Form>
  )
}

export default Estadios
