import { Drawer } from "antd";
import { NavegacaoDrawer } from "./styled";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { BodyDrawer } from "../gavetaCadastrar/styled";

//APP
import { moverTabs, obterTabsPermitidas } from "../gavetaCadastrar/meta";
import { useGetRecomendacaoAtivosCadastrados } from "../../../../../../services/recomendacao/ativos/novo-index";

//COMPONENTS
import Tabs from "../../../../../../Components/Tabs";
import ButtonCustom from "../../../../../../Components/ButtonCustom";
import { HeaderDrawer } from "../../../../../../Components/CustomUi/headerGaveta";
import { DetalhesDoses } from "./components/doses";
import { DetalhesPlantback } from "./components/plantback";
import { DetalhesObservacoes } from "./components/observacoes";
import { DetalhesCompatibilidade } from "./components/compatibilidade";
import { DetalhesIncompatibilidade } from "./components/incompatibilidade";

//PROPS
interface Props {
    open: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    nome_ativo_selecionado: string;
    id_ativo_selecionado: number;
    atualizarListaPrincipal: Function;
    id_tipo_principal: number;
    is_herbicida: boolean;
}

export const GavetaVisualizar:React.FC<Props> = ({ open, onClose, nome_ativo_selecionado, id_ativo_selecionado, atualizarListaPrincipal, id_tipo_principal, is_herbicida }) => {

    //STATES - GERAIS
    const [tabSelecionada, setTabSelecionada] = useState<'Compatibilidade'|'Incompatibilidade'|'Observações'|'Plant back'|'Doses'|''>('');

    //API
    const { data_cadastrados, refetch_data_cadastrados, carregando_cadastrados, isFetched_cadastrados } = useGetRecomendacaoAtivosCadastrados(id_ativo_selecionado, id_tipo_principal); 

    useEffect(() => {
        if (id_ativo_selecionado!==null) {
            refetch_data_cadastrados();
        }
    }, [id_ativo_selecionado]);
    
    useEffect(() => {
        moverTabs(data_cadastrados?.itens)
    }, [open, isFetched_cadastrados])

    return (
        <Drawer
            width={845}
            closable={false}
            onClose={()=>{onClose(false)}} open={open}
            bodyStyle={{ padding: 0 }}
            headerStyle={{padding: '10px 10px'}}
            title={
                <NavegacaoDrawer>
                    <ButtonCustom
                        danger
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        type='tertiary'
                        onClick={() => onClose(false)}
                        style={{ marginLeft: -10 }}
                    >
                        Fechar
                    </ButtonCustom>
                </NavegacaoDrawer>
            } 
        >
            <HeaderDrawer>
                {nome_ativo_selecionado}
            </HeaderDrawer>

            <BodyDrawer>
                <Tabs
                    label='selecione o tipo:'
                    tabs={obterTabsPermitidas(is_herbicida, id_tipo_principal)}
                    selecionado={tabSelecionada}
                    onChange={setTabSelecionada}
                    repetir={()=>{}}
                    indexInicial={0}
                    trackWidth={160}
                    tab_key='gaveta'
                />

                {(tabSelecionada==='Compatibilidade' && open) &&(
                    <DetalhesCompatibilidade 
                        ativos_cadastrados={data_cadastrados?.itens ?? []} 
                        carregando={carregando_cadastrados}
                        refetch={refetch_data_cadastrados}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                        id_tipo_principal={id_tipo_principal}
                    />
                )}

                {(tabSelecionada==='Incompatibilidade' && open) &&(
                    <DetalhesIncompatibilidade 
                        ativos_cadastrados={data_cadastrados?.itens ?? []} 
                        carregando={carregando_cadastrados}
                        refetch={refetch_data_cadastrados}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                        id_tipo_principal={id_tipo_principal}
                    />
                )}

                {(tabSelecionada==='Observações' && open) &&(
                    <DetalhesObservacoes 
                        ativos_cadastrados={data_cadastrados?.itens ?? []} 
                        carregando={carregando_cadastrados}
                        refetch={refetch_data_cadastrados}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                        id_tipo_principal={id_tipo_principal}
                    />
                )}

                {(tabSelecionada==='Plant back' && open && is_herbicida) &&(
                    <DetalhesPlantback 
                        ativos_cadastrados={data_cadastrados?.itens ?? []} 
                        carregando={carregando_cadastrados}
                        refetch={refetch_data_cadastrados}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                        id_tipo_principal={id_tipo_principal}
                    />
                )}

                {(tabSelecionada==='Doses' && open && (id_tipo_principal <= 2)) &&(
                    <DetalhesDoses 
                        ativos_cadastrados={data_cadastrados?.itens ?? []} 
                        carregando={carregando_cadastrados}
                        refetch={refetch_data_cadastrados}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                        id_tipo_principal={id_tipo_principal}
                    />
                )}

            </BodyDrawer>
            

        </Drawer>
    )
}