import { useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { IconDefinition, faClone, faMagnifyingGlass, faWarning, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
import { InputWrapper } from "./styled";

//APP
import { removerEspacos } from "../../../utils";
import { Render } from "../render";

//PROPS
interface Props {
    label?: string;
    placeholder: string;
    value: string;
    onChange: React.Dispatch<React.SetStateAction<string>>
    icon?: IconDefinition;
    obrigatorio?: boolean;
    error?: boolean;
    errorMessage?: string;
    desabilitado?: boolean;
    width?: number | string;
    maxLength?: number;
    height?: number |string;
    autoFocus?: boolean;
    allowCopy?: boolean;
    readOnly?: boolean;
    type?: 'text' | 'email' | 'password'
}

const Input: React.FC<Props> = ({ label, placeholder, value, onChange, icon, obrigatorio, error, errorMessage, 
    desabilitado, width, height, maxLength, autoFocus, allowCopy, readOnly, type }) => {
   
    useEffect(() => {
        if (autoFocus) {
            let input_element = document.getElementById(removerEspacos(placeholder));
            input_element?.focus()
        }
    }, [])
    

    return (
        <InputWrapper style={{width: width}} data-label={!!label}>

            {!!label &&(
                <label htmlFor={removerEspacos(placeholder)}>{label} {obrigatorio? <span className="InputWrapper__obrigatorio">(obrigatório)</span> : null } </label>
            )}

            <div className="InputWrapper__campo" data-error={error} data-disabled={desabilitado}>

                <FontAwesomeIcon icon={(error ? faWarning :  icon) ?? faMagnifyingGlass} className="InputWrapper__campo__icon" />

                <input 
                    id={removerEspacos(placeholder)}
                    className="InputWrapper__campo__input"
                    placeholder={placeholder}
                    value={value}
                    onChange={(e)=>{onChange(e.currentTarget.value)}}
                    autoComplete="off"
                    disabled={desabilitado}
                    maxLength={maxLength}
                    autoFocus={autoFocus}
                    readOnly={readOnly}
                    type={type??"text"}
                />

                <Render condition={allowCopy}>
                    <button 
                        className="InputWrapper__campo__copy" 
                        title="Copiar valor"
                        onClick={()=>onChange('')}
                        tabIndex={-1}
                    >
                        <FontAwesomeIcon icon={faClone}/>
                    </button>
                </Render>
                

                <button 
                    className="InputWrapper__campo__clear" 
                    type="reset" 
                    title="Limpar campo"
                    onClick={()=>onChange('')}
                    tabIndex={-1}
                    style={{display: allowCopy ? 'none' : undefined}}
                >
                    <FontAwesomeIcon icon={faXmark}/>
                </button>
            </div>
            {error &&(
                <span className="InputWrapper__error">
                    {errorMessage}
                </span>
            )}
            
        </InputWrapper>
    )
}

export default Input;