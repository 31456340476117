import IconAnd, { CloseOutlined } from '@ant-design/icons'
import { faArrowLeft, faBook, faLocationDot } from '@fortawesome/free-solid-svg-icons'
import { GroundOverlay, Marker, Polygon } from '@react-google-maps/api'
import {
  Badge,
  Button,
  Card,
  Checkbox,
  Col,
  Descriptions,
  Form,
  message,
  Modal,
  Progress,
  Row,
  Select,
  Space,
  Switch,
} from 'antd'
import dayjs, { Dayjs } from 'dayjs'
import 'idwpolygon'
import * as L from 'leaflet'
import { groupBy, orderBy, uniq } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useDispatch, useSelector } from 'react-redux'
import { v4 as uid } from 'uuid'
import { useAccess } from '../../context/useAccess'
import { percent, reset } from '../../features/Monitoramento/Monitoramento.progress'
import {
  convertMinHoras,
  getBounds,
  getBoundsNoArray,
  objOrder,
  tryError,
} from '../../services/Afins'
import {
  getMonitoramento,
  ItensMonitoramentoTypes,
  LeiturasMonitoramentoType,
} from '../../services/monitoramento'
import { MoniNivelItemControleItens } from '../../services/monitoramento/nivelControleItens'
import { PropriedadeItens } from '../../services/Propriedades'
import { Description, DescriptionItem } from '../Descritpion'
import Icons from '../Icons'
import SidePainel from '../SidePainel'
import Svg from '../talhasvg'
import {
  BadgePulse,
  Container,
  ContentMap,
  ContentPanel,
  DetailsMonitoramento,
  DivDrawerPonto,
  Inverte,
  MedMax,
  OverFlowDescription,
  PanelDetails,
} from './styles'

import { CheckboxChangeEvent } from 'antd/lib/checkbox'
import { CheckboxValueType } from 'antd/lib/checkbox/Group'
import logo from '../../assets/img/logo.png'
import pin from '../../assets/svg/marker.svg'
import pinSelect from '../../assets/svg/markerSelect.svg'
import { RootState } from '../../features'
import {
  setDataDetails,
  TypeDetailsMonitoramento,
} from '../../features/Monitoramento/Monitoramento.dataDetails'
import { resetListDates, setListDates } from '../../features/Monitoramento/Monitoramento.listDates'
import {
  getTipoConsultor,
  ItensTipoConsultorTypes,
} from '../../services/consultoriaAgricola/tipoConsultor'
import BookMonitoramento, { TypeBookMonitoramento, TypesBookData } from '../BookMonitoramento'
import CanvasInterpolation, {
  gradient,
  TypesGerarGroupOverlay,
  TypesOverlayView,
} from '../CanvasInterpolation'
import ColapseMoni from '../ColapseMonitoramento'
import DatePicker from '../DatePiker'
import { DefaultMap } from '../DefaultMap'

interface MonitoramentoMapCalorType {
  codTalhao?: number
  estadio?: string
  codEstadio?: number
  monitoramento?: MonitoramentoMapaCalorMonitoramentoType[][]
}

interface MonitoramentoMapaCalorMonitoramentoType {
  codMonitoramento?: number
  ponto: number
  lat: number
  lng: number
  altitude?: string
  valorApurado: string
  colmo?: string
  repeticao?: any[]
  estadio: string
  codEstadio: number
  codNivelControleItem: number
  codEspVari: string
  codTalhao?: number
  nomeTalhao: string
  valorNivel: string
  dataInit: string
  dataFim: string
  numRepeticao?: number
  horaInit: string
  horaFim: string
  nomeUsuario: string
  nivel: LeiturasMonitoramentoType['nivel_controle_item']
}

export interface PropsGeraBooking {
  dateBook: Dayjs
  consultorName: string
  codTalhao: number[]
  mapType: 'satellite' | 'terrain'
  listEspecieVariacao?: string[]
}

interface PropsMonitoramento {
  visible: boolean
  onClose: (data: boolean) => void
  propriedade: PropriedadeItens
  nivelControle: MoniNivelItemControleItens[]
  geraBooking?: PropsGeraBooking
  onLoadingBook?: (data: boolean) => void
  finishBook?: (data: boolean) => void
  bookApp: number
  resumir: boolean
  ordenar?: boolean
}

const gradientI = {
  1.0: '#01ff00', // 0
  0.8: '#01ff00', // 0
  0.4: '#b8ff05', // 1
  0.2: '#ffff02', // 2
  0.1: '#ffff02', // 3
  0.0: '#ffff02', // 4
}

const mediaMapaCalor = (apurado, nc) => {
  apurado = parseFloat(apurado)

  nc = parseFloat(nc)

  const porcentagem = (apurado * 100) / nc

  let valorFinal = '0.0'
  let cor = '#01ff00'

  // if (porcentagem <= 25) {
  //   valorFinal = '0.0'
  //   cor = '#01ff00'
  // } else if (porcentagem <= 50) {
  //   valorFinal = '0.1'
  //   cor = '#ffff02'
  // } else if (porcentagem <= 100) {
  //   valorFinal = '0.2 '
  //   cor = '#ffff02'
  // } else if (porcentagem <= 125) {
  //   valorFinal = '0.4 '
  //   cor = '#fb5e03'
  // } else if (porcentagem <= 150) {
  //   valorFinal = '0.8 '
  //   cor = '#fb0102'
  // } else {
  //   valorFinal = '1.0 '
  //   cor = '#fb0102'
  // }

  if (porcentagem == 0) {
    valorFinal = '0.0'
    cor = '#01ff00'
  } else if (porcentagem > 0 && porcentagem < 100) {
    valorFinal = '0.2'
    cor = '#fcff02'
  } else if (porcentagem >= 100 && porcentagem < 300) {
    valorFinal = '0.8'
    cor = '#fcb700'
  } else if (porcentagem >= 300) {
    valorFinal = '1.0'
    cor = '#fb0303'
  }

  return { valorFinal, cor }
}

const calcPorcentagem = (qtda, total) => {
  return parseFloat(((qtda * 100) / total).toFixed(0))
}

const Monitoramento: React.FC<PropsMonitoramento> = (props) => {
  const {
    visible,
    onClose,
    propriedade,
    nivelControle,
    geraBooking,
    onLoadingBook,
    finishBook,
    bookApp,
    resumir,
    ordenar,
  } = props

  const [form] = Form.useForm()

  const dispatch = useDispatch()
  const progress = useSelector((state: RootState) => state.progress)
  const listDates = useSelector((state: RootState) => state.listDates)
  const dataDetails = useSelector((state: RootState) => state.dataDetails).data

  const iconProps = { url: pin, scaledSize: new window.google.maps.Size(40, 50) }

  const [markerSelect, setMarkerSelect] = useState(-1)

  const [keyCollapse, setKeyCollapse] = useState(null)

  const [dateBook, setDateBook] = useState('')
  const [dataAtual, setDataAtual] = useState(null)
  const [dataInicial, setDataInicial] = useState(null)

  const [groupTotalizar, setGroupTotalizar] = useState(false)
  const [resumirBook, setResumirBook] = useState(false)
  const [ordenarTalhao, setOrdenarTalhao] = useState(true)
  const [valorInicial, setValorInicial] = useState(true)
  const [gerarTrue, setGerarTrue] = useState(false)
  const [changeTrue, setChangeTrue] = useState(false)
  const [clickTrue, setClickTrue] = useState(false)

  const { nameCliente, nameSafra, codCultura } = useAccess()
  const [visibleChangeBooking, setVisibleChangeBooking] = useState(false)

  const [loadingBook, setLoadingBook] = useState(false)
  const [mapType, setMapType] = useState<google.maps.MapTypeId>(google.maps.MapTypeId.SATELLITE)

  const refDivClickPraga = useRef<HTMLDivElement[]>([])

  const refContainer = useRef<HTMLDivElement>(null)
  const [detailsOpen, setDetailsOpen] = useState(false)

  const [map, setMap] = useState<google.maps.Map>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [dataMonitoramento, setDataMonitoramento] = useState<{
    [key: string]: MonitoramentoMapCalorType[]
  }>({})

  const [dataConsultor, setDataConsultor] = useState<ItensTipoConsultorTypes[]>([])
  const [codConsultor, setCodConsultor] = useState('')

  const [dataMonitoramentoNoGroup, setDataMonitoramentoNoGroup] = useState<
    MonitoramentoMapCalorType[]
  >([])

  const [dataBooking, setDataBooking] = useState<TypesBookData>({} as TypesBookData)
  const [dataBookingTemp, setDataBookingTemp] = useState<TypesBookData>({} as TypesBookData)

  const [hideMarker, setHideMarker] = useState(false)
  const [openBooking, setOpenBooking] = useState(false)

  const [monteMarker, setMonteMarker] = useState([])

  const [calorSelect, setCalorSelect] = useState(null)

  const [colapseOpen, setColapseOpen] = useState(false)
  const [dadosApp, setDadosApp] = useState<TypesBookData>({} as TypesBookData)
  const [filtroOrdenarTalhao, setFiltroOrdenarTalhao] = useState([])
  const [totalizarBook, setTotalizarBook] = useState<{
    [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
  }>({})
  const [totalizarBookApp, setTotalizarBookApp] = useState(false)

  const [dataMonitoramentoBooking, setDataMonitoramentoBooking] = useState<{
    [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
  }>({})

  const [overlayView, setOverlayView] = useState<TypesOverlayView[]>([])

  const [dataGroundOverlay, setDataGroundOverlay] = useState<TypesGerarGroupOverlay[]>([])

  const [monitoramentoAllGroup, setMonitoramentoAllGroup] = useState<
    {
      [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
    }[]
  >([])

  const [viewPonto, setViewPonto] = useState<MonitoramentoMapaCalorMonitoramentoType>(
    {} as MonitoramentoMapaCalorMonitoramentoType
  )

  const [visibleDrawerPonto, setVisibleDrawerPonto] = useState(false)
  const [sideClose, setSideClose] = useState(false)

  const [checkedList, setCheckedList] = useState<CheckboxValueType[]>([])
  const [indeterminate, setIndeterminate] = useState(false)
  const [checkAll, setCheckAll] = useState(false)
  const [disable, setDisable] = useState(false)
  const [dadosData, setDadosData] = useState<{
    [key: string]: MonitoramentoMapaCalorMonitoramentoType[]
  }>({})
  const [resultados, setResultados] = useState([])
  const [open, setOpen] = useState(false)
  const [codPro, setCodPro] = useState<number>()

  const [listCheckBoxArray, setListCheckBoxArray] = useState<string[]>([])

  const handleChangeAllPraga = (list: CheckboxValueType[] = []) => {
    if (list.length === listCheckBoxArray.length) {
      setCheckedList(list)
      form.setFieldsValue({ listEspecieVariacao: list })
      setCheckAll(true)
    } else {
      const menorComprimento = Math.min(list.length, listCheckBoxArray.length)
      const listaParcial = list.slice(0, menorComprimento)
      setCheckedList(listaParcial)
      form.setFieldsValue({ listEspecieVariacao: listaParcial })
    }
  }

  const handleCheckAllChange = (e: CheckboxChangeEvent) => {
    form.setFieldsValue({ listEspecieVariacao: e.target.checked ? listCheckBoxArray : [] })
    setCheckedList(e.target.checked ? listCheckBoxArray : [])
    setIndeterminate(false)
    setCheckAll(e.target.checked)
  }

  const markerRepeticao = new google.maps.Marker({})

  const listMonitoramento = async (codPropriedade: number) => {
    setCodPro(codPropriedade)
    try {
      const pagination = 100
      let total = 0
      let totalPage = 1
      let page = 1
      let dados: ItensMonitoramentoTypes[] = []

      dispatch(reset())

      for (let i = 0; i < totalPage; i += 1) {
        if (!visible) {
          break
        }
        const resp = await getMonitoramento({
          codPropriedade,
          embed:
            'monitoramento_repeticoes,monitoramento_leituras,monitoramento_leituras_nivel_controle_item',
          pagination,
          page,
        })
        dados = [...dados, ...resp.data.itens]

        if (i === 0) {
          total = resp.data.total
          totalPage = Math.ceil(total / pagination)
        }
        if (page >= 1) {
          dispatch(percent(calcPorcentagem(page, totalPage)))
        }
        page += 1
      }

      handleGerarColapse(dados)

      // console.log('polygon', polygon)

      const coord = polygon.getPaths().getArray()
      const bounds = getBounds(coord)

      map.fitBounds(bounds, { left: 630, right: 80 })
      const consultor = dataConsultor.filter(
        (item) => item.cod_usuario === propriedade?.cod_consultor
      )
      setCodConsultor(consultor[0].usuario_nome)
    } catch (error) {
      tryError(error)
    }
  }

  // console.log('propriedades', propriedade)
  // console.log('dadosMonitoramentoGroup', dataMonitoramentoNoGroup)
  // console.log('dadosMonitoramento', dataMonitoramentoBooking)

  const listConsultor = async () => {
    const resp = await getTipoConsultor({ codCliente: null })

    setDataConsultor(resp?.data?.itens)
  }

  const handleGerarColapse = (dados: ItensMonitoramentoTypes[]) => {
    propriedade.talhao.map((rec) => {
      rec.monitoramento = []
      return rec
    })

    for (let i = 0; i < propriedade.talhao.length; i += 1) {
      const talhao = propriedade.talhao[i]

      const moni = dados.filter((rec) => rec.cod_talhao === talhao.cod)

      talhao.monitoramento = [...talhao.monitoramento, ...moni]
    }

    let monitoramento: MonitoramentoMapCalorType[] = []
    for (let i = 0; i < propriedade.talhao.length; i += 1) {
      const talhao = propriedade.talhao[i]
      let leituras = []

      let fim: MonitoramentoMapCalorType = { monitoramento: [] }

      for (let ii = 0; ii < talhao.monitoramento.length; ii += 1) {
        const moni = talhao.monitoramento[ii]
        if (moni.monitoramento_repeticao.length > 0) {
          fim = {
            codTalhao: moni.cod_talhao,
            monitoramento: moni.monitoramento_repeticao.map((rep) => {
              return rep.monitoramento_leitura.map((lei) => {
                return {
                  codMonitoramento: moni.cod,
                  ponto: moni.ponto,
                  colmo: moni.colmo,
                  valorApurado: lei.valor,
                  estadio: moni.estadio_nome,
                  codEstadio: moni.cod_estadio,
                  numRepeticao: rep.repeticao,
                  nomeTalhao: talhao.nome,
                  nomeUsuario: moni.usuario_nome,
                  dataInit: moni.data_inicio,
                  codNivelControleItem: lei.cod_nivel_controle_item,
                  dataFim: moni.data_final,
                  altitude: moni.altitude,
                  horaInit: rep.hora_inicio,
                  totalizar: lei.nivel_controle_item[0].totalizar,
                  horaFim: rep.hora_final,
                  lat: Number(moni.latitude),
                  lng: Number(moni.longitude),
                  valorNivel: lei.nivel_controle_item[0].valor,
                  codEspVari: `${lei.nivel_controle_item[0].especie_nome}, ${lei.nivel_controle_item[0].cod_especie}, ${lei.nivel_controle_item[0].cod_variacao_especie}, ${lei.nivel_controle_item[0].totalizar}`,
                  nivel: lei.nivel_controle_item,
                  inverter: lei.nivel_controle_item[0].inverter_escala,
                }
              })
            }),
          }

          leituras = [...leituras, fim]
        }
      }
      monitoramento = [...monitoramento, ...leituras]
    }

    const monitoramentoGroup = groupBy(monitoramento, 'codTalhao')

    setDataMonitoramentoNoGroup(monitoramento)

    setDataMonitoramento(monitoramentoGroup)
  }

  const handleClickMarker = (indexMoni, array: MonitoramentoMapaCalorMonitoramentoType[]) => {
    setViewPonto(array[indexMoni])
    setMarkerSelect(indexMoni)
    setVisibleDrawerPonto(true)
  }

  // console.log('DataMonito', dataMonitoramentoBooking)
  // // console.log('DataBookig', dataBooking)
  // // console.log('propriedade', propriedade)
  // console.log('dadosData', dadosData)

  const tipoAcesso = localStorage.getItem('tipo-acesso')

  const handleAssembleHeatMap = async (monitoramento, all = false, codTalhao = 0) => {
    // montar array de mapa de calor
    try {
      setMarkerSelect(-1)
      message.loading('Carregando mapa de calor', 999999)
      const tempDataDetail: TypeDetailsMonitoramento = {} as TypeDetailsMonitoramento
      setOverlayView([])
      setMonteMarker([])

      if (!all) {
        setTimeout(() => {
          let praga: [number, number, string][] = []
          for (let i = 0; i < monitoramento.length; i += 1) {
            const moni = monitoramento[i]
            const talhao = propriedade.talhao.find((rec) => rec.cod === codTalhao)
            const polyCoord: L.LatLngTuple[] = talhao.kml.coordenadas.map((rec) => [
              rec.lat,
              rec.lng,
            ])

            const gPontoMoniT = groupBy(moni.monitoramento, 'codMonitoramento')

            const pontoMedia: any = Object.entries(gPontoMoniT).map(([k, v]) => {
              const data = v[0]

              const gRep: any = groupBy(v, 'numRepeticao')

              const repeticao = Object.entries(gRep).map(([key, val]: [any, any]) => {
                const dados = val[0]
                const valorApurado = val.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
                return {
                  ...dados,
                  numRepeticao: key,
                  valorApurado,
                }
              })
              const somaApurado = repeticao.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)

              const valorApurado = somaApurado / repeticao.length

              return {
                ...data,
                ponto: data?.ponto,
                repeticao,
                valorApurado,
              }
            })

            const arrayValues = pontoMedia.map((rec) => Number(rec.valorApurado))

            const estadios = uniq(pontoMedia.map((rec) => rec.estadio)).join(', ')

            const med = (
              arrayValues.reduce((partialSum, a) => partialSum + a, 0) / arrayValues.length
            ).toFixed(1)

            tempDataDetail.talhao = talhao.nome
            tempDataDetail.especie = pontoMedia[0].nivel[0].especie_nome
            tempDataDetail.data = dayjs(pontoMedia[0].dataInit).format('DD/MM/YYYY')
            tempDataDetail.variacao = pontoMedia[0].nivel[0].variacao_especie_nome
            tempDataDetail.inverterEscala = pontoMedia[0].nivel[0].inverter_escala === 1
            tempDataDetail.qtdPontos = pontoMedia.length
            tempDataDetail.totalizar = pontoMedia[0].nivel[0].totalizar
            tempDataDetail.estadios = estadios
            tempDataDetail.media = {
              min: Math.min(...arrayValues),
              med: Number(med),
              max: Math.max(...arrayValues),
            }

            tempDataDetail.pontosAcima = 0
            tempDataDetail.pontosAbaixo = 0
            const arrayValorFinal = []
            for (let ii = 0; ii < pontoMedia.length; ii += 1) {
              const leitura = pontoMedia[ii]
              const nivel = parseFloat(leitura.valorNivel)
              const valor = parseFloat(leitura.valorApurado)

              if (nivel >= valor) {
                tempDataDetail.pontosAbaixo += 1
              } else {
                tempDataDetail.pontosAcima += 1
              }

              let valorFinal = '0'

              if (parseFloat(leitura.valorApurado) === 0) {
                valorFinal = '0.0'
              } else {
                const vMedio = parseFloat(leitura.valorNivel).toFixed(1)
                const valor = parseFloat(leitura.valorApurado)

                valorFinal = mediaMapaCalor(valor, vMedio).valorFinal
              }
              arrayValorFinal.push(valorFinal)
              praga = [...praga, [Number(leitura.lat), Number(leitura.lng), valorFinal]]
            }
            const fim = {
              polyCoord,
              praga,
              talhao_observacao: '',
              inverterEscala: pontoMedia[0].nivel[0].inverter_escala === 1,
              nivelVari: '',
            }

            setDataGroundOverlay([fim])

            setMonteMarker(pontoMedia)
          }
          message.destroy()
          dispatch(setDataDetails(tempDataDetail))
          setDetailsOpen(true)
        }, 500)
      } else {
        setTimeout(() => {
          let monteHeartMap = []
          let talhoes = []

          const mon = monitoramento[0]

          const groupTalhao = groupBy(mon.monitoramento, 'codTalhao')

          const gPontoMoni = Object.entries(groupTalhao).map(([k, v]) => {
            return groupBy(v, 'codMonitoramento')
          })

          let pontoMedia = []
          Object.entries(gPontoMoni).forEach(([k, v]) => {
            return Object.entries(v).forEach(([vK, vD]) => {
              const data = vD[0]

              const gRep: any = groupBy(vD, 'numRepeticao')

              const repeticao = Object.entries(gRep).map(([key, val]: [any, any]) => {
                const dados = val[0]
                const valorApurado = val.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
                return {
                  ...dados,
                  numRepeticao: key,
                  valorApurado,
                }
              })

              const somaApurado = repeticao.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
              const valorApurado = somaApurado / repeticao.length

              pontoMedia = [
                ...pontoMedia,
                {
                  ...data,
                  ponto: vK,
                  repeticao,
                  valorApurado,
                },
              ]
            })
          })

          const estadios = uniq(pontoMedia.map((rec) => rec.estadio)).join(', ')
          const arrayValues = pontoMedia.map((rec) => Number(rec.valorApurado))

          const med = (
            arrayValues.reduce((partialSum, a) => partialSum + a, 0) / arrayValues.length
          ).toFixed(1)

          tempDataDetail.qtdPontos = pontoMedia.length
          tempDataDetail.especie = pontoMedia[0].nivel[0].especie_nome
          tempDataDetail.data = dayjs(pontoMedia[0].dataInit).format('DD/MM/YYYY')
          tempDataDetail.variacao = pontoMedia[0].nivel[0].variacao_especie_nome
          tempDataDetail.qtdPontos = pontoMedia.length
          tempDataDetail.totalizar = pontoMedia[0].nivel[0].totalizar
          tempDataDetail.inverterEscala = pontoMedia[0].nivel[0].inverter_escala === 1
          tempDataDetail.estadios = estadios
          tempDataDetail.media = {
            min: Math.min(...arrayValues),
            med: Number(med),
            max: Math.max(...arrayValues),
          }

          tempDataDetail.pontosAcima = 0
          tempDataDetail.pontosAbaixo = 0

          for (let i = 0; i < pontoMedia.length; i += 1) {
            const moni = pontoMedia[i]

            const nivel = parseFloat(moni.valorNivel)
            const tmpValor = parseFloat(moni.valorApurado)

            if (nivel >= tmpValor) {
              tempDataDetail.pontosAbaixo += 1
            } else {
              tempDataDetail.pontosAcima += 1
            }

            let valorFinal = '0'
            if (parseFloat(moni.valorApurado) === 0) {
              valorFinal = '0.0'
            } else {
              const vMedio = parseFloat(moni.valorNivel).toFixed(1)
              const valor = parseFloat(moni.valorApurado)

              valorFinal = mediaMapaCalor(valor, vMedio).valorFinal
            }

            talhoes = [
              ...talhoes,
              {
                cod_talhao: moni.codTalhao,
                pragas: [Number(moni.lat), Number(moni.lng), valorFinal],
                inverterEscala: moni.nivel[0].inverter_escala === 1,
              },
            ]
          }

          Object.entries(groupBy(talhoes, 'cod_talhao')).forEach(([k, v]) => {
            const talhao: L.LatLngTuple[] = propriedade.talhao
              .find((rec) => rec.cod === Number(k))
              .kml.coordenadas.map((item) => [item.lat, item.lng])

            const praga = v.map((f) => f.pragas)

            monteHeartMap = [
              ...monteHeartMap,
              {
                nameTalhao: propriedade.talhao.find((rec) => rec.cod === Number(k)).nome,
                codTalhao: k,
                polyCoord: talhao,
                praga,
                inverterEscala: v[0].inverterEscala,
              },
            ]
          })

          setMonteMarker(pontoMedia)
          setDataGroundOverlay(monteHeartMap)
          message.destroy()
          dispatch(setDataDetails(tempDataDetail))
          setDetailsOpen(true)
        }, 1000)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const filterDataTalhao = (data) => {
    const dadosExtraidos = []

    // Iterar sobre os objetos no estado
    for (const chave in dadosData) {
      if (dadosData.hasOwnProperty(chave)) {
        const arrayDeDados = dadosData[chave]
        const cont = arrayDeDados.filter((rec) => dayjs(rec.dataInit).format('DD/MM/YYYY') === data)
        // Adicionar os dados ao array de dados extraídos
        dadosExtraidos.push(...cont)
      }
    }

    const removerDuplicados = (array) => {
      const idsUnicos = new Set()
      return array.filter((item) => {
        if (!idsUnicos.has(item.codTalhao)) {
          idsUnicos.add(item.codTalhao)
          return true
        }
        return false
      })
    }

    const dadosFiltrados = removerDuplicados(dadosExtraidos)
    const dadosFilterFinal = dadosFiltrados.map((item) => {
      const coordenadas = propriedade.talhao.find((coord) => coord.cod === item.codTalhao)

      return {
        cod: item.codTalhao,
        data: dayjs(item.dataInit).format('DD/MM/YYYY'),
        nome: item.nomeTalhao,
        coordenadas: coordenadas.kml.coordenadas,
      }
    })

    setResultados(dadosFilterFinal)
  }

  const geraDataBooking = (date) => {
    let tempPraga: TypesBookData['monitoramento'] = []

    if (dayjs.isDayjs(date)) {
      date = dayjs(date).format('DD/MM/YYYY')
    }

    Object.entries(resumirBook === true ? totalizarBook : dataMonitoramentoBooking[0]).forEach(
      ([keyD, value]) => {
        //@ts-ignore

        const cont = value.filter((rec) => dayjs(rec.dataInit).format('DD/MM/YYYY') === date)

        if (cont.length > 0) {
          const groupTalhao = groupBy(cont, 'codTalhao')

          let dataPraga: TypeBookMonitoramento['data'] = []

          Object.entries(groupTalhao).forEach(([k, rec]) => {
            const pontosNovos = uniq(rec.map((item) => item.ponto))
            if (bookApp === 1) {
              if (pontosNovos.length > 2) {
                const pontosIdw = groupBy(rec, 'codMonitoramento')
                const key = uid()
                const talhao = propriedade.talhao.find((item) => item.cod === Number(k))
                let values = []
                let arrayValue = []
                let estadios = []
                let idw = []

                Object.entries(pontosIdw).map(([chave, v]) => {
                  const gRep: any = groupBy(v, 'numRepeticao')

                  const repeticao = Object.entries(gRep).map(([key, val]: [any, any]) => {
                    const valorApurado = val.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
                    return {
                      numRepeticao: key,
                      valorApurado,
                    }
                  })
                  values = [...values, ...v.map((item) => Number(item.valorApurado))]
                  estadios = [...estadios, ...v.map((item) => item.estadio)]
                  arrayValue = v.map((item) => Number(item.valorApurado))

                  const valorMedia =
                    resumirBook === true
                      ? arrayValue.reduce((prev, a) => prev + a, 0) / repeticao.length
                      : arrayValue.reduce((prev, a) => prev + a, 0) / arrayValue.length

                  idw = [
                    ...idw,
                    [
                      v[0].lat,
                      v[0].lng,
                      valorMedia === 0
                        ? '0'
                        : mediaMapaCalor(valorMedia, Number(v[0].valorNivel).toFixed(1)).valorFinal,
                      chave,
                      valorMedia,
                    ],
                  ]
                })

                estadios = uniq(estadios)
                dataPraga = [
                  ...dataPraga,
                  {
                    key,
                    talhao: talhao.nome,
                    codTalhao: talhao.cod,
                    talhao_observacao: talhao.observacao,
                    estadios,
                    area: talhao.area,
                    min: String(Math.min(...values)),
                    max: String(Math.max(...values)),
                    med: (values.reduce((a, b) => a + b) / values.length).toFixed(1),
                    polygon: talhao.kml.coordenadas.map((rec) => {
                      return { lat: rec.lat, lng: rec.lng }
                    }),
                    idw,
                  },
                ]
              }
            } else {
              const pontosIdw = groupBy(rec, 'codMonitoramento')
              const key = uid()
              const talhao = propriedade.talhao.find((item) => item.cod === Number(k))
              let values = []
              let arrayValue = []
              let estadios = []
              let idw = []

              Object.entries(pontosIdw).map(([chave, v]) => {
                const gRep: any = groupBy(v, 'numRepeticao')

                const repeticao = Object.entries(gRep).map(([key, val]: [any, any]) => {
                  const valorApurado = val.reduce((a, b) => Number(a) + Number(b.valorApurado), 0)
                  return {
                    numRepeticao: key,
                    valorApurado,
                  }
                })
                values = [...values, ...v.map((item) => Number(item.valorApurado))]
                estadios = [...estadios, ...v.map((item) => item.estadio)]
                arrayValue = v.map((item) => Number(item.valorApurado))

                const valorMedia =
                  resumirBook === true
                    ? arrayValue.reduce((prev, a) => prev + a, 0) / repeticao.length
                    : arrayValue.reduce((prev, a) => prev + a, 0) / arrayValue.length

                idw = [
                  ...idw,
                  [
                    v[0].lat,
                    v[0].lng,
                    valorMedia === 0
                      ? '0'
                      : mediaMapaCalor(valorMedia, Number(v[0].valorNivel).toFixed(1)).valorFinal,
                    chave,
                    valorMedia,
                  ],
                ]
              })

              estadios = uniq(estadios)
              dataPraga = [
                ...dataPraga,
                {
                  key,
                  talhao: talhao.nome,
                  codTalhao: talhao.cod,
                  talhao_observacao: talhao.observacao,
                  estadios,
                  area: talhao.area,
                  min: String(Math.min(...values)),
                  max: String(Math.max(...values)),
                  med: (values.reduce((a, b) => a + b) / values.length).toFixed(1),
                  polygon: talhao.kml.coordenadas.map((rec) => {
                    return { lat: rec.lat, lng: rec.lng }
                  }),
                  idw,
                },
              ]
            }
          })

          tempPraga = [...tempPraga, { especieVariacao: keyD, data: [...dataPraga] }]
          tempPraga = tempPraga.sort((a, b) => a.especieVariacao.localeCompare(b.especieVariacao))

          return tempPraga
        }
      }
    )

    const dataFim: TypesBookData = {
      nameCliente: nameCliente,
      codTalhao: form.getFieldValue('codTalhao'),
      namePropriedade: propriedade.nome,
      nameConsultor: '',
      date: date,
      monitoramento: [...tempPraga],
    }

    const dadosOrganizados: TypesBookData['monitoramento'] = []

    tempPraga.forEach((especie) => {
      especie.data.forEach((talhao) => {
        const novoItem = {
          data: [talhao],
          especieVariacao: especie.especieVariacao,
        }

        dadosOrganizados.push(novoItem)
      })
    })

    // Ordena os dados finais com base no nome do t
    dadosOrganizados.sort((a, b) => a.data[0].talhao.localeCompare(b.data[0].talhao))
    setFiltroOrdenarTalhao(dadosOrganizados)

    const dataFimApp: TypesBookData = {
      nameCliente: nameCliente,
      codTalhao: form.getFieldValue('codTalhao'),
      namePropriedade: propriedade.nome,
      nameConsultor: '',
      date: date,
      monitoramento: [...tempPraga.filter((item) => item.data.length > 0)],
    }

    setDadosApp(dataFimApp)
    if (bookApp === 1) {
      setGerarTrue(true)
    }
    message.destroy()

    return dataFim
  }

  // const organizarDados = (dados) => {
  //   const dadosOrganizados: TypesBookData['monitoramento'] = []

  //   dados.forEach((especie) => {
  //     especie.data.forEach((talhao) => {
  //       const novoItem = {
  //         data: [talhao],
  //         especieVariacao: especie.especieVariacao,
  //       }

  //       dadosOrganizados.push(novoItem)
  //     })
  //   })

  //   // Ordena os dados finais com base no nome do t
  //   dadosOrganizados.sort((a, b) => a.data[0].talhao.localeCompare(b.data[0].talhao))

  //   // Atualiza o estado com os dados organizados
  //   setFiltroOrdenarTalhao(dadosOrganizados)
  // }

  // console.log('DataMonitoramento', dataMonitoramentoBooking)

  const handleSubmitBook = (data: { consultorName: string; dateBook: Dayjs }) => {
    //@ts-ignore
    handleClickGerarBooking(data)
    loadingAlert()
    handleChangeAllPraga([])
    form.resetFields()
    setListCheckBoxArray([])
    setCheckAll(false)
  }

  const executarSubmit = () => {
    setDataBooking(dadosApp)
    setResumirBook(resumir)
    loadingAlert()
    handleChangeAllPraga([])
    form.resetFields()
    setListCheckBoxArray([])
  }

  const handleChangeDataBooking = async (geraBook = false, dateString) => {
    form.setFieldsValue({ listEspecieVariacao: [] })
    setCheckedList([])
    setCheckAll(false)
    setIndeterminate(false)
    message.loading('Aguarde...', 99999)
    const dataFim = { ...geraDataBooking(dateString) }
    filterDataTalhao(dateString)

    form.resetFields(['codTalhao'])
    setDateBook(dateString)

    setDataBookingTemp(dataFim)

    setListCheckBoxArray(dataFim.monitoramento.map((rec) => rec.especieVariacao))
    setCheckedList(dataFim.monitoramento.map((rec) => rec.especieVariacao))
    form.setFieldsValue({
      listEspecieVariacao: dataFim.monitoramento.map((rec) => rec.especieVariacao),
    })
    setCheckAll(true)

    if (geraBook) {
      handleClickGerarBooking(geraBooking, dataFim)
      handleChangeTalhaoBook(dataFim.codTalhao)
    }

    if (bookApp === 1) {
      setChangeTrue(true)
    }
  }

  // console.log('dateBook', dateBook)
  // console.log('dados', dadosApp)
  // console.log('fitler', filtroOrdenarTalhao)
  // console.log('dataBookingTemp', dataBookingTemp)

  const handleChangeTalhaoBook = (data: any[]) => {}

  const handleClickGerarBooking = (data: PropsGeraBooking, dataFim = null) => {
    try {
      if (data) {
        const dados: TypesBookData = dataFim === null ? dataBookingTemp : dataFim

        let monitoramento: TypesBookData['monitoramento'] = []

        const dadosFiltradosT = filtroOrdenarTalhao.filter((item) =>
          checkedList.includes(item.especieVariacao)
        )

        for (let i = 0; i < dados.monitoramento.length; i += 1) {
          const book = dados.monitoramento[i]

          let checked = []

          if (dataFim === null) {
            checked = checkedList.filter((rec) => rec === book.especieVariacao)
          } else {
            checked = data.listEspecieVariacao.filter((rec) => rec === book.especieVariacao)
          }

          if (checked.length > 0) {
            monitoramento = [...monitoramento, book]
          }
        }

        dados.nameConsultor = data.consultorName
        dados.codTalhao = data.codTalhao
        dados.monitoramento = ordenarTalhao == true ? dadosFiltradosT : monitoramento
        dados.date = dayjs.isDayjs(data.dateBook)
          ? dayjs(data.dateBook).format('DD/MM/YYYY')
          : data.dateBook

        setTimeout(() => {
          setDataBooking(dados)

          setOpenBooking(true)
          if (bookApp === 1) {
            setClickTrue(true)
          }

          setVisibleChangeBooking(false)
          if (dataFim === null) {
            form.resetFields(['dateBook', 'consultorName'])
          }
        }, 500)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const handleChangeCollapse = (key) => {
    setMonteMarker([])
    setOverlayView([])
    setMarkerSelect(-1)
    setCalorSelect(null)

    setKeyCollapse(key)

    if (key === -1 || key === null) {
      const talhoes = propriedade?.talhao.map((item) => {
        return item.kml.coordenadas.map((rec) => {
          return { lat: rec.lat, lng: rec.lng }
        })
      })

      if (map) {
        map?.fitBounds(getBounds(talhoes), { left: 630, right: 80 })
      }
    } else {
      const talhao = propriedade.talhao.find((rec) => rec.cod === Number(key))
      const coord = talhao.kml.coordenadas.map((rec) => {
        return { lat: rec.lat, lng: rec.lng }
      })
      const bounds = getBoundsNoArray(coord)
      map.fitBounds(bounds, { left: 630, right: 80 })
      setMonteMarker([])
    }
  }

  const handleCloseDrawer = () => {
    onClose(false)
    setResumirBook(false)
    setDetailsOpen(false)
    setDataDetails({})
    setSideClose(false)
    setCalorSelect(null)
    setDataMonitoramento({})
    setDetailsOpen(false)
    setColapseOpen(false)
    dispatch(resetListDates())
    setKeyCollapse(null)
    setDataDetails({})
    setMonteMarker([])
    setMonitoramentoAllGroup([])
    setResultados([])
    setDataMonitoramentoNoGroup([])
    setVisibleDrawerPonto(false)
    setOverlayView([])
    setDataGroundOverlay([])

    polygon.setMap(null)
  }

  const loadingAlert = () => {
    message.loading('Gerando book...', 999999)
    if (loadingBook == false) {
      message.destroy()
    }
  }

  const handleClickBadge = (chave) => {
    Object.entries(refDivClickPraga.current).forEach(([key, element]) => {
      if (key === chave) {
        refDivClickPraga.current[chave].style.backgroundColor = 'rgba(0, 200, 250, 0.2)'
      } else if (element !== null) {
        refDivClickPraga.current[key].style.backgroundColor = '#ffffff'
      }
    })
  }

  const handleDisableDate = (date: Dayjs) => {
    const resp = listDates.value.filter(
      (rec) => dayjs(rec).format('DD/MM/YYYY') === date.format('DD/MM/YYYY')
    )

    return resp.length <= 0
  }

  const opcoesOrdenacao = [
    { value: true, label: 'Mapa praga por talhão' },
    { value: false, label: 'Mapa praga por propriedade' },
  ]

  const handleChangeOrdem = (value) => {
    setOrdenarTalhao(value)
    setValorInicial(value)
  }

  useEffect(() => {
    if (propriedade !== null && propriedade?.talhao?.length > 0) {
      const positions: google.maps.LatLng[][] = propriedade?.talhao.map((rec) => {
        if (rec.cod_talhao !== null) {
          rec.isSubArea = true
        } else {
          rec.isSubArea = false
        }
        return rec.kml.coordenadas.map((item) => {
          return new google.maps.LatLng({ lat: item.lat, lng: item.lng })
        })
      })

      polygon?.setPaths(positions)
      polygon?.setMap(map)

      map?.setZoom(3)
      map?.setCenter({ lat: 0, lng: 0 })

      listMonitoramento(propriedade?.cod)
    }
  }, [propriedade])

  useEffect(() => {
    if (!visibleDrawerPonto) {
      setMarkerSelect(-1)
    }
  }, [visibleDrawerPonto])

  useEffect(() => {
    if (dataMonitoramentoNoGroup.length > 0) {
      let monitoramentoAll: MonitoramentoMapaCalorMonitoramentoType[][] = []
      const data = dataMonitoramentoNoGroup?.map((rec) => {
        if (rec.monitoramento !== undefined) {
          monitoramentoAll = [
            ...monitoramentoAll,
            ...rec.monitoramento?.map((leitura) => {
              return leitura.map((item) => {
                return {
                  codMonitoramento: item.codMonitoramento,
                  codTalhao: rec.codTalhao,
                  codNivelControleItem: item.codNivelControleItem,
                  ponto: item.ponto,
                  colmo: item?.colmo,
                  lat: item.lat,
                  lng: item.lng,
                  altitude: item.altitude,
                  repeticao: item.repeticao,
                  valorApurado: item.valorApurado,
                  nomeTalhao: item.nomeTalhao,
                  valorNivel: item.valorNivel,
                  dataInit: item.dataInit,
                  numRepeticao: item.numRepeticao,
                  dataFim: item.dataFim,
                  horaInit: item.horaInit,
                  horaFim: item.horaFim,
                  nomeUsuario: item.nomeUsuario,
                  nivel: item.nivel,
                  nomePraga: item.nivel[0].especie_nome,
                  codEspVari: `${item.nivel[0].especie_nome}, ${item.nivel[0].cod_especie}, ${item.nivel[0].cod_variacao_especie}, ${item.nivel[0].totalizar}`,
                  totalizar: item.nivel[0].totalizar,
                  estadio: item.estadio,
                  codEstadio: item.codEstadio,
                  // talhao_observacao: item.,
                }
              })
            }),
          ]
        }

        return monitoramentoAll
      })
      let tempPraga: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[] }[] = []
      let groupPraga: { [key: string]: MonitoramentoMapaCalorMonitoramentoType[] }[] = []
      let datas: {}
      let totalizar = {}

      monitoramentoAll.forEach((rec) => {
        rec.forEach((item) => {
          const cod = item.codEspVari.split(', ')
          let codE = `${cod[0]}, ${cod[1]}`
          const codV = `${cod[0]}, ${cod[1]}, ${cod[2]}`

          const dadosFilter = nivelControle.filter((item) => item.totalizar === 1)
          let codsTotalizar = []
          dadosFilter.forEach((item, index) => {
            if (
              item.totalizar === 1 &&
              item.variacao_especie_nome !== null &&
              item.cod_especie === parseInt(cod[1])
            ) {
              if (!codsTotalizar.includes(item.variacao_especie_nome)) {
                codsTotalizar.push(item.variacao_especie_nome)
              }
            }
          })
          let nomesFormatados = codsTotalizar.join(' + ')

          let codEspTotal =
            nomesFormatados == ''
              ? (codE += `, ${cod[3]}`)
              : (codE += `, ${nomesFormatados}` + `, ${cod[3]}`)

          if (cod[3] === '1') {
            if (totalizar[codE] === undefined) {
              totalizar[codEspTotal] = [
                { ...item, codEspVari: (cod[0] += `, ${cod[1]},` + `${nomesFormatados}`) },
              ]
            } else {
              totalizar[codEspTotal].push({
                ...item,
                codEspVari: (cod[0] += `, ${cod[1]}` + `${nomesFormatados}`),
              })
            }
          } else {
            if (totalizar[codV] === undefined) {
              totalizar[codV] = [item]
            } else {
              totalizar[codV].push(item)
            }
          }
        })

        tempPraga = [...tempPraga, groupBy(rec, 'codEspVari')]

        datas = { ...datas, ...groupBy(rec, 'dataInit') }
        return { tempPraga, datas, totalizar }
      })

      let temp = {}
      tempPraga.forEach((rec) => {
        Object.entries(rec).forEach(([key, value]) => {
          if (temp[key] !== undefined) {
            value.forEach((ar) => {
              temp[key].push(ar)

              return temp[key]
            })
          } else {
            temp = { ...temp, ...{ [key]: value } }
          }
          return temp
        })
        groupPraga = [temp]
        return groupPraga
      })

      const dataAtual = Object.keys(datas).map((date) => date)
      const sortedDates = [...dataAtual].sort(
        (a, b) => new Date(a).getTime() - new Date(b).getTime()
      )

      const dateInicial =
        sortedDates.length > 0 ? dayjs(sortedDates[sortedDates.length - 1]) : dayjs()
      setDataAtual(dateInicial)
      setDataInicial(dateInicial)
      setDataMonitoramentoBooking(groupPraga as any)

      setTotalizarBook(totalizar)
      setDadosData(groupPraga[0])
      dispatch(setListDates(orderBy(Object.keys(datas).map((rec) => rec)).reverse()))

      setMonitoramentoAllGroup(groupTotalizar ? [objOrder(totalizar)] : [objOrder(groupPraga[0])])
    }
  }, [dataMonitoramentoNoGroup, groupTotalizar])

  useEffect(() => {
    setColapseOpen(false)
  }, [groupTotalizar])
  // console.log('dados4', geraBooking)
  useEffect(() => {
    if (progress.value === 100 && dataMonitoramentoBooking && geraBooking) {
      setPolygon(
        new google.maps.Polygon({
          paths: [],
          fillOpacity: 0,
          strokeColor: '#f2f2f2',
          strokeWeight: 4,
          zIndex: 9999,
        })
      )
      handleChangeDataBooking(true, geraBooking.dateBook)

      // form.setFieldsValue({ ...geraBooking })

      // form.submit()
    }
  }, [dataMonitoramentoBooking])

  useEffect(() => {
    onLoadingBook?.(loadingBook)
  }, [loadingBook])

  useEffect(() => {
    if (clickTrue === true && gerarTrue === true && changeTrue === true) {
      executarSubmit()
    }
  }, [clickTrue, gerarTrue, changeTrue])

  useEffect(() => {
    // Atualiza o valor inicial quando o modal é fechado
    if (!openBooking && !visibleChangeBooking) {
      setValorInicial(true)
      setOrdenarTalhao(true)
      setDisable(false)
    }
  }, [openBooking, visibleChangeBooking])

  useEffect(() => {
    if (resumirBook === true) {
      setOrdenarTalhao(false)
      setValorInicial(false)
      setDisable(true)
    } else {
      setDisable(false)
    }
  }, [resumirBook])

  useEffect(() => {
    if (bookApp === 1) {
      setResumirBook(true)
      setGroupTotalizar(true)
      setTotalizarBookApp(true)
    } else {
      setTotalizarBookApp(false)
      setResumirBook(false)
    }
  }, [])

  useEffect(() => {
    listConsultor()
    setPolygon(
      new google.maps.Polygon({
        paths: [],
        fillOpacity: 0,
        strokeColor: '#f2f2f2',
        strokeWeight: 4,
        zIndex: 9999,
      })
    )
  }, [])

  useEffect(() => {
    if (visibleChangeBooking === true) {
      handleChangeDataBooking(false, dayjs(dataInicial).format('DD/MM/YYYY'))
      message.destroy()
    }
  }, [visibleChangeBooking])

  return (
    <>
      <Container id='container' ref={refContainer}>
        <CanvasInterpolation onOverlayView={setOverlayView} dataGroundOverlay={dataGroundOverlay} />
        <DefaultMap
          onMapLoad={setMap}
          onClose={handleCloseDrawer}
          open={visible}
          zoomMargin={visibleDrawerPonto ? 400 : 0}
          mapOptions={{ isFractionalZoomEnabled: true }}
        >
          {overlayView.map((rec) =>
            rec.url !== null ? (
              <GroundOverlay {...rec} onLoad={(data) => data.get} />
            ) : (
              <Polygon
                path={rec.polygon}
                options={{
                  fillColor: monteMarker.length <= 2 ? '#acacac' : rec.cor,
                  strokeWeight: 0,
                  fillOpacity: 1,
                }}
              />
            )
          )}
          {monteMarker.map((rec, i, array) => {
            return (
              // @ts-ignore
              <Marker
                key={uid()}
                clickable
                icon={markerSelect === i ? { ...iconProps, url: pinSelect } : iconProps}
                label={{
                  text: String(rec.valorApurado.toFixed(1)),
                  fontWeight: 'bold',
                  fontSize: '11px',
                  className: 'aly-text-ping',
                }}
                visible={!hideMarker}
                position={{ lat: rec.lat, lng: rec.lng }}
                onClick={() => handleClickMarker(i, array)}
              />
            )
          })}
          <ContentMap>
            <SidePainel
              hideSave
              onClose={handleCloseDrawer}
              title={<Space>Monitoramento</Space>}
              hideSize='small'
              size='small'
              placement='right'
              hideOverflow
              open={sideClose}
              onCloseSide={(data) => setSideClose(!data)}
              buttonExtra={
                <>
                  <div
                    style={{
                      display: 'flex',
                      flexDirection: 'row',
                      width: '45%',
                      justifyContent: 'flex-end',
                      alignItems: 'center',
                      gap: '5px',
                    }}
                  >
                    <div
                      style={{
                        width: '100px',
                        height: '80px',
                        display: 'flex',
                        justifyContent: 'space-between',
                        flexDirection: 'column',
                        border: '1px solid #e4e4e4',
                        padding: '5px',
                      }}
                    >
                      Gerar Book
                      <div>
                        <Button
                          type='primary'
                          disabled={dataMonitoramentoNoGroup.length <= 0}
                          onClick={() => setVisibleChangeBooking(true)}
                          icon={<Icons icon={faBook} />}
                        />
                      </div>
                    </div>
                    <div
                      style={{
                        width: '100px',
                        height: '80px',
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid #e4e4e4',
                        padding: '5px',
                      }}
                    >
                      Esconder marcadores
                      <div>
                        <Switch
                          unCheckedChildren='Não'
                          checkedChildren='Sim'
                          onChange={setHideMarker}
                        />
                      </div>
                    </div>
                    {/* <div
                      style={{
                        width: '100px',
                        height: '80px',
                        display: 'flex',
                        flexDirection: 'column',
                        border: '1px solid #e4e4e4',
                        padding: '5px',
                      }}
                    >
                      Agrupar totalizado
                      <div>
                        <Switch
                          unCheckedChildren='Não'
                          checkedChildren='Sim'
                          onChange={() => {
                            setGroupTotalizar(!groupTotalizar)
                            handleChangeCollapse(keyCollapse)
                            setDetailsOpen(false)
                            setVisibleDrawerPonto(false)
                          }}
                        />
                      </div>
                    </div> */}
                  </div>
                </>
              }
            >
              <PanelDetails>
                <DetailsMonitoramento slideOpen={detailsOpen}>
                  <Button
                    icon={<Icons icon={faArrowLeft} />}
                    size='small'
                    type='primary'
                    onClick={() => {
                      setDetailsOpen(false)
                      setDataDetails({})
                    }}
                  >
                    Voltar
                  </Button>
                  <table>
                    <tr>
                      <th colSpan={3}>
                        {propriedade?.nome}
                        <p>
                          <small style={{ opacity: '0.8' }}>{nameCliente}</small>
                        </p>
                      </th>
                    </tr>
                    <tr>
                      <td colSpan={3}>{dataDetails?.talhao}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        {dataDetails?.especie}

                        {groupTotalizar
                          ? dataDetails?.totalizar === 1
                            ? null
                            : dataDetails?.variacao !== null && (
                                <p>
                                  <small style={{ opacity: '0.6' }}>{dataDetails?.variacao}</small>
                                </p>
                              )
                          : dataDetails?.variacao !== null && (
                              <p>
                                <small style={{ opacity: '0.6' }}>{dataDetails?.variacao}</small>
                              </p>
                            )}
                      </td>
                    </tr>
                    <tr>
                      <th>Mínimo</th>
                      <th>Máximo</th>
                      <th>Média</th>
                    </tr>
                    <tr className='media'>
                      <td>{dataDetails?.media?.min.toFixed(1)}</td>
                      <td>{dataDetails?.media?.max.toFixed(1)}</td>
                      <td>{parseFloat(String(dataDetails?.media?.med)).toFixed(1)}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Pontos monitorados: {dataDetails?.qtdPontos}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        Pontos acima do nível de controle: {dataDetails?.pontosAcima}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        Pontos abaixo do nível de controle: {dataDetails?.pontosAbaixo}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>
                        Escala invertida:{' '}
                        {dataDetails?.inverterEscala ? (
                          <Inverte invert>Sim</Inverte>
                        ) : (
                          <Inverte>Não</Inverte>
                        )}
                      </td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Data do monitoramento: {dataDetails?.data}</td>
                    </tr>
                    <tr>
                      <td colSpan={3}>Estádio(s): {dataDetails?.estadios}</td>
                    </tr>
                    <tr>
                      <td colSpan={3} className='cores'>
                        {dataDetails.inverterEscala ? (
                          <>
                            <div>
                              <div style={{ backgroundColor: '#FCFF02' }} />
                              <div style={{ backgroundColor: '#BAFF04' }} />
                              <div style={{ backgroundColor: '#01FF00' }} />
                            </div>
                            <div style={{ height: '20px' }}>
                              <div>Pior caso</div>
                              <div>Melhor caso</div>
                            </div>
                          </>
                        ) : (
                          <>
                            <div>
                              <div style={{ backgroundColor: '#01FF00' }} />
                              {/* <div style={{ backgroundColor: '#BAFF04' }} /> */}
                              <div style={{ backgroundColor: '#FCFF02' }} />
                              <div style={{ backgroundColor: '#FCB700' }} />
                              <div style={{ backgroundColor: '#FB0303' }} />
                            </div>
                            <div style={{ height: '20px' }}>
                              <div>Abaixo do NC</div>
                              <div>Nível de controle</div>
                              <div>Acima do NC</div>
                            </div>
                          </>
                        )}

                        <div className='logo'>
                          <img src={logo} alt='Logo' />
                        </div>
                      </td>
                    </tr>
                  </table>
                </DetailsMonitoramento>
                <ContentPanel view={detailsOpen}>
                  <div
                    style={{
                      position: 'sticky',
                      top: 0,
                      backgroundColor: '#ffffff',
                      zIndex: 9998,
                      borderBottom: '1px solid #DCDCDC',
                    }}
                  >
                    <ColapseMoni
                      client={nameCliente}
                      closeContent={colapseOpen}
                      onClose={setColapseOpen}
                      safraName={nameSafra}
                      propriedade={propriedade?.nome}
                      progress={
                        <Progress percent={progress.value} steps={34} strokeColor='green' />
                      }
                      headerContent={
                        <OverFlowDescription>
                          <Description
                            // hidden={permission.find((rec) => rec.cod_rotina === 74)?.acesso !== 1}
                            date={listDates.value}
                          >
                            {monitoramentoAllGroup.map((rec) => {
                              return Object.entries(rec).map(([key, value], i) => {
                                const byDate = groupBy(value, 'dataInit')
                                const split = key.split(', ')
                                const codESpecie = Number(split[1])
                                const codVariaca = split[2] === 'null' ? null : Number(split[2])
                                let nivel: MoniNivelItemControleItens =
                                  {} as MoniNivelItemControleItens

                                if (!codVariaca) {
                                  nivel = nivelControle.find(
                                    (item) => item.cod_especie === codESpecie
                                  )
                                } else {
                                  nivel = nivelControle.find(
                                    (item) =>
                                      item.cod_especie === codESpecie &&
                                      item.cod_variacao_especie === codVariaca
                                  )
                                }

                                const moni = listDates.value.map((rec) => {
                                  const obj = Object.keys(byDate).filter((k) => rec === k)

                                  if (obj.length > 0) {
                                    const temp = Object.entries(byDate).filter(
                                      ([k, v]: any) => obj[0] === k
                                    )[0]
                                    return { [temp[0]]: temp[1] }
                                  }
                                  return { [rec]: [] }
                                })

                                let variacao = <small>{nivel?.variacao_especie_nome}</small>

                                if (nivel?.variacao_especie_nome !== null) {
                                  if (groupTotalizar && nivel?.totalizar === 1) {
                                    variacao = null
                                  }
                                }

                                return (
                                  <>
                                    <DescriptionItem
                                      title={
                                        <>
                                          <span>{nivel?.especie_nome}</span>
                                          {variacao}123
                                        </>
                                      }
                                      data={moni.map((rec) => {
                                        return Object.entries(rec).map(([k, v]) => {
                                          const arrayValues = v.map((item) =>
                                            parseFloat(item.valorApurado)
                                          )
                                          const nivel = v[0]?.nivel[0]
                                          const grad =
                                            nivel?.inverter_escala === 1 ? gradientI : gradient
                                          let color = { cor: '' }
                                          if (Math.max(...arrayValues) === 0) {
                                            color.cor = grad['0']
                                          } else {
                                            color = mediaMapaCalor(
                                              Math.max(...arrayValues),
                                              Number(v[0]?.valorNivel).toFixed(1)
                                            )
                                          }

                                          const med = (
                                            arrayValues.reduce(
                                              (partialSum, a) => partialSum + a,
                                              0
                                            ) / arrayValues.length
                                          ).toFixed(1)

                                          const chave = uid()
                                          return (
                                            <div
                                              style={{
                                                position: 'relative',
                                                width: '100%',
                                                display: 'flex',
                                                justifyContent: 'center',
                                                flexDirection: 'column',
                                                paddingTop: '3px',
                                                cursor: 'pointer',
                                              }}
                                              ref={(ref) => {
                                                refDivClickPraga.current[chave] = ref
                                              }}
                                              onClick={() => {
                                                if (v.length > 0) {
                                                  handleAssembleHeatMap(
                                                    [{ monitoramento: v }],
                                                    true
                                                  )
                                                  handleClickBadge(chave)
                                                  setCalorSelect(chave)
                                                }
                                              }}
                                            >
                                              {v.length <= 0 ? (
                                                <>
                                                  <BadgePulse colorBadge={color.cor}>
                                                    <Badge
                                                      status={
                                                        calorSelect === chave ? 'processing' : null
                                                      }
                                                      color='#bebebe'
                                                    />
                                                  </BadgePulse>
                                                  <MedMax hide>
                                                    <div>Mín: 0</div>
                                                    <div>Méd: 0</div>
                                                    <div>Máx: 0</div>
                                                  </MedMax>
                                                </>
                                              ) : (
                                                <>
                                                  <BadgePulse colorBadge={color.cor}>
                                                    <Badge
                                                      status={
                                                        calorSelect === chave ? 'processing' : null
                                                      }
                                                      color={color.cor}
                                                    />
                                                  </BadgePulse>
                                                  <MedMax>
                                                    <div>
                                                      Mín: {Math.min(...arrayValues).toFixed(1)}
                                                    </div>
                                                    <div>Méd: {Number(med).toFixed(1)}</div>
                                                    <div>
                                                      Máx: {Math.max(...arrayValues).toFixed(1)}
                                                    </div>
                                                  </MedMax>
                                                </>
                                              )}
                                            </div>
                                          )
                                        })
                                      })}
                                    />
                                  </>
                                )
                              })
                            })}
                          </Description>
                        </OverFlowDescription>
                      }
                      onChange={handleChangeCollapse}
                      extraHeader={
                        propriedade?.talhao?.length > 0 ? (
                          <IconAnd
                            component={() => (
                              <Svg
                                // @ts-ignore
                                coordinates={propriedade?.talhao.map((rec) => rec.kml.coordenadas)}
                                multi
                              />
                            )}
                          />
                        ) : null
                      }
                      talhao={Object.entries(dataMonitoramento).map(([key, moni]) => {
                        const talhao = propriedade?.talhao.find((rec) => rec.cod === Number(key))
                        const monitoramento = moni.map((rec) => rec.monitoramento)
                        let listMoni = []
                        monitoramento.forEach((rec) => {
                          rec.forEach((item) => {
                            listMoni = [...listMoni, ...item]
                          })
                          return listMoni
                        })

                        const moniNivel = groupBy(listMoni, 'codEspVari')
                        let totalizar = {}
                        listMoni.forEach((item) => {
                          const cod = item.codEspVari.split(', ')
                          const codE = `${cod[0]}, ${cod[1]}`
                          const codV = `${cod[0]}, ${cod[1]}, ${cod[2]}`

                          if (cod[3] === '1') {
                            if (totalizar[codE] === undefined) {
                              totalizar[codE] = [item]
                            } else {
                              totalizar[codE].push(item)
                            }
                          } else {
                            if (totalizar[codV] === undefined) {
                              totalizar[codV] = [item]
                            } else {
                              totalizar[codV].push(item)
                            }
                          }
                        })
                        const dateIsPraga = Object.entries(
                          groupTotalizar ? objOrder(totalizar) : objOrder(moniNivel)
                        ).map(([dKey, dValue]: [any, any]) => {
                          const pragas = groupBy(dValue, 'dataInit')
                          return { [dKey]: { ...pragas } }
                        })

                        localStorage.setItem('tlhao_teste_kml', JSON.stringify(talhao?.kml?.coordenadas))

                        return {
                          codTalhao: Number(key),
                          nameTalhao: talhao?.nome,
                          isSubarea: talhao?.isSubArea,
                          nameTalhaoSubArea: propriedade?.talhao.find(
                            (rec) => rec?.cod === talhao?.cod_talhao
                          )?.nome,
                          extraBody: (
                            <Svg
                              fill={talhao.isSubArea ? '#FFA500' : '#70CE9F'}
                              // @ts-ignore
                              coordinates={talhao?.kml?.coordenadas}
                            />
                          ),
                          content: (
                            <OverFlowDescription sizOverflow={true}>
                              <Description date={listDates.value}>
                                {dateIsPraga.map((rec) => {
                                  return Object.entries(rec).map(([pKey, value]: [string, any]) => {
                                    const codESpecie = Number(pKey.split(', ')[1])
                                    const codVariaca =
                                      pKey.split(', ')[2] === 'null'
                                        ? null
                                        : Number(pKey.split(', ')[2])

                                    let nivel: MoniNivelItemControleItens =
                                      {} as MoniNivelItemControleItens
                                    if (!codVariaca) {
                                      nivel = nivelControle.find(
                                        (item) => item.cod_especie === codESpecie
                                      )
                                    } else {
                                      nivel = nivelControle.find(
                                        (item) =>
                                          item.cod_especie === codESpecie &&
                                          item.cod_variacao_especie === codVariaca
                                      )
                                    }

                                    const moni = listDates.value.map((rec) => {
                                      const obj = Object.keys(value).filter((k) => rec === k)

                                      if (obj.length > 0) {
                                        const temp = Object.entries(value).filter(
                                          ([k, v]: any) => obj[0] === k
                                        )[0]
                                        return { [temp[0]]: temp[1] }
                                      }
                                      return { [rec]: [] }
                                    })

                                    let variacao = <small>{nivel?.variacao_especie_nome}</small>

                                    if (nivel?.variacao_especie_nome !== null) {
                                      if (groupTotalizar && nivel?.totalizar === 1) {
                                        variacao = null
                                      }
                                    }

                                    return (
                                      <DescriptionItem
                                        title={
                                          <>
                                            <span>{nivel?.especie_nome}</span>
                                            {variacao}
                                          </>
                                        }
                                        // data e array monitoramento para click
                                        data={moni.map((rec) => {
                                          // @ts-ignore
                                          return Object.entries(rec).map(([k, v]: any) => {
                                            const arrayValues = v.map((item) =>
                                              parseFloat(item.valorApurado)
                                            )

                                            let color = { cor: '' }
                                            const nivel = v[0]?.nivel[0]
                                            const grad =
                                              nivel?.inverter_escala === 1 ? gradientI : gradient
                                            if (Math.max(...arrayValues) === 0) {
                                              color.cor = grad['0']
                                            } else {
                                              color = mediaMapaCalor(
                                                Math.max(...arrayValues),
                                                Number(v[0]?.valorNivel).toFixed(1)
                                              )
                                            }

                                            const med = (
                                              arrayValues.reduce(
                                                (partialSum, a) => partialSum + a,
                                                0
                                              ) / arrayValues.length
                                            ).toFixed(1)

                                            const chave = uid()
                                            return (
                                              <div
                                                style={{
                                                  position: 'relative',
                                                  width: '100%',
                                                  display: 'flex',
                                                  justifyContent: 'center',
                                                  flexDirection: 'column',
                                                  paddingTop: '3px',
                                                  cursor: 'pointer',
                                                }}
                                                ref={(ref) => {
                                                  refDivClickPraga.current[chave] = ref
                                                }}
                                                onClick={() => {
                                                  if (v.length > 0) {
                                                    handleAssembleHeatMap(
                                                      [{ monitoramento: v }],
                                                      false,
                                                      Number(key)
                                                    )
                                                    handleClickBadge(chave)
                                                    setCalorSelect(chave)
                                                  }
                                                }}
                                              >
                                                {v.length <= 0 ? (
                                                  <>
                                                    <BadgePulse colorBadge={color.cor}>
                                                      <Badge
                                                        status={
                                                          calorSelect === chave
                                                            ? 'processing'
                                                            : null
                                                        }
                                                        color='#bebebe'
                                                      />
                                                    </BadgePulse>
                                                    <MedMax hide>
                                                      <div>Mín: 0</div>
                                                      <div>Méd: 0</div>
                                                      <div>Máx: 0</div>
                                                    </MedMax>
                                                  </>
                                                ) : (
                                                  <>
                                                    <BadgePulse colorBadge={color.cor}>
                                                      <Badge
                                                        status={
                                                          calorSelect === chave
                                                            ? 'processing'
                                                            : null
                                                        }
                                                        color={color.cor}
                                                      />
                                                    </BadgePulse>
                                                    <MedMax>
                                                      <div>
                                                        Mín: {Math.min(...arrayValues).toFixed(1)}
                                                      </div>
                                                      <div>Méd: {Number(med).toFixed(1)}</div>
                                                      <div>
                                                        Máx: {Math.max(...arrayValues).toFixed(1)}
                                                      </div>
                                                    </MedMax>
                                                  </>
                                                )}
                                              </div>
                                            )
                                          })
                                        })}
                                      />
                                    )
                                  })
                                })}
                              </Description>
                            </OverFlowDescription>
                          ),
                        }
                      })}
                    />
                    <p />
                  </div>
                </ContentPanel>
              </PanelDetails>
            </SidePainel>
          </ContentMap>

          <DivDrawerPonto
            slideOpen={visibleDrawerPonto}
            colorBadge={
              visibleDrawerPonto
                ? mediaMapaCalor(viewPonto.valorApurado, viewPonto.valorNivel).cor
                : ''
            }
          >
            <Card
              title={
                <>
                  <Icons icon={faLocationDot} /> Ponto {viewPonto.ponto}
                </>
              }
              extra={
                <Button
                  size='small'
                  ghost
                  icon={<CloseOutlined />}
                  onClick={() => setVisibleDrawerPonto(false)}
                />
              }
              style={{ width: '100%', height: '100vh', zIndex: 99999 }}
            >
              <Descriptions size='small' bordered>
                <Descriptions.Item label='Latitude' span={24}>
                  {viewPonto.lat}
                </Descriptions.Item>
                <Descriptions.Item label='Longitude' span={24}>
                  {viewPonto.lng}
                </Descriptions.Item>
                {viewPonto.altitude !== null && (
                  <Descriptions.Item label='Altitude' span={24}>
                    {Number(viewPonto.altitude).toFixed(2)}
                  </Descriptions.Item>
                )}
                <Descriptions.Item label='Talhão' span={24}>
                  {viewPonto.nomeTalhao}
                </Descriptions.Item>
                <Descriptions.Item label='Espécie' span={24}>
                  {viewPonto.nivel?.map((rec) => rec.especie_nome)}
                  <br />
                  <small style={{ opacity: 0.6 }}>
                    {viewPonto.nivel?.map((rec) =>
                      rec.totalizar === 0 ? rec.variacao_especie_nome : null
                    )}
                  </small>
                </Descriptions.Item>
                <Descriptions.Item label='Repetições' span={24}>
                  {/* @ts-ignore */}
                  {viewPonto.repeticao?.length}
                </Descriptions.Item>
                <Descriptions.Item label='Categoria' span={24}>
                  {viewPonto.nivel?.map((rec) => rec.categoria_nome)}
                </Descriptions.Item>
                <Descriptions.Item label='Estádio' span={24}>
                  {viewPonto.estadio}
                </Descriptions.Item>
                {codCultura === 9 && (
                  <Descriptions.Item label='Colmo' span={24}>
                    {viewPonto?.colmo}mm
                  </Descriptions.Item>
                )}
                <Descriptions.Item label='Data início' span={24}>
                  {dayjs(viewPonto.dataInit).format('DD/MM/YYYY')}{' '}
                  {viewPonto.horaFim?.substring(0, 5)}
                </Descriptions.Item>
                <Descriptions.Item label='Data fim' span={24}>
                  {dayjs(viewPonto.dataFim).format('DD/MM/YYYY')}{' '}
                  {viewPonto.horaFim?.substring(0, 5)}
                </Descriptions.Item>
                <Descriptions.Item label='Usuário' span={24}>
                  {viewPonto.nomeUsuario}
                </Descriptions.Item>
                <Descriptions.Item label='NC' span={24}>
                  {viewPonto.valorNivel}
                </Descriptions.Item>
                <Descriptions.Item label='Valor observado' span={24}>
                  {Number(viewPonto.valorApurado).toFixed(2)}{' '}
                  <Badge
                    status='processing'
                    color={
                      visibleDrawerPonto
                        ? mediaMapaCalor(viewPonto.valorApurado, viewPonto.valorNivel).cor
                        : ''
                    }
                  />
                </Descriptions.Item>
              </Descriptions>
              <Descriptions column={1} bordered size='small' title='Repetições'>
                {/* @ts-ignore */}
                {viewPonto?.repeticao?.map((rec) => {
                  return (
                    <Descriptions.Item contentStyle={{ width: '300px' }} label={rec?.numRepeticao}>
                      <div
                        style={{
                          width: '100%',
                          display: 'flex',
                          justifyContent: 'space-between',
                          flexDirection: 'row',
                        }}
                      >
                        <span>{rec?.valorApurado.toFixed(1)}</span>
                        <Button
                          size='small'
                          shape='round'
                          icon={<Icons icon={faLocationDot} />}
                          onClick={() => {
                            markerRepeticao.setPosition({ lat: rec.lat, lng: rec.lng })
                            markerRepeticao.setMap(map)
                          }}
                        />
                        <span>{dayjs(rec?.dataFim).format('DD/MM/YYYY')}</span>
                      </div>
                    </Descriptions.Item>
                  )
                })}
                <Descriptions.Item label='Tempo'>
                  {viewPonto?.repeticao?.length > 0 &&
                    convertMinHoras(
                      Math.abs(
                        dayjs(viewPonto?.repeticao[0]?.horaInit, 'HH:mm').diff(
                          dayjs(
                            viewPonto?.repeticao[viewPonto.repeticao?.length - 1]?.horaFim,
                            'HH:mm'
                          ),
                          'minute'
                        )
                      )
                    )}
                </Descriptions.Item>
              </Descriptions>
            </Card>
          </DivDrawerPonto>
        </DefaultMap>
        <Modal
          forceRender
          open={visibleChangeBooking}
          onCancel={() => {
            setVisibleChangeBooking(false)
            handleChangeAllPraga([])
            setListCheckBoxArray([])
            setResultados([])
            setCheckAll(false)
            setResumirBook(false)
            setOrdenarTalhao(false)
            form.resetFields()
          }}
          footer={false}
          title='Gerar book'
          width={1000}
        >
          {' '}
          {visibleChangeBooking === true ? (
            <Form layout='vertical' form={form} onFinish={handleSubmitBook}>
              <Col span={24}>
                <Row gutter={[5, 5]}>
                  <Col span={5}>
                    <Form.Item
                      name='dateBook'
                      rules={[{ required: true, message: 'Por favor, insira a data' }]}
                      initialValue={dataAtual}
                    >
                      <DatePicker
                        style={{ width: '100%' }}
                        disabledDate={handleDisableDate}
                        defaultValue={dataAtual}
                        onChange={(_, date) => handleChangeDataBooking(false, date)}
                        placeholder='Selecione uma data'
                      />
                    </Form.Item>

                    {tipoAcesso == 'conta' ? (
                      <Form.Item
                        name='consultorName'
                        rules={[{ required: true, message: 'Por favor, selecione o consultor' }]}
                        initialValue={codConsultor == '' ? null : codConsultor}
                      >
                        <Select
                          defaultValue={codConsultor}
                          showSearch
                          value={codConsultor}
                          placeholder='Consultor responsável'
                          options={dataConsultor.map((data) => {
                            return { label: data.usuario_nome, value: data.usuario_nome }
                          })}
                        />
                      </Form.Item>
                    ) : null}

                    <Form.Item
                      name='codTalhao'
                      label={
                        <div>
                          Talhão{' '}
                          <small style={{ fontSize: '10px', opacity: '0.8' }}>(Opcional)</small>
                        </div>
                      }
                      initialValue={[]}
                    >
                      <Select
                        mode='multiple'
                        showSearch
                        onChange={handleChangeTalhaoBook}
                        optionFilterProp='label'
                        optionLabelProp='label'
                        placeholder='Selecione o talhão'
                      >
                        {resultados?.map((rec) => (
                          <Select.Option label={rec.nome} value={rec.cod}>
                            <div
                              style={{
                                display: 'flex',
                                alignItems: 'center',
                                justifyContent: 'space-between',
                              }}
                            >
                              {/* @ts-ignore */}
                              <Svg coordinates={rec.coordenadas} /> {rec.nome}
                            </div>
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                    <Form.Item
                      name='mapType'
                      label='Tipo de mapa'
                      initialValue={google.maps.MapTypeId.SATELLITE}
                    >
                      <Select
                        placeholder='Tipo terreno mapa'
                        // onChange={(data) => setMapType(data)}
                        options={[
                          { label: 'Terreno', value: google.maps.MapTypeId.SATELLITE },
                          { label: 'Sem terreno', value: google.maps.MapTypeId.TERRAIN },
                        ]}
                      />
                    </Form.Item>
                    <Form.Item label='Configurações'>
                      <div
                        style={{
                          borderRadius: '5px',
                        }}
                      >
                        <Form.Item style={{ marginTop: '5px' }}>
                          <Select
                            disabled={disable}
                            value={valorInicial}
                            optionFilterProp='label'
                            showSearch
                            onChange={handleChangeOrdem}
                            options={opcoesOrdenacao.map((item) => {
                              return { label: item.label, value: item.value }
                            })}
                          />
                        </Form.Item>
                      </div>
                    </Form.Item>
                  </Col>
                  <Col
                    style={{
                      overflow: 'auto',
                      marginLeft: '20px',
                    }}
                    span={16}
                  >
                    <Checkbox
                      indeterminate={indeterminate}
                      onChange={handleCheckAllChange}
                      checked={checkAll}
                    >
                      Selecionar todos
                    </Checkbox>
                    <Form.Item
                      name='listEspecieVariacao'
                      rules={[{ required: true, message: 'Por favor, selecione uma espécie' }]}
                    >
                      <Checkbox.Group
                        style={{
                          display: 'flex',
                          flexDirection: 'column',
                          marginTop: '15px',

                          height: '300px',

                          margin: '0',
                          padding: '0',
                        }}
                        value={checkedList}
                        onChange={handleChangeAllPraga}
                      >
                        {listCheckBoxArray.map((rec) => {
                          const ev = rec.split(', ')
                          const e = Number(ev[1])
                          const v = ev[2] === 'null' ? null : Number(ev[2])

                          const nivel = nivelControle.find(
                            (item) => item?.cod_especie === e && v === item?.cod_variacao_especie
                          )

                          return (
                            <Checkbox
                              key={uid()}
                              style={{
                                marginLeft: '20px',
                                width: '35%',
                                margin: '0',
                                padding: '0',
                              }}
                              className='check-box-all'
                              value={rec}
                            >
                              <div style={{ display: 'flex', flexDirection: 'column' }}>
                                <div>{nivel?.especie_nome}</div>
                                {v !== null && <small>{nivel?.variacao_especie_nome}</small>}
                              </div>
                            </Checkbox>
                          )
                        })}
                      </Checkbox.Group>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Form.Item extra='*Isso pode levar alguns minutos dependendo da quantidade de informação 123'>
                <Button
                  onClick={() => loadingAlert()}
                  loading={loadingBook}
                  htmlType='submit'
                  type='primary'
                >
                  Gerar
                </Button>
              </Form.Item>
            </Form>
          ) : null}
        </Modal>
      </Container>
      <BookMonitoramento
        mapType={mapType}
        nivelControleItens={nivelControle}
        onClose={(b) => {
          setOpenBooking(b)
          setDataBooking({})
        }}
        onLoading={setLoadingBook}
        finishBook={finishBook}
        open={openBooking}
        data={dataBooking}
        resumir={resumirBook}
        ordenarTalhao={ordenarTalhao}
        setResumir={setResumirBook}
        setOpenBook={setOpen}
        totalizar={totalizarBookApp}
        setOrdenarTalhao={setOrdenarTalhao}
        codPro={codPro}
      />
    </>
  )
}

export default Monitoramento