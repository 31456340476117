import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'

import { Select, Space, Transfer } from 'antd'
import { TransferDirection } from 'antd/es/transfer'
import { concat, difference } from 'lodash'
import { Notification } from '../../../../Components/notification'
import { tryError } from '../../../../services/Afins'
import { EquipeItens, getEquipe } from '../../../../services/Equipe'
import { AdmConsultoriaItens, getAdmConsultoria } from '../../../../services/admConsultoria'
import { getUserTesterApp, postUserTesterApp } from '../../../../services/userTesterApp'
import { Container } from './styles'

const UserTesterApp: React.FC = () => {
  const [admConsultoriaItens, setAdmConsultoriaItens] = useState<AdmConsultoriaItens[]>([])
  const [loading, setLoading] = useState(false)
  const [consultoriaSelect, setConsultoriaSelect] = useState<number>()
  const [dataTransfer, setDataTransfer] = useState<{
    membrosEquipe: EquipeItens[]
    userTester: string[]
  }>({} as any)

  const listConsultoria = async () => {
    const resp = await getAdmConsultoria({})
    const dados = resp.data.itens
    setAdmConsultoriaItens(dados)

    if (dados.length === 1) {
      handleChangeUserAccess(dados[0].cod)
    }
  }

  const handleChangeUserAccess = async (codConta) => {
    try {
      setLoading(true)
      setConsultoriaSelect(codConta)
      const [userTester, equipe] = await Promise.all([
        getUserTesterApp({ ...codConta }),
        getEquipe({ codConta, acesso: 'conta' }),
      ])

      setDataTransfer({
        membrosEquipe: equipe.data.itens.map((rec) => {
          rec.cod_func = String(rec.cod_func)
          return rec
        }),
        userTester: userTester.data.itens.map((rec) => {
          return String(rec.cod_usuario)
        }),
      })
      setLoading(false)
    } catch (error) {
      tryError(error)
      setLoading(false)
    }
  }

  const filterOption = (inputValue: string, option: any) => {
    return option.usuario_nome.toLowerCase().indexOf(inputValue.toLowerCase()) > -1
  }

  const handleChangeMoveTransfer = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    try {
      setLoading(true)
      if (direction === 'right') {
        const finish = {
          cod_conta: consultoriaSelect,
          usuarios: moveKeys,
        }
        const resp = await postUserTesterApp({ ...finish })
        if (resp.status === 200 || resp.status === 201) {
          setDataTransfer((data) => {
            return {
              membrosEquipe: data.membrosEquipe,
              userTester: [...data.userTester, ...moveKeys],
            }
          })

          Notification({ type: 'success', message: 'Usuário liberado para testes!' })
          setLoading(false)
        }
      } else {
        const finish = {
          cod_conta: consultoriaSelect,
          usuarios_remover: moveKeys,
        }
        const resp = await postUserTesterApp({ ...finish })
        if (resp.status === 200 || resp.status === 201) {
          const userTester = concat(
            difference(dataTransfer.userTester, moveKeys),
            difference(moveKeys, dataTransfer.userTester)
          )
          setDataTransfer((data) => {
            return { membrosEquipe: data.membrosEquipe, userTester }
          })

          Notification({ type: 'success', message: 'Usuário removido com sucesso!' })
          setLoading(false)
        }
      }
    } catch (error) {
      tryError(error)
      setLoading(false)
    }
  }

  useEffect(() => {
    listConsultoria()
  }, [])
  return (
    <Container>
      <ViewPage newHide title='Usuário teste APP'>
        <Container>
          <Space style={{ width: '100%' }} direction='vertical'>
            <Select
              style={{ maxWidth: '500px', minWidth: '300px' }}
              value={consultoriaSelect}
              size='large'
              onChange={handleChangeUserAccess}
              popupMatchSelectWidth
              placeholder='Selecione uma consultoria'
              options={admConsultoriaItens.map((rec) => {
                return { label: rec.nome, value: rec.cod }
              })}
            />
            <Transfer
              rowKey={(data) => data.cod_func as string}
              disabled={loading}
              targetKeys={dataTransfer?.userTester}
              dataSource={dataTransfer?.membrosEquipe}
              selectAllLabels={[<b>Usuários</b>, <b>Usuários liberados</b>]}
              render={(rec) => <>{rec.usuario_nome}</>}
              listStyle={{ width: '100%', height: 'calc(90vh - 150px)' }}
              showSelectAll
              showSearch
              onChange={handleChangeMoveTransfer}
              filterOption={filterOption}
            />
          </Space>
        </Container>
      </ViewPage>
    </Container>
  )
}

export default UserTesterApp
