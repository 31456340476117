import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesClienteModulo {
  itens: ItensClienteModuloTypes[]
}

export interface ItensClienteModuloTypes {
  cliente_nome: string
  cod_cliente: number
  cod_modulo: number
  modulo_nome: string
}

type TypesQuery = {
  cod?: number
  embed?: string
  codCliente?: number
}

const url = '/cliente-modulo'

export const getClienteModulo = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesClienteModulo> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postClienteModulo = async (data) => {
  const { conta, codCliente } = localConfig()
  
  
  const resp: AxiosResponse<TypesClienteModulo> = await api.post(url, data, {
    params: { codConta: conta, codCliente },
  })

  return resp
}

export const putClienteModulo = async (data, cod: number) => {
  const { conta, codCliente } = localConfig()

  const resp: AxiosResponse<TypesClienteModulo> = await api.put(`${url}/${cod}`, data, {
    params: { codConta: conta, codCliente },
  })

  return resp
}

export const delClienteModulo = async (cod: number) => {
  const { conta, codCliente } = localConfig()

  const resp: AxiosResponse<TypesClienteModulo> = await api.delete(`${url}/${cod}`, {
    params: { codConta: conta, codCliente },
  })

  return resp
}
