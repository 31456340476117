import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesRotina {
  itens: ItensRotinaTypes[]
}

export interface ItensRotinaTypes {
  cod_rotina: number
  cod: number
  nome: string
  cod_modulo: number
  acesso: number | boolean | any
  inclusao: number | boolean | any
  alteracao: number | boolean | any
  exclusao: number | boolean | any
  modulo_cod: number | null
  descricao?: string
}

type TypesQuery = {
  cod?: number
  embed?: 'permissoes'
}

const url = '/rotina'

export const getRotina = async (params: TypesQuery) => {
  const { conta, codCliente, acesso } = localConfig()
  let moduloCliente
  if (acesso === 'cliente') {
    moduloCliente = 1
  } else {
    moduloCliente = 0
  }
  const resp: AxiosResponse<TypesRotina> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      moduloCliente,
      pagination: -1,
    },
  })

  return resp
}

export const postRotina = async (data) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'admin'
  const resp: AxiosResponse<TypesRotina> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putRotina = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'admin'

  const resp: AxiosResponse<TypesRotina> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
