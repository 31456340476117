import { AxiosResponse } from 'axios'
import { api } from '../api'

export interface TypeGetResultLabs {
  total?: number
  itens: [ResultLabsItens]
}

export interface ResultLabsItens {
  cod?: number
  cod_safra?: number
  cod_safra_conta?: number
  cod_laboratorio?: number
  cod_usuario?: number
  cod_representante?: number | null
  cod_calcario?: number
  talhao_nome?: string
  talhao_arquivo?: string
  talhao_area?: number
  /**
   * @param {status} 1 = Aberto | 2 = Liberado para Coleta | 3 = Coletada | 4 = Em análise | 5 = Análisada
   */
  status: 1 | 2 | 3 | 4 | 5
  arquivo?: string
  arquivo_processado?: string
  cod_usuario_laboratorio?: number
  data?: string
  data_coleta?: string
  solicitante?: number
  cod_conta?: number
  cod_cliente?: number
  cod_propriedade?: number
  propriedade_nome?: string
  laboratorio_nome?: string
  safra_nome?: string
  conta?: [
    {
      cod?: number
      nome?: string
    }
  ]
  cliente?: [
    {
      cod?: number
      nome?: string
    }
  ]
  propriedade?: [
    {
      cod?: number
      nome?: string
      status?: number
      cod_tipo?: number
      cod_conta?: number
      cod_cliente?: number
      arquivo?: string
      area?: number
      matricula?: string
      cod_cidade?: number
      cidade_nome?: string
      estado_nome?: string
      estado_sigla?: string
    }
  ]
  arquivo_url?: ''
  arquivo_processado_url?: ''
}

interface TypeParamLabGet {
  cod?: number
  page?: number | 1
  pagination?: number | -1
}

export const getResultLab = async (params: TypeParamLabGet) => {
  params = {
    ...params,
  }

  const response: AxiosResponse<TypeGetResultLabs> = await api.get(
    '/acesso-resultado-laboratorio',
    {
      params,
    }
  )

  return response
}

export const putResultLab = async (cod, arquivo) => {
  const response: AxiosResponse<TypeGetResultLabs> = await api.put(
    `/acesso-resultado-laboratorio/${cod}`,
    arquivo
  )

  return response
}
