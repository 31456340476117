import React, { useEffect, useState } from 'react'

import {
  Button,
  Card,
  Col,
  Drawer,
  Form,
  Input,
  message,
  Modal,
  Radio,
  Row,
  Select,
  Space,
  Table,
  Tabs,
} from 'antd'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { FontAwesomeIcon as FontA } from '@fortawesome/react-fontawesome'
import InputMask from 'react-input-mask'

import { getFornecedor, postFornecedor, putFornecedor } from '../../services/Fornecedores'
import { InputChangeEventHandler } from '../../types'

import { buscaCidade, listUf, tryError } from '../../services/Afins'

import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import { getServico } from '../../services/Servicos'
import { delfornecedorServico, getfornecedorServico } from '../../services/TipoServico'
import { IconAlert } from '../iconsAlert'
import { Notification } from '../notification'
import { Container } from './styled'
import DiasDesdeData from '../DiasDesdeData'

const { Option } = Select

const { TabPane } = Tabs

interface TypesLaboratorio {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
  dataFornecedores: any
}

const Laboratorio: React.FC<TypesLaboratorio> = (props) => {
  const { update, visible, setOnClose, setUpdate, atualizar, dataFornecedores, permissions } = props

  const [form] = Form.useForm()

  const [dataCity, setDataCity] = useState([])
  const [dataUf, setDataUf] = useState([])
  const [inputDisableCidade, setInputDisableCidade] = useState(true)
  const [valueInputCity, setValueInputCity] = useState(undefined)
  const [tabsSelect, setTabSelect] = useState('1')
  const [cardService, setCardService] = useState('1')
  const [updateService, setUpdateService] = useState(0)
  const [rowSelected, setRowSelected] = useState<number>()
  const [success, setSuccess] = useState([])
  const [toogleTipo, setToogleTipo] = useState({
    mask: '',
    label: 'CPF/CNPJ',
    fisico: true,
    juridico: true,
    labelF: 'Nome',
    labelJ: 'Nome Fantasia',
  })

  const [dataService, setDataService] = useState([])
  const [dataFornecedorServi, setDataFornecedorServi] = useState([])
  const [loadingTable, setLoadingTable] = useState(false)
  const [nameBtn, setNameBtn] = useState(tabsSelect === '2' ? 'Salvar' : 'Atualizar Fornecedor')

  const [radioTipo, setRadioTipo] = useState('J')

  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const hancleDrawerClose = () => {
    setOnClose(false)
    setUpdate(0)
    setTabSelect('1')
    setUpdateService(0)
    form.resetFields()
  }

  const onFinish = async (data: Object) => {
    try {
      if (update === 0) {
        message.loading('Aguarde...')
        const response = await postFornecedor(data)

        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Fornecedor cadastrado com Sucesso',
            type: 'success',
          })

          atualizar()
          const dados = Object.entries(data).map(([key, value]) => {
            return { [key]: null }
          })

          setSuccess(response.data.itens)
        }
      } else {
        message.loading('Aguarde...')
        const response = await putFornecedor(update, data)

        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Fornecedor atualizado com Sucesso',
            type: 'success',
          })
          atualizar()
        }
      }
    } catch (error) {
      message.destroy()

      tryError(error)
    }
  }

  const handleChangeCliente = async (data) => {
    message.loading('Aguarde...')
    const response = await getFornecedor('', data)
    const dados = response.data.itens[0]

    setLoadingTable(true)
    const respServico = await getfornecedorServico(update, '', '')
    setDataFornecedorServi(respServico.data.itens)
    setLoadingTable(false)

    form.setFieldsValue({ cod_fornecedor: update })

    form.setFieldsValue(dados)
    haldleChangeCidadeUf(dados?.cidade[0]?.cod_uf)

    form.setFieldsValue({ cidade_cod_uf: dados?.cidade[0].cod_uf })
    message.destroy()
  }

  const handleChangeTipo = (e) => {
    e.preventDefault()
    setRadioTipo(e.target.value)
  }

  const haldleChangeCidadeUf = async (cod) => {
    if (cod) {
      const resCity = await buscaCidade('', cod, '')
      setDataCity(resCity)

      setInputDisableCidade(false)
    } else {
      setInputDisableCidade(false)
    }
  }

  const handleChangeCity = (value) => {
    setValueInputCity({ value })
  }

  const listServico = async () => {
    const response = await getServico('')
    setDataService(response.data.itens)
  }

  const handleChangeMask = (data) => {
    let valor = data.target.value
    valor = valor.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    valor = (valor / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ valor })
  }

  const handelClickSubmit = () => {
    form.submit()
  }

  const handelChangeTabs = (key) => {
    setTabSelect(key)
  }

  const colums = [
    {
      key: 1,
      title: 'Data',
      dataIndex: 'data',
      render: (data) => {
        const date = data.split('-').reverse().join('/')
        return <>{date}</>
      },
    },
    {
      key: 2,
      title: 'Serviço',
      dataIndex: 'servico_nome',
    },
    {
      key: 3,
      title: 'Valor',
      dataIndex: 'valor',
    },
    {
      title: 'Ações',
      dataIndex: 'cod',
      key: 'acao',
      render: (data, dados) => {
        setCardService(dados.fornecedor_nome)
        return (
          <>
            {/* <Button
              type="primary"
              shape="circle"
              className="info-button"
              onClick={() => handleClickFornServ(data)}
              icon={<FontA icon={solid("pen")} />}
              size="middle"
            /> */}
            {'   '}
            <Button
              shape='circle'
              onClick={() => handleTrashFornServ(data)}
              className='trash-button'
              type='primary'
              icon={<FontA icon={solid('trash')} />}
              size='middle'
            />
          </>
        )
      },
    },
  ]

  const handleClickFornServ = async (data) => {
    setUpdateService(data)

    const response = await getfornecedorServico(update, '', data)

    form.setFieldsValue(response.data.itens[0])
    form.setFieldsValue({
      valor: response.data.itens[0].valor.replace('.', ','),
    })
  }
  const handleTrashFornServ = async (data) => {
    try {
      Modal.confirm({
        title: 'Excluir',
        content: 'Deseja realmente remover o Serviço?',
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        icon: <IconAlert type='confirm' size={6} />,
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delfornecedorServico(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Serviço Excluido',
              type: 'success',
            })

            handleChangeCliente(update)
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  useEffect(() => {
    if (update > 0) {
      handleChangeCliente(update)
    }
  }, [update])

  useEffect(() => {
    if (radioTipo === 'J') {
      setToogleTipo({
        mask: '99.999.999/9999-99',
        label: 'CNPJ',
        fisico: false,
        juridico: true,
        labelF: '',
        labelJ: 'Nome Fantasia',
      })
    } else if (radioTipo === 'F') {
      setToogleTipo({
        mask: '999.999.999-99',
        label: 'CPF',
        fisico: true,
        juridico: false,
        labelF: 'Nome',
        labelJ: '',
      })
    }
  }, [radioTipo])

  useEffect(() => {
    form.resetFields()
  }, [success])

  useEffect(() => {
    async function fetch() {
      const resUF = await listUf()

      setDataUf(resUF)
    }

    listServico()
    fetch()
  }, [])

  const optionsCity = dataCity.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))
  const optionsUF = dataUf.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.sigla}
    </Option>
  ))

  const optionsServicos = dataService.map((e) => (
    <Option key={e.cod} value={e.cod}>
      {e.nome}
    </Option>
  ))

  useEffect(() => {
    if (visible) {

      const fornecedoCheck = dataFornecedores.filter((item) => item.cod === update)

      if (fornecedoCheck.length > 0) {
        if (fornecedoCheck[0].create_user) {
          setUsuarioQueCriou(fornecedoCheck[0].create_user)
          const dateOnlyString = fornecedoCheck[0].create_time.split(' ')[0]
          const timeOnlyString = fornecedoCheck[0].create_time.split(' ')[1]
          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
        if (fornecedoCheck[0].update_user) {
          setUsuarioQueAlterou(fornecedoCheck[0].update_user)
          const dateOnlyString = fornecedoCheck[0].update_time.split(' ')[0]
          const timeOnlyString = fornecedoCheck[0].update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [
    logUser,
    visible,
    usuarioQueAlterou,
    dataFornecedores,
    usuarioQueCriou,
    update,
    horarioDeCriacao,
  ])



  return (
   <Container>
     <Drawer
      forceRender
      style={{ position: 'absolute' }}
      placement='right'
      width='100%'
      onClose={hancleDrawerClose}
      open={visible}
      closeIcon={false}
      getContainer={false}
      footer={
        <div
          style={{
            display: 'flex',
            justifyContent: 'space-between',
            alignItems:'center',
          }}
        >
          <div>
            <Button onClick={handelClickSubmit} type='primary' htmlType='submit'>
              {update ? nameBtn : 'Salvar'}
            </Button>
            {'   '}
            <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>

          <Space className='log-user'>
            <small style={{ opacity: '0.5' }}>
              {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                <>
                  <DiasDesdeData
                    dataCriacao={dataAlteracao}
                    onDataFromCreation={handleDataFromCreation}
                  />
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    atualizado por: {usuarioQueAlterou}{' '}
                    {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                      ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                      : `às ${horarioDeAtualizacao}h`}
                  </small>
                </>
              ) : !logUser && update > 0 ? (
                <>
                  {' '}
                  {usuarioQueCriou === '' ? null : (
                    <small style={{ opacity: '0.5', fontSize: '10px' }}>
                      criado por: {usuarioQueCriou}{' '}
                      {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                        ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                        : `às ${horarioDeCriacao}h`}
                    </small>
                  )}
                </>
              ) : null}
            </small>
          </Space>
        </div>
      }
    >
      <Form
        form={form}
        layout='vertical'
        onFinish={onFinish}
        style={{
          pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
        }}
      >
        {/* Hiddenn */}

        <Form.Item hidden initialValue='1' name='status'>
          <Input />
        </Form.Item>
        <Form.Item initialValue='1' hidden name='cod_categoria' />
        <Tabs
          className='ant-tab-green'
          activeKey={tabsSelect}
          onChange={handelChangeTabs}
          size='large'
          type='card'
        >
          <TabPane
            forceRender
            key={1}
            tab={update > 0 ? 'Atualizar Fornecedor' : 'Cadastrar Fornecedor'}
          >
            <Row gutter={[8, 0]}>
              <Col span={23} flex='1 1 100vw'>
                <Card size='small' title='Tipo de Fornecedor'>
                  <Form.Item
                    rules={[
                      {
                        required: true,
                        message: 'Selecione o Tipo de Cadastro',
                      },
                    ]}
                    required
                    initialValue='J'
                    name='tipo'
                  >
                    <Radio.Group
                      buttonStyle='outline'
                      onChange={handleChangeTipo}
                      value={radioTipo}
                    >
                      <Radio name='tipo' value='F'>
                        Pessoa Fisica
                      </Radio>

                      <Radio name='tipo' value='J'>
                        Pessoa Jurídica
                      </Radio>
                    </Radio.Group>
                  </Form.Item>
                </Card>
              </Col>
              <Col span={23}>
                <Row gutter={[8, 0]}>
                  <Col hidden={!!(radioTipo === 'G' || radioTipo === 'F')} flex='1 1 300px'>
                    <Form.Item
                      rules={[
                        {
                          message: 'Preencha a Razão social',
                        },
                      ]}
                      label='Razão Social'
                      name='razao_social'
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item
                      name='nome'
                      label={radioTipo === 'J' ? toogleTipo.labelJ : toogleTipo.labelF}
                    >
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col hidden={!!(radioTipo === 'G')} flex='1 1 300px'>
                    <Form.Item label={toogleTipo.label} name='cpf_cnpj'>
                      <InputMask mask={toogleTipo.mask}>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col hidden={!!(radioTipo === 'G' || radioTipo === 'F')} flex='1 1 300px'>
                    <Form.Item label='IE' name='inscricao_estadual'>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 0]}>
                  <Col flex='1 1 300px'>
                    <Form.Item label='Celular' name='fone'>
                      <InputMask mask='(99)99999-9999'>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item label='Fone Fixo' name='fone2'>
                      <InputMask mask='(99)9999-9999'>
                        {/* @ts-ignore */}
                        {(inputProps: InputChangeEventHandler) => <Input {...inputProps} />}
                      </InputMask>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item name='email' label='Email'>
                      <Input type='email' />
                    </Form.Item>
                  </Col>
                </Row>
                <Row gutter={[8, 0]}>
                  <Col flex='1 1 50px'>
                    <Form.Item label='UF' name='cidade_cod_uf'>
                      <Select
                        showSearch
                        onChange={haldleChangeCidadeUf}
                        filterOption={(input, option) =>
                          option.children
                            // @ts-ignore
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsUF}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Cidade' name='cod_cidade'>
                      <Select
                        disabled={inputDisableCidade}
                        onChange={handleChangeCity}
                        value={valueInputCity}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            // @ts-ignore
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {optionsCity}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item name='cep' label='Cep'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item name='endereco' label='Endereço'>
                      <Input />
                    </Form.Item>
                  </Col>

                  <Col flex='1 1 300px'>
                    <Form.Item name='endereco_numero' label='Numero'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item name='bairro' label='Bairro'>
                      <Input />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 300px'>
                    <Form.Item name='complemento' label='Complemento'>
                      <Input />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Row>
          </TabPane>
          <TabPane forceRender disabled={update <= 0} key={2} tab='Serviços'>
            <Card size='small' title={`Fornecedor: ${cardService}`}>
              <Col span={22}>
                <Row gutter={[8, 0]}>
                  <Col flex='1 1 100px'>
                    <Form.Item hidden name='cod_fornecedor'>
                      <Input />
                    </Form.Item>

                    <Form.Item label='Data Vigência' name='data'>
                      <Input type='date' />
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 200px'>
                    <Form.Item label='Tipo Serviço' name='cod_servico'>
                      <Select placeholder='Selecione o Tipo de Serviço' style={{ width: '100%' }}>
                        {optionsServicos}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 100px'>
                    <Form.Item label='Valor' name='valor'>
                      <Input onChange={handleChangeMask} />
                    </Form.Item>
                  </Col>
                </Row>
              </Col>
            </Card>

            <Col span={24}>
              <Container>
                <Table
                  rowKey='cod'
                  loading={loadingTable}
                  size='small'
                  bordered
                  columns={colums}
                  dataSource={dataFornecedorServi}
                  onRow={(record, i) => {
                    return {
                      onDoubleClick: () => {
                        setRowSelected(i)
                        handleClickFornServ(record.cod)
                      },
                      style: {
                        backgroundColor: rowSelected === i ? '#d2f8c8' : '',
                      },
                    }
                  }}
                />
              </Container>
            </Col>
          </TabPane>
        </Tabs>
      </Form>
    </Drawer>
   </Container>
  )
}

export default Laboratorio
