

/**adiona `|` para separar o nome da profundidade */
export function replaceCalcario(nome_calcario: string): string {
    switch (nome_calcario) {
        case 'Calcário 00-20':
            return 'Calcário | 00-20'

        case 'Calcário | 00-20':
            return 'Calcário 00-20'

        case 'Calcário 00-30':
            return 'Calcário | 00-30'

        case 'Calcário 00-40':
            return 'Calcário | 00-40'

        default:
            return nome_calcario
    }
}

/**verifica se o elemento se chama `Calcário 20-40` em diferentes variações */
export function removerCalcario2040(dataElemento: any): boolean {
    if (dataElemento.cod_elemento !== 30) {
        return false;
    }
    const descricoes = new Set([
        'Calcário | 20-40',
        'Calcario | 20-40',
        'calcário | 20-40',
        'calcario | 20-40',
        'Calcário - 20-40',
        'Calcario - 20-40',
        'calcário - 20-40',
        'calcario - 20-40',
        'Calcário - 20 - 40',
        'Calcario - 20 - 40',
        'calcário - 20 - 40',
        'calcario - 20 - 40',
        'Calcário 20-40',
        'Calcario 20-40',
        'calcário 20-40',
        'calcario 20-40',
        'Calcário | 20 a 40',
        'Calcario | 20 a 40',
        'calcário | 20 a 40',
        'calcario | 20 a 40',
        'Calcário - 20 a 40',
        'Calcario - 20 a 40',
        'calcário - 20 a 40',
        'calcario - 20 a 40',
        'Calcário 20 a 40',
        'Calcario 20 a 40',
        'calcário 20 a 40',
        'calcario 20 a 40',
        'Calcário 20 á 40',
        'Calcario 20 á 40',
        'calcário 20 á 40',
        'calcario 20 á 40',
        'Calcário 20 à 40',
        'Calcario 20 à 40',
        'calcário 20 à 40',
        'calcario 20 à 40',
    ]);

    return descricoes.has(dataElemento?.elemento_descricao);
}
