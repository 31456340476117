import { message } from 'antd'
import { api } from '../api'

interface TypeQuery {
  embed?: string
  page?: number | 1
  pagination?: number | -1
}

export const getBiotecnologia = async (params: TypeQuery, array) => {
  api.defaults.headers.Acesso = 'admin'
 
  

  const response = await api.get(`/recomendacao/tecnologia?codCultura=${array === null ? '' : array}`, {
    params
  })

  return response
}

export const postBiotecnologia = async (data, setBioNome, setDescricaoBio, setCulturasSelects, setNomeResumido) => {
   api.defaults.headers.Acesso = 'admin'

  const { nome, descricao, culturas, sigla } = data
  const response = api
    .post(`/recomendacao/tecnologia`, {
      nome: nome,
      sigla: sigla,
      descricao: descricao,
      culturas: culturas,
      
    })

    .then((): any => {
      message.success({ content: 'Biotecnologia cadastrado com sucesso!', key: 'loading' })
      setBioNome('')
      setDescricaoBio('')
      setNomeResumido('')
      setCulturasSelects([])
    })
    .catch(() => {
      message.error({ content: 'Falha ao cadastrar biotecnologia', key: 'loading' })
    })

  return response
}

export const putBiotecnologia = async (data, cod, setBioNome, setNomeResumido, setDescricaoBio, setCulturasSelects ,onClose) => {
  api.defaults.headers.Acesso = 'admin'
  
 const { nome, sigla, descricao, culturas, culturas_removers } = data
 const response = api
   .put(`/recomendacao/tecnologia/${cod}`, {
     nome: nome,
     sigla: sigla,
     descricao: descricao,
     culturas: culturas,
     culturas_remover: culturas_removers,
   })

   .then((): any => {
     message.success({ content: 'Biotecnologia atualizada com sucesso!', key: 'loading' })
     setBioNome('')
     setNomeResumido('')
     setDescricaoBio('')
     setCulturasSelects([])
     onClose(false)
   })
   .catch(() => {
     message.error({ content: 'Falha ao atualizar biotecnologia', key: 'loading' })
   })

 return response
}

export const delBioTecnologia = async (cod) => {
  const response = api.delete(`/recomendacao/tecnologia/${cod}`)

  .then((): any => {
    message.success({ content: 'Biotecnologia excluída com sucesso', key: 'loading' })

  })
  .catch(() => {
    message.error({ content: 'Falha ao excluir biotecnologia ', key: 'loading' })
  })

  return response
}
