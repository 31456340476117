import styled from "styled-components";
import { Pagination } from "../../pagination";

//STYLES
const Tbody = styled.div`
    width: 100%;
    min-height: 40px;
    border-left: thin solid rgba(0,0,0,0.06);
    border-right: thin solid rgba(0,0,0,0.06);
    overflow-y: auto;
`;

//PROPS
interface Props {
    children: React.ReactNode;
    style?: React.CSSProperties;
    paginate?: number;
    itensPage?: number;
    onChangeItensPage?: Function;
    onChangePage?: Function;
    current_page?: number;
    setCurrent_page?: React.Dispatch<React.SetStateAction<number>>;
}

export const BodyTable: React.FC<Props> = ({ children, style, paginate, onChangeItensPage, onChangePage, current_page, setCurrent_page }) => {
    return (
        <>
        <Tbody style={style}>
            { children }
        </Tbody>
        {!!paginate &&(
            <Pagination 
                paginate={paginate}
                onChangeItensPage={onChangeItensPage}
                onChangePage={onChangePage}
                current_page={current_page}
                setCurrent_page={setCurrent_page}
            />
        )}
        </>
    )
}