import { DownOutlined } from "@ant-design/icons";
import { Checkbox, Dropdown } from "antd";
import styled from "styled-components";

//STYLES
const Header = styled.div`
    display: flex;
    align-items: center;
    gap: 5px;
    font-weight: 600;
    font-size: 14px;

    div {
        display: flex;
        align-items: center;
        gap: 5px;
    }
`;

//PROPS
interface Props {
    onChangeCheckBox: Function;
    isIndeterminate: boolean;
    isChecked: boolean;
    overlayDropDown: JSX.Element;
    title: string;
    fontSize?: number;
}

export const HeaderColuna: React.FC<Props> = ({ onChangeCheckBox, isIndeterminate, isChecked, fontSize, title }) => {
    return (
        <Header style={{fontSize: fontSize}}>
            <div>
                <Checkbox
                    onChange={() => onChangeCheckBox()}
                    className={`select-all ${isIndeterminate ? 'ant-checkbox-indeterminate' : '' }`}
                    // className={`select-all ${isIndeterminate ? 'ant-checkbox-indeterminate' : '' } ${isChecked ? 'ant-checkbox-checked' : '' }`}
                    checked={isChecked}
                />
                {/* <Dropdown
                    overlay={overlayDropDown}
                    placement='bottomLeft'
                >
                <span style={{ fontSize: '.8em' }}>
                    <DownOutlined />
                </span>
                </Dropdown> */}

            </div>

            {title}
        
        </Header>
    )
}