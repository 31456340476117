
//PROPS
interface Props {
    /**Condição para renderizar o componente
     * 
     * Se `true` renderiza/monta o componente
     */
    condition: boolean;
    /**componente filho dentro da tag `<Render>` */
    children: React.ReactNode;
}

export const Render: React.FC<Props> = ({ condition, children }) => {
    if (condition) {
        return (
            <>{children}</>
        )
    }
    return null
}