import styled from 'styled-components'

export const Container = styled.div`
  .btn-trasfile {
    transition: 0.3s;
    color: green;
    a {
      color: green;
    }
    a:hover {
      color: red;
    }
  }

  position: absolute;
  margin: 10px;
  border-radius: 3px;
  height: auto;
  background: white;
  transition: 0.2s;

  .btn-hidden:hover {
    border-color: transparent;

    svg {
      color: #000000;
    }
  }

  li.ant-list-item {
    transition: 0.2s;
    border-radius: 3px;
    padding-left: 3px;
  }

  .progress {
    padding-left: 8px;
  }
`
