import { api } from '../api'

interface TypeQuery {
  nome?: string
  cod?: number
  codGrupo?: number
  pagination?: number | -1
  page?: number | 1
}

const url = '/cultura'
const urlMedida = '/tipos-medida'

export const getTiposMedidas = async (params?: TypeQuery) => {
  const response = api.get(urlMedida, {
    params
  })

  return response
}

export const postCadastrarMedidas = async (data) => {
  const response = api.post(urlMedida, data)

  return response
}

export const putCadastrarMedidas = async (cod, data) => {
  const response = api.put(`${urlMedida}/${cod}`, data)

  return response
}

export const delCadastrarMedidas = async (cod) => {
  const response = api.delete(`${urlMedida}/${cod}`)

  return response
}

export const getCulturas = async (params: TypeQuery) => {
  
  const response = api.get(url, {
    params,
  })

  return response
}

export const postCadastrarCultura = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putCadastrarCultura = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delCadastrarCultura = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
