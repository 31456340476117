import React from 'react'

import Icon from '@ant-design/icons'

import {
  IconConfirm,
  IconError,
  IconInfo,
  IconSuccess,
  IconUser,
  IconWarning,
} from '../../assets/svg'

interface TypesAlerts {
  type: 'success' | 'info' | 'warning' | 'confirm' | 'error' | 'user'
  // eslint-disable-next-line react/require-default-props
  size?: number
}

export const IconAlert: React.FC<TypesAlerts> = (props) => {
  const { type, size = 42 } = props
  let tipo
  switch (type) {
    case 'success':
      tipo = <IconSuccess size={size} />
      break
    case 'info':
      tipo = <IconInfo size={size} />
      break
    case 'warning':
      tipo = <IconWarning size={size} />
      break
    case 'confirm':
      tipo = <IconConfirm size={size} />
      break
    case 'error':
      tipo = <IconError size={size} />
      break
    case 'user':
      tipo = <IconUser size={size} />
      break
    default:
      tipo = null
      break
  }

  return <Icon component={() => tipo} />
}
