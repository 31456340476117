import React, { useEffect, useRef, useState } from 'react'

import { Button, Modal, Space, Switch, Table, Tag } from 'antd'

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { delPatrimonio, getPatrimonio, pathPatrimonio } from '../../../services/Patrimonio'

import { IconAlert } from '../../../Components/iconsAlert'

import FormPatrimonio from '../../../Components/patrimonio'

import { Notification } from '../../../Components/notification'

import { pagination } from '../../../Components/Default'
import ViewPage from '../../../Components/ViewPage'
import { filterTable } from '../../../services/Afins'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import { Container } from './styled'

const Patrimonio: React.FC = () => {
  const statusRef = useRef(null)

  const [permissions, setPermissions] = useState<ItensPerUserLogged>()

  const [loaddingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisisbleForm] = useState(false)

  const [uppdatePatrimonio, setUpdatePatrimonio] = useState(0)

  const [dataTable, setLDataTable] = useState([])
  const [dataTableRelative, setLDataTableRelative] = useState([])

  const listPatrimonio = async () => {
    setLoadingTable(true)
    const response = await getPatrimonio({ pagination: -1 })
    const dados = response.data.itens

    dados.forEach((data) => {
      data.loading = false
    })

    setLDataTable(dados)
    setLDataTableRelative(dados)


    setLoadingTable(false)
  }

  const handleVisibleForm = () => {
    setVisisbleForm(true)
    setUpdatePatrimonio(0)
  }

  const handleEditPatrimonio = (data) => {
    setUpdatePatrimonio(data)
    setVisisbleForm(true)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataTable
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setLDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleTrashPatrimonio = async (data, nome) => {
    try {
      Modal.confirm({
        title: 'Excluir',
        icon: <IconAlert type='confirm' size={6} />,
        content: `Deseja realmente remover a Patrimonio ${nome}?`,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delPatrimonio(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Patrimonio Excluido',
              type: 'success',
            })

            listPatrimonio()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  const handleAlteraStatus = async (status, dados) => {
    const dataTable = JSON.parse(JSON.stringify(dataTableRelative))

    const bodyFormData = new FormData()

    bodyFormData.append('status', status ? '1' : '0')
    for (let i = 0; i < dataTable.length; i += 1) {
      const data = dataTable[i]

      if (data.cod === dados.cod) {
        dataTable[i].loading = true
        setLDataTableRelative(dataTable)

        // eslint-disable-next-line no-await-in-loop
        const response = await pathPatrimonio(dados.cod, {
          status: status ? 1 : 0,
        })
        if (response.status === 200) {
          dataTable[i].loading = false
          dataTable[i].status = status ? 1 : 0
          const fim = JSON.parse(JSON.stringify(dataTable))
          setLDataTableRelative(fim)
        }
      }
    }
  }

  useEffect(() => {
    listPatrimonio()
  }, [])


  

  return (
    <Container>
      <ViewPage
        title='Patrimônio'
        onPermission={setPermissions}
        rotina={27}
        btnClick={handleVisibleForm}
        search
        inputChange={handlesPesquisa}
        content={
          <FormPatrimonio
          dataPatrimonio={dataTable}
            permissions={permissions}
            onShow={visibleForm}
            onClose={setVisisbleForm}
            update={uppdatePatrimonio}
            setUpdate={setUpdatePatrimonio}
            refresh={listPatrimonio}
          />
        }
      >
        <Table
          size='small'
          scroll={{ y: 'calc(90vh - 275px)', x: 'auto' }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTable.length}</b>
            </div>
          )}
          loading={loaddingTable}
          dataSource={dataTableRelative}
          bordered
          pagination={pagination}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleEditPatrimonio(record.cod)
              },
            }
          }}
          columns={[
            {
              key: 1,
              dataIndex: 'status',
              title: 'Ativo',
              width: '85px',
              render: (data, dados, i) => {
                return (
                  <Switch
                    ref={statusRef}
                    style={{
                      pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                    }}
                    checked={dataTableRelative[i].status === 1}
                    onChange={(checked) => {
                      handleAlteraStatus(checked, dados)
                    }}
                    checkedChildren='Sim'
                    unCheckedChildren='Não'
                    loading={dataTableRelative[i].loading}
                  />
                )
              },
            },
            {
              key: 2,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 3,
              dataIndex: 'modelo',
              title: 'Modelo',
              ...filterTable(true, true, 'modelo', dataTableRelative),
            },
            {
              key: 4,
              dataIndex: 'tipo_nome',
              title: 'Categorias',
              render: (data: any[]) => {
                return <Tag>{data}</Tag>
              },
              ...filterTable(true, true, 'tipo_nome', dataTableRelative),
            },
            {
              key: 5,
              dataIndex: 'cod',
              width: 90,
              align: 'center',
              title: 'Ações',
              render: (data, dados) => (
                <Space>
                  <Button
                    shape='circle'
                    hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                    onClick={() => handleEditPatrimonio(data)}
                    icon={<FaIcon icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
                    size='middle'
                  />
                  <Button
                    shape='circle'
                    onClick={() => handleTrashPatrimonio(data, dados.nome)}
                    className='trash-button'
                    hidden={permissions?.exclusao === 0}
                    type='primary'
                    icon={<FaIcon icon={solid('trash')} />}
                    size='middle'
                  />
                </Space>
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default Patrimonio
