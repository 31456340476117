import React, { useEffect, useState } from 'react'

import { Button, Drawer, Input, message, Modal, Pagination, Table, Tag, Upload } from 'antd'

import { UploadOutlined } from '@ant-design/icons'

import dayjs from 'dayjs'
import { UploadChangeParam } from 'antd/lib/upload'

import parse from 'html-react-parser'
import { Notification } from '../../../Components/notification'

import Exclamation from '../../../assets/img/exclamation.png'
import ExclamationError from '../../../assets/img/exclamation-error.png'

import { getResultLab, putResultLab } from '../../../services/resultadoLab'

import { CampoBuscar, Container } from './styled'
import base64 from '../../../Components/Base64Geral'
import { fileTypeText } from '../../../services/Afins'
import ViewPage from '../../../Components/ViewPage'
import { faSearch } from '@fortawesome/free-solid-svg-icons'
import Icons from '../../../Components/Icons'
import { useAccess } from '../../../context/useAccess'

const Laboratorio: React.FC = () => {
  const [loaddingTable, setLoadingTable] = useState(false)

  const [dataTable, setLDataTable] = useState([])
  const [dataTableRelative, setLDataTableRelative] = useState([])
  const [dataTableAnalise, setDataTableAnalise] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [total, setTotal] = useState(0)
  const [pageSize, setPageSize] = useState(10)

  const { setNomeLab } = useAccess()

  const [fileEnvia, setFileEnvia] = useState({
    cod: null,
    file: null,
  })

  const [colum, setColum] = useState(null)

  const [drawerFilePrevia, setDrawerFilePrevia] = useState(false)

  const listAnalises = async (data, pageSize) => {
    setLoadingTable(true)
    const response = await getResultLab({ page: data, pagination: pageSize })
    setNomeLab(response.data.itens[0].laboratorio_nome)

    setTotal(response.data.total)
    const dados = response.data.itens
    const sortedData = dados.sort(
      (a, b) => new Date(b.data_coleta).getTime() - new Date(a.data_coleta).getTime()
    )

    setLDataTableRelative([...sortedData])
    setLDataTable(sortedData)
    setLoadingTable(false)
  }

  // async function teste(data) {
  //   const response = await getResultLab({ page: data, pagination: 15})
  //   const sortedData = response.data.itens.sort(
  //     (a, b) => new Date(b.data_coleta).getTime() - new Date(a.data_coleta).getTime()
  //   )
  //   setLDataTableRelative(sortedData)
  // }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataTable
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter(
        (info) => info.propriedade_nome.toLowerCase().indexOf(nome) !== -1
      )
      setLDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const CSVToJSON = async (csv) => {
    const lines = csv.split('\r')
    const keys = lines[0].split(';')
    let colum = []
    Object.entries(keys).forEach(([key, value]) => {
      let render
      if (value === 'Propriedade') {
        render = (data) => (
          <p
            title={data}
            style={{
              maxWidth: '15ch',
              overflow: 'hidden',
              textOverflow: 'ellipsis',
              whiteSpace: 'nowrap',
            }}
          >
            {data}
          </p>
        )
      } else {
        render = null
      }
      const temp = {
        key,
        dataIndex: value,
        title: value.toString(),
        width: '100%',
        render,
      }

      colum = [...colum, temp]

      return colum
    })
    setColum(colum)
    return lines.slice(1).map((line) => {
      return line.split(';').reduce((acc, cur, i) => {
        const toAdd = {}
        toAdd[keys[i]] = cur
        return { ...acc, ...toAdd }
      }, {})
    })
  }

  const hendlaUplodaResultado = async (info: UploadChangeParam, cod) => {
    if (info.file.status === 'done') {
      message.loading('Carregando...', 99999)
      const codificado = await base64(info.file.originFileObj)

      const fileText = await fileTypeText(info.file.originFileObj)

      const retorno = await CSVToJSON(fileText)

      setDataTableAnalise(retorno)

      setFileEnvia({ cod, file: codificado })

      setDrawerFilePrevia(true)
      message.destroy()
    }
  }

  const handleClikAlert = async (osCod) => {
    const tipoModal = osCod.status === 8 ? Modal.error : Modal.warning
    tipoModal({
      title:
        osCod.status !== 8
          ? `Resultado com inconsistência na OS ${osCod.cod}`
          : `Erro na OS ${osCod.cod}`,
      icon: (
        <span className='anticon anticon-close-circle'>
          <img
            width='25px'
            src={osCod.status === 8 ? ExclamationError : Exclamation}
            alt='Warning'
          />
        </span>
      ),
      content: <div>{parse(osCod.observacao)}</div>,
      width: '800px',
    })
  }

  const handleEnviarFile = async () => {
    const dados = {
      arquivo: fileEnvia.file,
    }
    try {
      message.loading('Enviando...', 99999)
      const response = await putResultLab(fileEnvia.cod, dados)

      if (response.status === 200) {
        message.destroy()
        Notification({
          message: 'Resultado enviado',
          type: 'success',
        })
        listAnalises(1, 10)
        setDrawerFilePrevia(false)
      }

      message.destroy()
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const handleTableChange = (pagination) => {
    setCurrentPage(pagination.current)
    setPageSize(pagination.pageSize)
  }

  useEffect(() => {
    listAnalises(currentPage, pageSize)
  }, [currentPage, pageSize])

  useEffect(() => {
    listAnalises(1, 10)
  }, [])

  return (
    <Container>
      <ViewPage
        title='Laboratório'
        btnClick={() => null}
        newHide
        content={
          <Drawer
            title='Visualizar Arquivo'
            height='89%'
            placement='bottom'
            open={drawerFilePrevia}
            closeIcon={false}
            onClose={() => setDrawerFilePrevia(false)}
            footer={
              <>
                <Button onClick={handleEnviarFile} type='primary' htmlType='submit'>
                  Enviar
                </Button>
                {'   '}
                <Button
                  danger
                  type='primary'
                  onClick={() => setDrawerFilePrevia(false)}
                  style={{ marginRight: 8 }}
                >
                  Fechar
                </Button>
              </>
            }
          >
            <Table
              className='head-visu'
              rowClassName={(record, index) =>
                index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
              }
              scroll={{ x: '5000px', y: '60vh' }}
              columns={colum}
              dataSource={dataTableAnalise}
            />
          </Drawer>
        }
        search
        inputChange={handlesPesquisa}
      >
        <CampoBuscar>
          <input className='buscar' placeholder='Pesquisar' onChange={handlesPesquisa} />
          <Icons style={{ width: '10%' }} icon={faSearch}></Icons>
        </CampoBuscar>{' '}
        <Table
          loading={loaddingTable}
          dataSource={dataTableRelative}
          scroll={{ y: 'calc(90vh - 275px)' }}
          size='small'
          pagination={{
            current: currentPage,
            total: total,
            pageSize: pageSize,
            showSizeChanger: true,
            pageSizeOptions: ['10', '20', '50', '100'],
          }}
          onChange={handleTableChange}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTable.length}</b>
            </div>
          )}
          columns={[
            {
              key: 0,
              dataIndex: 'cod',
              width: '100px',
              title: 'Codigo',
              // @ts-ignore
              hidden: process.env.NODE_ENV === 'production',
            },
            {
              key: 1,
              dataIndex: 'data_coleta',
              title: 'Data da coleta',
              width: '130px',
              render: (data_coleta) => <>{dayjs(data_coleta).format('DD/MM/YYYY')}</>,
            },
            {
              key: 3,
              dataIndex: 'propriedade_nome',
              title: 'Propriedade',
            },

            {
              key: 2,
              dataIndex: 'talhao_nome',
              title: 'Talhão',
            },
            {
              key: 4,
              dataIndex: 'status',
              title: 'Status   ',
              render: (data, dados) => {
                let status
                let corTag
                switch (data) {
                  case 1:
                    status = 'ABERTA'
                    corTag = 'cyan'
                    break
                  case 2:
                    status = 'LIBERADO PARA COLETA'
                    corTag = 'gold'
                    break
                  case 3:
                    status = 'COLETADA'
                    corTag = 'geekblue'
                    break
                  case 4:
                    status = 'ENVIADO PARA ANÁLISE'
                    corTag = 'purple'
                    break
                  case 5:
                    status = 'EM ANÁLISE'
                    corTag = 'processing'
                    break
                  case 6:
                    status = 'INCONSISTÊNCIA MODERADA'
                    corTag = 'warning'
                    break
                  case 7:
                    status = 'INCONSISTÊNCIA ALTA'
                    corTag = 'volcano'
                    break
                  case 8:
                    status = 'RESULTADO COM ERRO'
                    corTag = 'error'
                    break
                  case 9:
                    status = 'ANALISADA'
                    corTag = 'green'
                    break

                  default:
                }
                return (
                  <>
                    {data === 6 || data === 7 || data === 8 ? (
                      <Tag
                        onClick={() => handleClikAlert(dados)}
                        style={{ cursor: 'pointer', border: 'none' }}
                      >
                        <img
                          width='19px'
                          src={data === 8 ? ExclamationError : Exclamation}
                          alt='Warning'
                        />
                      </Tag>
                    ) : null}
                    {'   '}
                    <Tag color={corTag}>{status}</Tag>
                  </>
                )
              },
            },
            {
              key: 5,
              dataIndex: 'cod',
              title: 'Resultado',
              width: '200px',
              render: (cod, dados) => (
                <>
                  {'   '}
                  <Upload
                    accept='.csv'
                    maxCount={1}
                    multiple={false}
                    showUploadList={false}
                    onChange={(data) => hendlaUplodaResultado(data, cod)}
                    customRequest={dummyRequest}
                  >
                    <Button
                      disabled={dados.status === 5 || dados.status === 9}
                      type='primary'
                      icon={<UploadOutlined />}
                      size='middle'
                    >
                      Enviar Resultado
                    </Button>
                  </Upload>
                </>
              ),
            },
          ].filter((item) => !item.hidden)}
        />
      </ViewPage>
    </Container>
  )
}

export default Laboratorio
