
/**retorna o index do array
 * 
 * usado para evitar alerta do sonarqube
 */
export function gerarKey(index: number) {
    return index;
}

/**verifica se botão está desabilitado (todos ou individualmente) */
export function isDisabled(desabilitarTodos: boolean, desabilitarIndividual?: boolean): boolean {
    return desabilitarTodos ? true : Boolean(desabilitarIndividual)
}