import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect, useRef } from 'react';
import { Button, Drawer, Empty, Popconfirm } from 'antd';
import { faArrowRightLong, faBolt, faChartArea, faVial, faXmark } from '@fortawesome/free-solid-svg-icons';

//APP
import { obterTabsPermitidas } from './meta';
import { useGetEstadiosByCultura, useGetRecomendacaoClasseAgronomica, useGetRecomendacaoCultura, useGetRecomendacaoFormulacao, useGetRecomendacaoGrupo, useGetRecomendacaoGrupoQuimico, 
    useGetRecomendacaoIngredientesAtivos, useGetRecomendacaoTecnologia, useGetRecomendacaoTipoInsumos } from '../../../../../../services/recomendacao/ativos/novo-index';

//STYLES
import { BodyDrawer, NavegacaoDrawer } from './styled';

//COMPONENTS
import Tabs from '../../../../../../Components/Tabs';
import ButtonCustom from '../../../../../../Components/ButtonCustom';
import { Doses } from './components/doses';
import { Select } from '../../../../../../Components/CustomUi/Select';
import { Inline } from '../../../../../../Components/CustomUi/inline';
import { Switcher } from '../../../../../../Components/CustomUi/Switcher';
import { Plantback } from './components/plantback';
import { Observacoes } from './components/observacoes';
import { SelectMulti } from '../../../../../../Components/CustomUi/SelectMulti';
import { ErrorDisplay } from '../../../../../../Components/CustomUi/error';
import { HeaderDrawer } from '../../../../../../Components/CustomUi/headerGaveta';
import { Compatibilidades } from './components/compatibilidades';
import { Incompatibilidades } from './components/incompatibilidades';

//TYPES
export type ListaObs = {
    key: number,
    estadios: { descr: string, id: number, key: number }[],
    nivel?: number;
    obs: string
}
type Tipos = {
    descricao: string;
    cod_tipo: number;
};

//PROPS
interface Props {
    open: boolean;
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    atualizarListaPrincipal: Function;
    id_inicial: number | null;
    nome_inicial: string;
}

export const GavetaCadastrar: React.FC<Props> = ({ open, onClose, atualizarListaPrincipal, id_inicial, nome_inicial }) => {

    //CONST
    const tipos_principais: Tipos[] = [
        {descricao: 'Ativos', cod_tipo: 1},
        {descricao: 'Insumo (produto comercial)', cod_tipo: 2},
        {descricao: 'Grupo químico', cod_tipo: 3},
        {descricao: 'Classe agronômica', cod_tipo: 4},
        {descricao: 'Formulação', cod_tipo: 5},
        {descricao: 'Cultura', cod_tipo: 6},
        // {descricao: 'Estádio cultura', cod_tipo: 7},
        {descricao: 'Biotecnologia', cod_tipo: 8},
        {descricao: 'Grupo', cod_tipo: 9},
    ];

    //REFs
    const refEstadioPrincipal = useRef<any>(null);

    //STATES - GERAIS 
    //-> tipo principal
    const [nome_tipo_principal, setNome_tipo_principal] = useState<string>('');
    const [id_tipo_principal, setId_tipo_principal] = useState<number|null>(null);
    const [openTipoPrincipal, setOpenTipoPrincipal] = useState<boolean>(false);

    //-> item principal
    const [nome_item_principal, setNome_item_principal] = useState<string>('');
    const [id_item_principal, setId_item_principal] = useState<number|null>(null);
    const [openItemPrincipal, setOpenItemPrincipal] = useState<boolean>(false);
    const [is_herbicida, setIs_herbicida] = useState<number|null>(null);

    //->estádios da cultura principal
    // const [id_estadio_principal, setId_estadio_principal] = useState<number|null>(null);
    const [estadios_selecionados_principal, setEstadios_selecionados_principal] = useState<{descr:string,id:number}[]>([]);
    const [todos_estadios_principal, setTodos_estadios_principal] = useState<boolean>(false);


    const [tabSelecionada, setTabSelecionada] = useState<'Compatibilidade'|'Incompatibilidade'|'Observações'|'Plant back'|'Doses'>('Incompatibilidade');
    const [form_nao_salvo, setForm_nao_salvo] = useState<'Compatibilidade'|'Incompatibilidade'|'Observações'|'Plant back'|'Doses'>('Incompatibilidade');
    const [apiError, setApiError] = useState<boolean>(false);

    //STATES - GERAIS - boolean
    const [openDropdownTipo, setOpenDropdownTipo] = useState<boolean>(false);
    const [openDropdownInsumo, setOpenDropdownInsumo] = useState<boolean>(false);
    const [openDropdownEstadio, setOpenDropdownEstadio] = useState<boolean>(false);
    const [openDropdownEstadioPrincipal, setOpenDropdownEstadioPrincipal] = useState<boolean>(false);
    const [alerta_perder_dados, setAlerta_perder_dados] = useState<boolean>(false);

    //STATES - COMPATIBILIDADE 
        //-> booleans
        const [todos_estadios, setTodos_estadios] = useState<boolean>(false);
        //-> nomes
        const [nome_tipo_selecionado, setNome_tipo_selecionado] = useState<string>('');
        const [nome_insumo_selecionado, setNome_insumo_selecionado] = useState<string>('');
        const [estadios_selecionados, setEstadios_selecionados] = useState<{descr:string,id:number}[]>([]);
        //-> IDs
        const [id_tipo_selecionado, setId_tipo_selecionado] = useState<number|null>(null);
        const [id_insumo_selecionado, setId_insumo_selecionado] = useState<number|null>(null);
        //-> obs
        const [obs, setObs] = useState<string>('');
        const [lista_obs, setLista_obs] = useState<ListaObs[]>([]);

    //STATES - INCOMPATIBILIDADE 
        //-> booleans
        const [todos_estadios_inco, setTodos_estadios_inco] = useState<boolean>(false);
        //-> nomes
        const [nome_tipo_selecionado_inco, setNome_tipo_selecionado_inco] = useState<string>('');
        const [nome_insumo_selecionado_inco, setNome_insumo_selecionado_inco] = useState<string>('');
        const [estadios_selecionados_inco, setEstadios_selecionados_inco] = useState<{descr:string,id:number}[]>([]);
        //-> IDs
        const [id_nivel_de_incompatibilidade, setId_nivel_de_incompatibilidade] = useState<number>(3);
        const [id_tipo_selecionado_inco, setId_tipo_selecionado_inco] = useState<number|null>(null);
        const [id_insumo_selecionado_inco, setId_insumo_selecionado_inco] = useState<number|null>(null);
        //-> obs
        const [obs_inco, setObs_inco] = useState<string>('');
        const [lista_obs_inco, setLista_obs_inco] = useState<ListaObs[]>([]);

    //STATES - OBSERVAÇÕES
        //-> booleans
        const [openDropdownTipoObs, setOpenDropdownTipoObs] = useState<boolean>(false);
        const [openDropdownCulturas, setOpenDropdownCulturas] = useState<boolean>(false);
        const [openDropdownEstadio_obs, setOpenDropdownEstadio_obs] = useState<boolean>(false);
        const [todos_estadios_obs, setTodos_estadios_obs] = useState<boolean>(false);
        //-> nomes
        const [nome_cultura_obs, setNome_cultura_obs] = useState<string>('');
        const [nome_tipo_obs, setNome_tipo_obs] = useState<string>('');
        const [estadios_selecionados_obs, setEstadios_selecionados_obs] = useState<{descr:string,id:number}[]>([]);
        //-> IDs
        const [id_cultura_obs, setId_cultura_obs] = useState<number|null>(null);
        const [id_tipo_obs, setId_tipo_obs] = useState<number | null>(null);
        const [id_nivel_alerta, setId_nivel_alerta] = useState<number>(3);
        //-> obs
        const [obs_obs, setObs_obs] = useState<string>('');
        const [lista_obs_obs, setLista_obs_obs] = useState<ListaObs[]>([]);
    //STATES - PLANT BACK
        //-> booleans
        const [openDropdownPB, setOpenDropdownPB] = useState<boolean>(false);
        //-> nomes
        const [nome_culturaPB, setNome_culturaPB] = useState<string>('');
        const [tempo_ate_plantio, setTempo_ate_plantio] = useState<string>('');
        //-> IDs
        const [id_culturaPB, setId_culturaPB] = useState<number | null>(null);
        //-> obs
        const [obsPB, setObsPB] = useState<string>('');
     //STATES - DOSES
        //-> booleans
        const [openDropdownDoses, setOpenDropdownDoses] = useState<boolean>(false);
        const [openDropdownEstadiosDoses, setOpenDropdownEstadiosDoses] = useState<boolean>(false);
        const [todos_estadiosDoses, setTodos_estadiosDoses] = useState<boolean>(false);
        //-> nomes
        const [nome_culturaDoses, setNome_culturaDoses] = useState<string>('');
        const [dose_minima, setDose_minima] = useState<string>('');
        const [dose_maxima, setDose_maxima] = useState<string>('');
        const [estadios_selecionadosDoses, setEstadios_selecionadosDoses] = useState<{descr:string,id:number}[]>([]);
        //-> IDs
        const [id_culturaDoses, setId_culturaDoses] = useState<number | null>(null);

    //API
    const { data_insumos, refetch_insumos, isFetched_insumos, carregando_insumos } = useGetRecomendacaoTipoInsumos(setApiError);
    const { data_ingredi_ativos, refetch_data_ingredi_ativos, isFetched_ingredi_ativos, carregando_ingredi_ativos } = useGetRecomendacaoIngredientesAtivos(setApiError);
    const { data_grupo_quimico, refetch_grupo_quimico, isFetched_grupo_quimico, carregando_grupo_quimico } = useGetRecomendacaoGrupoQuimico(setApiError);
    const { data_classe_agro, refetch_classe_agro, isFetched_tclasse_agro, carregando_classe_agro } = useGetRecomendacaoClasseAgronomica(setApiError);
    const { data_formulacao, refetch_formulacao, isFetched_formulacao, carregando_formulacao } = useGetRecomendacaoFormulacao(setApiError);
    const { data_cultura, refetch_cultura, isFetched_cultura, carregando_cultura } = useGetRecomendacaoCultura(false, setApiError);
    const { data_grupo, refetch_grupo, isFetched_grupo, carregando_grupo } = useGetRecomendacaoGrupo(setApiError);
    const { data_tecnologia, refetch_tecnologia, isFetched_tecnologia, carregando_tecnologia } = useGetRecomendacaoTecnologia(setApiError);
    const { data_estadios, refetch_estadios } = useGetEstadiosByCultura(id_item_principal);

    useEffect(() => {
        const refetchData = (condition, refetchFunction) => {
            if (condition && open) {
                refetchFunction();
            }
        };
    
        refetchData(id_tipo_principal === 1 && !isFetched_ingredi_ativos, refetch_data_ingredi_ativos);
        refetchData(id_tipo_principal === 2 && !isFetched_insumos, refetch_insumos);
        refetchData(id_tipo_principal === 3 && !isFetched_grupo_quimico, refetch_grupo_quimico);
        refetchData(id_tipo_principal === 4 && !isFetched_tclasse_agro, refetch_classe_agro);
        refetchData(id_tipo_principal === 5 && !isFetched_formulacao, refetch_formulacao);
        refetchData(id_tipo_principal === 6 && !isFetched_cultura, refetch_cultura);
        refetchData(id_tipo_principal === 8 && !isFetched_tecnologia, refetch_tecnologia);
        refetchData(id_tipo_principal === 9 && !isFetched_grupo, refetch_grupo);
    }, [id_tipo_principal, open]);

    const validarSeCadastroFoiSalvo = () => {
        const campos_compatibilidade = [
            nome_tipo_selecionado,
            nome_insumo_selecionado,
            estadios_selecionados,
            obs
        ];
        const campos_doses = [
            nome_culturaDoses,
            dose_minima,
            dose_maxima,
            estadios_selecionadosDoses
        ];
        const campos_obs = [
            nome_tipo_obs,
            nome_cultura_obs,
            obs_obs,
            estadios_selecionados_obs
        ];
        const campos_plantback = [
            nome_culturaPB,
            tempo_ate_plantio,
            obsPB
        ];
        const campos_incompatibilidade = [
            nome_tipo_selecionado_inco,
            nome_insumo_selecionado_inco,
            estadios_selecionados_inco,
            obs_inco,
        ];
    
        const compatibilidadeNaoSalvas = campos_compatibilidade.some(condition => condition.length > 0);
        const dosesNaoSalvas = campos_doses.some(condition => condition.length > 0);
        const obsNaoSalvas = campos_obs.some(condition => condition.length > 0);
        const planbackNaoSalvas = campos_plantback.some(condition => condition.length > 0);
        const incompatibilidadeNaoSalvas = campos_incompatibilidade.some(condition => condition.length > 0);

        if (compatibilidadeNaoSalvas) {
            setAlerta_perder_dados(true);
            setForm_nao_salvo('Compatibilidade');
        } 
        else if (dosesNaoSalvas) {
            setAlerta_perder_dados(true);
            setForm_nao_salvo('Doses');
        } 
        else if (obsNaoSalvas) {
            setAlerta_perder_dados(true);
            setForm_nao_salvo('Observações');
        } 
        else if (planbackNaoSalvas) {
            setAlerta_perder_dados(true);
            setForm_nao_salvo('Plant back');
        } 
        else if (incompatibilidadeNaoSalvas) {
            setAlerta_perder_dados(true);
            setForm_nao_salvo('Incompatibilidade');
        } 
        else {
            setTabSelecionada('Incompatibilidade');
            onClose(false);
        }
    };

    function obterListaTipo() {

        switch (id_tipo_principal) {
            case 1: // ativos
                return data_ingredi_ativos?.itens ?? [];
            case 2: // insumos (produto comercial)
                return data_insumos?.itens ?? [];
            case 3: // grupo quimico
                return data_grupo_quimico?.itens ?? [];
            case 4: //classe agronomica
                return data_classe_agro?.itens ?? [];
            case 5: //formulação
                return data_formulacao?.itens ?? [];
            case 6: // cultura
                return data_cultura?.itens ?? [];
            case 8: // biotecnologia
                return data_tecnologia?.itens ?? [];
            case 9: // grupo
                return data_grupo?.itens ?? [];
            default:
                return [];
        }
    }    

    useEffect(() => {
        setNome_item_principal('');
        setId_item_principal(null);
    }, [id_tipo_principal])
    
    
    useEffect(() => {
        return () => {
            if (!open) {
                setNome_item_principal('');
                setId_item_principal(null);
                setEstadios_selecionados_principal([])
                setNome_tipo_selecionado('');
                setId_tipo_selecionado(null);
                setId_tipo_selecionado_inco(null);
                setNome_insumo_selecionado('');
                setId_insumo_selecionado(null);
                setEstadios_selecionados([]);
                setEstadios_selecionadosDoses([]);
                setEstadios_selecionados_obs([]);
                setLista_obs([]);
                setDose_maxima('');
                setDose_minima('');
                setNome_culturaDoses('');
                setId_culturaDoses(null);
                setNome_tipo_selecionado_inco('');
                setNome_insumo_selecionado_inco('');
                setEstadios_selecionados_inco([]);
                setObs_inco('');
                setLista_obs_inco([]);
            }
        }
    }, [open])

    
    useEffect(() => {
        if (id_tipo_principal === 6 && id_item_principal !== null) {
            refetch_estadios();
        }
    }, [id_item_principal]);

    useEffect(() => {
      setId_tipo_principal(id_inicial);
      setNome_tipo_principal(nome_inicial);
    }, [id_inicial, nome_inicial]);

    useEffect(() => {
        setEstadios_selecionados_principal([]);
    }, [id_item_principal, id_tipo_principal])

    useEffect(() => {
        if (estadios_selecionados_principal.length < (data_estadios?.itens ?? []).length) {
            setTodos_estadios_principal(false)
        }
    }, [estadios_selecionados_principal]);
    

    function refazerRequisicao() {
        switch (id_tipo_principal) {
            case 1: // ativos
                refetch_data_ingredi_ativos();
                break;
            case 2: // insumos (produto comercial)
                refetch_insumos();
                break;
            case 3: // grupo quimico
                refetch_grupo_quimico();
                break;
            case 4: //classe agronomica
                refetch_classe_agro();
                break;
            case 5: //formulação
                refetch_formulacao();
                break;
            case 6: // cultura
                refetch_cultura();
                break;
            case 8: // biotecnologia
                refetch_tecnologia();
                break;
            case 9: // grupo
                refetch_grupo();
                break;
            default:
                return null;
        }
    }

    function addTodosEstadiosManualmente() {
        if (todos_estadios_principal) {
            setEstadios_selecionados_principal([]);
            for (const item of (data_estadios?.itens ?? [])) {
                setEstadios_selecionados_principal(estadios_selecionados_principal=> [...estadios_selecionados_principal, {
                    descr: item?.nome,
                    id: item?.cod,
                    key: item?.cod 
                }])
            }
        }
    }

    useEffect(() => {
        addTodosEstadiosManualmente();
    }, [todos_estadios_principal]);
    
    return (
        <Drawer 
            width={865}
            destroyOnClose
            closable={false}
            onClose={()=>{validarSeCadastroFoiSalvo()}} open={open}
            bodyStyle={{ padding: 0 }}
            headerStyle={{padding: '10px 10px'}}
            title={
                <NavegacaoDrawer>
                    <Popconfirm
                        placement="topRight"
                        title={`Você não salvou o cadastro de ${form_nao_salvo}!`}
                        open={alerta_perder_dados}
                        description={
                        <div style={{display:'flex',flexDirection:'column',gap:15}}>
                            <span>Deseja fechar sem salvar? Todas as informações inseridas serão perdidas.</span>
                            <Inline justifyContent="flex-end">

                                <ButtonCustom 
                                    onClick={()=>{setAlerta_perder_dados(false);setTabSelecionada('Incompatibilidade');onClose(false)}}
                                    icon={<FontAwesomeIcon icon={faXmark} />}
                                    danger
                                    type='tertiary'
                                >
                                    Fechar
                                </ButtonCustom>

                                <ButtonCustom 
                                    onClick={()=>{setAlerta_perder_dados(false)}}
                                    // type="tertiary"
                                    icon={<FontAwesomeIcon icon={faArrowRightLong} />}
                                >
                                    Voltar para o cadastro
                                </ButtonCustom>

                            </Inline>
                        </div>}
                        okButtonProps={{style: {display:'none'}}}
                        cancelButtonProps={{style: {display:'none'}}}
                    >
                        <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                            <ButtonCustom
                                danger
                                icon={<FontAwesomeIcon icon={faXmark} />}
                                type='tertiary'
                                onClick={() => validarSeCadastroFoiSalvo()}
                                style={{ marginLeft: -10 }}
                            >
                                Fechar
                            </ButtonCustom>  
                        </Button>
                    </Popconfirm>
                </NavegacaoDrawer>
            } 
        >
            
            <HeaderDrawer>
                Cadastrar Incompatibilidade ({nome_tipo_principal})
            </HeaderDrawer>

            <BodyDrawer style={{borderBottom: 'thin solid var(--border-color)'}} >
                <Inline>
                    <Select 
                        label='Selecione o tipo'
                        value={nome_tipo_principal}
                        onChange={setNome_tipo_principal}
                        onChangeID={setId_tipo_principal}
                        placeholder='Selecione o tipo'
                        open={openTipoPrincipal}
                        trigger={setOpenTipoPrincipal}
                        maxHeight={250}
                        items={tipos_principais ?? []}
                        idItem={'cod_tipo'}
                        descricaoItem='descricao'
                        icon={faBolt}
                        loading={false}
                        atual={nome_tipo_principal}
                    />
                    <Select 
                        label={`Selecione o ${id_tipo_principal!==null ? nome_tipo_principal : 'item'}`}
                        value={nome_item_principal}
                        onChange={setNome_item_principal}
                        onChangeID={setId_item_principal}
                        placeholder={`Selecione o ${id_tipo_principal!==null ? nome_tipo_principal : 'item'}`}
                        open={openItemPrincipal}
                        trigger={setOpenItemPrincipal}
                        maxHeight={250}
                        items={obterListaTipo() ?? []}
                        idItem={'cod'}
                        descricaoItem='nome'
                        extraItem='cod_classe_agronomica'
                        icon={faVial}
                        loading={carregando_insumos || carregando_ingredi_ativos || carregando_grupo || carregando_grupo_quimico || carregando_classe_agro || carregando_formulacao || carregando_cultura || carregando_tecnologia}
                        atual={nome_item_principal}
                        disabled={nome_tipo_principal===''}
                        onChangeExtra={setIs_herbicida}
                        id='tipo_principal'
                    />
                </Inline>

                {id_tipo_principal===6 &&<>

                    <Switcher
                        label='Válido para todos os estádios'
                        isChecked={todos_estadios_principal}
                        trigger={setTodos_estadios_principal}
                        disabled={nome_item_principal==='' || id_item_principal===null}                    
                    />

                    <SelectMulti
                        label='Selecione os estádios'
                        selecteds={estadios_selecionados_principal}
                        onChange={setEstadios_selecionados_principal}
                        placeholder='Selecione os estádios da cultura'
                        open={openDropdownEstadioPrincipal}
                        trigger={setOpenDropdownEstadioPrincipal}
                        maxHeight={250}
                        items={data_estadios?.itens ?? []}
                        descricaoItem='nome'
                        idItem='cod'
                        icon={faChartArea}
                        // removeItens={estadios_ja_selecionados_principal}
                        disabled={nome_item_principal===''}
                        // disabledItens={desabilitarEstadiosSelecionados()}
                        refProp={refEstadioPrincipal}
                    />
                </>}
                            
            </BodyDrawer>

            {apiError &&(
                <ErrorDisplay 
                    loading={carregando_insumos || carregando_ingredi_ativos || carregando_grupo_quimico || carregando_grupo || carregando_classe_agro || carregando_formulacao || carregando_cultura || carregando_tecnologia}
                    tentarNovamente={()=>refazerRequisicao()}
                />
            )}
            
            {!apiError &&<>
                {id_item_principal!==null &&(
                    <BodyDrawer >
                        <Tabs
                            label='selecione o tipo:'
                            tabs={obterTabsPermitidas(is_herbicida===144, id_tipo_principal)}
                            selecionado={tabSelecionada}
                            onChange={setTabSelecionada}
                            repetir={()=>{}}
                            indexInicial={0}
                            disableAll={id_item_principal===null}
                            trackWidth={160}
                        />
                    </BodyDrawer>
                )}

                {id_item_principal===null &&(
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Nenhum tipo foi selecionado`} />
                )}

                {(tabSelecionada==='Compatibilidade'&&id_item_principal!==null) &&(
                    <Compatibilidades 
                        id_tipo_principal={id_tipo_principal}
                        id_item_principal={id_item_principal}
                        estadios_selecionados_principal={estadios_selecionados_principal}
    
                        id_ativo_selecionado={id_item_principal}
    
                        nome_tipo_selecionado={nome_tipo_selecionado}
                        setNome_tipo_selecionado={setNome_tipo_selecionado}
                        nome_insumo_selecionado={nome_insumo_selecionado}
                        setNome_insumo_selecionado={setNome_insumo_selecionado}
    
                        id_tipo_selecionado={id_tipo_selecionado}
                        setId_tipo_selecionado={setId_tipo_selecionado}
                        id_insumo_selecionado={id_insumo_selecionado}
                        setId_insumo_selecionado={setId_insumo_selecionado}
    
                        obs={obs}
                        setObs={setObs}
    
                        openDropdownTipo={openDropdownTipo}
                        setOpenDropdownTipo={setOpenDropdownTipo}
                        openDropdownInsumo={openDropdownInsumo}
                        setOpenDropdownInsumo={setOpenDropdownInsumo}
                        openDropdownEstadio={openDropdownEstadio}
                        setOpenDropdownEstadio={setOpenDropdownEstadio}
    
                        estadios_selecionados={estadios_selecionados}
                        setEstadios_selecionados={setEstadios_selecionados}
                        lista_obs={lista_obs}
                        setLista_obs={setLista_obs}
                        todos_estadios={todos_estadios} 
                        setTodos_estadios={setTodos_estadios}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                    />
                )}

                {(tabSelecionada==='Incompatibilidade'&&id_item_principal!==null) &&(
                    <Incompatibilidades
                        id_ativo_selecionado={id_item_principal}
                        id_tipo_principal={id_tipo_principal}
                        id_item_principal={id_item_principal}
                        nome_item_principal={nome_item_principal}
                        estadios_selecionados_principal={estadios_selecionados_principal}

                        nome_tipo_selecionado={nome_tipo_selecionado_inco}
                        setNome_tipo_selecionado={setNome_tipo_selecionado_inco}
                        nome_insumo_selecionado={nome_insumo_selecionado_inco}
                        setNome_insumo_selecionado={setNome_insumo_selecionado_inco}

                        id_tipo_selecionado={id_tipo_selecionado_inco}
                        setId_tipo_selecionado={setId_tipo_selecionado_inco}
                        id_insumo_selecionado={id_insumo_selecionado_inco}
                        setId_insumo_selecionado={setId_insumo_selecionado_inco}

                        obs={obs_inco}
                        setObs={setObs_inco}

                        openDropdownTipo={openDropdownTipo}
                        setOpenDropdownTipo={setOpenDropdownTipo}
                        openDropdownInsumo={openDropdownInsumo}
                        setOpenDropdownInsumo={setOpenDropdownInsumo}
                        openDropdownEstadio={openDropdownEstadio}
                        setOpenDropdownEstadio={setOpenDropdownEstadio}

                        estadios_selecionados={estadios_selecionados_inco}
                        setEstadios_selecionados={setEstadios_selecionados_inco}
                        lista_obs={lista_obs_inco}
                        setLista_obs={setLista_obs_inco}
                        todos_estadios={todos_estadios_inco} 
                        setTodos_estadios={setTodos_estadios_inco}
                        id_nivel_de_incompatibilidade={id_nivel_de_incompatibilidade}
                        setId_nivel_de_incompatibilidade={setId_nivel_de_incompatibilidade}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                    />
                )}

                {(tabSelecionada==='Observações'&&id_item_principal!==null) &&(
                    <Observacoes 
                        id_tipo_principal={id_tipo_principal}
                        id_item_principal={id_item_principal}
                        estadios_selecionados_principal={estadios_selecionados_principal}
                        openDropdownTipoObs={openDropdownTipoObs}
                        setOpenDropdownTipoObs={setOpenDropdownTipoObs}
                        nome_tipo_obs={nome_tipo_obs}
                        setNome_tipo_obs={setNome_tipo_obs}
                        id_tipo_obs={id_tipo_obs}
                        setId_tipo_obs={setId_tipo_obs}
                        openDropdownCulturas={openDropdownCulturas}
                        setOpenDropdownCulturas={setOpenDropdownCulturas}
                        estadios_selecionados_obs={estadios_selecionados_obs}
                        setEstadios_selecionados_obs={setEstadios_selecionados_obs}
                        todos_estadios_obs={todos_estadios_obs} 
                        setTodos_estadios_obs={setTodos_estadios_obs}
                        openDropdownEstadio={openDropdownEstadio_obs}
                        setOpenDropdownEstadio={setOpenDropdownEstadio_obs}
                        id_nivel_alerta={id_nivel_alerta}
                        setId_nivel_alerta={setId_nivel_alerta}
                        obs_obs={obs_obs}
                        setObs_obs={setObs_obs}
                        lista_obs_obs={lista_obs_obs}
                        setLista_obs_obs={setLista_obs_obs}
                        nome_cultura_obs={nome_cultura_obs}
                        setNome_cultura_obs={setNome_cultura_obs}
                        id_cultura_obs={id_cultura_obs}
                        setId_cultura_obs={setId_cultura_obs}
                        id_ativo_selecionado={id_item_principal}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                    />
                )}

                {(tabSelecionada==='Plant back'&&id_item_principal!==null&&is_herbicida===144) &&(
                    <Plantback 
                        id_tipo_principal={id_tipo_principal}
                        id_item_principal={id_item_principal}
                        estadios_selecionados_principal={estadios_selecionados_principal}
                        openDropdownPB={openDropdownPB}
                        setOpenDropdownPB={setOpenDropdownPB}
                        nome_culturaPB={nome_culturaPB}
                        setNome_culturaPB={setNome_culturaPB}
                        id_culturaPB={id_culturaPB}
                        setId_culturaPB={setId_culturaPB}
                        tempo_ate_plantio={tempo_ate_plantio}
                        setTempo_ate_plantio={setTempo_ate_plantio}
                        obsPB={obsPB}
                        setObsPB={setObsPB}
                        id_ativo_selecionado={id_item_principal}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                    />
                )}

                {(tabSelecionada==='Doses'&&id_item_principal!==null) &&(
                    <Doses 
                        id_tipo_principal={id_tipo_principal}
                        id_item_principal={id_item_principal}
                        estadios_selecionados_principal={estadios_selecionados_principal}
                        openDropdownDoses={openDropdownDoses}
                        setopenDropdownDoses={setOpenDropdownDoses}
                        nome_culturaDoses={nome_culturaDoses}
                        setNome_culturaDoses={setNome_culturaDoses}
                        id_culturaDoses={id_culturaDoses}
                        setId_culturaDoses={setId_culturaDoses}
                        todos_estadiosDoses={todos_estadiosDoses}
                        setTodos_estadiosDoses={setTodos_estadiosDoses}
                        estadios_selecionadosDoses={estadios_selecionadosDoses}
                        setEstadios_selecionadosDoses={setEstadios_selecionadosDoses}
                        openDropdownEstadiosDoses={openDropdownEstadiosDoses}
                        setOpenDropdownEstadiosDoses={setOpenDropdownEstadiosDoses}
                        dose_minima={dose_minima}
                        setDose_minima={setDose_minima}
                        dose_maxima={dose_maxima}
                        setDose_maxima={setDose_maxima}
                        id_ativo_selecionado={id_item_principal}
                        atualizarListaPrincipal={atualizarListaPrincipal}
                    />
                )}
            </>}
            
        </Drawer>
    )
}