interface TypesConfig {
  conta: number | string
  codCliente: number | string
  acesso: 'conta' | 'cliente' | 'admin' | 'lab'
  tipo: string
  itens: string
  temAcesso: string
  codSafra: number | string
  cod_perfil?: string,
  nomeSafra?: string
}

export const localConfig = () => {
  const dados: TypesConfig = {
    conta: localStorage.getItem('conta'),
    codCliente: localStorage.getItem('cod-cliente'),
    acesso: localStorage.getItem('tipo-acesso') as any,
    tipo: localStorage.getItem('tipo'),
    itens: JSON.parse(localStorage.getItem('itens')),
    temAcesso: localStorage.getItem('temAcesso'),
    codSafra: localStorage.getItem('codSafra'),
    cod_perfil: localStorage.getItem('cod_perfil'),
    nomeSafra: localStorage.getItem('nomeSafra')
  }
  // console.log('dados', dados)
  return dados
}
