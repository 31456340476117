/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react'
import { LeftOutlined, RightOutlined, SearchOutlined } from '@ant-design/icons'
import { Button, Card, Checkbox, Input, InputProps, Menu } from 'antd'
import { CardProps } from 'antd/lib/card'

import {
  Container,
  GroupBtn,
  TransferSearch,
  TransferContent,
  TransferBody,
  Loading,
} from './styles'
// import { TypesTipoEquipeLiderGerenciar, getTipoEquipeLiderGerenciar } from '../../services/consultoriaAgricola/equipLiderGerenciar'
// import { AxiosResponse } from 'axios'
import { HeaderColuna } from './components/header'


//TYPES E INTERFACES
type TypeColunas = 'left' | 'center' | 'lider' | 'right' | 'consultores'
interface TransferTreeProps {
  title: {
    left: string
    center: string
    lider: string
    right: string
    consultores: string
  }
  dataLeft: DataProps[]
  dataCenter: DataProps[]
  dataLider: DataProps[]
  dataRight: DataProps[]
  dataConsultores: DataProps[]
  onTransfer?: (
    value: any[],
    position: TypeColunas,
    origin: TypeColunas
  ) => void
  loading?: boolean
}

interface DataProps {
  label: string
  value: any
  cod: any
  disabled?: boolean
}

type PropsSearch = {
  position: 'left' | 'center' | 'lider' | 'right' 
}

const TransferTree: React.FC<TransferTreeProps> = ({
  title,
  dataLeft,
  dataCenter,
  dataLider,
  dataRight,
  dataConsultores,
  onTransfer,
  loading = false,
}) => {
  const [leftData, setLeftData] = useState<DataProps[]>([])
  const [centerData, setCenterData] = useState<DataProps[]>([])
  const [liderData, setLiderData] = useState<DataProps[]>([])
  const [rightData, setRightData] = useState<DataProps[]>([])
  const [consultoresData, setConsultoresData] = useState<DataProps[]>([])

  const [checkBoxLeft, setCheckBoxLeft] = useState([])
  const [checkBoxCenter, setCheckBoxCenter] = useState([])
  const [checkBoxLider, setCheckBoxLider] = useState([])
  const [checkBoxRight, setCheckBoxRight] = useState([])
  const [checkBoxConsultores, setCheckBoxConsultores] = useState([])
  const [codLiderEquipe, setCodLiderEquipe] = useState([])

  const [searchLeft, setSearchLeft] = useState<string>('')
  const [searchCenter, setSearchCenter] = useState<string>('')
  const [searchLider, setSearchLider] = useState<string>('')
  const [searchRight, setSearchRight] = useState<string>('')
  const [searchConsultores, setSearchConsultores] = useState<string>('');

  const propsCard: CardProps = {
    size: 'small',
    bordered: true,
    style: {
      width: '32%',
      height: '100%',
      border: '1px solid #d9d9d9',
    },
  }

  const propsInput: InputProps = {
    size: 'middle',
    prefix: <SearchOutlined style={{ opacity: 0.3 }} />,
    width: '100%',
    placeholder: 'Procurar',
  }

  const menu = (onAll, onInverte) => (
    <Menu>
      <Menu.Item onClick={onAll}>Selecionar todos</Menu.Item>
      <Menu.Item onClick={onInverte}>Inverter seleção atual</Menu.Item>
    </Menu>
  )

  const handleSelectAll = (data: any[], position: TypeColunas) => {

    function removerDesabilitados(lista: DataProps[]) {
        return lista?.filter(item=> item.disabled === false) ?? [];
    }

    const cods = removerDesabilitados(data).map((item: any) => item.value);
    

    const codLid = data.map((item: any) => item.cod)

    switch (position) {
      case 'left':
        setCheckBoxLeft(checkBoxLeft.length === removerDesabilitados(dataLeft).length ? [] : cods)
        break

      case 'center':
        setCheckBoxCenter(checkBoxCenter.length === removerDesabilitados(dataCenter).length ? [] : cods)
        break
      case 'lider':
        setCheckBoxLider(checkBoxLider.length === removerDesabilitados(dataLider).length ? [] : cods)
        setCodLiderEquipe(codLiderEquipe.length === removerDesabilitados(dataLider).length ? [] : codLid)
        break

      case 'right':
        setCheckBoxRight(checkBoxRight.length === removerDesabilitados(dataRight).length ? [] : cods)
        break
      case 'consultores':
        setCheckBoxConsultores(checkBoxConsultores.length === removerDesabilitados(dataConsultores).length ? [] : cods)
        break

      default:
        break
    }
  }

  const handleOnTransfer = (
    values: number[],
    positionOrigin: 'left' | 'center' | 'lider' | 'right' | 'consultores',
    positionDest: 'left' | 'center' | 'lider' | 'right' | 'consultores'
  ) => {

    function obterDados() {
      if (positionOrigin === 'left') {
        return leftData;
      }
      if (positionOrigin === 'center') {
        return centerData;
      }
      if (positionOrigin === 'lider') {
        return liderData;
      }
      if (positionOrigin === 'right') {
        return rightData;
      }
      return consultoresData;
    }

    const dataOrigin = obterDados()

    const dados = { newDataDest: [], newDataOrigin: [] }

    dataOrigin.forEach((item) => {
      const newDAtaDest = values.filter((cod) => item.value === cod)



      if (newDAtaDest.length > 0) {
        dados.newDataDest = [...dados.newDataDest, item]
      } else {
        dados.newDataOrigin = [...dados.newDataOrigin, item]
      }
      return dados
    })



    if (positionOrigin === 'left') {
      setLeftData(dados.newDataOrigin)
      setCheckBoxLeft([])
    } else if (positionOrigin === 'center') {
      setCenterData(dados.newDataOrigin)
      setCheckBoxCenter([])
    } else if (positionOrigin === 'lider') {
      setLiderData(dados.newDataOrigin)
      setCheckBoxLider([])
    } else if (positionOrigin === 'consultores') {
      setConsultoresData(dados.newDataOrigin)
      setCheckBoxConsultores([])
    } else {
      setRightData(dados.newDataOrigin)
      setCheckBoxRight([])
    }

    switch (positionDest) {
      case 'left':
        setLeftData([...leftData, ...dados.newDataDest])
        onTransfer(values, positionDest, positionOrigin)
        break
      case 'center':
        setCenterData([...centerData, ...dados.newDataDest])
        onTransfer(values, positionDest, positionOrigin)
        break

      case 'lider':
        setLiderData([...liderData, ...dados.newDataDest]);
        onTransfer(values, positionDest, positionOrigin);
        break;

      case 'right':
        setRightData([...rightData, ...dados.newDataDest])
        onTransfer(values, positionDest, positionOrigin)
        break
      
        case 'consultores':
        setConsultoresData([...consultoresData, ...dados.newDataDest])
        onTransfer(values, positionDest, positionOrigin)
        break

      default:
        break
    }
  }



  // console.log('liderDataTeste', liderData);
  // console.log('rightData', rightData);

  let filterLeft = []
  let filterCenter = []
  let filterLider = []
  let filterRight = []
  let filterConsultores = []

  const handleSearch = (p: TypeColunas, input: string) => {
    input = input.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    const label = (l: string) => {
      return l.normalize('NFD').replace(/[\u0300-\u036f]/g, '')
    }
    switch (p) {
      case 'left':
        filterLeft =
          input.length > 0
            ? leftData.filter((item) =>
              label(item.label).toLowerCase().includes(input.toLowerCase())
            )
            : []
        break
      case 'center':
        filterCenter =
          input.length > 0
            ? centerData.filter((item) =>
              label(item.label).toLowerCase().includes(input.toLowerCase())
            )
            : []
        break
      case 'lider':
        filterLider =
          input.length > 0
            ? liderData.filter((item) =>
              label(item.label).toLowerCase().includes(input.toLowerCase())
            )
            : []
        break
      case 'right':
        filterRight =
          input.length > 0
            ? rightData.filter((item) =>
              label(item.label).toLowerCase().includes(input.toLowerCase())
            )
            : []
        break
      case 'consultores':
        filterConsultores =
          input.length > 0
            ? consultoresData.filter((item) =>
              label(item.label).toLowerCase().includes(input.toLowerCase())
            )
            : []
        break
      default:
        break
    }

    return { filterLeft, filterCenter, filterLider, filterRight, filterConsultores }
  }




  const handleInverterSelect = (data: any[], dataCods, position: 'left' | 'center' | 'lider' | 'right') => {
    const cods = data.map((item) => dataCods.filter((i) => i.value === item)[0])
  }

  useEffect(() => {
    setLeftData(dataLeft)

    setCenterData(dataCenter)
    setLiderData(dataLider)
    setRightData(dataRight)
    setConsultoresData(dataConsultores)
  }, [dataLeft, dataCenter, dataLider, dataRight, liderData, dataConsultores])

  return (
    <Container style={{ pointerEvents: loading ? 'none' : 'auto' }}>
      <Card
        {...propsCard}
        title={
          <HeaderColuna 
            onChangeCheckBox={() => handleSelectAll(leftData, 'left')}
            isIndeterminate={checkBoxLeft.length > 0 && checkBoxLeft.length !== leftData.length}
            isChecked={leftData.length > 0 && checkBoxLeft.length === leftData.length}
            overlayDropDown={menu(() => handleSelectAll(leftData, 'left'),() => handleInverterSelect(checkBoxLeft, leftData, 'left'))}
            title={title.left}
          />
        }
      >
        <TransferContent>
          <TransferSearch>
            <Input {...propsInput} onChange={(e) => setSearchLeft(e.currentTarget.value)} />
          </TransferSearch>
          <TransferBody>
            <Loading loading={loading} />
            <Checkbox.Group
              value={checkBoxLeft}
              onChange={(data) => setCheckBoxLeft(data)}
              className='flex-column'
              options={
                searchLeft.length > 0 ? handleSearch('left', searchLeft).filterLeft : leftData
              }
            />
          </TransferBody>
        </TransferContent>
      </Card>

      <GroupBtn>
        <Button
          disabled={checkBoxLeft.length <= 0}
          /* TRANSFERE USUARIO DISPONIVEL -> TECNICO  */
          onClick={() => handleOnTransfer(checkBoxLeft, 'left', 'center')}
          type='primary'
          size='small'
          icon={<RightOutlined />}
        />
        <Button
          disabled={checkBoxCenter.length <= 0}
          /* TRANSFERE TECNICO -> USUARIO DISPONIVEL  */
          onClick={() => handleOnTransfer(checkBoxCenter, 'center', 'left')}
          type='primary'
          size='small'
          icon={<LeftOutlined />}
        />

      </GroupBtn>


      {/* Coluna Tecnico */}
      <Card
        {...propsCard}
        title={
          <HeaderColuna 
            onChangeCheckBox={() => handleSelectAll(centerData, 'center')}
            isIndeterminate={checkBoxCenter.length > 0 && checkBoxCenter.length !== centerData.length}
            isChecked={centerData.length > 0 && checkBoxCenter.length === centerData.length}
            overlayDropDown={menu(() => handleSelectAll(centerData, 'center'),() => console.log)}
            title={title.center}
          />
        }
      >
        <TransferContent>
          <TransferSearch>
            <Input {...propsInput} onChange={(e) => setSearchCenter(e.currentTarget.value)} />
          </TransferSearch>
          <TransferBody>
            <Loading loading={loading} />
            <Checkbox.Group
              value={checkBoxCenter}
              onChange={(data) => setCheckBoxCenter(data)}
              className='flex-column'
              options={
                searchCenter.length > 0
                  ? handleSearch('center', searchCenter).filterCenter
                  : centerData
              }
            />
          </TransferBody>
        </TransferContent>
      </Card>

      <GroupBtn>
        <Button
          disabled={checkBoxCenter.length <= 0}
          /* TRANSFERE TECNICO -> LIDER   */
          onClick={() => handleOnTransfer(checkBoxCenter, 'center', 'lider')}
          type='primary'
          size='small'
          icon={<RightOutlined />}
        />
        <Button
          disabled={checkBoxLider.length <= 0}
          /* TRANSFERE LIDER  -> TECNICO   */
          onClick={() => handleOnTransfer(checkBoxLider, 'lider', 'center')}
          type='primary'
          size='small'
          icon={<LeftOutlined />}
        />
      </GroupBtn>

      {/* Coluna Lider */}
      <Card
        {...propsCard}
        title={
          <HeaderColuna 
            onChangeCheckBox={() => handleSelectAll(liderData, 'lider')}
            isIndeterminate={checkBoxLider.length > 0 && checkBoxLider.length !== liderData.length}
            isChecked={liderData.length > 0 && checkBoxLider.length === liderData.length}
            overlayDropDown={menu( () => handleSelectAll(liderData, 'lider'), () => console.log )}
            title={title.lider}
          />
        }
      >
        <TransferContent>
          <TransferSearch>
            <Input {...propsInput} onChange={(e) => setSearchLider(e.currentTarget.value)} />
          </TransferSearch>
          <TransferBody>
            <Loading loading={loading} />
            <Checkbox.Group
              value={checkBoxLider}
              onChange={(data) => setCheckBoxLider(data)}
              className='flex-column'
              options={
                searchCenter.length > 0
                  ? handleSearch('lider', searchLider).filterLider
                  : liderData
              }
            />
          </TransferBody>
        </TransferContent>
      </Card>


      <GroupBtn>
        <Button
          disabled={checkBoxLider.length <= 0}
          onClick={() => {
            handleOnTransfer(checkBoxLider, 'lider', 'right')
            // MembroDaEquipeLider(checkBoxLider)
          }}
          type='primary'
          size='small'
          icon={<RightOutlined />}
        />
        <Button
          disabled={checkBoxRight.length <= 0}
          onClick={() => handleOnTransfer(checkBoxRight, 'right', 'lider')}
          type='primary'
          size='small'
          icon={<LeftOutlined />}
        />
      </GroupBtn>

      {/* COLUNA ASSISTENTE TECNICO */}
      <Card
        {...propsCard}
        title={
          <HeaderColuna 
            onChangeCheckBox={() => handleSelectAll(rightData, 'right')}
            isIndeterminate={checkBoxRight.length > 0 && checkBoxRight.length !== rightData.length}
            isChecked={rightData.length > 0 && checkBoxRight.length === rightData.length}
            overlayDropDown={menu( () => handleSelectAll(rightData, 'right'), () => console.log )}
            title={title.right}
          />
        }
      >
        <TransferContent>
          <TransferSearch>
            <Input {...propsInput} onChange={(e) => setSearchRight(e.currentTarget.value)} />
          </TransferSearch>
          <TransferBody>
            <Loading loading={loading} />
            <Checkbox.Group
              value={checkBoxRight}
              onChange={(data) => setCheckBoxRight(data)}
              className='flex-column'
              options={
                searchRight.length > 0 ? handleSearch('right', searchRight).filterRight : rightData
              }
            />
          </TransferBody>
        </TransferContent>
      </Card>

      <GroupBtn>
        <Button
          disabled={checkBoxRight.length <= 0}
          onClick={() => {
            handleOnTransfer(checkBoxRight, 'right', 'consultores')
            // MembroDaEquipeLider(checkBoxLider)
          }}
          type='primary'
          size='small'
          icon={<RightOutlined />}
        />
        <Button
          disabled={checkBoxConsultores.length <= 0}
          onClick={() => handleOnTransfer(checkBoxConsultores, 'consultores', 'right')}
          type='primary'
          size='small'
          icon={<LeftOutlined />}
        />
      </GroupBtn>

      {/* COLUNA CONSULTORES */}
      <Card
        {...propsCard}
        title={
          <HeaderColuna 
            onChangeCheckBox={() => handleSelectAll(consultoresData, 'consultores')}
            isIndeterminate={checkBoxConsultores.length > 0 && checkBoxConsultores.length !== consultoresData.length}
            isChecked={consultoresData.length > 0 && checkBoxConsultores.length === consultoresData.length}
            overlayDropDown={menu( () => handleSelectAll(consultoresData, 'consultores'), () => console.log )}
            title={title.consultores}
          />
        }
      >
        <TransferContent>
          <TransferSearch>
            <Input {...propsInput} onChange={(e) => setSearchConsultores(e.currentTarget.value)} />
          </TransferSearch>
          <TransferBody>
            <Loading loading={loading} />
            <Checkbox.Group
              value={checkBoxConsultores}
              onChange={(data) => setCheckBoxConsultores(data)}
              className='flex-column'
              options={
                searchConsultores.length > 0 ? handleSearch('consultores', searchConsultores).filterConsultores : consultoresData
              }
            />
          </TransferBody>
        </TransferContent>
      </Card>


    </Container>
  )
}

export default TransferTree
