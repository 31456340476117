export function obterTipoLancamento(tab_selecionada: string) {
    switch (tab_selecionada) {
        case 'Ativos':
            return 1;
        case 'Insumos':
            return 2;
        case 'Grupo Químico':
            return 3;
        case 'Classe Agronômica':
            return 4;
        case 'Formulação':
            return 5;
        case 'Cultura':
            return 6;
        case 'Biotecnologia':
            return 8;
        case 'Grupo':
            return 9;
        default:
            return 1;
    }
};

export function obterNomeLancamento(item_lancamento: number) {
    switch (item_lancamento) {
        case 1:
            return 'Ativos';
        case 2:
            return 'Insumos';
        case 3:
            return 'Grupo Químico';
        case 4:
            return 'Classe Agronômica';
        case 5:
            return 'Formulação';
        case 6:
            return 'Cultura';
        case 7:
            return 'Cultura'; //ESTÁDIO
        case 8:
            return 'Biotecnologia';
        case 9:
            return 'Grupo';
        default:
            return '';
    }
};

export function obterCod(tab_selecionada: string) {
    switch (tab_selecionada) {
        case 'Ativos':
            return 'cod_ativo';
        case 'Insumos':
            return 'cod_produto';
        case 'Grupo Químico':
            return 'grupo_quimico';
        case 'Classe Agronômica':
            return 'classe_agronomica';
        case 'Formulação':
            return 'formulacao';
        case 'Cultura':
            return 'cultura';
        case 'Biotecnologia':
            return 'tecnologia';
        case 'Grupo':
            return 'grupo';
        default:
            return 'erro';
    }
}

export function obterCodPorId(id_tipo_principal: number) {
    switch (id_tipo_principal) {
        case 1:
            return 'cod_ativo';
        case 2:
            return 'cod_produto';
        case 3:
            return 'grupo_quimico';
        case 4:
            return 'classe_agronomica';
        case 5:
            return 'formulacao';
        case 6:
            return 'cultura';
        case 8:
            return 'tecnologia';
        case 9:
            return 'grupo';
        default:
            return 'erro';
    }
}

export function obterPlaceholder(tab_selecionada: string) {
    switch (tab_selecionada) {
        case 'Ativos':
            return 'Ativo';
        case 'Insumos':
            return 'Insumo (produto comercial)';
        case 'Grupo Químico':
            return 'Grupo químico';
        case 'Classe Agronômica':
            return 'Classe agronômico';
        case 'Formulação':
            return 'Formulação';
        case 'Cultura':
            return 'Cultura';
        case 'Biotecnologia':
            return 'Biotecnologia';
        case 'Grupo':
            return 'Grupo';
        default:
            return 'item';
    }
}

export function obterTituloColuna(tab_selecionada: string) {
    switch (tab_selecionada) {
        case 'Ativos':
            return 'Ativo';
        case 'Insumos':
            return 'Insumo';
        case 'Grupo Químico':
            return 'Grupo químico';
        case 'Classe Agronômica':
            return 'Classe agronômica';
        case 'Formulação':
            return 'Formulação';
        case 'Cultura':
            return 'Cultura';
        case 'Biotecnologia':
            return 'Biotecnologia';
        case 'Grupo':
            return 'Grupo';
        default:
            return 'item';
    }
}

export function obterLarguraTabResponsiva(tab_selecionada: string, width: number, dafault: number): number {

    const largurasTabs = {
        'Insumos': { md_1536: 100, lg_1920: 190 },
        'Ativos': { md_1536: 85, lg_1920: 190 },
        'Grupo Químico': { md_1536: 140, lg_1920: 205 },
        'Formulação': { md_1536: 117, lg_1920: 190 },
        'Cultura': { md_1536: 84, lg_1920: 190 },
        'Classe Agronômica': { md_1536: 170, lg_1920: 225 },
        'Biotecnologia': { md_1536: 130, lg_1920: 190 },
        'Grupo': { md_1536: 90, lg_1920: 190 }
    };

    const config = largurasTabs?.[tab_selecionada];

    if (config) {
        if (width < 1500) {
            return config.md_1536;
        } else if (width === 1920) {
            return config.lg_1920;
        }
    }

    return dafault || 125;
    
};

/**retorna o nome da propriedade de acordo com a origem do cadastro foi feito
 * 
 * `item_lancamento_nome` caso tenho sido cadastrado na mesma aba que está aberto
 * 
 * `lancamento_nome` caso tenha sido cadastrado em uma aba diferente da que está aberta
*/
export function obterValorLancamento(origem: number, tab: number, excessao?: boolean): 'item_lancamento_nome' | 'lancamento_nome' {
    if (excessao===true) {
        return 'item_lancamento_nome';
    }
    if ((origem === tab) && (origem !== 6 && origem !== 7)) {
        return 'lancamento_nome';
    }
    if (origem === tab) {
        return 'item_lancamento_nome';
    }
    return 'lancamento_nome';
}


export function obterValorLancamentoSemCultura(origem: number, tab: number, excessao?: boolean): 'item_lancamento_nome' | 'lancamento_nome' {
    if (excessao===true) {
        return 'item_lancamento_nome';
    }

    if (origem === tab) {
        return 'item_lancamento_nome';
    }
    return 'lancamento_nome';
}

/**retorna o nome da propriedade de acordo com a origem do cadastro foi feito
 * 
 * `item_lancamento` caso tenho sido cadastrado na mesma aba que está aberto
 * 
 * `lancamento` caso tenha sido cadastrado em uma aba diferente da que está aberta
*/
export function obterCodLancamento(origem: number, tab: number):  'item_lancamento' | 'lancamento' {
    if ((origem === tab) && (origem !== 6 && origem !== 7)) {
        return 'lancamento';
    }
    if (origem === tab) {
        return 'item_lancamento';
    }
    return 'lancamento';
}

export function obterCodLancamentoSemCultura(origem: number, tab: number):  'item_lancamento' | 'lancamento' {
    if (origem === tab) {
        return 'item_lancamento';
    }
    return 'lancamento';
}

export function obterMensagemLoading(tab_selecionada: string) {
    let mensagem = {
        'Insumos': 'Insumos',
        'Ativos': 'Ativos',
        'Grupo Químico': 'Grupos Químicos',
        'Formulação': 'Formulações',
        'Cultura': 'Culturas',
        'Classe Agronômica': 'Classes Agronônomicas',
        'Biotecnologia': 'Biotecnologias',
        'Grupo': 'Grupos',
    };

    return mensagem?.[tab_selecionada];
}


/**Retorna `true` se o item for do tipo cultura(6) ou estádio(7) */
export function isCulturaOuEstadio(item_lancamento: number, lancamento: number): boolean {
    return item_lancamento === 7  || item_lancamento === 6 || lancamento === 7  || lancamento === 6;
}


export function isAbaCultura(item_lancamento: number, lancamento: number, id_tipo_principal: number): boolean {
    let is_cultura_ou_estadio: boolean = item_lancamento === 7  || item_lancamento === 6 || lancamento === 7  || lancamento === 6;
    let is_aba_cultura: boolean = id_tipo_principal === 6 || id_tipo_principal ===7;

    return is_cultura_ou_estadio && is_aba_cultura;
}