import styled from "styled-components";

export const BoxTransfer = styled.div`
    width: 50%;
    border: thin solid var(--border-color);
    border-radius: 10px;
    display: flex;
    flex-direction: column;
    gap: 10px;

    div.BoxTransfer__header {
        display: flex;
        align-items: center;
        gap: 10px;
        border-bottom: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 15px 10px 10px 10px;
    }

    div.BoxTransfer__search {
        box-sizing: border-box;
        padding: 0px 9px;
    }

    div.BoxTransfer__container {
        width: 98%;
        height: 100%;
        overflow-y: auto;
        margin-top: -10px;
        position: relative;

        div.BoxTransfer__container__row {
            width: 100%;
            height: 35px;
            display: flex;
            align-items: center;
            gap: 10px;
            cursor: pointer;
            text-transform: uppercase;
            box-sizing: border-box;
            padding: 0px 10px;

            :hover {
                background: var(--hover-color);
            }

        }

        div.BoxTransfer__container__loading {
            position: absolute;
            top: 0;
            left: 0;
            background-color: rgba(255, 255, 255, 0.5);
            width: 100%;
            height: 100%;
        }
    }

    div.BoxTransfer__footer {
        width: 100%;
        height: 80px;
        display: flex;
        justify-content: space-between;
        align-items: center;
        border-top: thin solid var(--border-color);
        box-sizing: border-box;
        padding: 0px 10px;
        font-weight: 600;
    }
`;