import styled from "styled-components";
import { useState } from "react";
import { faRefresh } from "@fortawesome/free-solid-svg-icons";
import { clearCache } from "clear-cache";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";



//ASSETS
import iconRefresh from '../../../../../assets/img/iconRefresh.gif';
import ButtonCustom from "../../../../../Components/ButtonCustom";

//STYLES
const Popover = styled.div`
    width: 450px;
    background-color: #fff;
    position: fixed;
    bottom: 30px;
    right: 30px;
    border-radius: 10px;
    display: flex;
    align-items: center;
    box-sizing: border-box;
    padding: 15px;
    gap: 15px;
    overflow: hidden !important;
    animation: horizontal-shaking 1s forwards;

    :hover {
        animation: none
    }

    @keyframes horizontal-shaking {
        0% { transform: translateX(0) }
        10% { transform: translateX(-7px) }
        20% { transform: translateX(7px) }
        30% { transform: translateX(-7px) }
        40% { transform: translateX(7px) }
        50% { transform: translateX(-7px) }
        60% { transform: translateX(7px) }
        70% { transform: translateX(-7px) }
        80% { transform: translateX(7px) }
        90% { transform: translateX(-7px) }
        100% { transform: translateX(0px) }
    }

    div.Popover__border {
        width: 100%;
        height: 10px;
        position: absolute;
        left: 0;
        top: 0;
        background: linear-gradient(270deg, #61FF17, #3ddab4, var(--primary-color));
        background-size: 400% 400%;

        -webkit-animation: AnimationName 3s ease infinite;
        -o-animation: AnimationName 3s ease infinite;
        animation: AnimationName 3s ease infinite;
    }

        @-webkit-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @-o-keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }
        @keyframes AnimationName {
            0%{background-position:0% 50%}
            50%{background-position:100% 50%}
            100%{background-position:0% 50%}
        }

    div.Popover__icon {
        width: 60px;
        display: flex;
        align-items: center;
        justify-content: center;
        margin-top: 5px;

        img {
            width: 100%;
            border-radius: 50%;
            /* opacity: 0; */
        }
    }

    div.Popover__info {
        width: 100%;
        height: 100%;
        display: flex;
        flex-direction: column;
        gap: 3px;
        margin-top: 5px;

        h4 {
            font-weight: 700;
            font-size: 17px;
            color: var(--text-color);
            margin: 0;
        }
        span {
            font-size: 14px;
            line-height: 14px;
            margin: 0;
            color: var(--text-color-light);
        }
    }
`;

//PROPS 
interface Props {
    new_version?: string | any;
}

export const PopupUpdate: React.FC<Props> = ({ new_version }) => {

    //STATES
    const [loading, setLoading] = useState(false);

    const atualizarSistema = () => {
        setLoading(true);
        localStorage.setItem('last_handle_update', String(new Date()));
        localStorage.setItem('handle_version_updated', String(new_version))
        if ('serviceWorker' in navigator) {
          navigator.serviceWorker.getRegistrations().then((registrations) => {
            for (let registration of registrations) {
              registration.unregister();  // Desativa o service worker
            }
            clearCache(false);
            window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
          });
        } else {
            clearCache(false);
            window.location.href = window.location.origin + window.location.pathname + '?updated=' + new Date().getTime();
        }
    };

    return (
        <Popover>
            <div className="Popover__border" />
            <div className="Popover__icon">
                <img src={iconRefresh} />
            </div>
            <div className="Popover__info">
                <h4>Nova versão disponível!</h4>
                <span>Pressione CTRL + F5 para atualizar, ou clique no botão abaixo.</span>

                <ButtonCustom
                    onClick={atualizarSistema}
                    style={{alignSelf: 'flex-end', height: 40}}
                    icon={<FontAwesomeIcon icon={faRefresh} />}
                    loading={loading}
                    disabled={loading}
                >
                    Atualizar sistema
                </ButtonCustom>
            </div>
        </Popover>
    )
}