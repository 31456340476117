import styled from 'styled-components'

export const Container = styled.div`
  height: 100vh;
  overflow: auto;
  #participacao input[type='number'] {
    -moz-appearance: textfield;
    appearance: textfield;
  }
`
