import { AxiosResponse } from 'axios'
import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeMoniNivelControleItem {
  itens: [MoniNivelItemControleItens]
}

export interface MoniNivelItemControleItens {
  cod: number | string
  id?: number | string
  cod_nivel_controle: number
  cod_variacao_especie: number | null
  cod_tipo_metrica: number
  valor: string
  status: number
  cod_especie: number
  totalizar: number
  inverter_escala: number
  chave: number
  especie_nome: string
  variacao_especie_nome: string
  cod_categoria: number
  categoria_nome: string
  tipo_metrica_nome: string
  nivel_controle_item_estadio: {
    cod: number
    cod_nivel_controle_item: number
    cod_estadio: number
    estadio_nome: string
    cod_nivel_controle: number
    cod_especie: number
    cod_variacao_especie: number | null
  }[]
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  codCultura?: number
  codEstadio?: string
  cod?: number
  pagination?: number | -1
  embed?: string
}

const url = '/monitoramento/nivel-controle-item'

export const getMoniNivelControleItem = async (params: TypeQuery) => {
  const { pagination = -1, codConta } = params

  const { codCliente, conta } = localConfig()
  const codCultura = parseInt(localStorage.getItem('codCultura'))

  params = {
    pagination,
    codCliente,
    ...params,
    codCultura,
    codConta: codConta ? codConta : conta,
  }

  const response: AxiosResponse<TypeMoniNivelControleItem> = await api.get(url, {
    params,
  })

  return response
}

export const getTiposSistema = async (params) => {
  const { pagination = -1, codConta } = params

  params = {
    pagination,
    ...params,
    codConta: codConta,
  }

  const response: AxiosResponse = await api.get('/tipos-sistema', {
    params,
  })

  return response
}

export const postMoniNivelControleItem = async (data) => {
  const config = localConfig()

  const response = api.post(url, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed: 'nivel_controle_item_estadio',
    },
  })

  return response
}

export const putMoniNivelControleItem = async (cod, data, embed = 'item') => {
  const config = localConfig()

  const response = api.put(`${url}/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
      embed,
    },
  })

  return response
}

export const delMoniNivelControleItem = async (cod) => {
  const config = localConfig()

  const response = api.delete(`${url}/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
