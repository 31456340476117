import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useEffect, useState } from "react";
import { faBatteryFull, faBatteryQuarter, faFloppyDisk, faNetworkWired, faTag, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { localConfig } from "../../../../../../../../../../Configs/localConfig";
import { usePostDose } from "../../../../../../../../../../services/recomendacao/ativos/post";
import { apenasNumeros } from "../../../../../../../../../../utils";

//COMPONENTS
import Input from "../../../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../../../../../../Components/CustomUi/inline";
import { obterCodPorId } from "../../../../../../meta";
import { Button, Popconfirm } from "antd";
import { obterTipoPorId, obterValor } from "../../../compatibilidades/meta";
import { useDeleteCompatibilidade } from "../../../../../../../../../../services/recomendacao/ativos/delete";

//PROPS
interface Props {
    registros: any;
    onClose?: Function;
    refetch?: Function;
    atualizarLista?: Function;
    atualizarListaPrincipal?: Function
    isVisualizar?: boolean;
    id_tipo_principal: number | null;
}

export const ModalEditar: React.FC<Props> = ({ registros, onClose, refetch, atualizarLista, atualizarListaPrincipal, isVisualizar, id_tipo_principal }) => {

    //STATES
    const [obs, setObs] = useState<string>('');
    const [original_obs, setOriginal_obs] = useState<string>('');
    const [dose_minima, setDose_minima] = useState<string>('');
    const [dose_maxima, setDose_maxima] = useState<string>('');
    const [original_dose_minima, setOriginal_dose_minima] = useState<string>('');
    const [original_dose_maxima, setOriginal_dose_maxima] = useState<string>('');
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    //API
    const { carregando_salvar_dose, mutate_salvar_dose } =  usePostDose();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarEdicao() {
        mutate_salvar_dose({
            codConta: Number(localConfig().conta),
            id_tipo_principal: id_tipo_principal,
            id_item_principal: registros?.[obterCodPorId(id_tipo_principal)],
            cod: registros?.cod,
            cod_ativo: registros?.cod_ativo,
            cod_cultura: registros?.cod_cultura,
            dose_maxima: Number(dose_maxima),
            dose_minima: Number(dose_minima),
            todos_estadios: false,
            cod_estadios: registros?.cod_estadio ? [registros.cod_estadio] : undefined,
            obs: obs,
            limparStates: () => onClose?.(),
            atualizarLista: () => atualizarLista?.(),
            atualizarListaPrincipal: () => atualizarListaPrincipal?.(),
        });
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: registros.cod,
            limparStates: () => {},
            atualizarLista: () => {refetch?.(); atualizarLista?.(); atualizarListaPrincipal?.()},
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        setObs(registros?.observacao);
        setOriginal_obs(registros?.observacao);
        setDose_minima(String(registros?.dose_minima)?.replace('.00',''));
        setOriginal_dose_minima(String(registros?.dose_minima)?.replace('.00',''));
        setDose_maxima(String(registros?.dose_maxima)?.replace('.00',''));
        setOriginal_dose_maxima(String(registros?.dose_maxima)?.replace('.00',''));
    }, [registros]);

    function isDoseMaior() {
        if (!dose_maxima) {
            return false;
        }
        
        return Number(dose_maxima) < Number(dose_minima);
    }
    
    function desabilitarBtnSalvar() {
        if (carregando_salvar_dose) {
            return true;
        }
        if (isVisualizar) {
            return false;
        }
        return (carregando_salvar_dose || (original_obs === obs) && (original_dose_maxima === dose_maxima) && (original_dose_minima === dose_minima))
    }
    
    return (
        <div style={{display:'flex',flexDirection:'column',gap:15}}>

        <Inline gap={15}>
            <Input 
                label="Tipo"
                value={obterTipoPorId(registros?.lancamento)}
                onChange={()=>{}}
                placeholder="Selecione o tipo"
                icon={faNetworkWired}
                desabilitado
            />
            <Input 
                label={obterTipoPorId(registros?.lancamento)}
                value={registros?.lancamento_nome}
                onChange={()=>{}}
                placeholder="Selecione o tipo"
                icon={faTag}
                desabilitado
            />
        </Inline>

            <Inline>
                <Input 
                    label="Cultura"
                    value={registros.cultura_item_lancamento}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faNetworkWired}
                    desabilitado
                />
                <Input 
                    label="Estádio"
                    value={registros?.item_lancamento_nome ?? 'Todos'}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faTag}
                    desabilitado
                />
            </Inline>

            <Inline gap={15}>
                <Input 
                    label="Dose mínima (g/ha)"
                    value={apenasNumeros(dose_minima)}
                    onChange={setDose_minima}
                    placeholder="Selecione o tipo"
                    icon={faBatteryQuarter}
                    obrigatorio
                />
                <Input 
                    label="Dose máxima (g/ha)"
                    value={apenasNumeros(dose_maxima)}
                    onChange={setDose_maxima}
                    placeholder="Selecione o tipo"
                    icon={faBatteryFull}
                    obrigatorio
                    error={isDoseMaior()}
                    errorMessage="Dose máxima não pode ser menor que a dose mínima"
                />
            </Inline>
            

            <TextArea
                label={`Observação da compatibilidade`}
                placeholder='Digite aqui a observação'
                value={obs}
                onChange={setObs}
                resize={false}
                height={70}
            />

            <Inline justifyContent={isVisualizar ? 'space-between' :"flex-end"} reverse={isVisualizar}>
                {isVisualizar ? 
                    <Popconfirm
                        placement="topRight"
                        title={'Tem certeza que deseja deletar este item?'}
                        open={openConfirm}
                        description={
                        <div style={{display:'flex',flexDirection:'column',gap:15}}>
                            <span>o item <b><i>{obterValor(registros)}</i></b> será deletado!</span>
                            <Inline justifyContent="flex-end">

                                <ButtonCustom 
                                    onClick={()=>{setOpenConfirm(false)}}
                                    type="tertiary"
                                    icon={<FontAwesomeIcon icon={faXmark} />}
                                    disabled={carregando_deletar_compati}
                                >
                                    Cancelar
                                </ButtonCustom>

                                <ButtonCustom 
                                    onClick={()=>{deletarCompatibilidade()}}
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    danger
                                    loading={carregando_deletar_compati}
                                    disabled={carregando_deletar_compati}
                                >
                                    Deletar
                                </ButtonCustom>

                            </Inline>
                        </div>}
                        okButtonProps={{style: {display:'none'}}}
                        cancelButtonProps={{style: {display:'none'}}}
                    >
                        <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                            <ButtonCustom
                                onClick={()=>{setOpenConfirm(true)}}
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                type="secondary"
                                danger
                            >Deletar</ButtonCustom>  
                        </Button>
                    </Popconfirm>
                :
                    <ButtonCustom
                        icon={<FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>}
                        onClick={()=>{onClose(false)}}
                        type="tertiary"
                        danger
                        disabled={carregando_salvar_dose}
                    >
                        Cancelar
                    </ButtonCustom>
                }
                
                <ButtonCustom 
                    icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}
                    onClick={()=>{salvarEdicao()}}
                    loading={carregando_salvar_dose}
                    disabled={desabilitarBtnSalvar()}
                >
                    Salvar edição
                </ButtonCustom>
            </Inline>

        </div>
    )
};