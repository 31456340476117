import { faAngleDown } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect, useRef } from "react";

//STYLES
import { BotaoDefinicao, BotaoOpcao } from "./styled";

//ASSETS
import alta from '../../../../../../assets/img/hight.png';
import baixa from '../../../../../../assets/img/low.png';
import padrao from '../../../../../../assets/img/default.png';
// import { Tooltip } from "antd";

//PROPS
interface Props {
    descricao_atual: string;
    valor_atual: number;
    onChangeDescricao: Function;
    onChangeValor: Function;
    remontarBook: Function;
}

const Definicao: React.FC<Props> = ({ descricao_atual, valor_atual, onChangeDescricao, onChangeValor, remontarBook }) => {

    const definicoes: {descricao: string, icone: any, tooltip: string, valor: number}[] = [ 
        {descricao: 'Definição baixa', icone: baixa, tooltip: 'mapa com bastante serrilhado, com carregamento rápido', valor: 0.0005},
        {descricao: 'Definição padrão', icone: padrao,  tooltip: 'mapa com algum serrilhado, com carregamento normal', valor: 0.0001},
        {descricao: 'Definição melhorada', icone: alta,  tooltip: 'mapa com pouco ou sem serrilhado, com carregamento lento', valor: 0.00004},
    ];

    //REFs
    const refDefinicao = useRef<any>();

    //STATES
    const [open, setOpen] = useState<boolean>(false);

    function qualidadeAtual() {
        switch (valor_atual) {
            case 0.0002:
                return {icone: baixa, descricao: 'Definição baixa'}
            case 0.0003:
                return {icone: baixa, descricao: 'Definição baixa'}
            case 0.0005:
                return {icone: baixa, descricao: 'Definição baixa'}

            case 0.0001:
                return {icone: padrao, descricao: 'Definição padrão'}
            
            case 0.00003:
                return {icone: alta, descricao: 'Definição melhorada'}
            case 0.00004:
                return {icone: alta, descricao: 'Definição melhorada'}
            case 0.00005:
                return {icone: alta, descricao: 'Definição melhorada'}
            default:
                return padrao;
        }
    }

    const handleClickOutside:any = (event: React.MouseEvent<HTMLButtonElement>) => {
        if (refDefinicao.current && !refDefinicao.current.contains(event.target)) {
            setOpen(false);
        }
    };
  
    useEffect(() => {
        document.addEventListener('click', handleClickOutside, true);
        return () => {
          document.removeEventListener('click', handleClickOutside, true);
        };
    }); 

    return (
        <BotaoDefinicao ref={refDefinicao}>
            <div className="BotaoDefinicao__conteudo" data-open={open}>
                <div className="BotaoDefinicao__wrapper" onClick={()=>{setOpen(!open)}}>
                    <img src={qualidadeAtual().icone} className="BotaoDefinicao__img"/>
                    
                    {qualidadeAtual().descricao}

                    <FontAwesomeIcon icon={faAngleDown} className="BotaoDefinicao__indicador" />
                </div>
                
                <div className="BotaoDefinicao__lista" data-open={open}>
                    {definicoes.map(({ descricao, icone, valor, tooltip }, index)=> {
                        return (
                            // <Tooltip key={index} title={tooltip} placement="right">
                                <BotaoOpcao 
                                    key={index} 
                                    onClick={()=>{
                                        onChangeDescricao(descricao);
                                        onChangeValor(valor);
                                        setOpen(false);
                                        remontarBook()
                                    }}
                                    disabled={!open}
                                >
                                    <img src={icone} className="BotaoDefinicao__lista__img"/>
                                    {descricao}
                                </BotaoOpcao>
                            // </Tooltip>
                        )
                    })}
                </div>
            </div>
        </BotaoDefinicao>
    )
};

export default Definicao;