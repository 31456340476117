import { faPlus, faXmark } from "@fortawesome/free-solid-svg-icons";
import { Empty, Tooltip } from "antd";
import React, { useState, useEffect } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//STYLES
import { BoxTransfer } from "./style";

//APP
import { gerarKey, leadZero, parseArrayNumeros, removerAcentos } from "../../../utils";

//COMPONENTS
import Input from "../input";
import Checkbox from "../checkbox";
import Carregando from "../loading";
import ButtonCustom from "../../ButtonCustom";
import { TitleH2 } from "../titleH2";

//PROPS
interface Props {
    title: string;
    placeholder?: string;
    width?: number | string;
    height?: number | string;
    items: any[];
    descriptionItem: string;
    targetItem: string;
    selecteds: string[]|number[];
    selectItens: React.Dispatch<React.SetStateAction<any>>;
    type?: 'add' | 'remove';
    emptyMessage?: string;
    loadingMessage?: string;
    isLoading?: boolean;
    isLoadingBtn?: boolean;
    onChange: Function;
    markAll: boolean;
    setMarkAll: React.Dispatch<React.SetStateAction<boolean>>;
}

export const TransferContainer: React.FC<Props> = ({ title, placeholder, height, width, items, 
    descriptionItem, selecteds, selectItens, targetItem, type, emptyMessage, loadingMessage, 
    isLoading, onChange, isLoadingBtn, setMarkAll, markAll }) => {

    //STATES
    const [buscar, setBuscar] = useState<string>('');
  
    useEffect(() => {
        if (items.length == 0) {
            setMarkAll(false)
        }
    }, [items])
    

    function selecionarItens(item: string | number) {
        
        let jaExiste: number | string = parseArrayNumeros(selecteds)?.find(selects => selects === item);

        if (jaExiste!==undefined) {
            let remover = parseArrayNumeros(selecteds)?.filter(selects=> selects !== item);
            selectItens(remover);

            return null;
        }

        selectItens(selecteds=> [...selecteds, item]);
        
    }

    function isSelecionado(item: string | number) {
        let jaExiste  = parseArrayNumeros(selecteds)?.find(selects=> selects === item );

        return jaExiste!==undefined;
    }

    function encontrarItem(buscar_nome: string) {
        const ativoSemAcentos = removerAcentos(buscar_nome)
        return function (item) {
            const nomeSemAcentos = removerAcentos(String(item?.[descriptionItem]))
            return nomeSemAcentos.toLowerCase().includes(ativoSemAcentos.toLowerCase()) || !buscar_nome
        }
    }

    return (
        <BoxTransfer style={{height: height, width: width}} data-type={type??'add'}>

            <div className="BoxTransfer__header">
                <Tooltip title="Selecionar todos">
                    <span>
                        <Checkbox 
                            isChecked={markAll}
                            trigger={setMarkAll}
                            disabled={items?.length === 0}
                        />
                    </span>
                </Tooltip>
                <TitleH2>{title}</TitleH2>
            </div>

            <div className="BoxTransfer__search">
                <Input 
                    placeholder={placeholder??'Buscar'}
                    value={buscar}
                    onChange={setBuscar}
                />
            </div>         

            <div className="BoxTransfer__container">
                {(items ?? [])?.filter(encontrarItem(buscar))?.map((item, index)=> {
                    return (
                        <div className="BoxTransfer__container__row"  role='none' onClick={()=>{selecionarItens(item?.[targetItem??''])}} key={gerarKey(index)}>
                            <Checkbox 
                                isChecked={markAll ? true : isSelecionado(item?.[targetItem??''])}
                                trigger={()=>{}}
                                onlyCheckBox
                            />
                            {item?.[descriptionItem??'']}
                        </div>
                    )
                })}

                {(items ?? [])?.length === 0 &&(
                    <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={emptyMessage??`Não á registros`} />
                )}

                {isLoading &&(
                    <div className="BoxTransfer__container__loading">
                        <Carregando 
                            height={'100%'}
                            justifyContent="center"
                            legenda={loadingMessage??"Carregando registros"}
                            animation
                        />
                    </div>
                )}
                
                
            </div>

            <div className="BoxTransfer__footer">

                {markAll && items?.length > 0 ? 
                    <span>Todos os {leadZero((items??[])?.length)} selecionados</span>
                    : <>
                        {(selecteds??[])?.length > 0 
                            ? <>{leadZero((selecteds??[])?.length)} Selecionado{((selecteds??[])?.length) > 1?'s':''}</>
                            : <span style={{color: 'var(--text-color-light)', opacity: 0.8}}>Nenhum selecionado</span>
                        }
                    </>
                }
                
                

                <ButtonCustom 
                    value="Adicionar"
                    icon={<FontAwesomeIcon icon={type==='remove' ? faXmark : faPlus} />}
                    onClick={()=>{onChange?.()}}
                    danger={type==='remove'}
                    disabled={isLoadingBtn ? true : markAll ? false : (selecteds??[])?.length === 0}
                    loading={isLoadingBtn}
                >
                    {type==='remove' ? 'Remover' : 'Adicionar'}
                </ButtonCustom>
            </div>

        </BoxTransfer>
    )
}