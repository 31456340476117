import React, { useState } from 'react';
import { Col, ColorPicker, Divider, Row, Space } from 'antd';

const Picker = ({ onChange , value}) => (

  // const [selectedColor, setSelectedColor] = useState('FF0000');

  // const onChange = (color) => {
  //   setSelectedColor(color.hex);
  //   // Outras ações desejadas ao alterar a cor
  // };
  <ColorPicker
    styles={{
      popupOverlayInner: {
        width: 260,
      },
    }}
    onChange={onChange}
    value={value}
    presets={[
      {
        label: 'Selecione uma cor',
        colors: [
          '#FF0000',
          '#00FF00',
          '#0000FF',
          '#FFFF00',
          '#FF00FF',
          '#00FFFF',
          '#F5222D',
          '#FA8C16',
          '#722ED1',
          '#EB2F96',
        ]


      },
     
    ]}
    panelRender={(_, { components: { Presets } }) => (
      <div
        className="custom-panel"
        style={{
          display: 'flex',
          width: 468,
        }}
      >
        <div
          style={{
            flex: 1,
          }}
        >
          <Presets/>
        </div>
      </div>
    )}
  />

);

export default Picker;
