
import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { tryError } from '../../Afins'
import { api } from '../../api'

export interface TypesTipoEquipeConsultorGerenciar {
  itens: ItensTipoEquipeConsultorGerenciarTypes[]
}

export interface ItensTipoEquipeConsultorGerenciarTypes {
  cod_func: number
  cod_conta: number
  cod_cliente: number
  status: number
  acessa_sistema: number
  cod_perfil_conta: number
  cod_perfil_cliente: number
  cod_perfil_cliente_geral: number
  cod: number
  cod_consultor: number
  acesso_cliente: number
  usuario_nome: string
  conta_nome: string
  cliente_nome: string
  email: string
  cliente_logo: string
  usuario_tipo: number
}

type TypesQuery = {
  cod?: number
  codConsultor?: number
  embed?: string
}

const url = '/gerenciar-equipe-consultor'

export const getTipoEquipeConsultorGerenciar = async (params: TypesQuery) => {
  try {
    const { conta, codCliente } = localConfig()
    const resp: AxiosResponse<TypesTipoEquipeConsultorGerenciar> = await api.get(url, {
      params: {
        codConta: conta,
        codCliente,
        ...params,
        pagination: -1,
      },
    })

    return resp
  } catch (error) {
    tryError(error)
  }
}

export const postTipoEquipeConsultorGerenciar = async (data) => {
  try {
    const { codCliente, conta } = localConfig()
    const resp: AxiosResponse<TypesTipoEquipeConsultorGerenciar> = await api.post(url, data, {
      params: {
        codCliente,
        codConta: conta,
      },
    })

    return resp
  } catch (error) {
    tryError(error)
  }
}

export const delTipoEquipeConsultorGerenciar = async (codConsultor, codTecnico) => {
  try {
    const { codCliente, conta } = localConfig()
    const resp: AxiosResponse<TypesTipoEquipeConsultorGerenciar> = await api.delete(
      `${url}/consultor/${codConsultor}/usuario/${codTecnico}`,
      {
        params: {
          codConta: conta,
          codCliente,
        },
      }
    )
    return resp
  } catch (error) {
    tryError(error)
  }
}
