
export function buscarCadastro(buscarItem) {
    return function(item) {              
        return String(item.cultura_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.plant_back)?.includes(buscarItem?.toLowerCase()) ||
        !buscarItem;
    }
};

export function filtrarPlantback() {
    return function(item) {              
        return item.cod_tipo === 4
    }
}