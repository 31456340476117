import React from "react";
import styled from "styled-components";

//STYLES
const Row = styled.div`
    width: 100%;
    height: 55px;
    border-bottom: thin solid rgba(0,0,0,0.06);
    display: flex;

    &:hover {
        background-color: #fafafa;
    }

`;

//PROPS
interface Props {
    children?: React.ReactNode;
    onDoubleClick?: Function;
    highlight?: boolean;
    style?: React.CSSProperties;
}

export const RowTable: React.FC<Props> = ({ children, onDoubleClick, highlight, style }) => {
    return (
        <Row role="none"  onDoubleClick={()=>onDoubleClick?.()} style={{background: highlight ? 'rgba(152,251,152,0.3)' : undefined, ...style}}>
            {children}
        </Row>
    )
}