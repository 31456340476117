import React from 'react'
import Input from '../../../CustomUi/input'
import {
  faAddressCard,
  faCalendarDays,
  faCar,
  faEye,
  faLock,
  faUpload,
} from '@fortawesome/free-solid-svg-icons'
import { Modal, Upload } from 'antd'
import ButtonCustom from '../../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { formatarData, getB64Type } from '../../../../services/Afins'
import Svg from '@ant-design/icons'
import { regular } from '@fortawesome/fontawesome-svg-core/import.macro'
import { apenasNumeros } from '../../../../utils'

interface PropsSenha {
  cnh?: string
  setCnh?: React.Dispatch<React.SetStateAction<string>>
  categoriaCnh?: string
  setCategoriaCnh?: React.Dispatch<React.SetStateAction<string>>
  validadeCnh?: string
  setValidadeCnh?: React.Dispatch<React.SetStateAction<string>>
  documentoCnh?: any[]
  setDocumentoCnh?: React.Dispatch<React.SetStateAction<any[]>>
}

const FormDocumento: React.FC<PropsSenha> = ({
  cnh,
  setCnh,
  categoriaCnh,
  setCategoriaCnh,
  validadeCnh,
  setValidadeCnh,
  documentoCnh,
  setDocumentoCnh,
}) => {
  const onChangeDocuments = async (tipo) => {
    setDocumentoCnh({ ...documentoCnh, ...tipo })
  }

  const onPreviewDocuments = async (file, type = null) => {
    let src = documentoCnh[file].file
    if (type === null) type = documentoCnh[file].file?.type

    if (src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(documentoCnh[file].file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    } else {
      src = documentoCnh[file]
      type = getB64Type(src)
    }

    switch (file) {
      case 'doc_cnh':
        file = 'CNH'
        break
    }

    Modal.info({
      title: file,
      width: '1024px',
      style: { top: '10px' },
      okText: 'Fechar',
      icon: (
        <Svg
          component={() => (
            <FontAwesomeIcon
              icon={type === 'application/pdf' ? regular('file-pdf') : regular('image')}
            />
          )}
        />
      ),
      content: (
        <embed
          width={type === 'application/pdf' ? '100%' : null}
          style={{
            minHeight: '80vh',
            maxHeight: '80vh',
            maxWidth: type !== 'application/pdf' ? '100%' : null,
          }}
          src={src}
        />
      ),
    })
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  const onPreview = async (file) => {
    let src = file.url
    if (!src) {
      src = await new Promise((resolve) => {
        const reader = new FileReader()
        reader.readAsDataURL(file.originFileObj)
        reader.onload = () => resolve(reader.result)
      })
    }

    Modal.info({
      title: 'Foto',
      width: '500px',
      okText: 'Fechar',
      icon: <Svg component={() => <FontAwesomeIcon icon={regular('image')} />} />,
      content: <img width='100%' src={src} alt='foto' />,
    })
  }

  return (
    <div className='line'>
      <Input
        label='CNH'
        icon={faAddressCard}
        placeholder='Digite sua CNH'
        value={apenasNumeros(cnh)}
        onChange={setCnh}
        width={'100%'}
        height={'30%'}
      />
      <Input
        label='Categoria CNH'
        icon={faCar}
        placeholder='Categoria CNH'
        value={categoriaCnh}
        onChange={setCategoriaCnh}
        width={'100%'}
      />
      <div className='campo-input-upload'>
        <Input
          label='Validade CNH'
          icon={faCalendarDays}
          placeholder='00/00/0000'
          value={formatarData(validadeCnh)}
          onChange={setValidadeCnh}
          width={'100%'}
          maxLength={10}
        />
        <div className='campo-upload-preview'>
          <Upload
            className='upload-button'
            customRequest={dummyRequest}
            maxCount={1}
            accept='image/png, image/jpeg, .pdf'
            onChange={(file) => onChangeDocuments({ doc_cnh: file })}
            onPreview={onPreview}
            showUploadList={false}
          >
            <ButtonCustom
              style={{ height: '36px' }}
              onClick={() => {}}
              icon={<FontAwesomeIcon icon={faUpload} />}
            ></ButtonCustom>
          </Upload>

          <ButtonCustom
            type='secondary'
            disabled={documentoCnh === undefined || documentoCnh.length <= 0}
            onClick={() => onPreviewDocuments('doc_cnh')}
            style={{ height: '37px' }}
            icon={<FontAwesomeIcon icon={faEye} />}
          ></ButtonCustom>
        </div>
      </div>
    </div>
  )
}

export default FormDocumento
