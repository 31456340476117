import React, { useEffect, useState } from 'react'

import { GoogleMap, Marker, Polygon } from '@react-google-maps/api'
import { Modal } from 'antd'
import { getBoundsNoArray, iconPropsGoogle, tryError } from '../../../services/Afins'
import { GeoJSONFeatureCollection } from '../../../types'
import { Container } from './styled'

type Props = {
  kml: GeoJSONFeatureCollection
  status?: (success: boolean) => void
  malha: GeoJSONFeatureCollection
  updatePolygon?: (data: google.maps.LatLngLiteral[], inside: any[]) => void
  mapContorno?: (map: google.maps.Map) => void
  outMarker?: (data: boolean) => void
}

const MapaUpdateContorno: React.FC<Props> = (props) => {
  const { kml, status, malha, updatePolygon, mapContorno, outMarker } = props

  const [map, setMap] = useState<google.maps.Map>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [markers, setMarkers] = useState<{ lat: number; lng: number; point: number }[]>([])

  const handleMouseUp = () => {
    const polys = polygon
      .getPath()
      .getArray()
      .map((poly) => poly.toJSON())

    disableButtonUndo()

    const inside = alertInside(markers, false)

    updatePolygon?.(polys, inside)
  }

  const alertInside = (position: { lat: number; lng: number; point: number }[], alert = true) => {
    let positionInside = []
    for (let i = 0; i < position.length; i += 1) {
      const m = position[i]

      const inside = google.maps.geometry.poly.containsLocation({ lat: m.lat, lng: m.lng }, polygon)

      if (!inside) {
        positionInside = [...positionInside, m.point]
      }
    }

    if (positionInside.length > 0 && alert) {
      Modal.error({ title: `Pontos fora do talhão ${positionInside.join(', ')}` })
    }

    outMarker?.(positionInside.length > 0)

    return positionInside
  }

  const disableButtonUndo = () => {
    const divMap = document.getElementById('map')

    const allButtons = divMap.querySelectorAll('button')

    for (var i = 0; i < allButtons.length; i += 1) {
      allButtons[i].style.display = 'none'
    }
  }

  useEffect(() => {
    try {
      if (kml.features && kml.features.length > 0) {
        const polygonType = kml.features.filter(
          (rec) => rec.geometry.type === 'LineString' || rec.geometry.type === 'Polygon'
        )

        if (map && polygonType.length > 0) {
          //@ts-ignore
          const coords = polygonType[0].geometry?.coordinates[0].map((item) => {
            return { lat: item[1], lng: item[0] }
          })

          const position = malha.features.map((geo) => {
            return {
              point: geo.properties.NOME,
              lat: Number(geo.geometry.coordinates[1]),
              lng: Number(geo.geometry.coordinates[0]),
            }
          })
          setMarkers(position)

          const bounds = getBoundsNoArray(coords)

          const positions = {
            coords,
            bounds,
          }
          polygon.setPath(positions.coords)
          alertInside(position)

          status?.(true)
        } else {
          Modal.warning({ title: 'Kml não é um polygon' })
          status?.(false)
        }
      }
    } catch (error) {
      tryError(error)
    }
  }, [kml])

  return (
    <Container>
      <GoogleMap
        id='map'
        options={{
          mapTypeId: 'hybrid',
          zoomControl: false,
          mapTypeControl: false,
          streetViewControl: false,
          scaleControl: false,
          fullscreenControl: false,
        }}
        onLoad={(m) => {
          setMap(m)
          mapContorno?.(m)
        }}
        mapContainerStyle={{ width: '100%', height: '90vh' }}
      >
        <Polygon
          onMouseUp={handleMouseUp}
          options={{ fillColor: 'cyan', strokeColor: 'cyan', editable: true }}
          onLoad={setPolygon}
        />
        {markers.map((m) => (
          <Marker
            key={m.point}
            icon={iconPropsGoogle(false)}
            position={m}
            label={{
              text: String(m.point),
              fontWeight: 'bold',
              fontSize: '14px',
              className: `aly-text-ping`,
            }}
          />
        ))}
      </GoogleMap>
    </Container>
  )
}
export default MapaUpdateContorno
