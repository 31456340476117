const dev = {
  API_ENDPOINT_URL: 'https://jsonplaceholder.typicode.com',
}

const prod = {
  API_ENDPOINT_URL: 'https:/.prod.com',
}

const test = {
  API_ENDPOINT_URL: 'https:/.test.com',
}

const getEnv = () => {
  let res
  switch (process.env.NODE_ENV) {
    case 'development':
      res = dev
      break
    case 'production':
      res = prod
      break
    case 'test':
      res = test
      break
    default:
      res = 'undefined'
      break
  }

  return res
}

const env = getEnv()

export default env
