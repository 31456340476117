import { Modal } from 'antd/lib'
import React, { useEffect, useState } from 'react'
import Carregando from '../CustomUi/loading'
import { Link, useHistory } from 'react-router-dom'
import { useLogic } from '../../context/useLogic'
import { ContainerModal } from './styled'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faLayerGroup, faThLarge } from '@fortawesome/free-solid-svg-icons'

const ModalEscolha: React.FC = () => {
  const [abrirModal, setAbriModal] = useState(true)
  const history = useHistory()

  useEffect(() => {
    if (!abrirModal) {
      history.push('/home')
    }
  }, [abrirModal])
  

  return (
    <div>
      <Modal
        open={abrirModal}
        onCancel={() => setAbriModal(false)}
        closeIcon={false}
        footer={null}
        destroyOnClose
      >
        <ContainerModal>
          <div style={{display: 'flex', paddingLeft: '10px'}}>
            <h3
              style={{
                margin: 0,
                padding: 0,
                fontWeight: '700',
                fontSize: '16px',
              }}
            >Selecione um agrupamento</h3>
          </div>
          <Link className='campo-link' to='/agrupamento-propriedade'>
            <FontAwesomeIcon className='icone-agrupamento' icon={faLayerGroup} />
            <div>
              <h3
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Agrupamento de propriedades
              </h3>
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: '400',
                  fontSize: '12px',
                }}
              >
                Combine várias propriedades em um agrupamento para otimizar atividades e simplificar o gerenciamento
              </h4>
            </div>
          </Link>
          <Link className='campo-link' to='/talhao/grupotalhaoes'>
            <FontAwesomeIcon className='icone-agrupamento' icon={faThLarge} />
            <div>
              <h3
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: '600',
                  fontSize: '14px',
                }}
              >
                Agrupamento de talhões
              </h3>
              <h4
                style={{
                  margin: 0,
                  padding: 0,
                  fontWeight: '400',
                  fontSize: '12px',
                }}
              >
                Organize e gerencie múltiplos talhões em um agrupamento para facilitar o monitoramento de lavouras e agilizar tomadas de decisões
              </h4>
            </div>
          </Link>
        </ContainerModal>
      </Modal>

      <Carregando
        justifyContent='center'
        height={720}
        size={'large'}
        legenda='Esperando a escolha do agrupamento...'
        animation
      />
    </div>
  )
}

export default ModalEscolha
