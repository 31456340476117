import { Empty, Tag } from 'antd'
import { orderBy } from 'lodash'
import { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Link, useHistory } from 'react-router-dom'
import { faArrowRightLong, faHistory, faList, faXmark } from '@fortawesome/free-solid-svg-icons'

//ASSETS
import logoTTivos from '../../../../../assets/img/logo.png'

//APP
import { gerarKey } from '../../../../../utils';
import { findClient, firstAndLastName, saudacao, saveHistory } from './meta';

//STYLES
import { CardHistory, ContainerStart, TitleModal } from './style'

//COMPONENTS
import Input from '../../../../../Components/CustomUi/input'
import ButtonCustom from '../../../../../Components/ButtonCustom'

//TYPES
type Client = {
  acesso: string
  cod: number
  cod_conta: number
  conta_nome: string
  logo: string
  nome: string
  temAcesso?: boolean
}

//INTERFACE
interface Props {
  list_itens: Client[]
  setAcesso: React.Dispatch<React.SetStateAction<string>>
  setLoggedInAs: React.Dispatch<React.SetStateAction<any>>
  setCodCliente: React.Dispatch<React.SetStateAction<any>>
  setDataConta: React.Dispatch<React.SetStateAction<any>>
  setTypeAccess: React.Dispatch<React.SetStateAction<any>>
  username: string
}

export const Start: React.FC<Props> = ({ list_itens = [], setAcesso, setLoggedInAs, setCodCliente, 
    setDataConta, setTypeAccess, username }) => {

    //CONST
    const history = useHistory();
    const recents = JSON.parse(localStorage.getItem('access_history')) || [];
    const last_access = JSON.parse(localStorage.getItem('last_access')) || []
    const get_username: string[] = String(username)?.split(' ');
    const welcome_username: string = `${get_username?.[0]} ${get_username?.[1] ?? ''}`;

  //STATES
  const [search, setSearch] = useState<string>('')
  const [show_recents, setShow_recents] = useState<boolean>(true)

  function selectClient(data: any) {
    saveHistory(data)

    localStorage.setItem('tipo-acesso', data.acesso)
    localStorage.setItem('nome-cliente', data.nome)

    if (data.acesso === 'conta') {
      localStorage.setItem('conta', data.cod)
      localStorage.setItem('cod-cliente', '')

      setAcesso('conta')
      setLoggedInAs({
        type: 'CONTA',
        codCliente: null,
        codConta: data.cod,
      })

      setCodCliente(null)
    } else {
      localStorage.setItem('conta', data.cod_conta)
      localStorage.setItem('cod-cliente', data.cod)

      setCodCliente(data.cod)
      setAcesso('cliente')
      setLoggedInAs({
        type: 'CLIENTE',
        codCliente: data.cod,
        codConta: null,
      })
    }

    history.push('/app/home')
  }

  function selectAccount(data: any) {
    saveHistory(data)
    setDataConta([data])

    localStorage.setItem('tipo-acesso', data.acesso)
    localStorage.setItem('nome-cliente', data.nome)

    if (data.acesso === 'conta') {
      localStorage.setItem('conta', data.cod)
      localStorage.setItem('cod-cliente', '')

      setTypeAccess('conta')
      setAcesso('conta')
      setLoggedInAs({
        type: 'CONTA',
        codCliente: null,
        codConta: data.cod,
      })
    } else {
      localStorage.setItem('conta', data.cod_conta)
      localStorage.setItem('cod-cliente', data.cod)

      setTypeAccess('cliente')
      setAcesso('cliente')
      setLoggedInAs({
        type: 'CLIENTE',
        codCliente: data.cod,
        codConta: null,
      })
    }

        history.push('/app/home')
        // message.destroy()
    }
    
    return (
        <ContainerStart>
            <img src={logoTTivos} alt='TTivos' className='ContainerStart__logo'/>

            <div className='ContainerStart__welcome'>
            <h2 className='CS__welcome__greeting'>{saudacao()} <span>{firstAndLastName(welcome_username)?.toLowerCase()}</span>!</h2>
                <p className='CS__welcome__instruction'>Selecione uma consultoria ou cliente para começar:</p>
            </div>

      <div
        className='ContainerStart__recents'
        style={{ display: !show_recents || (recents ?? []).length === 0 ? 'none' : undefined }}
      >
        <TitleModal>
          <FontAwesomeIcon icon={faHistory} /> Recentes
        </TitleModal>

        <ButtonCustom
          onClick={() => {
            setShow_recents(false)
          }}
          icon={<FontAwesomeIcon icon={faXmark} />}
          style={{ position: 'absolute', right: 0, top: 4 }}
          type='tertiary'
          danger
        />

                <div className='CS__recents__history'>
                    {((recents ?? []).reverse()).map((item, index)=> {
                        let name_short = (item?.nome)?.split(" ")
                        return (
                            <CardHistory onClick={item?.acesso==='cliente' ? ()=>{selectClient(item)} : ()=>{selectAccount(item)}} key={gerarKey(index)}>
                                <div className='CardHistory__avatar' data-client={item?.acesso==='cliente'} data-logo={!!(item?.logo)}>
                                    {!!(item?.logo)
                                        ? <img src={item?.logo} className='CardHistory__avatar__img' alt={`Logo ${item?.nome}`} />
                                        : <>{item?.nome?.[0]}</>
                                    }
                                </div>
                                <div className='CardHistory__info'>
                                    {name_short?.[0]} {name_short?.[1]}
                                    <span>{item?.acesso==='cliente' ? 'Cliente' : 'Consultoria'}</span>
                                </div>
                            </CardHistory>
                        )
                    })}
                </div>
            </div>

      <div
        className='ContainerStart__list'
        style={{ opacity: (recents ?? []).length > 0 && show_recents ? 1 : 1 }}
      >
        <TitleModal>
          <FontAwesomeIcon icon={faList} /> Clientes e consultorias
        </TitleModal>

                <Input
                    placeholder='buscar cliente ou consultoria'
                    value={search}
                    onChange={setSearch}
                />
                
                <div className='ContainerStart__list__box' style={{maxHeight: (!show_recents || (recents??[]).length===0) ? 'calc(100vh - 400px)' : undefined}}>
                    {orderBy((list_itens || []), ['nome'], ['asc'])?.filter(findClient(search)).map((item, index)=> {
                        return (
                            <Link
                                onClick={item?.acesso==='cliente' ? ()=>{selectClient(item)} : ()=>{selectAccount(item)}} 
                                to="/home" 
                                className='CS__list__box__option'
                                key={gerarKey(index)}
                            >
                                <div className='CS__list__box__option__icon' data-client={item?.acesso==='cliente'} data-logo={!!(item?.logo)}>
                                    
                                    {!!(item?.logo)
                                        ? <img src={item?.logo} className='CS__list__box__option__icon__img' alt={`Logo ${item?.nome}`} />
                                        : <>{item?.nome?.[0]}</>
                                    }
                                    
                                </div>
                                <div className='CS__list__box__option__info'>
                                    {item?.nome}
                                    <span>{item?.acesso==='cliente' ? 'Cliente' : 'Consultoria'}</span>
                                </div>
                                <div className='CS__list__box__option__tag'>
                                    <FontAwesomeIcon icon={faArrowRightLong} className='CS__list__box__option__tag__arrow' />

                                    {last_access.cod === item.cod &&(
                                        <Tag bordered={false} color='green' className='CS__list__box__option__tag__tag' >
                                            recente
                                        </Tag>
                                    )}
                                   
                                </div>
                            </Link>
                        )
                    })}
                    
                    {((list_itens??[])?.filter(findClient(search))).length === 0 &&(
                        <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<span><b>'{search}'</b> não foi encontrado!</span>} />
                    )}
                    
                </div>
            </div>

        </ContainerStart>
    )
}
