import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { tryError } from '../../Afins'
import { api } from '../../api'

export interface TypesTipoConsultor {
  itens: ItensTipoConsultorTypes[]
}

export interface ItensTipoConsultorTypes {
  cod?: number
  cod_conta?: number
  cod_usuario?: number
  tipo?: number
  usuario_nome: string
  tipo_nome: string
  usuario: [
    {
      cod?: number
      nome: string
      email: string
    }
  ]
}

type TypesQuery = {
  cod?: number
  codConta?: number
  codCliente?: number
  embed?: 'permissoes'
}

const url = '/usuario-tipo-consultor'

export const getTipoConsultor = async (params: TypesQuery) => {
  try {
    const { conta, codCliente } = localConfig()
    const { codConta = conta } = params
    const resp: AxiosResponse<TypesTipoConsultor> = await api.get(url, {
      params: {
        codConta,
        ...params,
        codCliente,
        pagination: -1,
      },
    })

    return resp
  } catch (error) {
    tryError(error)
  }
}

export const postTipoConsultor = async (data) => {
  try {
    const { codCliente, conta } = localConfig()
    const resp: AxiosResponse<TypesTipoConsultor> = await api.post(url, data, {
      params: {
        codCliente,
        codConta: conta,
      },
    })

    return resp
  } catch (error) {
    tryError(error)
  }
}

export const delTipoConsultor = async (cod) => {
  try {
    const { codCliente, conta } = localConfig()
    const resp: AxiosResponse<TypesTipoConsultor> = await api.delete(`${url}/${cod}`, {
      params: {
        codConta: conta,
        codCliente,
      },
    })
    return resp
  } catch (error) {
    tryError(error)
  }
}
