import L from 'leaflet';
import shp from 'shpjs';
import dayjs, { Dayjs } from 'dayjs';
import ReactToPrint from 'react-to-print';
import SlowOverlayWorker from 'worker-loader!./slowOverlay.worker';
import { UploadChangeParam } from 'antd/lib/upload';
import { capitalize, groupBy, orderBy, uniq } from 'lodash';
import { Suspense, lazy, useEffect, useState } from 'react';
import { GoogleMap, GroundOverlay, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { MinusOutlined, PlusOutlined, PrinterOutlined, UserOutlined } from '@ant-design/icons';
import { Avatar, Button, Descriptions, Drawer, Form, Modal, Slider, Space, Spin, Switch, message} from 'antd';

//APP
import { useLogic } from './useLogic';
import { useAccess } from '../../../context/useAccess';
import { DefaultMap } from '../../DefaultMap';
import { OsItensTypes } from '../../../services/Laboratorio';
import { PropriedadeItens } from '../../../services/Propriedades';
import { ItensColetaTrajetoTypes } from '../../../services/coletaTrajeto';
import { GeoJSONFeatureCollection } from '../../../types';
import { ItensColetaUsuariosTypes } from '../../../services/coletaUsuarios';
import { ItensMapaFertilidadeTypes } from '../../../services/Laboratorio/osResultado';
import { ItensElementosTypes, getElementos } from '../../../services/Elementos';
import { ItensConfigConsultoriaTypes, getConfigBook } from '../../../services/configBook';
import { geraOverlayView, getBoundsNoArray, tryError } from '../../../services/Afins';

//ASSETS
import pin from '../../../assets/svg/marker.svg';
import pinSelect from '../../../assets/svg/markerSelect.svg';

//STYLES
import { ZoomControl } from './DrawerPrint/styles';
import { BoxHeader, Container } from './styles';

//COMPONENTS
import SideBar from '../../SideBar';
import Definicao from './DrawerPrint/components/definicao';
import { gerarKey } from '../../../utils';

//LAZY COMPONENTS
const CardLegenda = lazy(() => import('./components/cardLegenda/card_legenda'));
const PainelFiltro = lazy(() => import('./components/painelFiltro'));
const DrawerPrintMapaFertilidade = lazy(() => import('./DrawerPrint/'));

//PROPS
interface Props {
  open: boolean
  onClose?: (data: boolean) => void
  data: {
    talhaoNome?: string
    areaTalhao?: string
    talhao: OsItensTypes['talhao_kml']
    os: PropOrdem[]
    propriedade: PropriedadeItens
    data: string | Dayjs
    percurso: { [key: string]: ItensColetaTrajetoTypes[] }
    coletaUsuario: ItensColetaUsuariosTypes[],
    nome_cliente?: string
    calcario_propriedade?: string
  }
}

interface PropOrdem extends ItensMapaFertilidadeTypes {
  ordem?: number
}

const OsResultado: React.FC<Props> = (props) => {
  const { open, data, onClose } = props
  const { consultoria, nameCliente } = useAccess()

  //STATES
  const [map, setMap] = useState<google.maps.Map>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [totais, setTotais] = useState({ min: 0, med: 0, max: 0 })
  const [visibleMarker, setVisibleMarker] = useState(true)
  const [reverseScale, setReverseScale] = useState(false)
  const [groundOverlay, setGroundOverlay] = useState<google.maps.GroundOverlay>()
  const [dadosNovos, setDadosNovos] = useState([])
  const [cores, setCores] = useState([])
  const [close, setClose] = useState(false)
  const [dataElemento, setDataElemento] = useState<ItensElementosTypes[]>([])
  const [typeKricagem, setTypeKricagem] = useState(1)
  const [gridOverlay, setGridOverlay] = useState<{ grid: []; lats: any; longs: any; colors: any }>({
    colors: [],
    lats: '',
    longs: '',
    grid: [],
  })
  const [rangeZoom, setRangeZoom] = useState(0)
  const [exibeTerreno, setExibeTerreno] = useState(true)
  const [markerSelect, setMarkerSelect] = useState(null)
  const [bookLoading, setBookLoading] = useState(false)
  const [dataConfigMapaFertilidade, setDataConfigMapaFertilidade] =
    useState<ItensConfigConsultoriaTypes>({} as ItensConfigConsultoriaTypes)
  const [loading, setLoading] = useState(false)
  const [markerSelectPosition, setMarkerSelectPosition] = useState<{
    marker: google.maps.LatLngLiteral
    polyline?: google.maps.LatLngLiteral[]
  }>({
    marker: { lat: 0, lng: 0 },
    polyline: [],
  })
  const [defaultBounds, setDefaultBounds] = useState<L.LatLngBounds>()
  const [mapPercurso, setMapPercurso] = useState<google.maps.Map>()
  const iconProps = (select = true) => {
    return { url: select ? pinSelect : pin, scaledSize: new google.maps.Size(40, 50) }
  }
  const [openDrawerFertilidade, setOpenDrawerFertilidade] = useState(false)
  const [dataOverlayView, setDataOverlayView] = useState({
    key: null,
    colors: [],
    bounds: {
      north: 0,
      south: 0,
      east: 0,
      west: 0,
    },
    url: null,
  })
  const [markers, setMarkers] = useState<
    { lat: number; lng: number; ponto?: string; valorDistribuido: string; valor: string }[]
  >([])
  const [lista_profundidade, setLista_Profundidade] = useState<{
    [key: string]: ItensMapaFertilidadeTypes[]
  }>({})
  const [profundidadeSelect, setProfundidadeSelect] = useState(null)
  const [elemento, setElemento] = useState<{ [key: string]: ItensMapaFertilidadeTypes[] }>({})
  const [optionsElements, setOptionsElements] = useState<
    { label: string; value: string; ordem: number }[]
  >([])
  const [configBook, setConfigBook] = useState<
    ItensConfigConsultoriaTypes['mapa_fertilidade']['elementos']
  >([])
  const [elementoSelect, setElementoSelect] = useState<ItensMapaFertilidadeTypes>(
    {} as ItensMapaFertilidadeTypes
  )
  const [dataUserColetaPonto, setDataUserColetaPonto] = useState<ItensColetaUsuariosTypes>(
    {} as ItensColetaUsuariosTypes
  )
  const [itemElement, setItemElement] = useState<ItensElementosTypes>({} as ItensElementosTypes)
  const [loadingShape, setLoadingShape] = useState(false)
  const [gerarMap, setGerarMap] = useState(false)
  //STATES -FORMULÁRIO
  const [campo_profundidade, setCampo_profundidade] = useState<string>('');
  const [campo_elemento, setCampo_elemento] = useState<number | null>(null);
  const [tabAtualSelecionada, setTabAtualSelecionada] = useState<'Fertilidade'|'Indicadores'|'Prescrições'>('Fertilidade');
  //STATES - INFO DO CLIENTE
  const [nome_cliente_painel, setNome_cliente_painel] = useState('');
  //STATES - QUALIDADE DO MAPA DE KRIGAGEM
  const [descricao_definicao_book, setDescricao_definicao_book] = useState('Definição padrão')
  const [qualidade_definicao_book, setQualidade_definicao_book] = useState(0.0001);
  const [re_render_book, setRe_render_book] = useState(true);

  const [form] = Form.useForm()

  const { generateColors } = useLogic()

  const listElements = async () => {
    const resp = await getElementos({})

    const dados = resp.data.itens

    dados.map((rec) => {
      const existe = configBook.find((item) => Number(item.key) === Number(rec.cod))

      if (existe) {
        rec.ordem = Number(existe.ordem)
      } else {
        rec.ordem = null
      }
    })

    setDataElemento(orderBy(dados, 'ordem'))
    // console.log('DADOS ELEMENTO:', dados)
  }

  const listConfigBook = async () => {
    const resp = await getConfigBook()
    // console.log('CONFIG BOOK: ', resp.data.itens[0].mapa_fertilidade.elementos)

    setConfigBook(resp?.data?.itens?.[0]?.mapa_fertilidade.configuracoes ?? [])
  }

  const handleChangeProfundidade = (nome_profundidade: string) => {

    let dados = lista_profundidade[nome_profundidade]
    setProfundidadeSelect(nome_profundidade)
    form.resetFields(['elemento'])
    dataOverlayView.key = null
    setTotais({ min: 0, med: 0, max: 0 })
    setGerarMap(false)
    setMarkerSelect(null)
    setMarkers([])

    if (dados?.length > 0) {
      dados.map((rec) => {
        const existe = configBook.find((item) => Number(item.key) === Number(rec.cod_elemento))

        if (existe) {
          rec.ordem = existe.ordem
        } else {
          rec.ordem = null
        }
        return rec
      })

      function AgruparElementosPrecricao() {
        let result = dados.reduce((acc, curr) => {
          const existingEntry = acc.find(entry => entry.cod_elemento === curr.cod_elemento && entry.label === `${curr.elemento} ${curr.profundidade}`);
          if (existingEntry) {
            return acc;
          }
          if (curr.cod_elemento=== 30 && curr.profundidade === '20-40') {
            return acc;
          }
          const newEntry = {
            label: curr.elemento_descricao==='Calcário' ? `${curr.elemento_descricao} ${String(curr.profundidade).replace('-',' a ')} cm` : `${curr.elemento_descricao}` ,
            value: String(curr.cod_elemento), 
            cod_elemento: curr.cod_elemento,
            cod_prescricao: `${curr.cod_elemento}-${curr.profundidade}`,
            ordem: curr.ordem,
            latitude: curr.latitude,
            longitude: curr.longitude,
            ponto: curr.ponto,
            valor: curr.valor,
            valor_distribuido: curr.valor_distribuido,
            profundidade: curr.profundidade
          };
          return [...acc, newEntry];
        }, []);

        return groupBy(result, 'label')
      }

      const end = tabAtualSelecionada==='Prescrições' ? AgruparElementosPrecricao() :  groupBy(dados, 'cod_elemento');

      const elements = Object?.entries(end)?.map(([key, value]) => {
        return tabAtualSelecionada==='Prescrições' 
          ?  { label: `${value?.[0]?.label}`, value: value?.[0]?.cod_prescricao, cod: value?.[0]?.value, ordem: value?.[0]?.ordem, profundidade: value?.[0]?.profundidade}
          :  { label: `${value[0].elemento_descricao}`, value: key, ordem: value[0].ordem }
      })

      // console.log("elements: ", elements)
      setOptionsElements(orderBy(elements, (or) => Number(or.ordem)))

      setElemento(tabAtualSelecionada==='Prescrições' ? groupBy(dados, 'cod_elemento') : end)
    }
  }
  const [profundidade_calcario_selecionado, setProfundidade_calcario_selecionado] = useState<any>('')
  /**chamado ao selecionar o elemnento */
  const handleChangeElemento = async (cod: number|string, profundidade_calcario?: string | any) => {
    setProfundidade_calcario_selecionado(profundidade_calcario?.profundidade)
    gerarMapOverlay(cod, profundidade_calcario)
  }


  // console.log('options', optionsElements)

  /**Gera o mapa ao selecioar o elemento */
  const gerarMapOverlay = async (cod: number|string, profundidade_calcario?: any) => {
    // console.log("gerarMapOverlay: ", cod)
    // console.log("ELEMENTOS: ", elemento)
    if (cod) {
      setLoading(true)
      const element = dataElemento.find((rec) => rec.cod === Number(cod))
      const e = dataElemento.find((rec) => rec.cod === 50)
      setItemElement(element)

      setMarkerSelect(null);

      function separarPrescricaoCalcario() {
        
        let dados_calcario = elemento['30'];
        let filtrado_por_profundidade = dados_calcario?.filter((item)=> {
          return item.profundidade === profundidade_calcario?.profundidade;
        })

        return filtrado_por_profundidade;
      };
      // console.log('SEGUNDO ARGUMENTO: ', profundidade_calcario?.profundidade)
      // console.log('TESTE FILTRO CALCÁRIO: ', separarPrescricaoCalcario())

      let dados = (cod === '30' && tabAtualSelecionada==='Prescrições') ? separarPrescricaoCalcario() : elemento[cod]

      if (dados?.length < 3) {
        // const ultimoElemento = dados[dados.length - 1]
        // const elementosFaltando = 3 - dados.length
        dados = [...dados, ...Array(3 - dados?.length).fill(dados[dados?.length - 1])]

        // setDadosNovos([dadosFiltrados])
      }

      // const resultadoDados = dados?.length < 3 ? dadosNovos : dados

      let cods_q_devem_ser_reverso = ['25', '26', '30', '31', '32', '49', '41', '42', '46', '43', '44', '45','7', '18', '28', '50']

      const reverse = cods_q_devem_ser_reverso.indexOf(String(cod)) > -1

      setReverseScale(reverse);

      const { minimo, maximo, media } = dados?.[0]

      setTotais({
        min: Number(minimo),
        med: Number(media),
        max: Number(maximo),
      })

      const pontos = dados.map((rec) => {
        return {
          lat: Number(rec.latitude),
          lng: Number(rec.longitude),
          ponto: String(rec.ponto),
          valor: rec.valor,
          valorDistribuido: rec.valor_distribuido,
        }
      })

      // console.log("pontos: ", pontos)

      const coord = data.talhao.coordenadas.map((rec) => {
        return [Number(rec.longitude), Number(rec.latitude)]
      })

      const values = dados.map((rec) =>
        Number(typeKricagem === 1 ? rec.valor_interpretado : rec.valor_distribuido)
      )

      const points = [
        {
          t: values,
          x: pontos.map((rec) => rec.lng),
          y: pontos.map((rec) => rec.lat),
        },
      ]

      // console.log("points: ", points)

      const colors = uniq(generateColors(values, typeKricagem === 1, !reverse))
      // console.log("colors: ", colors);
      const uniqArrayColors = colors.filter((value, index, self) => {
        return self.indexOf(value) === index
      })

      // console.log('cores', uniqArrayColors)

      setCores(uniqArrayColors)
      const lastColor = colors[colors.length - 1]

      // console.log("colors: ", colors)

      // if (colors.length === 2 || colors.length === 3) {
      colors.push(lastColor)
      // }
      message.loading('Aguarde...', 99999)

      if (colors.length === 1) {
        // @ts-ignore
        setGridOverlay({ colors, grid: ['t'], lats: '', longs: '' })
        setLoading(false)

        message.destroy()
      } else {
        //@ts-ignore
        const webWorker = new SlowOverlayWorker()

        webWorker.onmessage = async (e) => {
          // console.log("EEEEEEEEEEEEEEEEEEE: ", e)
          const { grid, lats, longs, colors } = await e.data
          if (!e.data.error) {
            setGridOverlay({ ...e.data[0] })
            message.destroy()
            webWorker.terminate()
            setGerarMap(true)
          } else {
            webWorker.terminate()
            setLoading(false)
            message.destroy()
            message.error('Erro ao gerar Grid2')
            tryError(e.data.error)
          }
        }
        webWorker.postMessage({ command: 'message-mapa', dados: [{ coord, points, colors }] })
      }

      setMarkers(pontos)
    }
  }
  // console.log("gridOverlay: ", gridOverlay)

  /**função chamada ao alterar o tipo de kricagem [interpretado ou distribuido] */
  const handleChangeTypeKrigagem = async (data: number) => {
    // handleReset()
    setGerarMap(false)
    setTotais({ min: 0, med: 0, max: 0 })
    setTypeKricagem(data)
  }

  /**função ao clicar sobre o ponto */
  const handleClickMarker = (ponto: string) => {
    setMarkerSelect(ponto)

    // const elemento = Number(form.getFieldValue('elemento'))
    // const profundidade = form.getFieldValue('profundidade')

    const elemento = campo_elemento
    const profundidade = campo_profundidade

    function listarApenasCalcario(listagem_elementos: PropOrdem[]) {
      let apenas_calcario = listagem_elementos?.filter((item)=> {
        return item.cod_elemento === 30 && item.profundidade === String(profundidade_calcario_selecionado)
      })

      return apenas_calcario.find((rec) => rec.ponto === Number(ponto))
    }

    const select =
      profundidade !== 'indicadores' && profundidade !== 'prescricoes'
        ? data.os.find(
            (rec) =>
              rec.ponto === Number(ponto) &&
              rec.cod_elemento === elemento &&
              rec.profundidade === profundidade
          )
        : elemento === 30 ? listarApenasCalcario(data.os) : data.os.find((rec) => rec.ponto === Number(ponto) && rec.cod_elemento === elemento)

    if (data.percurso[ponto]) {
      const polyline = data?.percurso?.[ponto]?.map((rec) => {
        return { lat: Number(rec.latitude), lng: Number(rec.longitude) }
      })

      setMarkerSelectPosition({
        marker: { lat: Number(select?.latitude), lng: Number(select?.longitude) },
        polyline: polyline,
      })
    } else {
      setMarkerSelectPosition({
        marker: { lat: Number(select?.latitude), lng: Number(select?.longitude) },
      })
    }
    const bounds = getBoundsNoArray([
      {
        lat: Number(select?.latitude),
        lng: Number(select?.longitude),
      },
    ])
    mapPercurso.fitBounds(bounds)

    const user = data.coletaUsuario.find((rec) => rec.ponto === select.ponto)

    if (user?.cod_pedido) {
      setDataUserColetaPonto(user)
    } else {
      setDataUserColetaPonto({ doc_foto_perfil_url: null, nome: null })
    }

    

    setElementoSelect(select)
  }

  const importMalha = async (data: UploadChangeParam) => {
    if (data.fileList.length > 0) {
      try {
        setLoadingShape(true)
        const newFile = await data.file.originFileObj.arrayBuffer()
        const geojson: GeoJSONFeatureCollection = (await shp(newFile)) as GeoJSONFeatureCollection

        map.data.addGeoJson(geojson)
        setLoadingShape(false)
      } catch (error) {
        Modal.error({
          title: 'Erro',
          content:
            'Arquivo shape inválido! Por favor entre em contato com o suporte técnico para mais informações.',
        })
        setLoadingShape(false)
        tryError(error)
      }
    }
  }

  const onChangeUpload = (info: UploadChangeParam) => {
    if (info.file.status === 'done') {
      importMalha(info)
    }
  }

  const handleClose = () => {
    setClose(true)
    setTimeout(() => {
      setTypeKricagem(1)
      setOpenDrawerFertilidade(false)
      setLista_Profundidade({})
      setElemento({})
      setMarkers([])
      dataOverlayView.key = null
      setMarkerSelect(null)
      setTotais({ med: 0, max: 0, min: 0 })
      setItemElement({} as ItensElementosTypes)
      setVisibleMarker(true)
      setGerarMap(false)
      setClose(false)
    }, 800)
  }
  const cod_prescricoes = new Set([30, 31, 32, 41, 42, 43, 44, 45, 46, 49]);
  const handleProfundidades = () => {
    if (map) {
      message.loading('Aguarde...', 999)
      form.resetFields(['profundidade', 'elemento'])
      
      if (data?.talhao) {
        const coord = data?.talhao?.coordenadas?.map((rec) => {
          return { lat: Number(rec.latitude), lng: Number(rec.longitude) }
        })
        const bound = getBoundsNoArray(coord)
        map.fitBounds(bound, { left: 300, bottom: 50, top: 50 })
        polygon.setPaths(coord)


        const filter = data?.os.filter((rec) =>
          typeKricagem === 1 ? (rec.valor_interpretado !== null || cod_prescricoes.has(rec.cod_elemento) ) : rec.valor_distribuido !== null
        )

        // console.log("FILTER: ", filter)
        const filterORder = orderBy(
          filter.map((rec) => {
            const elemento = configBook.find(
              (item) => Number(item.key) === Number(rec.cod_elemento)
            )
            if (elemento) {
              rec.ordem = elemento.ordem
            } else {
              rec.ordem = null
            }
            return rec
          }),
          (or) => Number(or.ordem)
        )

        bound.getNorthEast().toJSON()

        const lBounds = new L.LatLngBounds(
          [bound.getNorthEast().toJSON().lat, bound.getNorthEast().toJSON().lng],
          [bound.getSouthWest().toJSON().lat, bound.getSouthWest().toJSON().lng]
        )

        setDefaultBounds(lBounds)


        let indicadores_e_precricoes = [30, 31, 32, 49, 41, 42, 46, 43, 44, 45, 33, 34, 28, 38, 50]

        const zeroAVinte = filterORder.filter((rec) => rec.profundidade === '00-20' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
        const vinteAQuarenta = filterORder.filter((rec) => rec.profundidade === '20-40' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
        const micro = filterORder.filter((rec) => rec.profundidade === 'micro' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
        const zeroACinquenta = filterORder.filter((rec) => rec.profundidade === '00-50' && !indicadores_e_precricoes?.includes(rec.cod_elemento) )
        const prescricoes = filterORder.filter(
          (rec) =>
            rec.cod_elemento === 30 ||
            rec.cod_elemento === 31 ||
            rec.cod_elemento === 32 ||
            rec.cod_elemento === 49 ||
            rec.cod_elemento === 41 ||
            rec.cod_elemento === 42 ||
            rec.cod_elemento === 46 ||
            rec.cod_elemento === 43 ||
            rec.cod_elemento === 44 ||
            rec.cod_elemento === 45
        );
        const indicadores = filterORder.filter(
          (rec) =>
            rec.cod_elemento === 33 ||
            rec.cod_elemento === 34 ||
            rec.cod_elemento === 28 ||
            rec.cod_elemento === 38 ||
            rec.cod_elemento === 50
        )
        // console.log("prescricoes: ", prescricoes)


        let grup: { [key: string]: ItensMapaFertilidadeTypes[] } = {}

        if (zeroAVinte.length > 0) {
          grup = { ...grup, '00-20': zeroAVinte }
        }
        if (vinteAQuarenta.length > 0) {
          grup = { ...grup, '20-40': vinteAQuarenta }
        }
        if (micro.length > 0) {
          grup = { ...grup, micro: micro }
        }
        if (zeroACinquenta.length > 0) {
          grup = { ...grup, '00-50': zeroACinquenta }
        }
        if (indicadores.length > 0) {
          grup = { ...grup, indicadores }
        }
        if (prescricoes.length > 0) {
          grup = { ...grup, prescricoes }
        }

        // const grup = groupBy(filter, 'profundidade')
        // console.log("grup: ", grup);

        setLista_Profundidade(grup)
      }
      message.destroy()
    }
  }

  useEffect(() => {
    handleProfundidades()
  }, [data, typeKricagem])

  useEffect(() => {
    if (!open) {
      form.resetFields()
      setTypeKricagem(1)
      setOpenDrawerFertilidade(false)
      setLista_Profundidade({})
      setElemento({})
      setMarkers([])
      dataOverlayView.key = null
      setMarkerSelect(null)
      setTotais({ med: 0, max: 0, min: 0 })
      setItemElement({} as ItensElementosTypes)
      setTabAtualSelecionada('Fertilidade');
    }
  }, [open])

  useEffect(() => {
    const fetch = async () => {
      if (gridOverlay.grid?.length > 0 && gridOverlay) {
        const { grid, colors, lats, longs } = gridOverlay
        let overlay
        if (colors.length > 1) {
          overlay = await geraOverlayView(grid, lats, longs, colors)
        } else {
          overlay = { key: null, url: null, colors }
        }
        setLoading(false)
        // console.log("overlay teste: ", overlay)
        setDataOverlayView(overlay)
      }
    }
    fetch()
  }, [gridOverlay])

  useEffect(() => {
    listElements()
    listConfigBook()
  }, [])

  //LIMPA TODOS OS DADOS AO MUDAR O TIPO DE MAPA (INTERPRETADO/DISTRIBUIDO)
  useEffect(() => {
    // setVisibleMarker(true);
    setMarkers([])
    setDataOverlayView({
      key: null,
      colors: [],
      bounds: {
        north: 0,
        south: 0,
        east: 0,
        west: 0,
      },
      url: null,
    })
    setMarkerSelect(null)
    setGridOverlay({
      colors: [],
      lats: '',
      longs: '',
      grid: [],
    })
  }, [typeKricagem, tabAtualSelecionada])


  function nomeDoArquivoBookPdf() {
    let nome_propriedade = capitalize(String(data?.propriedade?.nome)?.toLowerCase());
    let nome_talhao = capitalize(String(data?.talhaoNome)?.toLowerCase());
    let ano = data?.data ? `- ${String(data?.data)?.split('-')?.[0]}` : '';
    return `${nome_propriedade} - ${nome_talhao} ${ano} - book completo`
  }


  //LOCAL
  const tipoAcesso = localStorage.getItem('tipo-acesso');
  const nomeBookApp = JSON.parse(localStorage.getItem('bookApp'));
  const nomeClienteLocal = localStorage.getItem('nome-cliente')

  const filtrarDadosLocal = () => {
    if (nomeBookApp == 1) {
      setNome_cliente_painel(localStorage.getItem('nome-cliente'));
    } else {
      const licenciadoPara = nomeClienteLocal;
      // const licenciadoPara = tipoAcesso === 'cliente' ? nomeClienteLocal : nomeClienteLocal;
      setNome_cliente_painel(licenciadoPara);
    }
  }

  useEffect(() => {
    filtrarDadosLocal()
  }, [])


  function remontarBook() {
    setRe_render_book(false);

    setTimeout(() => {
      setRe_render_book(true)
    }, 250);
  }

  function definicaoDaKrigagem(): number {
    if (Number(data?.areaTalhao) < 70) {
      return 0.00003
    }
    if (Number(data?.areaTalhao) < 100) {
      return 0.00004
    }
    if (Number(data?.areaTalhao) < 200) {
      return 0.00005
    }
    if (Number(data?.areaTalhao) < 300) {
      return 0.0001
    }
    if (Number(data?.areaTalhao) < 400) {
      return 0.0002
    }
    if (Number(data?.areaTalhao) > 400) {
      return 0.0003
    }
  }

  useEffect(() => {
    setQualidade_definicao_book(definicaoDaKrigagem)
  }, [data?.areaTalhao])
  
  /**Ronaldo pediu para esconder valor do ponto de `mapa de decisão`, `Fonte de enxofre`
   * , `adubação de sistema`, 
   * 
   * pois o valor é igual a nota interpretado
   */
  function esconderValorDoPonto(cod_elemento_check: number): boolean {
    let esconder_valor_elemento = [33, 34, 38];
    return esconder_valor_elemento?.indexOf(cod_elemento_check) === -1
  }

  return (
    <Container>
      <DefaultMap
        onMapLoad={setMap}
        open={open}
        escClose={!loading}
        onClose={() => onClose(false)}
        mapOptions={{ isFractionalZoomEnabled: true }}
      >
        <BoxHeader>
          
          <Suspense fallback={<>carregando painel...</>}>
            <PainelFiltro
              nomeTalhao={String(data?.talhaoNome)?.toLowerCase()}
              nomeFazenda={String(data?.propriedade?.nome)?.toLowerCase()}
              nomeCliente={nameCliente ? String(nameCliente) : (data?.nome_cliente ?? '-----')}
              areaTalhao={String(data?.areaTalhao) + ' ha'}
              dataColeta={ data?.data !== null ? dayjs(data?.data).format('DD/MM/YYYY') : 'Não informado' }
              setCampo_profundidade={setCampo_profundidade}
              setCampo_elemento={setCampo_elemento}
              onChangeKrigagem={handleChangeTypeKrigagem}
              handleChangeProfundidade={handleChangeProfundidade}
              lista_profundidade={lista_profundidade}
              lista_elementos={optionsElements}
              handleChangeElemento={handleChangeElemento}
              loading={loading}
              onClose={onClose}
              bookLoading={bookLoading}
              setOpenDrawerFertilidade={setOpenDrawerFertilidade}
              handleProfundidades={handleProfundidades}
              typeKricagem={typeKricagem}
              setTabAtualSelecionada={setTabAtualSelecionada}
              resertarCores={setCores}
              configBook={configBook}
            />
          </Suspense>

          <Suspense fallback={<>carregando legenda...</>}>
            <CardLegenda
              colors={cores}
              dados={totais}
              onChange={(sw) => setVisibleMarker(!sw)}
              gerarMap={gerarMap}
              checked={visibleMarker}
              unidade={itemElement.unidade}
              tabSelecionada={tabAtualSelecionada}
              cod_elemento={campo_elemento}
              loading={loading}
              bookLoading={bookLoading}
              handleProfundidades={handleProfundidades}
              setOpenDrawerFertilidade={setOpenDrawerFertilidade}
            ></CardLegenda>
          </Suspense>
        </BoxHeader>

        <Polygon options={{ fillOpacity: 0, strokeColor: '#fff' }} onLoad={setPolygon} />
        {markers.map((rec, index) => (
          // @ts-ignore
          <Marker
            key={gerarKey(index)}
            opacity={visibleMarker ? 0 : 1}
            onClick={() => handleClickMarker(rec.ponto)}
            icon={iconProps(markerSelect === rec.ponto)}
            position={{ lat: rec.lat, lng: rec.lng }}
            label={{
              text: rec.ponto,
              fontWeight: 'bold',
              fontSize: '14px',
              className: `aly-text-ping`,
            }}
            // title={'%' + rec.valorDistribuido + ' / v' + rec.valor}
          />
        ))}

        {/* EXIBE AS CORES NO MAPA */}
        {/* @ts-ignore */}
        {dataOverlayView.key !== null ? (
          <GroundOverlay onLoad={setGroundOverlay} {...dataOverlayView} />
        ) : null}

        {dataOverlayView?.colors?.length === 1 && (
          <Polygon
            path={polygon.getPath().getArray()}
            options={{ fillColor: dataOverlayView.colors[0], fillOpacity: 1, strokeOpacity: 0 }}
          />
        )}

        <SideBar
          title={`Ponto ${elementoSelect?.ponto}`}
          open={markerSelect !== null}
          placement='right'
          footer={
            <Button onClick={() => setMarkerSelect(null)} danger type='primary'>
              Fechar
            </Button>
          }
        >
          <Space style={{ width: '100%', height: '100%' }} direction='vertical' size={20}>
            <div
              style={{
                overflow: 'hidden',
                display: dataUserColetaPonto.nome === null ? 'none' : 'flex',
                backgroundColor: '#FAFAFA',
                width: '100%',
                justifyContent: 'start',
                gap: '20px',
                flexDirection: 'row',
                alignItems: 'center',
                border: '1px solid #EBEBEB',
                borderRadius: 10,
                padding: 5,
              }}
            >
              <Avatar
                icon={<UserOutlined />}
                shape='circle'
                src={dataUserColetaPonto.doc_foto_perfil_url}
                size={50}
              />
              <small style={{ fontWeight: 700 }}>{dataUserColetaPonto.nome}</small>
            </div>
            <Descriptions
              size='small'
              layout='horizontal'
              bordered
              column={1}
              labelStyle={{
                fontWeight: 'bold',
              }}
            >
              <Descriptions.Item label='Latitude'>
                {Number(elementoSelect?.latitude).toFixed(13)}
              </Descriptions.Item>
              <Descriptions.Item label='Longitude'>
                {Number(elementoSelect?.longitude).toFixed(13)}
              </Descriptions.Item>
              {process.env.NODE_ENV !== 'production' && (
                <Descriptions.Item label={typeKricagem ? 'Interpretado' : 'Distribuído'}>
                  {typeKricagem
                    ? elementoSelect?.valor_interpretado
                    : elementoSelect?.valor_distribuido}
                </Descriptions.Item>
              )}
              {esconderValorDoPonto(elementoSelect?.cod_elemento) && (
                <Descriptions.Item label='Valor'>
                  {elementoSelect?.valor} {itemElement?.unidade}
                </Descriptions.Item>
              )}
              
            </Descriptions>
            <div style={{ width: '100%' }}>
              <div
                style={{
                  width: '100%',
                  height: 50,
                  padding: 10,
                  paddingTop: 5,
                  fontWeight: 700,
                  fontSize: 14,
                  marginBottom: -20,
                  borderRadius: 10,
                  border: '1px solid #EBEBEB',
                  backgroundColor: '#FAFAFA',
                }}
              >
                Trajeto
              </div>
              <GoogleMap
                options={{
                  zoomControl: false,
                  streetViewControl: false,
                  mapTypeControl: false,
                  fullscreenControl: false,
                  draggable: false,
                  disableDoubleClickZoom: true,
                  disableDefaultUI: true,
                  mapTypeId: 'hybrid',
                }}
                onLoad={setMapPercurso}
                mapContainerStyle={{ width: '100%', height: 250, borderRadius: '10px' }}
              >
                <Marker
                  position={markerSelectPosition.marker}
                  icon={iconProps(true)}
                  label={{
                    text: markerSelect,
                    fontWeight: 'bold',
                    fontSize: '14px',
                    className: `aly-text-ping`,
                  }}
                />
                <Polyline
                  path={markerSelectPosition.polyline}
                  options={{ strokeColor: 'orange' }}
                />
              </GoogleMap>
            </div>
          </Space>
        </SideBar>
      </DefaultMap>
      {/* <a href='#topo-documento' id="force-voltar-topo">teste</a> */}
      <Suspense fallback={<Spin />}>
        <Drawer
          width={'228mm'}
          placement='right'
          onClose={() => {
            (document?.getElementById('force-voltar-topo'))?.click()
            setOpenDrawerFertilidade(false)
            setBookLoading(false);
          }}
          bodyStyle={{ overflow: 'hidden' }}
          open={openDrawerFertilidade}
          extra={
            <Space>
              <ZoomControl hidden>
                <div className='box'>
                  <Button
                    onClick={() => setRangeZoom(rangeZoom - 0.25)}
                    size='small'
                    shape='round'
                    type='primary'
                    icon={<MinusOutlined />}
                  />
                  <Slider
                    style={{ width: 250 }}
                    max={18}
                    min={3}
                    step={0.25}
                    value={rangeZoom}
                    onChange={(data) => setRangeZoom(data)}
                  />
                  <Button
                    onClick={() => setRangeZoom(rangeZoom + 0.25)}
                    size='small'
                    type='primary'
                    shape='round'
                    icon={<PlusOutlined />}
                  />
                  <span className='zoom-label'>Zoom dos mapas</span>
                </div>
              </ZoomControl>
              <div
                style={{
                  display: 'flex',
                  flexDirection: 'row',
                  gap: '10px',
                  alignItems: 'center',
                  fontSize: '16px',
                }}
              >

                <Definicao 
                  descricao_atual={descricao_definicao_book}
                  valor_atual={qualidade_definicao_book}
                  onChangeDescricao={setDescricao_definicao_book}
                  onChangeValor={setQualidade_definicao_book}
                  remontarBook={remontarBook}
                />

                Exibir terreno:
                <Switch
                  checkedChildren='Sim'
                  onChange={(data) => setExibeTerreno(data)}
                  unCheckedChildren='Não'
                  checked={exibeTerreno}
                />
              </div>
              
              <ReactToPrint
              documentTitle={nomeDoArquivoBookPdf() ?? 'book completo'}
              // documentTitle={`${data?.propriedade?.nome} - ${data?.talhaoNome} - ${!!data?.data ? String(data?.data)?.split('-')?.[0] : ''} - book completo`}
                trigger={() => (
                  <Button type='primary' title='Imprimir' icon={<PrinterOutlined />}>
                    Imprimir
                  </Button>
                )}
                content={() => document.getElementById('mapa-fertilidade')}
              />
            </Space>
          }
        >
          <div
            id='divScroll'
            style={{ height: '90vh', overflow: 'auto', scrollBehavior: 'smooth' }}
          >

            {(openDrawerFertilidade && re_render_book) &&(
              <DrawerPrintMapaFertilidade
                openBook={openDrawerFertilidade}
                idPrint='mapa-fertilidade'
                exibeTerreno={exibeTerreno}
                defaultBounds={defaultBounds}
                nameCliente={data?.nome_cliente}
                // nameCliente={nameCliente ?? data?.nome_cliente}
                typeKricagem={typeKricagem === 1}
                isLoading={setBookLoading}
                consultoria={consultoria}
                dataColeta={data?.data}
                talhao={data?.talhao}
                talhaoNome={data?.talhaoNome}
                areaTalhao={data?.areaTalhao}
                propriedade={data?.propriedade}
                dataFertilidade={lista_profundidade}
                elementosData={dataElemento}
                cod_prescricoes={cod_prescricoes}
                cods_q_devem_ser_reverso={[7, 18, 25, 26, 28, 30, 31, 32, 41, 42, 43, 44, 45, 46, 49, 50]}
                setNome_cliente_painel={setNome_cliente_painel}
                nome_calcario_propriedade={data?.calcario_propriedade}
                qualidade_definicao_book={qualidade_definicao_book}
              />
            )}
             
          </div>
        </Drawer>
      </Suspense>
    </Container>
  )
}

export default OsResultado

//PRECIÇÃO É SMEPRE INVERTIDO
//PRESCRIÇÃO É SEMPRE DISTRIBUIDO
