import { Select, Space, Transfer } from 'antd'
import { TransferDirection } from 'antd/lib/transfer'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'
import { useLogic } from '../../../../context/useLogic'
import { tryError } from '../../../../services/Afins'
import { EquipeItens, TypesEquipe, getEquipe } from '../../../../services/Equipe'
import {
  TypesTipoEquipeLiderGerenciar,
  delTipoEquipeLiderGerenciar,
  getTipoEquipeLiderGerenciar,
  postTipoEquipeLiderGerenciar,
} from '../../../../services/consultoriaAgricola/equipLiderGerenciar'
import {
  TypesTipoLiderEquipe,
  getTipoLiderEquipe,
} from '../../../../services/consultoriaAgricola/tipoLiderEquipe'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

import { Container } from './styles'

const EquipeLider: React.FC = () => {
  const { itens, responsive } = useLogic()
  const [_visibleDrawer, setVisibleDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataTechnician, setDataTechnician] = useState<EquipeItens[]>([])
  const [dataLideres, setDataLideres] = useState<any[]>([])
  const [dataAt, setDataAt] = useState<any[]>([])
  const [valueAt, setValueAt] = useState(null)
  const [lisTechnician, setLisTechnician] = useState([])
  const [removeTechnician, setRemoveTechnician] = useState([])
  const [consultorSelect, setLiderSelect] = useState<number>()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>({} as ItensPerUserLogged)
  const [consultor, setLider] = useState<boolean>(true)

  const listLideres = async () => {
    setLoading(true)
    const resp: AxiosResponse<TypesTipoLiderEquipe> = await getTipoLiderEquipe({})
    const dados = resp.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })
    const isLider = dados.filter((item) => item.value === itens[0].cod)
    if (permissions?.acesso === 1 && isLider.length > 0) {
      listTechnicianAvailable(itens[0].cod)
      setLider(true)
    } else {
      setDataLideres(dados)
      setLider(false)
    }
    setLoading(false)
  }

  const listAt = (at, codLider) => {
    let dados = []
    for (let i = 0; i < at.length; i += 1) {
      const item = at[i]

      if (item.cod_consultor === null) {
        dados = [
          ...dados,
          {
            label: `${item.usuario_nome}`,
            value: item.cod_func,
          },
        ]
      } else if (item.cod_consultor === codLider) {
        dados = [
          ...dados,
          {
            label: `${item.usuario_nome}`,
            value: item.cod_func,
          },
        ]
      }
    }
    setLoading(false)
    return dados
  }

  const listTechnicianAvailable = async (cod) => {
    setLoading(true)
    setValueAt(null)

    setLiderSelect(cod)
    const resp: AxiosResponse<TypesEquipe> = await getEquipe({
      tecnicoPermitidoConsultor: cod,
      embed: 'tipo_tecnico',
      pagination: -1,
    })

    const { itens } = resp.data

    const at = itens.filter((item) => item.tecnico)
    const tec = itens
    const respGe: AxiosResponse<TypesTipoEquipeLiderGerenciar> = await getTipoEquipeLiderGerenciar({
      codLider: cod,
    })

    const dados = respGe.data.itens

    setDataAt(listAt(at, cod))

    setDataTechnician(tec)
    setLisTechnician(dados.map((item) => String(item.cod_func)))
    setLoading(false)
  }

  const handleChangeMoveTransfer = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    try {
      setLoading(true)
      if (direction === 'right') {
        const keys = [...removeTechnician, ...moveKeys]

        const resp = await postTipoEquipeLiderGerenciar({
          cod_consultor: consultorSelect,
          tecnicos: moveKeys,
        })
        if (resp.data.itens.length > 0) {
          setRemoveTechnician(keys)
          setLisTechnician(targetKeys)
        }
        setLoading(false)
      } else {
        for (let i = 0; i < moveKeys.length; i += 1) {
          const cod = moveKeys[i]
          await delTipoEquipeLiderGerenciar(consultorSelect, cod)
        }
        setRemoveTechnician(removeTechnician.filter((item) => item !== moveKeys[0]))
        setLisTechnician(targetKeys)
        setLoading(false)
      }
    } catch (error) {
      tryError(error)
    }
  }

  const filterOption = (inputValue: string, option: any) =>
    option.usuario_nome.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  useEffect(() => {
    listLideres()
  }, [permissions])

  return (
    <Container>
      <ViewPage
        newHide
        rotina={77}
        onPermission={setPermissions}
        title='Equipe do Lider'
        btnClick={() => handleClickNew()}
        btnsExta={
          <div style={{ maxWidth: '160px' }}>
            {consultor ? (
              <b>{itens[0].nome.toUpperCase()}</b>
            ) : (
              <Space>
                <Select
                  placeholder='Selecione um lider para iniciar'
                  style={{ minWidth: '160px' }}
                  popupMatchSelectWidth={false}
                  onChange={(e) => {
                    listTechnicianAvailable(e)
                  }}
                  showSearch
                  optionFilterProp='label'
                  options={orderBy(dataLideres, 'label')}
                />
              </Space>
            )}
          </div>
        }
        content={<></>}
      >
        <Transfer
          className={responsive && 'transfer-responsive'}
          showSearch
          filterOption={filterOption}
          disabled={loading}
          rowKey={(e) => String(e.cod_func)}
          selectAllLabels={[<b>Técnicos</b>, <b>Técnicos vinculados</b>]}
          targetKeys={lisTechnician}
          dataSource={dataTechnician}
          render={(record) => <> {record.usuario_nome}</>}
          listStyle={{ width: '100%', height: 'calc(90vh - 150px)' }}
          onChange={handleChangeMoveTransfer}
        />
      </ViewPage>
    </Container>
  )
}

export default EquipeLider
