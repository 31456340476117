import { DataNode } from 'antd/es/tree'
import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

type ProsTree = DataNode & {
  ordem: number
  children: {
    key: string
    params: {
      profundidades: {
        [key: string]: { exibe: '0' | '1'; obs: '' }
      }
    }
  }[]
}

export interface TypesConfigConsultoria {
  itens: ItensConfigConsultoriaTypes[]
}

export interface ItensConfigConsultoriaTypes {
  cod_conta?: number
  mapa_fertilidade: {
    elementos: ProsTree[],
    configuracoes: ProsTree[],
    texto_introducao: string,
  }
}

type TypesQuery = {}

const url = '/parametros-mapa-fertilidade'

export const getConfigBook = async () => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesConfigConsultoria> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      pagination: -1,
    },
  })

  return resp
}

export const getConfigBookMobile = async (token_url) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesConfigConsultoria> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      pagination: -1,
    },
    headers: {
      'Content-Type': 'application/json',
      // Authorization: `Token, Bearer ${token_url}`,
      Authorization: `Token ${process.env.REACT_APP_TOKEN_API}, Bearer ${token_url}`,
      Acesso: localConfig().acesso,
    },
  })

  return resp
}

export const putConfigBook = async (data) => {
  const { conta } = localConfig()
  const resp: AxiosResponse<TypesConfigConsultoria> = await api.post(url, data, {
    params: {
      codConta: conta,
    },
  })

  return resp
}
