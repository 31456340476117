import {
  Button,
  Col,
  Drawer,
  Form,
  Input,
  InputNumber,
  InputRef,
  message,
  Popconfirm,
  Radio,
  RefSelectProps,
  Row,
  Select,
  Space,
  Table,
  Tabs,
  TreeSelect,
} from 'antd'
import React, { useEffect, useRef, useState } from 'react'

import Icon, { MinusOutlined, PlusOutlined } from '@ant-design/icons'
import {
  faBug,
  faCheck,
  faCopy,
  faCrosshairs,
  faDisease,
  faPen,
  faSeedling,
  faTrash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'
import { v4 as uid } from 'uuid'
import { getCategoriaMarcaInsumo } from '../../../services/config/marca'
import {
  getInsumos,
  InsumosItens,
  postInsumos,
  putInsumos,
  TypeInsumos,
} from '../../../services/recomendacao/insumos'
import { Notification } from '../../notification'

import { listMedidas, tiposGerais, tiposSistema, tryError } from '../../../services/Afins'
import { getCategoriaInsumo, getCategoriaProdutos, TypeCategoriaProdutos } from '../../../services/categoriaProdutos'
import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado'
import {
  getMoniEspecie,
  MoniEspecieItens,
  TypeMoniEspecie,
} from '../../../services/monitoramento/especie'
import { getRecomendaAtivos, TypeRecomendaAtivos } from '../../../services/recomendacao/ativos'
import { getFormulacao, TypeFormulacao } from '../../../services/recomendacao/formulacao'
import { pagination, propsPopconfirm } from '../../Default'
import Icons from '../../Icons'
import { IconAlert } from '../../iconsAlert'
import { Container } from './styled'
import DiasDesdeData from '../../DiasDesdeData'

interface TypesInsumos {
  visible: boolean
  update: number
  onClose: (data: boolean) => void
  permissions: ItensPerUserLogged
  codClone: number
}

interface TypeInsetAtivos {
  uid: string
  cod: number
  cod_ativo: number
  editable?: boolean
  nomeAtivo: string
  concentracao: number
}

interface TypeEditAlvo {
  cod?: string | number
  dose_bula?: number
  dose_usual?: number
  medida?: string
  cod_variacao_especie?: number
  cod_especie?: number
}

const { TreeNode } = TreeSelect

const FormInsumos: React.FC<TypesInsumos> = (props) => {
  const { visible, update, onClose, permissions, codClone } = props

  const [form] = Form.useForm()

  const refEspecieTree = useRef(null)
  const refInputName = useRef<InputRef>(null)
  const refSelectCultura = useRef<RefSelectProps>({} as RefSelectProps)
  const refSelectFaseCultura = useRef<RefSelectProps>({} as RefSelectProps)

  const [dataCatProduto, setDataCatProduto] = useState([])
  const [dataCatCategoria, setDataCatCategoria] = useState([])
  const [dataCultura, setDataCultura] = useState([])
  const [dataFaseCultura, setDataFaseCultura] = useState([])
  const [dataEspecie, setDataEspecie] = useState<MoniEspecieItens[]>([] as MoniEspecieItens[])
  const [dataFabricantes, setDataFabricantes] = useState([])
  const [dataFormulacao, setDataFormulacao] = useState([])
  const [dataUnidadeMedida, setDataUnidadeMedida] = useState([])
  const [updateConcentracao, setUpdateConcentracao] = useState(null)
  const [dataAtivos, setDataAtivos] = useState([])
  const [alvoSelect, setAlvoSelect] = useState(0)
  const [expandedRowKeys, setExpandedRowKeys] = useState<any>([])
  const [dataInsumos, setDataInsumos] = useState<any>([])
  const [ativos, setAtivos] = useState<TypeInsetAtivos[]>([] as TypeInsetAtivos[])
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [tabSelected, setTabSelected] = useState('1')

  const [visibleAdjuvante, setVisibleAdjuvante] = useState(true)

  const [aplicacoes, setAplicacoes] = useState<InsumosItens['aplicacoes']>(
    [] as InsumosItens['aplicacoes']
  )
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const listCatProduto = async () => {
    const resp: AxiosResponse<TypeCategoriaProdutos> = await getCategoriaProdutos({ codGrupo: 20 })

    setDataCatProduto(resp.data.itens)
  }

  async function listCatInsumos () {
    const resp: AxiosResponse = await getCategoriaInsumo({})

    setDataCatCategoria(resp.data.itens)
  }

  const listFabricantes = async () => {
    const resp = await getCategoriaMarcaInsumo()
    setDataFabricantes(resp.data.itens)
  }

  const listFormulacao = async () => {
    const resp: AxiosResponse<TypeFormulacao> = await getFormulacao({
      pagination: -1,
    })

    setDataFormulacao(resp.data.itens)
  }

  const listCultura = async () => {
    const resp = await tiposGerais()

    setDataCultura(resp)
  }

  const listFaseCultura = async () => {
    const resp = await tiposSistema(13)

    setDataFaseCultura(resp)
  }

  const listEspecie = async () => {
    const resp: AxiosResponse<TypeMoniEspecie> = await getMoniEspecie({
      embed: 'variacao',
      pagination: -1,
    })

    const dados = orderBy(resp.data.itens, ['cod_categoria'])

    setDataEspecie(dados)
  }

  const listUnidadeMedida = async () => {
    const resp = await listMedidas()

    setDataUnidadeMedida(resp)
  }

  const handleChangeClasse = async (codCategoriaProduto) => {
    const resp: AxiosResponse<TypeRecomendaAtivos> = await getRecomendaAtivos({
      codCategoriaProduto,
      pagination: -1,
    })

    const catego = dataCatProduto.find((data) => codCategoriaProduto === data.cod)
    if (catego?.cod_categoria === 10) {
      setVisibleAdjuvante(false)
    } else {
      setVisibleAdjuvante(true)
    }

    setDataAtivos(resp.data.itens)
  }

  const handleClickInsertAtivos = async () => {
    const codAtivo = Number(form.getFieldValue('ativo'))
    const nomeAtivo = dataAtivos.find((data) => data.cod === codAtivo)

    const check = ativos.find((data) => data.cod_ativo === codAtivo)

    if (check !== undefined) {
      Notification({
        type: 'error',
        message: 'Ativo ja inserido',
      })

      return
    }

    setAtivos([
      ...ativos,
      {
        uid: uid(),
        cod: null,
        cod_ativo: form.getFieldValue('ativo'),
        nomeAtivo: nomeAtivo.nome,
        concentracao: form.getFieldValue('concentracao'),
      },
    ])
    if (update > 0) {
      form.submit()
    }
    form.resetFields(['ativo', 'concentracao'])
  }

  const editInsumo = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse<TypeInsumos> = await getInsumos({
      cod,
      embed: 'aplicacoes,alvos,composicao',
    })

    const dados = resp.data.itens[0]

      setDataInsumos(dados)
      
    handleChangeClasse(dados.cod_categoria_produto)

    form.setFieldsValue(dados)

    let composicao: TypeInsetAtivos[] = []

    dados.composicao.forEach((data) => {
      composicao = [
        ...composicao,
        {
          uid: uid(),
          cod: data.cod,
          concentracao: data.concentracao,
          cod_ativo: data.cod_ativo,
          nomeAtivo: data.ativo_nome,
        },
      ]
    })

    const aplica = dados.aplicacoes.map((rec) => {
      if (codClone > 0) {
        rec.cod = uid()
      }

      rec.alvos.map((item) => {
        if (codClone > 0) {
          item.cod = uid()
        }
        item.cod_cultura = rec.cod_cultura
        return item
      })
      return rec
    })

    setAplicacoes(aplica)

    setAtivos(composicao)
    message.destroy()
  }

  const handleChangeMask = (data, input) => {
    let area = data.target.value
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ [`${input}`]: area })
  }

  const handleEditiAtivo = (uid) => {
    const dados = [...ativos]
    dados.forEach((data) => {
      if (data.uid === uid) {
        data.editable = true
      } else {
        data.editable = false
      }
    })
    setAtivos(dados)
  }

  const handleClickTree = () => {
    //
  }

  const handelSaveAtivo = (cod) => {
    const dados = [...ativos]
    dados.forEach((data) => {
      if (data.cod === cod) {
        data.concentracao = updateConcentracao
      }
      data.editable = false
    })

    setAtivos(dados)
    form.submit()
  }
  const handleInsertCultura = () => {
    const formAplica = {
      cultura: form.getFieldValue('cod_cultura'),
      faseCultura: form.getFieldValue('cod_fase_cultura'),
    }

    if (Number.isNaN(formAplica.cultura) || formAplica.cultura === undefined) {
      Notification({
        message: 'Campo cultura inválido',
        type: 'error',
      })
      return
    }
    if (Number.isNaN(formAplica.faseCultura) || formAplica.faseCultura === undefined) {
      Notification({
        message: 'Campo fase da cultura inválido',
        type: 'error',
      })
      return
    }

    const verifica = aplicacoes.find(
      (data) =>
        data.cod_cultura === formAplica.cultura && data.cod_fase_cultura === formAplica.faseCultura
    )
    if (!verifica) {
      setAplicacoes([
        ...aplicacoes,
        {
          cod: uid(),
          cod_cultura: form.getFieldValue('cod_cultura'),
          cod_fase_cultura: form.getFieldValue('cod_fase_cultura'),
        },
      ])
      form.resetFields(['cod_cultura', 'cod_fase_cultura'])
    } else {
      Notification({
        message: 'Cultura e fase da cultura já inserida',
        type: 'error',
      })
    }
  }

  const handelRemoveAtivo = async (uid, cod = null) => {
    const dados = [...ativos]
    let filtro = []
    if (cod === null) {
      filtro = dados.filter((data) => data.uid !== uid)
    } else {
      message.loading('Aguarde...', 99999)
      const resp = await putInsumos(update, {
        ativos_remover: [cod],
      })
      if (resp.status === 200) {
        filtro = dados.filter((data) => data.uid !== uid)
        Notification({
          type: 'success',
          message: 'Ativo removido com sucesso',
        })
        message.destroy()
      }
    }
    setAtivos(filtro)
  }
  const handleDrawerClose = () => {
    onClose(false)
    setTabSelected('1')
    setAlvoSelect(0)
    setAtivos([] as TypeInsetAtivos[])
    setVisibleAdjuvante(true)
    setExpandedRowKeys([])
    setAplicacoes([] as InsumosItens['aplicacoes'])
    form.resetFields()
  }

  const handleUpConcentra = (concen, uid) => {
    const dados = [...ativos]
    let temp = []
    dados.forEach((data) => {
      if (data.uid === uid) {
        data.concentracao = concen
        setUpdateConcentracao(concen)
      }
      temp = [...temp, data]

      return temp
    })

    setAtivos(temp)
  }

  const onFinish = async (data) => {
    const dataAtivos = [...ativos]
    // eslint-disable-next-line camelcase
    let tempAtivo: { cod_ativo: number; concentracao: number }[] = []
    dataAtivos.forEach((ativo) => {
      tempAtivo = [
        ...tempAtivo,
        {
          cod_ativo: ativo.cod_ativo,
          concentracao: ativo.concentracao,
        },
      ]
      return tempAtivo
    })

    let dataFim: InsumosItens = {
      nome: data.nome,
      cod_fabricante: data.cod_fabricante,
      cod_categoria_produto: data.cod_categoria_produto,
      cod_classe_agronomica: data.cod_classe_agronomica,
      cod_formulacao: data.cod_formulacao,
      adjuvante: data.adjuvante,
      cod_medida_aplicacao: data.cod_medida_aplicacao,
      observacao: data.observacao,
      // @ts-ignore
      ativos: tempAtivo,
      aplicacoes,
    }

    if (data.adjuvante === 1) {
      dataFim = { ...dataFim, cod_tipo_adjuvante: data.cod_tipo_adjuvante }
    }
    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postInsumos(dataFim)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Insumo salvo com sucesso',
          })
          onClose(true)
          form.resetFields()
          setAtivos([] as TypeInsetAtivos[])
          setAplicacoes([])
          handleTabSelect('1')
          message.destroy()
        }
      } else {
        const resp = await putInsumos(update, dataFim)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Insumo atualizado com sucesso',
          })

          editInsumo(resp.data.itens[0].cod)
          message.destroy()
          onClose(true)
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleTabSelect = (data) => {
    setTabSelected(data)
  }

  const handleClickCloneAplica = (cod) => {
    try {
      const codCultura = form.getFieldValue('cod_cultura')
      const codFaseCultura = form.getFieldValue('cod_fase_cultura')
      if (codCultura === undefined) {
        Notification({
          message: 'Selecione uma cultura e a fase da cultura para clonar a aplicação',
          type: 'info',
        })
        refSelectCultura.current?.focus()
      } else if (codFaseCultura === undefined) {
        Notification({
          message: 'Selecione uma cultura e a fase da cultura para clonar a aplicação',
          type: 'info',
        })
        refSelectFaseCultura.current?.focus()
      } else {
        const f = aplicacoes.filter(
          (item) => item.cod_cultura === codCultura && item.cod_fase_cultura === codFaseCultura
        )

        if (f.length <= 0) {
          const dados = aplicacoes.find((item) => item.cod === cod)

          const aplicacao = {
            cod: uid(),
            cod_cultura: codCultura,
            cod_fase_cultura: codFaseCultura,
            alvos: dados.alvos.map((item) => {
              item.cod = uid()
              return item
            }),
          }
          setAplicacoes([...aplicacoes, aplicacao])
          form.resetFields(['cod_cultura', 'cod_fase_cultura'])
        } else {
          Notification({
            message: 'Cultura ja cadastrada',
            type: 'error',
          })
        }
      }
    } catch (error) {
      console.log(error)
    }
  }

  const onTableRowExpand = (expanded, record) => {
    const keys = []
    if (expanded) {
      keys.push(record.cod)
    }
    setExpandedRowKeys(keys)
  }

  const handleRemoveCultura = async (id) => {
    try {
      if (typeof id === 'string') {
        setAplicacoes(aplicacoes.filter((item) => item.cod !== id))
      } else {
        const remover = () => {
          const dados = [...aplicacoes]

          const remove = dados.filter((data) => data.cod !== id)

          setAplicacoes(remove)
          Notification({
            type: 'success',
            message: 'Aplicação removida com sucesso',
          })
        }

        if (update === 0) {
          remover()
        } else {
          message.loading('Removendo...')
          const resp = await putInsumos(
            update,
            { aplicacoes_remover: [id] },
            { embed: 'aplicacoes,alvos,composicao' }
          )

          if (resp.status === 200) {
            remover()
            message.destroy()
          } else {
            message.destroy()
          }
        }
      }
    } catch (error) {
      tryError(error)
    }
  }

  useEffect(() => {
    setVisibleDrawer(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editInsumo(update)
    }
  }, [update])

  useEffect(() => {
    if (codClone > 0) {
      editInsumo(codClone)
      refInputName.current?.focus()
    }
  }, [codClone])

  useEffect(() => {
    listCatProduto()
    listCatInsumos()
    listFabricantes()
    listFormulacao()
    listCultura()
    listFaseCultura()
    listEspecie()
    listUnidadeMedida()
  }, [])

  useEffect(() => {
    if (visible) {


      if (dataInsumos) {
        if (dataInsumos.create_user) {
          setUsuarioQueCriou(dataInsumos.create_user)

          const dateOnlyString = dataInsumos.create_time.split(' ')[0]
          const timeOnlyString = dataInsumos.create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (dataInsumos.update_user) {
          setUsuarioQueAlterou(dataInsumos.update_user)

          const dateOnlyString = dataInsumos.update_time.split(' ')[0]
          const timeOnlyString = dataInsumos.update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }
    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, visible, usuarioQueAlterou, dataInsumos, usuarioQueCriou, update, horarioDeCriacao])

  return (
    <Container>
      <Form form={form} onFinish={onFinish} layout='vertical'>
        <Drawer
          placement='left'
          bodyStyle={{ padding: 10 }}
          headerStyle={{ display: 'none' }}
          style={{ position: 'absolute' }}
          width='900px'
          getContainer={false}
          open={visibleDrawer}
          closeIcon={false}
          onClose={() => handleDrawerClose()}
          footer={
            <Space
              style={{
                display: 'flex',
                justifyContent: 'space-between',
              }}
            >
              <div>
                <Button
                  style={{
                    margin: '0 5px',
                  }}
                  hidden={permissions?.alteracao === 0 && permissions?.inclusao === 0}
                  type='primary'
                  htmlType='submit'
                >
                  Salvar
                </Button>
                <Button type='primary' danger onClick={() => handleDrawerClose()}>
                  Fechar
                </Button>
              </div>

              <Space className='log-user'>
                <small style={{ opacity: '0.5' }}>
                  {logUser && update > 0 && usuarioQueAlterou !== '' ? (
                    <>
                      <DiasDesdeData
                        dataCriacao={dataAlteracao}
                        onDataFromCreation={handleDataFromCreation}
                      />
                      <small style={{ opacity: '0.5', fontSize: '10px' }}>
                        atualizado por: {usuarioQueAlterou}{' '}
                        {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                          ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                          : `às ${horarioDeAtualizacao}h`}
                      </small>
                    </>
                  ) : !logUser && update > 0 ? (
                    <>
                      {' '}
                      {usuarioQueCriou === '' ? null : (
                        <small style={{ opacity: '0.5', fontSize: '10px' }}>
                          criado por: {usuarioQueCriou}{' '}
                          {diasDesdeCriacao !== null && diasDesdeCriacao !== 0
                            ? `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
                            : `às ${horarioDeCriacao}h`}
                        </small>
                      )}
                    </>
                  ) : null}
                </small>
              </Space>
            </Space>
          }
        >
          <Tabs
            className='ant-tab-green'
            onChange={handleTabSelect}
            activeKey={tabSelected}
            size='small'
            type='card'
          >
            <Tabs.TabPane tab='Dados do Insumo' key='1'>
              <Col
                style={{
                  pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                }}
                span={23}
              >
                <Row gutter={[8, 8]}>
                  <Col flex='1 1 400px'>
                    <Form.Item label='Nome' name='nome'>
                      <Input ref={refInputName} />
                    </Form.Item>
                  </Col>

                  <Col flex='1 1 300px'>
                    <Form.Item label='Categoria do insumo' name='cod_categoria_produto'>
                      <Select
                        disabled={ativos.length > 0}
                        allowClear
                        showSearch
                        filterOption={(input, options) =>
                          String(options.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataCatProduto.map((data) => (
                          <Select.Option key={data.cod} value={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col flex='1 1 300px'>
                    <Form.Item label='Classe agronômica' name='cod_classe_agronomica'>
                      <Select
                        disabled={ativos.length > 0}
                        allowClear
                        onChange={handleChangeClasse}
                        showSearch
                        filterOption={(input, options) =>
                          String(options.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataCatCategoria.map((data) => (
                          <Select.Option key={data.cod} value={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col flex='0 1 300px'>
                    <Form.Item label='Fabricante' name='cod_fabricante'>
                      <Select
                        showSearch
                        filterOption={(input, options) =>
                          String(options.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataFabricantes.map((data) => (
                          <Select.Option key={data.cod} value={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col flex='0 1 300px'>
                    <Form.Item label='Formulação' name='cod_formulacao'>
                      <Select
                        showSearch
                        filterOption={(input, options) =>
                          String(options.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataFormulacao.map((data) => (
                          <Select.Option key={data.cod} value={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='1 1 250px'>
                    <Form.Item name='cod_medida_aplicacao' label='Unidade dose'>
                      <Select style={{ width: '100px' }}>
                        {dataUnidadeMedida.map((data) => (
                          <Select.Option value={data.cod} key={data.cod}>
                            {String(data.nome).toUpperCase()}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={24}>
                    <Row gutter={[8, 8]}>
                      <Col hidden={visibleAdjuvante} flex='0 1 120px'>
                        <Form.Item
                          style={{ marginRight: 0 }}
                          hidden={visibleAdjuvante}
                          label='Adjuvante?'
                          name='adjuvante'
                        >
                          <Radio.Group
                            optionType='button'
                            buttonStyle='solid'
                            options={[
                              { label: 'Sim', value: 1 },
                              { label: 'Não', value: 0 },
                            ]}
                          />
                        </Form.Item>
                      </Col>
                      <Col span={24}>
                        <Form.Item label='Observação' name='observacao'>
                          <Input.TextArea rows={7} />
                        </Form.Item>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Col>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Composição' key='2'>
              <Col span={24}>
                <Row
                  style={{
                    pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                  }}
                  gutter={[8, 8]}
                >
                  <Col flex='0 1 280px'>
                    <Form.Item
                      rules={[
                        {
                          required: ativos.length <= 0,
                        },
                      ]}
                      label='Ativos'
                      name='ativo'
                    >
                      <Select
                        showSearch
                        filterOption={(input, options) =>
                          String(options.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
                        }
                      >
                        {dataAtivos.map((data) => (
                          <Select.Option key={data.cod} value={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>

                  <Col flex='0 1 110px'>
                    <Form.Item
                      rules={[
                        {
                          required: ativos.length <= 0,
                        },
                      ]}
                      label='Concentração'
                      name='concentracao'
                    >
                      <Input
                        addonAfter='%'
                        style={{ width: '100%' }}
                        onChange={(e) => handleChangeMask(e, 'concentracao')}
                      />
                    </Form.Item>
                  </Col>
                  <Col flex='0 1 70px'>
                    <Form.Item label=' '>
                      <Button onClick={handleClickInsertAtivos} type='primary'>
                        Inserir
                      </Button>
                    </Form.Item>
                  </Col>
                </Row>
              </Col>

              <Col span={24}>
                <Table
                  scroll={{ y: 'calc(90vh - 300px)' }}
                  size='small'
                  style={{
                    width: '500px',
                    pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                  }}
                  rowKey='uid'
                  pagination={pagination}
                  footer={() => (
                    <div
                      style={{
                        display: 'flex',
                        width: '100%',
                        justifyContent: 'right',
                      }}
                    >
                      <b>Total geral: {ativos.length}</b>
                    </div>
                  )}
                  dataSource={ativos}
                  columns={[
                    {
                      key: 1,
                      dataIndex: 'nomeAtivo',
                      title: 'Ativo',
                    },
                    {
                      key: 2,
                      width: 110,
                      dataIndex: 'concentracao',
                      title: 'Concentração',
                      render: (data, record) =>
                        record.editable ? (
                          <InputNumber
                            value={record.concentracao}
                            addonAfter='%'
                            onChange={(value) => handleUpConcentra(value, record.uid)}
                            size='small'
                            decimalSeparator=','
                          />
                        ) : (
                          `${data} %`
                        ),
                    },
                    {
                      key: 3,
                      width: 90,
                      dataIndex: 'uid',
                      title: 'Acões',
                      render: (data, record) => (
                        <Space>
                          <Button
                            shape='default'
                            disabled={record.cod === null}
                            onClick={() =>
                              record.editable ? handelSaveAtivo(record.cod) : handleEditiAtivo(data)
                            }
                            size='small'
                            type={record.editable ? 'primary' : 'default'}
                            icon={
                              <Icon
                                component={() => <Icons icon={record.editable ? faCheck : faPen} />}
                              />
                            }
                          />
                          <Popconfirm
                            title='Deseja remover o ativo?'
                            okText='Sim'
                            cancelText='Não'
                            cancelButtonProps={{
                              type: 'primary',
                              danger: true,
                            }}
                            onConfirm={() => handelRemoveAtivo(data, record.cod_ativo)}
                          >
                            <Button
                              shape='default'
                              size='small'
                              type='primary'
                              danger
                              icon={<Icon component={() => <Icons icon={faTrash} />} />}
                            />
                          </Popconfirm>
                        </Space>
                      ),
                    },
                  ]}
                />
              </Col>
            </Tabs.TabPane>
            <Tabs.TabPane tab='Aplicação' key='3'>
              <Col
                style={{
                  pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
                }}
                span={24}
              >
                <Row gutter={[8, 8]}>
                  <Col flex='0 1 280px'>
                    <Form.Item name='cod_cultura' label='Cultura'>
                      <Select ref={refSelectCultura}>
                        {dataCultura.map((data) => (
                          <Select.Option value={data.cod} key={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col flex='0 1 280px'>
                    <Form.Item name='cod_fase_cultura' label='Fase da cultura'>
                      <Select ref={refSelectFaseCultura}>
                        {dataFaseCultura.map((data) => (
                          <Select.Option value={data.cod} key={data.cod}>
                            {data.nome}
                          </Select.Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Form.Item label=' '>
                    <Button onClick={handleInsertCultura} type='primary'>
                      Inserir
                    </Button>
                  </Form.Item>

                  <Col span={23}>
                    <Table
                      size='small'
                      rowClassName={(record, index) =>
                        index % 2 === 0 ? 'table-row-light' : 'table-row-dark'
                      }
                      rowKey='cod'
                      scroll={{ y: 'calc(100vh - 420px)' }}
                      pagination={pagination}
                      expandable={{
                        expandedRowKeys,
                        onExpand: onTableRowExpand,
                        columnWidth: 80,
                        expandIcon: ({ expanded, onExpand, record }) => (
                          <Button
                            size='small'
                            type={expanded ? 'dashed' : null}
                            ghost={!expanded}
                            onClick={(e) => onExpand(record, e)}
                            icon={expanded ? <MinusOutlined /> : <PlusOutlined />}
                          >
                            Alvos
                          </Button>
                        ),
                        expandedRowRender: (record, index) => {
                          const insertAlvo = () => {
                            if (form.getFieldValue('dose_bula') === undefined) {
                              Notification({
                                message: 'Favor informar uma dose de bula',
                                type: 'info',
                              })
                              return
                            }
                            if (form.getFieldValue('dose_usual') === undefined) {
                              Notification({
                                message: 'Favor informar uma dose de usual',
                                type: 'info',
                              })
                              return
                            }
                            const dados = [...aplicacoes]

                            for (let i = 0; i < dados.length; i += 1) {
                              const element = dados[i]

                              const ev = form.getFieldValue('cod_especie').substr(0, 1)

                              let espcieVari: { cod: number; ev: string }
                              if (ev === 'v') {
                                espcieVari = {
                                  cod: form.getFieldValue('cod_especie').replace(/[^0-9]/g, ''),
                                  ev: 'v',
                                }
                              } else {
                                espcieVari = {
                                  cod: form.getFieldValue('cod_especie').replace(/[^0-9]/g, ''),
                                  ev: 'e',
                                }
                              }

                              const especie =
                                espcieVari.ev === 'v'
                                  ? {
                                      cod_variacao_especie: Number(espcieVari.cod),
                                    }
                                  : { cod_especie: Number(espcieVari.cod) }

                              let contemAlvo: any[] = []
                              if (element.alvos) {
                                if (espcieVari.ev === 'v') {
                                  contemAlvo = element.alvos.filter(
                                    (rec) =>
                                      rec.cod_variacao_especie === especie.cod_variacao_especie &&
                                      rec.cod_cultura === record.cod_cultura
                                  )
                                } else {
                                  contemAlvo = element.alvos?.filter(
                                    (rec) =>
                                      rec.cod_especie === especie.cod_especie &&
                                      rec.cod_cultura === record.cod_cultura
                                  )
                                }
                              }

                              if (contemAlvo.length <= 0) {
                                if (index === i) {
                                  if (!element.alvos) {
                                    element.alvos = []
                                  }
                                  element.alvos = [
                                    ...element.alvos,
                                    {
                                      cod: uid(),
                                      dose_bula: form.getFieldValue('dose_bula'),
                                      dose_usual: form.getFieldValue('dose_usual'),
                                      cod_cultura: record.cod_cultura,
                                      ...especie,
                                    },
                                  ]
                                }
                              } else {
                                Notification({
                                  message: 'Erro',
                                  descricao: 'Alvo já cadastrado',
                                  type: 'error',
                                })
                                return
                              }
                            }
                            form.resetFields(['dose_bula', 'dose_usual', 'medida', 'cod_especie'])

                            setAplicacoes(orderBy(dados, 'nome'))
                          }

                          const updateAlvo = async (codEspecie, i, cod) => {
                            const aplica = JSON.parse(JSON.stringify(aplicacoes))
                            const especie = codEspecie.vari
                              ? { cod_variacao_especie: codEspecie.cod }
                              : { cod_especie: codEspecie.cod }
                            const fields: TypeEditAlvo = {
                              cod,
                              dose_bula: form.getFieldValue(`dose_bula${alvoSelect}`),
                              dose_usual: form.getFieldValue(`dose_usual${alvoSelect}`),
                              // medida: form.getFieldValue(`medida${alvoSelect}`),
                              ...especie,
                            }

                            if (update > 0) {
                              message.loading('Aguarde...', 99999)
                              const resp: AxiosResponse<TypeInsumos> = await putInsumos(
                                update,
                                {
                                  aplicacoes: [
                                    {
                                      cod: expandedRowKeys[0],
                                      alvos: [fields],
                                    },
                                  ],
                                },
                                { embed: 'aplicacoes,alvos,composicao' }
                              )

                              if (resp.status === 200) {
                                Notification({
                                  type: 'success',
                                  message: 'Alvo atualizado com sucesso',
                                })

                                editInsumo(resp.data.itens[0].cod)
                                setAlvoSelect(0)
                                message.destroy()
                              } else {
                                message.destroy()
                              }
                            } else {
                              aplica[index].alvos[i] = fields
                              setAplicacoes(aplica)
                            }
                          }

                          const copyAlvo = (data) => {
                            form.setFieldsValue({
                              dose_bula: data.dose_bula,
                              dose_usual: data.dose_usual,
                              // medida: data.medida,
                            })
                            refEspecieTree.current?.focus()
                          }

                          const removeAlvo = async (cod, record: [], index: number) => {
                            if (typeof cod === 'string') {
                              const dados = [...aplicacoes]
                              const f = dados[index].alvos.filter((item) => item.cod !== cod)

                              dados[index].alvos = f

                              setAplicacoes(dados)
                            } else {
                              try {
                                const remove = () => {
                                  const dados = [...aplicacoes]
                                  dados.map((data) => {
                                    if (data.cod === expandedRowKeys[0]) {
                                      data.alvos = data.alvos.filter((alvo) => alvo.cod !== cod)
                                    }
                                    return data
                                  })
                                  Notification({
                                    type: 'success',
                                    message: 'Alvo removido com sucesso',
                                  })
                                  setAplicacoes(dados)
                                }

                                if (update === 0) {
                                  remove()
                                } else {
                                  message.loading('Removendo...', 999999)
                                  const resp = await putInsumos(
                                    update,
                                    {
                                      aplicacoes: [
                                        {
                                          cod: expandedRowKeys[0],
                                          alvos_remover: [cod],
                                        },
                                      ],
                                    },
                                    { embed: 'aplicacoes,alvos,composicao' }
                                  )

                                  if (resp.status === 200) {
                                    remove()
                                    message.destroy()
                                  } else {
                                    message.destroy()
                                  }
                                }
                              } catch (error) {
                                tryError(error)
                              }
                            }
                          }
                          return (
                            <>
                              <Space>
                                <Form.Item name='cod_especie' label='Especie'>
                                  <TreeSelect
                                    ref={refEspecieTree}
                                    size='small'
                                    style={{ width: '435px' }}
                                    treeLine={true && { showLeafIcon: false }}
                                    showSearch
                                    filterTreeNode={(search, item) => {
                                      return (
                                        String(item.key)
                                          .toLowerCase()
                                          .indexOf(search.toLowerCase()) >= 0
                                      )
                                    }}
                                    disabled={dataEspecie.length <= 0}
                                    dropdownStyle={{
                                      maxHeight: 400,
                                      overflow: 'auto',
                                    }}
                                  >
                                    {dataEspecie.map((data) => {
                                      let tipoPraga
                                      switch (data.cod_categoria) {
                                        case 1:
                                          tipoPraga = faBug
                                          break
                                        case 2:
                                          tipoPraga = faSeedling
                                          break
                                        case 3:
                                          tipoPraga = faDisease
                                          break

                                        default:
                                          break
                                      }
                                      return (
                                        <TreeNode
                                          selectable={!(data.variacao.length > 0)}
                                          title={
                                            <div onClick={() => handleClickTree()}>
                                              <Icon
                                                component={() => (
                                                  <FontAwesomeIcon icon={tipoPraga} />
                                                )}
                                              />{' '}
                                              {data.nome}
                                            </div>
                                          }
                                          key={data.nome}
                                          value={`C${data.cod}`}
                                        >
                                          {data.variacao.map((vari) => (
                                            <TreeNode
                                              key={vari.nome}
                                              value={`v${vari.cod}`}
                                              title={
                                                <div onClick={() => handleClickTree()}>
                                                  <Icon
                                                    component={() => (
                                                      <FontAwesomeIcon icon={tipoPraga} />
                                                    )}
                                                  />{' '}
                                                  {`${data.nome} > ${vari.nome}`}
                                                </div>
                                              }
                                              treeLine={false}
                                            />
                                          ))}
                                        </TreeNode>
                                      )
                                    })}
                                  </TreeSelect>
                                </Form.Item>
                                <Form.Item name='dose_bula' label='Dose de bula'>
                                  <Input
                                    size='small'
                                    name='dose_bula'
                                    onChange={(e) => handleChangeMask(e, 'dose_bula')}
                                  />
                                </Form.Item>

                                <Form.Item name='dose_usual' label='Dose usual'>
                                  <Input
                                    size='small'
                                    onChange={(e) => handleChangeMask(e, 'dose_usual')}
                                  />
                                </Form.Item>
                                {/* <Form.Item name='medida' label='Unidade dose'>
                                  <Select size='small' style={{ width: '100px' }}>
                                    {dataUnidadeMedida.map((data) => (
                                      <Select.Option value={data.cod} key={data.cod}>
                                        {String(data.nome).toUpperCase()}
                                      </Select.Option>
                                    ))}
                                  </Select>
                                </Form.Item> */}
                                <Form.Item label=' '>
                                  <Button onClick={insertAlvo} size='small' type='primary'>
                                    Inserir
                                  </Button>
                                </Form.Item>
                              </Space>
                              <Table
                                rowKey='cod'
                                locale={{
                                  emptyText: (
                                    <>
                                      <Icon component={() => <Icons icon={faCrosshairs} />} />
                                      <> Não possui alvo</>
                                    </>
                                  ),
                                }}
                                bordered
                                dataSource={record.alvos}
                                pagination={false}
                                size='small'
                                columns={[
                                  {
                                    key: 5,
                                    dataIndex: 'cod_especie',
                                    title: 'Especie',
                                    render: (_, record: any) => {
                                      let cod: number
                                      let nome: string
                                      let codCategoria: number
                                      if (record?.cod_variacao_especie) {
                                        cod = record.cod_variacao_especie
                                        dataEspecie.forEach((data) => {
                                          data.variacao.forEach((vari) => {
                                            if (vari.cod === cod) {
                                              nome = `${vari.especie_nome}>${vari.nome}`
                                              codCategoria = data.cod_categoria
                                            }
                                          })
                                        })
                                      } else {
                                        cod = record.cod_especie
                                        const dados = dataEspecie.find((data) => data.cod === cod)
                                        nome = dados.nome
                                        codCategoria = dados.cod_categoria
                                      }

                                      let tipoPraga
                                      switch (codCategoria) {
                                        case 1:
                                          tipoPraga = faBug
                                          break
                                        case 2:
                                          tipoPraga = faSeedling
                                          break
                                        case 3:
                                          tipoPraga = faDisease
                                          break

                                        default:
                                          break
                                      }
                                      return (
                                        <Space>
                                          <FontAwesomeIcon icon={tipoPraga} />
                                          {nome}
                                          {typeof record.cod === 'string' ? (
                                            <span
                                              style={{
                                                color: '#12a4c9',
                                                fontWeight: 'bold',
                                                fontSize: '22px',
                                              }}
                                            >
                                              *
                                            </span>
                                          ) : null}
                                        </Space>
                                      )
                                    },
                                  },
                                  {
                                    key: 1,
                                    width: '120px',
                                    dataIndex: 'dose_bula',
                                    title: 'Dose de bula',
                                    render: (doseBula, record) => {
                                      return alvoSelect !== record.cod ? (
                                        <>{doseBula}</>
                                      ) : (
                                        <Form.Item
                                          noStyle
                                          initialValue={doseBula}
                                          name={`dose_bula${record.cod}`}
                                        >
                                          <Input
                                            size='small'
                                            onChange={(e) =>
                                              handleChangeMask(e, `dose_bula${record.cod}`)
                                            }
                                          />
                                        </Form.Item>
                                      )
                                    },
                                  },
                                  {
                                    key: 3,
                                    width: '120px',
                                    dataIndex: 'dose_usual',
                                    title: 'Dose usual',
                                    render: (doseUsual, record) => {
                                      return alvoSelect !== record.cod ? (
                                        <>{doseUsual}</>
                                      ) : (
                                        <Form.Item
                                          noStyle
                                          initialValue={doseUsual}
                                          name={`dose_usual${alvoSelect}`}
                                        >
                                          <Input
                                            size='small'
                                            width='auto'
                                            onChange={(e) =>
                                              handleChangeMask(e, `dose_usual${alvoSelect}`)
                                            }
                                          />
                                        </Form.Item>
                                      )
                                    },
                                  },
                                  {
                                    key: 2,
                                    dataIndex: 'medida',
                                    width: '120px',
                                    title: 'Unidade',
                                    render: (cod, record) =>
                                      dataUnidadeMedida.find(
                                        (item) =>
                                          item.cod === form.getFieldValue('cod_medida_aplicacao')
                                      )?.nome,
                                  },

                                  {
                                    key: 5,
                                    dataIndex: 'cod',
                                    align: 'center',
                                    width: 50,
                                    title: 'Ações',
                                    render: (id, record, indexAlvo) => {
                                      let codTipo
                                      if (record.cod_variacao_especie) {
                                        codTipo = {
                                          cod: record.cod_variacao_especie,
                                          vari: true,
                                        }
                                      } else {
                                        codTipo = {
                                          cod: record.cod_especie,
                                          vari: false,
                                        }
                                      }

                                      return (
                                        <Space>
                                          <Button
                                            onClick={() => {
                                              if (
                                                alvoSelect > 0 ||
                                                typeof alvoSelect === 'string'
                                              ) {
                                                updateAlvo(codTipo, indexAlvo, id)
                                                setAlvoSelect(0)
                                              } else {
                                                setAlvoSelect(id)
                                              }
                                            }}
                                            size='small'
                                            type={alvoSelect === record.cod ? 'primary' : 'default'}
                                            icon={
                                              <Icon
                                                component={() => (
                                                  <Icons
                                                    icon={
                                                      alvoSelect === record.cod ? faCheck : faPen
                                                    }
                                                  />
                                                )}
                                              />
                                            }
                                            shape='circle'
                                          />
                                          <Button
                                            size='small'
                                            icon={
                                              <Icon
                                                onClick={() => copyAlvo(record)}
                                                component={() => <Icons icon={faCopy} />}
                                              />
                                            }
                                            shape='circle'
                                          />
                                          <Popconfirm
                                            {...propsPopconfirm}
                                            icon={<IconAlert type='confirm' size={4} />}
                                            onConfirm={() => removeAlvo(id, record, index)}
                                            title='Deseja remover esse alvo?'
                                          >
                                            <Button
                                              size='small'
                                              danger
                                              type='primary'
                                              icon={
                                                <Icon component={() => <Icons icon={faTrash} />} />
                                              }
                                              shape='circle'
                                            />
                                          </Popconfirm>
                                        </Space>
                                      )
                                    },
                                  },
                                ]}
                              />
                            </>
                          )
                        },
                      }}
                      dataSource={aplicacoes}
                      columns={[
                        {
                          key: 1,
                          title: 'Cultura',
                          dataIndex: 'cod_cultura',
                          render: (cod, record) => {
                            const nameCultura = dataCultura.find((data) => data.cod === cod)
                            return (
                              <>
                                {nameCultura?.nome}{' '}
                                {typeof record.cod === 'string' ? (
                                  <span
                                    style={{
                                      height: '5px',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                      color: '#12a4c9',
                                      fontWeight: 'bold',
                                      fontSize: '22px',
                                    }}
                                  >
                                    *
                                  </span>
                                ) : null}
                              </>
                            )
                          },
                        },
                        {
                          key: 2,
                          title: 'Fase da cultura',
                          dataIndex: 'cod_fase_cultura',
                          render: (cod) => {
                            const nameFaseCultura = dataFaseCultura.find((data) => data.cod === cod)
                            return <>{nameFaseCultura?.nome}</>
                          },
                        },
                        {
                          key: 3,
                          title: 'Ações',
                          align: 'center',
                          dataIndex: 'cod',
                          width: 75,
                          render: (cod) => {
                            return (
                              <Space>
                                <Popconfirm
                                  {...propsPopconfirm}
                                  title='Deseja clonar essa aplicação?'
                                  onConfirm={() => handleClickCloneAplica(cod)}
                                >
                                  <Button
                                    size='small'
                                    icon={<Icon component={() => <Icons icon={faCopy} />} />}
                                    shape='default'
                                  />
                                </Popconfirm>
                                <Popconfirm
                                  {...propsPopconfirm}
                                  title='Deseja remover essa cultura?'
                                  onConfirm={() => handleRemoveCultura(cod)}
                                >
                                  <Button
                                    size='small'
                                    danger
                                    type='primary'
                                    icon={<Icon component={() => <Icons icon={faTrash} />} />}
                                    shape='default'
                                  />
                                </Popconfirm>
                              </Space>
                            )
                          },
                        },
                      ]}
                    />
                  </Col>
                </Row>
              </Col>
            </Tabs.TabPane>
          </Tabs>
        </Drawer>
      </Form>
    </Container>
  )
}
export default FormInsumos
