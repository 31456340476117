import styled from "styled-components";

export const CheckBoxWrapper = styled.div`
    width: 100%;
    display: flex;
    align-items: center;
    gap: 5px;

    button.CheckBoxWrapper__ckeckbox {
        width: 16px;
        height: 16px;
        border: thin solid var(--border-color);
        border-radius: 5px;
        background-color: #fff;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;

        &:hover {
            outline: 3px solid rgba(93, 165, 125, 0.4);
        }

        &:active {
            transform: scale(0.9);
        }

        &:disabled,
        &:disabled:is([data-checked = true]) {
            background-color: var(--disabled-color) !important;
            color: var(--disabled-text-color) !important;
            border: thin solid var(--disabled-text-color);
            cursor: not-allowed;
        }

        &:is([data-checked = true]) {
            background-color: var(--primary-color);
            border-color: var(--primary-color);
        }

        svg {
            font-size: 10px;
            color: #fff;
        }

    }

    label.CheckBoxWrapper__label {
        font-weight: 400;
        color: var(--text-color);
        font-size: 13px;
    }

`