import { Avatar, Drawer } from 'antd'
import React, { useState } from 'react'
import { ContainerGaveta } from './styled'
import ButtonCustom from '../../../ButtonCustom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faBriefcase, faHandshake, faUser, faXmark } from '@fortawesome/free-solid-svg-icons'
import dayjs from 'dayjs'

interface PropsGaveta {
  cod?: number
  dados?: any[]
  open?: boolean
  setOpen?: React.Dispatch<React.SetStateAction<boolean>>
}

const GavetaAniversariante: React.FC<PropsGaveta> = ({ cod, dados, open, setOpen }) => {
  const tirarCodDuplicado = (arr, cod) => {
    const filtered = arr.filter((item) => item.cod === cod)
    return filtered.length > 0 ? [filtered[0]] : []
  }

  const dadosFilter = tirarCodDuplicado(dados, cod)

  function calcularTempoEmpresa(dataAdmissao) {
    const hoje = dayjs()
    const admissao = dayjs(dataAdmissao)

    const anos = hoje.diff(admissao, 'year')
    const meses = hoje.diff(admissao.add(anos, 'year'), 'month')

    const anoTexto = anos === 1 ? 'ano' : 'anos'
    const mesTexto = meses === 1 ? 'mês' : 'meses'

    if (anos > 0 && meses > 0) {
      return `${anos} ${anoTexto} e ${meses} ${mesTexto}`
    } else if (anos > 0) {
      return `${anos} ${anoTexto}`
    } else {
      return `${meses} ${mesTexto}`
    }
  }

  return (
    <Drawer
      open={open}
      onClose={() => setOpen(false)}
      closeIcon={false}
      placement='right'
      width={'30%'}
      title={
        <div style={{ height: '20px' }}>
          <ButtonCustom
            type='tertiary'
            danger
            icon={<FontAwesomeIcon icon={faXmark} />}
            onClick={() => setOpen(false)}
          >
            Fechar
          </ButtonCustom>
        </div>
      }
    >
      <ContainerGaveta>
        <h2>Perfil do aniversariante</h2>
        {dadosFilter?.map((item) => {
          const tempoEmpresa = calcularTempoEmpresa(item?.tempo_empresa)
          return (
            <div key={item?.cod} className='informacoes__perfil'>
              <div className='img__perfil'>
                {item?.doc_foto_perfil_url ? (
                  <img src={item?.doc_foto_perfil_url} />
                ) : (
                  <Avatar
                    style={{
                      color: 'white',
                      fontWeight: 500,
                      fontSize: 70,
                      display: 'flex',
                      alignItems: 'center',
                      justifyContent: 'center',
                      width: '140px',
                      height: '140px',
                    }}
                  >
                    {String(item.nome).substring(0, 1).toLocaleUpperCase()}
                  </Avatar>
                )}
                <h3 className='nome'>{`${item?.nome} ${item?.sobrenome}`.toLocaleLowerCase()}</h3>
              </div>

              <div className='dados__aniversariantes'>
                <div className='idade__empresa'>
                  <p>
                    <FontAwesomeIcon icon={faBriefcase} />
                    <strong>Tempo na empresa:</strong>
                  </p>
                  {tempoEmpresa}
                </div>
                <div className='idade__empresa'>
                  <p>
                    <FontAwesomeIcon icon={faUser} />
                    <strong>Idade:</strong>
                  </p>
                  {item?.idade === null ? '' : `${item.idade} anos`}
                </div>
              </div>
            </div>
          )
        })}
      </ContainerGaveta>
    </Drawer>
  )
}

export default GavetaAniversariante
