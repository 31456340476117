
import { api } from '../../api'
import { localConfig } from '../../../Configs/localConfig'

export interface TypeRecomendaAtivos {
  itens: [RecomendaAtivos]
}

export interface RecomendaAtivos {
  cod?: number
  nome?: string
  cod_categoria_produto?: number
  cod_grupo_quimico?: number
  cod_classe_quimica?: number
  cod_grupo?: number
  cod_modo_acao?: number
  cod_local_acao?: number
  cod_mecanismo_acao?: number
  categoria_produto_nome?: string
  grupo_quimico_nome?: string
  classe_quimica_nome?: null
  grupo_nome?: string
  modo_acao_nome?: string
  local_acao_nome?: string
  local_mecanismo_acao?: string
}

interface TypeQuery {
  codCategoriaProduto?: number
  nome?: string
  cod?: number
  pagination?: number | -1
  codConta?: boolean | any
}

const url = '/recomendacao/ativo'

export const getRecomendaAtivos = async (params: TypeQuery) => {
  const { pagination = -1, codConta = false } = params
  const { conta } = localConfig()
  params = {
    ...params,
    pagination,
    codConta: codConta ? conta : null,
  }

  const response = api.get(url, {
    params,
  })

  return response
}

export const postRecomendaAtivos = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putRecomendaAtivos = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delRecomendaAtivos = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
