import React, { useState, useEffect } from 'react';
import moment from 'moment';

const DiasDesdeData = ({ dataCriacao, onDataFromCreation }) => {
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null);

  useEffect(() => {
    if (dataCriacao) {
      
      const hoje = moment();
   
      const dias = hoje.diff(moment(dataCriacao), 'days');
     
      setDiasDesdeCriacao(dias);
      // Chama a função de retorno de chamada passando o número de dias desde a criação
      onDataFromCreation(dias);
    }
  }, [dataCriacao, onDataFromCreation]);

  return null; // Não renderiza nada diretamente
};

export default DiasDesdeData;
