/* eslint-disable react/no-unused-prop-types */
/* eslint-disable react/require-default-props */

import React, { useCallback, useEffect, useRef, useState } from 'react'

import { DrawingManager, GoogleMap, Polygon } from '@react-google-maps/api'

import axios from 'axios'

import {
  Button,
  Card,
  Col,
  Form,
  Input,
  InputNumber,
  InputRef,
  message,
  Row,
  Select,
  Switch,
} from 'antd'

import { faRedo, faSync, faUndo } from '@fortawesome/free-solid-svg-icons'
import { buscaCidade, calculoAreaCoord, centerMap, listUf, polyGetPath } from '../../services/Afins'

import { Notification } from '../notification'

import { postTalhao, putTalhao } from '../../services/Talhao'

import { postTalhaoCultivavel, putTalhaoCultivavel } from '../../services/TalhaoSafra'

import { geralKmlPolygon } from '../gerarKml'
import Icons from '../Icons'

export interface TypesDesenhoTalhao {
  propriedade: number
  update: number
  visible?: boolean
  onClose?: (data: boolean) => void
  atualizar: (codPro: number, nome: string) => void
  cultivavel: boolean
  dataPolygon?: {
    coordenadas?: google.maps.LatLng[]
    nome?: string
    lookat?: google.maps.LatLng
  }
}

const { Option } = Select

const DesenhaTalhao: React.FC<TypesDesenhoTalhao> = (props) => {
  const { propriedade, update, atualizar, cultivavel, dataPolygon = { coordenadas: [] } } = props

  const inputRef = useRef<InputRef>(null)

  const [form] = Form.useForm()

  const [getPath, setPath] = useState([])
  const [getAreatalhao, setGetAreatalhao] = useState<number>(0)
  const [getCenter, setCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({
    lat: -18.4590515,
    lng: -64.3517889,
  })

  const [coordPoly, setCoordPoly] = useState([])

  const [getZoom, setZoom] = useState<number>(4)

  const [typeBusca, setTypeBusca] = useState(false)

  const [getlistUf, setListUf] = useState([])

  const [getlistCity, setListCity] = useState([])

  const [undoPolygon, setUndoPolygon] = useState([])
  const [redoPolygon, setRedoPolygon] = useState([])

  const [getLoadingBuscaCoord, setLoadingBuscaCoord] = useState(false)

  const [drawing, setDrawing] = useState<google.maps.drawing.DrawingManager>()
  const [polygon, setPolygon] = useState<google.maps.Polygon>()
  const [map, setMap] = useState<google.maps.Map>()

  const polylineRef = useRef(null)

  const mapRef = useRef(null)

  const listenersRef = useRef([])

  const onUnmount = useCallback(() => {
    listenersRef.current.forEach((lis) => lis.remove())
    polylineRef.current = null
  }, [])

  const showPath = () => {
    const path = getPath.filter((data) => {
      return data !== undefined
    })

    const area = window.google.maps.geometry.spherical.computeArea(path)

    const ha = parseFloat((area / 10000).toFixed(2))

    setGetAreatalhao(ha)

    form.setFieldsValue({ area: ha.toFixed(2).toString().replace('.', ',') })
  }

  const handleListUf = async () => {
    const response = await listUf()
    setListUf(response)
  }

  const handleUndo = () => {
    const old = [...undoPolygon]

    const index = undoPolygon.length - 1
    let newArray = []
    let newCoord = []
    old.forEach((data, i) => {
      if (data !== undefined) {
        if (index !== i) {
          const info = data
          newArray = [...newArray, info]
        } else {
          setRedoPolygon([...redoPolygon, data])
          newCoord = [old[i - 1]]
        }
      }

      return { newArray, newCoord }
    })

    polygon.setPaths(newCoord)

    setGetAreatalhao(calculoAreaCoord(newCoord))
    form.setFieldsValue({ area: calculoAreaCoord(newCoord) })

    setUndoPolygon(newArray)
  }

  const onLoadMap = () => {
    //
  }

  const handleRedo = () => {
    const old = [...redoPolygon]

    const index = redoPolygon.length - 1
    let newArray = []
    let newCoord = []
    old.forEach((data, i) => {
      if (data !== undefined) {
        if (index !== i) {
          const info = data
          newArray = [...newArray, info]
        } else {
          setUndoPolygon([...undoPolygon, data])
          newCoord = [old[i]]
        }
      }

      return { newArray, newCoord }
    })

    polygon.setPaths(newCoord)

    setGetAreatalhao(calculoAreaCoord(newCoord))
    form.setFieldsValue({ area: calculoAreaCoord(newCoord) })

    setRedoPolygon(newArray)
  }

  const handleResetDesenho = () => {
    setUndoPolygon([])
    setRedoPolygon([])
    polygon.setPaths([])
    form.resetFields(['area', 'nome'])
  }

  const handlePolylineComplete = (e: google.maps.Polyline) => {
    const dados = polyGetPath(e)

    setCoordPoly(dados)
    setUndoPolygon([dados])
    polygon.setPaths(dados)
    drawing.setDrawingMode(null)
    const ha = calculoAreaCoord([dados])
    form.setFieldsValue({ area: ha })
    setGetAreatalhao(ha)
    inputRef.current.focus({
      cursor: 'all',
    })
    e.setPath([])
  }

  const handleMouseUpPolygon = () => {
    const dados = polyGetPath(polygon)

    setUndoPolygon([...undoPolygon, dados])

    const ha = calculoAreaCoord([dados])

    form.setFieldsValue({ area: ha })
    setGetAreatalhao(ha)
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...')

      const polygonFinish = polyGetPath(polygon)

      const kml = geralKmlPolygon(polygonFinish, data.nome)

      const cripto = btoa(kml)

      const arquivo = `data:@file/xml;base64,${cripto}`

      data = { ...data, arquivo }

      const dados = {
        nome: data.nome,
        cod_propriedade: data.cod_propriedade,
        arquivo: data.arquivo,
        status: data.status,
        area: String(data.area),
      }

      if (update <= 0) {
        const response = cultivavel
          ? await postTalhaoCultivavel(propriedade, dados)
          : await postTalhao(propriedade, dados)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Talhão Cadastrado com Sucesso',
            type: 'success',
          })
          atualizar(propriedade, '')
        }
      } else {
        const response = cultivavel
          ? await putTalhaoCultivavel(propriedade, update, dados)
          : await putTalhao(propriedade, update, dados)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Talhão Atualizado com Sucesso',
            type: 'success',
          })
          atualizar(propriedade, '')
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const onFinishCoord = () => {
    // data
  }

  const onFildesChangeCoord = () => {
    // data
  }

  const onLoadDrawing = (drawing: google.maps.drawing.DrawingManager) => {
    setDrawing(drawing)
  }

  const handleSelectedUF = async (data) => {
    const response = await buscaCidade('', data, '')
    setListCity(response)
  }

  const handleClickCoord = () => {
    const lat = parseFloat(form.getFieldValue('lat'))

    const lng = parseFloat(form.getFieldValue('lng'))

    setCenter({ lat, lng })
    mapRef.current.state.map.zoom = 15
  }

  const handleClickCity = async () => {
    setLoadingBuscaCoord(true)

    const response = await buscaCidade('', '', form.getFieldValue('city'))

    const resp = response[0]

    const coord = await axios.get(
      `https://maps.googleapis.com/maps/api/geocode/json?address=${resp.nome}+${resp.uf_sigla}&key=${process.env.REACT_APP_TOKEN_GOOGLE_MAPS}`
    )
    // @ts-ignore
    const location = coord.data?.results[0].geometry.location

    const { lat, lng } = location

    setCenter({ lat, lng })
    // mapRef.current.state.map.zoom = 15;
    setZoom(15)

    setLoadingBuscaCoord(false)
  }

  const handleChangeTypeCenter = (data) => {
    setTypeBusca(data)
  }

  // const mapZoom = () => {};

  useEffect(() => {
    const registro = getPath.length - 1

    if (getPath.length > 0 && getPath[registro] !== undefined) {
      showPath()
    }
  }, [getPath])

  useEffect(() => {
    if (propriedade === undefined || propriedade === null) {
      //
    } else {
      form.setFieldsValue({ cod_propriedade: propriedade })
    }
  }, [propriedade])

  useEffect(() => {
    if (dataPolygon.coordenadas.length > 0) {
      const coord = dataPolygon.coordenadas

      setPath(coord)

      setCoordPoly(coord)
      setUndoPolygon([coord])
      const ha = calculoAreaCoord([coord])
      form.setFieldsValue({ area: ha, nome: dataPolygon.nome })
      setZoom(12)

      const center = centerMap(coord)

      setCenter(center)
    }
  }, [dataPolygon])

  useEffect(() => {
    if (drawing) {
      drawing.setDrawingMode(null)
      setCoordPoly([])
      setPath([])
      form.resetFields()
    }
  }, [atualizar])

  useEffect(() => {
    handleListUf()
  }, [])

  return (
    <Row gutter={[8, 8]}>
      <Col span={20}>
        <Row gutter={[8, 8]}>
          <Col span={24}>
            <Form
              hidden={update > 0}
              onFieldsChange={onFildesChangeCoord}
              onFinish={onFinishCoord}
              form={form}
              size='small'
              layout='inline'
            >
              <Col span={4}>
                <Form.Item label='Busca por Coordenadas?'>
                  <Switch
                    size='small'
                    checkedChildren='Sim'
                    unCheckedChildren='Não'
                    onChange={handleChangeTypeCenter}
                  />
                </Form.Item>
              </Col>
              {typeBusca ? (
                <>
                  <Col span={3}>
                    <Form.Item name='lat'>
                      <InputNumber
                        min={-90}
                        max={90}
                        style={{ width: '100%' }}
                        type='text'
                        placeholder='Latitude'
                      />
                    </Form.Item>
                  </Col>
                  <Col span={3}>
                    <Form.Item name='lng'>
                      <InputNumber
                        min={-180}
                        max={180}
                        style={{ width: '100%' }}
                        type='text'
                        placeholder='Longitude'
                      />
                    </Form.Item>
                  </Col>
                  <Col>
                    {' '}
                    <Form.Item>
                      <Button onClick={handleClickCoord} type='primary' htmlType='submit'>
                        Buscar
                      </Button>
                    </Form.Item>
                  </Col>
                </>
              ) : (
                <>
                  <Col span={2}>
                    <Form.Item name='uf'>
                      <Select
                        onChange={handleSelectedUF}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            // @ts-ignore
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder='Uf'
                      >
                        {getlistUf.map((data) => (
                          <Option value={data.cod} key={data.cod}>
                            {data.sigla}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col span={4}>
                    <Form.Item name='city'>
                      <Select
                        disabled={getlistCity.length <= 0}
                        showSearch
                        filterOption={(input, option) =>
                          option.children
                            // @ts-ignore
                            .toLowerCase()
                            .indexOf(input.toLowerCase()) >= 0
                        }
                        placeholder='Cidade'
                      >
                        {getlistCity.map((data) => (
                          <Option value={data.cod} key={data.cod}>
                            {data.nome}
                          </Option>
                        ))}
                      </Select>
                    </Form.Item>
                  </Col>
                  <Col>
                    {' '}
                    <Button
                      loading={getLoadingBuscaCoord}
                      onClick={handleClickCity}
                      type='primary'
                      htmlType='submit'
                    >
                      Buscar
                    </Button>
                  </Col>
                </>
              )}
            </Form>
          </Col>
        </Row>
        <p />
        {/* @ts-ignore */}
        <GoogleMap
          ref={mapRef}
          onLoad={onLoadMap}
          options={{
            mapTypeId: 'hybrid',
            mapTypeControl: false,
            streetViewControl: false,
          }}
          mapContainerStyle={{
            width: '100%',
            height: '81vh',
          }}
          center={getCenter}
          zoom={getZoom}
        >
          {/* Desenha talhao e movimentar no mapa */}
          <DrawingManager
            onLoad={onLoadDrawing}
            onPolylineComplete={handlePolylineComplete}
            options={{
              polylineOptions: {
                strokeColor: 'cyan',
                strokeWeight: 4,
                clickable: true,
                draggable: false,
                editable: true,
                geodesic: false,
              },
              drawingControl: update <= 0,
              drawingControlOptions: {
                drawingModes: [window.google.maps.drawing.OverlayType.POLYLINE],
              },
            }}
          />

          <Polygon
            onLoad={(data) => setPolygon(data)}
            paths={getPath}
            onMouseUp={handleMouseUpPolygon}
            onUnmount={onUnmount}
            options={{
              fillColor: 'cyan',
              strokeColor: 'cyan',
              strokeWeight: 4,
              fillOpacity: 0.45,
              clickable: true,
              draggable: false,
              editable: true,
              geodesic: false,
            }}
          />
        </GoogleMap>
      </Col>
      <Col span={4}>
        <Card title='Dados Talhão' style={{ minWidth: '100%' }} size='small'>
          <Form onFinish={onFinish} size='small' form={form}>
            <Col span={24}>
              <Row gutter={[8, 0]}>
                <Col span={24}>
                  <Form.Item required name='nome'>
                    <Input ref={inputRef} required placeholder='Nome Talhão' />
                  </Form.Item>
                </Col>
                <Col span={15}>
                  <Form.Item name='area'>
                    <Input readOnly addonAfter='ha' />
                  </Form.Item>
                </Col>
                <Col span={3}>
                  <Form.Item>
                    <Button
                      disabled={undoPolygon.length <= 1}
                      onClick={handleUndo}
                      style={{ color: 'white' }}
                      title='Desfazer'
                    >
                      <Icons icon={faUndo} />
                    </Button>
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item>
                    <Button
                      disabled={redoPolygon.length <= 0}
                      onClick={handleRedo}
                      style={{ color: 'white' }}
                      title='Refazer'
                    >
                      <Icons icon={faRedo} />
                    </Button>
                  </Form.Item>
                </Col>

                <Col span={3}>
                  <Form.Item>
                    <Button
                      disabled={getPath.length <= 0}
                      onClick={handleResetDesenho}
                      style={{ color: 'white' }}
                      type='primary'
                      danger
                      title='Resetar'
                    >
                      <Icons icon={faSync} />
                    </Button>
                  </Form.Item>
                </Col>
                <Col span={24}>
                  <Button disabled={getAreatalhao <= 0} htmlType='submit' block type='primary'>
                    {update <= 0 ? 'Gerar talhão' : 'Atualizar talhão'}
                  </Button>
                </Col>
                <Form.Item name='cod_propriedade' />
                <Form.Item name='status' initialValue={1} />
              </Row>
            </Col>
          </Form>
        </Card>
      </Col>
    </Row>
  )
}

export default DesenhaTalhao
