import styled from "styled-components";


export const Container = styled.div`
    width: 100%;
    height: 100%;
    background-color: #fff;
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
`