import styled from "styled-components"

export const LoadingContainer = styled.div`
    width: 100%;
    padding: 15px 0px;
    display: flex;
    align-items: center;

    :is([data-isWhite = true]) {
        filter: brightness(0) saturate(100%) invert(100%) sepia(1%) saturate(7476%) hue-rotate(232deg) brightness(97%) contrast(98%);
    }

    div.LoadingContainer__conteudo {
        max-width: 500px;
        display: flex;
        flex-direction: column;
        align-items: center;
        gap: 20px;

        span.LoadingContainer__conteudo__legenda {
            font-weight: 600;
            color: var(--primary-color);
            display: flex;
            animation: blink 0.8s infinite;
            animation-timing-function: linear;
        }

        @keyframes blink {
            0% { opacity: 1; }
            50% { opacity: 0.4; }
            100% { opacity: 1; }
        }

    }
`