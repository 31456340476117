import styled from "styled-components";

export const ContainerBoxes = styled.div`
    width: 100%;
    display: flex;
    gap: 15px;

    p.ContainerBoxes__box__title {
        margin: 0;
        font-size: 13px;
        color: var(--text-color-light);
        font-weight: 500;
    }
    span.ContainerBoxes__box__value {
        margin: 0;
        font-size: 14px;
        color: var(--text-color);
        font-weight: 600;
        line-height: 15px;
    }
`;