import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeEstadios {
  itens: [EstadioItens]
}

export interface EstadioItens {
  cod?: number
  cod_cultura?: number
  cod_estadio?: number
  nome?: string
  descricao?: string
  status?: number
  arquivo?: string
  cultura_nome?: string
  arquivo_url?: string
  ordem: number | null
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  codSafra?: number
  cod?: number
  pagination?: number | -1
  embed?: string
  codCultura?: number
}

export const getEstadios = async (params: TypeParamGet) => {
  const { codCliente } = localConfig()

  params = {
    ...params,
    codCliente,
  }

  const response: AxiosResponse<TypeEstadios> = await api.get('/estadio', {
    params,
  })

  return response
}

export const postEstadios = async (data) => {
  const config = localConfig()

  const response = api.post('/estadio', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const putEstadios = async (cod, data) => {
  const config = localConfig()

  const response = api.put(`/estadio/${cod}`, data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const delEstadios = async (cod) => {
  const config = localConfig()

  const response = api.delete(`/estadio/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
