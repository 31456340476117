import React, { useEffect, useState } from 'react'
import dayjs, { Dayjs } from 'dayjs'
import 'dayjs/locale/pt-br'
import { GoogleMap } from '@react-google-maps/api'
import { Content } from 'antd/lib/layout/layout'
import { AxiosResponse } from 'axios'
import { Container, Painel, PainelNiver } from './styled'
import WeatherForecast from '../../../Components/previsao'
import ViewPage from '../../../Components/ViewPage'
import { useLogic } from '../../../context/useLogic'
import { buscaGoogleCity } from '../../../services/Afins'
import { useHistory, useLocation } from 'react-router'

import navigationConfig from '../../../Configs/NavegacaoAdmin'
import { log } from 'console'
import { set } from 'lodash'
import AniversariantesCard from '../../../Components/aniversariantes'
interface TypesNiver {
  key: number
  nome: string
  dataAniversario: string | Dayjs
  avatar: string
  typeNiver?: string
}

const Home: React.FC = () => {
  const [map, setMap] = useState<google.maps.Map>()
  const [time, setTime] = useState('fetching')
  

  const [shouldUpdateMap, setShouldUpdateMap] = useState<boolean>(true);
  const [dados, setDados] = useState([]);
  const [center, setCenter] = useState<google.maps.LatLngLiteral>({
    lat: -18.4590515,
    lng: -64.3517889,
  })
  const { dataClima } = useLogic()

  // const listEquipe = async () => {
  //   setLoading(true)
  //   const resp: AxiosResponse<TypesEquipe> = await getEquipe({ embed: 'usuario' })
  //   const dados = resp.data.itens.map((item) => {
  //     return {
  //       key: item.cod,
  //       dataAniversario: dayjs(item.usuario[0].nascimento).format('DD/MM'),
  //       nome: item.usuario_nome,
  //       avatar: item.usuario[0].doc_foto,
  //       typeNiver: '',
  //     }
  //   })
  //   const response = dados.filter((item) => item.dataAniversario === dayjs().format('DD/MM'))
  //   let filtrado = []
  //   for (let i = 0; i < response.length; i += 1) {
  //     const item = response[i]

  //     if (item.avatar !== null) {
  //       const foto = await getEquipeFiles({
  //         doc: item.avatar,
  //         docType: 'doc_foto',
  //         cod: item.key,
  //       })
  //       item.avatar = foto.url
  //     }

  //     filtrado = [...filtrado, item]
  //   }
  //   setDataEquipe(filtrado)
  //   setLoading(false)
  // }


  /*  useEffect para renderizar o mapa na dashboard */
  useEffect(() => {
    const fetchData = async () => {
      if (map && dataClima?.city) {
        try {
          const dados: AxiosResponse<any> = await buscaGoogleCity(
            dataClima.city.name,
            dataClima.city.uf
          );

          const geometryBounds = dados.data.results?.[0]?.geometry?.viewport;

          if (geometryBounds && geometryBounds.northeast) {
            const northeast = geometryBounds.northeast;
            const southwest = geometryBounds.southwest;

            // Calcular a diferença em latitude e longitude
            const latDiff = northeast.lat - southwest.lat;
            const lngDiff = northeast.lng - southwest.lng;

            // Calcular o nível de zoom
            const latZoom = Math.floor(Math.log2(360 / latDiff));
            const lngZoom = Math.floor(Math.log2(360 / lngDiff));

            // Usar o menor nível de zoom para garantir que toda a área seja visível
            const zoom = Math.min(latZoom, lngZoom);   

            // Criar limites para o mapa
            const bounds = new google.maps.LatLngBounds(
              new google.maps.LatLng(southwest.lat, southwest.lng),
              new google.maps.LatLng(northeast.lat, northeast.lng)
            );

            // Aplicar zoom e centralizar no mapa
            map.fitBounds(bounds);
            map.setZoom(13);
          } else {
            console.error("Não foi possível obter os limites do objeto geometryBounds");
          }
        } catch (error) {
          console.error("Erro ao buscar dados do Google Places:", error);
        }
      }
    };

    fetchData();
    setShouldUpdateMap(false);
  }, [map, dataClima, shouldUpdateMap]);


  return (
    <Container>
      {/* <span>{dayjs(time).format('LLLL')}</span> */}
      <ViewPage rotina={65} title='' btnClick={() => null} content={<></>} hidden />
      <Content style={{ maxWidth: '100%' }}>
        {/* @ts-ignore */}
        {

          <GoogleMap

            onLoad={(map) => {
              setMap(map)
              setShouldUpdateMap(true)
            }}
            mapContainerStyle={{
              width: '99.5%',
              height: '100%',
            }}

            options={{
              mapTypeId: 'hybrid',
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
          >
            <Painel>
              <WeatherForecast />
              
            </Painel>
            <PainelNiver>
              <AniversariantesCard />
            </PainelNiver>
          </GoogleMap>



        }

        {/* <Col flex='1 1 500px'>
            <Card loading={loading} title='Aniversariantes do Mês'>
              <Row gutter={[8, 8]}>
                {dataEquipe.map((data) => {
                  return (
                    <Col key={data.key} span={6}>
                      <Card key={data.key}>
                        <Col>
                          <Avatar size='large' src={data.avatar} />
                        </Col>
                        <Col>
                          <b style={{ fontSize: '13px' }}>{data.nome}</b>
                        </Col>
                        <Col>
                          {data.typeNiver ? (
                            <>
                              <FontAwesomeIcon icon={solid('handshake')} />{' '}
                              <span>{data.dataAniversario}</span>
                            </>
                          ) : (
                            <>
                              <FontAwesomeIcon icon={solid('birthday-cake')} />{' '}
                              <span>{data.dataAniversario}</span>
                            </>
                          )}
                        </Col>
                      </Card>
                    </Col>
                  )
                })}
              </Row>
            </Card>
          </Col> */}

        {/* <ApiRelatorio /> */}
      </Content>
    </Container>
  )
}

export default Home
