export const dadosFilter = [
  { cod: 1, nome: 'Implementação' },
  { cod: 2, nome: 'Correção' },
  { cod: 3, nome: 'Docs' },
  { cod: 4, nome: 'Visual' },
  { cod: 5, nome: 'Refatoração' },
  { cod: 6, nome: 'Teste' },
]

export const cores = {
  1: 'success',
  2: 'error',
  3: 'yellow',
  4: 'blue',
  5: 'warning',
  6: 'purple',
}

