import Icon from '@ant-design/icons'
import { Button, Drawer, Form, Select, Table } from 'antd'
import { Excel } from 'antd-table-saveas-excel'
import { IExcelColumn } from 'antd-table-saveas-excel/app'
import { RefSelectProps } from 'antd/lib/select'
import dayjs, { Dayjs } from 'dayjs'
import html2canvas from 'html2canvas'
import { jsPDF as JsPDF } from 'jspdf'
import autoTable from 'jspdf-autotable'
import { orderBy, uniq } from 'lodash'
import React, { useEffect, useRef, useState } from 'react'
import { useHistory } from 'react-router-dom'
import ViewPage from '../../../../Components/ViewPage'

import { SvgExcel, SvgPdf } from '../../../../assets/svg'
import { ReportFooter, ReportHeader } from '../../../../Components/CabecalhoRodape'
import DatePicker from '../../../../Components/DatePiker'
import { pagination } from '../../../../Components/Default'
import { useLogic } from '../../../../context/useLogic'
import { EstadioItens, getEstadios } from '../../../../services/estadio'
import { getMonitoramentoDatas } from '../../../../services/monitoramento'
import {
  getResumoMonitoramento,
  ItensMonitoramentoResumoTypes,
} from '../../../../services/monitoramento/resumo'
import { getPropriedade, PropriedadeItens } from '../../../../services/Propriedades'
import { getTalhaoCultivavel, TalhaoCultivaveisItens } from '../../../../services/TalhaoSafra'
import { Container } from './styles'

interface TypeFinish {
  codPropriedade: number
  codTalhao: number
  dataInicioMonitoramento: string
}

const print = async (nameFile) => {
  const htmlHeader = document.getElementById('reportHeader')
  const htmlFooter = document.getElementById('reportFooter')

  const tempDiv = document.getElementById('tempDiv')

  tempDiv.appendChild(htmlHeader)
  tempDiv.appendChild(htmlFooter)

  if (htmlHeader && htmlFooter) {
    const filename = nameFile

    const pdf = new JsPDF({ orientation: 'p', unit: 'pt', format: 'a4' })

    const options = { allowTaint: true, useCORS: true, logging: true, scale: 2 }

    const [canvasHeader, canvasFooter] = await Promise.all([
      html2canvas(htmlHeader, options),
      html2canvas(htmlFooter, options),
    ])

    const imgDataHeader = canvasHeader.toDataURL('image/png')
    const imgDataFooter = canvasFooter.toDataURL('image/png')

    autoTable(pdf, {
      html: '#printTable',
      headStyles: { fillColor: '#8BBEA3' },
      didDrawPage: () => {
        pdf.addImage(imgDataHeader, 'png', 20, 20, pdf.internal.pageSize.getWidth() - 40, 0)
        pdf.addImage(imgDataFooter, 'png', 20, 770, pdf.internal.pageSize.getWidth() - 40, 0)
      },
      margin: { top: 120, right: 20, left: 20, bottom: 70 },
    })
    pdf.save(filename)
  }
}

const ResumoMonitoramento: React.FC = () => {
  const { checkSafra } = useLogic()

  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens[]>([])
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataTalhao, setDataTalhao] = useState<TalhaoCultivaveisItens[]>([])
  const [dataMonitoramento, setDataMonitoramento] = useState<ItensMonitoramentoResumoTypes[]>([])
  const [fromValues, setFromValues] = useState<TypeFinish>({} as TypeFinish)
  const [dataEstadios, setDataEstadios] = useState<EstadioItens[]>([])
  const [datasMonitoradas, setDatasMonitoradas] = useState<string[]>([])
  const [estadios, setEstadios] = useState<string>()
  const codSafra = localStorage.getItem('codSafra')

  const history = useHistory()

  const [loading, setLoading] = useState(false)

  const refSelectPropriedade = useRef<RefSelectProps>()

  const [form] = Form.useForm<TypeFinish>()

  const listPropriedade = async () => {
    const resp = await getPropriedade('', '')
    const respFilter = resp.data.itens.filter((item) => item.status === 1)
   
    setDataPropriedade(respFilter)
  }

  const listEstadios = async () => {
    const resp = await getEstadios({ pagination: -1 })

    setDataEstadios(resp.data.itens)
  }

  const handleChangePropriedade = async (cod) => {
    form.resetFields(['dataInicioMonitoramento', 'codTalhao'])
    const resp = await getTalhaoCultivavel({ codPropriedade: cod })
    setDataTalhao(resp.data.itens)
  }

  const handleChangeTalhao = async (codTalhao) => {
    const codPropriedade = form.getFieldValue('codPropriedade')

    const resp = await getMonitoramentoDatas({ codPropriedade, codTalhao })

    setDatasMonitoradas(resp.data.itens.map((rec) => rec.data_inicio))
  }

  const handleDisableDate = (date: Dayjs) => {
    const resp = datasMonitoradas.filter(
      (rec) => dayjs(rec).format('DD/MM/YYYY') === date.format('DD/MM/YYYY')
    )
    return resp.length <= 0
  }

  const onFinish = async (values: TypeFinish) => {
    setLoading(true)
    values.dataInicioMonitoramento = dayjs(values.dataInicioMonitoramento).format('YYYY-MM-DD')
    setFromValues(values)
    setDataMonitoramento([])
    const resp = await getResumoMonitoramento({
      ...values,
      tipo: 2,
      embed: 'estadios_nc',
    })
    const dados = orderBy(resp.data.itens, 'pontos_acima', 'desc')
    setDataMonitoramento(dados)
    // setVisibleDrawer(true)
    setLoading(false)
  }

  const columns: IExcelColumn[] = [
    {
      title: String(dataTalhao.find((rec) => rec.cod === fromValues.codTalhao)?.nome),
      align: 'left',
      dataIndex: '',
      children: [
        {
          title: `Data do monitoramento: ${dayjs(fromValues.dataInicioMonitoramento).format(
            'DD/MM/YYYY'
          )}`,
          align: 'left',
          dataIndex: '',
          children: [
            {
              title: estadios,
              dataIndex: '',
              align: 'left',
              children: [
                {
                  title: 'Praga / Doença / Invasora',
                  align: 'left',
                  width: 400,
                  dataIndex: 'cod_especie',
                  render: (_, record) => {
                    let nome = ''
                    if (record.variacao_nome === null) {
                      nome = record.especie_nome
                    } else {
                      nome = `${record.especie_nome} / ${record.variacao_nome}`
                    }
                    return nome
                  },
                },
                {
                  title: 'Pontos',
                  align: 'center',
                  dataIndex: 'pontos',
                  width: 150,
                },
                {
                  width: 115,
                  align: 'center',
                  title: 'Abaixo do NC',
                  dataIndex: 'pontos_abaixo',
                },
                {
                  width: 115,
                  align: 'center',
                  title: 'Acima do NC',
                  dataIndex: 'pontos_acima',
                },
                {
                  width: 50,
                  align: 'center',
                  title: 'Méd',
                  dataIndex: 'media',
                },
                {
                  width: 50,
                  align: 'center',
                  title: 'Min',
                  dataIndex: 'minimo',
                },
                {
                  width: 50,
                  align: 'center',
                  title: 'Máx',
                  dataIndex: 'maximo',
                },
                {
                  width: 50,
                  align: 'center',
                  title: 'Un. Med.',
                  dataIndex: 'estadios_nc',
                  render: (data: ItensMonitoramentoResumoTypes['estadios_nc']) =>
                    data[0].nc[0].tipo_metrica_nome,
                },
              ],
            },
          ],
        },
      ],
    },
  ]

  useEffect(() => {
    setEstadios(
      `Estádio(s): ${uniq(
        dataMonitoramento.map(
          (rec) => dataEstadios.find((e) => e.cod === rec.estadios_nc[0].cod_estadio).nome
        )
      ).join(', ')}`
    )
  }, [dataMonitoramento])

  useEffect(() => {
    listPropriedade()
    listEstadios()
    refSelectPropriedade.current.focus()

    const idDaSafra = checkSafra('/monitoramento/resumo')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    listPropriedade()
    listEstadios()
  }, [codSafra])

  return (
    <Container>
      <ViewPage
        newHide={false}
        title='Resumo monitoramento'
        btnClick={() => null}
        content={<></>}
        btnsExta={
          <>
            <Form form={form} layout='inline' onFinish={onFinish}>
              <Form.Item
                name='codPropriedade'
                rules={[
                  {
                    required: true,
                    message: 'Insira a propriedade',
                  },
                ]}
              >
                <Select
                  ref={refSelectPropriedade}
                  showSearch
                  optionFilterProp='label'
                  style={{ width: '250px' }}
                  onChange={handleChangePropriedade}
                  placeholder='Propriedade'
                  options={dataPropriedade.map((rec) => {
                    return { label: rec.nome, value: rec.cod }
                  })}
                />
              </Form.Item>
              <Form.Item
                name='codTalhao'
                initialValue={null}
                rules={[
                  {
                    required: true,
                    message: 'Insira o talhão',
                  },
                ]}
              >
                <Select
                  disabled={form.getFieldValue('codPropriedade') === undefined}
                  showSearch
                  onChange={handleChangeTalhao}
                  optionFilterProp='label'
                  style={{ width: '250px' }}
                  placeholder='Talhão'
                  options={dataTalhao.map((rec) => {
                    return { label: rec.nome, value: rec.cod }
                  })}
                />
              </Form.Item>
              <Form.Item
                name='dataInicioMonitoramento'
                rules={[
                  {
                    required: true,
                    message: 'Insira data início',
                  },
                ]}
              >
                <DatePicker
                  disabled={form.getFieldValue('codTalhao') === null}
                  disabledDate={handleDisableDate}
                  placeholder='Data início'
                  style={{ width: '100%' }}
                />
              </Form.Item>
              <Form.Item>
                <Button loading={loading} htmlType='submit' type='primary'>
                  Gerar
                </Button>
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    border: '0',
                    verticalAlign: 'middle',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  title='Exportar para excel'
                  disabled={dataMonitoramento.length <= 0}
                  ghost
                  onClick={() => {
                    const excel = new Excel()
                    excel
                      .addSheet(
                        `${dataTalhao.find((rec) => rec.cod === fromValues.codTalhao)?.nome} ${
                          fromValues.dataInicioMonitoramento
                        }`
                      )
                      .addColumns(columns)
                      .addDataSource(dataMonitoramento)
                      .saveAs(
                        `${dataTalhao.find((rec) => rec.cod === fromValues.codTalhao)?.nome}.xlsx`
                      )
                  }}
                  icon={
                    <Icon component={() => <SvgExcel disable={dataMonitoramento.length <= 0} />} />
                  }
                />
              </Form.Item>
              <Form.Item>
                <Button
                  style={{
                    border: '0',
                    verticalAlign: 'middle',
                    display: 'flex',
                    justifyContent: 'center',
                    alignItems: 'center',
                  }}
                  title='Exportar para pdf'
                  onClick={() =>
                    print(dataTalhao.find((rec) => rec.cod === fromValues.codTalhao)?.nome)
                  }
                  disabled={dataMonitoramento.length <= 0}
                  ghost
                  icon={
                    <Icon
                      component={() => (
                        <SvgPdf fill={dataMonitoramento.length <= 0 ? '#7a7a7a' : '#FB3449'} />
                      )}
                    />
                  }
                />
              </Form.Item>
            </Form>
          </>
        }
      >
        {dataMonitoramento.length > 0 ? (
          <Table
            bordered
            loading={loading}
            style={{ width: 'auto' }}
            scroll={{ y: 'calc(90vh - 400px)' }}
            size='small'
            onRow={(data: ItensMonitoramentoResumoTypes) => {
              return {
                className: data.pontos_acima > 0 && '',
              }
            }}
            pagination={pagination}
            dataSource={dataMonitoramento}
            columns={columns}
          />
        ) : (
          <Table />
        )}
      </ViewPage>
      <Drawer
        size='default'
        forceRender
        width={900}
        open={visibleDrawer}
        closeIcon={false}
        onClose={() => setVisibleDrawer(false)}
      >
        <div>
          <ReportHeader
            id='reportHeader'
            title={dataPropriedade.find((rec) => rec.cod === fromValues.codPropriedade)?.nome}
          />

          {/* @ts-ignore */}
          <table id='printTable' border='1px solid #000000'>
            <thead>
              <tr>
                <th colSpan={9}>
                  {dataTalhao.find((rec) => rec.cod === fromValues.codTalhao)?.nome}
                </th>
              </tr>
              <tr>
                <th colSpan={9}>
                  Data do monitoramento:{' '}
                  {dayjs(fromValues.dataInicioMonitoramento).format('DD/MM/YYYY')}
                </th>
              </tr>
              <tr>
                <th colSpan={9}>Estadios</th>
              </tr>
              <tr>
                <th>Praga / Doença / Invasora</th>
                <th>Variação</th>
                <th>Pontos</th>
                <th>Abaixo do NC</th>
                <th>Acima do NC</th>
                <th>Méd</th>
                <th>Min</th>
                <th>Máx</th>
                <th>Un. Med.</th>
              </tr>
            </thead>
            <tbody>
              {dataMonitoramento.map((rec) => (
                <tr className={rec.pontos_acima > 0 && ''}>
                  <td>{rec.especie_nome}</td>
                  <td>{rec.variacao_nome === null ? '-' : rec.variacao_nome}</td>
                  <td>{rec.pontos}</td>
                  <td>{rec.pontos_abaixo}</td>
                  <td>{rec.pontos_acima}</td>
                  <td>{rec.media}</td>
                  <td>{rec.minimo}</td>
                  <td>{rec.maximo}</td>
                  <td>{rec.estadios_nc[0].nc[0].tipo_metrica_nome}</td>
                </tr>
              ))}
            </tbody>
          </table>

          <ReportFooter id='reportFooter' />
        </div>
      </Drawer>
      <div style={{ width: '900px' }} id='tempDiv' />
    </Container>
  )
}

export default ResumoMonitoramento
