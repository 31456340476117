import styled from 'styled-components'

export const Container = styled.div`

.log-user{
    display: flex;
    flex-direction: column;
    justify-content: flex-end;
    align-items: flex-end;
    /* height: 32px; */
    /* margin-top: -25px; */
    /* background-color: rebeccapurple; */
}
`
