import { api } from '../../api'

import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'

export interface TypeCalcario {
  itens: [ItensCalcario]
}

export interface ItensCalcario {
  cod: number
  cod_conta: number
  cod_fornecedor: number
  nome: string
  ca_o: string
  mg_o: string
  pn: string
  re: string
  prnt: string
  tipo: number
  create_time: string
  update_time: string
  delete_time: string
  fonecedor_nome: string
}

interface TypeQuery {
  codConta?: number | string
  codCliente?: number | string
  cod?: number
  codAtivo?: number
  pagination?: number | -1
  embed?: string
}

const url = '/recomendacao/produto-calculo'

export const getCalcario = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  const { conta, acesso, codCliente } = localConfig()

  api.defaults.headers.Acesso = acesso

  params = {
    pagination,
    codConta: conta,
    codCliente,
    ...params,
  }

  const response: AxiosResponse<TypeCalcario> = await api.get(url, {
    params,
  })

  return response
}

export const postCalcario = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCalcario> = await api.post(url, data, {
    params: {
      codConta: config.conta,
    },
  })

  return response
}

export const putCalcario = async (cod, data, params?: TypeQuery) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCalcario> = await api.put(`${url}/${cod}`, data, {
    params: {
      codConta: config.conta,
      ...params,
    },
  })

  return response
}

export const delCalcario = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeCalcario> = await api.delete(`${url}/${cod}`, {
    params: {
      codConta: config.conta,
    },
  })

  return response
}
