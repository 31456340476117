import ClassicEditor from '@ckeditor/ckeditor5-build-classic'
import '@ckeditor/ckeditor5-build-classic/build/translations/pt-br'
import { CKEditor } from '@ckeditor/ckeditor5-react'
import Editor from 'ckeditor5-custom-build/build/ckeditor'
import React from 'react'
import { Container } from './styled'

type Props = {
  onReady?: (data: ClassicEditor | any) => void
  onChange?: (event: any, editor: ClassicEditor | any) => void
  onBlur?: (event: any, editor: ClassicEditor | any) => void
  onFocus?: (event: any, editor: ClassicEditor | any) => void
  data?: string
}

const EditorText: React.FC<Props> = (props) => {
  const { onReady, onBlur, onChange, onFocus, data = '' } = props
  return (
    <Container height={400}>
      <CKEditor
        editor={Editor}
        config={{
          toolbar: {
            items: [
              'undo',
              'redo',
              'heading',
              '|',
              'fontSize',
              'bold',
              'italic',
              'bulletedList',
              'numberedList',
              '|',
              'outdent',
              'indent',
              '|',
              'blockQuote',
              'insertTable',
              'alignment',
              '|',
            ],
          },
          language: 'pt-br',
          table: {
            contentToolbar: ['tableColumn', 'tableRow', 'mergeTableCells'],
          },
        }}
        data={data}
        onReady={onReady}
        onChange={onChange}
        onBlur={onBlur}
        onFocus={onFocus}
      />
    </Container>
  )
}
export default EditorText
