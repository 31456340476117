
//STYLES
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { TabelaDetalhes, TabelaFazenda, TableFertilidade } from "./styles";
import { faCircleInfo } from "@fortawesome/free-solid-svg-icons";
import { Tooltip } from "antd";
import { legendas_mapa_indicadores } from "../../../components/cardLegenda/meta";
import { info_fazenda, info_detalhes, info_min_med_max, legendaClassificacao } from "./meta/tabelas";

//Props
interface Props {
    nome_cliente: string;
    nome_propriedade: string;
    logo_url?: string;
    nome_calcario_propriedade?: string;

    nome_talhao: string;
    data_coleta: string;
    profundidade: string;
    elemento_analisado: string;

    unidade?: string;
    nota_minima: string;
    nota_media: string;
    nota_maxima: string;
    cor_invertida?: boolean;

    isPrescricao?: boolean;
    isIndicadores?: boolean;

    colors?: string[];
    interpretado_minimo: number;
    interpretado_media: number;
    interpretado_maximo: number;
    cod_elemento: number;
    isInterpretado?: boolean;
    areaTalhao: number
}

const TabelaFertilidade: React.FC<Props> = ({ nome_cliente, nome_propriedade, logo_url, 
    nome_talhao, data_coleta, profundidade, elemento_analisado, unidade, nota_minima,
    nota_media, nota_maxima, cor_invertida, cod_elemento, isInterpretado,
    isPrescricao, isIndicadores, colors, interpretado_minimo, interpretado_media, interpretado_maximo, 
    areaTalhao, nome_calcario_propriedade
 }) => {

    function excecaoSilteAreia() {
        if (cod_elemento === 25 || cod_elemento === 26) {
            return true
        }
        return cor_invertida;
    }

    //PASSAR PROPS
    const props_detalhes = {
        nome_talhao: nome_talhao,
        data_coleta: data_coleta,
        profundidade: profundidade,
        elemento_analisado: elemento_analisado,
        isPrescricao: isPrescricao,
        isIndicadores: isIndicadores,
        nome_calcario_propriedade: nome_calcario_propriedade ?? 'Não informado'
    };
    let props_notas_interpretacao = {
        interpretado_minimo: interpretado_minimo,
        interpretado_media: interpretado_media,
        interpretado_maximo: interpretado_maximo,
        cor_invertida: cor_invertida,
        isInterpretado: isInterpretado,
        cod_elemento: cod_elemento,
        unidade: unidade,
        nota_minima: nota_minima,
        nota_media: nota_media,
        nota_maxima: nota_maxima,
        cores_do_mapa: colors,
        isIndicadores: isIndicadores,
        areaTalhao: areaTalhao,
        isPrescricao: isPrescricao
    }

    function styleNotas(descricao: 'Mínima'|'Média'|'Máxima'|'Unidade', nota: string|number) {
        return {
            display: (!(!!nota)) ? 'none' : 'flex',
            borderRight: (isPrescricao || !isInterpretado) ? 'none' : `3px solid ${legendaClassificacao(descricao, props_notas_interpretacao)?.hexadecimal}`,
            width: isPrescricao ? 'calc(100% - 90px)' : undefined
        }
    }
    function styleDescricao(descricao: 'Mínima'|'Média'|'Máxima'|'Unidade', nota: string|number) {
        let qtd_cores: number = colors?.length;
        let tres_cores = {
            Mínima: cor_invertida ? colors?.[2] : colors?.[0],
            Média:  colors?.[1],
            Máxima: cor_invertida ? colors?.[0] : colors?.[2],
        }
        if (!isInterpretado) {
            return {
                borderRight: `3px solid ${legendaClassificacao(descricao, props_notas_interpretacao)?.hexadecimal}`,
            }
        }
        return {
            borderRight: (isPrescricao || (isIndicadores && !(!!nota))) ? `3px solid ${(qtd_cores===3&&isIndicadores) ? tres_cores?.[descricao] : legendaClassificacao(descricao, props_notas_interpretacao)?.hexadecimal}` : 'none',
        }
    }
    function styleColunaClassificacao(descricao: 'Mínima'|'Média'|'Máxima'|'Unidade', nota: string|number, valor?: string) {
        if (!isInterpretado) {
            return {
                display: descricao==='Unidade' ? undefined : 'none'
            }
        }
        return {
            width: !(!!nota) ? 'calc(100% - 90px)' : undefined, display: (isPrescricao) ? ((descricao==='Unidade' && !!valor) ? undefined : 'none') : undefined 
        }
    }

    return (
        <TableFertilidade>
            <TabelaFazenda>
                <div className="TabelaFazenda__tabela">
                    {(info_fazenda(nome_cliente, nome_propriedade) ?? [])?.map(({ descricao, valor }, index)=> {
                        return (
                            <div className="TabelaFazenda__linha" key={index}>
                                <div className="TabelaFazenda__linha__desc">
                                    {descricao}
                                </div>
                                <div className="TabelaFazenda__linha__valor">
                                    {valor}
                                </div>
                            </div>
                        )
                    })}
                </div>
                <div className="TabelaFazenda__logo">
                    <img className="TabelaFazenda__logo__img" src={logo_url} alt="logo" />
                </div>
            </TabelaFazenda>

            <TabelaDetalhes>
                <div className="TabelaDetalhes__wrapper">
                    <div className="TabelaDetalhes__tabela" style={{width: '55%'}}>
                        {(info_detalhes(props_detalhes) ?? [])?.map(({ descricao, valor, small }, index)=> {
                            return (
                                <div className="TabelaDetalhes__linha" key={index}>
                                    <div className="TabelaDetalhes__linha__desc" style={{fontSize: small ? 12 : undefined}}>
                                        {descricao}
                                    </div>
                                    <div className="TabelaDetalhes__linha__valor" data-aviso={valor==='Não informado'}>
                                        {valor}
                                        <Tooltip title="Data de coleta não foi informada na OS">
                                            <span className="TabelaDetalhes__linha__valor_alerta" data-aviso={valor==='Não informado'}>{<FontAwesomeIcon icon={faCircleInfo} />}</span>
                                        </Tooltip>
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                    <div className="TabelaDetalhes__tabela" style={{width: '45%'}}>
                        {(info_min_med_max(props_notas_interpretacao) ?? [])?.map(({ descricao, valor, nota, esconder }, index)=> {
                            return (
                                <div className="TabelaDetalhes__linha" key={index}>
                                    <div className="TabelaDetalhes__linha__desc medias" style={styleDescricao(descricao, nota)} data-esconder={esconder}>
                                        {descricao}
                                    </div>
                                    <div className="TabelaDetalhes__linha__nota" style={styleNotas(descricao, nota)} data-esconder={esconder}>
                                        {String(nota)}
                                    </div>
                                    <div className="TabelaDetalhes__linha__valor medias" style={styleColunaClassificacao(descricao, nota, valor)} data-esconder={esconder}>
                                    {/* <div className="TabelaDetalhes__linha__valor medias"  data-esconder={esconder}> */}
                                        {valor}
                                    </div>
                                </div>
                            )
                        })}
                    </div>
                </div>
            </TabelaDetalhes>

        </TableFertilidade>
    )
};

export default TabelaFertilidade;