import { message } from "antd";
import { ItemRequest } from "../../../../../../../../services/logs";

export async function copyJson(request_selected: ItemRequest) {
    
    const { body } = request_selected?.descricao ?? {};

    let stringfy = JSON.stringify(body);

    try {
        await navigator.clipboard.writeText(stringfy);
        message.success('JSON body copiado com sucesso')
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
    }
}
