
import { AxiosResponse } from 'axios'
import { api } from '../../api'

import { localConfig } from '../../../Configs/localConfig'

export interface TypeGetMarcas {
  itens: [MarcasItens]
}

export interface MarcasItens {
  cod?: number
  nome?: string
  cod_tipo?: number
  outros_tipos?: number
  cod_categoria?: number
  geral?: number
  exibir_marca?: number
  categorias?: [
    {
      categoria_nome: string
      cod: number
      cod_categoria: number
      cod_tipo: number
      tipo_nome: string
    }
  ]
}

export const getCategoriaMarca = async (cod, codCategoria, codCategoriaExibir, order) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetMarcas> = await api.get(
    `/categoria-marca?codCategoria=${codCategoria}&cod=${cod}&codCategoriaExibir=${codCategoriaExibir}&pagination=-1&order=${order}`
  )

  return response
}

export const getCategoriaMarcaInsumo = async () => {
  const response: AxiosResponse<TypeGetMarcas> = await api.get(
    '/categoria-marca/insumo-recomendacao?pagination=-1'
  )

  return response
}

export const postCategoriaMarca = async (data) => {
  const response: AxiosResponse<TypeGetMarcas> = await api.post('/categoria-marca', data)

  return response
}

export const putCategoriaMarca = async (codMarca, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetMarcas> = await api.put(`/categoria-marca/${codMarca}`, data)

  return response
}

export const delCategoriaMarca = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetMarcas> = await api.delete(`/categoria-marca/${cod}`)

  return response
}
