
import styled from "styled-components";
import { useState } from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm, Tooltip } from "antd";
import { faFloppyDisk, faPencil, faXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
const BoxEditar = styled.div`
    width: 100%;
    height: 100%;
    font-size: 12px !important;
    display: flex;
    align-items: center;
    gap: 7px;
    white-space: normal;
    color: var(--text-color);
    text-align: left;
    line-height: 15px;
`;

//COMPONENTS
import TextArea from "../../../../../../CustomUi/textarea";
import ButtonCustom from "../../../../../../ButtonCustom";
import { Inline } from "../../../../../../CustomUi/inline";

//PROPS
interface Props {
    nome_talhao: string;
    setCurrent_row: React.Dispatch<React.SetStateAction<number|null>>;
    index_atual: number;
    ponto_info: any;
    salvarNomeEditado: Function;
    setPopup_isOpen: React.Dispatch<React.SetStateAction<boolean>>;
}

export const EditarNomeTalhao: React.FC<Props> = ({ nome_talhao, index_atual, setCurrent_row, ponto_info, salvarNomeEditado, setPopup_isOpen }) => {

    //STATES
    const [popup_editar, setPopup_editar] = useState<boolean>(false);
    const [novo_nome, setNovo_nome] = useState(nome_talhao);
    const [fake_loading, setFake_loading] = useState<boolean>(false);

    function finalizarFakeLoading() {
        setFake_loading(true);
        setTimeout(() => {
            setFake_loading(false);
            setPopup_editar(false);
            setPopup_isOpen(false);
        }, 2000);
    }

    return (
        <Tooltip title={nome_talhao} placement="right">
            <Popconfirm
                placement="topRight"
                arrow={{ pointAtCenter: true }}
                title={'Editar nome do talhão'}
                open={popup_editar}
                style={{transform: `translateY(${50})`}}
                description={
                    <div style={{ display: 'flex', flexDirection: 'column', gap: 15 }}>

                        <TextArea
                            value={novo_nome}
                            onChange={setNovo_nome}
                            placeholder="Novo nome"
                            resize={false}
                            height={40}
                        />

                        <Inline justifyContent="flex-end">

                            <ButtonCustom
                                onClick={() => { setPopup_editar(false);setPopup_isOpen(false) }}
                                type="tertiary"
                                danger
                                icon={<FontAwesomeIcon icon={faXmark} />}
                                disabled={fake_loading}
                            >
                                Cancelar
                            </ButtonCustom>

                            <ButtonCustom
                                onClick={() => {salvarNomeEditado(novo_nome, ponto_info);finalizarFakeLoading()}}
                                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                                loading={fake_loading}
                                disabled={fake_loading}
                            >
                                Salvar
                            </ButtonCustom>

                        </Inline>
                    </div>}
                okButtonProps={{ style: { display: 'none' } }}
                cancelButtonProps={{ style: { display: 'none' } }}
            >
                <Button style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }}>
                    <BoxEditar>
                        {nome_talhao}

                        <ButtonCustom
                            onClick={()=>{setPopup_editar(true);setCurrent_row(index_atual);setPopup_isOpen(true)}} 
                            icon={<FontAwesomeIcon icon={faPencil}/>} 
                            type="tertiary"
                        />
                    </BoxEditar>
                </Button>
            </Popconfirm>
                   
        </Tooltip>
        
    )
}