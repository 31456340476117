import styled from 'styled-components'
export const Container = styled.div<{ reverse?: boolean }>`
  position: relative;
  width: 100vw;
  min-height: 100vh;
  overflow: hidden;

  .print {
    overflow: hidden;
  }

  .spin {
    display: flex;
    min-height: 100vh;
    width: 100vw;
    justify-content: center;
    align-items: center;
  }
`
export const Tools = styled.div`
  position: fixed;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  align-items: center;
  overflow: hidden;
  top: 0;
  z-index: 9999;
  width: 100vw;
  height: 100vh;
  background-color: #fff;
  overflow: hidden;
  display: flex;

  h3 {
    width: 80vw;
    text-align: center;
  }

  img {
    width: 40vw;
  }

  button {
    display: flex;
    height: 90px !important;
    gap: 5px;
    font-size: 30px !important;
    justify-content: center;
    align-items: center;
  }
`
