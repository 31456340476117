import { faCalendarWeek, faCircleCheck, faCommentDots, faFlask, faSkullCrossbones } from "@fortawesome/free-solid-svg-icons";
import { ItemAtivoCadastrado } from "../../../../../../../services/recomendacao/ativos/novo-index";

export function obterTabsPermitidas(is_herbicida: boolean, id_tipo_principal: number|null) {
    let msg_erro:string = id_tipo_principal > 2 ? 'Item selecionado não é um Ativo ou Insumo' : 'Item selecionado não é Herbicida';
    let desabilitar_pb = id_tipo_principal > 2 ? true : !is_herbicida
    return [
        { icone: faSkullCrossbones, descricao: 'Incompatibilidade', task: () => { }},
        { icone: faFlask, descricao: 'Doses', task: () => { }, disabled: id_tipo_principal > 2, disabledMsg: msg_erro},
        { icone: faCommentDots, descricao: 'Observações', task: () => { }},
        { icone: faCalendarWeek, descricao: 'Plant back', task: () => { }, disabled: desabilitar_pb, disabledMsg: msg_erro},
        { icone: faCircleCheck, descricao: 'Compatibilidade', task: () => { }},
    ]
};



export function moverTabs(data_cadastrados: ItemAtivoCadastrado[]) {
    let compatibilidades = data_cadastrados?.filter(item=> item?.cod_tipo === 1);
    let incompatibilidades = data_cadastrados?.filter(item=> item?.cod_tipo === 2);
    let observacoes = data_cadastrados?.filter(item=> item?.cod_tipo === 3);
    let plantback = data_cadastrados?.filter(item=> item?.cod_tipo === 4);
    let doses = data_cadastrados?.filter(item=> item?.cod_tipo === 5);

    if ((incompatibilidades?.length ?? -1) > 0) {
        let tab = document.getElementById('btnTab_gaveta_0');
        tab?.click();

        return;
    }

    else if ((doses?.length ?? -1) > 0) {
        let tab = document.getElementById('btnTab_gaveta_1');
        tab?.click();

        return;
    }

    else if ((observacoes?.length ?? -1) > 0) {
        let tab = document.getElementById('btnTab_gaveta_2');
        tab?.click();

        return;
    }

    else if ((plantback?.length ?? -1) > 0) {
        let tab = document.getElementById('btnTab_gaveta_3');
        tab?.click();

        return;
    }

    else if ((compatibilidades?.length ?? -1) > 0) {
        let tab = document.getElementById('btnTab_gaveta_4');
        tab?.click();

        return;
    }

    let tab = document.getElementById('btnTab_gaveta_0');
    tab?.click();
}