import React from 'react'
import { configureStore } from '@reduxjs/toolkit'
import { useMonitoramento } from './Monitoramento'

const { monitoramentoReducer } = useMonitoramento()

export const store = configureStore({
  devTools: process.env.NODE_ENV !== 'production',
  reducer: monitoramentoReducer,
})

export type RootState = ReturnType<typeof store.getState>
export default store
