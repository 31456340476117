import React, { useEffect, useState } from 'react'

import { Container } from './styles'
import { DefaultMap } from '../../../../../Components/DefaultMap'
import { Polygon } from '@react-google-maps/api'
import { PropsDataTalhoesAll } from '..'
import { getBounds } from '../../../../../services/Afins'
import SidePainel from '../../../../../Components/SidePainel'
import { Table } from 'antd'

interface Props {
  open?: boolean
  onClose?: (data: boolean) => void
  dataTalhoes: PropsDataTalhoesAll[]
}

const MapaPropriedade: React.FC<Props> = (props) => {
  const { open, onClose, dataTalhoes } = props
  const [openSide, setOpenSide] = useState(false)
  const [map, setMap] = useState<google.maps.Map>()
  const [listTalhao, setListTalhao] = useState([])
  const [defaultBounds, setDefaultBounds] = useState<google.maps.LatLngBounds>()
  const [areaSelecionada, setAreaSelecionada] = useState(0)

  const handleSelectTableRow = (cods) => {
    setListTalhao(cods)
  }

  const handleClickPolygon = (cod) => {
    setListTalhao([cod])
    const divPro = document.getElementById(`p_${cod}`)

    divPro.scrollIntoView({ behavior: 'smooth', block: 'center' })
  }

  const handleClickSelectedRow = (cod: number) => {
    const list = listTalhao.filter((rec) => rec === cod)
    if (list.length > 0) {
      setListTalhao(listTalhao.filter((rec) => rec !== cod))
    } else {
      setListTalhao([...listTalhao, cod])
    }
  }

  useEffect(() => {
    if (map) {
      let polygons = []
      dataTalhoes.forEach((rec) => {
        polygons = [...polygons, ...rec.talhoes.map((item) => item)]
        return polygons
      })

      if (polygons.length > 0) {
        const bounds = getBounds(polygons)
        setDefaultBounds(bounds)
      }
    }
  }, [dataTalhoes])

  useEffect(() => {
    let propriedades: PropsDataTalhoesAll[] = []
    for (let i = 0; i < dataTalhoes.length; i += 1) {
      const data = dataTalhoes[i]

      listTalhao.forEach((t) => {
        if (data.cod === t) {
          propriedades = [...propriedades, data]
        }
      })
    }

    let polygons = []
    propriedades.forEach((rec) => {
      polygons = [...polygons, ...rec.talhoes.map((item) => item)]

      return polygons
    })

    // zoom conforme area selecionada
    // const bounds = getBounds(polygons)

    // if (map) {
    //   map.fitBounds(polygons.length <= 0 ? defaultBounds : bounds, { left: 250 })
    // }

    setAreaSelecionada(propriedades.reduce((a, b) => a + Number(b.area), 0))
  }, [listTalhao])

  useEffect(() => {
    if (map) {
      map.fitBounds(defaultBounds, { left: 250 })
    }
  }, [defaultBounds])

  return (
    <Container>
      <DefaultMap open={open} onClose={onClose} onMapLoad={setMap}>
        {dataTalhoes.map((rec, i) => {
          const selected = listTalhao.filter((f) => f === rec.cod)
          return (
            <Polygon
              key={String(i)}
              paths={rec.talhoes}
              onClick={() => handleClickPolygon(rec.cod)}
              options={{
                clickable: true,
                fillColor: selected.length > 0 ? 'orange' : 'cyan',
                strokeColor: selected.length > 0 ? 'orange' : 'cyan',
              }}
            />
          )
        })}
        <div
          style={{
            width: '450px',
            height: '95vh',
            position: 'absolute',
            left: 8,
            top: 8,
            overflowX: 'hidden',
          }}
        >
          <SidePainel
            hideSave
            placement='right'
            size='small'
            title='Propriedades'
            open={openSide}
            onCloseSide={(data) => setOpenSide(data)}
            onClose={(data) => onClose(data)}
            actions={[
              <b style={{ color: '#000', display: 'flex', flexDirection: 'column' }}>
                <span>Área selecionada </span>
                <span>{areaSelecionada.toLocaleString('pt-BR')}</span>
              </b>,
              <b style={{ display: 'flex', flexDirection: 'column', color: '#000' }}>
                <span>Área total</span>
                <span>
                  {dataTalhoes.reduce((a, b) => a + Number(b.area), 0).toLocaleString('pt-BR')}
                </span>
              </b>,
            ]}
          >
            <Table
              loading={dataTalhoes.length <= 0}
              rowKey='cod'
              size='small'
              pagination={false}
              onRow={(record) => {
                return {
                  onClick: () => {
                    handleClickSelectedRow(record.cod)
                  },
                  style: { cursor: 'pointer' },
                }
              }}
              columns={[
                {
                  title: 'Nome propriedade',
                  dataIndex: 'propriedade',
                  render: (nome, record) => (
                    <div
                      id={`p_${record.cod}`}
                      style={{ display: 'flex', flexDirection: 'column' }}
                    >
                      <span>{nome}</span>
                      <small style={{ opacity: 0.8 }}>Área/ha {record.area}</small>
                    </div>
                  ),
                },
                {
                  title: 'Qtde Talhões',
                  dataIndex: 'qtaTalhoes',
                },
              ]}
              dataSource={dataTalhoes}
              rowSelection={{
                type: 'checkbox',
                selectedRowKeys: listTalhao,
                onChange: (data) => handleSelectTableRow(data),
              }}
            />
          </SidePainel>
        </div>
      </DefaultMap>
    </Container>
  )
}

export default MapaPropriedade
