import styled from "styled-components";


export const TextAreaWrapper = styled.div`
    display: flex;
    flex-direction: column;
    gap: 2px;

    label.TextAreaWrapper__label {
        font-weight: 500;
        font-size: 13px;
        color: var(--text-color);
    }

    textarea.TextAreaWrapper__textarea {
        padding: 10px;
        width: 100%;
        min-height: 60px;
        height: 110px;
        border-radius: 5px;
        box-sizing: border-box;
        border: thin solid var(--border-color);
    }
    textarea.TextAreaWrapper__textarea:focus {
        border: thin solid var(--primary-color);
        outline: thin solid rgba(93, 165, 125, 0.2);
    }
    textarea.TextAreaWrapper__textarea::placeholder {
        font-size: 12px;
    }
    textarea.TextAreaWrapper__textarea:disabled {
        background-color: var(--disabled-color);
        color: var(--disabled-text-color);
        cursor: not-allowed;
    }

`