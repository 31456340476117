import styled from 'styled-components'

export const Container = styled.div`
  .form-item {
    margin-bottom: 0;
    div {
      .ant-form-item-label {
        width: 130px;
      }
    }
  }

  .ant-tabs-tabpane {
    padding-left: 0 !important;
  }

  .card-pai {
    border-radius: 0 0 0 0;

    .ant-card-head {
      /* background-color: red; */
      border-radius: 0 0 0 0;
    }
    .ant-card-body {
      /* background-color: green; */
    }
  }
  .permissao {
    width: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    justify-content: space-around;
    div.switch {
      width: 120px;
      display: flex;
      justify-content: space-between;
      align-items: center;
      span.label {
      }
    }
  }

  .ant-card-head-title {
    white-space: pre-line;
  }

  .input-text {
    display: flex;
    width: 100%;
    div {
      display: flex;
      flex-direction: column;
      width: 100%;
    }
  }
`
export const SwitchIco = styled.label`
  position: relative;
  display: inline-block;
  cursor: pointer;
  transition: 0.5ms;

  & input {
    opacity: 0;
    width: 0;
    height: 0;
  }

  &:hover {
    color: #161c0e;
  }
`
