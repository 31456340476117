import styled from "styled-components";
import { Tag } from "antd";
import { faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//APP
import { ListaObs } from "../../../..";

//STYLES
const SelecContainer = styled.div`
    width: 100%;
    box-sizing: border-box;
    padding: 15px 0px 15px 20px;
    border-left: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 10px;

    :is([data-disabled = true]) {
        opacity: 0.5;
        cursor: not-allowed;
    }
`;

//COMPONENTS
import { Box } from "../../../../../../../../../../Components/CustomUi/box";
import { TitleH3 } from "../../../../../../../../../../Components/CustomUi/titleH3";
import Label from "../../../../../../../../../../Components/Label";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";

//PROPS
interface Props {
    lista_obs: ListaObs[];
    removerDaListaObs: Function;
    disabled?: boolean;
}

export const Selecionados: React.FC<Props> = ({ lista_obs, removerDaListaObs, disabled }) => {
    return (
        <SelecContainer data-disabled={disabled}>
            <TitleH3>Observações Adicionadas</TitleH3>
            {(lista_obs ?? []).map(({ key, estadios, obs, nivel })=> {
                return (
                    <Box padding={15} key={key}>
                        <ButtonCustom 
                            onClick={()=>{removerDaListaObs(key)}}
                            icon={<FontAwesomeIcon icon={faTrash} />}
                            style={{alignSelf: 'flex-start'}}
                            type="tertiary"
                            danger
                            disabled={disabled}
                        >
                            Deletar
                        </ButtonCustom>

                        <Label fontSize={13}>Estádios selecionados</Label>
                        <div className="SelecContainer__itens">
                            {(estadios ?? []).map(({ descr, id })=> {
                                return (
                                    <Tag color="success" key={id}>
                                        {descr}
                                    </Tag>
                                )
                            })}
                        </div>

                        <TextArea
                            label={`Observação da dose`}
                            placeholder='Digite aqui a observação'
                            value={obs}
                            onChange={()=>{}}
                            resize={false}
                            height={70}
                            disabled={disabled}
                        />

                    </Box>
                )
            })}
            
        </SelecContainer>
    )
}