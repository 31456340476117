import { H4Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
    style?: React.CSSProperties
}

export const TitleH4: React.FC<Props> = ({ children, style }) => {
    return (
        <H4Component style={style}>
            {children}
        </H4Component>
    )
}