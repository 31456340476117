

export function typeMessage(type:'title'|'message', tipoMonitoramento: number|null): string {
    let title_msg = {
        null: 'Descartar pontos fora dos limites!',
        1: 'Descartar pontos fora da área da propriedade!',
        2: 'Descartar pontos fora da área do agrupamento!',
        3: 'Descartar pontos fora da área do talhão!',
        4: 'Descartar pontos fora dos limites da subárea!',
    };
    let msg = {
        null: '',
        1: 'da propriedade',
        2: 'do agrupamento',
        3: 'do talhão',
        4: 'da subárea',
    }
    
    return type==='title' ? title_msg?.[tipoMonitoramento] : msg?.[tipoMonitoramento];
}