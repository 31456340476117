import { faMapLocation } from '@fortawesome/free-solid-svg-icons'
import { Button, message, Table } from 'antd'
import React, { useEffect, useState } from 'react'
import { useHistory } from 'react-router-dom'
import DataMonitoramento from '../../../Components/monitoramento'
import ViewPage from '../../../Components/ViewPage'
import { PermissoesType } from '../../../services/perfil'

import { pagination } from '../../../Components/Default'
import Icons from '../../../Components/Icons'
import { useLogic } from '../../../context/useLogic'
import { tryError } from '../../../services/Afins'
import {
  getMoniNivelControleItem,
  MoniNivelItemControleItens,
} from '../../../services/monitoramento/nivelControleItens'
import { getPropriedade, PropriedadeItens } from '../../../services/Propriedades'
import { getTalhaoCultivavel } from '../../../services/TalhaoSafra'
import { Container } from './styles'

const Monitoramento: React.FC = () => {
  const { checkSafra } = useLogic()

  const history = useHistory()

  const [permission, setPermission] = useState<PermissoesType>()
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [dataPropriedade, setDataPropriedade] = useState<PropriedadeItens[]>([])
  const [propriedadeSelect, setPropriedadeSelect] = useState<PropriedadeItens>(null)
  const [dataNivelControle, setDataNivelControle] = useState<MoniNivelItemControleItens[]>([])
  const [loading, setLoading] = useState(false)
  const codSafra = localStorage.getItem('codSafra')

  const listPropriedade = async () => {
    try {
      setLoading(true)
      const codSafra = localStorage.getItem('codSafra')
      const codCliente = null
      const resp = await getPropriedade('', '', codCliente, codSafra)
      const dados = resp.data.itens

      // for (let i = 0; i < dados.length; i += 1) {
      //   const pro = dados[i]
      //   const res = await getTalhaoCultivavel({ codPropriedade: pro.cod, embed: 'sub_area' })
      //   const cultivavel = res.data.itens

      //   let subArea = []

      //   cultivavel.forEach((rec) => {
      //     subArea = [...subArea, ...rec.sub_area]

      //     return subArea
      //   })

      //   if (subArea.length > 0) {
      //     cultivavel.push(...subArea)
      //   }
      //   for (let ii = 0; ii < cultivavel.length; ii += 1) {
      //     const tal = cultivavel[ii]

      //     if (tal.cod_talhao !== null) {
      //       tal.isSubArea = true
      //     } else {
      //       tal.isSubArea = false
      //     }

      //     // @ts-ignore
      //     tal.kml.coordenadas = tal.kml.coordenadas.map((item) => {
      //       return { lat: item.latitude, lng: item.longitude }
      //     })
      //   }

      //   pro.talhao = cultivavel
      // }
      const dadosFiltrados = dados.filter((item) => item.status === 1)

      setDataPropriedade(dadosFiltrados)

      setLoading(false)
    } catch (error) {
      tryError(error)
    }
  }

  const listNivelControle = async () => {
    const resp = await getMoniNivelControleItem({ pagination: -1 })
    setDataNivelControle(resp.data.itens)
  }

  const addTalhaoPro = async (cod) => {
    const filtrarCod = dataPropriedade.find((item) => item.cod === cod)
    const dados = filtrarCod

    const pro = dados
    const res = await getTalhaoCultivavel({ codPropriedade: pro.cod, embed: 'sub_area' })
    const cultivavel = res.data.itens
    message.loading('Gerando mapa...')

    let subArea = []

    cultivavel.forEach((rec) => {
      subArea = [...subArea, ...rec.sub_area]

      return subArea
    })

    if (subArea.length > 0) {
      cultivavel.push(...subArea)
    }
    for (let ii = 0; ii < cultivavel.length; ii += 1) {
      const tal = cultivavel[ii]

      if (tal.cod_talhao !== null) {
        tal.isSubArea = true
      } else {
        tal.isSubArea = false
      }

      // @ts-ignore
      tal.kml.coordenadas = tal.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    }

    pro.talhao = cultivavel

    setPropriedadeSelect(dados)

    message.destroy()
  }

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleClickPropriedade = (cod) => {
    message.loading('Aguarde...', 999999)
    setVisibleDrawer(true)
    addTalhaoPro(cod)
    message.destroy()
  }

  const handleCloseDrawer = (bol: boolean) => {
    setVisibleDrawer(bol)
    setPropriedadeSelect(null)
  }
  useEffect(() => {
    listPropriedade()

    listNivelControle()

    const idDaSafra = checkSafra('/monitoramento/mapadecalor')

    if (!idDaSafra) {
      history.push('/home')
    }
  }, [])

  useEffect(() => {
    setLoading(true)
    listPropriedade()
  }, [codSafra])

  return (
    <Container>
      <ViewPage
        rotina={73}
        newHide
        onPermission={setPermission}
        title='Mapa de calor'
        btnClick={() => handleClickNew()}
        content={
          <DataMonitoramento
            propriedade={propriedadeSelect}
            nivelControle={dataNivelControle}
            visible={visibleDrawer}
            onClose={handleCloseDrawer}
            bookApp={0}
            resumir={false}
          />
        }
      >
        <Table
          loading={loading}
          dataSource={dataPropriedade}
          size='small'
          onRow={(record) => {
            return {
              onDoubleClick: () => handleClickPropriedade(record.cod),
            }
          }}
          bordered
          pagination={pagination}
          scroll={{ y: 'calc(90vh - 275px)' }}
          columns={[
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 2,
              dataIndex: 'cidade',
              title: 'Cidade',
              render: (value) => value[0].nome,
            },
            {
              key: 3,
              dataIndex: 'cidade',
              title: 'Estado',
              render: (value) => value[0].uf_sigla,
            },
            // {
            //   key: 3,
            //   dataIndex: 'talhao',
            //   title: 'Talhões',
            //   render: (value) => value.filter((rec) => !rec.isSubArea).length,
            // },
            // {
            //   key: 4,
            //   dataIndex: 'talhao',
            //   title: 'Subárea',
            //   render: (value) => value.filter((rec) => rec.isSubArea).length,
            // },
            {
              key: 5,
              width: 45,
              dataIndex: 'cod',
              render: (cod, record) => {
                return (
                  <Button
                    onClick={() => {
                      handleClickPropriedade(record.cod)
                    }}
                    shape='circle'
                    icon={<Icons icon={faMapLocation} />}
                  />
                )
              },
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default Monitoramento
