
import { api } from '../../api'

export interface TypeRecomendaAcaoTipos {
  itens: [RecomendaAcaoTipos]
}

export interface RecomendaAcaoTipos {
  nome: string
  cod_tipo: number
}

interface TypeQuery {
  codTipo?: number
  nome?: string
  cod?: number
  pagination?: number | -1
}

const url = '/recomendacao/acao-ativos'

export const getRecomendaAcaoAtivos = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    ...params,
    pagination,
  }

  const response = api.get(url, {
    params,
  })

  return response
}

export const postRecomendaAcaoAtivos = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putRecomendaAcaoAtivos = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delRecomendaAcaoAtivos = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
