import React, { useEffect, useState } from 'react'

import { Button, Drawer, Form, Input, InputNumber, Select, Space } from 'antd'
import { tryError } from '../../services/Afins'
import { FornecedorItens } from '../../services/Fornecedores'
import {
  ItensCalcario,
  getCalcario,
  postCalcario,
  putCalcario,
} from '../../services/recomendacao/calcario'
import { Notification } from '../notification'
import { Container } from './styled'
import DiasDesdeData from '../DiasDesdeData'

type Props = {
  open?: boolean
  onClose?: (data: boolean) => void
  dataFornecedor: FornecedorItens[]
  update?: number
  setUpdate?: (data: number) => void
  refresh?: () => void
  dataCalcario: any
}

const formateInput = {
  // formatter: (value) => `${value}`.replace(/\B(?=(\d{2})+(?!\d))/g, '.'),
  // parser: (value) => value!.replace(/\$\s?|(,*)/g, ''),
}

const FormCalcario: React.FC<Props> = (props) => {
  const {
    open = false,
    onClose,
    dataFornecedor,
    dataCalcario,
    update = 0,
    setUpdate,
    refresh,
  } = props

  const [loading, setLoading] = useState(false)

  const [form] = Form.useForm<ItensCalcario>()
  const [logUser, setLogUser] = useState(false)
  const [usuarioQueCriou, setUsuarioQueCriou] = useState('')
  const [usuarioQueAlterou, setUsuarioQueAlterou] = useState('')
  const [dataCriacao, setDataCriacao] = useState('')
  const [horarioDeCriacao, setHorarioDeCriacao] = useState('')
  const [horarioDeAtualizacao, setHorarioDeAtualizacao] = useState('')
  const [dataAlteracao, setDataAlteracao] = useState('')
  const [diasDesdeCriacao, setDiasDesdeCriacao] = useState(null)

  const handleDataFromCreation = (dias) => {
    setDiasDesdeCriacao(dias)
  }

  const onFinish = async (data: ItensCalcario) => {
    try {
      if (update <= 0) {
        await postCalcario(data)

        Notification({
          type: 'success',
          descricao: 'Calcário salvo com sucesso',
          message: 'Sucesso',
        })
        form.resetFields()
      } else {
        await putCalcario(update, data)

        Notification({
          type: 'success',
          descricao: 'Calcário atualizado com sucesso',
          message: 'Sucesso',
        })
      }
      refresh?.()
    } catch (error) {
      tryError(error)
    }
  }

  const handleUpdateCalcario = async (cod: number) => {
    setLoading(true)
    const resp = await getCalcario({ cod })

    form.setFieldsValue(resp.data.itens[0])
    setLoading(false)
  }

  useEffect(() => {
    if (update > 0) {
      handleUpdateCalcario(update)
    }
  }, [update])

  useEffect(() => {
    if (!open) {
      form.resetFields()
      setUpdate(0)
    }
  }, [open])

  useEffect(() => {
    if (open) {
      const calcarioCheck = dataCalcario.filter((item) => item.cod === update)

      if (calcarioCheck.length > 0) {
        if (calcarioCheck[0].create_user) {
          setUsuarioQueCriou(calcarioCheck[0].create_user)

          const dateOnlyString = calcarioCheck[0].create_time.split(' ')[0]
          const timeOnlyString = calcarioCheck[0].create_time.split(' ')[1]

          setDataCriacao(dateOnlyString)
          setHorarioDeCriacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }

        if (calcarioCheck[0].update_user) {
          setUsuarioQueAlterou(calcarioCheck[0].update_user)

          const dateOnlyString = calcarioCheck[0].update_time.split(' ')[0]
          const timeOnlyString = calcarioCheck[0].update_time.split(' ')[1]
          setDataAlteracao(dateOnlyString)
          setHorarioDeAtualizacao(timeOnlyString)
          setLogUser(true)
        } else {
          setLogUser(false)
        }
      }

    } else {
      setUsuarioQueCriou('')
      setUsuarioQueAlterou('')
    }

    if (update === 0) {
      setLogUser(false)
    }
  }, [logUser, open, usuarioQueAlterou, usuarioQueCriou, update, horarioDeCriacao])

  function quandoFoiCriado(diasDesdeCriacao:number|null, horarioDeCriacao: string|null): string {
    if (diasDesdeCriacao !== null && diasDesdeCriacao !== 0) {
      return `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
    }

    return `às ${horarioDeCriacao}h`
  }
  function quandoFoiAtualizado(diasDesdeCriacao:number|null, horarioDeAtualizacao: string|null): string {
    if (diasDesdeCriacao !== null && diasDesdeCriacao !== 0) {
      return `há ${diasDesdeCriacao} ${diasDesdeCriacao > 1 ? 'dias' : 'dia'}`
    }

    return `às ${horarioDeAtualizacao}h`
  }

  return (
    <Container>
      <Drawer
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            {update ? 'Atualizar Calcário' : 'Cadastrar Calcário'}
            {logUser && update > 0 && usuarioQueAlterou !== '' ? (
              <>
                <DiasDesdeData
                  dataCriacao={dataAlteracao}
                  onDataFromCreation={handleDataFromCreation}
                />
                <small style={{ opacity: '0.5', fontSize: '10px' }}>
                  atualizado por: {usuarioQueAlterou}{' '}
                  {quandoFoiAtualizado(diasDesdeCriacao, horarioDeAtualizacao)}
                </small>
              </>
            ) : !logUser && update > 0 && (
              <>
                {' '}
                {usuarioQueCriou !== '' &&(
                  <small style={{ opacity: '0.5', fontSize: '10px' }}>
                    criado por: {usuarioQueCriou}{' '}
                    {quandoFoiCriado(diasDesdeCriacao, horarioDeAtualizacao)}
                  </small>
                )}
              </>
            )}
          </div>
        }
        forceRender
        open={open}
        onClose={() => onClose?.(false)}
        placement='left'
        getContainer={false}
        closeIcon={false}
        footer={
          <Space
            style={{
              display: 'flex',
              justifyContent: 'space-between',
            }}
          >
            <div>
              <Button
                style={{
                  margin: '0 5px',
                }}
                onClick={() => form.submit()}
                type='primary'
              >
                Salvar
              </Button>
              <Button onClick={() => onClose?.(false)} type='primary' danger>
                Sair
              </Button>
            </div>
          </Space>
        }
      >
        <Form disabled={loading} form={form} onFinish={onFinish} layout='vertical'>
          <Form.Item label='Nome' name='nome'>
            <Input />
          </Form.Item>
          <Form.Item label='Fornecedor' name='cod_fornecedor'>
            <Select
              popupMatchSelectWidth={false}
              showSearch
              optionFilterProp='label'
              options={dataFornecedor.map((rec) => {
                return { label: rec.nome, value: rec.cod }
              })}
            />
          </Form.Item>
          <Form.Item name='ca_o' label='Óxido de Calcio'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item name='mg_o' label='Óxido de Magnésio'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item name='pn' label='Poder de Neutralização'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item name='re' label='Poder Relativo de Neutralização Total'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item name='prnt' label='Reatividade'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item name='peneira_fundo' label='Peneira de fundo'>
            <InputNumber {...formateInput} />
          </Form.Item>
          <Form.Item initialValue={1} name='tipo' />
        </Form>
      </Drawer>
    </Container>
  )
}
export default FormCalcario
