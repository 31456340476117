import { Button, Empty } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useState, useEffect } from "react";
import { DndContext, closestCorners } from "@dnd-kit/core"; 
import { faArrowUpRightDots, faFileLines, faFloppyDisk, faSeedling } from "@fortawesome/free-solid-svg-icons";
import { SortableContext, verticalListSortingStrategy, arrayMove } from "@dnd-kit/sortable";

//APP
import { usePostSalvarConfigBook } from "../../../../../../services/novoConfigBook/post";
import { adaptarLista, encontrarElemento, filtrarSecao, header_tabela, obterProfundidades, obterTipoDeMapas, obterVisibilidade } from "./meta";
import { useGetElementosFertilidade, Item__MapaFertilidade__Config, useGetParametrosFertilidadeRef, ParametroFertilidade__Itens} from "../../../../../../services/novoConfigBook/get";

//STYLES
import { Conteudo } from "../../styled"

//COMPONENTS
import Tabs from "../../../../../../Components/Tabs";
import Input from "../../../../../../Components/CustomUi/input";
import Carregando from "../../../../../../Components/CustomUi/loading";
import GavetaElementos from "../gavetaElement";
import { ItemLista } from "./components/itemLista";

//PROPS
interface Props {
    texto_introducao: string;
    setTexto_introducao: React.Dispatch<React.SetStateAction<string>>;
    lista_elementos: Item__MapaFertilidade__Config[];
    setLista_elementos: React.Dispatch<React.SetStateAction<Item__MapaFertilidade__Config[]>>;
    elementos_base: ParametroFertilidade__Itens[];
    setElementos_base: React.Dispatch<React.SetStateAction<ParametroFertilidade__Itens[]>>;
}

const ConfigElementos: React.FC<Props> = ({ texto_introducao, setTexto_introducao, lista_elementos, setLista_elementos, 
    elementos_base, setElementos_base
 }) => {

    //STATES
    const [tab_container_atual, setTab_container_atual] = useState<'Fertilidade' | 'Indicadores' | 'Prescrições'>('Fertilidade');
    const [buscar, setBuscar] = useState<string>('');
    const [lista_elementos_api, setlista_elementos_api] = useState<Item__MapaFertilidade__Config[]>([]);
    const [permitir_click, setPermitir_click] = useState<boolean>(false);
    const [abrir_gaveta, setAbrir_gaveta]= useState<boolean>(false);
    //STATES - LISTAGEM
    const [ordenm_original, setOrdemOriginal] = useState<ParametroFertilidade__Itens[]>([]);
    //STATES - INFORMAÇÕES
    const [info_cod_elemento, setInfo_cod_elemento] = useState<number | null>(null);
    const [info_nome_elemento, setInfo_nome_elemento] = useState<string>('');
    const [id_posicao, setId_posicao] = useState<number>(1000);
    const [info_ordem, setInfo_ordem] = useState<number>(1000);
    const [info_tipos_mapa, setInfo_tipos_mapa] = useState([]);
    const [info_profundidades, setInfo_profundidades] = useState([]);

    //API
    const { data_elementos, carregando_elementos, refetch_data_elementos } = useGetElementosFertilidade();
    const { data_paramentros, carregando_parametros, isFetched } = useGetParametrosFertilidadeRef();
    const { callUsePostSalvarConfigBook, carregando_salvar } = usePostSalvarConfigBook(
        1, 
        texto_introducao, 
        lista_elementos, 
        setAbrir_gaveta, 
        refetch_data_elementos, 
        elementos_base
    );
    
    const getPosicaoLista = (id: number) => elementos_base.findIndex(item=> item.id === id)

    const handleDragEnd = (event) => {
        const { active, over } = event;

        if (active.id === over.id) return;

        setElementos_base((elementos_base)=> {
            const posicaoOriginal = getPosicaoLista(active.id);
            const novaPosicao = getPosicaoLista(over.id);
            return arrayMove(elementos_base, posicaoOriginal, novaPosicao)
        })
    }

    useEffect(() => {
        setlista_elementos_api(data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []);
        setLista_elementos(data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []);
        setTexto_introducao(data_elementos?.itens?.[0]?.mapa_fertilidade?.texto_introducao );
    }, [data_elementos]);

    useEffect(() => {
        adaptarLista(isFetched, (data_paramentros?.itens), setElementos_base, (data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []));
        adaptarLista(isFetched, (data_paramentros?.itens), setOrdemOriginal, (data_elementos?.itens?.[0]?.mapa_fertilidade?.configuracoes ?? []));
    }, [data_paramentros]);
 
    let listaFiltrada = filtrarSecao(elementos_base, tab_container_atual)?.filter(encontrarElemento(buscar));
        
    return (
        <Conteudo>
            <h2>Lista de elementos</h2>       

            <div className="Conteudo__filtros">
                {abrir_gaveta &&(
                    <GavetaElementos
                        visible={abrir_gaveta}
                        onClose={setAbrir_gaveta}
                        parametros={data_paramentros?.itens}
                        listaElementos={lista_elementos}
                        nomeElemento={info_nome_elemento}
                        codElemento={info_cod_elemento}
                        secao_atual={tab_container_atual}
                        textoIntroducao={texto_introducao}
                        callUsePostSalvarConfigBook={callUsePostSalvarConfigBook}
                        isFetching={carregando_salvar}
                        id_posicao={id_posicao}
                        info_ordem={info_ordem}
                        info_tipos_mapa={info_tipos_mapa}
                        info_profundidades={info_profundidades}
                        elementosBase={elementos_base}
                    />
                )}
                
                <Tabs
                    width={530}
                    tabs={[
                        { descricao: 'Fertilidade', icone: faSeedling },
                        { descricao: 'Indicadores', icone: faArrowUpRightDots },
                        { descricao: 'Prescrições', icone: faFileLines },
                    ]}
                    indexInicial={0}
                    selecionado={tab_container_atual}
                    onChange={setTab_container_atual}
                />

                <Input 
                    placeholder="Buscar elemento"
                    value={buscar}
                    onChange={setBuscar}
                />
            </div>

            {(carregando_elementos || carregando_parametros) 
            ?
                <Carregando 
                    height={400}
                    justifyContent="center"
                    legenda="Carregando elementos..."
                    animation
                />
            :
                <div className="Conteudo__tabela">
                    <div className="Conteudo__tabela__header">
                        {header_tabela(tab_container_atual).map(({ titulo, width, display }, index)=>{
                            return (
                                <div 
                                    className="Conteudo__tabela__header__item" 
                                    style={{width: width, minWidth: width, display: display}} 
                                    key={index}
                                >
                                    {titulo}
                                </div>
                            )
                        })}
                    </div>

                    <DndContext collisionDetection={closestCorners} onDragEnd={handleDragEnd}>
                    <div className="Conteudo__tabela__body" >

                        {listaFiltrada.length === 0 &&(
                            <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={<><b>'{buscar}'</b> não foi encontrado!</>} />
                        )}
                       
                        <SortableContext items={elementos_base} strategy={verticalListSortingStrategy} disabled={permitir_click}>
                            {filtrarSecao(elementos_base, tab_container_atual)?.filter(encontrarElemento(buscar)).map(({ id, cod, descricao, parametros }, index, atual)=> {
                                return (
                                    <ItemLista 
                                        key={index}
                                        id={id}
                                        index={index + 1}
                                        elemento={descricao}
                                        mapa={obterTipoDeMapas(parametros, lista_elementos?.filter((i)=>i.elemento == cod), tab_container_atual)}
                                        profundidade={obterProfundidades(parametros, lista_elementos?.filter((i)=>i.elemento == cod))}
                                        abrirGaveta={setAbrir_gaveta}
                                        setPermitir_click={setPermitir_click}
                                        setInfo_cod_elemento={setInfo_cod_elemento}
                                        tab_secao={tab_container_atual}
                                        codElemento={cod}
                                        setInfo_nome_elemento={setInfo_nome_elemento}
                                        setInfo_tipos_mapa={setInfo_tipos_mapa}
                                        setInfo_profundidades={setInfo_profundidades}
                                        setId_posicao={setId_posicao}
                                        setInfo_ordem={setInfo_ordem}
                                        secao_visivel={obterVisibilidade(lista_elementos?.filter((i)=>i.elemento == cod))}
                                        ordem_original={filtrarSecao(ordenm_original, tab_container_atual)?.filter(encontrarElemento(buscar))}
                                        tamanho_lista={atual?.length ?? 0}
                                    />
                                )
                            })}
                        </SortableContext>
                    </div>
                    </DndContext>
                </div>
            }
            
            <div className="Conteudo__footer">
                <Button 
                    type="primary" 
                    icon={<FontAwesomeIcon icon={faFloppyDisk} />} 
                    onClick={()=>callUsePostSalvarConfigBook()}
                    loading={carregando_salvar}
                    disabled={(JSON.stringify(ordenm_original))===(JSON.stringify(elementos_base))}
                >
                    Salvar nova ordem
                </Button>
            </div>

        </Conteudo>
    )
};

export default ConfigElementos