
import { CSS } from "@dnd-kit/utilities";
import { useState } from "react";
import { useSortable } from "@dnd-kit/sortable";
import { Tag, Tooltip } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCaretUp, faPenToSquare, faSort } from "@fortawesome/free-solid-svg-icons";

//APP
import { leadZero } from "../../../../../../../../utils";
import { TypeobterProfundidades } from "../../meta";
import { ParametroFertilidade__Itens } from "../../../../../../../../services/novoConfigBook/get";

//COMPONENTS
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";

//PROPS
interface Props {
    id: number;
    codElemento: number;
    elemento: string;
    mapa: {descricao: string, cod_tipo_mapa: number, ativo: boolean, visivel: boolean}[];
    profundidade: TypeobterProfundidades[];
    index: number;
    abrirGaveta: React.Dispatch<React.SetStateAction<boolean>>;
    setPermitir_click: React.Dispatch<React.SetStateAction<boolean>>;
    setInfo_cod_elemento: React.Dispatch<React.SetStateAction<number>>;
    setInfo_nome_elemento: React.Dispatch<React.SetStateAction<string>>;
    tab_secao: 'Fertilidade' | 'Indicadores' | 'Prescrições';
    setInfo_tipos_mapa: Function;
    setInfo_profundidades: Function;
    setId_posicao: Function;
    setInfo_ordem: Function;
    secao_visivel: boolean;
    ordem_original: ParametroFertilidade__Itens[];
    tamanho_lista?: number;
}

export const ItemLista: React.FC<Props> = ({ id, elemento, mapa, profundidade, index, abrirGaveta, setPermitir_click, 
    setInfo_cod_elemento, tab_secao, codElemento, setInfo_nome_elemento, setInfo_tipos_mapa, setInfo_profundidades, 
    setId_posicao, setInfo_ordem, secao_visivel, ordem_original, tamanho_lista
 }) => {
    
    //SORTABLE
    const { attributes, listeners, setNodeRef, transform, transition } = useSortable({id});
    const styleItem = {
        transition,
        transform: CSS.Transform.toString(transform)
    }

    //STATES
    const [efeito_hover, setEfeito_hover] = useState<boolean>(true);

    function ordemOriginal(): number {
        let elemento = ordem_original.findIndex((item)=> item.cod == codElemento);
        return elemento + 1
    }

    return (
        <div className={`Conteudo__tabela__body__row ${efeito_hover?'':'non-active'}`} ref={setNodeRef} {...attributes} {...listeners} style={styleItem} key={id}>
            <div className="Conteudo__tabela__body__row__item ordem">
                <FontAwesomeIcon icon={faSort} />
                {leadZero(index)}°
                {ordemOriginal() !== index &&(
                    <div className="nova_ordem" data-change={index < ordemOriginal() ? 'subiu' : 'desceu'}>
                        <FontAwesomeIcon icon={faCaretUp} />
                        {(index < ordemOriginal()) ? (ordemOriginal() - index) : (index - ordemOriginal())}
                    </div>
                )}
                
            </div>
            <Tooltip title={secao_visivel ? null :"Este elemento não está sendo exibido no book"} placement="topLeft">
                <div className="Conteudo__tabela__body__row__item elemento" data-tab={tab_secao} data-visivel={secao_visivel} data-encalso={tamanho_lista <= 5}>
                    {elemento}
                </div>
            </Tooltip>
            <div className="Conteudo__tabela__body__row__item mapa" data-tab={tab_secao} data-encalso={tamanho_lista <= 5}>
                {(mapa ?? []).map(({ descricao, ativo, visivel }, index)=> {
                    return (
                        <Tag 
                            style={{opacity: secao_visivel ? (!ativo ? 0.4 : (visivel ? 1 : 0.4)) : 0.4}} 
                            bordered={ativo} 
                            color={!ativo ? 'default' : (descricao==='Interpretado' ? 'success' : "processing")} 
                            key={index}
                        >
                            {descricao}
                        </Tag>
                    )
                })}
            </div>
            <div className="Conteudo__tabela__body__row__item profundidade" data-tab={tab_secao} data-encalso={tamanho_lista <= 5}>
                {(profundidade ?? []).map(({ profundidade_nome, tipo_mapa_nome, visivel, ativo }, index)=> {
                    return (
                        <Tag 
                            style={{opacity: secao_visivel ? (!visivel && 0.4) : 0.4}} 
                            bordered={ativo} 
                            color={!ativo ? 'default' : (tipo_mapa_nome==='Interpretado' ? 'success' : "processing")} 
                            key={index}
                        >
                            {profundidade_nome}
                        </Tag>
                    )
                })}
            </div>
            <div className="Conteudo__tabela__body__row__item editar" onMouseOver={()=>{setPermitir_click(true);setEfeito_hover(false)}} onMouseLeave={()=>{setPermitir_click(false);setEfeito_hover(true)}}>
                <ButtonCustom
                    icon={<FontAwesomeIcon icon={faPenToSquare} />}
                    value="Editar"
                    onClick={()=>{
                        setInfo_cod_elemento(codElemento);
                        setInfo_nome_elemento(elemento);
                        abrirGaveta(true);
                        setInfo_tipos_mapa(mapa);
                        setInfo_profundidades(profundidade);
                        setId_posicao(id);
                        setInfo_ordem(index);
                    }}
                >
                    Editar
                </ButtonCustom>
            </div>
        </div>
    )
}
