import React, { ReactNode } from 'react'

import { Container } from './styled'

type Props = {
  width?: number
  open: boolean
  placement?: 'right' | 'left'
  title?: string
  footer?: ReactNode
}

const SideBar: React.FC<Props> = (props) => {
  const { children, width = 300, placement = 'left', open, title, footer } = props
  return (
    <Container width={width} placement={placement} open={!open}>
      <header>
        <h2>{title}</h2>
      </header>
      <main>{children}</main>
      <footer>{footer}</footer>
    </Container>
  )
}

export default SideBar
