import styled from 'styled-components'

export const ContainerInputs = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;

  .lines {
    width: 100%;
    display: flex;
    gap: 10px;
  }

  .radio-sexo {
    width: 100%;
    display: flex;
    flex-direction: column;
  }

  .radio-group .ant-radio-button-wrapper-checked {
    background-color: #5da57d;
    border-color: #5da57d;
    color: white; /* Cor do texto quando selecionado */
  }
  /* Estilo quando o radio está checked e em hover */
  .radio-group .ant-radio-button-wrapper-checked:hover {
    background-color: #5da57d; /* Verde no hover quando está checked */
    border-color: #5da57d;
    color: white; /* Texto branco quando hover e checked */
  }

  /* Estilo quando o radio não está checked e em hover */
  .radio-group .ant-radio-button-wrapper:not(.ant-radio-button-wrapper-checked):hover {
    background-color: transparent; /* Mantém transparente no hover quando não está checked */
    border-color: #d9d9d9;
    color: #5da57d; /* Texto verde no hover quando não está checked */
  }

  .data-nascimento {
    width: 100%;
    display: flex;
    flex-direction: column;
  }
`
