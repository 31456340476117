import { api } from '../../api';
import { useQuery } from 'react-query';
import { localConfig } from '../../../Configs/localConfig';
import { message } from 'antd';

//=================================================================================>
// BUSCAR LISTA DE ATIVOS CADASTRADOS
//<=================================================================================

//TYPES
type ItemAtivosGerais = {
    item_lancamento: number | null;
    item_lancamento_cod_classe_agronomica?: number;
    item_lancamento_nome: string | null
}

//INTERFACE
export interface AtivosGerais {
    itens: ItemAtivosGerais[],
    total?: number | null,
    mensagem?: string | null,
}

export const useGetNomeRecomendacaoAtivosCadastrados = (codTipoLancamento: number, triggerError?: Function) => {
    const queryKey: any = [`recomendacao-ativos-todos_${codTipoLancamento}`];

    const { conta } = localConfig();

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<AtivosGerais, Error>(queryKey, async () => {
        
        return api.get(`recomendacao/compatibilidade?tipoLancamento=${codTipoLancamento}&codConta=${conta}&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de ativos!');
                triggerError?.(true);
            })
    },
        {
            enabled: true,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_ativos: data, 
        carregando_ativos: isFetching, 
        refetch_data_ativos: refetch,
        isFetched_ativos: isFetchedAfterMount
    };
};


//=================================================================================>
// BUSCAR LISTA DE ATIVOS
//<=================================================================================

//TYPES
type Item = {
    categoria_produto_nome: string | null,
    classe_quimica_nome: string | null,
    cod: number | null,
    cod_categoria_produto: number | null,
    cod_classe_quimica: string | null,
    cod_grupo: number | null,
    cod_grupo_quimico: number | null,
    cod_local_acao: number | null,
    cod_mecanismo_acao: number | null,
    cod_modo_acao: number | null,
    create_time: string | null,
    delete_time: string | null,
    grupo_nome: string | null,
    grupo_quimico_nome: string | null,
    local_acao_nome: string | null,
    local_mecanismo_acao: string | null,
    modo_acao_nome: string | null,
    nome: string | null,
    update_time: string | null
}

//INTERFACE
export interface Ativos {
    itens: Item[]
}

export const useGetRecomendacaoAtivos = (triggerError?: Function) => {
    const queryKey: ['recomendacao-ativos'] = [`recomendacao-ativos`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Ativos, Error>(queryKey, async () => {
        let error_message: string;
        return api.get(`recomendacao/ativo?pagination=${-1}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de ativos!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_ativos: data, 
        carregando_ativos: isFetching, 
        refetch_data_ativos: refetch,
        isFetched_ativos: isFetchedAfterMount
    };
};


//=================================================================================>
// BUSCAR ATIVOS CADASTRADOS
//<=================================================================================

//TYPES
export type ItemAtivoCadastrado = {
    aplicacao_localizada: number | null,
    item_lancamento: number | null,
    lancamento: number | null,
    ativo_nome: string | null,
    cultura_item_lancamento: string | null,
    cultura_lancamento: string | null,
    item_lancamento_nome: string | null,
    lancamento_nome: string | null,
    cod: number | null,
    cod_adjuvante: number | null,
    cod_ativo: number | null,
    cod_conta: number | null,
    cod_cultura: number | null,
    cod_estadio: number | null,
    cod_foliar: number | null,
    cod_grupo_quimico: number | null,
    cod_classe_agronomica: number | null,
    cod_formulacao: number | null,
    cod_grupo: number | null,
    cod_ingrediente_ativo: number | null,
    cod_insumo: number | null,
    cod_produto: number | null,
    cod_tecnologia: number | null,
    cod_textura_solo: number | null,
    cod_tipo: number | null,
    cod_alerta: number | null,
    cod_observacao: number | null,
    estadio_cultura: number | null;
    create_time: string | null,
    create_user: string | null,
    cultura_nome: string | null,
    delete_time: string | null,
    delete_user: string | null,
    descricao: string | null,
    grupo_quimico_nome: string | null,
    grupo_dado_quimico_nome: string | null,
    ingrediente_ativo_nome: string | null,
    insumo_nome: string | null,
    classe_agronomica_nome: string | null,
    formulacao_nome: string | null,
    grupo_nome: string | null,
    observacao: string | null,
    produto_nome: string | null,
    tecnologia_nome: string | null,
    update_time: string | null,
    update_user: string | null,
    valor: string | null,
    valor_maximo: string | null,
    valor_minimo: string | null,
    dose_minima: string | null,
    dose_maxima: string | null,
    plant_back: number |null,
    estadio_nome: string | null,
}

//INTERFACE
export interface AtivosCadastrados {
    itens: ItemAtivoCadastrado[]
}

export const useGetRecomendacaoAtivosCadastrados = (id_item_principal: number, id_tipo_principal: number) => {
    const queryKey: any = [`recomendacao-tipos_${id_item_principal}`];

    const { conta } = localConfig();

    function obterTipoPrincipal() {
        switch (id_tipo_principal) {
            case 1:
                return `lancamentoAtivo=${id_item_principal}`;
            case 2:
                return `lancamentoProduto=${id_item_principal}`;
            case 3:
                return `lancamentoGrupoQuimico=${id_item_principal}`;
            case 4:
                return `lancamentoClasseAgronomica=${id_item_principal}`;
            case 5:
                return `lancamentoFormulacao=${id_item_principal}`;
            case 6:
                return `lancamentoCultura=${id_item_principal}`;
            case 8:
                return `lancamentoBioTecnologia=${id_item_principal}`;
            case 9:
                return `lancamentoGrupoDadoQuimico=${id_item_principal}`;
            default:
                return `TipoInvalido=null`;
        }
    }

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<AtivosCadastrados, Error>(queryKey, async () => {
        return api.get(`recomendacao/compatibilidade/lancamentos?pagination=-1&codConta=${conta}&${obterTipoPrincipal()}`)
            .then((response):any => {
                return response.data
            })
            .catch(() => {
                message.error({ content: 'Falha ao obter lista de ativos cadastrados!', key: 'loading'});
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_cadastrados: data, 
        carregando_cadastrados: isFetching, 
        refetch_data_cadastrados: refetch,
        isFetched_cadastrados: isFetchedAfterMount
    };
};


//=================================================================================>
// BUSCAR LISTA DE TIPOS
//<=================================================================================

//TYPES
type ItemInsumo = {
    adjuvante: number | null,
    categoria_produto_nome: string | null,
    cod: number | null,
    cod_categoria_produto: number | null,
    cod_cliente: number | null,
    cod_conta: number | null,
    cod_fabricante: number | null,
    cod_formulacao: number | null,
    cod_medida_aplicacao: number | null,
    cod_tipo_adjuvante: number | null,
    create_time: string | null,
    create_user: string | null,
    delete_time: string | null,
    delete_user: string | null,
    descricao: string | null,
    fabricante_nome: string | null,
    nome: string | null,
    observacao: string | null,
    update_time: string | null,
    update_user: string | null
}

//INTERFACE
export interface Insumos {
    itens: ItemInsumo[]
}

export const useGetRecomendacaoTipoInsumos = (triggerError?: Function) => {
    const queryKey: ['recomendacao-insumos'] = [`recomendacao-insumos`];

    const { conta } = localConfig();

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Insumos, Error>(queryKey, async () => {
        return api.get(`recomendacao/insumo-agricola?pagination=-1&codConta=${conta}`)
            .then((response):any => {
                triggerError?.(false)
                return response.data
            })
            .catch(() => {
                message.error({ content: 'Falha ao obter lista de insumos!' });
                triggerError?.(true)
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_insumos: data, 
        carregando_insumos: isFetching, 
        refetch_insumos: refetch,
        isFetched_insumos: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA DE INGREDIENTES ATIVOS
//<=================================================================================

//INTERFACE
export interface IngredienteAtivos {
    itens: Item[]
}

export const useGetRecomendacaoIngredientesAtivos = (triggerError?: Function) => {
    const queryKey: ['recomendacao-ativos'] = [`recomendacao-ativos`];

    const { conta } = localConfig();

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<IngredienteAtivos, Error>(queryKey, async () => {
        return api.get(`recomendacao/ativo?pagination=${-1}&codConta=${conta}`)
            .then((response):any => {
                triggerError?.(false)
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de ingredientes ativos!');
                triggerError?.(true)
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_ingredi_ativos: data, 
        carregando_ingredi_ativos: isFetching, 
        refetch_data_ingredi_ativos: refetch,
        isFetched_ingredi_ativos: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA DE GRUPO QUÍMICO
//<=================================================================================

//TYPES
type ItemGrupoQuimico = {
    cod: number | null,
    cod_tipo: number | null,
    create_time: string | null,
    delete_time: string | null,
    nome: string | null,
    tipo_nome: string | null,
    update_time: string | null
}

//INTERFACE
export interface GrupoQuimico {
    itens: ItemGrupoQuimico[]
}

export const useGetRecomendacaoGrupoQuimico = (triggerError?: Function) => {
    const queryKey: ['recomendacao-grupo-quimico'] = [`recomendacao-grupo-quimico`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<GrupoQuimico, Error>(queryKey, async () => {
        return api.get(`recomendacao/dado-quimico?pagination=${-1}&codTipo=${6}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de grupos quimicos!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_grupo_quimico: data, 
        carregando_grupo_quimico: isFetching, 
        refetch_grupo_quimico: refetch,
        isFetched_grupo_quimico: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA DE GRUPO
//<=================================================================================

//TYPES
type ItemGrupo = {
    cod: number | null,
    cod_tipo: number | null,
    create_time: string | null,
    delete_time: string | null,
    nome: string | null,
    tipo_nome: string | null,
    update_time: string | null
}

//INTERFACE
export interface Grupo {
    itens: ItemGrupo[]
}

export const useGetRecomendacaoGrupo = (triggerError?: Function) => {
    const queryKey: ['recomendacao-grupo'] = [`recomendacao-grupo`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Grupo, Error>(queryKey, async () => {
        return api.get(`recomendacao/dado-quimico?pagination=${-1}&codTipo=${8}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de grupos!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_grupo: data, 
        carregando_grupo: isFetching, 
        refetch_grupo: refetch,
        isFetched_grupo: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA CULTURAS
//<=================================================================================

//TYPES
type ItemCultura = {
    cod: number | null,
    create_time: string | null,
    create_user: string | null,
    delete_time: string | null,
    delete_user: string | null,
    nome: string | null,
    nome_cientifico: string | null,
    unidade_colheita: number | null,
    unidade_colheita_nome: string | null,
    update_time: string | null,
    update_user: string | null
}

//INTERFACE
export interface Cultura {
    itens: ItemCultura[]
}

export const useGetRecomendacaoCultura = (autoCall?: boolean, triggerError?: Function) => {
    const queryKey: ['recomendacao-cultura'] = [`recomendacao-cultura`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Cultura, Error>(queryKey, async () => {
        return api.get(`cultura?ordem=ordem&pagination=-1`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de culturas!');
                triggerError?.(true);
            })
    },
        {
            enabled: autoCall ?? false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_cultura: data, 
        carregando_cultura: isFetching, 
        refetch_cultura: refetch,
        isFetched_cultura: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA TECNOLOGIA
//<=================================================================================

//TYPES
type ItemTecnologia = {
    cod: number | null,
    create_time: string | null,
    delete_time: string | null,
    descricao: string | null,
    nome: string | null,
    update_time: string | null
}

//INTERFACE
export interface Tecnologia {
    itens: ItemTecnologia[]
}

export const useGetRecomendacaoTecnologia = (triggerError?: Function) => {
    const queryKey: ['recomendacao-tecnologia'] = [`recomendacao-tecnologia`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Tecnologia, Error>(queryKey, async () => {
        return api.get(`recomendacao/tecnologia?pagination=${-1}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de tecnologias!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_tecnologia: data, 
        carregando_tecnologia: isFetching, 
        refetch_tecnologia: refetch,
        isFetched_tecnologia: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR CLASSE AGRONOMICA
//<=================================================================================

//TYPES
type ItemClasseAgronomica = {
    cod: number | null,
    cod_tipo_geral: number | null,
    create_time: string | null,
    delete_time: string | null,
    nome: string | null,
    ordem: number | null,
    update_time: string | null
}

//INTERFACE
export interface ClasseAgronomica {
    itens: ItemClasseAgronomica[]
}

export const useGetRecomendacaoClasseAgronomica = (triggerError?: Function) => {
    const queryKey: ['recomendacao-classe-agronomica'] = [`recomendacao-classe-agronomica`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<ClasseAgronomica, Error>(queryKey, async () => {
        return api.get(`tipos-sistema?codTipo=143&?pagination=${-1}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de classes agronomicas!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_classe_agro: data, 
        carregando_classe_agro: isFetching, 
        refetch_classe_agro: refetch,
        isFetched_tclasse_agro: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR FORMULAÇÃO
//<=================================================================================

//TYPES
type ItemFormulacao = {
    cod: number | null,
    cod_tipo: number | null,
    create_time: string | null,
    delete_time: string | null,
    nome: string | null,
    sigla: string | null,
    tipo_nome: string | null,
    update_time: string | null
}

//INTERFACE
export interface Formulacao {
    itens: ItemFormulacao[]
}

export const useGetRecomendacaoFormulacao = (triggerError?: Function) => {
    const queryKey: ['recomendacao-formulacao'] = [`recomendacao-formulacao`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<Formulacao, Error>(queryKey, async () => {
        return api.get(`recomendacao/formulacao?pagination=${-1}`)
            .then((response):any => {
                triggerError?.(false);
                return response.data
            })
            .catch(() => {
                message.error('Falha ao obter lista de formulação!');
                triggerError?.(true);
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_formulacao: data, 
        carregando_formulacao: isFetching, 
        refetch_formulacao: refetch,
        isFetched_formulacao: isFetchedAfterMount
    };
};

//=================================================================================>
// BUSCAR LISTA DE ESTADIOS BASEADO NO ID DA CULTURA
//<=================================================================================

//TYPES
type ItemEstadio = {
    cod: number | null,
    cod_cultura: number | null,
    nome: string | null,
    descricao: string | null,
    status: number | null,
    create_time: string | null,
    update_time: string | null,
    delete_time: string | null,
    arquivo: string | null,
    ordem: number | null,
    cultura_nome: string | null,
    arquivo_url: string | null
}

//INTERFACE
export interface EstadiosDaCultura {
    itens: ItemEstadio[]
}

export const useGetEstadiosByCultura = (codCultura: number) => {
    const queryKey: any = [`estadio-idCultura_${codCultura}`];

    const { data, isFetching, refetch, isFetchedAfterMount } = useQuery<EstadiosDaCultura, Error>(queryKey, async () => {
        return api.get(`estadio?codCultura=${codCultura}&pagination=-1`)
            .then((response):any => {
                return response.data
            })
            .catch(() => {
                alert('Falha ao obter lista de estádios!');
            })
    },
        {
            enabled: false,
            refetchOnWindowFocus: false
        }
    );

    return { 
        data_estadios: data, 
        carregando_estadios: isFetching, 
        refetch_estadios: refetch,
        isFetched_estadios: isFetchedAfterMount
    };
};