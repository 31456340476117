// @ts-nocheck
const toBase64 = (file) =>
  new Promise((resolve, reject) => {
    const reader = new FileReader()
    reader.readAsDataURL(file)
    reader.onload = () => resolve(reader.result)
    reader.onerror = (error) => reject(error)
  })
const base64 = async (file: any) => {
  try {
    const resp = await toBase64(file)

    return resp
  } catch (error) {
    return error
  }
}

export default base64
