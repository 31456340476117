import React from 'react'
import { FontAwesomeIcon as Ico, FontAwesomeIconProps } from '@fortawesome/react-fontawesome'
import { library } from '@fortawesome/fontawesome-svg-core'
import { fas } from '@fortawesome/free-solid-svg-icons'

library.add(fas)

const Icons: React.FC<FontAwesomeIconProps> = (props) => {
  return <Ico {...props} />
}

export default Icons
