/* eslint-disable react/no-unused-prop-types */
import React from 'react'
import 'react-draft-wysiwyg/dist/react-draft-wysiwyg.css'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'

interface PropsContratos {
  permissions: ItensPerUserLogged
}

const Contratos: React.FC<PropsContratos> = () => {
  // const [modalVisible, setModalVisible] = useState(false);
  // style={{ pointerEvents: permissions?.acesso === 1 ? "none" : "auto" }}
  // const { permissions } = props;

  return <></>
}
export default Contratos
