import styled from "styled-components";
import { faPagelines } from "@fortawesome/free-brands-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Fragment, useEffect, useState } from "react";
import { Button, Empty, Modal, Popconfirm } from "antd";
import { faCalendarWeek, faFloppyDisk, faPenToSquare, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { localConfig } from "../../../../../../../../Configs/localConfig";
import { usePostPlantBack } from "../../../../../../../../services/recomendacao/ativos/post";
import { apenasNumeros, gerarKey } from "../../../../../../../../utils";
import { useDeleteCompatibilidade } from "../../../../../../../../services/recomendacao/ativos/delete";
import { buscarCadastro, filtrarPlantback } from "./meta";
import { useGetRecomendacaoAtivosCadastrados, useGetRecomendacaoCultura } from "../../../../../../../../services/recomendacao/ativos/novo-index";

//COMPONENTS
import Input from "../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../Components/CustomUi/textarea";
import Carregando from "../../../../../../../../Components/CustomUi/loading";
import ButtonCustom from "../../../../../../../../Components/ButtonCustom";
import { Box } from "../../../../../../../../Components/CustomUi/box";
import { Inline } from "../../../../../../../../Components/CustomUi/inline";
import { Select } from "../../../../../../../../Components/CustomUi/Select";
import { RowItem } from "../../../../../../../../Components/CustomUi/table/rowItem";
import { TitleH4 } from "../../../../../../../../Components/CustomUi/titleH4";
import { RowTable } from "../../../../../../../../Components/CustomUi/table/rowTable";
import { BodyTable } from "../../../../../../../../Components/CustomUi/table/bodyTable";
import { obterValor } from "../compatibilidades/meta";
import { ModalEditar } from "./components/editar";
import { HeaderTable } from "../../../../../../../../Components/CustomUi/table/headerTable";

//STYLES
const Container = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    box-sizing: border-box;
    padding: 15px;
`;
const BodyDrawer = styled.div`
    width: 100%;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 10px;
    border-top: thin solid var(--border-color);
`;

//PROPS
interface Props {
    id_tipo_principal: number | null;
    id_item_principal: number | null;
    estadios_selecionados_principal: {descr:string,id:number}[];
    openDropdownPB: boolean;
    setOpenDropdownPB: React.Dispatch<React.SetStateAction<boolean>>;
    nome_culturaPB: string;
    setNome_culturaPB: React.Dispatch<React.SetStateAction<string>>;
    id_culturaPB: number | null;
    setId_culturaPB: React.Dispatch<React.SetStateAction<number | null>>;
    tempo_ate_plantio: string;
    setTempo_ate_plantio: React.Dispatch<React.SetStateAction<string>>;
    obsPB: string;
    setObsPB: React.Dispatch<React.SetStateAction<string>>;
    id_ativo_selecionado: number;
    atualizarListaPrincipal: Function;
}

export const Plantback: React.FC<Props> = (props) => {

    //PROPS
    const { openDropdownPB, setOpenDropdownPB, nome_culturaPB, setNome_culturaPB, 
        id_culturaPB, setId_culturaPB, tempo_ate_plantio, setTempo_ate_plantio, 
        obsPB, setObsPB, id_ativo_selecionado, atualizarListaPrincipal, 
        id_tipo_principal, id_item_principal, estadios_selecionados_principal } = props;

    function limparStates() {
        setNome_culturaPB('');
        setId_culturaPB(null);
        setTempo_ate_plantio('');
        setObsPB('');
    }

    //STATES
    const [search, setSearch] = useState<string>('');
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);
    const [nome_item_atual, setNome_item_atual] = useState<string>('');
    const [id_item_atual, setId_item_atual] = useState<number | null>(null);
    const [modalEditar, setModalEditar] = useState<boolean>(false);
    const [dados_editar, setDados_editar] = useState<any>();

    //API - GET
    const { data_cultura, carregando_cultura } = useGetRecomendacaoCultura(true);
    const { data_cadastrados, refetch_data_cadastrados, carregando_cadastrados } = useGetRecomendacaoAtivosCadastrados(id_ativo_selecionado, id_tipo_principal);
    //API - POST
    const { carregando_salvar_plant, mutate_salvar_plant } = usePostPlantBack();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarPlantBack() {
        mutate_salvar_plant({
            codConta: Number(localConfig().conta),
            id_tipo_principal: id_tipo_principal,
            id_item_principal: id_item_principal,
            id_estadio_principal: estadios_selecionados_principal?.map(item => item.id),
            cod_ativo: id_ativo_selecionado,
            cod_cultura: id_culturaPB,
            plant_back: Number(tempo_ate_plantio),
            obs: obsPB,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            atualizarListaPrincipal: () => atualizarListaPrincipal()
        });
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: id_item_atual,
            limparStates: () => limparStates(),
            atualizarLista: () => refetch_data_cadastrados(),
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        if (id_ativo_selecionado !== null) {
            refetch_data_cadastrados();
        }
    }, [id_ativo_selecionado])


    /**Desabilita os tipos que já foram adicionados no cadastro */
    function desabilitarSelecionados() {
        return (data_cadastrados?.itens ?? [])?.filter(filtrarPlantback())?.map(item=> item?.['cod_cultura']) ?? [];
    }

    return (
        <Fragment>
        <Container>
            <Box>
                <Inline gap={15}>
                    <Select
                        label='Selecione a cultura'
                        placeholder='Selecione Selecione a cultura'
                        value={nome_culturaPB}
                        onChange={setNome_culturaPB}
                        onChangeID={setId_culturaPB}
                        open={openDropdownPB}
                        trigger={setOpenDropdownPB}
                        items={(data_cultura?.itens) ?? []}
                        icon={faPagelines}
                        maxHeight={250}
                        idItem={'cod'}
                        descricaoItem='nome'
                        atual={nome_culturaPB}
                        loading={carregando_cultura}
                        disabledItens={desabilitarSelecionados()}
                    />

                    <Input 
                        label="Tempo até o inicio do plantio (em dias)"
                        placeholder="Tempo até o inicio do plantio"
                        value={apenasNumeros(tempo_ate_plantio)}
                        onChange={setTempo_ate_plantio}
                        icon={faCalendarWeek}
                        obrigatorio
                    />
                </Inline>

                <TextArea
                    label={`Observação`}
                    placeholder='Digite aqui a observação'
                    value={obsPB}
                    onChange={setObsPB}
                    resize={false}
                    height={70}
                    // disabled={disabilitarTextArea()}
                />

            </Box>

            <ButtonCustom
                icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                onClick={()=>{salvarPlantBack()}}
                style={{alignSelf: 'flex-start'}}
                loading={carregando_salvar_plant}
                disabled={id_culturaPB===null || tempo_ate_plantio==='' || carregando_salvar_plant}
            >
                Salvar plant back
            </ButtonCustom>
        </Container>

        <BodyDrawer>

            <Box>

                <TitleH4 style={{color: 'var(--text-color-light)'}}>Plant back Cadastradas</TitleH4>

                <Input 
                    placeholder="Buscar plant back"
                    value={search}
                    onChange={setSearch}
                />

                {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarPlantback()).length === 0
                ? <Empty image={Empty.PRESENTED_IMAGE_SIMPLE} description={`Nenhuma plantback cadastrada`} />
                : <>

                    {carregando_cadastrados &&(
                        <Carregando
                            legenda="Carregando lista"
                            justifyContent="center"
                            animation
                            height={100}
                        />
                    )}

                    <HeaderTable
                        itens={[
                            {valor: 'Cultura', width: 340},
                            {valor: 'Tempo', width: 340},
                            {valor: 'Opções', width: 120}
                        ]}
                        opacity={carregando_cadastrados ? 0.3 : 1}
                    />
                    
                    <BodyTable style={{
                        marginTop: -10, height: (data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarPlantback())?.length < 7 ? 'auto' : 400,
                        opacity: carregando_cadastrados ? 0.3 : 1
                    }}>
                        {(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarPlantback()).map((item, index)=> {
                            return (
                                <RowTable key={gerarKey(index)}>
                                    <RowItem width={340}>
                                        {item?.cultura_item_lancamento}
                                    </RowItem>
                                    <RowItem width={340}>
                                        {item?.plant_back} dia{item?.plant_back > 1 ? 's' : null}
                                    </RowItem>
                                    <RowItem width={(data_cadastrados?.itens ?? []).filter(buscarCadastro(search)).filter(filtrarPlantback())?.length > 7 ? 116 : 120} gap={10}>
                                        <ButtonCustom
                                            onClick={()=>{setDados_editar(item);setModalEditar(true)}}
                                            icon={<FontAwesomeIcon icon={faPenToSquare} />}
                                            type="tertiary"
                                        >
                                        </ButtonCustom>

                                        <Popconfirm
                                            key={gerarKey(index)}
                                            placement="topRight"
                                            title={'Tem certeza que deseja deletar este item?'}
                                            open={openConfirm && id_item_atual===item.cod}
                                            description={
                                            <div style={{display:'flex',flexDirection:'column',gap:15}}>
                                                <span>o item <b><i>{nome_item_atual}</i></b> será deletado!</span>
                                                <Inline justifyContent="flex-end">

                                                    <ButtonCustom 
                                                        onClick={()=>{setOpenConfirm(false)}}
                                                        type="tertiary"
                                                        icon={<FontAwesomeIcon icon={faXmark} />}
                                                        disabled={carregando_deletar_compati}
                                                    >
                                                        Cancelar
                                                    </ButtonCustom>

                                                    <ButtonCustom 
                                                        onClick={()=>{deletarCompatibilidade()}}
                                                        icon={<FontAwesomeIcon icon={faTrash} />}
                                                        danger
                                                        loading={carregando_deletar_compati}
                                                        disabled={carregando_deletar_compati}
                                                    >
                                                        Deletar
                                                    </ButtonCustom>

                                                </Inline>
                                            </div>}
                                            okButtonProps={{style: {display:'none'}}}
                                            cancelButtonProps={{style: {display:'none'}}}
                                        >
                                            
                                            <Button style={{background:'transparent',padding:0,margin:0,border:'none',boxShadow:'none'}}>
                                                <ButtonCustom
                                                    onClick={()=>{setId_item_atual(item.cod);setNome_item_atual(obterValor(item));setOpenConfirm(true)}}
                                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                                    type="tertiary"
                                                    danger
                                                />  
                                            </Button>
                                        </Popconfirm>
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </BodyTable>
                </>
                }

            </Box>
            
        </BodyDrawer>

        <Modal
            title={`Editar ${obterValor(dados_editar)}`}
            width={450}
            open={modalEditar} 
            onOk={()=>{}} 
            onCancel={()=>{setModalEditar(false)}}
            footer={false}
        >
            <ModalEditar
                registros={dados_editar}
                onClose={()=>{setModalEditar(false)}}
                atualizarLista={()=>{refetch_data_cadastrados()}}
                id_tipo_principal={id_tipo_principal}
            />
        </Modal>

        </Fragment>
    )
}