import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesColetaUsuarios {
  itens: ItensColetaUsuariosTypes[]
}

export interface ItensColetaUsuariosTypes {
  cod_pedido?: number
  ponto?: number
  coleta_cod_usuario?: number
  nome?: string
  doc_foto_perfil?: string
  doc_foto_perfil_url?: string
  doc_foto_url?: string
}

type TypesQuery = {
  codPedido: number
}

const url = '/coleta/usuarios'

export const getColetaUsuarios = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesColetaUsuarios> = await api.get(url, {
    params: {
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}
