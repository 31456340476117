
import { api } from '../../api'

export interface TypeDadosQuimicos {
  itens: [DadosQuimicos]
}

export interface DadosQuimicos {
  cod?: number
  nome?: string
  cod_tipo?: number
  tipo_nome?: string
}

interface TypeQuery {
  codTipo?: number
  nome?: string
  cod?: number
  pagination?: number | -1
}

const url = '/recomendacao/dado-quimico'

export const getDadosQuimicos = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    ...params,
    pagination,
  }

  const response = api.get(url, {
    params,
  })

  return response
}

export const postDadosQuimicos = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putDadosQuimicos = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delDadosQuimicos = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
