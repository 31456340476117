
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetPatrimonios {
  itens: [PatrimoniosItens]
}

export interface PatrimoniosItens {
  cod?: number
  nome?: string
  cod_tipo?: number
  cod_conta?: number
  ano?: any
  cod_cliente?: number
  status?: 0 | 1
  descricao?: string
  cod_propriedade?: number
  cod_marca?: number
  modelo?: string
  imagem?: string | null
  tipo_nome?: string
  tipo_odom?: number
  marca_nome?: string
  imagem_url?: string
  imagem_base64?: string
  data_aquisicao?: any
  loading?: boolean
  valor: string
  odom_horimetro?: string
  motorizado?: number
  arquivos?: [
    {
      cod?: number
      cod_patrimonio?: number
      nome?: string
      arquivo_url?: string
    }
  ]
  tipo?: {
    cod?: number
    nome?: string
    cod_tipo?: number
    outros_tipos?: number
    cod_categoria?: number
    geral?: number
    exibir_marca?: 0 | 1
    categoria?: {
      cod?: number
      nome?: string
      cod_tipo?: number
      outros_tipos?: number
      cod_categoria?: string | null
      geral?: number
      exibir_marca?: 0 | 1
    }
  }
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  cod?: number
  pagination?: number | -1
}

export const getPatrimonio = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  params = {
    codConta: config.conta,
    codCliente: config.codCliente,
    ...params,
  }

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get('/patrimonio', {
    params,
  })

  return response
}

export const getFilePatrimonioArr = async (url: string) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get(url, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    responseType: 'arraybuffer',
  })

  return response
}

export const getFilePatrimonioBlob = async (url: string) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.get(url, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    responseType: 'blob',
  })

  return response
}

export const postPatrimonio = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.post('/patrimonio', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
    headers: {
      'Content-Type': 'multipart/form-data',
    },
  })

  return response
}

export const putPatrimonio = async (codPatrimonio, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'multipart/form-data'

  const response: AxiosResponse<TypeGetPatrimonios> = await api.post(
    `/patrimonio/${codPatrimonio}`,
    data,
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}

export const pathPatrimonio = async (codPatrimonio, status) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.put(
    `/patrimonio/${codPatrimonio}`,
    status,
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}

export const delPatrimonio = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetPatrimonios> = await api.delete(`/patrimonio/${cod}`, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}
