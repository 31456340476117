import dayjs from 'dayjs'
import dayJsPT from 'dayjs/locale/pt-br'
import localizeFormat from 'dayjs/plugin/localizedFormat'
import Sun from '../../assets/svg/sun.svg'
import Nublado from '../../assets/svg/nublado.svg'
import Chuva from '../../assets/svg/chuvoso.svg'
import NuvensDispersas from '../../assets/svg/nuvensDesipadas.svg'
import AlgumasNuvens from '../../assets/svg/parcialnublado.svg'
import ChuvasLeves from '../../assets/svg/chuvaFraca.svg'
import Tempestade from '../../assets/svg/chuvaTrovoada.svg'
import Neblina from '../../assets/svg/neblina.svg'

import React, { useEffect, useState } from 'react'
import { v4 as uuid } from 'uuid'

import { FontAwesomeIcon, FontAwesomeIcon as Icon } from '@fortawesome/react-fontawesome'

import { useLogic } from '../../context/useLogic'
import { TypesForecastList } from '../../services/apiClima'

import { Container } from './styled'
import {
  faCloud,
  faDroplet,
  faLocationDot,
  faWater,
  faWind,
} from '@fortawesome/free-solid-svg-icons'

const WeatherForecast: React.FC = () => {
  const { dataClima, responsive } = useLogic()
  dayjs.locale(dayJsPT)
  dayjs.extend(localizeFormat)

  const [previView, setpreviView] = useState<TypesForecastList[]>([])
  const [dataFormatada, setDataFormatada] = useState('')

  const [loadingTemp, setLoadingTemp] = useState(true)

  const handlePrevisao = async () => {
    setLoadingTemp(true)

    const dados = dataClima

    let previ = []
    let dia = 0

    for (let i = 0; i < dados.list.length; i += 1) {
      const data = dados.list[i]

      const dataDia = dayjs(data.dt_txt).format('LL')
      const dataDiaFormat = dayjs(data.dt_txt).format('DD MMM')
      const diaSemana = dayjs(data.dt_txt).format('dddd')
      const diaSemanaFormat = dayjs(data.dt_txt).format('ddd')
      const tempDia = Number(dayjs(data.dt_txt).format('DD'))

      data.dataDia = dataDia
      data.dataDiaFormat = dataDiaFormat
      data.semana = diaSemana
      data.semanaFormat = diaSemanaFormat

      if (dia === tempDia) {
        previ = [...previ, [data]]
      } else if (dia === 0 || dia < tempDia) {
        previ = [...previ, data]
        dia = Number(dayjs(data.dt_txt).format('DD'))
      }
    }

    const filtro = previ
    previ = []
    filtro.forEach((data) => {
      if (data.dt_txt) {
        previ.push(data)
      }

      return previ
    })

    setpreviView(previ)
    setLoadingTemp(false)
  }

  function getImg(description) {
    switch (description) {
      case 'céu limpo':
        return Sun
      case 'nublado':
        return Nublado
      case 'chuva':
        return Chuva
      case 'nuvens dispersas':
        return NuvensDispersas
      case 'algumas nuvens':
        return AlgumasNuvens
      case 'chuva leve':
        return ChuvasLeves
      case 'chuva moderada':
        return ChuvasLeves
      case 'chuva forte':
        return Chuva
      case 'chuva congelante': 
        return Chuva
      case 'Tempestade':
      return Tempestade
      case 'Tempestade com chuva leve':
        return Tempestade
      case 'tempestade com chuva':
        return Tempestade
      case 'tempestade com chuva forte':
        return Tempestade
      case 'neblina': 
        return Neblina    
      default:
        return Sun
    }
  }

  useEffect(() => {
    if (dataClima?.list?.length > 0) {
      handlePrevisao()
    }
  }, [dataClima])

  return (
    <Container responsive={responsive}>
      <div className='main-container'>
        {previView.map((data, index) =>
          index === 0 ? (
            <>
              <div key={uuid()} className='card-temp'>
                <div key={uuid()} className='card-temp-city'>
                  <div style={{ display: 'flex', gap: '10px', alignItems: 'center' }}>
                    <FontAwesomeIcon
                      icon={faLocationDot}
                      style={{ fontSize: '30px', color: '#3d7054' }}
                    />
                    <div style={{ display: 'flex', gap: '3px', alignItems: 'center' }}>
                      <h4
                        style={{
                          margin: '0',
                          fontSize: '17px',
                          fontWeight: '700',
                          color: '#3d7054',
                        }}
                      >
                        {dataClima?.city?.name}
                      </h4>{' '}
                        <p style={{padding: 0, margin: 0, color: '#3d7054'}}>/</p>
                      <h4
                        style={{
                          margin: '0',
                          fontSize: '17px',
                          fontWeight: '700',
                          color: '#3d7054',
                        }}
                      >
                        {dataClima?.city?.uf}
                      </h4>
                    </div>
                  </div>
                  <div>
                    <h4
                      style={{ margin: '0', fontSize: '13px', fontWeight: '700', color: '#3d7054' }}
                    >{`${data?.semanaFormat.toLocaleUpperCase()}, ${data?.dataDiaFormat.toLocaleUpperCase()}`}</h4>
                  </div>
                </div>
              </div>
              <div className='campo-info-clima' key={uuid()}>
                <img
                  className='img-nuvem'
                  src={getImg(data?.weather?.[0]?.description)}
                  alt={data?.weather[0].description}
                />
                <div className='campo-temp'>
                  <div className='temperatura'>
                    <h2>{`${data?.main.temp.toFixed(0)}º`}</h2>
                    <b style={{ color: '#d2d2d2' }}>{`${data?.main.temp_min.toFixed(0)}º`}</b>
                  </div>
                  <h4>{data?.weather[0].description.toLocaleLowerCase()}</h4>
                </div>

                {/* <table key={uuid()} style={{ width: '100%' }}>
                  <tbody>
                    <tr>
                      <td style={{ maxWidth: '50px', textAlign: 'center' }}>
                        
                        <h4 className='capitalized'>{data?.weather[0].description}</h4>
                      </td>
                      <td>
                        <b>
                          Umidade <Icon icon='droplet' />
                        </b>
                        <h4>{`${data?.main.humidity}%`}</h4>
                      </td>
                      <td>
                        <b>
                          Chuva <Icon icon='cloud-showers-heavy' />
                        </b>

                        <h4>{`${data?.rain === undefined ? '0.0mm' : `${data?.rain['3h']}mm`}`}</h4>
                      </td>
                      <td>
                        <b>
                          Vento <Icon icon='wind' />
                        </b>
                        <h4>{`${data.wind.speed} km/h`}</h4>
                      </td>
                    </tr>
                  </tbody>
                </table> */}
              </div>
              <div className='infos-do-clima'>
                <div className='campo-infos'>
                  <div className='infos-header'>
                    <h4>Umidade</h4>{' '}
                    <FontAwesomeIcon
                      icon={faDroplet}
                      style={{ fontSize: '12px', color: ' #3d7054' }}
                    />
                  </div>
                  <h4>{`${data?.main.humidity}%`}</h4>
                </div>
                <div className='campo-infos'>
                  <div className='infos-header'>
                    <h4 style={{ margin: '0', padding: '0', color: ' #3d7054' }}>Chuva</h4>{' '}
                    <FontAwesomeIcon
                      icon={faCloud}
                      style={{ fontSize: '12px', color: ' #3d7054' }}
                    />
                  </div>
                  <h4>{`${data?.rain === undefined ? '0.0mm' : `${data?.rain['3h']}mm`}`}</h4>
                </div>
                <div className='campo-infos'>
                  <div className='infos-header'>
                    <h4 style={{ margin: '0', padding: '0', color: ' #3d7054' }}>Vento</h4>{' '}
                    <FontAwesomeIcon
                      icon={faWind}
                      style={{ fontSize: '12px', color: ' #3d7054' }}
                    />
                  </div>
                  <h4>{`${data.wind.speed} km/h`}</h4>
                </div>
              </div>
            </>
          ) : null
        )}
        <div className='container-clima-semana'>
          {previView.map((data, index) => (
            <>
              {index > 0 ? (
                <div className='cards-clima-semana'>
                  <h5 className='capitalized'>{`${data.semana.substring(0, 3)}.`}</h5>
                  <img
                    style={{ width: '60%' }}
                    title={data?.weather[0].description}
                    src={getImg(data?.weather?.[0]?.description)}
                    alt={data?.weather[0].description}
                  />
                  <div>
                    <span>{`${data?.main.temp.toFixed(0)}º`}</span>
                    {'  '}
                    <span style={{ color: '#d2d2d2' }}>{`${data?.main.temp_min.toFixed(0)}º`}</span>
                  </div>
                </div>
              ) : null}
            </>
          ))}
        </div>
      </div>
    </Container>
  )
}

export default WeatherForecast