
import axios, { AxiosResponse } from 'axios'
import { navegadorUser, tryError } from '../Afins'
import { api } from '../api'

type Itens = {
  cod?: number
  nome?: string
  email?: string
  status?: number
  acesso_admin?: number
  cod_laboratorio?: number
  sexo?: 'M' | 'F'
  doc_foto_perfil?: string
  acessoConta?: {
    contas?: {
      cod?: number
      nome?: string
      clientes?: {
        cod?: number
        nome?: string
        logo?: string
      }[]
      acesso?: string
      temAcesso?: boolean
    }[]
    clientes?: [
      {
        cod?: number
        cod_conta?: number
        nome?: string
        logo?: string
        acesso?: string
      }
    ]
  }
  hash?: string
}

export interface TypeLogin {
  itens?: [Itens]
  total?: number
  pagina?: number
  quantidadePorPagina?: number
  links?: {
    atual?: string
    anterior?: string
    proximo?: string
  }
  mensagem?: string
  timestamp?: number
}

interface ParamLogin {
  email: string
  senha: string
  admin?: boolean
  lab?: boolean
  lat?: string
  long?: string
  os?: string
  modelo?: string
  fabricante?: string
  ip?: string
  localidade?: string
  browse?: string
  app_bundle?: string
  app_version?: string
  device_id?: string
  batery_level?: string
  batery_status?: string

}

interface ParamsIpApi {
  ip: string
  network: string
  version: string
  city: string
  region: string
  region_code: string
  country: string
  country_name: string
  country_code: string
  country_code_iso3: string
  country_capital: string
  country_tld: string
  continent_code: string
  in_eu: string
  postal: string
  latitude: string
  longitude: string
  timezone: string
  utc_offset: string
  country_calling_code: string
  currency: string
  currency_name: string
  languages: string
  country_area: string,
  country_population: string
  asn: string
  org: string
}

const url = '/login'
const urlAdm = '/login-admin'
const urlLab = '/login-representante-laboratorio'

export async function postLogin({ ...params }: ParamLogin) {
  const { email, senha, admin, lab } = params
  let ipData;
  let urlRota
  try {
    ipData = await ipApi()

  } catch (error) {
    tryError(error)
  }
  const browser = navegadorUser()

  let dataIp

  if (ipData) {
    dataIp = {
      ip: ipData.data.ip,
      lat: ipData.data.latitude,
      long: ipData.data.longitude,
      browse: `${browser.nameNavegador}/${browser.version}`,
      app_version: process.env.REACT_APP_VERSION,
      localidade: `${ipData.data.city}/${ipData.data.region_code}`,
    }
  } else {
    dataIp = null
  }
  
  if (admin) {
    urlRota = urlAdm
  }else if (lab) {
    urlRota = urlLab
  }else{
    urlRota = url
  }

  const response: AxiosResponse<TypeLogin> = await api.post(urlRota, {
    email,
    senha,
    ...dataIp
  })

  return response
}

async function ipApi() {

  const ip: AxiosResponse<{ ip: string }> = await axios.get('https://api.ipify.org/?format=json', { timeout: 5000 })

  const response: AxiosResponse<ParamsIpApi> = await axios.get(`https://ipapi.co/${ip.data.ip}/json/`, { timeout: 5000 })

  return response
}
