import { ItemAtivoCadastrado } from "../../../../../../../../../../services/recomendacao/ativos/novo-index"

//PROPS
interface Props {
    item: ItemAtivoCadastrado;
}

export const InfoCultura: React.FC<Props> = ({ item }) => {

    let { lancamento, item_lancamento, cultura_item_lancamento, cultura_lancamento, lancamento_nome } = item;
    let tem_cultura = lancamento === 6 || lancamento === 7 ;
    let cultura_na_cultura = (lancamento === 6 && item_lancamento === 6) || (lancamento === 7 && item_lancamento === 7);

    const style:React.CSSProperties = {
        fontWeight: 600,
        fontSize: 12.5,
        marginTop: 5,
        display: 'flex',
        textTransform: 'capitalize'
    }

    if (tem_cultura) {

        let cultura_item = (cultura_item_lancamento!==null && cultura_item_lancamento!==undefined) ? `${cultura_item_lancamento} 》 ` : '';
        let cultura = (cultura_lancamento!==null && cultura_lancamento!==undefined) ? `${cultura_lancamento} 》 ` : '';

        return (
            <>
            <br />
            {cultura_na_cultura
                ? <span style={style}>({cultura_item} {String(lancamento_nome).toLocaleLowerCase()})</span>
                : <span style={style}>({cultura} {String(lancamento_nome).toLocaleLowerCase()})</span>
            }
            </>
            
        )
    }

    return <i/>
}