import { H3Component } from "./styled"

//PROPS
interface Props {
    children: string | React.ReactNode;
}

export const TitleH3: React.FC<Props> = ({ children }) => {
    return (
        <H3Component>
            {children}
        </H3Component>
    )
}