import styled from 'styled-components'

export const Container = styled.div`
  div {
    width: 100vw;
    height: 100vh;
    visibility: hidden;
    z-index: -9999;
    position: absolute;
    margin-top: 10px;
  }
`
