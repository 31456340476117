import React, { useEffect, useState } from 'react'
import { useHistory, useParams } from 'react-router-dom'

import { Button, Form, Input, message, Typography } from 'antd'

import { SyncOutlined } from '@ant-design/icons'

import { Notification, removeNotification } from '../../../Components/notification'
import { api } from '../../../services/api'

import { Bg, Container } from './style'

import logo from '../../../assets/img/logo.png'

const ResetPass: React.FC = () => {
  const [loading, setLoading] = useState(false)
  const [statusBtn, setStatusBtn] = useState(true)
  const history = useHistory()

  const params = useParams()

  const [form] = Form.useForm()

  const getToken = async () => {
    // @ts-ignore
    const response = await Api.get(
      // @ts-ignore
      `/validar/hash/redefinicao/${params.token}`
    )
    // @ts-ignore
    if (!response.data.status) {
      history.push('/auth/login')
      Notification({
        message: 'NOT FOUND',
        descricao: 'Pagina Não encontrada',
        type: 'warning',
      })
      removeNotification()
    }
  }

  const handleSubmit = async (pass) => {
    setLoading(true)
    if (handelChangeValidate()) {
      message.error('Senhas não confere')
      setLoading(false)
    } else {
      try {
        const response = await api.post(
          // @ts-ignore
          `/login/recuperar/${params.token}`,
          pass
        )

        if (response.status === 200) {
          setLoading(true)
          Notification({
            message: 'Senha Alterada com sucesso',
            descricao: 'Você sera direcionado para o Login',
            type: 'success',
          })
          removeNotification()

          setTimeout(() => {
            history.push('/auth')
          }, 3000)
        }
      } catch (error) {
        if (error.response) {
          setLoading(false)
          Notification({
            message: error.response.data.error,
            type: 'error',
          })
        }
      }
    }
  }

  const handleChangeValue = () => {
    const newpass = form.getFieldValue('senha')
    const repass = form.getFieldValue('repass')

    if (newpass !== '' && repass !== '' && newpass === repass) {
      setStatusBtn(false)
    } else {
      setStatusBtn(true)
    }
  }

  const handelChangeValidate = () => {
    const newpass = form.getFieldValue('senha')
    const repass = form.getFieldValue('repass')

    if (newpass === repass) {
      return false
    }
    return true
  }

  useEffect(() => {
    getToken()
  }, [])

  return (
    <Bg>
      <Container>
        <Typography style={{ display: 'flex', justifyContent: 'center' }}>
          <img src={logo} alt='Logo SysAGRO' />
        </Typography>
        <Form onFinish={handleSubmit} form={form} size='large' layout='vertical'>
          <Form.Item required label='Nova senha' name='senha'>
            <Input.Password onChange={handleChangeValue} />
          </Form.Item>
          <Form.Item required label='Repita a senha' name='repass'>
            <Input.Password onChange={handleChangeValue} />
          </Form.Item>
          <Button disabled={statusBtn} type='primary' size='large' block htmlType='submit'>
            {loading ? <SyncOutlined spin /> : 'Alterar Senha'}
          </Button>
        </Form>
      </Container>
    </Bg>
  )
}

export default ResetPass
