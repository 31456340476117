import { message } from "antd";
import { ItemRequest } from "../../../../../../../../services/logs";

export async function copyJson(request_selected: ItemRequest) {
    
    const { httpCode, content } = request_selected?.descricao?.response ?? {};

    let buildJson = {
        'httpCode': httpCode,
        'content': content
    };

    let stringfy = JSON.stringify(buildJson);

    try {
        await navigator.clipboard.writeText(stringfy);
        message.success('JSON copiado com sucesso')
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
    }
}
