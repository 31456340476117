import styled from "styled-components";


export const TableFertilidade = styled.div`
    width: 100%;
    min-height: 228px !important;
    display: flex;
    flex-direction: column;
    gap: 10px;
    overflow: hidden;
    margin-top: 10px;
`

export const TabelaFazenda = styled.div`
    width: 100%;
    display: flex;

    div.TabelaFazenda__tabela {
        width: calc(100% - 160px);
        /* border: thin solid #f1f1f1; */
        border: thin solid var(--border-color);
        border-radius: 7px;
        overflow: hidden;
    }
    div.TabelaFazenda__linha {
        width: 100%;
        height: 35px;
        border-bottom: thin solid var(--border-color);
        display: flex;
    }
    div.TabelaFazenda__linha:last-child {
        border: none;
    }
    div.TabelaFazenda__linha__desc {
        width: 115px;
        height: 100%;
        background-color: #f5f5f5;
        color: #545454;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
    }
    div.TabelaFazenda__linha__valor {
        width: calc(100% - 115px);
        height: 100%;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
    } 

    div.TabelaFazenda__logo {
        width: 160px;
        height: 100%;
        max-height: 70px;
        overflow: hidden;
        box-sizing: border-box;
        display: flex;
        align-items: center;
        justify-content: center;
    }
    img.TabelaFazenda__logo__img {
        width: 150px;
        height: 70px;
        object-fit: contain;
        /* box-shadow: 0 3px 20px rgba(0,0,0,0.12), 0 3px 12px rgba(0,0,0,0.24); */
    }
`

export const TabelaDetalhes = styled.div`
    width: 100%;

    div.TabelaDetalhes__wrapper {
        width: 100%;
        display: flex;
    }

    div.TabelaDetalhes__tabela {
        width: 100%;
        /* border: thin solid #f1f1f1; */
        border: thin solid var(--border-color);
        overflow: hidden;
    }
    div.TabelaDetalhes__tabela:first-child {
        border-top-left-radius: 7px;
        border-bottom-left-radius: 7px;
        border-right: none;
    }
    div.TabelaDetalhes__tabela:last-child {
        border-top-right-radius: 7px;
        border-bottom-right-radius: 7px;
    }
    div.TabelaDetalhes__linha {
        width: 100%;
        height: 35px;
        border-bottom: thin solid var(--border-color);
        display: flex;
    }
    div.TabelaDetalhes__linha:last-child {
        border: none;
    }
    div.TabelaDetalhes__linha__desc {
        width: 115px;
        height: 100%;
        background-color: #f5f5f5;
        color: #545454;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 700;
    }
    div.TabelaDetalhes__linha__desc.medias {
        width: 90px;
    }
    div.TabelaDetalhes__linha__desc:is([data-esconder = true]) {
        opacity: 0;
    }
    div.TabelaDetalhes__linha__nota {
        width: 75px;
        height: 100%;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
        border-right: thin solid var(--border-color);
    }
    div.TabelaDetalhes__linha__nota:is([data-esconder = true]) {
        opacity: 0;
    }
    div.TabelaDetalhes__linha__nota:is([data-esconder = false]) {
        display: none;
    }
    div.TabelaDetalhes__linha__valor {
        width: calc(100% - 115px);
        height: 100%;
        box-sizing: border-box;
        padding-left: 10px;
        display: flex;
        align-items: center;
        font-size: 12px;
        font-weight: 500;
    }
    div.TabelaDetalhes__linha__valor:is([data-aviso = true]) {
        color: red;
        font-style: italic;
        opacity: 0.7;
    }
    span.TabelaDetalhes__linha__valor_alerta {
        display: none;
    }
    div.TabelaDetalhes__wrapper:hover span.TabelaDetalhes__linha__valor_alerta:is([data-aviso = true]) {
        display: flex;
        margin-left: 3px;
    }
    div.TabelaDetalhes__linha__valor:is([data-esconder = true]) {
        opacity: 0;
    }
    div.TabelaDetalhes__linha__valor.medias {
        width: calc(100% - 165px);
    }
    div.TabelaDetalhes__linha__valor.medias:is([data-esconder = false]) {
        width: calc(100% - 90px);
    }
    div.TabelaDetalhes__linha__valor.medias:is([data-esconder = true]) {
        width: calc(100% - 50px);
    }

`