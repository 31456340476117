import { orderBy } from "lodash";
import { useEffect, useState } from "react";

//STYLES
import { ContainerAssistenteTecnico } from "./styles";

//APP
import { useGetEquipeUsuarios, useGetUsuarioTipoAssisTecnico } from "../../../../services/consultoriaAgricola/tipoLiderEquipe/get/novo_index";

//COMPONENTS
import PageContainer from "../../../../Components/pageContainer";
import { TitleH1 } from "../../../../Components/CustomUi/titleH1";
import { Select } from "../../../../Components/CustomUi/Select";
import { TransferContainer } from "../../../../Components/CustomUi/transferContainer";
import { Box } from "../../../../Components/CustomUi/box";
import { usePostGerenciarEquipeAssisTecnico } from "../../../../services/consultoriaAgricola/tipoLiderEquipe/post";
import { parseArrayNumeros } from "../../../../utils";
import { faUser } from "@fortawesome/free-solid-svg-icons";

export const EquipeAssistenteTecnico2: React.FC = () => {

    //STATES
    const [nome_tecnico_selecionado, setNome_tecnico_selecionado] = useState<string>('');
    const [id_tecnico_selecionado, setId_tecnico_selecionado] = useState<number|null>(null);
    const [open, setOpen] = useState<boolean>(false);
    const [selecionados_add, setSelecionados_add] = useState<string[]|number[]>([]);
    const [selecionados_remover, setSelecionados_remover] = useState<string[]|number[]>([]);
    const [marcar_todos_add, setMarcar_todos_add] = useState<boolean>(false);
    const [marcar_todos_add_remover, setMarcar_todos_remover] = useState<boolean>(false);

    //API
    const { data_usuarios } = useGetUsuarioTipoAssisTecnico();
    const { data_equipe, refetch_data_equipe, carregando_equipe } = useGetEquipeUsuarios(id_tecnico_selecionado);
    const { mutate_salvar_assist, carregando_salvar_assist } = usePostGerenciarEquipeAssisTecnico();

    useEffect(() => {
        if (id_tecnico_selecionado!==null) {
            refetch_data_equipe();
        } 
    }, [id_tecnico_selecionado])
    
    function listaDisponiveis() {
        return (data_equipe?.itens??[]).filter(item=> item.cod_consultor===null);
    }
    function listaAdicionados() {
        return (data_equipe?.itens??[]).filter(item=> item.cod_consultor!==null);
    }

    function salvarAlteracao(type:'add' | 'remover') {
        
        let todos = type==='add' ? listaDisponiveis()?.map(item => Number(item.cod_func)) : listaAdicionados()?.map(item => Number(item.cod_func));

        let lista_usuarios = type==='add' ? {tecnicos_add: marcar_todos_add ? todos : parseArrayNumeros(selecionados_add)} : {tecnicos_remover:  marcar_todos_add_remover ? todos : parseArrayNumeros(selecionados_remover)};

        mutate_salvar_assist({
            tipo_acao: type,
            cod_consultor: id_tecnico_selecionado,
            refetchGerenciar: ()=> refetch_data_equipe(),
            limpar: type==='add' ? ()=> setSelecionados_add([]) : ()=> setSelecionados_remover([]),
            ...lista_usuarios
        })
    }

    return (
        <ContainerAssistenteTecnico>
            <PageContainer>

                <TitleH1>Equipe do Assistente Técnico</TitleH1>

                <Box padding={15} gap={10}>

                    <Select 
                        label="Selecione um técnico para começar"
                        width={'40%'}
                        placeholder="Selecione um técnico para começar"
                        value={nome_tecnico_selecionado}
                        onChange={setNome_tecnico_selecionado}
                        onChangeID={setId_tecnico_selecionado}
                        items={orderBy(data_usuarios?.itens ?? [], 'usuario_nome')}
                        descricaoItem="usuario_nome"
                        idItem="cod_usuario"
                        open={open}
                        trigger={setOpen}
                        icon={faUser}
                    />

                    <div style={{display:'flex',gap:40,opacity: id_tecnico_selecionado===null?0.4:1,marginTop:5}}>
                        <TransferContainer
                            title="Técnicos disponíveis"
                            height={'calc(100vh - 275px)'}
                            items={listaDisponiveis()}
                            descriptionItem={'usuario_nome'}
                            targetItem={'cod_func'}
                            selecteds={selecionados_add}
                            selectItens={setSelecionados_add}
                            emptyMessage="Nenhum assistente disponível"
                            loadingMessage="Carregando assistentes disponíveis"
                            isLoading={carregando_equipe}
                            isLoadingBtn={carregando_salvar_assist}
                            markAll={marcar_todos_add}
                            setMarkAll={setMarcar_todos_add}
                            onChange={()=>salvarAlteracao('add')}
                        />
                        
                        <TransferContainer
                            title="Técnicos Adicionados"
                            height={'calc(100vh - 275px)'}
                            items={listaAdicionados()}
                            descriptionItem={'usuario_nome'}
                            targetItem={'cod_func'}
                            selecteds={selecionados_remover}
                            selectItens={setSelecionados_remover}
                            type="remove"
                            emptyMessage="Nenhum usuário foi adicionado"
                            loadingMessage="Carregando assistentes adicionados"
                            isLoading={carregando_equipe}
                            isLoadingBtn={carregando_salvar_assist}
                            markAll={marcar_todos_add_remover}
                            setMarkAll={setMarcar_todos_remover}
                            onChange={()=>salvarAlteracao('remover')}
                        />
                    </div>

                </Box>     
                
            </PageContainer>           
        </ContainerAssistenteTecnico>
    )
}