import React, { useEffect, useState } from 'react'
import { Button, Modal, Space, Table } from 'antd'
import Icon from '@ant-design/icons'
import { AxiosResponse } from 'axios'

import { faCopy, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import { uniqBy } from 'lodash'
import ViewPage from '../../../../Components/ViewPage'
import FormRecomendaAtivos from '../../../../Components/recomendacao/ativos'
import {
  delRecomendaAtivos,
  getRecomendaAtivos,
  RecomendaAtivos as RecomendaAtivosItens,
  TypeRecomendaAtivos,
} from '../../../../services/recomendacao/ativos'
import { pagination } from '../../../../Components/Default'
import { IconAlert } from '../../../../Components/iconsAlert'
import { Notification } from '../../../../Components/notification'
import Icons from '../../../../Components/Icons'
import { filterTable } from '../../../../services/Afins'

const RecomendaAtivos: React.FC = () => {
  const [visibleForm, setVisibleForm] = useState(false)
  const [update, setUpdate] = useState(0)

  const [dataAtivos, setDataTipos] = useState<RecomendaAtivosItens[]>([])
  const [tempAtivos, setTempTipos] = useState<RecomendaAtivosItens[]>([])

  const [clone, setClone] = useState(0)

  const [totalAtivos, setTotalAtivos] = useState(0)

  const [loadingTable, setLoadingTable] = useState(false)

  const listAtivos = async () => {
    setLoadingTable(true)
    const resp: AxiosResponse<TypeRecomendaAtivos> = await getRecomendaAtivos({
      pagination: -1,
    })

    setDataTipos(resp.data.itens)
    setTempTipos(resp.data.itens)
    setLoadingTable(false)
  }

  const handleClickNewTipo = () => {
    setVisibleForm(true)
  }

  const handleClickEdit = (cod) => {
    setUpdate(cod)
    setVisibleForm(true)
  }

  const handleSearch = (data) => {
    setLoadingTable(true)
    const dados = dataAtivos
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setTempTipos(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleClickClone = (data) => {
    setClone(data)
    setVisibleForm(true)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o ativo ${nome}?`,
      icon: <IconAlert type='confirm' size={6} />,
      okText: 'Sim',
      cancelText: 'Não',
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delRecomendaAtivos(cod)

        if (response.status === 200) {
          Notification({
            message: 'Ativo removido com sucesso',
            type: 'success',
          })

          listAtivos()
        }
      },
    })
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    if (data) {
      listAtivos()
    } else {
      setUpdate(0)
      setClone(0)
    }
  }

  useEffect(() => {
    listAtivos()
  }, [])

  return (
    <ViewPage
      title='Ativos'
      btnClick={handleClickNewTipo}
      search
      inputChange={handleSearch}
      content={
        <FormRecomendaAtivos
          onClose={(data) => handleCloseForm(data)}
          visible={visibleForm}
          update={update}
          clone={clone}
        />
      }
    >
      <Table
        rowKey='cod'
        loading={loadingTable}
        bordered
        size='small'
        dataSource={tempAtivos}
        onChange={(e, b, c, d) => setTotalAtivos(d.currentDataSource.length)}
        pagination={pagination}
        scroll={{ y: 'calc(90vh - 275px)', x: 1500 }}
        columns={[
          {
            key: 1,
            title: 'Nome',
            dataIndex: 'nome',
            sorter: (a, b) => a.nome.localeCompare(b.nome),
          },
          {
            key: 2,
            title: 'Classe agronômica',
            dataIndex: 'classe_agronomica_nome',
            ...filterTable(true, true, 'classe_agronomica_nome', tempAtivos),
          },
          {
            key: 3,
            title: 'Grupo químico',
            dataIndex: 'grupo_quimico_nome',
            ...filterTable(true, true, 'grupo_quimico_nome', tempAtivos),
          },
          {
            key: 4,
            title: 'Classe química',
            dataIndex: 'classe_quimica_nome',
            ...filterTable(true, true, 'classe_quimica_nome', tempAtivos),
          },
          {
            key: 5,
            title: 'Grupo',
            dataIndex: 'grupo_nome',
            ...filterTable(true, true, 'grupo_nome', tempAtivos),
          },
          {
            key: 6,
            title: 'Modo de ação',
            dataIndex: 'modo_acao_nome',
            sorter: (a, b) => {
              if (a.modo_acao_nome === null) {
                a.modo_acao_nome = ''
                b.modo_acao_nome = ''
              }
              return a?.modo_acao_nome.localeCompare(b?.modo_acao_nome)
            },
          },
          {
            key: 7,
            title: 'Local de acão',
            dataIndex: 'local_acao_nome',
            sorter: (a, b) => {
              if (a.local_acao_nome === null) {
                a.local_acao_nome = ''
                b.local_acao_nome = ''
              }
              return a?.local_acao_nome.localeCompare(b?.local_acao_nome)
            },
          },
          {
            key: 8,
            width: 135,
            title: 'Ações',
            dataIndex: 'cod',
            fixed: 'right',
            render: (data, record) => (
              <Space>
                <Button
                  shape='circle'
                  type='default'
                  onClick={() => handleClickEdit(data)}
                  icon={<Icon component={() => <Icons icon={faPen} />} />}
                />
                <Button
                  shape='circle'
                  onClick={() => handleClickClone(data)}
                  icon={<Icon component={() => <Icons icon={faCopy} />} />}
                />
                <Button
                  shape='circle'
                  type='primary'
                  danger
                  onClick={() => handleClickTrash(data, record.nome)}
                  icon={<Icon component={() => <Icons icon={faTrash} />} />}
                />
              </Space>
            ),
          },
        ]}
        footer={() => (
          <div
            style={{
              display: 'flex',
              width: '100%',
              justifyContent: 'right',
            }}
          >
            <b>Total geral: {totalAtivos > 0 ? totalAtivos : dataAtivos.length}</b>
          </div>
        )}
      />
    </ViewPage>
  )
}

export default RecomendaAtivos
