import styled from 'styled-components'

export const Container = styled.div`
  .ant-card-body {
    margin-bottom: -15px;
  }

  .ant-form-item-control:first-child:not([class^='ant-col-']):not([class*=' ant-col-']) {
    width: 90%;
  }

  .ant-drawer-header {
    padding: 0 !important;
  }

  .ant-upload,
  .ant-upload-list-item,
  .ant-upload-list-item-done {
    width: 200px !important;
    height: 200px !important;
  }
`
