import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesFilesUser {
  itens: ItensFilesUser[]
}

export interface ItensFilesUser {
  cod: number
  nome: string
  email: string
  cod_cliente: number
}

type TypesQuery = {
  cod?: number
  pagination?: number
  ordem?: string
}

const url = '/compartilhamento-arquivo/usuarios'

export const getFilesUser = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  api.defaults.headers.Acesso = 'conta'
  const resp: AxiosResponse<TypesFilesUser> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postFilesUser = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesFilesUser> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putFilesUser = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesFilesUser> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delFilesUser = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesFilesUser> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
