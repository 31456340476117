import styled from 'styled-components'

export const Container = styled.div``

export const BoxHeader = styled.div`
  position: absolute;
  padding: 8px;
  display: block;
  width: 665px;

  .ant-card-actions {
    li {
      padding-left: 15px;
      display: flex;
      align-items: flex-start;
    }
  }
`

export const TypeKrigagem = styled.div`
  position: absolute;
  bottom: 0;
  left: 0;
  background: #fff;
  width: 260px;
  height: 50px;
  padding: 15px;
  margin: 15px;
  border-radius: 5px;
`
