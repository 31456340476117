import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesAplicativo {
  itens: ItensAplicativoTypes[]
}

export interface ItensAplicativoTypes {
  cod?: number,
  app_bundle?: string,
  nome?: string,
  version_android?: string,
  version_ios?: string,
  url_android?: string,
  url_ios?: string,
  create_time?: string,
  update_time?: string,
  delete_time?: string
}

type TypesQuery = {
  cod?: number
}

const url = '/aplicativo'

export const getAplicativos = async (params: TypesQuery) => {
  const { conta } = localConfig()
  const resp: AxiosResponse<TypesAplicativo> = await api.get(url, {
    params: {
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}