import React, { useEffect } from 'react'

import { Button, Col, Drawer, Form, Input, message, Row } from 'antd'

import { getServico, postServico, putServico } from '../../services/Servicos'

import { Notification } from '../notification'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'

interface TypesLaboratorio {
  update: number
  visible: boolean
  setOnClose: (data: boolean) => void
  setUpdate: (data: number) => void
  atualizar: () => void
  permissions: ItensPerUserLogged
}

const Laboratorio: React.FC<TypesLaboratorio> = (props) => {
  const { update, visible, setOnClose, setUpdate, atualizar, permissions } = props

  const [form] = Form.useForm()

  const hancleDrawerClose = () => {
    setOnClose(false)
    setUpdate(0)
    form.resetFields()
  }

  const handleChangeServico = async (data) => {
    message.loading('Aguarde...')
    const response = await getServico(data === undefined ? '' : data)

    form.setFieldsValue(response.data.itens[0])
    message.destroy()
  }

  const onFinish = async (data) => {
    try {
      if (update === 0) {
        message.loading('Aguarde...')
        const response = await postServico(data)

        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Servico cadastrado com Sucesso',
            type: 'success',
          })
          form.resetFields()
          atualizar()
        }
      } else {
        message.loading('Aguarde...')
        const response = await putServico(update, data)

        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Servico atualizado com Sucesso',
            type: 'success',
          })
          atualizar()
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    if (update !== 0) {
      handleChangeServico(update)
    }
  }, [update])

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        title={update ? 'Atualizar Servico' : 'Cadastrar Servico'}
        style={{ position: 'absolute' }}
        placement='left'
        width='350px'
        onClose={hancleDrawerClose}
        open={visible}
        closeIcon={false}
        getContainer={false}
        footer={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button
              hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
              type='primary'
              htmlType='submit'
            >
              {update ? 'Atualizar Ordem de serviço' : 'Salvar'}
            </Button>
            {'   '}
            <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>
        }
      >
        <Col span={24}>
          <Row
            style={{
              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
            }}
            gutter={[8, 0]}
          >
            <Col flex='1 1 300px'>
              <Form.Item label='Nome' name='nome'>
                <Input />
              </Form.Item>
            </Col>
            <Col flex='1 1 200px'>
              <Form.Item label='Sigla' name='sigla'>
                <Input />
              </Form.Item>
            </Col>
          </Row>
        </Col>

        {/* Hiddenn */}

        <Form.Item initialValue='1' hidden name='status'>
          <Input />
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default Laboratorio
