import React, { useEffect, useState } from 'react'

import { Button, Checkbox, Modal, Space, Table, Typography } from 'antd'

import { faBars, faPen, faTrash } from '@fortawesome/free-solid-svg-icons'

import { AxiosResponse } from 'axios'

import { orderBy, uniq } from 'lodash'
import {
  SortableContainer,
  SortableContainerProps,
  SortableElement,
  SortableHandle,
  SortEnd,
} from 'react-sortable-hoc'
import { arrayMoveImmutable } from 'array-move'
import Icon from '@ant-design/icons'
import FormEstadios from '../../../../Components/estadios'

import { Notification } from '../../../../Components/notification'

import { Container } from './styled'
import {
  delEstadios,
  EstadioItens,
  getEstadios,
  putEstadios,
  TypeEstadios,
} from '../../../../services/estadio'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'
import { tiposGerais } from '../../../../services/Afins'
import Icons from '../../../../Components/Icons'

const { Text } = Typography

const CadEstadios: React.FC = () => {
  const [visibleForm, setVisibleForm] = useState(false)
  const [loadingTable, setLoadingTable] = useState(false)

  const [update, setUpdate] = useState(0)

  const [dataCultura, setDataCultura] = useState([])

  const [dataListEstadio, setDataListEstadio] = useState<EstadioItens[]>([])

  const [culturaFilter, setCulturaFilter] = useState([])

  const [dataTableRelative, setDataTableRelative] = useState<EstadioItens[]>([])

  const listEstadio = async () => {
    setLoadingTable(true)
    const response: AxiosResponse<TypeEstadios> = await getEstadios({
      pagination: -1,
    })

    const dados = orderBy(response.data.itens, ['cultura_nome', 'ordem'])

    setDataListEstadio(dados)
    setLoadingTable(false)
    handleChangeFilter([...culturaFilter], dados)
    listCultura(dados)
  }

  const listCultura = async (stadios) => {
    const resp = await tiposGerais()
    let filter = []
    resp.forEach((data) => {
      filter = [
        ...filter,
        {
          text: data.nome,
          value: data.nome,
        },
      ]

      return filter
    })

    const dataFilter = stadios.map((item) => {
      const data = filter.find((record) => record.text.includes(item.cultura_nome))
      return data
    })
    setDataCultura(uniq(dataFilter))
  }

  const handleClickNewEstadio = () => {
    setVisibleForm(true)
    setUpdate(0)
  }

  const handleClickEdit = (data) => {
    setVisibleForm(true)

    setUpdate(data)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataListEstadio
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleClickTrash = (cod, nome) => {
    Modal.confirm({
      title: `Deseja remover o estádio ${nome}?`,
      okText: 'Sim',
      cancelText: 'Não',
      icon: <IconAlert type='confirm' size={6} />,
      cancelButtonProps: {
        type: 'primary',
        danger: true,
      },
      onOk: async () => {
        const response = await delEstadios(cod)

        if (response.status === 200) {
          Notification({
            message: 'Estádio excluido com Sucesso',
            type: 'success',
          })

          listEstadio()
        }
      },
    })
  }
  const handleChangeFilter = (filter = [], dados?) => {
    let temp = []
    filter.forEach((filter) => {
      temp = [...temp, ...dados.filter((item) => item.cultura_nome === filter)]
      return temp
    })

    setDataTableRelative(filter.length === 0 ? [...dados] : [...temp])
    setCulturaFilter(filter)
  }

  const resetFilter = () => {
    setDataTableRelative(dataListEstadio)
    setCulturaFilter([])
  }

  const Drag = SortableHandle(() => (
    <Icon
      style={{
        fontSize: '18px',
        cursor: culturaFilter.length === 1 ? 'grab' : 'not-allowed',
        color: culturaFilter.length >= 1 ? '#72b628' : '#d0cccc',
      }}
      component={() => <Icons icon={faBars} />}
    />
  ))

  const TrSort = SortableElement((props: React.HTMLAttributes<HTMLTableRowElement>) => (
    <tr {...props} />
  ))

  const TbobySort = SortableContainer((props: React.HTMLAttributes<HTMLTableSectionElement>) => (
    <tbody {...props} />
  ))
  const onSortEnd = async ({ oldIndex, newIndex }: SortEnd) => {
    if (oldIndex !== newIndex) {
      const newEstadio = arrayMoveImmutable(dataListEstadio.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )
      setDataListEstadio(newEstadio)

      const newRelative = arrayMoveImmutable(dataTableRelative.slice(), oldIndex, newIndex).filter(
        (el) => !!el
      )
      setDataTableRelative(newRelative)

      const arrayCultura = newRelative.filter(
        (data) => data.cod_cultura === newRelative[oldIndex].cod_cultura
      )

      for (let i = 0; i < arrayCultura.length; i += 1) {
        const element = arrayCultura[i]
        await putEstadios(element.cod, { ordem: i + 1 })
      }
    }
  }

  const DraggableContainer = (props: SortableContainerProps) => (
    // @ts-ignore
    <TbobySort
      useDragHandle
      disableAutoscroll
      helperClass='row-dragging'
      onSortEnd={onSortEnd}
      {...props}
    />
  )

  const DraggableBodyRow: React.FC<any> = ({ ...restProps }) => {
    const index = dataTableRelative?.findIndex((x) => x.cod === restProps['data-row-key'])
    // @ts-ignore

    return <TrSort index={index} {...restProps} />
  }

  const handleCloseForm = (data) => {
    setVisibleForm(data)
    listEstadio()
    setUpdate(0)
  }
  useEffect(() => {
    listEstadio()
  }, [])
  return (
    <Container>
      <ViewPage
        title='Estádio'
        btnClick={handleClickNewEstadio}
        search
        inputChange={handlesPesquisa}
        content={
          <FormEstadios
            onClose={(data) => handleCloseForm(data)}
            open={visibleForm}
            update={update}
          />
        }
      >
        <Table
          rowKey='cod'
          loading={loadingTable}
          pagination={pagination}
          scroll={{ y: 'calc(90vh - 270px)' }}
          components={{
            body: {
              wrapper: DraggableContainer,
              row: culturaFilter.length === 1 ? DraggableBodyRow : null,
            },
          }}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataListEstadio.length}</b>
            </div>
          )}
          bordered
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleClickEdit(record.cod)
              },
            }
          }}
          size='small'
          columns={[
            {
              key: 0,
              title: 'Ordem',
              width: '80px',
              align: 'center',
              // @ts-ignore

              render: () => <Drag />,
            },
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Estádio',
              width: '200px',
              responsive: ['md', 'sm', 'lg'],
            },
            {
              key: 2,
              dataIndex: 'cultura_nome',
              width: '175px',
              title: 'Cultura',
              filterDropdown: (
                <div
                  style={{
                    padding: '10px',
                    width: '300px',
                    position: 'absolute',
                    top: 0,
                    left: 100,
                    backgroundColor: 'white',
                    boxShadow: '0px 4px 8px rgba(0, 0, 0, 0.15)',
                    borderRadius: '5px',
                  }}
                >
                  <Checkbox.Group
                    style={{ height: '200px', overflowY: 'auto' }}
                    value={culturaFilter}
                    onChange={(data) => handleChangeFilter(data, dataListEstadio)}
                  >
                    {dataCultura.map((data) => (
                      <div key={data.value} style={{ width: '100%', marginBottom: '5px' }}>
                        <Checkbox value={data.value}>{data.value}</Checkbox>
                      </div>
                    ))}
                  </Checkbox.Group>

                  <Button onClick={resetFilter} size='small' type='link'>
                    Limpar
                  </Button>
                </div>
              ),
            },
            {
              key: 3,
              dataIndex: 'descricao',
              title: 'Descrição',
              render: (data) => <Text>{data}</Text>,
            },
            {
              key: 4,
              dataIndex: 'cod',
              width: '90px',
              title: 'Ações',
              render: (data, record) => (
                <Space size='small'>
                  <Button
                    shape='circle'
                    onClick={() => handleClickEdit(data)}
                    icon={<Icons icon={faPen} />}
                  />
                  <Button
                    shape='circle'
                    onClick={() => handleClickTrash(data, record.nome)}
                    type='primary'
                    danger
                    icon={<Icons icon={faTrash} />}
                  />
                </Space>
              ),
            },
          ]}
          dataSource={dataTableRelative}
        />
      </ViewPage>
    </Container>
  )
}

export default CadEstadios
