import styled, { css } from 'styled-components'

export const Container = styled.div<{
  placement:
  | 'bottom'
  | 'top'
  | 'right'
  | 'left'
  | 'bottomLeft'
  | 'bottomRight'
  | 'topLeft'
  | 'topRight',
  size?: number
}>`
  position: absolute;
  opacity: 0.6;
  padding: 1rem;

  ${(p) =>
    p.placement === 'bottom'
      ? css`
          bottom: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'top'
      ? css`
          top: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'right'
      ? css`
          right: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'left'
      ? css`
          left: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'bottomLeft'
      ? css`
          bottom: 0;
          left: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'bottomRight'
      ? css`
          bottom: 0;
          right: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'topLeft'
      ? css`
          top: 0;
          left: 0;
        `
      : null};
  ${(p) =>
    p.placement === 'topRight'
      ? css`
          top: 0;
          right: 0;
        `
      : null};

  img {
    height: ${(p) => p.size}rem;
  }
`
