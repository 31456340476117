import styled from "styled-components";
import { useState } from "react";
import { faFileExport } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

//APP
import { ImportarPontos } from "./import";
import { ExportarPontos } from "./export";
import { ExcluirTodosOsPontos } from "./deteleAll";
import { usePostRemoverPontos } from "../../../../../../../services/monitoramento/padraoDePontos/novo_index";
import { TalhaoCultivaveisItens } from "../../../../../../../services/TalhaoSafra";
import { AgrupamentoTalhaoItens } from "../../../../../../../services/agrupamentoTalhao";

//COMPONENTS
import Label from "../../../../../../Label";
import ButtonCustom from "../../../../../../ButtonCustom";
import { Render } from "../../../../../../CustomUi/render";

//STYLES
const OptionsContainer = styled.div`
    width: 100%;
    display: flex; 
    gap: 10px;
    margin-top: -8px;

    div.OptionsContainer__placeholder {
        width: 110.95px;
        height: 100%;
        position: relative;
    }
`;

type MarkerType = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
}

//PROPS 
interface Props {
    handleChangePropriedade: Function;
    handleChangeAgrupamento: Function;
    handleChangeTalhao: Function;
    handleChangeSubarea: Function;
    markerDefault: MarkerType[];
    tipoMonitoramento: number | null;
    nome_propriedade_atual: string;
    cod_propriedade_atual: number | null;
    cod_agrupamento: number | null;
    cod_talhao_atual: number | null;
    cod_subarea_atual: number | null;
    list_talhoes: TalhaoCultivaveisItens[];
    nome_talhao_atual: string;
    list_Agrupamentos: AgrupamentoTalhaoItens[]
    polygonSubArea: google.maps.Polygon;
    nome_subarea: string;
    atualizarListaPropriedades: Function;
    nome_agrupamento_atual: string;
    nome_subarea_atual: string;
}

export const OptionsPainel: React.FC<Props> = ({ handleChangePropriedade, handleChangeTalhao, markerDefault, 
    tipoMonitoramento, cod_propriedade_atual, cod_talhao_atual, list_talhoes, cod_subarea_atual, 
    nome_propriedade_atual, nome_talhao_atual, cod_agrupamento, list_Agrupamentos, handleChangeAgrupamento,
    handleChangeSubarea, polygonSubArea, nome_subarea, atualizarListaPropriedades, nome_agrupamento_atual, 
    nome_subarea_atual
 }) => {

    //API
    const { carregando_remover_pontos, mutate_remover_pontos } = usePostRemoverPontos();
    const [rerender, setRerender] = useState<boolean>(true);

    function fakeButton() {
        
        setTimeout(() => {
            setRerender(false)
        }, 1500);

        setTimeout(() => {
            setRerender(true)
        }, 1500);
    }

    return (
        <>
        <Label fontSize={13}>Opções de pontos</Label>
        <OptionsContainer>
            <ImportarPontos
                handleChangePropriedade={handleChangePropriedade}
                handleChangeAgrupamento={handleChangeAgrupamento}
                handleChangeSubarea={handleChangeSubarea}
                handleChangeTalhao={handleChangeTalhao}
                markerDefault={markerDefault}
                tipoMonitoramento={tipoMonitoramento}
                cod_propriedade_atual={cod_propriedade_atual}
                cod_agrupamento={cod_agrupamento}
                cod_talhao_atual={cod_talhao_atual}
                cod_subarea_atual={cod_subarea_atual}
                list_talhoes={list_talhoes}
                carregando_remover_pontos={carregando_remover_pontos}
                list_Agrupamentos={list_Agrupamentos}
                polygonSubArea={polygonSubArea}
                nome_subarea={nome_subarea}
                atualizarListaPropriedades={atualizarListaPropriedades}
            />
            
            
            <div className="OptionsContainer__placeholder">
                <Render condition={rerender}>
                    <ExportarPontos 
                        carregando_remover_pontos={carregando_remover_pontos}
                        markerDefault={markerDefault}
                        nome_propriedade_atual={nome_propriedade_atual}
                        nome_talhao_atual={nome_talhao_atual}
                        tipoMonitoramento={tipoMonitoramento}
                        nome_agrupamento_atual={nome_agrupamento_atual}
                        nome_subarea_atual={nome_subarea_atual}
                        fakeButton={fakeButton}
                    />
                </Render>
                
                <ButtonCustom
                    onClick={() => {}}
                    icon={<FontAwesomeIcon icon={faFileExport} />}
                    loading={true}
                    disabled={true}
                    style={{position:'absolute',top:0,left:0,zIndex:0}}
                >
                    Exportar
                </ButtonCustom>
    
            </div>

            <ExcluirTodosOsPontos 
                handleChangeAgrupamento={handleChangeAgrupamento}
                handleChangeSubarea={handleChangeSubarea}
                cod_subarea_atual={cod_subarea_atual}
                cod_agrupamento={cod_agrupamento}
                carregando_remover_pontos={carregando_remover_pontos}
                cod_propriedade_atual={cod_propriedade_atual}
                cod_talhao_atual={cod_talhao_atual}
                handleChangePropriedade={handleChangePropriedade}
                handleChangeTalhao={handleChangeTalhao}
                markerDefault={markerDefault}
                mutate_remover_pontos={mutate_remover_pontos}
                tipoMonitoramento={tipoMonitoramento}
                atualizarListaPropriedades={atualizarListaPropriedades}
            />
        </OptionsContainer>
        </>
    )
};