import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { Button, Popconfirm } from "antd";
import { useEffect, useState } from "react";
import { faFloppyDisk, faNetworkWired, faTag, faTrash, faXmark } from "@fortawesome/free-solid-svg-icons";

//APP
import { obterTipoPorId, obterValor } from "../../../compatibilidades/meta";
import { localConfig } from "../../../../../../../../../../Configs/localConfig";
import { obterCodPorId } from "../../../../../../meta";
import { apenasNumeros } from "../../../../../../../../../../utils";
import { usePostPlantBack } from "../../../../../../../../../../services/recomendacao/ativos/post";
import { useDeleteCompatibilidade } from "../../../../../../../../../../services/recomendacao/ativos/delete";

//COMPONENTS
import Input from "../../../../../../../../../../Components/CustomUi/input";
import TextArea from "../../../../../../../../../../Components/CustomUi/textarea";
import ButtonCustom from "../../../../../../../../../../Components/ButtonCustom";
import { Inline } from "../../../../../../../../../../Components/CustomUi/inline";

//PROPS
interface Props {
    registros: any;
    onClose?: Function;
    atualizarLista?: Function;
    atualizarListaPrincipal?: Function;
    isVisualizar?: boolean;
    refetch?: Function;
    id_tipo_principal: number | null;
}

export const ModalEditar: React.FC<Props> = ({ registros, onClose, atualizarLista, isVisualizar, refetch, atualizarListaPrincipal, id_tipo_principal }) => {

    //STATES
    const [plantback, setPlantback] = useState<string>('');
    const [original_plantback, setOriginal_plantback] = useState<string>('');
    const [obs, setObs] = useState<string>('');
    const [original_obs, setOriginal_obs] = useState<string>('');
    const [openConfirm, setOpenConfirm] = useState<boolean>(false);

    //API
    const { carregando_salvar_plant, mutate_salvar_plant } = usePostPlantBack();
    const { carregando_deletar_compati, mutate_deletar_compati } = useDeleteCompatibilidade();

    function salvarEdicao() {
        mutate_salvar_plant({
            codConta: Number(localConfig().conta),
            id_tipo_principal: id_tipo_principal,
            id_item_principal: registros?.[obterCodPorId(registros?.lancamento)],
            id_estadio_principal: registros?.estadio_cultura,
            cod: registros?.cod,
            cod_ativo: registros?.cod_ativo,
            cod_cultura: registros?.cod_cultura,
            plant_back: Number(plantback),
            obs: obs,
            limparStates: () => onClose?.(),
            atualizarLista: () => atualizarLista?.(),
            atualizarListaPrincipal: () => atualizarListaPrincipal?.(),
        });
    }

    function deletarCompatibilidade() {
        mutate_deletar_compati({
            codConta: Number(localConfig().conta),
            cod: registros.cod,
            limparStates: () => {},
            atualizarLista: () => {refetch?.(); atualizarLista?.(); atualizarListaPrincipal?.()},
            closePopup: () => setOpenConfirm(false)
        })
    }

    useEffect(() => {
        setObs(registros?.observacao);
        setOriginal_obs(registros?.observacao);
        setPlantback(registros?.plant_back);
        setOriginal_plantback(registros?.plant_back);
    }, [registros]);
    

    function desabilitarBtnSalvar() {
        if (carregando_salvar_plant) {
            return true;
        }
        if (isVisualizar) {
            return false;
        }
        return (original_obs === obs && original_plantback === plantback)
    }

    return (
        <div style={{display:'flex',flexDirection:'column',gap:15}}>

            <Inline gap={15}>
                <Input 
                    label="Tipo"
                    value={obterTipoPorId(registros?.lancamento)}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faNetworkWired}
                    desabilitado
                />
                <Input 
                    label={obterTipoPorId(registros?.lancamento)}
                    value={registros?.lancamento_nome}
                    onChange={()=>{}}
                    placeholder="Selecione o tipo"
                    icon={faTag}
                    desabilitado
                />
            </Inline>
      
            <Input 
                label="Cultura"
                value={registros?.item_lancamento_nome}
                onChange={()=>{}}
                placeholder="Selecione o tipo"
                icon={faNetworkWired}
                desabilitado
            />

            <Input 
                label="Tempo até o inicio do plantio (em dias)"
                value={apenasNumeros(plantback)}
                onChange={setPlantback}
                placeholder="Selecione o tipo"
                icon={faTag}
                obrigatorio
            />

            <TextArea
                label={`Observação da compatibilidade`}
                placeholder='Digite aqui a observação'
                value={obs}
                onChange={setObs}
                resize={false}
                height={70}
            />

            <Inline justifyContent={isVisualizar ? 'space-between' :"flex-end"} reverse={isVisualizar}>
                {isVisualizar ? 
                    <Popconfirm
                        placement="topRight"
                        title={'Tem certeza que deseja deletar este item?'}
                        open={openConfirm}
                        description={
                        <div style={{display:'flex',flexDirection:'column',gap:15}}>
                            <span>o item <b><i>{obterValor(registros)}</i></b> será deletado!</span>
                            <Inline justifyContent="flex-end">

                                <ButtonCustom 
                                    onClick={()=>{setOpenConfirm(false)}}
                                    type="tertiary"
                                    icon={<FontAwesomeIcon icon={faXmark} />}
                                    disabled={carregando_salvar_plant}
                                >
                                    Cancelar
                                </ButtonCustom>

                                <ButtonCustom 
                                    onClick={()=>{deletarCompatibilidade()}}
                                    icon={<FontAwesomeIcon icon={faTrash} />}
                                    danger
                                    loading={carregando_deletar_compati}
                                    disabled={carregando_deletar_compati}
                                >
                                    Deletar
                                </ButtonCustom>

                            </Inline>
                        </div>}
                        okButtonProps={{style: {display:'none'}}}
                        cancelButtonProps={{style: {display:'none'}}}
                    >
                        <Button style={{background:'transparent', padding:0, margin:0, border:'none', boxShadow:'none'}}>
                            <ButtonCustom
                                onClick={()=>{setOpenConfirm(true)}}
                                icon={<FontAwesomeIcon icon={faTrash} />}
                                type="secondary"
                                danger
                            >Deletar</ButtonCustom>  
                        </Button>
                    </Popconfirm>
                :
                    <ButtonCustom
                        icon={<FontAwesomeIcon icon={faXmark}></FontAwesomeIcon>}
                        onClick={()=>{onClose(false)}}
                        type="tertiary"
                        danger
                        disabled={carregando_salvar_plant}
                    >
                        Cancelar
                    </ButtonCustom>
                }
                
                <ButtonCustom 
                    icon={<FontAwesomeIcon icon={faFloppyDisk}></FontAwesomeIcon>}
                    onClick={()=>{salvarEdicao()}}
                    loading={carregando_salvar_plant}
                    disabled={desabilitarBtnSalvar()}
                >
                    Salvar edição
                </ButtonCustom>
            </Inline>

        </div>
    )
};