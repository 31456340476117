import { Modal } from 'antd/lib'
import styled from 'styled-components'

export const CustomModal = styled(Modal)`
  .ant-modal-content {
    padding: 20px 24px 5px;
    height: 100vh;
  }

  .ant-modal-footer{
    margin-top: 5px;
  }

  .ant-modal-close {
    left: 45px; 
    top: 6px;
  }

  .ant-modal-body {
    max-height: calc(100vh - 64px); 
  }
  
`
