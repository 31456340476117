import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesParamApp {
  itens: ItensParamAppTypes[]
}

export interface ItensParamAppTypes {
  cod?: number
  cod_conta?: number
  cod_aplicativo?: number
  parametros?: {
    maximo_repeticoes?: number
    minimo_repeticoes?: number
    velocidade_maxima?: number
    trajeto_coleta?: boolean
    trajeto_monitoramento?: boolean
    observacoes_coleta?: string[]
    periodo_sincronizar_monitoramento?: {
      data_final?: string
      data_inicio?: string
    }
  }

  create_time?: string
  update_time?: string
  delete_time?: string
  app_bundle?: string
}

export interface DataPostParamAppTypes {
  cod_aplicativo?: number
  parametros?: {
    velocidade_maxima?: number
    minimo_repeticoes?: number
    maximo_repeticoes?: number
    periodo_sincronizar_monitoramento?: {
      data_inicio?: string
      data_final?: string
    }
    observacoes_coleta?: string[]
  }
}

type TypesQuery = {
  cod?: number
}

const url = '/parametros-app'

export const getParamApps = async (params: TypesQuery) => {
  const { conta, codCliente } = localConfig()
  const resp: AxiosResponse<TypesParamApp> = await api.get(url, {
    params: {
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postParamApps = async (data: DataPostParamAppTypes) => {
  const { conta } = localConfig()

  const resp: AxiosResponse<TypesParamApp> = await api.post(url, data, {
    params: { codConta: conta },
  })

  return resp
}

export const putParamApps = async (data: DataPostParamAppTypes, cod: number) => {
  const { conta } = localConfig()

  const resp: AxiosResponse<TypesParamApp> = await api.put(`${url}/${cod}`, data, {
    params: { codConta: conta },
  })

  return resp
}

export const delParamApps = async (cod: number) => {
  const { conta } = localConfig()

  const resp: AxiosResponse<TypesParamApp> = await api.delete(`${url}/${cod}`, {
    params: { codConta: conta },
  })

  return resp
}
