import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'
import { message } from 'antd'

interface Props {
  embed?: string
  codConta?: number
  codCliente?: number
}

const url = '/change-log-info'

export const getLogs = async (params: Props) => {
  const { acesso, conta, codCliente } = localConfig()
  api.defaults.headers.Acesso = acesso

  const response = await api.get(url, {
    params: {
      codConta: conta,
      codCliente: codCliente,
      ...params,
      pagination: -1,
    },
  })

  return response
}

export const postLogs = async (
  data,
  setTitulo,
  setDescricao,
  setTipoLog,
  setBranch,
  setItensLogs,
  setDescricaoNew,
  setCheckBox,
  setNomeLog,
  setUsuarioLogNome,
  setAddNewLog
) => {
  const { titulo, descricao, aplicacao, tipo, branch, logs, cod_usuario, version } = data

  api.defaults.headers.Acesso = 'admin'

  const response: AxiosResponse = await api
    .post('/change-log', {
      titulo: titulo,
      descricao: descricao,
      aplicacao: aplicacao,
      tipo: tipo,
      branch: branch,
      logs: logs,
      cod_usuario: cod_usuario,
      versao: version
    })
    .then((): any => {
      message.success({ content: 'ChangeLog cadastrado com sucesso!', key: 'loading' })
      setTitulo('')
      setDescricao('')
      setTipoLog(0)
      setBranch('')
      setItensLogs([])
      setDescricaoNew('')
      setNomeLog('')
      setUsuarioLogNome('')
      setCheckBox(false)
      setAddNewLog(false)
    })
    .catch(() => {
      message.error({ content: 'Falha ao cadastrar changeLog', key: 'loading' })
    })

  return response
}

export const putLogs = async (
  data,
  cod,
  setTitulo,
  setDescricao,
  setTipoLog,
  setBranch,
  setItensLogs,
  setDescricaoNew,
  setCheckBox,
  setNomeLog,
  setUsuarioLogNome,
  setAddNewLog,
  fecharModal
) => {
  const { titulo, descricao, aplicacao, tipo, branch, logs, cod_usuario, logs_remover } = data

  api.defaults.headers.Acesso = 'admin'

  const response: AxiosResponse = await api
    .put(`/change-log/${cod}`, {
      titulo: titulo,
      descricao: descricao,
      aplicacao: aplicacao,
      tipo: tipo,
      branch: branch,
      logs: logs,
      cod_usuario: cod_usuario,
      logs_remover: logs_remover
    })
    .then((): any => {
      message.success({ content: 'ChangeLog atualizado com sucesso!', key: 'loading' })
      setTitulo('')
      setDescricao('')
      setTipoLog(0)
      setBranch('')
      setItensLogs([])
      setDescricaoNew('')
      setNomeLog('')
      setUsuarioLogNome('')
      setCheckBox(false)
      setAddNewLog(false)
      fecharModal()
    })
    .catch(() => {
      message.error({ content: 'Falha ao atualizar changeLog', key: 'loading' })
    })

  return response
}

export const deleteLog = async (cod) => {
  const { acesso, conta, codCliente } = localConfig()
  api.defaults.headers.Acesso = 'admin'

  const response = await api.delete(
    `/change-log/${cod}?codConta=${conta}&codCliente=${codCliente}`)

    .then((): any => {
      message.success({ content: 'ChangeLog deletado com sucesso!', key: 'loading' })
     
    })
    .catch(() => {
      message.error({ content: 'Falha ao deletar changeLog', key: 'loading' })
    })

  return response
}

export const getUsuarioLog = async () => {
  const config = localConfig()

  api.defaults.headers.Acesso = 'admin'

  const response: AxiosResponse = await api.get('/change-log/usuarios')

  return response
}
