import styled from "styled-components";

export const BoxContainer = styled.div`
    box-sizing: border-box;
    padding: 10px;
    border-radius: 10px;
    border: 2px solid var(--border-color);
    display: flex;
    flex-direction: column;
    gap: 10px;
`