import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { useState, useEffect } from 'react';
import { Button, Drawer, Switch, Tabs, Tag } from 'antd';
import { faClipboardCheck, faFloppyDisk, faGripVertical, faXmark } from '@fortawesome/free-solid-svg-icons';

//APP
import { Item__MapaFertilidade__Config, ParametroFertilidade__Itens } from '../../../../../../services/novoConfigBook/get';

//STYLE
import { CampoObs, CampoTabs, Footer, HeaderGaveta, HeaderNavagacao, ObsIndicadores, Representacao, SwitchSecao } from './styled';

//COMPONENTS
import Tabs1 from '../../../../../../Components/Tabs';
import Checkbox from '../../../../../../Components/CustomUi/checkbox';
import TextArea from '../../../../../../Components/CustomUi/textarea';
import ButtonCustom from '../../../../../../Components/ButtonCustom';
import { attrTipos_mapa, corTagSecao, filtrarTipoMapa, obterIdSecao, tipoSecao } from './meta';
import { TypeobterProfundidades, atualizarElementoModificado } from '../elementos/meta';
import { alterarObservacaoMapa, alterarObservacaoProfundidade, alterarObservacaoSecao, alterarVisibilidadeMapa, alterarVisibilidadeProfundidade, alterarVisibilidadeProfundidadeCalcario, alterarVisibilidadeSecao } from './meta/alterar';

//PROPS
interface PropsGaveta {
    visible: boolean
    onClose: React.Dispatch<React.SetStateAction<boolean>>;
    nomeElemento?: string;
    codElemento?: number;
    parametros?: ParametroFertilidade__Itens[]
    listaElementos: Item__MapaFertilidade__Config[];
    secao_atual: 'Fertilidade' | 'Indicadores' | 'Prescrições';
    textoIntroducao: string;
    callUsePostSalvarConfigBook: Function;
    isFetching: boolean;
    info_tipos_mapa: {descricao: string, cod_tipo_mapa: number, ativo: boolean}[];
    info_profundidades: TypeobterProfundidades[];
    id_posicao: number;
    info_ordem: number;
    elementosBase?: ParametroFertilidade__Itens[]
}
//TYPES
type TypeParamsProfundidade = {
    profundidade_nome: string,
    cod_profundidade: number,
    tipo_mapa_nome: string,
    cod_tipo_mapa: number,
    key: string
}

const GavetaElementos: React.FC<PropsGaveta> = ({ visible, onClose, codElemento, listaElementos, parametros, secao_atual, 
    callUsePostSalvarConfigBook, isFetching, nomeElemento, info_tipos_mapa, info_profundidades, id_posicao, info_ordem, elementosBase
 }) => {
    
    //STATES
    const [tipo_de_mapa, setTipo_de_mapa] = useState<'Interpretado' | 'Distribuído'>('Interpretado');
    const [tab_atual, setTab_atual] = useState('')
    const [info_parametro, setInfo_parametro] = useState<ParametroFertilidade__Itens|null>();
    const [info_elemento, setInfo_elemento] = useState<Item__MapaFertilidade__Config|null>(null);
    const [info_original, setInfo_original] = useState<Item__MapaFertilidade__Config|null>();
    const [lista_params_profundidade, setLista_params_profundidade] = useState<TypeParamsProfundidade[]>([]);
    const [elemento_existe, setElemento_existe] = useState<boolean|undefined>(undefined);
    //STATES - FORM
    const [swt_visivel_no_book, setSwt_visivel_no_book] = useState<boolean>(true);
    const [obs_atual, setObs_atual] = useState<string>('');
    const [checkbox_visivel, setCheckbox_visivel] = useState<boolean>(false);
    //STATES - EDIÇÃO
    const [tipo_mapa_atual, setTipo_mapa_atual] = useState<number>(1);
    const [profundidade_atual, setProfundidade_atual] = useState<number>();
    const [existe_inicialmente, setExiste_inicialmente] = useState<boolean>(true);

    //FUNÇÕES
    const selecionarTab = (newActiveKey: string) => {
        setTab_atual(newActiveKey)
    }
    function isCalcario(): boolean {
        let nome_elemento: string = elemento_existe ? info_elemento?.nome : nomeElemento;
        return nome_elemento==='Calcário'
    }

    useEffect(() => {
        setInfo_elemento(listaElementos?.find((item)=> item.elemento === codElemento));
        setInfo_original(listaElementos?.find((item)=> item.elemento === codElemento));
        setElemento_existe(!!(listaElementos?.find((item)=> item.elemento === codElemento)));
    }, [codElemento]);   

    useEffect(() => {
        setInfo_parametro(parametros?.find((item)=> item.cod === info_elemento?.elemento));
    }, [info_elemento]);
    
    useEffect(() => {

        let param = info_parametro?.parametros;
        
        for (let i = 0; i < (param ?? []).length; i++) {
            setLista_params_profundidade(lista_params_profundidade=>[...lista_params_profundidade, {
                profundidade_nome: param?.[i]?.profundidade_nome,
                cod_profundidade: param?.[i]?.profundidade,
                tipo_mapa_nome: param?.[i]?.tipo_mapa_nome,
                cod_tipo_mapa: isCalcario() ? 2 : param?.[i]?.tipo_mapa,
                key: `pro_${param?.[i]?.profundidade}_tipo_${param?.[i]?.tipo_mapa}`
            }])
        }
        
        setTab_atual(`pro_${param?.[0]?.profundidade}_tipo_${param?.[0]?.tipo_mapa}`)

    }, [info_parametro]);
    
    useEffect(() => {
        let param = info_parametro?.parametros;
        setTab_atual(`pro_${param?.[0]?.profundidade}_tipo_${tipo_de_mapa==='Distribuído'?2:1}`)
    }, [tipo_de_mapa])
    

    useEffect(() => {
        setSwt_visivel_no_book(info_elemento?.secoes?.[0]?.visivel);
        setExiste_inicialmente(info_elemento?.secoes?.[0]?.visivel);
    }, [info_elemento]);

    useEffect(() => {
        let split = String(tab_atual)?.split('_');

        setProfundidade_atual(Number(split?.[1]));
        setTipo_mapa_atual(Number(split?.[3]));
    }, [tab_atual]);

    useEffect(() => {

        if (existe_inicialmente === false && swt_visivel_no_book) {
            setInfo_elemento({
                id: id_posicao,
                elemento: codElemento,
                nome: nomeElemento,
                nova_ordem: id_posicao,
                secoes: [{ 
                    secao: obterIdSecao(secao_atual),
                    ordem: info_ordem, 
                    visivel: true,
                    observacao: '',
                    tipos_mapa: attrTipos_mapa(secao_atual, info_profundidades, isCalcario())
                }]
            })
        }
    }, [elemento_existe, swt_visivel_no_book])
    
    
    useEffect(() => {

        let mapas = info_elemento?.secoes?.[0]?.tipos_mapa;
        let profundidades = mapas?.[tipo_mapa_atual===1?0:1]?.profundidades;
        let obter_obs = profundidades?.find((item)=> item.profundidade === profundidade_atual);

        let profundidades_calcario = mapas?.[0]?.profundidades;
        let obter_obs_calcario = profundidades_calcario?.find((item)=> item.profundidade === profundidade_atual);

        if (secao_atual==='Fertilidade') {
            setCheckbox_visivel(obter_obs?.visivel);
            setObs_atual(obter_obs?.observacao ?? '');
        }
        if (secao_atual==='Indicadores') {
            setCheckbox_visivel(mapas?.[tipo_mapa_atual===1?0:1]?.visivel);
            setObs_atual( mapas?.[tipo_mapa_atual===1?0:1]?.observacao ?? '');
        }
        if (secao_atual==='Prescrições' && !isCalcario()) {
            setObs_atual( info_elemento?.secoes?.[0]?.observacao );
        }
        if (secao_atual==='Prescrições' && isCalcario()) {
            setCheckbox_visivel(obter_obs_calcario?.visivel);
            setObs_atual(obter_obs_calcario?.observacao ?? '');
        }
    
    }, [profundidade_atual, tipo_mapa_atual, info_elemento]); 

    function salvarAlteracao() {

        atualizarElementoModificado(listaElementos, info_elemento, info_ordem);

        callUsePostSalvarConfigBook();
    }

    useEffect(() => {
        return () => {
            setInfo_elemento(null);
            setInfo_original(null);
            setInfo_parametro(null);
            setLista_params_profundidade([]);
        }
    }, []);

    function substituirAsc(str:string) {
        
        let remove = str?.replace(/quot;|&amp;/g, '"');
        
        return remove ?? str;
    }

    return (
        <Drawer
            width='450px'
            bodyStyle={{ padding: 0 }}
            headerStyle={{padding: '10px 10px'}}
            onClose={() => {
                setInfo_elemento(null);
                setInfo_original(null);
                setInfo_parametro(null);
                setLista_params_profundidade([]);
                onClose(false)
            }}
            open={visible}
            closable={false}
            title={
                <HeaderNavagacao>
                    <ButtonCustom
                        danger
                        icon={<FontAwesomeIcon icon={faXmark} />}
                        type='tertiary'
                        onClick={() => onClose(false)}
                        style={{ marginLeft: -10 }}
                    >
                        Fechar
                    </ButtonCustom>
                </HeaderNavagacao>
            }
        >

            <HeaderGaveta>
                <h2 className='HeaderGaveta__titulo'>
                    {elemento_existe ? info_elemento?.nome : nomeElemento}
                </h2>

                <div className='HeaderGaveta__tags'>
                    {!elemento_existe &&(
                        <Tag color='error'>Não possui cadastro</Tag>
                    )}
                    
                    <Tag color={corTagSecao(info_elemento?.secoes?.[0]?.secao)}>{tipoSecao(info_elemento?.secoes?.[0]?.secao)}</Tag>
                    <Tag color={tipo_de_mapa=== 'Interpretado' ? 'success' : 'processing'}>{tipo_de_mapa}</Tag>
                </div>
            </HeaderGaveta>

            <SwitchSecao>
                <h3 className='SwitchSecao__titulo'>Seções do mapa</h3>

                <div className='SwitchSecao__switch'>
                    <Switch
                        checkedChildren='Sim'
                        unCheckedChildren='Não'
                        checked={swt_visivel_no_book}
                        onChange={setSwt_visivel_no_book}
                        onClick={elemento_existe ? ()=>alterarVisibilidadeSecao({info_elemento, swt_visivel_no_book, setInfo_elemento}) : (()=>{})}
                    />
                    <p className='SwitchSecao__switch__label'>Visível no book</p>
                </div>
            </SwitchSecao>

            {secao_atual!=='Prescrições' && (
                <Representacao data-desabilitar={!swt_visivel_no_book}>
                    <h3 className='Representacao__titulo'>Tipo de representação do mapa</h3>

                    <Tabs1
                        // width={358}
                        tabs={[
                            { descricao: 'Interpretado', icone: faClipboardCheck, disabled: !(info_tipos_mapa??[]).some(item => item.cod_tipo_mapa === 1) },
                            { descricao: 'Distribuído', icone: faGripVertical, disabled: !(info_tipos_mapa??[]).some(item => item.cod_tipo_mapa === 2) },
                        ]}
                        indexInicial={0}
                        selecionado={tipo_de_mapa}
                        onChange={setTipo_de_mapa}
                        trackWidth={200}
                        disableAll={!swt_visivel_no_book}
                    />
                </Representacao>
            )}
            

            {secao_atual==='Fertilidade' &&(
                <CampoTabs data-desabilitar={!swt_visivel_no_book}>
                    <h3 className='CampoTabs__titulo'>Profundidades</h3>

                    <Tabs
                        key={tipo_de_mapa}
                        tabBarStyle={{ margin: 0 }}
                        onChange={(data)=>selecionarTab(data)}
                        type='card'
                        aria-disabled={!swt_visivel_no_book}
                        items={(lista_params_profundidade ?? [])?.filter(filtrarTipoMapa(tipo_de_mapa==='Interpretado'?1:2)).map((prof) => {
                            return {
                                label: prof.profundidade_nome,
                                key: prof.key,
                                children: (
                                    <CampoObs>

                                        <Checkbox 
                                            label={`Visível na profundidade ${prof.profundidade_nome}`}
                                            isChecked={checkbox_visivel}
                                            trigger={setCheckbox_visivel}
                                            disabled={!swt_visivel_no_book}
                                            onCheckClick={()=>alterarVisibilidadeProfundidade({
                                                info_elemento,
                                                lista_params_profundidade,
                                                tipo_mapa_atual, 
                                                tipo_de_mapa,
                                                checkbox_visivel,
                                                setInfo_elemento,
                                                profundidade_atual
                                            })}
                                        />

                                        <TextArea 
                                            label='Observação do elemento'
                                            placeholder='Digite aqui a observação que será apresentada no book'
                                            value={substituirAsc(obs_atual)}
                                            onChange={setObs_atual}
                                            height={150}
                                            resize={false}
                                            maxLength={350}
                                            disabled={!swt_visivel_no_book || !checkbox_visivel}
                                            onKeyUp={()=>alterarObservacaoProfundidade({
                                                info_elemento,
                                                lista_params_profundidade,
                                                tipo_mapa_atual,
                                                tipo_de_mapa,
                                                obs_atual,
                                                setInfo_elemento,
                                                profundidade_atual
                                            })}
                                        />

                                        <div className='lenght__text'>
                                            <p className='number__total'>{obs_atual?.length} / <span className='lenght__text__bold'>350</span></p>
                                        </div>

                                    </CampoObs>
                                ),
                            }
                        })}
                        
                    />
                </CampoTabs>
            )}
            
            {secao_atual==='Indicadores' &&(
                <CampoTabs data-desabilitar={!swt_visivel_no_book}>
                    {/* <h3 className='CampoTabs__titulo'>Profundidades</h3> */}

                    <ObsIndicadores>

                        <Checkbox 
                            label={`Visível no mapa ${tipo_de_mapa}`}
                            isChecked={checkbox_visivel}
                            trigger={setCheckbox_visivel}
                            disabled={!swt_visivel_no_book}
                            onCheckClick={()=>alterarVisibilidadeMapa({
                                info_elemento,
                                tipo_mapa_atual,
                                checkbox_visivel,
                                setInfo_elemento
                            })}
                        />

                        <TextArea 
                            label='Observação do elemento'
                            placeholder='Digite aqui a observação que será apresentada no book'
                            value={substituirAsc(obs_atual)}
                            onChange={setObs_atual}
                            height={150}
                            resize={false}
                            maxLength={350}
                            disabled={!swt_visivel_no_book || !checkbox_visivel}
                            onKeyUp={()=>alterarObservacaoMapa({info_elemento, tipo_mapa_atual, obs_atual, setInfo_elemento})}
                        />

                        <div className='lenght__text'>
                            <p className='number__total'>{obs_atual?.length} / <span className='lenght__text__bold'>350</span></p>
                        </div>

                    </ObsIndicadores>
                </CampoTabs>
            )}
            
            {(secao_atual==='Prescrições' && !isCalcario()) &&(
                <CampoTabs data-desabilitar={!swt_visivel_no_book}>

                    {/* <h3 className='CampoTabs__titulo'>Configurção</h3> */}

                    <ObsIndicadores>

                        <TextArea 
                            label='Observação do elemento'
                            placeholder='Digite aqui a observação que será apresentada no book'
                            value={substituirAsc(obs_atual)}
                            onChange={setObs_atual}
                            height={150}
                            resize={false}
                            maxLength={350}
                            disabled={!swt_visivel_no_book}
                            onKeyUp={()=>alterarObservacaoSecao({info_elemento, obs_atual, setInfo_elemento})}
                        />

                        <div className='lenght__text'>
                            <p className='number__total'>{obs_atual?.length} / <span className='lenght__text__bold'>350</span></p>
                        </div>

                    </ObsIndicadores>
                </CampoTabs>
            )}

            {(secao_atual==='Prescrições' && isCalcario()) &&(
                <CampoTabs data-desabilitar={!swt_visivel_no_book}>
                    <h3 className='CampoTabs__titulo'>Profundidades</h3>

                    <Tabs
                        key={tipo_de_mapa}
                        tabBarStyle={{ margin: 0 }}
                        onChange={(data)=>selecionarTab(data)}
                        type='card'
                        aria-disabled={!swt_visivel_no_book}
                        items={(lista_params_profundidade ?? [])?.filter(filtrarTipoMapa(2)).map((prof) => {
                            return {
                                label: prof.profundidade_nome,
                                key: prof.key,
                                children: (
                                    <CampoObs>

                                        <Checkbox 
                                            label={`Visível na profundidade ${prof.profundidade_nome}`}
                                            isChecked={checkbox_visivel}
                                            trigger={setCheckbox_visivel}
                                            disabled={!swt_visivel_no_book}
                                            onCheckClick={()=>alterarVisibilidadeProfundidade({
                                                info_elemento,
                                                lista_params_profundidade,
                                                tipo_mapa_atual: 1,
                                                tipo_de_mapa: 'Distribuído',
                                                checkbox_visivel,
                                                setInfo_elemento,
                                                profundidade_atual
                                            })}
                                        />

                                        <TextArea 
                                            label='Observação do elemento'
                                            placeholder='Digite aqui a observação que será apresentada no book'
                                            value={substituirAsc(obs_atual)}
                                            onChange={setObs_atual}
                                            height={120}
                                            resize={false}
                                            maxLength={350}
                                            disabled={!swt_visivel_no_book || !checkbox_visivel}
                                            onKeyUp={()=>alterarObservacaoProfundidade({
                                                info_elemento,
                                                lista_params_profundidade,
                                                tipo_mapa_atual: 1,
                                                tipo_de_mapa: 'Distribuído',
                                                obs_atual,
                                                setInfo_elemento,
                                                profundidade_atual
                                            })}
                                        />

                                        <div className='lenght__text'>
                                            <p className='number__total'>{obs_atual?.length} / <span className='lenght__text__bold'>350</span></p>
                                        </div>

                                    </CampoObs>
                                ),
                            }
                        })}
                        
                    />
                </CampoTabs>
            )}
            

            <Footer style={{position: 'absolute', bottom: 0, left: 0}}>
                <Button 
                    type="primary" 
                    icon={<FontAwesomeIcon icon={faFloppyDisk} />} 
                    disabled={JSON.stringify(info_elemento) === JSON.stringify(info_original)}
                    onClick={()=>{salvarAlteracao()}}
                    loading={isFetching}
                >
                    Salvar
                </Button>
            </Footer>
        </Drawer>
    )
}

export default GavetaElementos;
