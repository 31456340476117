import styled from "styled-components";
import { Tooltip } from "antd";
import { paleta_cores } from "../../meta";
import { TalhaoCultivaveisItens } from "../../../../../services/TalhaoSafra";

//STYLES
const TabelaProp = styled.div`
    background: white;
    z-index: 2;
    border-radius: 8px;
    box-sizing: border-box;

    div.TabelaProp__header {
        display: flex;
        height: 25px;

        div.TabelaProp__header__item {
            width: 33%;
            height: 100%;
            display: flex;
            align-items: center;
            font-weight: 600;
            font-size: 13px;
            color: var(--text-color);
        }
        div.TabelaProp__header__item.talhao_nome {
                width: 80%;
        }
        div.TabelaProp__header__item.area_talhao {
            width: 20%;
        }
    }

    div.TabelaProp__container {
        width: 100%;
        max-height: calc(100vh - 445px);
        overflow-y: auto;

        div.TabelaProp__container__row {
            width: 100%;
            height: 35px;
            display: flex;
            font-weight: 500;
            font-size: 12px;
            color: var(--text-color);

            .TabelaProp__container__row__item {
                height: 100%;
                align-items: center;
                box-sizing: border-box;
                padding: 10px 5px;
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: flex;
                gap: 3px;

                .TabelaProp__container__row__item__color {
                    width: 10px;
                    height: 10px;
                    border-radius: 50%;
                }
            }
            /* :hover {
                background-color: var(--hover-color);
            } */
            .TabelaProp__container__row__item.talhao_nome {
                width: 80%;
                text-transform: capitalize;
                padding-left: 0px;
            }
            .TabelaProp__container__row__item.area_talhao {
                width: 20%;
                padding-left: 0px;
            }
            .TabelaProp__container__row__item.area_talhao:is([data-padding = true]) {
                padding-left: 3px;
            }
        }
    }
`;

//PROPS
interface Props {
    dados_propriedades: TalhaoCultivaveisItens[],
    distinguir_cor?: boolean;
}

export const TabelaPropiedades: React.FC<Props> = ({ dados_propriedades, distinguir_cor }) => {
    return (
        <TabelaProp>
            <div className="TabelaProp__header">
                <div className="TabelaProp__header__item talhao_nome">
                    Talhão
                </div>
                <div className="TabelaProp__header__item area_talhao">
                    Área
                </div>
            </div>
            <div className="TabelaProp__container">
                {(dados_propriedades ?? []).map(({ nome, area, cod }, index)=> {
                    return (
                        <div className="TabelaProp__container__row" key={cod}>

                            <Tooltip title={nome} mouseEnterDelay={1}>
                                <div className="TabelaProp__container__row__item talhao_nome" data-padding={dados_propriedades?.length > 11}>
                                    {distinguir_cor &&(
                                        <div className="TabelaProp__container__row__item__color" style={{background: paleta_cores?.[index]}}/>
                                    )}
                                    {String(nome)?.toLowerCase()}
                                </div>
                            </Tooltip>

                            <Tooltip title={`${area} ha`} mouseEnterDelay={1}>
                                <div className="TabelaProp__container__row__item area_talhao" data-padding={dados_propriedades?.length > 11}>
                                {area} ha
                                </div>
                            </Tooltip>
                                    
                        </div>
                    )
                })}
            </div>
        </TabelaProp>
    )
}