import styled from 'styled-components'

export const ConfigBook = styled.section`
    width: 100%;
    height: calc(100% - 10px);
    box-sizing: border-box;
    padding: 5px;

    h1 {
        font-weight: 700;
        font-size: 18px;
        color: var(--text-color);
    }

`

export const Conteudo = styled.section`
    width: 100%;
    height: calc(100% - 90px);
    border: thin solid var(--border-color);
    border-radius: 10px;
    box-sizing: border-box;
    padding: 15px;
    display: flex;
    flex-direction: column;
    gap: 5px;

    h2 {
        font-weight: 700;
        font-size: 16px;
        color: var(--text-color);
    }

    div.Conteudo__filtros {
        width: 100%;
        display: flex;
        gap: 10px;
    } 

    div.Conteudo__tabela {
        width: 100%;
        height: calc(100% - 150px);
        border: thin solid var(--border-color);
        border-radius: 5px;
        overflow: hidden;
    }

    div.Conteudo__tabela__header {
        width: 100%;
        height: 45px;
        background-color: #fafafa;
        display: flex;
    }
    div.Conteudo__tabela__header__item {
        width: 150px;
        height: 100%;
        display: flex;
        align-items: center;
        padding-left: 10px;
        font-weight: 500;
        font-size: 14px;
        border-left: thin solid #ebebeb;
    }
    div.Conteudo__tabela__header__item:first-child {
        border: none;
    }

    div.Conteudo__tabela__body {
        width: 100%;
        height: calc(100% - 45px);
        overflow-y: auto;
        overflow-x: hidden;
    }
    div.Conteudo__tabela__body__row {
        width: 100.4%;
        height: 56px;
        display: flex;
        align-items: center;
        border-bottom: thin solid var(--border-color);
        background-color: #fff;
        cursor: move;
    }
    div.Conteudo__tabela__body__row:hover {
        background-color: var(--hover-color);
    }

    /* div.Conteudo__tabela__body__row:active {
        background-color: var(--primary-color);
        color: #fff;
        cursor: grabbing;
    }
    div.Conteudo__tabela__body__row.non-active:active {
        background-color: #fff;
        color: var(--text-color);
        cursor: normal;
    } */
    div.Conteudo__tabela__body__row__item {
        width: 80px;
        height: 100%;
        display: flex;
        align-items: center;
        padding: 0 10px;
        border-left: thin solid #ebebeb;
        font-weight: 400;
        font-size: 14px;
    }
    div.Conteudo__tabela__body__row__item:is([data-visivel = false]) {
        /* text-decoration: line-through; */
        color: var(--text-color-light);
    }
    div.Conteudo__tabela__body__row__item:first-child {
        border: none;
    }
    div.Conteudo__tabela__body__row__item.ordem {
        width: 80px;
        min-width: 80px;
        gap: 7px;
        position: relative;
    }
    div.Conteudo__tabela__body__row__item.ordem svg {
        color: var(--primary-color);
        font-size: 16px;
    }
    div.nova_ordem {
        position: absolute;
        right: 5px;
        height: 15px;
        display: flex;
        gap: 3px;
        font-size: 11px;
    }
    div.nova_ordem svg {
        width: 8px;
    }
    div.nova_ordem:is([data-change = 'subiu']),
    div.nova_ordem:is([data-change = 'subiu']) svg {
        color: #42B92F;
    }
    div.nova_ordem:is([data-change = 'desceu']),
    div.nova_ordem:is([data-change = 'desceu']) svg {
        color: red;
    }
    div.nova_ordem:is([data-change = 'desceu']) svg {
        transform: rotate(180deg);
    }
    div.Conteudo__tabela__body__row__item.elemento {
        width: 35%;
        min-width: 35%;
    }
    div.Conteudo__tabela__body__row__item.elemento:is([data-tab = 'Fertilidade']):is([data-encalso = true]) {
        width: 34%;
        min-width: 34.85%;
    }
    div.Conteudo__tabela__body__row__item.elemento:is([data-tab = 'Prescrições']) {
        width: 83.3%;
        min-width: 83.3%;
    }
    div.Conteudo__tabela__body__row__item.elemento:is([data-tab = 'Prescrições']):is([data-encalso = true]) {
        width: 82.95%;
        min-width: 82.95%;
    }
    div.Conteudo__tabela__body__row__item.elemento:is([data-tab = 'Indicadores']) {
        width: 63.3%;
        min-width: 63.3%;
        opacity: 1;
    }
    div.Conteudo__tabela__body__row__item.mapa {
        width: 20%;
        min-width: 20%;
    }
    div.Conteudo__tabela__body__row__item.mapa:is([data-tab = 'Fertilidade']):is([data-encalso = true]) {
        width: 19.9%;
        min-width: 19.9%;
    }
    div.Conteudo__tabela__body__row__item.mapa:is([data-tab = 'Prescrições']) {
        display: none;
    }
    div.Conteudo__tabela__body__row__item.profundidade {
        width: calc(35% - 80px);
        min-width: calc(35% - 80px);
    }
    div.Conteudo__tabela__body__row__item.profundidade:is([data-tab = 'Fertilidade']):is([data-encalso = true]) {
        width: calc(35% - 81px);
        min-width: calc(35% - 81px);
    }
    div.Conteudo__tabela__body__row__item.profundidade:is([data-tab = 'Indicadores']),
    div.Conteudo__tabela__body__row__item.profundidade:is([data-tab = 'Prescrições']) {
        display: none;
    }
    div.Conteudo__tabela__body__row__item.editar {
        width: 9.4%;
        min-width: 9.4%;
    }

    .ant-drawer .ant-drawer-body{
      padding: 0px !important;
    }

    div.Conteudo__footer {
        width: 100%;
        height: 50px;
        display: flex;
        align-items: flex-end;
    }
`


