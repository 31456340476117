import React, { useEffect, useState } from 'react'

import { GoogleMap, Marker, Polyline } from '@react-google-maps/api'

import { faMapMarker } from '@fortawesome/free-solid-svg-icons'
import { getBoundsNoArray } from '../../services/Afins'

export interface TypesGoogleMaps {
  open: boolean
  geoLocation?: any
  talhaoSelecionado?: any
  pontosSelecionados?: any
  grupoDeAnalises?: any
}

const visualizarPontosMap: React.FC<TypesGoogleMaps> = (props) => {
  const { geoLocation, talhaoSelecionado, pontosSelecionados, grupoDeAnalises, open } = props

  const [geoJson, setGeoJson] = useState<any>([])
  const [map, setMap] = useState<google.maps.Map>()

  const [listLinha, setListLinha] = useState<google.maps.LatLngLiteral[]>([])
  const linha = () => {
    const polyline: google.maps.LatLngLiteral[] = (talhaoSelecionado.coordenadas).map((rec) => {
      return {
        lat: Number(rec.latitude),
        lng: Number(rec.longitude),
      }
    })

    setListLinha(polyline)
  }
  useEffect(() => {
    if (geoLocation?.features) {
      linha()
      const dadosGeo = geoLocation

      dadosGeo.features.forEach((data) => {
        data.color = '#ffffff'
      })
      if (pontosSelecionados.cod_analises) {
        const codAnalise =
          pontosSelecionados.cod_analises.cod === undefined
            ? pontosSelecionados.cod_analises[0].cod
            : pontosSelecionados.cod_analises.cod

        const analise = grupoDeAnalises.find((data) => data.cod_analise === codAnalise)

        dadosGeo.features.forEach((data) => {
          const check = pontosSelecionados.pontos.find(
            (info) => parseInt(info.NOME, 10) === parseInt(data.properties.NOME, 10)
          )

          if (check) {
            data.color = analise.cor
          }
        })
        setGeoJson(geoLocation)
      }
    }
  }, [geoLocation, pontosSelecionados])
  useEffect(() => {
    if (map) {
      const bounds = getBoundsNoArray(listLinha)
      if (!map.getBounds()?.intersects(bounds)) {
        map.fitBounds(bounds)
      }
    }
  }, [listLinha])

  return (
    <>
      {/* @ts-ignore */}
      <GoogleMap
        onLoad={setMap}
        options={{
          mapTypeId: 'satellite',
          mapTypeControl: false,
          streetViewControl: false,
          clickableIcons: true,
        }}
        mapContainerStyle={{
          width: '100%',
          height: '81vh',
        }}
      >
        {geoJson?.features?.map((data) => {
          return (
            <>
              {/* @ts-ignore */}
              <Marker
                icon={{
                  path: faMapMarker.icon[4] as string,
                  anchor: new window.google.maps.Point(190, 550),
                  fillColor: data.color,
                  fillOpacity: 1,
                  strokeWeight: 1,
                  strokeColor: '#ffffff',
                  scale: 0.055,
                  labelOrigin: new window.google.maps.Point(190, 220),
                }}
                animation={window.google.maps.Animation.DROP}
                options={{
                  clickable: false,
                  draggable: false,
                }}
                label={{
                  className: 'color-marker-label',
                  text: parseInt(data.properties.NOME, 10).toString(),
                  fontSize: '11px',
                  fontWeight: 'bold',
                }}
                key={data.properties.INDICE}
                position={{
                  lat: parseFloat(data.geometry.coordinates[1]),
                  lng: parseFloat(data.geometry.coordinates[0]),
                }}
              />
            </>
          )
        })}
        <Polyline
          options={{
            path: listLinha,
            geodesic: true,
            strokeColor: '#00f75b',
            strokeOpacity: 1.0,
            strokeWeight: 4,
            zIndex: 0,
          }}
        />
      </GoogleMap>
    </>
  )
}

export default visualizarPontosMap
