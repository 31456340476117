import { api } from '../api'

export interface TypeCategoriaProdutos {
  itens: [CategoriaProdutos]
}

export interface CategoriaProdutos {
  cod?: number
  nome?: string
  cod_grupo?: number
  grupo_categoria_produto_nome?: string
}

interface TypeQuery {
  nome?: string
  cod?: number
  codGrupo?: number
  pagination?: number | -1
}

const url = '/categoria-produto'

export const getCategoriaProdutos = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    ...params,
    pagination,
  }

  const response = api.get(url, {
    params,
  })

  return response
}


export const getCategoriaInsumo = async (params: TypeQuery) => {
  const { pagination = -1 } = params

  params = {
    ...params,
    pagination,
  }

  const response = api.get('/classe-agronomica', {
    params,
  })

  return response
}

export const postCategoriaProdutos = async (data) => {
  const response = api.post(url, data)

  return response
}

export const putCategoriaProdutos = async (cod, data) => {
  const response = api.put(`${url}/${cod}`, data)

  return response
}

export const delCategoriaProdutos = async (cod) => {
  const response = api.delete(`${url}/${cod}`)

  return response
}
