import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetTalhaoSafraGeral {
  itens: [TalhaoSafraGeralItens]
  links: {
    anterior: string
    atual: string
    proximo: string
  }
  mensagem: string
  pagina: number
  quantidadePorPagina: number
  timestamp: number
  total: number
}

export interface TalhaoSafraGeralItens {
  cod?: number
  select?: boolean
  cod_conta?: number
  cod_propriedade?: number
  cod_talhao?: number
  isSubArea?: boolean
  area?: number
  arquivo?: string
  nome?: string
  /**
   * @param {status} 1 = Ativo | 0 = Desativado
   */
  status?: 1 | 0
  length?: number
  padrao_pontos?: {
    cod: number
    cod_talhao: number
    latitude: string
    longitude: string
    nome: string
  }[]
  kml?: {
    nome?: string
    lookAt?: {
      latitude?: string
      longitude?: string

      lat?: any
      lng?: any
    }
    coordenadas?: [
      {
        latitude?: any
        longitude?: any

        lat?: any
        lng?: any
      }
    ]
  }
  sub_area?: any[]
}

interface TypesGetTalhaoSafraGeral {
  codPropriedade?: number
  cod?: number
  embed?: string
  pagination?: number
  page?: number
  codUsuarioPropriedade?: number
  dataVigenciaSafra?: string
  codCultura?: number
  codCliente?: number
}

const url = '/talhao-safra/geral'

export const getTalhaoSafraGeral = async (params: TypesGetTalhaoSafraGeral) => {
  const config = localConfig()

  const { pagination = -1 } = params

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetTalhaoSafraGeral> = await api.get(url, {
    params: {
      codConta: config.conta,
      pagination,
      ...params,
    },
  })

  return response
}

