import React, { useEffect, useState } from 'react'
import { Button, Drawer, Form, Input, message, Select, Space } from 'antd'

import { AxiosResponse } from 'axios'
import { tiposSistema } from '../../../services/Afins'
import { Notification } from '../../notification'
import {
  getDadosQuimicos,
  postDadosQuimicos,
  putDadosQuimicos,
  TypeDadosQuimicos,
} from '../../../services/recomendacao/dadosQuimicos'

interface TypesDadosQuimicos {
  visible: boolean
  update: number
  onClose: (data: boolean) => void
}

const FormDadosQuimicos: React.FC<TypesDadosQuimicos> = (props) => {
  const { visible, update, onClose } = props

  const [form] = Form.useForm()

  const [dataDadosQuimicos, setDataDadosQuimicos] = useState([])
  const [visibleDrawer, setVisibleDrawer] = useState(false)

  const listDadosQuimicos = async () => {
    const resp = await tiposSistema(5)

    setDataDadosQuimicos(resp)
  }

  const editAtivo = async (cod) => {
    message.loading('Carregando', 99999)
    const resp: AxiosResponse<TypeDadosQuimicos> = await getDadosQuimicos({
      cod,
    })

    form.setFieldsValue(resp.data.itens[0])
    message.destroy()
  }

  const hancleDrawerClose = () => {
    onClose(false)
    form.resetFields()
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...', 99999)
      if (update <= 0) {
        const resp = await postDadosQuimicos(data)

        if (resp.status === 201) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Ativo salvo com sucesso',
          })
          onClose(true)
          form.resetFields()
          message.destroy()
        }
      } else {
        const resp = await putDadosQuimicos(update, data)

        if (resp.status === 200) {
          Notification({
            type: 'success',
            message: 'Sucesso',
            descricao: 'Ativo atualizado com sucesso',
          })
          message.destroy()
          onClose(true)
        }
      }
    } catch (error) {
      message.destroy()
      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  useEffect(() => {
    setVisibleDrawer(visible)
  }, [visible])

  useEffect(() => {
    if (update > 0) {
      editAtivo(update)
    }
  }, [update])

  useEffect(() => {
    listDadosQuimicos()
  }, [])

  return (
    <Form form={form} onFinish={onFinish} layout='vertical'>
      <Drawer
        placement='left'
        style={{ position: 'absolute' }}
        getContainer={false}
        open={visibleDrawer}
        closeIcon={false}
        onClose={() => hancleDrawerClose()}
        footer={
          <Space>
            <Button type='primary' htmlType='submit'>
              Salvar
            </Button>
            <Button type='primary' danger onClick={() => hancleDrawerClose()}>
              Fechar
            </Button>
          </Space>
        }
      >
        <Form.Item label='Nome' name='nome'>
          <Input />
        </Form.Item>
        <Form.Item label='Tipo' name='cod_tipo'>
          <Select
            showSearch
            filterOption={(input, option) =>
              String(option.children).toLowerCase().indexOf(input.toLowerCase()) >= 0
            }
          >
            {dataDadosQuimicos.map((data) => (
              <Select.Option key={data.cod} value={data.cod}>
                {data.nome}
              </Select.Option>
            ))}
          </Select>
        </Form.Item>
      </Drawer>
    </Form>
  )
}

export default FormDadosQuimicos
