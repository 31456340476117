
//STYLES
import { gerarKey } from "../../../utils"
import { ContainerBoxes } from "./style"

//PROPS
interface Props {
    items: {title: string, value: number|string, width?: number}[];
    gap?: number;
}

export const InfoBox: React.FC<Props> = ({ items, gap }) => {

    return (
        <ContainerBoxes style={{gap: gap}}>
            {items.map(({ title, value }, index)=> {
                return (
                    <div className="ContainerBoxes__box" key={gerarKey(index)}>
                        <p className="ContainerBoxes__box__title">{title}</p>
                        <span className="ContainerBoxes__box__value">{value}</span>
                    </div>
                )
            })}
        </ContainerBoxes>
    )
}