import React from 'react'
import { Redirect, Route, Switch } from 'react-router-dom'
import AdminLayout from '../Components/admin-layout'
import AppLayout from '../Components/app-layout'
import AuthLayout from '../Components/auth-layout'
import { ADMIN_PREFIX_PATH, APP_PREFIX_PATH, AUTHLAB_PREFIX_PATH, AUTH_PREFIX_PATH } from '../Configs/AppConfig'
import BookFertilidade from './bookFertilidade'
import BookMonitoramento from './bookMonitoramento'

const Views: React.FC = () => {
  // const tokenDAta = dataAccess ? dataAccess.itens[0].hash : false;

  // useEffect(() => {
  //   const checkToken = async (token) => {
  //     const validade = await ChekToken(token);
  //     if (validade) {
  //       const url = history.location.pathname;
  //       history.push(url);
  //     }
  //   };

  //   checkToken(tokenDAta);
  // }, []);

  return (
    <>
      <Switch>
        <Route path='/book-fertilidade/:token?/:codOs?' component={BookFertilidade} />
        <Route path='/book-monitoramento/:token?/:codOs?' component={BookMonitoramento} />
        <Route path={AUTH_PREFIX_PATH} component={AuthLayout} />
        <Route path={AUTHLAB_PREFIX_PATH} component={AuthLayout} />
        <Route path={ADMIN_PREFIX_PATH} component={AdminLayout} />
        <Route path={APP_PREFIX_PATH} component={AppLayout} />

        <Route exact path='/'>
          <Redirect to={AUTH_PREFIX_PATH} />
        </Route>
      </Switch>
    </>
  )
}

export default Views
