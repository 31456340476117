type getWidthTableRow = {
    cod: number | string,
    data: number | string,
    safra: number | string,
    propriedade: number | string,
    talhao: number | string,
    acoes: number | string
}

export function getWidthTableRow(currentWidth: number, hasScroll: boolean): getWidthTableRow {
   
    // console.log("currentWidth:", currentWidth)

    if (hasScroll) {
        if (currentWidth > 1900) {
            return {
                cod: 60,
                data: '10%',
                safra: '20.1%',
                propriedade: 'calc(35.1% - 60px)',
                talhao: '26.5%',
                acoes: '8%',
            }
        }
    
        return {
            cod: 60,
            data: '10.05%',
            safra: '20.1%',
            propriedade: 'calc(35.1% - 60px)',
            talhao: '26.6%',
            acoes: '8%',
        }
    }

    if (currentWidth > 1900) {
        return {
            cod: 60,
            data: '9.93%',
            safra: '20.05%',
            propriedade: 'calc(35% - 60px)',
            talhao: '26.45%',
            acoes: '8.5%',
        }
    }

    return {
        cod: 60,
        data: '10%',
        safra: '20%',
        propriedade: 'calc(35% - 60px)',
        talhao: '26.45%',
        acoes: '8.5%',
    }
}