import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { Button, Popconfirm, Popover, Space, Tag, Tooltip, Upload } from 'antd'
import Icons from '../../../../Components/Icons'
import { IconAlert } from '../../../../Components/iconsAlert'
import { localConfig } from '../../../../Configs/localConfig'
import ExclamationError from '../../../../assets/img/exclamation-error.png'
import Exclamation from '../../../../assets/img/exclamation.png'
import { filterTable } from '../../../../services/Afins'

export const useLogic = () => {
  const columLabOrder = ({
    dataSafraConsultoria,
    dataOrderSer,
    handleClikAlert,
    handleStatusOsColeta,
    handleClickUpdate,
    permissions,
    handleCloneData,
    handleDownloadResultado,
    handleUploadResult,
    dumpRequest,
    handleTrashOrderSer,
    handleReprocessarOs,
  }) => {
    return [
      {
        title: <div style={{ minWidth: '50px' }}>Cod</div>,
        dataIndex: 'cod',
        width: '4rem',
        // @ts-ignore
        hidden: process.env.NODE_ENV === 'production',
      },
      {
        title: <div style={{ minWidth: '50px' }}>Data</div>,
        key: 1,
        dataIndex: 'data',
        width: '150px',
        render: (data) => (
          <>
            {data?.substr(0, 10).split('-').reverse().join('/')}
            {data?.substr(10, 18)}
          </>
        ),
        sorter: {
          compare: (a, b) => a.status - b.status,
          multiple: 1,
        },
      },
      {
        title: <div style={{ minWidth: '100px' }}>Cliente</div>,
        dataIndex: 'conta',
        className: localConfig().codCliente !== '' ? 'hideColum' : null,
        render: (data, dados) => (
          <>
            {dados?.cod_cliente !== null ? (
              <Tooltip
                placement='right'
                title={dados?.cliente_nome.length > 30 ? dados?.cliente_nome : null}
              >
                <div
                  style={{
                    maxWidth: '30ch',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {dados?.cliente_nome}
                </div>
              </Tooltip>
            ) : (
              <Tooltip placement='right' title={data?.[0]?.nome > 30 ? data?.[0]?.nome : null}>
                <div
                  style={{
                    maxWidth: '30ch',
                    overflow: 'hidden',
                    textOverflow: 'ellipsis',
                    whiteSpace: 'nowrap',
                  }}
                >
                  {data?.[0]?.nome}
                </div>
              </Tooltip>
            )}
          </>
        ),
      },
      {
        title: <div style={{ minWidth: '50px' }}>Safra consultoria</div>,
        dataIndex: 'cod_safra_conta',
        width: 'auto',
        render: (data) => (
          <Tooltip placement='right' title={data}>
            <div
              style={{
                maxWidth: '30ch',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {dataSafraConsultoria.find((rec) => rec.cod === data)?.nome}
            </div>
          </Tooltip>
        ),
      },
      {
        title: <div style={{ minWidth: '50px' }}>Propriedade</div>,
        width: 'auto',
        ...filterTable(false, true, 'propriedade_nome', dataOrderSer),
        dataIndex: 'propriedade_nome',
        render: (data) => (
          <Tooltip placement='right' title={data.length > 30 ? data : null}>
            <div
              style={{
                maxWidth: '30ch',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {data}
            </div>
          </Tooltip>
        ),
      },
      {
        title: <div style={{ minWidth: '50px' }}>Talhão</div>,
        dataIndex: 'talhao_nome',
        render: (data) => (
          <Tooltip placement='right' title={data}>
            <div
              style={{
                maxWidth: '30ch',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {data}
            </div>
          </Tooltip>
        ),
      },
      {
        title: <div style={{ minWidth: '150px' }}>Laboratório</div>,
        dataIndex: 'laboratorio_nome',
        render: (data) => (
          <Tooltip placement='right' title={data}>
            <div
              style={{
                maxWidth: '30ch',
                overflow: 'hidden',
                textOverflow: 'ellipsis',
                whiteSpace: 'nowrap',
              }}
            >
              {data}
            </div>
          </Tooltip>
        ),
      },
      {
        title: <div style={{ minWidth: '50px' }}>Status</div>,
        dataIndex: 'status',
        filters: [
          { text: 'ABERTA', value: 1 },
          { text: 'LIBERADO PARA COLETA', value: 2 },
          { text: 'COLETADA', value: 3 },
          { text: 'ENVIADO PARA ANÁLISE', value: 4 },
          { text: 'EM ANÁLISE', value: 5 },
          { text: 'INCONSISTÊNCIA MODERADA', value: 6 },
          { text: 'INCONSISTÊNCIA ALTA', value: 7 },
          { text: 'RESULTADO COM ERRO', value: 8 },
          { text: 'ANALISADA', value: 9 },
        ],
        sorter: {
          compare: (a, b) => a.status - b.status,
          multiple: 1,
        },
        render: (data, dados) => {
          let status
          let tag
          switch (data) {
            case 1:
              status = 'ABERTA'
              tag = 'cyan'
              break
            case 2:
              status = 'LIBERADO PARA COLETA'
              tag = 'gold'
              break
            case 3:
              status = 'COLETADA'
              tag = 'geekblue'
              break
            case 4:
              status = 'ENVIADO PARA ANÁLISE'
              tag = 'purple'
              break
            case 5:
              status = 'EM ANÁLISE'
              tag = 'processing'
              break
            case 6:
              status = 'INCONSISTÊNCIA MODERADA'
              tag = 'warning'
              break
            case 7:
              status = 'INCONSISTÊNCIA ALTA'
              tag = 'volcano'
              break
            case 8:
              status = 'RESULTADO COM ERRO'
              tag = 'error'
              break
            case 9:
              status = 'ANALISADA'
              tag = 'green'
              break

            default:
          }
          return (
            <>
              {data === 6 || data === 7 || data === 8 || dados.resultado_inconsistente === 1 ? (
                <Tag
                  onClick={() => handleClikAlert(dados)}
                  style={{ cursor: 'pointer', border: 'none' }}
                >
                  <img
                    width='19px'
                    src={data === 8 ? ExclamationError : Exclamation}
                    alt='Warning'
                  />
                </Tag>
              ) : null}
              {'   '}
              <Tag
                onClick={() => handleStatusOsColeta(dados.cod)}
                style={{ cursor: 'pointer' }}
                color={tag}
              >
                {status}
              </Tag>
            </>
          )
        },
      },
      {
        title: <div style={{ minWidth: '50px' }}>Ações</div>,
        dataIndex: 'cod',
        key: 'acao',
        render: (data, dados) => {
          let disable
          if (dados.status === 4 || dados.status === 8) {
            disable = false
          } else {
            disable = true
          }
          // console.log('dados', dados)
          const codCalcario = dados.calcario_propriedade
        
          return (
            <Space>
              <Button
                shape='circle'
                onClick={() => handleClickUpdate(data, dados)}
                hidden={permissions?.acesso === 0 && permissions?.alteracao === 0}
                icon={<Icons icon={permissions?.acesso === 1 ? solid('eye') : solid('pen')} />}
                size='middle'
              />

              <Popconfirm
                onConfirm={() => handleCloneData(data, dados)}
                icon={<IconAlert type='confirm' size={4} />}
                cancelText='Não'
                okText='Sim'
                cancelButtonProps={{
                  type: 'primary',
                  danger: true,
                }}
                title='Deseja clonar essa ordem de serviço?'
              >
                <Button
                  hidden={permissions?.inclusao === 0}
                  shape='circle'
                  title='Duplicar OS'
                  icon={<Icons icon={solid('copy')} />}
                  size='middle'
                />
              </Popconfirm>

              {dados.status === 9 || dados.status === 6 || dados.status === 7 ? (
                dados.status === 9 ? (
                  <Popover
                    trigger='click'
                    content={() => (
                      <Space style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          onClick={() => handleDownloadResultado(dados.arquivo_processado_url)}
                          type='primary'
                          title='Resultado Análise'
                          size='middle'
                        >
                          <p style={{ fontSize: '13px' }}>
                            <Icons icon={solid('download')} /> Download análise
                          </p>
                        </Button>
                        <Button
                          onClick={() =>
                            handleReprocessarOs(
                              dados.cod,
                              dados.cod_cliente,
                              dados.propriedade_nome,
                              dados.talhao_nome,
                              dados.cod_calcario,
                              dados.cod_calcario_propriedade
                            )
                          }
                          type='default'
                          style={{ backgroundColor: 'red', border: 'none', width: '160px' }}
                          size='middle'
                        >
                          <p style={{ fontSize: '13px' }}>
                            <Icons icon={solid('rotate-back')} /> Reprocessar OS
                          </p>
                        </Button>
                      </Space>
                    )}
                  >
                    <Button
                      shape='circle'
                      type='primary'
                      title='Resultado Análise'
                      icon={<Icons icon={solid('download')} />}
                      size='middle'
                    />
                  </Popover>
                ) : (
                  <Popover
                    trigger='click'
                    content={() => (
                      <Space style={{ display: 'flex', flexDirection: 'column' }}>
                        <Button
                          onClick={() => handleDownloadResultado(dados.arquivo_processado_url)}
                          type='primary'
                          size='middle'
                        >
                          <p style={{ fontSize: '13px' }}>
                            <Icons icon={solid('download')} /> Baixar resultado
                          </p>
                        </Button>

                        {codCalcario === null ? (
                          <button className='ant-btn-warning' title='Enviar Resultado Análise'>
                            <Icons icon={solid('warning')} /> Informar calcário
                          </button>
                        ) : (
                          <Upload
                            accept='.csv'
                            onChange={(file) => handleUploadResult(file, data)}
                            customRequest={dumpRequest}
                            showUploadList={false}
                          >
                            <Button type='default' title='Enviar Resultado Análise' size='middle'>
                              <p style={{ fontSize: '13px' }}>
                                <Icons icon={solid('upload')} /> Enviar resultado
                              </p>
                            </Button>
                          </Upload>
                        )}

                        {/* {dados.arquivo_processado ? null : (
                          <Button
                            onClick={() => handleReprocessarOs(dados.arquivo_processado_url)}
                            type='default'
                            style={{ backgroundColor: 'red', border: 'none', width: '100%' }}
                            size='small'
                          >
                            <p>
                              <Icons icon={solid('rotate-back')} /> Reprocessar OS
                            </p>
                          </Button>
                        )} */}
                        {dados.arquivo_processado === '' ? (
                          <Button
                            onClick={() => handleReprocessarOs(dados.cod)}
                            type='default'
                            style={{ backgroundColor: 'red', border: 'none', width: '100%' }}
                            size='middle'
                          >
                            <p style={{ fontSize: '13px' }}>
                              <Icons icon={solid('rotate-back')} /> Reprocessar OS
                            </p>
                          </Button>
                        ) : null}
                      </Space>
                    )}
                  >
                    <Button
                      shape='circle'
                      type='default'
                      title='Enviar ou baixar'
                      icon={<Icons icon={solid('arrow-right-arrow-left')} rotation={90} />}
                      size='middle'
                    />
                  </Popover>
                )
              ) : codCalcario === null ? (
                <Tooltip
                  placement='top'
                  title='Informar calcário no cadastro da propriedade'
                  trigger='hover'
                >
                  <button className='ant-btn-warning ant-shape-circle'>
                    <Icons icon={solid('warning')} />
                  </button>
                </Tooltip>
              ) : (
                <Upload
                  accept='.csv'
                  onChange={(file) => handleUploadResult(file, data)}
                  customRequest={dumpRequest}
                  showUploadList={false}
                >
                  <Button
                    hidden={permissions?.alteracao === 0}
                    disabled={disable}
                    shape='circle'
                    type='default'
                    title='Enviar Resultado Análise'
                    icon={<Icons icon={solid('upload')} />}
                    size='middle'
                  />
                </Upload>
              )}

              <Button
                shape='circle'
                hidden={permissions?.exclusao === 0}
                onClick={() => handleTrashOrderSer(data, dados)}
                className='trash-button'
                type='primary'
                icon={<Icons icon={solid('trash')} />}
                size='middle'
              />
            </Space>
          )
        },
      },
    ].filter((item) => !item.hidden)
  }

  return { columLabOrder }
}
