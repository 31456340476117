import { EstadiosDaCultura, ItemAtivoCadastrado } from "../../../../../../../../../services/recomendacao/ativos/novo-index";

export function obterTipoPorId(origem_lancamento: number) {
    let origem_nome = {
        1: 'Ativos',
        2: 'Insumos (produto comercial)',
        3: 'Grupo Químico',
        4: 'Classe Agronômica',
        5: 'Formulação',
        6: 'Cultura',
        7: 'Estádio',
        8: 'Biotecnologia',
        9: 'Grupo'
    };

    return origem_nome?.[origem_lancamento] ?? 'inválido';
}

/**Retorna o `valor` do tipo selecionado*/
export function obterValor(registros: ItemAtivoCadastrado) {
    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return registros?.ingrediente_ativo_nome;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return registros?.insumo_nome

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return registros?.grupo_quimico_nome;

    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return registros?.classe_agronomica_nome;

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return registros?.formulacao_nome;

    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return registros?.cultura_nome

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return registros?.tecnologia_nome

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return registros?.grupo_dado_quimico_nome

}

/**Retorna o `id` do tipo (insumo/ingrediente ativo/grupo quimico/cultura/tecnologia) */
export function obterIdTipo(registros: ItemAtivoCadastrado) {

    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return 1;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return 2;

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return 3;
    
    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return 4;

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return 5;

    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return 6

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return 8;

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return 9;
}

/**Retorna o `id` da compatibilidade */
export function obterIdCompatibilidade(registros: ItemAtivoCadastrado) {
    if(registros?.ingrediente_ativo_nome !== null && registros?.ingrediente_ativo_nome !== undefined) 
        return registros?.cod_ingrediente_ativo;

    if(registros?.insumo_nome !== null && registros?.insumo_nome !== undefined) 
        return registros?.cod_insumo

    if(registros?.grupo_quimico_nome !== null && registros?.grupo_quimico_nome !== undefined) 
        return registros?.cod_grupo_quimico

    if(registros?.classe_agronomica_nome !== null && registros?.classe_agronomica_nome !== undefined) 
        return registros?.cod_classe_agronomica

    if(registros?.formulacao_nome !== null && registros?.formulacao_nome !== undefined) 
        return registros?.cod_formulacao
    
    if(registros?.cultura_nome !== null && registros?.cultura_nome !== undefined) 
        return registros?.cod_cultura

    if(registros?.tecnologia_nome !== null && registros?.tecnologia_nome !== undefined) 
        return registros?.cod_tecnologia

    if(registros?.grupo_dado_quimico_nome !== null && registros?.grupo_dado_quimico_nome !== undefined) 
        return registros?.cod_grupo

}

/**Desabilita o botão de adicionar caso nenhum estádio seja selecionado ou caso todos os estádios já tenham sido adicionados */
export function disabilitarBtnAdd(estadios_selecionados: {descr: string, id: number, key?: number}[], data_estadios: EstadiosDaCultura): boolean {
    if (estadios_selecionados?.length === (data_estadios?.itens)?.length) {
        return true;
    }
    return estadios_selecionados?.length === 0;
}

/**Desabilita o `Textarea` caso nenhum estádio seja selecionado ou caso todos os estádios já tenham sido adicionados
 * 
 * Habilita o `Textarea` caso o tipo seja diferente de `cultura` ou caso o Switch `todos_estadios` seja `true`
 */
export function disabilitarTextArea(id_tipo_selecionado:number|null, todos_estadios: boolean, 
    estadios_selecionados:{descr: string, id: number, key?: number}[], data_estadios: EstadiosDaCultura) {

    if (id_tipo_selecionado !== 6) {
        return false;
    }
    if (todos_estadios) {
        return false;
    }
    if (estadios_selecionados?.length === (data_estadios?.itens)?.length) {
        return true;
    }
    return estadios_selecionados?.length === 0;
}

/**Filtrar listagem baseado no valor digitado no campo buscar*/
export function buscarCadastro(buscarItem: string) {
    return function(item) {              
        return String(item.insumo_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.grupo_quimico_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.tecnologia_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.classe_agronomica_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.formulacao_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.grupo?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.ingrediente_ativo_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.cultura_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        String(item.item_lancamento_nome?.toLowerCase())?.includes(buscarItem?.toLowerCase()) ||
        !buscarItem;
    }
}

/**Listar apenas items de compatiblidade `(cod_tipo == 1)` */
export function filtrarCompatibilidade() {
    return function(item) {              
        return item.cod_tipo === 1
    }
}

export function obterCodItem(id_tipo_selecionado: number|null) {
    switch (id_tipo_selecionado) {
        case 1:
            return 'cod_ingrediente_ativo';
        case 2:
            return 'cod_insumo';
        case 3:
            return 'cod_grupo_quimico';
        case 4:
            return 'cod_classe_agronomica';
        case 5:
            return 'cod_formualacao';
        case 6:
            return ''; //cultura
        case 8:
            return 'cod_tecnologia';
        case 9:
            return 'cod_grupo';
        default:
            return 'cod';
    }
}