import styled from 'styled-components'

export const Container = styled.div`
  min-width: 100%;
  position: absolute;
  bottom: 0;
  margin-bottom: 20px;
  .gradiente {
    width: 95%;
    min-height: 6px;
    background-image: linear-gradient(to right, #00770e, #ffffff);
    margin-bottom: 5px;
  }

  .logo {
    width: 95%;
    margin-left: 20px;
    justify-content: left;
    align-items: left;
    text-align: left;
    font-size: 11px;
    span {
      font-weight: 700;
    }
  }
  .texto {
    font-size: 10px;
    margin: 0 0 0 20px;
  }
`
