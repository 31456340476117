import type { DragEndEvent } from '@dnd-kit/core'
import { DndContext } from '@dnd-kit/core'
import { restrictToVerticalAxis } from '@dnd-kit/modifiers'
import { SortableContext, arrayMove, verticalListSortingStrategy } from '@dnd-kit/sortable'
import { Table } from 'antd'
import { TableProps } from 'antd/lib/table'
import React, { useEffect, useState } from 'react'
import { pagination } from '../Default'
import { Container } from './styled'
import { useLogic } from './useLogic'

interface Props extends TableProps<any> {
  dragEnd?: (newDataSource: any[], dragDataSource?: any[]) => void
}

const TableDrag: React.FC<Props> = (props) => {
  const { Row } = useLogic()
  const [data, setData] = useState<any>([])
  const [originalData, setOriginalData] = useState<any>([])

  const onDragEnd = ({ active, over }: DragEndEvent) => {
    if (active.id !== over?.id) {
      
      const activeIndex = originalData.findIndex((i) => i[props.rowKey as string] === active.id)

      // console.log('activeIndex', activeIndex)
      const overIndex = originalData.findIndex((i) => i[props.rowKey as string] === over?.id)
      

      function subiuOuDesceu(novo_array: any[]) {
        const subiu_deceu_array = [];
        novo_array.forEach((elemento) => {
          const elementoAtual = { ...elemento };
          elementoAtual.origem = active.id > over?.id ? 'subiu' : 'desceu';
          subiu_deceu_array.push(elementoAtual);
      });
        return subiu_deceu_array
      }

      const newArray = arrayMove(originalData, activeIndex, overIndex);


      const startIndex = Math.min(activeIndex, overIndex)

      // console.log('startIndex', startIndex)

      const endIndex = Math.max(activeIndex, overIndex) + 1
      const movedRowsData = subiuOuDesceu(newArray).slice(startIndex, endIndex)
      props.dragEnd(subiuOuDesceu(newArray), movedRowsData)
      // const movedRowsData = newArray.slice(startIndex, endIndex)
      // props.dragEnd(newArray, movedRowsData)

      setData(subiuOuDesceu(newArray))
      setOriginalData(subiuOuDesceu(newArray))
    }
  }

  useEffect(() => {
    setData(props.dataSource)
    setOriginalData(props.dataSource)
  }, [props.dataSource])

  // console.log("data: ", data);
  // console.log("PAIS: ", props);
  // console.log("ITEMS: ", data.map((i, index) => index + 1));

  return (
    <Container>
      <DndContext modifiers={[restrictToVerticalAxis]} onDragEnd={onDragEnd}>
        <SortableContext
          // rowKey array
          // items={data.map((i) => i[props.rowKey as any])}
          items={data.map((_, index) => index + 1)}
          strategy={verticalListSortingStrategy}
        >
          <Table
            pagination={pagination}
            components={{
              body: {
                row: Row,
              },
            }}
            dataSource={data}
            {...props}
          />
        </SortableContext>
      </DndContext>
    </Container>
  )
}

export default TableDrag
