import { useQuery } from 'react-query';
import { UseMutationResult, useMutation } from 'react-query';
import { api } from '../../../api'; 
import { message } from 'antd';
import { localConfig } from '../../../../Configs/localConfig';

//=================================================================================>
// GERENCIAR EQUIPE ASSISTENTE TÉCNICO
//<=================================================================================

//INTERFACE
interface Params {
    cod_consultor: number,
    tipo_acao: 'add' | 'remover',
    tecnicos_add?: number[];
    tecnicos_remover?: number[];
    refetchGerenciar: Function;
    limpar: Function;
}

export const usePostGerenciarEquipeAssisTecnico = (): {
    carregando_salvar_assist: boolean;
    mutate_salvar_assist: (params: Params) => void;
    isSuccess_salvar_assist: boolean
} => {

    
    const mutation: UseMutationResult<Response, Error, Params> = useMutation(
        async (params: Params) => {

            const { tipo_acao, tecnicos_add, tecnicos_remover, cod_consultor, refetchGerenciar, limpar } = params;

            message.loading({ content: 'Salvando...', key: 'loading' });

            function tipoAcao() {
                return tipo_acao === 'add' ? { tecnicos: tecnicos_add } : { tecnicos_remover: tecnicos_remover };
            }

            return api.post(`gerenciar-equipe-assistente-tecnico?codConta=${localConfig()?.conta}`, {
                cod_consultor: cod_consultor,
                ...tipoAcao()
            } )
                .then((response: any) => {
                    message.success({ content: tipo_acao==='add' ? 'Adicionado com sucesso!' : 'Removido com sucesso!', key: 'loading' });
                    limpar();
                    refetchGerenciar();
                    return response;
                })
                .catch((error: any) => {
                    message.error({ content: tipo_acao==='add' ? 'Falha ao adicionar assistente' : 'Falha ao remover assistente', key: 'loading' });
                    message.error({ content: (error?.response?.data?.error) ?? 'Falha salvar alteração!', duration: 10 });
                    throw error;
                });
        }
    );

    return { 
        carregando_salvar_assist: mutation.isLoading, 
        mutate_salvar_assist: mutation.mutate,
        isSuccess_salvar_assist: mutation.isSuccess
    };
};