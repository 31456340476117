import styled from "styled-components";

export const ContainerPagination = styled.div`
    width: 100%;
    height: 50px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    background: #fafafa;
    box-sizing: border-box;
    padding: 15px;
    border-bottom-left-radius: 5px;
    border-bottom-right-radius: 5px;
    border-top: thin solid var(--border-color);
    margin-top: -10px;

    div.ContainerPagination__total {
        color: var(--text-color);
    }

    div.ContainerPagination__pages {
        display: flex;
        align-items: center;
        gap: 15px;

        div.CP__pages__perPage {
            width: 150px;
            height: 35px;
            position: relative;

            button.CP__pages__perPage__main {
                width: 100%;
                height: 100%;
                border-radius: 5px;
                border: thin solid var(--border-color);
                background-color: white;
                cursor: pointer;
                display: flex;
                gap: 7px;
                align-items: center;
                justify-content: center;
                transition: .3s;
                z-index: 1;
                position: absolute;

                :hover {
                    background: var(--primary-color-light);
                    border: thin solid var(--primary-color);
                    filter: brightness(110%);
                }
                :active {
                    transform: scale(0.97);
                }
            }

            div.CP__pages__perPage__options {
                position: absolute;
                bottom: 30px;
                left: 0;
                width: 100%;
                background-color: white;
                border-top: thin solid var(--border-color);
                border-left: thin solid var(--border-color);
                border-right: thin solid var(--border-color);
                border-top-right-radius: 5px;
                border-top-left-radius: 5px;
                transition: .4s;
                margin-bottom: -35px;
                opacity: 0.5;
                transform: scaleY(0);
                transform-origin: bottom;

                :is([data-open = true]) {      
                    margin-bottom: 0px;
                    opacity: 1;
                    transform: scale(1);
                }

                button.CP__pages__perPage__options__item {
                    width: 100%;
                    height: 40px;
                    display: flex;
                    align-items: center;
                    gap: 7px;
                    background-color: transparent;
                    border: none;
                    cursor: pointer;
                    box-sizing: border-box;
                    padding: 10px;
                    color: var(--text-color);
                    font-size: 13px;

                    :hover {
                        background: var(--hover-color);
                    }
                    :active {
                        background: var(--primary-color-light);
                    }
                    :is([data-active = true]) {
                        font-weight: 600;
                    }
                }
            }
        }

        div.CP__pages__pagination {

            button.CP__pages__pagination__page {
                box-sizing: border-box;
                padding: 5px;
                min-width: 30px;
                margin: 0 2px;
                background: white;
                color: var(--text-color);
                border: 1px solid var(--border-color);
                border: none;
                border-radius: 5px;
                font-size: 13px;
                cursor: pointer;
                transition: .4s;

                :hover {
                    background: var(--primary-color-light);
                    border: thin solid var(--primary-color);

                    :disabled {
                        background-color: transparent;
                        cursor: normal !important;
                    }
                }
                :active {
                    transform: scale(0.9);
                }
                :is([data-active = true]) {
                    background: var(--primary-color);
                    color: white;
                    font-weight: 600;
                }
                :disabled {
                    background-color: transparent;
                    border: none;
                    cursor: default !important;
                }
            }
        }

        div.CP__pages__goTo {
            /* width: 65px; */
            height: 30px;
            display: flex;
            align-items: center;
            background: #fff;
            border: thin solid var(--border-color);
            border-radius: 5px;
            box-sizing: border-box;
            padding: 0px 5px;

            input.CP__pages__goTo__input {
                width: 40px;
                background: transparent;
                border: none;
                box-sizing: border-box;
                padding: 0px 7px;
            }

            button.CP__pages__goTo__btn {
                width: 22px;
                height: 22px;
                display: flex;
                align-items: center;
                justify-content: center;
                background: transparent;
                border: none;
                font-size: 10px;
                background: var(--primary-color);
                cursor: pointer;
                border-radius: 5px;
                color: #fff;
            }
        }
    }

`;