import { faCheck, faClone } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { useRef, useState } from "react";
import styled from "styled-components";
import { Render } from "../render";

//STYLES
const Row = styled.div`
    width: 100%;
    min-height: 40px;
    display: flex;
    border: thin solid var(--border-color);
    border-bottom: none;
    transition: .4s;

    :is([data-last = true]) {
        border-bottom: thin solid var(--border-color);
    }

    :hover {
        div.Row__atribute:is([data-null = true]) {
            opacity: 0.8;
        }
    }

    div.Row__atribute {
        width: 150px;
        background: #FAFAFA;
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 0px 10px;
        border-right: thin solid var(--border-color);
        font-size: 13px;
        font-weight: 600;
        color: var(--text-color);
        word-break: break-all;

        :is([data-null = true]) {
            opacity: 0.4;
            font-style: italic;
        }
    }
    div.Row__value {
        width: calc(100% - 200px);
        display: flex;
        align-items: center;
        box-sizing: border-box;
        padding: 10px;
        font-size: 13px;
        color: var(--text-color);
        box-sizing: border-box;
        word-break: break-all;

        input.Row__value__input {
            border: none;
            transform: scale(0);
            opacity: 0;
        }

        span.Row__value__null {
            font-style: italic;
            font-weight: 400;
            color: var(--text-color-light);
        }
    }
    div.Row__copy {
        width: 50px;
        display: flex;
        align-items: center;
        justify-content: center;

        button.Row__copy__btn {
            width: 30px;
            height: 30px;
            display: flex;
            align-items: center;
            justify-content: center;
            border-radius: 5px;
            border: none;
            background: transparent;
            cursor: pointer;
            color: var(--primary-color);

            :hover {
                background: var(--primary-color);
                color: #fff;
            }
            :is([data-copied = true]):hover {
                background: transparent;
                color: var(--primary-color);
            }
            :active {
                transform: scale(0.95);
            }
        }
    }
`;

//PROPS
interface Props {
    atribute: string;
    value: string;
    isLast?: boolean;
}

export const CopiableRow: React.FC<Props> = ({ atribute, value, isLast }) => {

    //REFs
    const inputRef = useRef<HTMLInputElement>(null);

    //STATES
    const [copied, setCopied] = useState<boolean>(false);

    function copyClick() {
        setCopied(true);
        setTimeout(() => {
            setCopied(false);
        }, 2000);
    }

    const copyValue = () => {
        if (inputRef.current) {
            inputRef.current.select();
            inputRef.current.setSelectionRange(0, 99999);
            document.execCommand('copy');
        }
    };

    function isValidValue() {
        return !(value==='' || value===null || value===undefined || value==='null' || value==='unknow' || value==='[]')
    }

    return (
        <Row data-last={isLast}>
            <div className="Row__atribute" data-null={!isValidValue()}>
                {atribute}
            </div>
            <div className="Row__value">
                {isValidValue()
                    ? value
                    : <span className="Row__value__null">null</span>
                }
                <input ref={inputRef}  value={value} type="text" className='Row__value__input' />
            </div>
            <div className="Row__copy">
                <Render condition={isValidValue()}>
                    <button className="Row__copy__btn" onClick={()=>{copyClick();copyValue();}} data-copied={copied} title={copied?'Copiado':'Copiar valor'}>
                        {copied
                            ? <FontAwesomeIcon icon={faCheck} />
                            : <FontAwesomeIcon icon={faClone} />
                        }         
                    </button>
                </Render>
                
            </div>
        </Row>
    )
}