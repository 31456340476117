import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'
import { Polygon } from '@react-google-maps/api'
import { faPen, faTrash } from '@fortawesome/free-solid-svg-icons'
import {
  Button,
  Card,
  Col,
  Form,
  Input,
  List,
  Modal,
  Row,
  Space,
  Table,
  Tag,
  Typography,
  message,
} from 'antd'
import { map, sumBy } from 'lodash'
import Icons from '../../../../Components/Icons'
import TableDrag from '../../../../Components/TableDrag'
import { Notification } from '../../../../Components/notification'
import AgrupamentoPropriedade from '../../../../Components/propriedade/agrupamentoPropriedade'
import { centerMap, getBoundsAgrupamentoPropriedade, tryError } from '../../../../services/Afins'
import {
  ItensAgrupamentoPropriedadeTypes,
  delAgrupamentoPropriedades,
  getAgrupamentoPropriedades,
  putAgrupamentoPropriedades,
} from '../../../../services/Propriedades/agrupamentoPropriedade'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'
import { Container } from './styles'
import { log } from 'console'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'
import { ContainerMap } from '../styled'
import { GoogleMap, PolygonF } from '@react-google-maps/api'
import { getTalhaoCultivavel } from '../../../../services/TalhaoSafra'
import { getTalhao } from '../../../../services/Talhao'
import SidePainel from '../../../../Components/SidePainel'
import { ColorPicker } from 'antd/lib'
import { Tools } from '../../../bookFertilidade/styled'
import { formatarValor } from '../../../../utils'
import GeneratePdfButton from '../../../../Components/relatorio-component'

interface TypesMapa {
  zoom: number
  center: {
    lat: number
    lng: number
  }
  style?: { [key: string]: string }
}

interface Coordenada {
  latitude: number
  longitude: number
}
interface PropsPolygon {
  nomePropriedade: string
  cod_propriedade: number
  kml: {
    coordenadas: Coordenada[]
  }
  color: string
}

type TipoPolygonAtualizado = { paths: any; options: { fillColor: any; strokeColor: any } }

const PageAgrupamentoPropriedade: React.FC = () => {
  const [visibleDrawer, setVisibleDrawer] = useState(false)
  const [form] = Form.useForm()
  const [sideSidePainel, setSideSidePainel] = useState(false)
  const [visualizarAgrupamento, setVisualizarAgrupamento] = useState(false)
  const [loading, setLoading] = useState(false)
  const [permission, setPermission] = useState<ItensPerUserLogged>({} as ItensPerUserLogged)
  const [agrupados, setAgrupados] = useState<number[]>([])
  const [update, setUpdate] = useState(0)
  const [arrayTalhoes, setArrayTalhoes] = useState([])
  const [map, setMap] = useState<google.maps.Map>()
  const [dataPolygon, setDataPolygon] = useState([])
  const [mapKey, setMapKey] = useState(0)
  const [arrayColors, setArrayColors] = useState([])
  const [dataAgrupamento, setDataAgrupamento] = useState([])
  const [totalArea, setTotalArea] = useState(0)
  const [dataAgrupamentoPropriedade, setDataAgrupamentoPropriedade] = useState<
    ItensAgrupamentoPropriedadeTypes[]
  >([])
  const [dadosEstatisticas, setDadosEstatisticas] = useState([])
  const [dadosResultado, setDadosResultado] = useState([])
  const [nomeAgrupamento, setNomeAgrupamento] = useState('')

  const [propsMap, setPropsMapa] = useState<TypesMapa>({
    zoom: 11,
    center: {
      lat: 0,
      lng: 0,
    },
    style: { width: '100%', height: '78vh' },
  })

  const resetMap = () => {
    if (map) {
      map.setCenter({ lat: 0.0, lng: 0.0 })
      map.setZoom(2)
    }
  }

  const listAgrupamentoPropriedade = async () => {
    try {
      setLoading(true)
      const resp = await getAgrupamentoPropriedades({ embed: 'propriedades' })

      /* Lista as novas propriedadesagrupadas do Cliente*/
      const dados = resp.data.itens

      /* retornar o array, sendo um conjunto das propriedade agrupadas */
      const agru = dados.map((rec) => {
        return rec.propriedades.map((item) => item.cod_propriedade)
      })

      /* Agrupado vai filtrar todas as propriedade que foram agrupada em um unico array */
      const agrupado = agru
        .map((submatriz) => submatriz.concat())
        .reduce((acumulador, submatriz) => acumulador.concat(submatriz), [])

      setAgrupados(agrupado)

      setDataAgrupamentoPropriedade(dados)
      setNomeAgrupamento(resp?.data?.itens[0]?.nome)
      somarValores(dados, resp?.data?.itens[0]?.propriedades.length)
      gerarTabela(dados)
      setLoading(false)
    } catch (error) {
      setLoading(false)
    }
  }

  function somarValores(dados, totais) {
    const resultado = dados[0]?.propriedades?.reduce(
      (soma, item) => {
        return {
          //@ts-ignore
          area: soma?.area + item?.propriedade_area,
          talhao_area: soma?.talhao_area + item?.talhao_area,
        }
      },
      {
        area: 0,
        talhao_area: 0,
      }
    )

    const resultadoFinal = [
      {
        totalizado_area: formatarValor(resultado?.area),
        totalizado_talhao_area: formatarValor(resultado?.talhao_area),
      },
    ]
    gerarTabela2(resultadoFinal, totais)
  }

  function gerarTabela(dados) {
    const safra = dados[0]?.safra_nome
    const data = dados[0]?.propriedades?.map((item) => {
      return [
        { text: item?.propriedade_nome, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: safra, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: formatarValor(item?.propriedade_area),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
        {
          text: formatarValor(item?.talhao_area),
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'right',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*', '*'],
          body: [
            [
              { text: 'Nome', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Safra', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área/ha', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              { text: 'Área talhão', style: 'tableHeader', fontSize: 9, alignment: 'center' },
            ],
            ...data,
          ],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosEstatisticas(table)
  }

  function gerarTabela2(dados, length) {
    const data = dados?.map((item) => {
      return [
        { text: length, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        { text: item?.totalizado_area, fontSize: 7, margin: [0, 2, 0, 2], alignment: 'center' },
        {
          text: item?.totalizado_talhao_area,
          fontSize: 7,
          margin: [0, 2, 0, 2],
          alignment: 'center',
        },
      ]
    })

    const table = [
      {
        table: {
          headerRows: 1,
          widths: ['*', '*', '*'], // Definindo larguras das colunas
          body: [
            [
              {
                text: 'Total propriedades',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
              { text: 'Total de Área', style: 'tableHeader', fontSize: 9, alignment: 'center' },
              {
                text: 'Total de Área talhões',
                style: 'tableHeader',
                fontSize: 9,
                alignment: 'center',
              },
            ],
            ...data,
          ],
          margin: [0, 50, 0, 0],
        },
        layout: {
          fillColor: function (rowIndex, node, columnIndex) {
            return rowIndex % 2 === 0 ? '#f5f5f5' : null
          },
        },
      },
    ]
    setDadosResultado(table)
  }

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  const handleClickRemoveProAgrupada = async (data, cod) => {
    Modal.info({
      title: `Deseja remover a propriedade ${cod?.propriedade_nome}?`,

      okText: 'Sim',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { danger: true, type: 'primary' },
      cancelText: 'Não',
      okCancel: true,
      onOk: async () => {
        try {
          const resp = await putAgrupamentoPropriedades(
            { propriedades_remover: [cod.cod_propriedade] },
            cod.cod_propriedade_grupo
          )

          Notification({ message: 'Propriedade removida com sucesso', type: 'success' })

          listAgrupamentoPropriedade()
        } catch (error) {
          tryError(error)
        }
      },
    })
  }

  const onLoadMap = (map) => {
    setMap(map)
  }

  const setColorPropriedade = (codPropriedade, cor) => {
    setArrayColors((colors) => ({
      ...colors,
      [codPropriedade]: cor,
    }))
  }

  const handleVisualizarAgrupamento = async (cod) => {
    try {
      setLoading(true)

      const resp = await getAgrupamentoPropriedades({ cod, embed: 'propriedades' })
      const dados = resp.data.itens[0]

      let arrayAgrupamentoPropriedades = []

      const data = dados.propriedades.map((item) => {
        arrayAgrupamentoPropriedades = [...arrayAgrupamentoPropriedades, item]
      })

      const somaDasAreas = arrayAgrupamentoPropriedades.reduce(
        (acumulador, objeto) => acumulador + Number(objeto.talhao_area),
        0
      )

      setTotalArea(somaDasAreas)

      const propriedades = dados.propriedades.map((item) => item.cod_propriedade)

      // lista as propriedades agrupadas
      const listPro = dados.propriedades.map((rec) => {
        setColorPropriedade(rec.cod_propriedade, rec.cor)
        return rec.cod_propriedade
      })

      let talhoes = []

      /* Faço a requisição dos talhoes cultivavei das propriedades agrupadas */
      for (let i = 0; i < propriedades.length; i += 1) {
        /*  a cada passagem no meu array de propriedades pego o codigo de cada propriedade agrupada
         para em seguida fazer a requisição dos talhoes cultivaveis da propriedade */

        const codPropriedade = propriedades[i]

        const resp = await getTalhaoCultivavel({ codPropriedade: codPropriedade })

        //montagem do array de objetos
        if (resp.data.itens.length > 0) {
          talhoes = [
            ...talhoes,
            {
              cod: codPropriedade,
              talhoes: resp.data.itens,
            },
          ]
        }
      }

      let arrayTalhao = []

      talhoes.map((item, index) => {
        arrayTalhao = [...arrayTalhao, ...item.talhoes]
      })

      setDataPolygon(arrayTalhao)
      setDataAgrupamento(arrayAgrupamentoPropriedades)
      form.setFieldsValue(dados)
      setLoading(false)
    } catch (error) {
      setLoading(false)
      console.error('Erro ao carregar dados para edição:', error)
    }

    setVisualizarAgrupamento(true)
  }

  const handleDeleteAgrupamento = async (cod) => {
    const agrupamento = dataAgrupamentoPropriedade.find((item) => item.cod === cod)
    const nomeAgrupamento = agrupamento?.nome

    const confirmRemoval = async () => {
      try {
        const resp = await delAgrupamentoPropriedades(cod)
        Notification({ message: 'Agrupamento removido com sucesso', type: 'success' })
        listAgrupamentoPropriedade()
      } catch (error) {
        tryError(error)
      }
    }

    Modal.confirm({
      title: `Deseja remover o agrupamento ${nomeAgrupamento}? `,
      okText: 'Sim',
      okButtonProps: { type: 'primary' },
      cancelButtonProps: { danger: true, type: 'primary' },
      cancelText: 'Não',
      onOk: confirmRemoval,
    })
  }

  /* Lista as propriedades agrupadas */
  useEffect(() => {
    // refresh()
    if (update === 0) {
      listAgrupamentoPropriedade()
      setDataPolygon([])
    }
  }, [update])

  useEffect(() => {
    if (dataPolygon.length > 0 && map) {
      const coordenadas: google.maps.LatLngLiteral[] = []
      dataPolygon.forEach((dp) => {
        dp.color = arrayColors[dp.cod_propriedade] ?? 'cyan'

        if (dp.kml && dp.kml.coordenadas) {
          dp.kml.coordenadas.forEach((e) => {
            if (
              typeof e.latitude === 'number' &&
              typeof e.longitude === 'number' &&
              isFinite(e.latitude) &&
              isFinite(e.longitude)
            ) {
              coordenadas.push({ lat: Number(e.latitude), lng: Number(e.longitude) })
            }
          })
        }
      })
      const bounds = getBoundsAgrupamentoPropriedade(coordenadas)
      const center: google.maps.LatLngLiteral = centerMap(coordenadas).toJSON()
      setPropsMapa({ center, zoom: 12 })

      map.fitBounds(bounds)
    }
  }, [dataPolygon, map])

  useEffect(() => {}, [visualizarAgrupamento])

  useEffect(() => {
    if (map) {
      map.setZoom(propsMap.zoom)
      map.panTo(propsMap.center)
    }
  }, [map, propsMap])

  return (
    <Container>
      <ViewPage
        newHide={false}
        onPermission={setPermission}
        rotina={83}
        title='Agrupamento de propriedade'
        btnClick={() => handleClickNew()}
        btnsExta={
          <>
            {localStorage.getItem('tipo-acesso') === 'conta' ? (
              <GeneratePdfButton
                dados={dadosEstatisticas}
                title={'Relatório agrupamento de propriedades'}
                totais={dadosResultado}
                disable={dataAgrupamentoPropriedade.length <= 0}
                titleSecundario={nomeAgrupamento}
              />
            ) : null}
          </>
        }
        content={
          <AgrupamentoPropriedade
            permissions={permission}
            open={visibleDrawer}
            onClose={setVisibleDrawer}
            update={update}
            agrupados={agrupados}
            setUpdate={setUpdate}
            refresh={listAgrupamentoPropriedade}
            dataAgrupamento={dataAgrupamentoPropriedade}
            dataPolygon={null}
            map={null}
          />
        }
      >
        <TableDrag
          rowKey={'cod'}
          loading={loading}
          size='small'
          dataSource={dataAgrupamentoPropriedade}
          bordered
          columns={[
            { title: 'Nome', dataIndex: 'nome' },
            {
              title: 'Propriedades',
              dataIndex: 'propriedades',
              render: (data) => {
                return <Tag color='blue'>{data?.length}</Tag>
              },
            },
            {
              title: 'Total área agrupada/ha',
              dataIndex: 'propriedades',
              render: (data) => {
                // @ts-ignore
                return sumBy(data, (rec) => Number(rec.talhao_area)).toFixed(2)
              },
            },
            {
              title: 'Ações',
              width: 45,
              align: 'center',
              dataIndex: 'cod',
              render: (data: number) => (
                <Space>
                  {/* btn editar */}
                  <Button
                    icon={<Icons icon={faPen} />}
                    shape='circle'
                    hidden={permission?.alteracao === 0}
                    onClick={() => {
                      setUpdate(data)
                      setVisibleDrawer(true)
                    }}
                  />
                  {/* visualizar */}

                  <Button
                    shape='circle'
                    onClick={() => handleVisualizarAgrupamento(data)}
                    icon={<FontAwesomeIcon icon={solid('map-marked')} />}
                    hidden={permission?.alteracao === 0 && permission?.acesso === 0}
                    size='middle'
                  />

                  {/* Remover Agrupamento */}
                  <Button
                    danger
                    type='primary'
                    icon={<Icons icon={faTrash} />}
                    shape='circle'
                    hidden={permission?.exclusao === 0}
                    onClick={() => {
                      handleDeleteAgrupamento(data)
                    }}
                  />
                </Space>
              ),
            },
          ]}
          expandable={{
            expandedRowRender: (record) => (
              <Table
                size='small'
                bordered
                pagination={false}
                dataSource={record.propriedades}
                columns={[
                  { title: 'Nome da Propriedade', dataIndex: 'propriedade_nome' },
                  { title: 'Área da propriedade/ha', dataIndex: 'talhao_area' },
                ]}
              />
            ),
          }}
        />
      </ViewPage>
      <Modal
        open={visualizarAgrupamento}
        onCancel={() => {
          setVisualizarAgrupamento(false)
          // Incrementa a chave do mapa para forçar a remontagem
          setMapKey((prevKey) => prevKey + 1)
        }}
        destroyOnClose
        style={{ top: '10px', minWidth: '97%' }}
        closable={false}
        footer={false}
      >
        <ContainerMap>
          {/* @ts-ignore */}
          <GoogleMap
            key={mapKey} // Atualiza a chave do mapa
            onLoad={onLoadMap}
            mapContainerClassName='floating'
            zoom={propsMap.zoom}
            mapContainerStyle={{
              width: '100%',
              height: '93vh',
            }}
            center={propsMap.center}
            options={{
              streetViewControl: false,
              fullscreenControl: false,
              mapTypeControl: false,
              mapTypeId: 'hybrid',
            }}
          >
            <div style={{ width: '300px', position: 'relative', padding: 10 }}>
              <Card
                size='small'
                style={{ backgroundColor: '#fff', borderRadius: '' }}
                title='Agrupamento de propriedade'
                actions={[
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'space-between',
                      marginLeft: '1.3rem',
                      marginRight: '1rem',
                    }}
                  >
                    <Button
                      type='primary'
                      danger
                      size='small'
                      onClick={() => setVisualizarAgrupamento(false)}
                      style={{ marginRight: 8, width: '50px' }}
                    >
                      Sair
                    </Button>
                  </div>,
                ]}
              >
                <Form form={form} layout='vertical'>
                  <Row gutter={[8, 0]}>
                    <Col>
                      <Form.Item label='Nome do agrupamento' name='nome'>
                        <h4 style={{ marginBottom: '0' }}>{form.getFieldValue('nome')}</h4>
                      </Form.Item>
                    </Col>

                    <Col flex='1 1 auto'>
                      <Form.Item label='Propriedades' name='propriedades'>
                        <div
                          style={{
                            maxHeight: 'calc(100vh - 500px)',
                            overflowY: 'auto',
                          }}
                        >
                          <List loading={loading}>
                            {dataAgrupamento.map((data, index) => {
                              const key = index + 1
                              const color = dataAgrupamento.find(
                                (rec) => rec.cod_propriedade === data.cod
                              )

                              return (
                                <List.Item key={key}>
                                  <div
                                    style={{
                                      display: 'flex',
                                      justifyContent: 'center',
                                      alignItems: 'center',
                                    }}
                                  >
                                    <div style={{ paddingLeft: '15px' }}>
                                      <h5 style={{ marginBottom: '0' }}>{data.propriedade_nome}</h5>
                                      <small>{data.talhao_area} ha</small>
                                    </div>
                                  </div>

                                  <ColorPicker
                                    disabledAlpha
                                    // onChange={(_, hex) => handleChangeColor(hex, data.cod)}
                                    presets={[
                                      {
                                        label: 'Recomendado',
                                        colors: [
                                          '#00FFFF',
                                          'red',
                                          'yellow',
                                          'blue',
                                          '#19F819',
                                          '#FFC0CB',
                                        ],
                                      },
                                    ]}
                                    value={data.cor || 'cyan'} // Defina ciano como valor inicial
                                    disabled // Adicione a propriedade readOnly para bloquear a edição
                                  />
                                </List.Item>
                              )
                            })}
                          </List>
                        </div>
                      </Form.Item>
                    </Col>
                  </Row>
                  <div style={{ display: 'inline-flex', alignItems: 'center' }}>
                    <span>Área total: </span>
                    <h5 style={{ marginLeft: '8px', marginBottom: '0' }}>{totalArea.toFixed(2)}</h5>
                    <span style={{ marginLeft: '4px', marginBottom: '0' }}>ha</span>
                  </div>
                </Form>
              </Card>
            </div>
            {dataPolygon.map((rec: any) => {
              return (
                <Polygon
                  paths={rec.kml.coordenadas.map((e) => ({ lat: e.latitude, lng: e.longitude }))}
                  options={{
                    fillColor: rec.color,
                    strokeColor: rec.color,
                  }}
                />
              )
            })}
          </GoogleMap>
        </ContainerMap>
      </Modal>
    </Container>
  )
}

export default PageAgrupamentoPropriedade
function refresh() {
  throw new Error('Function not implemented.')
}
