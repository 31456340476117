import { faSpinner } from '@fortawesome/free-solid-svg-icons'
import { Button } from 'antd'
import React, { ChangeEvent, ReactNode, useEffect, useRef, useState } from 'react'
import Icons from '../Icons'

type Props = {
  label: ReactNode
  onChange: (file: ChangeEvent<HTMLInputElement>) => void
  hidden?: boolean
  key?: string
  className?: string
  styles?: React.CSSProperties
}

const UploadFile: React.FC<Props> = (props) => {
  const { label = '', onChange, hidden = false, key, className, styles } = props

  const refFile = useRef<HTMLInputElement | null>(null)
  const [loading, setLoading] = useState(false)

  const handleClick = () => {
    refFile.current.value = null
    refFile.current.click()
  }

  useEffect(() => {
    if (label === undefined) {
      setLoading(true)
    } else {
      setLoading(false)
    }
  }, [label])

  return (
    <>
      <Button
        style={styles}
        className={className}
        key={key}
        hidden={hidden}
        onClick={handleClick}
        type='default'
      >
        {loading ? <Icons icon={faSpinner} spin /> : label}
      </Button>
      <input key={key} hidden ref={refFile} type='file' accept='.kml' onChange={onChange} />
    </>
  )
}
export default UploadFile
