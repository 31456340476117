export const listTipoUniao = [
  {
    cod: 1,
    nome: 'Comunhão universal de bens',
  },
  {
    cod: 2,
    nome: 'Comunhão parcial de bens',
  },
  {
    cod: 3,
    nome: 'Separação de bens',
  },
  {
    cod: 4,
    nome: 'União estável',
  },
]

export const tiposReservistas = [
  {
    cod: 1,
    nome: 'Certificado de alistamento militar',
  },
  {
    cod: 2,
    nome: 'Certificado de reservista de 1ª ou 2ª categoria',
  },
  {
    cod: 3,
    nome: 'Certificado de dispensa de incorporação',
  },
  { cod: 4, nome: 'Certificado de isenção' },
  {
    cod: 5,
    nome: 'Certificado de situação militar',
  },
]

export const listTipoSanguineo = [
  {
    cod: 1,
    nome: 'A+',
  },
  {
    cod: 2,
    nome: 'A-',
  },
  {
    cod: 3,
    nome: 'B+',
  },
  {
    cod: 4,
    nome: 'B-',
  },
  {
    cod: 5,
    nome: 'AB+',
  },
  {
    cod: 6,
    nome: 'AB-',
  },
  {
    cod: 7,
    nome: 'O+',
  },
  {
    cod: 8,
    nome: 'O-',
  },
]

export const listaEscolaridade = [
  {
    cod: 9,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'DOUTORADO',
    status: 1,
    update_time: null,
  },
  {
    cod: 10,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'PÓS-DOUTORADO',
    status: 1,
    update_time: null,
  },
  {
    cod: 1,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'FUNDAMENTAL INCOMPLETO',
    status: 1,
    update_time: null,
  },
  {
    cod: 2,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'FUNDAMENTAL',
    status: 1,
    update_time: null,
  },
  {
    cod: 3,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'MÉDIO INCOMPLETO',
    status: 1,
    update_time: null,
  },
  {
    cod: 4,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'MÉDIO',
    status: 1,
    update_time: null,
  },
  {
    cod: 5,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'SUPERIOR INCOMPLETO',
    status: 1,
    update_time: null,
  },
  {
    cod: 6,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'SUPERIOR',
    status: 1,
    update_time: null,
  },
  {
    cod: 7,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'PÓS-GRADUADO',
    status: 1,
    update_time: null,
  },
  {
    cod: 8,
    create_time: '2022-05-16 10:47:10.276534',
    delete_time: null,
    nome: 'MESTRADO',
    status: 1,
    update_time: null,
  },
]

export const listarEstadoCivil = [
  {
    cod: 1,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'SOLTEIRO(A)',
    status: 1,
    update_time: null,
  },
  {
    cod: 2,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'CASADO(A)',
    status: 1,
    update_time: null,
  },
  {
    cod: 3,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'UNIÃO ESTÁVEL',
    status: 1,
    update_time: null,
  },
  {
    cod: 4,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'SEPARADO(A)',
    status: 1,
    update_time: null,
  },
  {
    cod: 5,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'DIVORCIADO(A)',
    status: 1,
    update_time: null,
  },
  {
    cod: 6,
    create_time: '2022-05-16 14:00:31.016113',
    delete_time: null,
    nome: 'VIÚVO(A)',
    status: 1,
    update_time: null,
  },
]
