import { PaginationProps, PopconfirmProps } from 'antd'

export const pagination: PaginationProps = {
  showSizeChanger: true,
  defaultPageSize: 15,
  pageSizeOptions: [15, 30, 50, 100],
}

export const propsPopconfirm: PopconfirmProps = {
  title: null,
  okText: 'Sim',
  cancelText: 'Não',
  cancelButtonProps: {
    danger: true,
    type: 'primary',
  },
}
