import React, { useEffect, useState } from 'react'

import { Button, Col, Drawer, Form, Input, Row, message } from 'antd'

import {
  getTalhaoCultivavel,
  postTalhaoCultivavel,
  putTalhaoCultivavel,
} from '../../services/TalhaoSafra'

import { Notification } from '../notification'

import { faPen } from '@fortawesome/free-solid-svg-icons'
import { kml } from '@tmcw/togeojson'
import { useLogic } from '../../context/useLogic'
import { calculoAreaCoord, getBoundsNoArray } from '../../services/Afins'
import { ItensPerUserLogged } from '../../services/login/permissaoUsuarioLoado'
import Icons from '../Icons'
import DesenhaTalhaoNew from '../desenhoTalhao/new'
import KMLUploader from '../kmlUpload'
import Maps from '../maps/polygon'
import { Container } from '../talhaoSafra/styled'
import DiasDesdeData from '../DiasDesdeData'
import dayjs from 'dayjs'
import { useAccess } from '../../context/useAccess'

interface TypeProps {
  visible: boolean
  onClose: (data: boolean) => void
  update: number
  setUpdate: (codPro?: number, codTalhao?: number) => void
  atualizar: (data?: number, data2?: string) => void
  codPro: number
  permissions: ItensPerUserLogged
  dataTalhao: any
}

interface Coordinates {
  latitude: number
  longitude: number
}

interface TypeEditPolygon {
  nome: string
  propriedadeCod: number
  propriedadeNome: string
  fitBounds: google.maps.LatLngBounds | google.maps.LatLngBoundsLiteral
  polygon: google.maps.LatLngLiteral[] | google.maps.LatLng[]

}

const FormTalhaoSafra: React.FC<TypeProps> = (props) => {
  const { visible, onClose, update, atualizar, setUpdate, codPro, dataTalhao, permissions } = props

  const [form] = Form.useForm()
  const { responsive } = useLogic()
  const {  atualizarArea, setAtualizarArea } = useAccess()

  const [drawerVisible, setDrawerVisible] = useState(false)
  const [safra, setSafra] = useState(true)
  const [kmls, setKml] = useState(null)
  const [coordsKml, setCoordsKml] = useState([])
  const [editPolygon, setEditPolygon] = useState<TypeEditPolygon>({
    polygon: [],
  } as TypeEditPolygon)
  const [selectedFile, setSelectedFile] = useState(null)
  const [disable, setDisable] = useState(true)
  const [validoFile, setValidoFile] = useState(false)
  const [modalDesenho, setModalDesenho] = useState(false)
  const [logUser, setLogUser] = useState(false)
  const [usuarioDeRegistro, setUsuarioDeRegistro] = useState('')
  const [dataDeRegistro, setDataDeRegistro] = useState('')
  const [criadoUpdate, setCriadoUpdate] = useState<number>()

  const hancleDrawerClose = () => {
    setDrawerVisible(false)
    onClose(false)
    setUpdate(0)
    form.resetFields()
    setKml(null)
    setSelectedFile(null)
    setCoordsKml([])
    setDisable(true)
  }

  /* Faz a requisição de todos os talhões da safra */
  const handleGetTalhao = async () => {
    try {
      message.loading('Aguarde...')
      const response = await getTalhaoCultivavel({
        codPropriedade: codPro,
        cod: update,
      })

      if (response.status === 200) {
        const dados = response.data.itens[0]

        setKml(dados)
        dados.arquivo = ''

        form.setFieldsValue(dados)

        const areaPolygon = dados.kml.coordenadas.map((item) => {
          return { lat: item.latitude, lng: item.longitude }
        })

        const calcArea = calculoAreaCoord([areaPolygon])

        if (calcArea > 0) {
          setValidoFile(true)
        } else {
          setValidoFile(false)
        }

        setEditPolygon({
          nome: dados.nome,
          propriedadeNome: '',
          propriedadeCod: dados.cod_propriedade,
          fitBounds: getBoundsNoArray(
            dados.kml.coordenadas.map((item) => {
              return { lat: item.latitude, lng: item.longitude }
            })
          ),
          polygon: dados.kml.coordenadas.map((item) => {
            return { lat: item.latitude, lng: item.longitude }
          }),
        })

        form.setFieldsValue({ area: calcArea })
        message.destroy()
        
      }
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })

        message.destroy()
      }
    }
  }

  const listPropri = () => {
    handleGetTalhao()
    atualizar(codPro, '')
    setDisable(false)
  }

  const handleFileUpload = (rawData: string, base64Data) => {
    extractCoordinates(rawData)
    setSelectedFile(base64Data)
  }

  const parseXmlFromString = async (xmlString: string): Promise<Document> => {
    const parser = new DOMParser()
    const xmlDoc = parser.parseFromString(xmlString, 'application/xml')
    return xmlDoc
  }

  const extractCoordinates = async (kmlString: string) => {
    try {
      const domKml = await parseXmlFromString(kmlString)

      const dataGeoJson = kml(domKml)

      const talhoes = []

      const dados = dataGeoJson.features
      const filtrado = dados.filter((data) => data.geometry.type === 'Polygon')

      if (filtrado.length > 0) {
        for (let i = 0; i < filtrado.length; i += 1) {
          const fragment = filtrado[i]
          const talhaoPoly = []

          const coordinates = fragment.geometry.coordinates[0]

          for (let ii = 0; ii < coordinates.length; ii += 1) {
            const coord = coordinates[ii]

            talhaoPoly.push({ lat: coord[1], lng: coord[0] })
          }

          talhoes.push(talhaoPoly)
        }

        const areaCoord = talhoes[0].map((item) => {
          return { lat: item.lat, lng: item.lng }
        })

        const calcArea = calculoAreaCoord([areaCoord])
        if (calcArea <= 0) {
          Notification({
            message: 'Arquivo Kml inválido',
            type: 'error',
          })
          setValidoFile(false)
          setTimeout(() => {
            setDisable(true)
          }, 1000)
        } else {
          setValidoFile(true)
          setTimeout(() => {
            setDisable(false)
          }, 1000)
        }

        form.setFieldsValue({ area: calcArea })

        if (update > 0) {
          setEditPolygon({
            nome: kmls.nome,
            propriedadeNome: '',
            propriedadeCod: kmls.cod_propriedade,
            fitBounds: getBoundsNoArray(editPolygon.polygon),
            polygon: areaCoord.map((item) => {
              return { lat: item.lat, lng: item.lng }
            }),
          })
        } else {
          setCoordsKml(areaCoord)
        }
      } else {
        Notification({
          message: 'Arquivo Kml inválido',
          type: 'error',
        })
        setValidoFile(false)
      }
    } catch (error) {
      console.error('Erro ao processar XML:', error)
    }
  }

  const handleEditContorno = () => {
    setEditPolygon({
      nome: kmls.nome,
      propriedadeNome: '',
      propriedadeCod: kmls.cod_propriedade,
      fitBounds: getBoundsNoArray(editPolygon.polygon),
      polygon: kmls.kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      }),
    })

    setModalDesenho(true)
  }

  const onFinish = async (data) => {
    try {
      message.loading('Aguarde...')
      // @ts-ignore
      if (update <= 0) {
        const novoArquivo = data.arquivo ?? selectedFile

        const dadosAtualizados = {
          ...data,
          arquivo: novoArquivo,
        }
        const response = await postTalhaoCultivavel(codPro, dadosAtualizados)
        if (response.status === 201) {
          message.destroy()
          Notification({
            message: 'Talhão Cadastrado com Sucesso',
            type: 'success',
          })

          form.resetFields()

          onClose(false)
          setKml(null)
          setUpdate(0)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
          setAtualizarArea(!atualizarArea)
          atualizar(codPro, '')
        }
      } else {
        const novoArquivo = selectedFile

        const dadosAtualizados = {
          ...data,
          arquivo: novoArquivo,
        }
        const response = await putTalhaoCultivavel(codPro, update, dadosAtualizados)
        if (response.status === 200) {
          message.destroy()
          Notification({
            message: 'Talhão Atualizado com Sucesso',
            type: 'success',
          })

          onClose(false)
          setKml(null)
          setUpdate(0)
          setSelectedFile(null)
          setCoordsKml([])
          setDisable(true)
          setAtualizarArea(!atualizarArea)
          atualizar(codPro, '')
        }
      }
    } catch (error) {
      message.destroy()

      if (error.response) {
        const { data } = error.response
        if (!data.error[0].field) {
          Notification({
            type: 'error',
            message: 'Erro',
            descricao: data.error,
          })
        } else {
          data.error.forEach((data) => {
            Notification({
              type: 'error',
              message: 'Erro',
              descricao: data.msg[0],
            })
          })
        }
      }
    }
  }

  const handleChangeMask = (data) => {
    let area = data.target.value
    area = area.replace(/([\u0300-\u036f]|[^0-9a-zA-Z\s])/g, '')
    area = (area / 100).toFixed(2).replace('.', ',')
    form.setFieldsValue({ area })
  }

  useEffect(() => {
    form.setFieldsValue({ cod_propriedade: codPro })
    setDrawerVisible(visible)
  }, [visible])

  useEffect(() => {
    if (update <= 0) {
      form.resetFields()
      setUsuarioDeRegistro('')
      setDataDeRegistro('')
      setLogUser(false)
      setCriadoUpdate(0)
    } else {
      handleGetTalhao()
    }
  }, [update])

  useEffect(() => {
    if (visible) {
      const listTalhao = dataTalhao.filter((item) => item.cod === update)
      if (update > 0 && listTalhao[0].create_user) {
        setUsuarioDeRegistro(listTalhao[0].create_user)
        setDataDeRegistro(listTalhao[0].create_time)
        setLogUser(true)
        setCriadoUpdate(0)
      }

      if (update > 0 && listTalhao[0].update_user != null) {
        setUsuarioDeRegistro(listTalhao[0].update_user)
        setDataDeRegistro(listTalhao[0].update_time)
        setCriadoUpdate(1)
        setLogUser(true)
      }
    }
  }, [visible, update])

  return (
    <Form form={form} layout='vertical' onFinish={onFinish}>
      <Drawer
        title={
          <div style={{ display: 'flex', flexDirection: 'column' }}>
            <>{update ? 'Atualizar' : 'Cadastrar'}</>
            <small style={{ opacity: '0.5', fontSize: '10px' }}>
              {logUser === true ? (
                <>
                  {' '}
                  {criadoUpdate > 0 ? 'Atualizado por:' : 'Criado por:'}{' '}
                  {`${usuarioDeRegistro} ${dayjs(dataDeRegistro).format('DD/MM/YYYY HH:mm:ss')}`}
                </>
              ) : null}
            </small>
          </div>
        }
        style={{ position: 'absolute' }}
        placement='right'
        width='100%'
        onClose={hancleDrawerClose}
        open={drawerVisible}
        closeIcon={false}
        getContainer={false}
        footer={
          <div
            style={{
              textAlign: 'left',
            }}
          >
            <Button
              hidden={permissions?.inclusao === 0 && permissions?.alteracao === 0}
              type='primary'
              htmlType='submit'
            >
              {update ? 'Atualizar' : 'Salvar'}
            </Button>
            {'   '}
            <Button danger type='primary' onClick={hancleDrawerClose} style={{ marginRight: 8 }}>
              Fechar
            </Button>
          </div>
        }
      >
        <Row gutter={[8, 0]}>
          <Col
            style={{
              pointerEvents: permissions?.acesso === 1 ? 'none' : 'auto',
            }}
            span={24}
          >
            <Row gutter={[8, 0]}>
              <Col flex='1 1 400px'>
                <Form.Item
                  rules={[
                    {
                      required: true,
                    },
                  ]}
                  label='Nome'
                  name='nome'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Row gutter={[8, 0]}>
              <Col flex='1 1 400px'>
                <Form.Item
                  rules={[
                    {
                      required: false,
                    },
                  ]}
                  label='Observação'
                  name='observacao'
                >
                  <Input />
                </Form.Item>
              </Col>
            </Row>
            <Col span={24}>
              <Row gutter={[8, 0]}>
                <Col flex='1 1 200px'>
                  <Form.Item
                    rules={[
                      {
                        required: false,
                      },
                    ]}
                    label='Área (ha)'
                    name='area'
                  >
                    <Input style={{ cursor: 'not-allowed' }} readOnly onChange={handleChangeMask} />
                  </Form.Item>
                </Col>
              </Row>
            </Col>

            <Col span={24} flex='1 1 200px'>
              <Form.Item style={{ width: '300px' }} name='arquivo'>
                <KMLUploader
                  disable={disable}
                  setDisable={setDisable}
                  onFileUpload={handleFileUpload}
                  valido={validoFile}
                  setValido={setValidoFile}
                ></KMLUploader>
              </Form.Item>
              <Col flex='1 1 600px'>
                {update <= 0 ? (
                  <div className='map-content'>
                    {coordsKml.length > 0 && <Maps coord={coordsKml} />}
                  </div>
                ) : (
                  <Container>
                    <div className='map-content'>
                      <div className='btn-edit-talhao'>
                        <Button
                          style={{ backgroundColor: 'white', border: 'none' }}
                          hidden={responsive}
                          onClick={handleEditContorno}
                          icon={<Icons style={{ color: 'green' }} icon={faPen} />}
                        />
                      </div>
                      {editPolygon.polygon.length > 0 && <Maps coord={editPolygon.polygon} />}
                    </div>
                  </Container>
                )}
              </Col>
            </Col>
          </Col>
        </Row>

        {/* Hide */}

        <Form.Item initialValue='1' hidden name='status'>
          <Input />
        </Form.Item>

        <Form.Item hidden initialValue={localStorage.getItem('conta')} name='cod_conta'>
          <Input />
        </Form.Item>
        <Form.Item hidden initialValue={localStorage.getItem('cod-cliente')} name='cod_cliente'>
          <Input />
        </Form.Item>
        <Form.Item hidden name='cod_propriedade' />
      </Drawer>
      <DesenhaTalhaoNew
        isOpen={modalDesenho}
        update={update}
        onSuccess={listPropri}
        cultivavel={safra}
        onClose={setModalDesenho}
        talhao={editPolygon}
      />
    </Form>
  )
}

export default FormTalhaoSafra
