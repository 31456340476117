
import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { api } from '../api'

export interface TypesConsultaUserEmail {
  itens: ItensConsultaUserEmailTypes[]
}

export interface ItensConsultaUserEmailTypes {
  cod: number
  nome: string
  email: string
  cod_equipe: number | null
}

type TypesQuery = {
  email?: string
  pagination?: number
}

const url = '/consulta-email-usuario'

export const getConsultaEmail = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesConsultaUserEmail> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}
