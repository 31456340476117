import { useState } from "react";
import { faListCheck, faTextWidth } from "@fortawesome/free-solid-svg-icons";
import { localConfig } from "../../../../Configs/localConfig";

//STYLES
import { ConfigBook } from "./styled";

//APP
import { usePostSalvarConfigBook } from "../../../../services/novoConfigBook/post";
import { Item__MapaFertilidade__Config, ParametroFertilidade__Itens } from "../../../../services/novoConfigBook/get";

//COMPONENTS
import Tabs from "../../../../Components/Tabs";
import PageContainer from "../../../../Components/pageContainer";
import TextoIntroducao from "./components/textoIntroducao";
import ConfigElementos from "./components/elementos";

const PageConfigBookNovo: React.FC = () => {

    //STATES
    const [tab_container_atual, setTab_container_atual] = useState<'Texto introdução' | 'Lista de elementos'>('Texto introdução');
    const [texto_introducao, setTexto_introducao] = useState<string>('');
    //STATES - LISTAS
    const [elementos_base, setElementos_base] = useState<ParametroFertilidade__Itens[]>([]);
    const [lista_elementos, setLista_elementos] = useState<Item__MapaFertilidade__Config[]>([]);

    //API
    const { callUsePostSalvarConfigBook, carregando_salvar } = usePostSalvarConfigBook(
        Number(localConfig().conta), 
        texto_introducao, 
        lista_elementos, 
        (()=>{}), //setAbrir_gaveta 
        (()=>{}), //refetch_data_elementos
        elementos_base
    );

    return (
        <ConfigBook>
            <PageContainer>

                <h1>Configuração do Book</h1>

                <Tabs
                    width={360}
                    tabs={[
                        { descricao: 'Texto introdução', icone: faTextWidth },
                        { descricao: 'Lista de elementos', icone: faListCheck }
                    ]}
                    selecionado={tab_container_atual}
                    indexInicial={0}
                    onChange={setTab_container_atual}
                />

                {tab_container_atual==='Texto introdução' && (
                    <TextoIntroducao 
                        texto_introducao={texto_introducao}
                        setTexto_introducao={setTexto_introducao}
                        callUsePostSalvarConfigBook={callUsePostSalvarConfigBook}
                        carregando_salvar={carregando_salvar}
                        setElementos_base={setElementos_base}
                        setLista_elementos={setLista_elementos}
                    />
                )}

                {tab_container_atual === 'Lista de elementos' && (
                    <ConfigElementos 
                        texto_introducao={texto_introducao}
                        setTexto_introducao={setTexto_introducao}
                        lista_elementos={lista_elementos}
                        setLista_elementos={setLista_elementos}
                        elementos_base={elementos_base}
                        setElementos_base={setElementos_base}
                    />
                )}


            </PageContainer>
        </ConfigBook>

    )
}

export default PageConfigBookNovo;