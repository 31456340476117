
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetServico {
  itens: [ServicoItens]
}

export interface ServicoItens {
  nome: string
  sigla: string
  status: 0 | 1
}

export const getServico = async (codServico) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetServico> = await api.get(
    `/servico?codConta=${config.conta}&codCliente=${config.codCliente}&cod=${codServico}`
  )

  return response
}

export const postServico = async (data) => {
  const config = localConfig()

  const response: AxiosResponse<TypeGetServico> = await api.post(
    `/servico?codConta=${config.conta}&codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const putServico = async (codServico, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeGetServico> = await api.put(
    `/servico/${codServico}?codConta=${config.conta}?codCliente=${config.codCliente}`,
    data
  )

  return response
}

export const delServico = async (codServico) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetServico> = await api.delete(
    `/servico/${codServico}?codConta=${config.conta}&codCliente=${config.codCliente}`
  )

  return response
}
