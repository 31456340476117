
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeGetAgrupamentoTalhao {
  itens: [AgrupamentoTalhaoItens]
}

export interface AgrupamentoTalhaoItens {
  area_total?: string
  cod?: number
  cod_cliente?: number
  cod_conta?: number
  cod_propriedade?: number
  cod_safra?: number
  delete_time?: number
  nome?: string
  status?: number
  talhao?: [TypeTalhaoAgrupamento]
}

export interface TypeTalhaoAgrupamento {
  cod?: number
  cod_talhao?: number
  cod_talhao_grupo?: number
  talhao_area?: string
  talhao_nome?: string
  talhao_kml?: {
    coordenadas?: {
      latitude?: string
      longitude?: string
    }[]
    lookAt?: {
      latitude?: number
      longitude?: number
    }
  }
}

interface TypeParamGet {
  codConta?: number | string
  codCliente?: number | string
  codCategoria?: number
  codSafra?: number | string
  cod?: number
  pagination?: number | -1
  embed?: string
  codPropriedade?: number
}

export const getAgrupamentoTalhao = async (params: TypeParamGet) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  params = {
    codConta: config.conta,
    codCliente: config.codCliente,
    codSafra: config.codSafra,
    embed: 'talhao',
    ...params,
  }

  const response: AxiosResponse<TypeGetAgrupamentoTalhao> = await api.get('/grupo-talhao', {
    params,
  })

  return response
}

export const postAgrupamentoTalhao = async (data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetAgrupamentoTalhao> = await api.post('/grupo-talhao', data, {
    params: {
      codConta: config.conta,
      codCliente: config.codCliente,
    },
  })

  return response
}

export const putAgrupamentoTalhao = async (cod, data) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetAgrupamentoTalhao> = await api.put(
    `/grupo-talhao/${cod}`,
    data,
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}

export const delAgrupamentoTalhao = async (cod) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeGetAgrupamentoTalhao> = await api.delete(
    `/grupo-talhao/${cod}`,
    {
      params: {
        codConta: config.conta,
        codCliente: config.codCliente,
      },
    }
  )

  return response
}
