import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesAgrupamentoPropriedade {
  itens: ItensAgrupamentoPropriedadeTypes[]
}

export interface ItensAgrupamentoPropriedadeTypes {
  cod: number
  cod_conta: number
  cod_cliente: number
  cod_safra: number
  nome: string
  status: number
  safra_nome: string
  propriedades: {
    cod_propriedade_grupo: number
    cod_propriedade: number
    propriedade_grupo_nome: string
    propriedade_nome: string
    cor: string
  }[]
  talhoes?: {
    area: string
    arquivo: string
    cod: number
    cod_conta: number
    cod_propriedade: number
    cod_safra: number
    cod_talhao: number
    kml?: {
      nome?: string
      lookAt?: {
        latitude?: string
        longitude?: string

        lat?: any
        lng?: any
      }
      coordenadas?: [
        {
          latitude?: any
          longitude?: any
        }
      ]
    }
    nome: string
    propriedade_nome: string
    status: number
  }[]
}

export interface ItensAgrupamentoProTypes {
  cod: number
  cod_conta: number
  cod_cliente: number
  cod_safra: number
  nome: string
  status: number
  safra_nome: string
  talhoes: {
    area: string
    arquivo: string
    cod: number
    cod_conta: number
    cod_propriedade: number
    cod_safra: number
    cod_talhao: number
    kml?: {
      nome?: string
      lookAt?: {
        latitude?: string
        longitude?: string

        lat?: any
        lng?: any
      }
      coordenadas?: [
        {
          latitude?: any
          longitude?: any
        }
      ]
    }
    nome: string
    propriedade_nome: string
    status: number
  }[]
}

type TypesQuery = {
  cod?: number
  embed?: string
}

const url = '/agrupamento-propriedade'

/* Requisição */
export const getAgrupamentoPropriedades = async (params: TypesQuery) => {
  const { conta, codCliente, codSafra } = localConfig()
  const resp: AxiosResponse<TypesAgrupamentoPropriedade> = await api.get(url, {
    params: {
      codSafra,
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}
export const getAgrupamentoPropriedadesBook = async (params: TypesQuery) => {
  const { conta, codCliente, codSafra } = localConfig()
  const resp: AxiosResponse<TypesAgrupamentoPropriedade> = await api.get(url, {
    params: {
      codSafra,
      codConta: conta,
      codCliente,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postAgrupamentoPropriedades = async (data) => {
  const { conta, codCliente, codSafra } = localConfig()
  const resp: AxiosResponse<TypesAgrupamentoPropriedade> = await api.post(url, data, {
    params: { codConta: conta, codCliente, codSafra },
  })

  return resp
}

export const putAgrupamentoPropriedades = async (data, cod: number) => {
  const { conta, codCliente } = localConfig()

  const resp: AxiosResponse<TypesAgrupamentoPropriedade> = await api.put(`${url}/${cod}`, data, {
    params: { codConta: conta, codCliente },
  })

  return resp
}

export const delAgrupamentoPropriedades = async (cod: number) => {
  const { conta, codCliente } = localConfig()

  const resp: AxiosResponse<TypesAgrupamentoPropriedade> = await api.delete(`${url}/${cod}`, {
    params: { codConta: conta, codCliente },
  })

  return resp
}
