import { UseMutationResult, useMutation } from 'react-query';
import { api } from '../../api'; 
import { message } from 'antd';
import { localConfig } from '../../../Configs/localConfig';

//=================================================================================>
// SALVAR PONTOS IMPORTADOS
//<=================================================================================

//TYPES
type Pontos = {
    cod_talhao: number;
    latitude: number;
    longitude: number;
    nome: string;
}

//INTERFACE
interface Params {
    pontos: Pontos[]
    refetchPontos: Function
}

export const usePostImportarPontos = (): {
    carregando_importar: boolean;
    mutate_importar: (params: Params) => void;
    isSuccess_importar: boolean
} => {

    
    const mutation: UseMutationResult<Response, Error, Params> = useMutation(
        async (params: Params) => {

            const { codCliente, conta } = localConfig()
            const { pontos, refetchPontos } = params;

            message.loading({ content: 'Importando...', key: 'loading_pontos', duration: 600000});

            if (pontos.length === 0) {
                console.error('Nenhum ponto foi informado ou são duplicados (provavelmente foi removido pelo filtro a fim de evitar duplicidade)');
                message.error({content:'Os pontos já foram adicionados ou não fazem parte da propriedade!', key: 'loading_pontos'});
                return null;
            }

            return api.post(`/monitoramento/padrao-ponto?codCliente=${codCliente}&codConta=${conta}`, {
                pontos: pontos,
            } )
                .then((response: any) => {
                    message.success({ content: `${pontos?.length} pontos importados com sucesso!`, key: 'loading_pontos' });
                    refetchPontos?.();
                    return response;
                })
                .catch((error: any) => {
                    // message.error({ content: (error?.response?.data?.error) ?? 'Falha salvar alteração!', duration: 10 });
                    if (pontos?.[0]?.cod_talhao === null || pontos?.[0]?.cod_talhao === undefined) {
                        message.error({content: 'KML incompatível', key: 'loading_pontos'});
                    }
                    else {
                        message.error({content: error?.response?.data?.error ?? 'Pontos repetidos', key: 'loading_pontos'});
                    }
                    throw error;
                });
        }
    );

    return { 
        carregando_importar: mutation.isLoading, 
        mutate_importar: mutation.mutate,
        isSuccess_importar: mutation.isSuccess
    };
};

//=================================================================================>
// REMOVER PONTOS IMPORTADOS
//<=================================================================================

//TYPES

//INTERFACE
interface ParamsRemover {
    pontos_remover: number[]
    refetchPontos: Function
}

export const usePostRemoverPontos = (): {
    carregando_remover_pontos: boolean;
    mutate_remover_pontos: (params: ParamsRemover) => void;
    isSuccess_remover_pontos: boolean
} => {

    
    const mutation: UseMutationResult<Response, Error, ParamsRemover> = useMutation(
        async (params: ParamsRemover) => {

            const { codCliente, conta } = localConfig()
            const { pontos_remover, refetchPontos } = params;

            message.loading({ content: 'Excluindo pontos...', key: 'loading_remove', duration: 600000 });

            return api.post(`/monitoramento/padrao-ponto?codCliente=${codCliente}&codConta=${conta}`, {
                pontos_remover: pontos_remover,
            } )
                .then((response: any) => {
                    message.success({ content: `${pontos_remover?.length} Pontos excluídos com sucesso!`, key: 'loading_remove' });
                    refetchPontos?.();
                    return response;
                })
                .catch((error: any) => {
                    // message.error({ content: 'Falha ao remover pontos', key: 'loading' });
                    message.error({ content: (error?.response?.data?.error) ?? 'Falha ao excluir pontos!', duration: 10, key: 'loading_remove' });
                    throw error;
                });
        }
    );

    return { 
        carregando_remover_pontos: mutation.isLoading, 
        mutate_remover_pontos: mutation.mutate,
        isSuccess_remover_pontos: mutation.isSuccess
    };
};