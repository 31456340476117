import { AxiosResponse } from 'axios'
import { localConfig } from '../../Configs/localConfig'
import { tryError } from '../Afins'
import { api } from '../api'

export interface TypesColetaTrajeto {
  itens: ItensColetaTrajetoTypes[]
}

export interface ItensColetaTrajetoTypes {
  cod_pedido?: number
  ponto?: number
  longitude?: string
  latitude?: string
  observacao?: string
  create_time?: string
  update_time?: string
  delete_time?: string
}

type TypesQuery = {
  embed?: string
  codPedido?: number
}

const url = '/coleta/trajeto'

export const getColetaTrajeto = async (params: TypesQuery) => {
  try {
    const { conta, codCliente } = localConfig()
    const resp: AxiosResponse<TypesColetaTrajeto> = await api.get(url, {
      params: {
        codConta: conta,
        codCliente,
        ...params,
        pagination: -1,
      },
    })

    return resp
  } catch (error) {
    tryError(error)
  }
}
