import moment from "moment";
import { useState } from "react";
import { DatePicker } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAt, faFloppyDisk, faGlobe, faXmark } from "@fortawesome/free-solid-svg-icons";

//COMPONENTS
import Input from "../../../../../../../Components/CustomUi/input";
import Label from "../../../../../../../Components/Label";
import ButtonCustom from "../../../../../../../Components/ButtonCustom";

//PROPS
interface Props {
    setEndpoint: React.Dispatch<React.SetStateAction<string>>;
    setUser_email: React.Dispatch<React.SetStateAction<string>>;
    setDate: React.Dispatch<React.SetStateAction<string>>;
    onClose: Function;
    refetch: Function;
}

export const FilterRequest: React.FC<Props> = ({ setEndpoint, setUser_email, setDate, onClose, refetch }) => {

    //STATES
    const [pre_endpoint, setPre_endpoint] = useState<string>('');
    const [pre_user_email, setPre_user_email] = useState<string>('');
    const [pre_date, setPre_date] = useState<string>('');
    const [loading_filter, setLoading_filter] = useState<boolean>(false);
    
    function applyFilter() {
        if (pre_endpoint!=='') {
            setEndpoint(pre_endpoint);
        }
        else setEndpoint(null);
        
        if (pre_user_email!=='') {
            setUser_email(pre_user_email);
        }
        else setUser_email(null);
        
        if (pre_date!==''&& pre_date!=='Invalid date') {
            setDate(pre_date);
        }
        else setDate(null);

        
        setTimeout(() => {
            finishApply();
        }, 500);

        return null;
    }

    function finishApply() {
        refetch();
        onClose();
        setLoading_filter(false);
    }

    // console.log("pre_date: ", pre_date)

    return (
        <div style={{display:'flex',flexDirection:'column',gap:15}}>

            <Input 
                label="Filtrar por endpoint"
                placeholder="filtrar por endpoind"
                value={pre_endpoint}
                onChange={setPre_endpoint}
                icon={faGlobe}
            />

            <Input 
                label="Filtrar por email do usuário"
                placeholder="Filtrar por email do usuário"
                value={pre_user_email}
                onChange={setPre_user_email}
                type="email"
                icon={faAt}
            />

            <div>
                <Label>Filtrar por data</Label>
                <DatePicker 
                    onChange={(data)=>setPre_date(moment(String(data)).format('yyyy-MM-DD'))}
                /> 
            </div>
            
            <div style={{width:'100%',height:45,borderTop:'thin solid var(--border-color)',display:'flex',gap:15,justifyContent:'flex-end',alignItems:'flex-end'}}>
                <ButtonCustom 
                    onClick={()=>onClose()}
                    icon={<FontAwesomeIcon icon={faXmark} />}
                    type="tertiary"
                    danger
                >
                    Cencelar
                </ButtonCustom>

                <ButtonCustom 
                    onClick={()=>{setLoading_filter(true);applyFilter()}}
                    icon={<FontAwesomeIcon icon={faFloppyDisk} />}
                    loading={loading_filter}
                    disabled={loading_filter}
                >
                    Aplicar filtros
                </ButtonCustom>
            </div>

        </div>
    )
}