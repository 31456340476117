import React from 'react'

import logo from '../../assets/img/logo.png'
import { Container } from './styles'

interface WaterMarkerProps {
  placement:
    | 'bottom'
    | 'top'
    | 'right'
    | 'left'
    | 'bottomLeft'
    | 'bottomRight'
    | 'topLeft'
    | 'topRight'

  hidden?: boolean
  size?: number
}

const WaterMarker: React.FC<WaterMarkerProps> = ({ placement, hidden = false, size = 5 }) => {
  return (
    <Container size={size} hidden={hidden} style={{ zIndex: 999 }} placement={placement}>
      <img src={logo} alt='Logo TTivos' />
    </Container>
  )
}

export default WaterMarker
