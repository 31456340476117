/* eslint-disable array-callback-return */
/* eslint-disable react/require-default-props */
import React, { useEffect, useState } from 'react';
import { kml } from '@tmcw/togeojson';
import { orderBy } from 'lodash'
import { AxiosResponse } from 'axios';
import { UploadChangeParam } from 'antd/lib/upload/interface';
import { GoogleMap, Marker, Polygon, Polyline } from '@react-google-maps/api';
import { Drawer, Form, message, Modal, Progress } from 'antd';

//STYLES
import { Container } from './styles';

//APP
import { gerarKey } from '../../../utils';
import { PainelPontosPadrao } from './components/painel';
// import { ItensPerUserLogged } from '../../../services/login/permissaoUsuarioLoado';
import { postPadraoPontos, putPadraoPontos } from '../../../services/monitoramento/padraoDePontos';
import { getSubArea, SubAreaItens, TypeSubArea } from '../../../services/subArea';
import { getPropriedade, PropriedadeItens, TypeGetPropriedade } from '../../../services/Propriedades';
import { getTalhaoCultivavel, TalhaoCultivaveisItens, TypeGetTalhao } from '../../../services/TalhaoSafra';
import { fileTypeText, getBounds, getBoundsNoArray, parseXml, tryError } from '../../../services/Afins';
import { AgrupamentoTalhaoItens, getAgrupamentoTalhao, TypeGetAgrupamentoTalhao } from '../../../services/agrupamentoTalhao';

//ASSETS
import WaterMarker from '../../WaterMarker';

//INTERFACES
interface NovoMonitoramentoProps {
  visible?: boolean
  onClose?: (e: boolean) => void
  // update?: number
  // permissions: ItensPerUserLogged
  onListPropriedade?: (data: PropriedadeItens[]) => void
  handleDefaultPoint: Function;
}
interface ListLinhasProps {
  ativo: boolean
  coord: [[google.maps.LatLngAltitudeLiteral]]
  fill: string
  name: string
  
}

//CONST
const svgCoordPropiedade = (data) => {
  const dados = data
  let talhoes = [];

    for (const talhao of dados.talhao) {
      let temp = []
      for (const coord of (talhao.talhao_kml.coordenadas)) {
        temp = [...temp, coord]
      }
      talhoes = [...talhoes, temp]
    }
  return talhoes;
}

const calcPorcentagem = (qtda, total) => {
  if (qtda === 1) {
    return 100
  }
  return parseFloat(((qtda * 100) / total).toFixed(0))
}

const NovoMonitoramento: React.FC<NovoMonitoramentoProps> = ({
  visible = false,
  onClose,
  onListPropriedade,
  handleDefaultPoint
}) => {

  const [form] = Form.useForm()
  const [tipoMonitoramento, setTipoMonitoramento] = useState(null)
  const [propriedades, setPropriedades] = useState<PropriedadeItens[]>([])
  const [talhoes, setTalhoes] = useState<TalhaoCultivaveisItens[]>([])
  const [visibleLinhas, setVisibleLinhas] = useState(false)
  const [markerDragStart, setMarkerDragStart] = useState<google.maps.LatLng>()

  const [progressModal, setProgressModal] = useState(false)

  const [postProgress, setPostProgress] = useState(0)

  const [cod_propriedade_atual, setCod_propriedade_atual] = useState<number|null>(null);
  const [cod_subarea_atual, setCod_subarea_atual] = useState<number|null>(null);

  const [listLinhas, setListLinhas] = useState<ListLinhasProps>({
    name: '',
    ativo: true,
    fill: '#d0ff00',
    coord: [] as any,
  } as ListLinhasProps)

  const [subarea, setSubarea] = useState<SubAreaItens[]>([])
  const [agrupamento, setAgrupamento] = useState<AgrupamentoTalhaoItens[]>([])
  const [markerDefault, setMarkerDefault] = useState<
    {
      cod?: number
      nome?: string
      cod_talhao: number
      latitude: number
      longitude: number
    }[]
  >([])

  const [map, setMap] = useState<google.maps.Map>({} as google.maps.Map)
  const [polygonTalhao, setPolygonTalhao] = useState<google.maps.Polygon>({} as google.maps.Polygon)
  const [polygonAgrupamento, setPolygonAgrupamento] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )
  const [polygonPropriedade, setPolygonPropriedade] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )

  const [_, setRender] = useState<any>()
  const [polygonSubArea, setPolygonSubArea] = useState<google.maps.Polygon>(
    {} as google.maps.Polygon
  )
  const [marker, setMarker] = useState<google.maps.Marker[]>([])

  const [getCenter, setGetCenter] = useState<google.maps.LatLng | google.maps.LatLngLiteral>({
    lat: -18.4590515,
    lng: -64.3517889,
  })
  const [getZoom, setGetZoom] = useState<number>(4)


  const handleCloseDrawer = () => {
    onClose(false)
    polygonPropriedade.setPaths([])
    polygonTalhao.setPath([])
    polygonAgrupamento.setPaths([])
    polygonSubArea.setPath([])

    setMarkerDefault([])
    setMarker([])

    map.setCenter({ lat: 0, lng: 0 })
    map.setZoom(3)
    form.resetFields()
  }

  const listPropriedade = async () => {
    // if (propriedades.length <= 0) {
      message.loading({content: 'Carregando lista de propriedades...', duration: 99999, key:'loading_list_props'})
      const resp: AxiosResponse<TypeGetPropriedade> = await getPropriedade('', 'talhao')

      setPropriedades(resp.data.itens)
      onListPropriedade(resp.data.itens)
      message.destroy()
    // }
  }

  const listTalhao = async (value) => {
    
    setCod_propriedade_atual(value);
    
    message.loading({content: 'Carregando pontos...', duration: 99999, key: 'loading_pontos'})
    const resp: AxiosResponse<TypeGetTalhao> = await getTalhaoCultivavel({
      codPropriedade: value,
      embed: 'padrao_pontos',
    })
    setTalhoes(resp.data.itens)

    message.destroy()
    return resp.data.itens
  }

  const listAgrupamento = async (value) => {
    message.loading({content: 'Validando coordenadas...', duration: 99999, key: 'loading_coordenadas'})
    const resp: AxiosResponse<TypeGetAgrupamentoTalhao> = await getAgrupamentoTalhao({
      embed: 'talhao',
      codPropriedade: value,
      pagination: -1,
    })
    setAgrupamento(resp.data.itens)
    message.destroy()
  }

  const handleChangePropriedade = async (data) => {
  
    const talhoes = await listTalhao(data.value)

    const coord = talhoes.map((item) => {
      return item.kml.coordenadas.map((talhao) => {
        return { lat: talhao.latitude, lng: talhao.longitude }
      })
    })
    map.fitBounds(getBounds(coord), { left: 120 })
    polygonPropriedade?.setPaths(coord)

    if (tipoMonitoramento === 1) {
      handleLoadMarkerEdit(talhoes)
    }

    listAgrupamento(data.value)
  }

  const handleChangeTalhao = async (data) => {
    message.loading('Carregando talhão...', 99999)

    const resp: AxiosResponse<TypeGetTalhao> = await getTalhaoCultivavel({
      cod: data.value,
      embed: 'sub_area,padrao_pontos',
    })

    const dados = resp.data.itens[0]
    const coord = dados.kml.coordenadas.map((item) => {
      return { lat: item.latitude, lng: item.longitude }
    })

    if (tipoMonitoramento === 3) {
      handleLoadMarkerEdit(resp.data.itens)
    }

    if (tipoMonitoramento === 4) {
      form.resetFields(['cod_subarea'])
      setCod_subarea_atual(null);
      polygonSubArea.setPath([])
    }


    polygonTalhao.setPath(coord)
    setSubarea(dados.sub_area)

    map.fitBounds(getBoundsNoArray(coord), { left: 320 })
    message.destroy()
  }

  const handleChangeAgrupamento = async (value) => {

    message.loading({content: 'carregando agrupamento...', duration: 99999, key: 'loading_agrupamento'})

    const talhoes_agrup = await listTalhao(cod_propriedade_atual);

    const resp: AxiosResponse<TypeGetAgrupamentoTalhao> = await getAgrupamentoTalhao({
      embed: 'talhao,padrao_pontos',
      cod: value,
      pagination: -1,
    })
    
    const dados: AgrupamentoTalhaoItens['talhao'] = resp.data.itens[0].talhao
    const coord = dados.map((item) => {
      return item.talhao_kml.coordenadas.map((item) => {
        return { lat: item.latitude, lng: item.longitude }
      })
    })

    const cods = dados.map((item) => item.cod_talhao)

    const data = talhoes_agrup.filter((item) => {
      for (const cod of cods) {
        if (cod === item.cod) {
          return true
        }
      }
      return false
    })

    handleLoadMarkerEdit(data)

    polygonAgrupamento.setPaths(coord)
    map.fitBounds(getBounds(coord), { left: 120 })
    message.destroy();
  }

  const handleChangeSubarea = async (value) => {

    message.loading({content: 'carregando subárea...', duration: 99999, key: 'loading_subarea'})

    const resp: AxiosResponse<TypeSubArea> = await getSubArea({
      cod: value,
      embed: 'padrao_pontos',
    })

    handleLoadMarkerEdit(resp.data.itens)

    // console.log("value: ", value)

    const dados = subarea.find((item) => item.cod === value)
    const coord = (dados?.kml?.coordenadas ?? []).map((item) => {
      return { lat: item.latitude, lng: item.longitude }
    })
    polygonSubArea.setPath(coord)
    map.fitBounds(getBoundsNoArray(coord), { left: 120 })
    message.destroy()
    setRender([])
  }

  const handleLoadMarkerEdit = (dataTalhoes) => {
    let temp = []

    for (const t of dataTalhoes) {
      temp = [
        ...temp,
        ...t.padrao_pontos.map((p) => {
          return {
            cod: p.cod,
            latitude: Number(p.latitude),
            longitude: Number(p.longitude),
            nome: p.nome,
            cod_talhao: p.cod_talhao
          }
        }),
      ]
    }
    
    setMarkerDefault(JSON.parse(JSON.stringify(temp)))
  }

  const handleSetMarker = async (
    e: google.maps.MapMouseEvent,
    eAR: google.maps.LatLngLiteral[] = []
  ) => {

    // console.log("MapMouseEvent: ", e);
    // console.log("LatLngLiteral: ", eAR);

    try {
      if (eAR.length > 0) {
        for (let i = 0; i < eAR.length; i += 1) {
          const obj = eAR[i]
          const pontos = checkMarkerTalhao(new google.maps.LatLng({ lat: obj.lat, lng: obj.lng }))


          const resp = await postPadraoPontos(pontos)
          setProgressModal(true)
          setPostProgress(calcPorcentagem(i + 1, eAR.length))

          if (i + 1 === eAR.length) {
            setProgressModal(false)
            message.success(`${eAR.length} importados com sucesso`)
          }
        }
      } else {
        message.loading('Inserindo...', 99999)
        const markerTalhao = checkMarkerTalhao(e.latLng)

        const resp = await postPadraoPontos(markerTalhao)

        if (resp.status === 201) {
          const codReturn = resp.data.itens[0].cod
          markerTalhao.pontos[0].cod = codReturn
          setMarkerDefault([
            ...markerDefault,
            JSON.parse(JSON.stringify(markerTalhao.pontos[0])) as any,
          ])
        }
        handleDefaultPoint();
        message.destroy()
        message.success('Ponto de coleta inserido com sucesso')
      }
    } catch (error) {
      tryError(error)
    }
  }
  const checkMarkerTalhao: any = (coord: google.maps.LatLng) => {
    try {
      const newPolygon = new google.maps.Polygon({
        fillColor: 'none',
        strokeColor: 'none',
      })
      const talhaoMarker = talhoes.map((talhao) => {
        return {
          nome: talhao.nome,
          cod_talhao: talhao.cod,
          coord: talhao.kml.coordenadas.map((kml) => {
            return { lat: kml.latitude, lng: kml.longitude }
          }),
        }
      })

      const dataTalhaoMarker = talhaoMarker.filter((item) => {
        newPolygon.setPath(item.coord)
        if (google.maps.geometry.poly.containsLocation(coord, newPolygon)) {
          return {
            cod_talhao: item.cod_talhao,
            latitude: coord.lat.toString(),
            longitude: coord.lng.toString(),
          }
        }
      })

      if (talhaoMarker.length > 0) {
        let typePost
        if (tipoMonitoramento === 4) {
          typePost = { cod_talhao: cod_subarea_atual }
          // typePost = { cod_talhao: form.getFieldValue(['cod_subarea']) }
        } else {
          typePost = { cod_talhao: dataTalhaoMarker[0].cod_talhao }
        }
        const nameCodMarker = orderBy(
          markerDefault.map((m) => {
            return parseInt(m.nome.split('|')[1])
          })
        )

        

        function isNanOrNomeCod() {
            const isNan = Number.isNaN(nameCodMarker[nameCodMarker.length - 1]);

            if (isNan) {
                return 1;
            }
            return nameCodMarker[nameCodMarker.length - 1] + 1;
        }

        const newMarker = nameCodMarker.length <= 0 ? 1 : isNanOrNomeCod()

        return {
          pontos: [
            {
              nome: `${dataTalhaoMarker[0].nome} | ${newMarker}`,
              ...typePost,
              latitude: coord.lat(),
              longitude: coord.lng(),
            },
          ],
        }
      }
      return false
    } catch (error) {
      tryError(error)
    }
  }

  const handleMoveMarker = async (e: google.maps.MapMouseEvent, index: number) => {
    let polygon: google.maps.Polygon
    let polygonPro = false
    switch (tipoMonitoramento) {
      case 1:
        polygon = polygonPropriedade
        polygonPro = true
        break
      case 2:
        polygon = polygonAgrupamento
        break
      case 3:
        polygon = polygonTalhao
        break
      case 4:
        polygon = polygonSubArea
        break

      default:
        break
    }
    if (google.maps.geometry.poly.containsLocation(e.latLng, polygon)) {
      try {
        message.loading('Movendo ponto...')

        // markerDefault.forEach( async (rec) => {
        //   const inside = google.maps.geometry.poly.containsLocation(
        //     { lat: rec.latitude, lng: rec.longitude },
        //     polygon
        //   )
        //   if (!inside) {
        //     await postPadraoPontos({ pontos_remover: [rec.cod] })
        //   }
        // })

        if (polygonPro) {
          const polyTemp = new google.maps.Polygon()

          let indexPoly = null
          polygon.getPaths().forEach((poly, i) => {
            polyTemp.setPath(poly.getArray())
            const inside = google.maps.geometry.poly.containsLocation(e.latLng, polyTemp)

            if (inside) {
              indexPoly = i
            }

            return indexPoly
          })

          polyTemp.setPath(polygon.getPaths().getAt(indexPoly).getArray())

          // console.log("markerDragStart: ", markerDragStart);
          // console.log("polyTemp: ", polyTemp);

          const inside = google.maps.geometry.poly.containsLocation(markerDragStart, polyTemp)

          if (!inside) {
            marker[index].setPosition(markerDragStart)
            message.destroy()
            message.warning('O ponto de monitoramento fora do talhão')

            throw new Error('ponto fora do talhão')
          }
        }

        markerDefault[index].latitude = e.latLng.lat()
        markerDefault[index].longitude = e.latLng.lng()
        const newCoord = markerDefault[index]

        const resp = await putPadraoPontos({ pontos: [newCoord] }, newCoord.cod)

        if (resp.status === 200) {
          setMarkerDefault(
            markerDefault.map((item, i) => {
              if (index === i) {
                item.latitude = e.latLng.lat()
                item.longitude = e.latLng.lng()
              }
              return item
            })
          )
        }
        message.destroy()
        message.success('Ponto realocado')
      } catch (error) {
        tryError(error)
      }
    } else {
      marker[index].setPosition({
        lng: markerDefault[index].longitude,
        lat: markerDefault[index].latitude,
      })
      message.warning('O ponto de monitoramento fora do talhão')
    }
  }

  const handleRemoveMarker = async (e: google.maps.MapMouseEvent, index: number) => {
    try {
      message.loading('Excluíndo ponto...', 99999)
      const resp = await postPadraoPontos({ pontos_remover: [markerDefault[index].cod] })
      if (resp.status === 201) {
        setMarkerDefault(markerDefault.filter((item, i) => index !== i))
      }
      handleChangePropriedade({value: cod_propriedade_atual})
      handleDefaultPoint();
      message.destroy()
      message.success('Ponto excluído com suceesso')
    } catch (error) {
      tryError(error)
    }
  }

  const handleClickRow = (index: number) => {
    for (let i = 0; i < marker.length; i += 1) {
      const pin = marker[i]
      if (index === i) {
        pin.setAnimation(google.maps.Animation.BOUNCE)
        setTimeout(() => {
          pin.setAnimation(null)
        }, 500)
      } else {
        pin.setAnimation(null)
      }
    }
  }

  let tempMarker: google.maps.Marker[] = []
  const handleOnloadMarker = (e: google.maps.Marker, i) => {
    if (i === 0) {
      tempMarker = []
    }
    tempMarker = [...tempMarker, e]
    if (markerDefault.length === i + 1) {
      setMarker(tempMarker)
    }
  }

  const handleChangeEditName = async (e: string, record: any) => {

    try {
      message.loading('Aguarde...5555', 99999)
      const resp = await putPadraoPontos({ pontos: [{ ...record, nome: e }] }, record.cod)

      if (resp.status === 200) {
        setMarkerDefault(
          markerDefault.map((item) => {
            if (record.cod === item.cod) {
              item.nome = e
            }
            return item
          })
        )
      }
      message.destroy()
      message.success('Nome do ponto alterado')
    } catch (error) {
      tryError(error)
    }
  }

  const handleUploadKml = async (
    file: UploadChangeParam,
    type: 'linha' | 'pTalhao' | 'pSubArea'
  ) => {
    if (file.file.status === 'done') {
      const domKml = parseXml(await fileTypeText(file.file.originFileObj))
      const dataGeoJson = kml(domKml)

      const newArray = {
        ...listLinhas,
        name: '',
        ativo: true,
        coord: [] as any,
      }

      let newPontos: {
        cod: any
        latitude: any
        longitude: any
        nome: string
        cod_talhao: number
      }[] = []
      let pontosFora = 0

      switch (type) {
        case 'linha':
          dataGeoJson.features.forEach((data) => {
            if (data.geometry.type === 'LineString' || data.geometry.type === 'Polyline') {
              let newLatLng = []
              data.geometry.coordinates.forEach((latLng) => {
                newLatLng = [...newLatLng, { lat: latLng[1], lng: latLng[0] }]
                return { newLatLng }
              })
              const temp = newLatLng

              newArray.coord = [...newArray.coord, temp]
            }

            return newArray
          })
          setListLinhas(newArray)
          break
        case 'pTalhao':
          dataGeoJson.features.forEach((item) => {
            if (item.geometry.type === 'Point') {
              const latLng = {
                lat: Number(item.geometry.coordinates[1]),
                lng: Number(item.geometry.coordinates[0]),
              }
              if (google.maps.geometry.poly.containsLocation(latLng, polygonTalhao)) {
                newPontos = [
                  ...newPontos,
                  {
                    cod: null,
                    latitude: item.geometry.coordinates[1],
                    longitude: item.geometry.coordinates[0],
                    nome: item.properties?.name,
                    cod_talhao: form.getFieldValue('cod_talhao'),
                  },
                ]
              } else {
                pontosFora += 1
              }
            }
            return { newPontos, pontosFora }
          })

          await handleSetMarker(
            _,
            newPontos.map((item) => {
              return { lat: item.latitude, lng: item.longitude }
            })
          )

          if (pontosFora > 0) {
            message.destroy()
            message.warning(`${pontosFora} pontos estão fora do talhão`)
          }
          setMarkerDefault([...markerDefault, ...newPontos])
          break
        case 'pSubArea':
          break

        default:
          break
      }
    }
  }

  const dummyRequest = (options) => {
    const { onSuccess } = options
    setTimeout(() => {
      onSuccess('done')
    }, 500)
  }

  useEffect(() => {
    const codPropriedade = cod_propriedade_atual;
    // const codPropriedade = form.getFieldValue('cod_propriedade');
    switch (tipoMonitoramento) {

      case 1: // propriedade
        polygonPropriedade.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })
        if (codPropriedade !== undefined) {
          map.fitBounds(getBounds(polygonPropriedade.getPaths().getArray()), { left: 120 })
        }

        polygonPropriedade.setOptions({ clickable: true })
        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: false })
        polygonSubArea.setOptions({ clickable: false })

        polygonTalhao.setPath([])
        polygonSubArea.setPath([])
        polygonAgrupamento.setPaths([])

        // form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao', 'cod_propriedade'])
        setMarkerDefault([])

        break

      case 2: // agrupamento
        polygonPropriedade.setOptions({
          fillColor: 'white',
          strokeColor: 'white',
          clickable: false,
        })
        polygonAgrupamento.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })

        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: true })
        polygonSubArea.setOptions({ clickable: false })

        polygonTalhao.setPath([])
        polygonSubArea.setPath([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao'])
        setMarkerDefault([])

        break

      case 3: // talhao
        polygonPropriedade.setOptions({ fillColor: 'white', strokeColor: 'white' })
        polygonTalhao.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })

        polygonPropriedade.setOptions({ clickable: false })
        polygonTalhao.setOptions({ clickable: true })
        polygonAgrupamento.setOptions({ clickable: false })
        polygonSubArea.setOptions({ clickable: false })

        polygonAgrupamento.setPaths([])
        polygonSubArea.setPath([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao'])
        setMarkerDefault([])

        break

      case 4: // subarea
        polygonPropriedade.setOptions({ fillColor: 'white', strokeColor: 'white' })
        polygonTalhao.setOptions({ fillColor: 'cyan', strokeColor: 'cyan' })
        polygonSubArea.setOptions({ fillColor: 'orange', strokeColor: 'orange' })

        polygonPropriedade.setOptions({ clickable: false })
        polygonTalhao.setOptions({ clickable: false })
        polygonAgrupamento.setOptions({ clickable: false })
        polygonSubArea.setOptions({ clickable: true })

        polygonAgrupamento.setPaths([])
        polygonTalhao.setPath([])

        form.resetFields(['cod_subarea', 'cod_agrupamento', 'cod_talhao'])
        setMarkerDefault([])

        break

      default:
        break
    }
  }, [tipoMonitoramento])

  useEffect(() => {
    listPropriedade()
  }, []);
  

  return (
    <Form form={form} size='middle' layout='vertical' wrapperCol={{ span: 24 }}>
      <Drawer
        open={visible}
        closeIcon={false}
        onClose={handleCloseDrawer}
        placement='bottom'
        headerStyle={{ display: 'none' }}
        width='100%'
        contentWrapperStyle={{ minHeight: '100%' }}
        bodyStyle={{ overflow: 'hidden', padding: 0 }}
      >
        <Container>
          {/* @ts-ignore */}
          <GoogleMap
            onLoad={setMap}
            mapContainerStyle={{
              width: '100%',
              height: '100vh',
            }}
            options={{
              mapTypeId: 'hybrid',
              mapTypeControl: false,
              streetViewControl: false,
              fullscreenControl: false,
              zoomControl: false,
            }}
            center={getCenter}
            zoom={getZoom}
          >
            <Polygon
              onClick={handleSetMarker}
              options={{ zIndex: 9 }}
              onLoad={setPolygonPropriedade}
            />

            <Polygon onClick={handleSetMarker} options={{ zIndex: 10 }} onLoad={setPolygonTalhao} />
            <Polygon
              onClick={handleSetMarker}
              options={{ zIndex: 20 }}
              onLoad={setPolygonAgrupamento}
            />
            <Polygon
              onClick={handleSetMarker}
              options={{ zIndex: 30 }}
              onLoad={setPolygonSubArea}
            />
            {listLinhas?.coord.map((item, i) => (
              <Polyline
                key={gerarKey(i)}
                onClick={handleSetMarker}
                visible={visibleLinhas}
                path={item}
                options={{ strokeColor: listLinhas.fill, strokeWeight: 1, zIndex: 40 }}
              />
            ))}

            {markerDefault.map((item, i) => (
              // @ts-ignore
              <Marker
                key={gerarKey(i)}
                onLoad={(e) => handleOnloadMarker(e, i)}
                draggable
                onDragEnd={(e) => handleMoveMarker(e, i)}
                onDragStart={(e) => setMarkerDragStart(e.latLng)}
                onRightClick={(e) => handleRemoveMarker(e, i)}
                position={{ lat: item.latitude, lng: item.longitude }}
              />
            ))}

            <PainelPontosPadrao 
              visible={visible}
              handleCloseDrawer={handleCloseDrawer}
              list_propriedades={propriedades}
              handleChangePropriedade={handleChangePropriedade}
              setTipoMonitoramento={setTipoMonitoramento}
              tipoMonitoramento={tipoMonitoramento}
              setCod_propriedade_atual={setCod_propriedade_atual}

              list_talhoes={talhoes}
              handleChangeTalhao={handleChangeTalhao}

              list_Agrupamentos={agrupamento}
              handleChangeAgrupamento={handleChangeAgrupamento}

              list_subarea={subarea}
              handleChangeSubarea={handleChangeSubarea}
              setCod_subarea_atual={setCod_subarea_atual}

              markerDefault={markerDefault}
              visibleLinhas={visibleLinhas}
              setVisibleLinhas={setVisibleLinhas}

              dummyRequest={dummyRequest}
              handleUploadKml={handleUploadKml}
              handleChangeEditName={handleChangeEditName}
              handleClickRow={handleClickRow}

              // polygonPropriedade={polygonPropriedade}
              polygonSubArea={polygonSubArea}
              polygonAgrupamento={polygonAgrupamento}

              setMarkerDefault={setMarkerDefault}
              atualizarListaPropriedades={handleDefaultPoint}
            />

            <WaterMarker placement='bottomRight' />
          </GoogleMap>
        </Container>
      </Drawer>
      <Modal
        title='Aguarde... importando pontos...'
        footer={false}
        closeIcon={false}
        closable={false}
        width='387px'
        style={{ top: '10px' }}
        open={progressModal}
      >
        <h5>
          <Progress percent={postProgress} steps={19} strokeColor='#87d068' />
        </h5>
      </Modal>
    </Form>
  )
}

export default NovoMonitoramento