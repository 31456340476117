/* eslint-disable react/require-default-props */
import dayjs from 'dayjs'
import React, { ReactNode } from 'react'
import { Container, ContainerItem } from './styled'

interface DescriptionProps {
  date: ReactNode[] | string[]
  hidden?: boolean
}

interface DescriptionItemProps {
  data: ReactNode[]
  title: ReactNode | string
}

export const Description: React.FC<DescriptionProps> = (props) => {
  const { children, date, hidden = false } = props
  return (
    <Container hidden={hidden}>
      <thead>
        <tr>
          <th
            style={{
              position: 'fixed',
              backgroundColor: '#ffffff',
              width: '200px',
              zIndex: 9999,
              color: '#ffffff',
            }}
          >
            .
          </th>
          {date.map((rec) => (
            <th className='th-date'>{dayjs(rec).format('DD/MM/YYYY')}</th>
          ))}
        </tr>
      </thead>
      <tbody>{children}</tbody>
    </Container>
  )
}

export const DescriptionItem: React.FC<DescriptionItemProps> = (props) => {
  const { data = [], title } = props
  return (
    <ContainerItem>
      <th>{title}</th>
      {data.map((rec) => (
        <td>{rec}</td>
      ))}
    </ContainerItem>
  )
}
