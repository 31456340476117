import { Select, Space, Transfer } from 'antd'
import { TransferDirection } from 'antd/lib/transfer'
import { AxiosResponse } from 'axios'
import { orderBy } from 'lodash'
import React, { useEffect, useState } from 'react'
import ViewPage from '../../../../Components/ViewPage'
import { useLogic } from '../../../../context/useLogic'
import { EquipeItens, TypesEquipe, getEquipe } from '../../../../services/Equipe'
import {
  TypesTipoEquipeConsultorGerenciar,
  delTipoEquipeConsultorGerenciar,
  getTipoEquipeConsultorGerenciar,
  postTipoEquipeConsultorGerenciar,
} from '../../../../services/consultoriaAgricola/equipConsultorGerenciar'
import {
  TypesTipoConsultor,
  getTipoConsultor,
} from '../../../../services/consultoriaAgricola/tipoConsultor'
import { ItensPerUserLogged } from '../../../../services/login/permissaoUsuarioLoado'

import { Container } from './styles'

const EquipeConsultor: React.FC = () => {
  const { itens, responsive } = useLogic()
  const [_visibleDrawer, setVisibleDrawer] = useState(false)
  const [loading, setLoading] = useState(false)
  const [dataTechnician, setDataTechnician] = useState<EquipeItens[]>([])
  const [dataConsultores, setDataConsultores] = useState<any[]>([])
  const [dataAt, setDataAt] = useState<any[]>([])
  const [valueAt, setValueAt] = useState(null)
  const [lisTechnician, setLisTechnician] = useState([])
  const [removeTechnician, setRemoveTechnician] = useState([])
  const [consultorSelect, setConsultorSelect] = useState<number>()
  const [permissions, setPermissions] = useState<ItensPerUserLogged>({} as ItensPerUserLogged)
  const [consultor, setConsultor] = useState<boolean>(true)

  const listConsultores = async () => {
    setLoading(true)
    const resp: AxiosResponse<TypesTipoConsultor> = await getTipoConsultor({})
    const dados = resp.data.itens.map((item) => {
      return { label: item.usuario_nome, value: item.cod_usuario }
    })
    const isConsultor = dados.filter((item) => item.value === itens[0].cod)
    if (permissions?.acesso === 1 && isConsultor.length > 0) {
      listTechnicianAvailable(itens[0].cod)
      setConsultor(true)
    } else {
      setDataConsultores(dados)
      setConsultor(false)
    }
    setLoading(false)
  }

  const listAt = (at, codConsultor) => {
    let dados = []
    for (let i = 0; i < at.length; i += 1) {
      const item = at[i]

      if (item.cod_consultor === null) {
        dados = [
          ...dados,
          {
            label: `${item.usuario_nome}`,
            value: item.cod_func,
          },
        ]
      } else if (item.cod_consultor === codConsultor) {
        dados = [
          ...dados,
          {
            label: `${item.usuario_nome}`,
            value: item.cod_func,
          },
        ]
      }
    }
    setLoading(false)
    return dados
  }

  const listTechnicianAvailable = async (cod) => {
    setLoading(true)
    setValueAt(null)

    setConsultorSelect(cod)
    const resp: AxiosResponse<TypesEquipe> = await getEquipe({
      assistenteTecnicoPermitidoConsultor: cod,
      embed: 'tipo_asistente_tecnico',
      pagination: -1,
    })

    const { itens } = resp.data

    const at = itens.filter((item) => item.assistente_tecnico)
    const tec = itens
    const respGe: AxiosResponse<TypesTipoEquipeConsultorGerenciar> =
      await getTipoEquipeConsultorGerenciar({ codConsultor: cod })

    const dados = respGe.data.itens

    setDataAt(listAt(at, cod))

    setDataTechnician(tec)
    setLisTechnician(dados.map((item) => String(item.cod_func)))
    setLoading(false)
  }

  const handleChangeMoveTransfer = async (
    targetKeys: string[],
    direction: TransferDirection,
    moveKeys: string[]
  ) => {
    setLoading(true)
    if (direction === 'right') {
      const keys = [...removeTechnician, ...moveKeys]

      const resp = await postTipoEquipeConsultorGerenciar({
        cod_consultor: consultorSelect,
        assistente_tecnico: moveKeys,
      })
      if (resp.data.itens.length > 0) {
        setRemoveTechnician(keys)
        setLisTechnician(targetKeys)
      }
      setLoading(false)
    } else {
      for (let i = 0; i < moveKeys.length; i += 1) {
        const cod = moveKeys[i]
        await delTipoEquipeConsultorGerenciar(consultorSelect, cod)
      }
      setRemoveTechnician(removeTechnician.filter((item) => item !== moveKeys[0]))
      setLisTechnician(targetKeys)
      setLoading(false)
    }
  }

  const filterOption = (inputValue: string, option: any) =>
    option.usuario_nome.toLowerCase().indexOf(inputValue.toLowerCase()) > -1

  const handleClickNew = () => {
    setVisibleDrawer(true)
  }

  useEffect(() => {
    listConsultores()
  }, [permissions])

  return (
    <Container>
      <ViewPage
        newHide
        rotina={69}
        onPermission={setPermissions}
        title='Equipe do Consultor'
        btnClick={() => handleClickNew()}
        btnsExta={
          <div style={{ minWidth: '160px' }}>
            {consultor ? (
              <b>{itens[0].nome.toUpperCase()}</b>
            ) : (
              <Space>
                <Select
                  placeholder='Selecione um consultor para iniciar'
                  style={{ minWidth: '160px' }}
                  popupMatchSelectWidth={false}
                  onChange={(e) => {
                    listTechnicianAvailable(e)
                  }}
                  showSearch
                  optionFilterProp='label'
                  options={orderBy(dataConsultores, 'label')}
                />
              </Space>
            )}
          </div>
        }
      >
        <Transfer
          className={responsive && 'transfer-responsive'}
          showSearch
          filterOption={filterOption}
          disabled={loading}
          rowKey={(e) => String(e.cod_func)}
          selectAllLabels={[<b>Assistente técnicos</b>, <b>Assistente técnicos vinculados</b>]}
          targetKeys={lisTechnician}
          dataSource={dataTechnician}
          render={(record) => <> {record.usuario_nome}</>}
          listStyle={{ width: '100%', height: 'calc(90vh - 150px)' }}
          onChange={handleChangeMoveTransfer}
        />
      </ViewPage>
    </Container>
  )
}

export default EquipeConsultor
