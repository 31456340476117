import styled from "styled-components";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck, faCircleXmark } from "@fortawesome/free-solid-svg-icons";

//STYLES
const Tag = styled.div`
    box-sizing: border-box;
    padding: 5px 12px;
    background-color: #F0F0F0;
    border-radius: 5px;
    display: flex;
    align-items: center;
    gap: 5px;
    font-size: 13px;
    font-weight: 600;
    flex-shrink: 0;
    min-height: 30px;

    :is([data-method = 'fail']) {
        background-color: #fff1f0;
        color: #ff4d4f;
    }
    :is([data-method = 'success']) {
        background-color: #F6FFED;
        color: #52C41A;
    }

`;

//PROPS
interface Props {
    status: 'success' | 'fail';
    style?: React.CSSProperties;
    value: string;
}

export const TagStatus: React.FC<Props> = ({ status, style, value }) => {

    function statusInfo():{icon: any, description:'Sucesso'|'Falha'} {
        if (status==='success') {
            return {icon: faCircleCheck, description: 'Sucesso'}
        }
        return {icon: faCircleXmark, description: 'Falha'}
    }

    return (
        <Tag data-method={status} style={style}>
            <FontAwesomeIcon icon={statusInfo().icon} />
            {value!=='undefined' ? value : '???'}
        </Tag>
    )
}