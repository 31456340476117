import styled from "styled-components";

export const ContainerDetalhes = styled.div`
    
    div.ContainerDetalhes__urlPreview {
        box-sizing: border-box;
        padding: 15px;
        border-bottom: thin solid var(--border-color);
    }
    
    div.ContainerDetalhes__content {
        box-sizing: border-box;
        padding: 15px;
        display: flex;
        flex-direction: column;
        gap: 10px;
    }
`;