
import { AxiosResponse } from 'axios'
import { api } from '../api'

import { localConfig } from '../../Configs/localConfig'

export interface TypeRepresentanteLegal {
  itens: [RepresentanteLegalItens]
}

export interface RepresentanteLegalItens {
  cod?: number
  cod_cliente?: number
  cod_conta?: number
  cod_usu?: number
  participacao?: string
  observacao?: string
  status?: number
  representante?: number | boolean
  cei?: number
  solicitante?: string
  usuario_nome?: string
  conta_nome?: string
  cliente_nome?: string
  assinatura?: string
  assinatura_base64?: string
}

export const getRepresentanteLegal = async (cod, codCliente) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeRepresentanteLegal> = await api.get(
    `/representante-legal?codConta=${config.conta}&codCliente=${codCliente === '' ? config.codCliente : codCliente
    }&cod=${cod}&pagination=-1`
  )

  return response
}

export const getRepresentanteLegalAssinatura = async (codUsuario, arquivo, codCliente) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeRepresentanteLegal> = await api.get(
    `/representante-legal/visualizar/arquivo/${arquivo}?codUsuario=${codUsuario}&codConta=${config.conta
    }&codCliente=${codCliente === '' ? config.codCliente : codCliente}`
  )

  return response
}

export const postRepresentanteLegal = async (data, codCliente) => {
  const config = localConfig()

  const response: AxiosResponse<TypeRepresentanteLegal> = await api.post(
    `/representante-legal?codConta=${config.conta}&codCliente=${codCliente === '' ? config.codCliente : codCliente
    }`,
    data
  )

  return response
}

export const putRepresentanteLegal = async (cod, data, codCliente) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso
  api.defaults.headers['Content-Type'] = 'application/json'

  const response: AxiosResponse<TypeRepresentanteLegal> = await api.put(
    `/representante-legal/${cod}?codConta=${config.conta}&codCliente=${codCliente === '' ? config.codCliente : codCliente
    }`,
    data
  )

  return response
}

export const delRepresentanteLegal = async (cod, codCliente) => {
  const config = localConfig()

  api.defaults.headers.Acesso = config.acesso

  const response: AxiosResponse<TypeRepresentanteLegal> = await api.delete(
    `/representante-legal/${cod}?codConta=${config.conta}&codCliente=${codCliente === '' ? config.codCliente : codCliente
    }`
  )

  return response
}
