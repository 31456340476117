import { createSlice } from '@reduxjs/toolkit'
import type { PayloadAction } from '@reduxjs/toolkit'

export interface PropsProgressState {
  data: TypeDetailsMonitoramento
}

export interface TypeDetailsMonitoramento {
  talhao?: string
  especie?: string
  variacao?: string
  qtdPontos?: number
  totalizar?: number
  inverterEscala?: boolean
  estadios?: string
  pontosAcima?: number
  pontosAbaixo?: number
  data?: string
  media?: {
    min: number
    med: number
    max: number
  }
}

const initialState: PropsProgressState = { data: {} as TypeDetailsMonitoramento }

const dataDetails = createSlice({
  name: 'dataDetails',
  initialState,
  reducers: {
    setDataDetails(state, action: PayloadAction<TypeDetailsMonitoramento>) {
      state.data = action.payload
    },
    resetDataDetails(state) {
      state.data = {} as TypeDetailsMonitoramento
    },
  },
})

export const { setDataDetails, resetDataDetails } = dataDetails.actions
export default dataDetails.reducer
