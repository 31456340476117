import styled from 'styled-components'

export const Container = styled.div`
  .form-param {
    height: calc(100vh - 160px);
    overflow: auto;
  }
  .ant-input-group-addon {
    background-color: #ca5e51;
    color: #ffffff;
    border-color: #ca5e51;
    transition: 0.5s;
    padding: 0;
    div {
      cursor: pointer;
      padding: 0 11px;
    }

    &:hover {
      background-color: #c82333;
      color: #ffffff;
      border-color: #c82333;
    }
  }
`
