import { Container } from "./styled"

//PROPS
interface Props {
    children?: React.ReactNode;
    padding?: string | number;
}

const PageContainer: React.FC<Props> = ({ children }) => {
    return (
        <Container>
            {children}
        </Container>
    )
}

export default PageContainer;