import { AxiosResponse } from 'axios'
import { localConfig } from '../../../Configs/localConfig'
import { api } from '../../api'

export interface TypesPadraoPontos {
  itens: ItensPadraoPontosTypes[]
}

export interface ItensPadraoPontosTypes {
  cod: number
  nome: string
  descricao?: string
  cod_conta: number
  cod_cliente: number
  status: number
  conta_nome: string
}

type TypesQuery = {
  cod?: number
  codTalhao?: number
  codPropriedade?: number
  pagination?: number
}

const url = '/monitoramento/padrao-ponto'

export const getPadraoPontos = async (params: TypesQuery) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPadraoPontos> = await api.get(url, {
    params: {
      codCliente,
      codConta: conta,
      ...params,
      pagination: -1,
    },
  })

  return resp
}

export const postPadraoPontos = async (data) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPadraoPontos> = await api.post(url, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const putPadraoPontos = async (data, cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPadraoPontos> = await api.put(`${url}/${cod}`, data, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}

export const delPadraoPontos = async (cod) => {
  const { codCliente, conta } = localConfig()
  const resp: AxiosResponse<TypesPadraoPontos> = await api.delete(`${url}/${cod}`, {
    params: {
      codCliente,
      codConta: conta,
    },
  })

  return resp
}
