
export function generatePaginationv2(tamanho_lista: number, maxVisivel: number, pagina_atual: number) {

    const lista_gerada = Array.from({ length: tamanho_lista }, (_, index) => index);
    const ultimo_item_original = lista_gerada[lista_gerada.length - 1];

    if (lista_gerada.length <= maxVisivel) {
        return lista_gerada;
    }

    const lista_reduzida = lista_gerada.slice(0, maxVisivel);

    const primeiro_item_reduzida = lista_reduzida[0];

    const tolereancia_inicio = primeiro_item_reduzida + 4;

    const distancio_pro_inicio = (pagina_atual - tolereancia_inicio);

    if (distancio_pro_inicio > 0) {
        let subir_lista = lista_reduzida.map(item=>item+distancio_pro_inicio);
        let sem_excedentes = subir_lista.filter(item=> item <= ultimo_item_original );
        let ultimo_item_atual = sem_excedentes[sem_excedentes.length - 1];

        if (ultimo_item_atual === ultimo_item_original) {
            return [0, -2, ...sem_excedentes]
        }

        return [0, -2, ...sem_excedentes, -2, ultimo_item_original]
    }

    return [...lista_reduzida, -2, ultimo_item_original];
}