import styled from 'styled-components'

const Container = styled.div`
  height: 100vh;
  overflow-y: hidden;

  header {
    position: relative;

    .option-user {
      position: absolute;
      top: 0;
      right: 0;
      margin-right: 15px;

      a {
        padding-left: 8px;
      }
    }

    .selectCliente {
      position: absolute;
      top: 15px;
      left: 50px;
    }
  }

  .ant-dropdown-menu-item {
    color: red !important;
  }

  .trigger {
    padding: 0 24px;
    font-size: 18px;
    line-height: 64px;
    cursor: pointer;
    transition: color 0.3s;
  }

  .area-logo {
    display: flex;
    justify-content: center;
    position: relative;
    z-index: 30 !important;
    height: 76px;
    background: #fff;
    padding: 0;
  }

  .logo {
    height: 75px;
  }

  .logo-sm {
    height: 55px;
  }

  .logo-cliente {
    height: 64px;
    background: #fff;
    max-width: 68px;
  }


  .site-layout .site-layout-background {
    background: #fff;

    span:hover {
      color: #4caf50;
    }
  }

  .corThema {
    background: linear-gradient(0deg, rgba(64, 128, 128, 1) 0%, rgba(59, 80, 99, 1) 50%) !important;
    height: 91vh;
  }

  .ant-menu-item-selected {
    background: linear-gradient(0deg, rgba(64, 128, 128, 1) 0%, rgba(59, 80, 99, 1) 50%) !important;
  }

  .ant-menu-inline.ant-menu-sub {
    background: rgba(59, 80, 99, 1) !important;
  }

  .ant-menu-item {
    font-weight: 500;
  }

  .ant-dropdown-menu-item {
    span {
      font-size: 50px !important;
      color: red;
    }
  }

  .title > div > span {
    padding-top: 5px;
  }

  .title .anticon {
    padding-top: 5px;
  }
`

export default Container
