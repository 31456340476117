import styled from 'styled-components'

export const Container = styled.div`
  .head-visu {
    thead[class*='ant-table-thead'] th {
      background-color: yellow !important;
    }
  }
`
export const CampoBuscar = styled.div`
  width: 25%;
  margin-left: 5px;
  border-radius: 5px;
  margin-bottom: 10px;
  border: 1px solid #d9d9d9;
  .buscar {
    height: 100%;
    width: 90%;
    padding: 5px;
    border-radius: 10px;
    border: none;
  }
`
