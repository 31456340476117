import React, { useEffect, useState } from 'react'

import { Button, Modal, Table, Tag } from 'antd'

import { FontAwesomeIcon as FaIcon } from '@fortawesome/react-fontawesome'

import { solid } from '@fortawesome/fontawesome-svg-core/import.macro'

import { delCategoriaMarca, getCategoriaMarca } from '../../../../services/config/marca'

import FormMarca from '../../../../Components/Marcas'

import { Notification } from '../../../../Components/notification'

import { Container } from './styled'
import { IconAlert } from '../../../../Components/iconsAlert'
import { pagination } from '../../../../Components/Default'
import ViewPage from '../../../../Components/ViewPage'

const Marcas: React.FC = () => {
  const [loaddingTable, setLoadingTable] = useState(false)
  const [visibleForm, setVisisbleForm] = useState(false)

  const [uppdateMarca, setUpdateMarca] = useState(0)

  const [dataTable, setLDataTable] = useState([])
  const [dataTableRelative, setLDataTableRelative] = useState([])

  const listMarcas = async () => {
    setLoadingTable(true)
    const response = await getCategoriaMarca('', '', '', 'nome')
    setLDataTable(response.data.itens)
    setLDataTableRelative(response.data.itens)

    setLoadingTable(false)
  }

  const handleVisibleForm = () => {
    setVisisbleForm(true)
    setUpdateMarca(0)
  }

  const handleEditMarca = (data) => {
    setUpdateMarca(data)
    setVisisbleForm(true)
  }

  const handlesPesquisa = (data) => {
    setLoadingTable(true)
    const dados = dataTable
    const nome: any = data.target.value.toLowerCase()

    setTimeout(() => {
      const response = dados.filter((info) => info.nome.toLowerCase().indexOf(nome) !== -1)
      setLDataTableRelative(response)
      setLoadingTable(false)
    }, 500)
  }

  const handleTrashFornecedor = async (data, nome) => {
    try {
      Modal.confirm({
        title: 'Excluir',
        content: `Deseja realmente remover a Marca ${nome}?`,
        icon: <IconAlert type='confirm' size={6} />,
        okText: 'Confirmar',
        cancelText: 'Cancelar',
        cancelButtonProps: {
          type: 'primary',
          danger: true,
        },
        onOk: async () => {
          const responseDel = await delCategoriaMarca(data)

          if (responseDel.status === 200) {
            Notification({
              message: 'Marca Excluido',
              type: 'success',
            })

            listMarcas()
          }
        },
      })
    } catch (error) {
      if (error.response) {
        const { data } = error.response

        Notification({
          type: 'error',
          message: 'Erro',
          descricao: data.error,
        })
      }
    }
  }

  useEffect(() => {
    listMarcas()
  }, [])

  return (
    <Container>
      <ViewPage
        title='Marca'
        btnClick={handleVisibleForm}
        search
        inputChange={handlesPesquisa}
        content={
          <FormMarca
            onShow={visibleForm}
            onClose={setVisisbleForm}
            update={uppdateMarca}
            setUpdate={setUpdateMarca}
            refresh={listMarcas}
          />
        }
      >
        <Table
          pagination={pagination}
          size='small'
          loading={loaddingTable}
          dataSource={dataTableRelative}
          footer={() => (
            <div
              style={{
                display: 'flex',
                width: '100%',
                justifyContent: 'right',
              }}
            >
              <b>Total geral: {dataTable.length}</b>
            </div>
          )}
          onRow={(record) => {
            return {
              onDoubleClick: () => {
                handleEditMarca(record.cod)
              },
            }
          }}
          scroll={{ y: 'calc(90vh - 255px)' }}
          columns={[
            {
              key: 1,
              dataIndex: 'nome',
              title: 'Nome',
            },
            {
              key: 2,
              dataIndex: 'categorias',
              title: 'Categorias',
              render: (data: any[]) => {
                return (
                  <>
                    {data.map((info) => (
                      <Tag key={info.categoria_nome}>{info.categoria_nome}</Tag>
                    ))}
                  </>
                )
              },
            },
            {
              key: 3,
              dataIndex: 'cod',
              title: 'Ações',
              render: (data, dados) => (
                <>
                  <Button
                    type='primary'
                    shape='circle'
                    className='info-button'
                    onClick={() => handleEditMarca(data)}
                    icon={<FaIcon icon={solid('pen')} />}
                    size='middle'
                  />
                  {'   '}
                  <Button
                    shape='circle'
                    onClick={() => handleTrashFornecedor(data, dados.nome)}
                    className='trash-button'
                    type='primary'
                    icon={<FaIcon icon={solid('trash')} />}
                    size='middle'
                  />
                </>
              ),
            },
          ]}
        />
      </ViewPage>
    </Container>
  )
}

export default Marcas
