import { useEffect, useState } from "react";
import { Button, Upload } from "antd";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAngleLeft, faBorderTopLeft, faMapLocation, faRoad, faTableCellsLarge, faUpload } from "@fortawesome/free-solid-svg-icons";

//APP
import { gerarKey } from "../../../../../utils";
import { SubAreaItens } from "../../../../../services/subArea";
import { PropriedadeItens } from "../../../../../services/Propriedades";
import { TalhaoCultivaveisItens } from "../../../../../services/TalhaoSafra";
import { AgrupamentoTalhaoItens } from "../../../../../services/agrupamentoTalhao";
import { ordernarListaTalhoes, tabs_pontos } from "../../meta";

//STYLES
import { PainelPontos } from "./style";

//COMPONENTS
import Label from "../../../../Label";
import TabsRadio from "../../../../CustomUi/TabsRadio";
import ButtonCustom from "../../../../ButtonCustom";
import { Render } from "../../../../CustomUi/render";
import { Select } from "../../../../CustomUi/Select";
import { RowItem } from "../../../../CustomUi/table/rowItem";
import { RowTable } from "../../../../CustomUi/table/rowTable";
import { Switcher } from "../../../../CustomUi/Switcher";
import { LineDivider } from "../../../../CustomUi/LineDivider";
import { HeaderTable } from "../../../../CustomUi/table/headerTable";
import { OptionsPainel } from "./components/options";
import { EditarNomeTalhao } from "./components/editName";

//TYPES
type MarkerType = {
    cod?: number
    nome?: string
    cod_talhao: number
    latitude: number
    longitude: number
}
//PROPS
interface Props {
    handleCloseDrawer: Function;
    list_propriedades: PropriedadeItens[];
    setCod_propriedade_atual: React.Dispatch<React.SetStateAction<number|null>>;
    handleChangePropriedade: Function;
    tipoMonitoramento: number | null;
    setTipoMonitoramento: React.Dispatch<React.SetStateAction<number | null>>;
    list_talhoes: TalhaoCultivaveisItens[];
    handleChangeTalhao: Function;
    list_Agrupamentos: AgrupamentoTalhaoItens[];
    handleChangeAgrupamento: Function;
    list_subarea: SubAreaItens[];
    handleChangeSubarea: Function;
    setCod_subarea_atual: React.Dispatch<React.SetStateAction<number|null>>;
    markerDefault: MarkerType[];
    visibleLinhas: boolean;
    setVisibleLinhas: React.Dispatch<React.SetStateAction<boolean>>;
    dummyRequest:  (options: any) => void;
    handleUploadKml: Function;
    handleChangeEditName: Function;
    handleClickRow: Function;
    polygonSubArea: google.maps.Polygon;
    polygonAgrupamento: google.maps.Polygon;
    setMarkerDefault: Function;
    atualizarListaPropriedades: Function;
    visible: boolean;
}

export const PainelPontosPadrao: React.FC<Props> = ({ list_propriedades, handleChangePropriedade, setTipoMonitoramento, 
    tipoMonitoramento, setCod_propriedade_atual, handleChangeTalhao, list_talhoes, 
    list_Agrupamentos, handleChangeAgrupamento, list_subarea, handleChangeSubarea, setCod_subarea_atual, 
    markerDefault, visibleLinhas, setVisibleLinhas, dummyRequest, handleUploadKml, handleChangeEditName, 
    handleClickRow, handleCloseDrawer, polygonSubArea, polygonAgrupamento, setMarkerDefault, atualizarListaPropriedades, 
    visible
 }) => {

    //CONST
    const column_width: number = 150;

    //STATES - TAB
    const [tab_selecionada, setTab_selecionada] = useState<string>('Propriedade');
    //STATES - PROPRIEDADE
    const [nome_propriedade, setNome_propriedade] = useState<string>('');
    const [cod_propriedade, setCod_propriedade] = useState<number|null>(null);
    const [open_propriedade, setOpen_propriedade] = useState<boolean>(false);
    //STATES - AGRUPAMENTO
    const [nome_agrupamento, setNome_agrupamento] = useState<string>('');
    const [cod_agrupamento, setCod_agrupamento] = useState<number|null>(null);
    const [open_agrupamento, setOpen_agrupamento] = useState<boolean>(false);
    //STATES - TALHÃO
    const [nome_talhao, setNome_talhao] = useState<string>('');
    const [cod_talhao, setCod_talhao] = useState<number|null>(null);
    const [open_talhao, setOpen_talhao] = useState<boolean>(false);
    //STATES - SUBÁREA
    const [nome_subarea, setNome_subarea] = useState<string>('');
    const [cod_subarea, setCod_subarea] = useState<number|null>(null);
    const [open_subarea, setOpen_subarea] = useState<boolean>(false);
    //STATES - SELECIONAR LINHA
    const [current_row, setCurrent_row] = useState<number | null>(null);
    const [popup_isOpen, setPopup_isOpen] = useState<boolean>(false);

    useEffect(() => {
        if (cod_propriedade!==null) {
            setCod_propriedade_atual(cod_propriedade)
            setTipoMonitoramento(1)
            handleChangePropriedade({value: cod_propriedade});
        }
    }, [cod_propriedade]);

    useEffect(() => {
        if (cod_propriedade!==null) {
            handleChangePropriedade({value: cod_propriedade});
        }
        if (tipoMonitoramento!==3 && tipoMonitoramento!==4) {
            setCod_talhao(null);
            setNome_talhao('');
        }
        if (tipoMonitoramento!==2) {
            setNome_agrupamento('');
            setCod_agrupamento(null)
        }
        if (tipoMonitoramento!==4) {
            setNome_subarea('');
            setCod_agrupamento(null)
        }
    }, [tipoMonitoramento]);
    
    useEffect(() => {
        if (cod_talhao!==null) {
            handleChangeTalhao({value: cod_talhao});
        }
    }, [cod_talhao]);
    
    useEffect(() => {
        if (cod_agrupamento!==null) {
            handleChangeAgrupamento(cod_agrupamento);
        }
    }, [cod_agrupamento]);

    useEffect(() => {
        if (cod_subarea!==null) {
            handleChangeSubarea(cod_subarea);
            setCod_subarea_atual(cod_subarea);
        }
    }, [cod_subarea]);

    useEffect(() => {
        if (tipoMonitoramento===2 && cod_propriedade!==null && cod_agrupamento===null) {
            polygonAgrupamento.setOptions({
                fillColor: 'white',
                strokeColor: 'white',
                clickable: false,
            })
            setMarkerDefault([]);
        }
        else if (tipoMonitoramento===2 && cod_propriedade!==null && cod_agrupamento!==null) {
            polygonAgrupamento.setOptions({ fillColor: 'cyan', strokeColor: 'cyan', clickable: true })  
        } 
        

    }, [cod_agrupamento])
    

    useEffect(() => {
        //se tiver apenas 1 propriedade, irá selecionar automáticamente
        setTimeout(() => {
           if (list_propriedades?.length === 1 && visible) {
                setNome_propriedade(list_propriedades?.[0]?.nome);
                setCod_propriedade(list_propriedades?.[0]?.cod);
            } 
        }, 500);
        
    }, [visible])
    

    function limparStates() {

        setTab_selecionada('Propriedade');
        setNome_propriedade('');
        setCod_propriedade(null);
        setOpen_propriedade(false);
        setNome_agrupamento('');
        setCod_agrupamento(null);
        setOpen_agrupamento(false);
        setNome_talhao('');
        setCod_talhao(null);
        setOpen_talhao(false);
        setNome_subarea('');
        setCod_subarea(null);
        setOpen_subarea(false);
        setCurrent_row(null);
        setPopup_isOpen(false);

        setCod_propriedade_atual(null);
        setTipoMonitoramento(null);
        setCod_subarea_atual(null);
        setVisibleLinhas(false);

    }
    
    return (
        <PainelPontos>

            <ButtonCustom
                type="tertiary"
                danger
                icon={<FontAwesomeIcon icon={faAngleLeft} />} 
                onClick={()=>{limparStates();handleCloseDrawer()}}
                style={{marginLeft: -10, alignSelf:'flex-start'}}
            >
                Voltar
            </ButtonCustom>

            <Select 
                value={nome_propriedade}
                onChange={setNome_propriedade}
                onChangeID={setCod_propriedade}
                open={open_propriedade}
                trigger={setOpen_propriedade}
                descricaoItem="nome"
                idItem="cod"
                items={list_propriedades ?? []}
                label="Selecione a propriedade"
                placeholder="Selecione a propriedade"
                icon={faMapLocation}
                onChangeExtra={tipoMonitoramento===null? ()=> setTipoMonitoramento(1) : ()=>{}}
            />

            <Render condition={cod_propriedade !== null}>
                <LineDivider />

                <TabsRadio 
                    label="Selecione o tipo de mapa:"
                    onChange={setTab_selecionada}
                    onChangeId={setTipoMonitoramento}
                    selecionado={tab_selecionada}
                    tabs={tabs_pontos}
                    trackWidth={115}
                />

                <Render condition={tipoMonitoramento===2}>
                    <Select 
                        value={nome_agrupamento}
                        onChange={setNome_agrupamento}
                        onChangeID={setCod_agrupamento}
                        open={open_agrupamento}
                        trigger={setOpen_agrupamento}
                        descricaoItem="nome"
                        idItem="cod"
                        items={list_Agrupamentos ?? []}
                        label="Selecione o agrupamento de talhões"
                        placeholder="Selecione o agrupamento"
                        icon={faTableCellsLarge}
                    />
                </Render>

                <Render condition={(tipoMonitoramento===3 || tipoMonitoramento===4)}>
                    <Select 
                        value={nome_talhao}
                        onChange={setNome_talhao}
                        onChangeID={setCod_talhao}
                        open={open_talhao}
                        trigger={setOpen_talhao}
                        descricaoItem="nome"
                        idItem="cod"
                        items={list_talhoes ?? []}
                        label="Selecione o talhão"
                        placeholder="Selecione o talhão"
                        icon={faRoad}
                        hasTalhaoSVG
                        iconSVGSize={40}
                        allowClear={false}
                    />
                </Render>

                <Render condition={tipoMonitoramento===4}>
                    <Select 
                        value={nome_subarea}
                        onChange={setNome_subarea}
                        onChangeID={setCod_subarea}
                        open={open_subarea}
                        trigger={setOpen_subarea}
                        descricaoItem="nome"
                        idItem="cod"
                        items={list_subarea ?? []}
                        label="Selecione a subárea do talhão"
                        placeholder="Selecione a subárea"
                        icon={faBorderTopLeft}
                        disabled={cod_talhao===null}
                    />
                </Render>

                <OptionsPainel 
                    cod_propriedade_atual={cod_propriedade}
                    cod_agrupamento={cod_agrupamento}
                    cod_talhao_atual={cod_talhao}
                    cod_subarea_atual={cod_subarea}
                    list_talhoes={list_talhoes}
                    markerDefault={markerDefault ?? []}
                    tipoMonitoramento={tipoMonitoramento}
                    handleChangePropriedade={handleChangePropriedade}
                    handleChangeAgrupamento={handleChangeAgrupamento}
                    handleChangeTalhao={handleChangeTalhao}
                    handleChangeSubarea={handleChangeSubarea}
                    nome_propriedade_atual={nome_propriedade}
                    nome_talhao_atual={nome_talhao}
                    list_Agrupamentos={list_Agrupamentos ?? []}
                    polygonSubArea={polygonSubArea}
                    nome_subarea={nome_subarea}
                    atualizarListaPropriedades={atualizarListaPropriedades}
                    nome_agrupamento_atual={nome_agrupamento}
                    nome_subarea_atual={nome_subarea}
                />

                <LineDivider />

                <Render condition={markerDefault.length > 0}>
                    <HeaderTable
                        itens={[
                            { valor: 'Nome', width: 165.5 },
                            { valor: 'Latitude', width: column_width },
                            { valor: 'Longitude', width: column_width },
                        ]}
                    />
                    <div className="PainelPontos__table">
                        {ordernarListaTalhoes(markerDefault)?.map((ponto, index)=> {
                            return (
                                <RowTable key={gerarKey(index)} highlight={(current_row === index) && popup_isOpen}>
                                    <RowItem width={166} fontSize={12} gap={7} onClick={()=>{handleClickRow(ponto?.index_original)}}>
                                        
                                        <EditarNomeTalhao 
                                            nome_talhao={ponto?.nome} 
                                            setCurrent_row={setCurrent_row} 
                                            index_atual={index}
                                            ponto_info={ponto}
                                            salvarNomeEditado={handleChangeEditName}
                                            setPopup_isOpen={setPopup_isOpen}
                                        />
                                        
                                    </RowItem>
                                    <RowItem width={column_width} fontSize={11.5} onClick={()=>{handleClickRow(ponto?.index_original)}}>
                                        {ponto?.latitude}
                                    </RowItem>
                                    <RowItem width={column_width} fontSize={11.5} onClick={()=>{handleClickRow(ponto?.index_original)}}>
                                        {ponto?.longitude}
                                    </RowItem>
                                </RowTable>
                            )
                        })}
                    </div>
                </Render>  

                <LineDivider />
                <Label fontSize={13}>Opções de linhas</Label>
                <div className="PainelPontos__footer">
            
                    <Upload
                        showUploadList={false}
                        maxCount={1}
                        multiple={false}
                        customRequest={dummyRequest}
                        accept='.kml'
                        onChange={(d) => handleUploadKml(d, 'linha')}
                    >
                        <Button size='small' style={{ background: 'transparent', padding: 0, margin: 0, border: 'none', boxShadow: 'none' }} >
                            <ButtonCustom
                                onClick={()=>{}}
                                icon={<FontAwesomeIcon icon={faUpload} />}
                                type="secondary"
                            >
                                Importar
                            </ButtonCustom>
                        </Button>
                    </Upload>

                    <Switcher 
                        label="Exibir linhas de plantio"
                        isChecked={visibleLinhas}
                        trigger={setVisibleLinhas}
                    />
                </div>

            </Render>
       

        </PainelPontos>
    )
}