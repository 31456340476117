import styled from "styled-components";
import Label from "../../../../../../../../../../Components/Label";

//STYLES
const Wrapper = styled.div`
    width: 100%;
    display: flex;
    flex-direction: column;
    gap: 3px;
`;
const GroupButton = styled.div`
    width: 270px;
    height: 30px;
    display: flex;
    background: #F4F4F4;
    border-radius: 7px;
    overflow: hidden;

    button.GroupButton__btn {
        box-sizing: border-box;
        width: 90px;
        border: none;
        background-color: transparent;
        cursor: pointer;
        display: flex;
        align-items: center;
        justify-content: center;
        gap: 5px;
        color: var(--text-color-light);
        font-size: 13px;
        font-weight: 500;
        opacity: 0.8;
    }
    button.GroupButton__btn:is([data-type = 'Baixo']):hover {
        background-color: #FFFCEB;
        color: #FFD001;
    }
    button.GroupButton__btn:is([data-type = 'Médio']):hover {
        background-color: #FFF0D9;
        color: #FF9B05;
    }
    button.GroupButton__btn:is([data-type = 'Alto']):hover {
        background-color: #ffeeee;
        color: #FF4D4F;
    }
    button.GroupButton__btn:is([data-type = 'Baixo']):is([data-active = true]) {
        background-color: #FFD001;
        color: var(--text-color);
        font-weight: 600;
    }
    button.GroupButton__btn:is([data-type = 'Médio']):is([data-active = true]) {
        background-color: #FF9B05;
        font-weight: 600;
        color: #FFF0D9;
    }
    button.GroupButton__btn:is([data-type = 'Alto']):is([data-active = true]) {
        background-color: #ff4d4f;
        font-weight: 600;
        color: #fff;
    }
    div.GroupButton__btn__dot {
        width: 7px;
        height: 7px;
        border-radius: 50%;
        background: gray;
    }
    button.GroupButton__btn:is([data-type = 'Baixo']) div.GroupButton__btn__dot {
        background: #FFD001;
    }
    button.GroupButton__btn:is([data-type = 'Médio']) div.GroupButton__btn__dot {
        background: #FF9B05;
    }
    button.GroupButton__btn:is([data-type = 'Alto']) div.GroupButton__btn__dot {
        background: #FF4D4F;
    }
    button.GroupButton__btn:is([data-type = 'Baixo']):is([data-active = true]) div.GroupButton__btn__dot {
        background: #856C00;
    }
    button.GroupButton__btn:is([data-type = 'Médio']):is([data-active = true]) div.GroupButton__btn__dot {
        background: #FFF0D9;
    }
    button.GroupButton__btn:is([data-type = 'Alto']):is([data-active = true]) div.GroupButton__btn__dot {
        background: #990002;
    }
`;

//PROPS
interface Props {
    id_nivel_alerta: number;
    setId_nivel_alerta: React.Dispatch<React.SetStateAction<number>>;
}

export const Nivel: React.FC<Props> = ({ id_nivel_alerta, setId_nivel_alerta }) => {

    const niveis: {id:number, descr:string}[] = [
        {id: 1, descr: 'Baixo'},
        {id: 2, descr: 'Médio'},
        {id: 3, descr: 'Alto'}
    ];

    return (
        <Wrapper>
            <Label fontSize={13}>Nível de alerta</Label>

            <GroupButton>
               {niveis.map(({ id, descr })=> {
                    return (
                        <button 
                            className="GroupButton__btn" 
                            data-type={descr} 
                            data-active={id_nivel_alerta === id} 
                            key={id}
                            onClick={()=>{setId_nivel_alerta(id)}}
                        >
                            <div className="GroupButton__btn__dot"/>
                            {descr}
                        </button>
                    )
                })} 
            </GroupButton>
            
        </Wrapper>
    )
} 