import { faBirthdayCake, faCalendarDays, faHandshake } from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { Avatar, Drawer, Empty } from 'antd'
import dayjs from 'dayjs'
import React, { useEffect, useRef, useState } from 'react'
import JSConfetti from 'js-confetti'
import Carregando from '../../../CustomUi/loading'
import GavetaAniversariante from '../gavetaAniversariante'

interface PropsLista {
  dados: any[]
  loading?: boolean
  setLoading?: React.Dispatch<React.SetStateAction<boolean>>
}

const ListaAniversariantes: React.FC<PropsLista> = ({ dados, loading, setLoading }) => {
  const [open, setOpen] = useState(false)
  const [cod, setCod] = useState(0)

  function abrirGaveta(cod) {
    setCod(cod)
    setOpen(true)
  }

  if (loading) {
    return <Carregando justifyContent='center' height={400} legenda='Carregando...' animation />
  }

  return (
    <>
      {dados.length > 0 ? (
        dados.map((item) => {
          let foto = item?.doc_foto_perfil_url ? true : false
          return (
            <div
              className='proximos__aniversariantes'
              key={item.nome}
              data-hoje={item?.hoje}
              data-passou={dayjs(item?.dataBase).format('MM-DD') < dayjs().format('MM-DD')}
              onClick={() => abrirGaveta(item?.cod)}
            >
              <Avatar
                src={item?.doc_foto_perfil_url}
                style={{
                  color: 'white',
                  fontWeight: 500,
                  fontSize: 19,
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  width: foto ? '135px' : '45px',
                  height: foto ? '46px' : '45px',
                }}
              >
                {String(item?.nome)?.substring(0, 1)?.toLocaleUpperCase()}
              </Avatar>

              <div className='info__nome__data'>
                <p className='nome__aniversariante__lista' title={item?.nome}>
                  {`${item?.nome?.toLowerCase()} ${item?.sobrenome?.toLowerCase()}`}
                </p>
                <div className='data'>
                  <FontAwesomeIcon
                    style={{ fontSize: '12px' }}
                    icon={item?.tipo === 'nascimento' ? faBirthdayCake : faHandshake}
                  />

                  <p>{dayjs(item?.dataBase).format('DD/MM/YYYY')}</p>
                </div>
              </div>
            </div>
          )
        })
      ) : (
        <Empty
          image={Empty.PRESENTED_IMAGE_SIMPLE}
          description={<>Nenhum aniversariante no mês</>}
        />
      )}
      <GavetaAniversariante cod={cod} open={open} setOpen={setOpen} dados={dados} />
    </>
  )
}

export default ListaAniversariantes
