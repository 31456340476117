import { message } from "antd";
import { ItemRequest } from "../../../../../../../../services/logs";

export async function copyJson(request_selected: ItemRequest) {
    
    const { cod, descricao, cod_usuario, usuario_email, create_time, create_user, update_time, update_user, delete_time, delete_user } = request_selected ?? {};

    let buildJson = {
        'cod': cod,
        'uri': descricao.uri,
        'ip': descricao.ip,
        'httpMethod': descricao.httpMethod,
        'cod_usuario': cod_usuario,
        'usuario_email': usuario_email,
        'create_time': create_time,
        'create_user': create_user,
        'update_time': update_time,
        'update_user': update_user,
        'delete_time': delete_time,
        'delete_user': delete_user
    };

    let stringfy = JSON.stringify(buildJson);

    try {
        await navigator.clipboard.writeText(stringfy);
        message.success('JSON copiado com sucesso')
    } catch (err) {
        console.error("Falha ao copiar o JSON: ", err);
    }
}
