import styled from 'styled-components'

import CapFertilidade from './assets/MODELO_CAPA_NOVA.webp'
import SubFertilidade from './assets/imgSubCapa.webp'
import SubFertilidadePrescricoes from './assets/PRESCRICOES.webp'
import SubFertilidadeIndicadores from './assets/INDICADORES.webp'
import SubFertilidadeWhite from './assets/CAPA_EM_BRANCO.webp'

export const Container = styled.div`
  /* overflow: inherit; */
`

export const PdfPrint = styled.div`
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  font-variant: normal;

  @media print {
    @page {
      size: A4;
      margin: 0px;
    }

    .force_margen_sumario {
      display: block !important;
    }

    .break {
      width: 210mm;
      height: 297mm;
      break-inside: avoid;
    }
    .break-manual {
      width: 210mm;
      height: 593mm;
      break-inside: auto-fill;
    }
    .quebrar-forca {
    page-break-before: always;
    }
  }
`

export const Capa = styled.div`
  position: relative;
  background-image: url(${CapFertilidade});
  background-size: cover;
  position: relative;
  overflow: hidden;
  display: flex;
  width: 210mm;
  height: 297mm;
  justify-content: center;
  align-items: center;
  flex-direction: column;
  padding-left: 210px;
  border: 1px solid #e0e0e0;

  div.title {
    width: 100%;
    display: flex;
    flex-direction: column;
    align-items: end;
    padding-right: 50px;
    /* background-color: red; */
    h2 {
      font-size: 25px;
      color: #00a8b9;
      flex: 2;
      margin-top: 500px;
      margin-bottom: 0;
      font-weight: 700;
      font-family: 'Calibri' !important;
    }

    span {
      font-size: 18px;
      color: #6b6c6f;
      font-weight: 700;
      font-family: 'Calibri' !important;
    }
  }

  img.Capa__logo {
    max-height: 150px;
    object-fit: contain;
  } 

  @media (max-width: 768px) {
    height: 100vh;
    /* background-size: contain; */
    div.title {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: end;
      padding-right: 50px;
      /* background-color: red; */
      h2 {
        font-size: 12px;
        color: #00a8b9;
        flex: 2;
        margin-top: 100px;
        margin-bottom: 0;
        font-weight: 700;
        font-family: 'Calibri' !important;
      }

      span {
        font-size: 12px;
        color: #6b6c6f;
        font-weight: 700;
        font-family: 'Calibri' !important;
      }
    }

    div.footer {
      margin-right: 21px !important;
      div {
        img {
          width: 140px !important;
        }
        span {
        }
      }
      span {
      }
    }
  }

  div.footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 35%;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    padding-right: 25px;
    margin-bottom: 25px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 225px;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
    span {
      font-size: 22px;
    }
  }
`

export const Sumario = styled.div<{ $interpretado?: boolean; }>`
  position: relative;

  display: flex;
  position: relative;
  align-items: center;
  padding-top: 35px;
  /* overflow: hidden; */
  font-size: 12px;

  gap: 15px;
  flex-direction: column;
  width: 210mm;
  height: ${props => props.$interpretado ? '350mm' : '297mm'};
  /* height: 350mm; */
  /* height: 297mm; */
  border: 1px solid #e0e0e0;

  span.Sumario__referencia {
    position: inherit;

    width: 100%;
    /* height: 100%; */
    display: inherit;
    align-items: inherit;
    padding-top: inherit;
    overflow: inherit;
    font-size: inherit;
    gap: inherit;
    flex-direction: inherit;
    /* background-color: rebeccapurple; */
  }

  ul.ul-principal {
    width: 95%;
    display: flex;
    flex-direction: column;
    gap: 15px;
    list-style: none;
    margin-right: 35px;
    li.li-principal {
      display: flex;
      flex-direction: column;
      justify-content: space-between;
      padding-bottom: 5px;

      div.div-principal {
        width: 100%;
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        position: relative;
        font-weight: bold;
        background-color: #fff;
        div {
          z-index: 10;
          background-color: #fff;
          a {
            text-decoration: none;
            color: #000;
          }
        }
        &::before {
          z-index: 9;
          content: '';
          width: 95%;
          position: absolute;
          left: -20px;
          top: 0;
          bottom: 3px;
          margin-left: 35px;
          border-bottom: 1px dashed #ccc;
        }
      }
      ul.ul-secundaria {
        width: 100%;
        display: flex;
        flex-direction: column;
        gap: 5px;
        list-style: none;
        padding-left: 10px;

        .force_margen_sumario {
          display: none;
        }

        @media print {
          .force_margen_sumario {
          display: block;
        }
        
      }

        li.li-secundaria {
          display: flex;
          flex-direction: column;
          justify-content: space-between;

          div.div-secundaria {
            width: 100%;
            display: flex;
            flex-direction: row;
            justify-content: space-between;
            position: relative;
            z-index: 10;
            div {
              z-index: 10;
              background-color: #fff;
              a {
                color: #000;
                text-decoration: none;

                transition: 0.2s;
                &:hover {
                  font-weight: 500;
                }
              }
            }

            &::before {
              z-index: 9;
              content: '';
              width: 95%;
              position: absolute;
              left: -20px;
              top: 0;
              bottom: 3px;
              margin-left: 35px;
              border-bottom: 1px dashed #ccc;
            }
          }
        }
      }
    }
  }
`

export const Warning = styled.div`
  position: relative;
  display: flex;
  position: relative;
  align-items: center;
  overflow: hidden;

  flex-direction: column;
  justify-content: space-between;
  width: 210mm;
  height: 297mm;
  border: 1px solid #e0e0e0;

  div.msg {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 35px;
    h2 {
      font-weight: 700;
      font-size: 16px;
    }
    p {
      font-size: 16px;
    }
  }

  img.Warning__logo {
    max-height: 125px;
    object-fit: contain;
  }

  .btn-edit-warning {
    display: none;
    position: absolute;
    top: 50px;
    left: 15px;
    transition: 1s;
    z-index: 99999;
  }

  &:hover {
    .btn-edit-warning {
      display: block;
    }
  }

  div.logo {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    margin-bottom: 45px;
    margin-right: 35px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 225px;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
    span {
      font-size: 22px;
    }
  }
`

export const SubCapa = styled.div<{ profundidade: string, isPrescricoes?: boolean, isIndicadores?: boolean }>`
  position: relative;

  display: flex;
  /* background-image: url(${SubFertilidade}); */
  background-image:  ${props => props.isPrescricoes ? `url(${SubFertilidadePrescricoes})` : (props.isIndicadores ? `url(${SubFertilidadeIndicadores})` : `url(${SubFertilidade})`)};
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  width: 210mm;

  height: 297mm;
  border: 1px solid #e0e0e0;

  div.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 50px 100px;
    height: 100%;
    position: relative;
    h1 {
      display: flex;
      bottom: 0;
      align-items: end;
      flex-direction: row;
      gap: 5px;
      text-align: center;
      margin: 0;
      color: #3f3f3f;
      font-family: 'Calibri' !important;
    }
    img {
      width: 400px;
    }
  }

  
  img.SubCapa__logo {
    max-height: 150px;
    object-fit: contain;
  }

  div.footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    margin-right: 35px;
    margin-bottom: 25px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 225px;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
    span {
      font-size: 22px;
    }
  }
`

export const SubCapaWhite = styled.div`
  position: relative;

  display: flex;
  background-image: url(${SubFertilidadeWhite});
  background-size: cover;
  flex-direction: column;
  justify-content: space-between;
  position: relative;
  overflow: hidden;
  width: 210mm;
  height: 297mm;
  border: 1px solid #e0e0e0;

  div.title {
    font-family: 'Calibri';
    font-weight: 600;
    color: #152b42;
    margin: 0 0 50px 60px;
    text-transform: uppercase;
    font-size: 80px;
    position: absolute;
    top: 350px;
    width: 100%;
    text-align: center;
    display: flex;
    justify-content: center;
    align-items: center;
    height: 120px;
    div {
      border: 2px solid #152b42;
      border-left: none;
      border-right: none;
    }
  }

  div.header {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    padding: 0 0 50px 100px;
    height: 100%;
    position: relative;
    h1 {
      display: flex;
      bottom: 0;
      align-items: end;
      flex-direction: row;
      gap: 5px;
      text-align: center;
      margin: 0;
      color: #3f3f3f;
      font-family: 'Calibri' !important;
    }
    img {
      width: 400px;
    }
  }

  div.footer {
    display: flex;
    position: absolute;
    bottom: 0;
    width: 30%;
    flex-direction: column;
    justify-content: center;
    align-self: flex-end;
    margin-right: 35px;
    margin-bottom: 25px;
    div {
      display: flex;
      flex-direction: row;
      align-items: center;
      img {
        width: 225px;
      }
      span {
        font-size: 13px;
        font-weight: 500;
      }
    }
    span {
      font-size: 22px;
    }
  }
`

export const ContentPontos = styled.div`
  position: relative;

  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  justify-content: space-between;
  padding: 20px;
  overflow: hidden;

  width: 210mm;
  height: 297mm;
  border: 1px solid #e0e0e0;

  div.header {
    width: 95%;
    text-align: center;
    h1 {
      font-family: 'Calibri';
      font-weight: bold;
      font-size: 25px;
      color: #152b42;
      margin-bottom: 0;
    }
  }

  h2 {
    font-weight: 700;
    text-align: center;
  }

  div.data-produtor {
    display: flex;
    font-weight: 600;
    align-self: flex-start;
    flex-direction: column;
    margin-left: 18px;
    line-height: 25px;
  }

  div.footer {
    display: flex;
    flex-direction: row;
    align-items: center;
    img {
      width: 160px;
    }
    span {
      font-size: 18px;
      font-weight: 600;
    }
  }
`

export const Content = styled.div`
  position: relative;

  display: flex;
  position: relative;
  align-items: center;
  flex-direction: column;
  padding: 20px 35px 35px 35px;
  gap: 10px;

  width: 210mm;
  height: 297mm;
  border: 1px solid #e0e0e0;
  overflow: hidden;

  div.header {
    width: 100%;
    text-align: center;
    h2 {
      font-family: 'Calibri';
      font-weight: bold;
      font-size: 23px;
      color: #152b42;
      margin-bottom: 0;
      span {
        font-weight: normal;
        color: #00a8b9;
      }
    }
  }

  div.obs {
    text-align: center;
    h2 {
      font-weight: 700;
    }
  }
  .map-dimension {
    width: 100%;
    min-height: 600px;
    max-height: 600px;
    border-radius: 10px;
  }
`

export const ResumoNutrientes = styled.div`
  position: relative;

  display: flex;
  position: relative;
  align-items: center;
  padding-top: 35px;
  gap: 15px;
  flex-direction: column;
  width: 100%;
  height: 100vh;
  border: 1px solid #e0e0e0;
  overflow: hidden;

  div.div-table {
    border-radius: 5px;
    overflow: hidden;
    border: 1px solid #ccc;
    table {
      font-family: 'Calibri';
      thead,
      tbody,
      th,
      td {
        padding: 12px;
      }

      tbody {
        tr {
          th {
            display: flex;
            flex-direction: column;
            small {
              color: #959595;
            }
          }
        }
      }

      thead {
        tr {
          th {
            font-weight: 700;
            border-left: 1px solid #ccc;

            &:first-child {
              border-left: none;
            }
          }
        }
      }

      th {
        background-color: #f1f1f1;
      }
      td {
        text-align: center;
      }
    }
  }
`

export const TrReverse = styled.tr<{ reverse?: boolean }>`
  position: relative;

  th {
    text-align: left;
    font-weight: 600;
    border-top: 1px solid #ccc;
  }
  td {
    border-left: 1px solid #ccc;
    border-top: 1px solid #ccc;
    overflow: hidden;

    div {
      width: 100%;
      display: flex;
      justify-content: center;
      gap: 5px;
      align-items: center;
      div.circle {
        width: 10px;
        height: 10px;
        border: 1px solid #d8d8d8;
        border-radius: 50%;
      }
    }
  }
`

export const ZoomControl = styled.div`
  width: 100%;
  display: flex;
  justify-content: center;
  margin-right: 50px;
  div.box {
    position: relative;
    width: 300px;
    display: flex;
    align-items: center;
    border: 1px solid #f1f1f1;
    padding: 5px;
    border-radius: 5px;

    .zoom-label {
      position: absolute;
      bottom: 0;
      right: 100px;
      font-size: 12px;
      color: darkgrey;
      font-weight: 500;
    }
  }
`

export const Page = styled.div`
  position: absolute;
  width: 30px;
  height: 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  bottom: 0;
  right: 0;
  font-family: 'Calibri';
  overflow: hidden;
  padding-right: 8px;
`
